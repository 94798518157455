/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription } from '../models/MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray {
    'contextEpoch'?: number | null;
    'contextId'?: string | null;
    'duration'?: string;
    'partitionId'?: number;
    'replicaName'?: string | null;
    'replicaToken'?: string | null;
    'startTime'?: Date;
    'data'?: Array<MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "contextEpoch",
            "baseName": "contextEpoch",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "contextId",
            "baseName": "contextId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "string",
            "format": "duration"
        },
        {
            "name": "partitionId",
            "baseName": "partitionId",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "replicaName",
            "baseName": "replicaName",
            "type": "string",
            "format": ""
        },
        {
            "name": "replicaToken",
            "baseName": "replicaToken",
            "type": "string",
            "format": "byte"
        },
        {
            "name": "startTime",
            "baseName": "startTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "Array<MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray.attributeTypeMap;
    }

    public constructor() {
    }
}

