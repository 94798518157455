import './ContentModal.scss';

import React, {
  FunctionComponent, useEffect, ReactElement,
} from 'react';
import {
  Button, Header, Icon, Dimmer, Segment, Container, SemanticICONS,
} from 'semantic-ui-react';
import useEventListener from '@use-it/event-listener';

interface ContentModalProps {
  header: string,
  className?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: string | ReactElement | FunctionComponent<any> | undefined,
  style: 'success' | 'caution' | 'error',
  open: boolean | undefined,
  closeEvent?: () => void,
  icon?: SemanticICONS,
  actions?: ReactElement
}

const ContentModal: FunctionComponent<ContentModalProps> = ({
  header, className, message, style = 'success', open, icon, closeEvent, actions = undefined,
}: ContentModalProps) => {
  const [openModal, setOpenModal] = React.useState<boolean | undefined>(open);
  const contextRef = React.useRef<HTMLDivElement>(null);

  const ESCAPE_KEYS = ['27', 'Escape'];

  const triggerCloseEvent = () => {
    setOpenModal(false);
    if (closeEvent) closeEvent();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDownCheck = ({ key }: any) => {
    if (ESCAPE_KEYS.includes(String(key))) {
      triggerCloseEvent();
    }
  };

  useEventListener('keydown', handleKeyDownCheck);

  useEffect(() => {
    setOpenModal(open);

    if (contextRef) {
      const parentTabManagerElement = contextRef.current?.closest('.parent-tab-manager');
      const pinnedTabManagerElement = contextRef.current?.closest('.pinned-tab-container');
      // The pinned tab manager should be the preferred selector since we always antiticpate having a parent
      // tab manager element.
      const parentContainer = pinnedTabManagerElement || parentTabManagerElement;

      if (parentContainer) {
        const activeTabElement = parentContainer.querySelector(':scope > div > .segment.active.tab')
          || parentContainer.querySelector(':scope > .segment.active.tab');
        if (activeTabElement) {
          if (open) {
            activeTabElement.children[0].classList.add('modal-open');
          } else {
            activeTabElement.children[0].classList.remove('modal-open');
          }
        }
      }
    }
  }, [open, openModal]);

  const actionContent = actions !== undefined ? actions : (
    <Button
      primary
      className="blue"
      content="Ok"
      onClick={triggerCloseEvent}
    />
  );

  return (
    <Dimmer.Dimmable as={Segment} className="contentModal" dimmed={openModal}>
      <Dimmer active={openModal}>
        <div ref={contextRef} className="anchor" />
        <Segment
          className={`ui popup contentModal ${className}`}
          wide="very"
          content={(
            <Container fluid className="popup-container">
              <Header className={`${style} contentModalHeader`}>
                {icon && <Icon name={icon} />}
                {header}
              </Header>
              <div className="content">
                <div className="description">{message}</div>
              </div>
              <div className="actions">
                {actionContent}
              </div>
            </Container>
            )}
        />
      </Dimmer>
    </Dimmer.Dimmable>
  );
};
ContentModal.defaultProps = {
  closeEvent: undefined,
  icon: undefined,
  className: '',
  actions: undefined,
};

export default ContentModal;
