import React, { useState, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import {
  Card, CardContent, Popup, SemanticWIDTHS,
} from 'semantic-ui-react';
import { stageUserPreferences } from 'app/actions/preferenceActions';
import _ from 'lodash';

import { ValidPreferences } from 'app/typings';
import ThemePreview from './ThemePreview';

interface ThemePickerProps {
  itemsPerRow? : SemanticWIDTHS,
  useStagedTheme? : boolean,
  updatePreferences?: (data:ValidPreferences) => void
}

const ThemePicker: FunctionComponent<ThemePickerProps> = ({
  itemsPerRow,
  useStagedTheme,
  updatePreferences: customUpdatePreferences,
}: ThemePickerProps) => {
  const dispatch = useDispatch();
  const preferencesState = useSelector((state: State) => state.preferences);
  const [preferences, setPreferences] = useState(
    (useStagedTheme && preferencesState.staged !== undefined) ? preferencesState.staged : preferencesState.data,
  );

  const availableThemesList = [
    { name: 'System Default', description: 'Selects either light or dark theme based on computer and browser settings.' },
    { name: 'Light', description: 'A brilliant theme for those who need more light in their life.' },
    { name: 'Dark', description: 'You merely adopted the dark. I was born in it, molded by it.' },
    { name: 'Hotdog', description: "Everyone's favorite win 3.1 theme, served up hot and fresh." },
    { name: 'Forest', description: 'Cancelled a hiking trip due to a sev 1? Well, this will probably just make you feel worse about that.' },
    { name: 'Synthwave', description: "Experience humankind's peak of style; everything else has been a mistake." },
    { name: 'Solarized-Light', description: 'One of the most popular color schemes available today. Thanks for saving our eyes Ethan Schoonover.' },
    { name: 'Solarized-Dark', description: 'The dark side of the Solarized is a pathway to many abilities some consider to be unnatural.' }];

  const {
    activeTheme,
  } = preferences;

  const updatePreferences = (data: ValidPreferences) => {
    const updated = { ...preferences, ...data };
    setPreferences(updated); //* Set in state locally
    if (typeof customUpdatePreferences === 'function') {
      customUpdatePreferences(data);
    } else {
      dispatch(stageUserPreferences(updated));
    }
  };

  return (
    <Card.Group itemsPerRow={itemsPerRow}>
      { _.map(availableThemesList, (op) => (

        <Popup
          key={op.name}
          trigger={(
            <Card
              color={activeTheme.toLowerCase() === op.name.toLowerCase().replace(' ', '-') ? 'teal' : undefined}
              onClick={() => updatePreferences({ activeTheme: op.name.toLowerCase().replace(' ', '-') } as ValidPreferences)}
            >
              <CardContent>
                <ThemePreview theme={op.name.toLowerCase()} />
              </CardContent>
              <CardContent extra>
                {op.name}
              </CardContent>
            </Card>
            )}
          position="left center"
          content={op.description}
        />
      )) }
    </Card.Group>
  );
};

ThemePicker.defaultProps = {
  itemsPerRow: 4,
  useStagedTheme: false,
  updatePreferences: undefined,
};

export default ThemePicker;
