// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString } from '../models/CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString';
import { DSExplorerWebHostModelsBase64Model } from '../models/DSExplorerWebHostModelsBase64Model';
import { DSExplorerWebHostModelsDateTimeModel } from '../models/DSExplorerWebHostModelsDateTimeModel';
import { DSExplorerWebHostModelsGuidModel } from '../models/DSExplorerWebHostModelsGuidModel';
import { DSExplorerWebHostModelsHexModel } from '../models/DSExplorerWebHostModelsHexModel';
import { DSExplorerWebHostModelsNetIdModel } from '../models/DSExplorerWebHostModelsNetIdModel';
import { DSExplorerWebHostModelsSidModel } from '../models/DSExplorerWebHostModelsSidModel';

/**
 * no description
 */
export class ToolboxApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param dSExplorerWebHostModelsBase64Model 
     */
    public async toolboxBase64Converter(dSExplorerWebHostModelsBase64Model: DSExplorerWebHostModelsBase64Model, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsBase64Model' is not null or undefined
        if (dSExplorerWebHostModelsBase64Model === null || dSExplorerWebHostModelsBase64Model === undefined) {
            throw new RequiredError("ToolboxApi", "toolboxBase64Converter", "dSExplorerWebHostModelsBase64Model");
        }


        // Path Params
        const localVarPath = '/public/Toolbox/Base64Converter';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsBase64Model, "DSExplorerWebHostModelsBase64Model", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsDateTimeModel 
     */
    public async toolboxDateTimeConverter(dSExplorerWebHostModelsDateTimeModel: DSExplorerWebHostModelsDateTimeModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsDateTimeModel' is not null or undefined
        if (dSExplorerWebHostModelsDateTimeModel === null || dSExplorerWebHostModelsDateTimeModel === undefined) {
            throw new RequiredError("ToolboxApi", "toolboxDateTimeConverter", "dSExplorerWebHostModelsDateTimeModel");
        }


        // Path Params
        const localVarPath = '/public/Toolbox/DatetimeConverter';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsDateTimeModel, "DSExplorerWebHostModelsDateTimeModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsGuidModel 
     */
    public async toolboxGuidConverter(dSExplorerWebHostModelsGuidModel: DSExplorerWebHostModelsGuidModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsGuidModel' is not null or undefined
        if (dSExplorerWebHostModelsGuidModel === null || dSExplorerWebHostModelsGuidModel === undefined) {
            throw new RequiredError("ToolboxApi", "toolboxGuidConverter", "dSExplorerWebHostModelsGuidModel");
        }


        // Path Params
        const localVarPath = '/public/Toolbox/GuidConverter';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsGuidModel, "DSExplorerWebHostModelsGuidModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsHexModel 
     */
    public async toolboxHexConverter(dSExplorerWebHostModelsHexModel: DSExplorerWebHostModelsHexModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsHexModel' is not null or undefined
        if (dSExplorerWebHostModelsHexModel === null || dSExplorerWebHostModelsHexModel === undefined) {
            throw new RequiredError("ToolboxApi", "toolboxHexConverter", "dSExplorerWebHostModelsHexModel");
        }


        // Path Params
        const localVarPath = '/public/Toolbox/HexConverter';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsHexModel, "DSExplorerWebHostModelsHexModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsNetIdModel 
     */
    public async toolboxNetIdConverter(dSExplorerWebHostModelsNetIdModel: DSExplorerWebHostModelsNetIdModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsNetIdModel' is not null or undefined
        if (dSExplorerWebHostModelsNetIdModel === null || dSExplorerWebHostModelsNetIdModel === undefined) {
            throw new RequiredError("ToolboxApi", "toolboxNetIdConverter", "dSExplorerWebHostModelsNetIdModel");
        }


        // Path Params
        const localVarPath = '/public/Toolbox/NetIdConverter';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsNetIdModel, "DSExplorerWebHostModelsNetIdModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsSidModel 
     */
    public async toolboxSidConverter(dSExplorerWebHostModelsSidModel: DSExplorerWebHostModelsSidModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsSidModel' is not null or undefined
        if (dSExplorerWebHostModelsSidModel === null || dSExplorerWebHostModelsSidModel === undefined) {
            throw new RequiredError("ToolboxApi", "toolboxSidConverter", "dSExplorerWebHostModelsSidModel");
        }


        // Path Params
        const localVarPath = '/public/Toolbox/SidConverter';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsSidModel, "DSExplorerWebHostModelsSidModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class ToolboxApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to toolboxBase64Converter
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async toolboxBase64ConverterWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to toolboxDateTimeConverter
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async toolboxDateTimeConverterWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to toolboxGuidConverter
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async toolboxGuidConverterWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to toolboxHexConverter
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async toolboxHexConverterWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to toolboxNetIdConverter
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async toolboxNetIdConverterWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to toolboxSidConverter
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async toolboxSidConverterWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString", ""
            ) as CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
