import React, { FunctionComponent, useState } from 'react';
import {
  Button, Checkbox, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface ALOSearchForm {
  proxyAddressFilterString : string,
  sourceAnchorFilterString : string,
  exchangeSingleAuthorityOnly : boolean,
  softDeleted: boolean
}

export const defaultAddressListObjectsForm = {
  proxyAddressFilterString: '', sourceAnchorFilterString: '', exchangeSingleAuthorityOnly: false, softDeleted: false,
};

const AddressListObjectsSB: FunctionComponent<ObjectTypeProps<ALOSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ALOSearchForm>) => {
  const [searchForm, setSearchForm] = useState<ALOSearchForm>(defaultAddressListObjectsForm);

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          ProxyAddress
          <Popup
            flowing
            position="bottom left"
            content="Enter a ProxyAddress of the address list object (Contact, Group or User) you are looking for (e.g. 'smtp:JohnDoe@contoso.com')"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.proxyAddressFilterString}
                className="margin-bottom"
                fluid
                placeholder="Search ProxyAddress"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, proxyAddressFilterString: value })}
              />
                      )}
          />
        </label>
        <label className="label">
          SourceAnchor
          <Popup
            flowing
            position="bottom left"
            content="Enter a SourceAnchor of the address list object (Contact, Group or User) you are looking for"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.sourceAnchorFilterString}
                className="margin-bottom"
                fluid
                placeholder="Search SourceAnchor"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, sourceAnchorFilterString: value })}
              />
                      )}
          />
        </label>
        <Popup
          flowing
          position="bottom left"
          content={'If selected, the search will be scoped to objects '
            + 'where EXO is the single authority for one or more properties or forward links'}
          trigger={(
            <Checkbox
              disabled={disabled}
              className="margin-bottom"
              toggle
              label="Exchange Single Authority Only"
              checked={searchForm.exchangeSingleAuthorityOnly}
              onChange={(e, { checked }) => setSearchForm({
                ...searchForm,
                exchangeSingleAuthorityOnly: checked as boolean,
              })}
            />
                    )}
        />
        <Popup
          flowing
          position="bottom left"
          content="If selected, the search will be scoped to the soft deleted object container (aka Recycle Bin)"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="last-form-item"
              toggle
              label="Soft Deleted"
              checked={searchForm.softDeleted}
              onChange={(e, { checked }) => setSearchForm({ ...searchForm, softDeleted: checked as boolean })}
            />
                    )}
        />
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm(defaultAddressListObjectsForm)}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default AddressListObjectsSB;
