import { SearchFilterOperator } from 'app/proxyClients';
import { ObjectsSearchForm } from 'app/typings';
import { DropdownItemProps } from 'semantic-ui-react';
import {
  SystemCategories,
  CompanyCategories,
  operands,
  PossibleCategories,
} from './ObjectsSBConstants';

function isSystemCategory(
  location: '/company' | '/system',
  value: PossibleCategories,
): value is keyof typeof SystemCategories {
  return location === '/system';
}

export function isValidLocation(value: string) {
  if (value === '/company' || value === '/system') {
    return value;
  }
  throw new Error('Unknown location');
}

export function setPropertiesDropdown(location: '/company' | '/system') {
  return Object.entries(location === '/company' ? CompanyCategories : SystemCategories).map(([key], index) => ({
    key: index,
    text: key,
    value: key,
  }));
}

export function setInitialProperties(location: '/company' | '/system') {
  const initProps = location === '/company'
    ? CompanyCategories.Account.map((value, index) => ({
      key: index,
      text: value,
      value,
    }))
    : SystemCategories.Datacenter.map((value, index) => ({
      key: index,
      text: value,
      value,
    }));
  return initProps;
}

export function setInitialDropdowns(
  initProperties: DropdownItemProps[],
  location: '/company' | '/system',
) {
  const values = location === '/company'
    ? {
      Account: initProperties,
      '': [],
    }
    : {
      Datacenter: initProperties,
      '': [],
    };
  return values;
}
export function setInitialSearchFrom(
  location: '/company' | '/system',
  pageSize: number,
): ObjectsSearchForm {
  if (location === '/company') {
    return {
      properties: [],
      resultsLimit: pageSize,
      Category: 'Account',
      filter: [
        {
          property: Object.values(CompanyCategories)[0][0],
          comparisonOperator: operands[4],
          propertyValueString: '',
          conditionalOperator: SearchFilterOperator.None,
        },
      ],
      softDeleted: false,
      objectClass: 1,
    };
  }
  return {
    companyIdentifier: '00000000-0000-0000-0000-000000000000',
    properties: [],
    resultsLimit: pageSize,
    Category: 'Datacenter',
    filter: [
      {
        property: Object.values(SystemCategories)[0][0],
        comparisonOperator: operands[4],
        propertyValueString: '',
        conditionalOperator: SearchFilterOperator.None,
      },
    ],
    softDeleted: false,
    objectClass: 21,
  };
}
export function getCategoryArray(
  value: PossibleCategories | '',
  location: '/company' | '/system',
) {
  if (value === '') return [];
  if (isSystemCategory(location, value)) {
    return SystemCategories[`${value}`];
  }
  return CompanyCategories[`${value}`];
}

export function isSearchFormEmpty(
  value: ObjectsSearchForm,
  index: number,
): boolean {
  if (value.Category === '') {
    return true;
  }
  return value.filter.some(
    (details, i) => i < index && (details.property === '' || (details.propertyValueString === '' && details.comparisonOperator !== operands[4])),
  );
}
