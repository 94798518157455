// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { MicrosoftOnlineDSExplorerContractAltSecIdType } from '../models/MicrosoftOnlineDSExplorerContractAltSecIdType';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject';

/**
 * no description
 */
export class IndexStoreApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param applicationId 
     */
    public async indexStoreGetApplicationStub(applicationId: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'applicationId' is not null or undefined
        if (applicationId === null || applicationId === undefined) {
            throw new RequiredError("IndexStoreApi", "indexStoreGetApplicationStub", "applicationId");
        }


        // Path Params
        const localVarPath = '/api/IndexStore/GetApplicationStub/{applicationId}'
            .replace('{' + 'applicationId' + '}', encodeURIComponent(String(applicationId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param issuerUri 
     */
    public async indexStoreGetTrustedRealmStub(issuerUri?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;


        // Path Params
        const localVarPath = '/api/IndexStore/GetTrustedRealmStub';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (issuerUri !== undefined) {
            requestContext.setQueryParam("issuerUri", ObjectSerializer.serialize(issuerUri, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async indexStoreSearchAltSecIdStore(altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;







        // Path Params
        const localVarPath = '/api/IndexStore/SearchAltSecIdStore';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (altSecIdType !== undefined) {
            requestContext.setQueryParam("altSecIdType", ObjectSerializer.serialize(altSecIdType, "MicrosoftOnlineDSExplorerContractAltSecIdType", ""));
        }

        // Query Params
        if (altSecIdKey !== undefined) {
            requestContext.setQueryParam("altSecIdKey", ObjectSerializer.serialize(altSecIdKey, "string", ""));
        }

        // Query Params
        if (altSecIdProvider !== undefined) {
            requestContext.setQueryParam("altSecIdProvider", ObjectSerializer.serialize(altSecIdProvider, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class IndexStoreApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to indexStoreGetApplicationStub
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async indexStoreGetApplicationStubWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to indexStoreGetTrustedRealmStub
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async indexStoreGetTrustedRealmStubWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to indexStoreSearchAltSecIdStore
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async indexStoreSearchAltSecIdStoreWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
