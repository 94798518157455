import { Action } from 'redux';
import * as SystemTypes from 'app/actions/systemTypes';

export type SystemState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  systems: Array<any>
  error: boolean
  message: string
  loading: boolean
};

const state: SystemState = {
  systems: [],
  error: false,
  message: '',
  loading: false,
};

type SystemActions =
  GetSystemSettings
  | GetSystemSettingsCompletedAction
  | GetSystemSettingsFailed
  | GetSystemExport
  | GetSystemExportCompletedAction
  | GetSystemExportFailed;

type GetSystemSettings = Action<typeof SystemTypes.GET_SYSTEM_SETTINGS>;
type GetSystemSettingsCompletedAction = Action<typeof SystemTypes.GET_SYSTEM_SETTINGS_COMPLETED>;
type GetSystemSettingsFailed = Action<typeof SystemTypes.GET_SYSTEM_SETTINGS_FAILED> & { ex: { message: string } };
type GetSystemExport = Action<typeof SystemTypes.GET_SYSTEM_CONTEXT_EXPORT>;
type GetSystemExportCompletedAction = Action<typeof SystemTypes.GET_SYSTEM_CONTEXT_EXPORT_COMPLETED>;
type GetSystemExportFailed = Action<typeof SystemTypes.GET_SYSTEM_CONTEXT_EXPORT_FAILED> & { ex: { message: string } };

export default (systemState: SystemState = state, action: SystemActions): SystemState => {
  switch (action.type) {
    case SystemTypes.GET_SYSTEM_SETTINGS:
      return {
        ...systemState, error: false, message: '', loading: true,
      };
    case SystemTypes.GET_SYSTEM_SETTINGS_COMPLETED:
      return {
        ...systemState, error: false, message: '', loading: false,
      };
    case SystemTypes.GET_SYSTEM_SETTINGS_FAILED:
      return {
        ...systemState, error: true, message: action.ex.message, loading: false,
      };
    case SystemTypes.GET_SYSTEM_CONTEXT_EXPORT:
      return {
        ...systemState, error: false, message: '', loading: true,
      };
    case SystemTypes.GET_SYSTEM_CONTEXT_EXPORT_COMPLETED:
      return {
        ...systemState, error: false, message: '', loading: false,
      };
    case SystemTypes.GET_SYSTEM_CONTEXT_EXPORT_FAILED:
      return {
        ...systemState, error: true, message: action.ex.message, loading: false,
      };
    default:
      return systemState;
  }
};
