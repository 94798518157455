import { enumToDropDownList } from 'app/helpers/helpers';
import { DirSyncProvisioningErrorCategory, DirSyncSupportedDirectoryProperties } from 'app/helpers/types';
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Dropdown, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface DirSyncErrorsSearchForm {
  errorCategory: DirSyncProvisioningErrorCategory,
  propertyNameFilterValue: DirSyncSupportedDirectoryProperties | undefined,
  propertyValueFilterString: string
}

const DirSyncErrorsSB: FunctionComponent<ObjectTypeProps<DirSyncErrorsSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<DirSyncErrorsSearchForm>) => {
  const [searchForm, setSearchForm] = useState<DirSyncErrorsSearchForm>({
    errorCategory: 0, propertyNameFilterValue: undefined, propertyValueFilterString: '',
  });

  const id = _.uniqueId('DirSyncErrorsSB-');
  const ErrorCategoryDropdownId = `${id}-ErrorCategoryDropdown`;
  const PropertyDropdownId = `${id}-PropertyDropdown`;

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label" htmlFor={ErrorCategoryDropdownId}>Error Category</label>
        <Dropdown
          fluid
          selection
          disabled={disabled}
          className="margin-bottom"
          id={ErrorCategoryDropdownId}
          options={enumToDropDownList(DirSyncProvisioningErrorCategory)}
          value={searchForm.errorCategory}
          onChange={(e, { value }) => setSearchForm({ ...searchForm, errorCategory: value as number })}
        />
        <label className="label" htmlFor={PropertyDropdownId}>Property</label>
        <Dropdown
          fluid
          selection
          clearable
          disabled={disabled}
          className="margin-bottom"
          id={PropertyDropdownId}
          placeholder="Select Property"
          options={enumToDropDownList(DirSyncSupportedDirectoryProperties)}
          value={searchForm.propertyNameFilterValue as number}
          onChange={(e, { value }) => setSearchForm({ ...searchForm, propertyNameFilterValue: value as number })}
        />
        <label className="label">
          Value
          <Popup
            flowing
            position="bottom left"
            content="Property value to filter on"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.propertyValueFilterString}
                className="last-form-item"
                fluid
                placeholder="Property value to filter on"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, propertyValueFilterString: value })}
              />
                      )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ errorCategory: 0, propertyNameFilterValue: undefined, propertyValueFilterString: '' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default DirSyncErrorsSB;
