import TimeFormat, { TimeFormatOptions, TimeFormatProps } from 'app/components/shared/Utilities/TimeFormat';
import {
  DSObject,
  DSObjectDirectoryProperty,
  DSObjectMetadataProperty,
  DSReferenceSet,
  GenericTreeNodeItemModel,
  ProvisioningStatus,
  TreeNodeModel,
} from 'app/helpers/types';
import { DSObjectMomentProperty, FormattedSearchData, SearchResults } from 'app/typings';
import _, { Dictionary } from 'lodash';
import moment from 'moment';
import React from 'react';

export const timeColumns = ['CreationTime', 'CompanyLastDirSyncTime', 'CreatedOn', 'ExpirationDate', 'LastPasswordChangeTimestamp',
  'StsRefreshTokensValidFrom', 'ShadowLastPasswordChangeTimestamp', 'ApproximateLastLogonTimestamp', 'LastDirSyncTime',
  '_WhenCreated', '_WhenChanged', 'FailoverStarted', 'FailoverEnded', 'WhenCreated', 'WhenChanged', 'Last Write Time',
  'Originating Timestamp', 'Start Date', 'Next Lifecycle Date', '[A] Assigned On', '[P] Assigned On', '[P] Provisioned On', '[P] Received On'];

type TProperties = (DSObjectMetadataProperty | DSObjectDirectoryProperty | DSObjectMomentProperty)[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lowercaseKeys = (obj: any) => Object.keys(obj).reduce((acc: any, key: any) => {
  acc[key.charAt(0).toLowerCase() + key.slice(1)] = obj[`${key}`];
  return acc;
}, {});

function formatNumber(val: string) {
  return _.parseInt(val, 10);
}

export function formatTimeOutput({
  time, timeFormat, highlight, highlightColor, timeUtc,
}: TimeFormatProps) {
  if (!time) {
    return undefined;
  }

  if (typeof (time) === 'string') {
    // eslint-disable-next-line no-console
    console.warn('Passed a string into the formatTimeOutput function -- Was expecting a moment object', time);
  }

  if (moment.isDate(time)) {
    time = moment(time);
  }

  if (!moment.isMoment(time)) {
    return undefined;
  }

  const columnOutputTime = time.unix() !== -62135579038 ? time : undefined;
  if (columnOutputTime) {
    return (
      <TimeFormat key={0} time={columnOutputTime} timeFormat={timeFormat} highlight={highlight} highlightColor={highlightColor} timeUtc={timeUtc} />
    );
  }

  return undefined;
}

function formatData(data: TProperties, timeFormatOptions: TimeFormatOptions) {
  return data.map((row) => {
    const formattedRow = {
      Name: row.displayName || '',
      '#': row.values?.length,
      'Value(s)': row.values || [],
      Version: (row as DSObjectDirectoryProperty).version,
      'Last Write Time': formatTimeOutput({ time: (row as DSObjectDirectoryProperty).lastWriteTime, ...timeFormatOptions }),
      'USN Local': (row as DSObjectDirectoryProperty).localUpdateSequenceNumber,
      'USN Orig': (row as DSObjectDirectoryProperty).originatingUpdateSequenceNumber,
      'Originating Timestamp': formatTimeOutput({ time: (row as DSObjectDirectoryProperty).originatingTimestamp, ...timeFormatOptions }),
    };

    if (timeColumns.includes(row.displayName || '')) {
      _.assign(formattedRow, {
        __moment: formatTimeOutput({ time: (row as DSObjectMomentProperty).moment, ...timeFormatOptions }),
      });
    }

    return formattedRow;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatSingleObject(data: DSObject, timeFormatOptions: TimeFormatOptions): any {
  const lowerCaseData = lowercaseKeys(data);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lowerCaseData.directoryProperties = lowerCaseData.directoryProperties.map((dprop: any) => {
    if (timeColumns.includes(dprop.displayName)) {
      return { ...dprop, moment: moment(dprop.values[0]), values: [formatTimeOutput({ time: moment(dprop.values[0]), ...timeFormatOptions })] };
    }
    return lowercaseKeys(dprop);
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lowerCaseData.metadataProperties = lowerCaseData.metadataProperties.map((mprop: any) => {
    if (timeColumns.includes(mprop.displayName)) {
      return { ...mprop, moment: moment(mprop.values[0]), values: [formatTimeOutput({ time: moment(mprop.values[0]), ...timeFormatOptions })] };
    }
    return lowercaseKeys(mprop);
  });

  const allData: TProperties = ([] as TProperties)
    .concat((lowerCaseData?.metadataProperties || []))
    .concat((lowerCaseData?.directoryProperties || []));

  return formatData(allData, timeFormatOptions);
}

function formatPropBagValues(displayName: string, values: string[], timeFormatOptions: TimeFormatOptions) {
  if (timeColumns.includes(displayName)) {
    return formatTimeOutput({ time: moment(values[0]), ...timeFormatOptions });
  }
  return values.join(',');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatPropBagForTable(data: any[], timeFormatOptions: TimeFormatOptions) {
  const tableData = data.map((row) => ({
    Name: row.item1 ? row.item1 : row.displayName,
    '#': row.item2 ? row.item2.length : row.values.length,
    'Value(s)': row.item2 ? row.item2 : formatPropBagValues(row.displayName, row.values, timeFormatOptions),
  }));
  return tableData;
}

export function formateReferenceSet(data: DSReferenceSet[]) {
  const lowerCaseRes = data.map((row) => lowercaseKeys(row));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableData: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lowerCaseRes.forEach((referenceSet) => referenceSet.targets && referenceSet.targets.forEach((target: any) => {
    const t = lowercaseKeys(target);
    tableData.push({
      'Source Object ID': referenceSet.source?.objectId || referenceSet.source?.ObjectId,
      'Target Object ID': t.objectId,
      'Target Prop Bag': t,
    });
  }));
  return tableData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatProvStatus(data: any[], timeFormatOptions: TimeFormatOptions) {
  return data.map((row) => ({
    'A/P': row.apCapabilityStatus,
    'Service Plan Name': row.servicePlanName,
    IP: row.IsImplicitlyProvisioned ? 'T' : 'F',
    'Service Instance': row.assignedPlan?.serviceInstance,
    Units: formatNumber(row.subscribedPlanProperties.PrepaidUnits),
    Trial: row.subscribedPlanProperties.TotalTrialUnitsDetail.split(',')[0],
    '[A] Assigned On': formatTimeOutput({ time: row.assignedPlan?.assignedTimestamp, ...timeFormatOptions }),
    '[P] Assigned On': row.provisionedPlan ? formatTimeOutput({ time: row.provisionedPlan.assignedTimestamp, ...timeFormatOptions }) : '',
    '[P] Provisioned On': row.provisionedPlan ? formatTimeOutput({ time: row.provisionedPlan.provisionedTimestamp, ...timeFormatOptions }) : '',
    '[P] Received On': row.provisionedPlan ? formatTimeOutput({ time: row.provisionedPlan.receivedTimestamp, ...timeFormatOptions }) : '',
    '[P] Status': row.provisionedPlan ? ProvisioningStatus[row.provisionedPlan.provisioningStatus] : '',
    '[P] Error Detail': row.provisionedPlan
      && row.provisionedPlan.errorDetail && typeof row.provisionedPlan.errorDetail === 'string' ? row.provisionedPlan.errorDetail : '',
  }));
}

export const formatSearchData = (
  results: SearchResults | undefined,
  timeFormatOptions: TimeFormatOptions,
  extraProps?: string[][],
  rowFunc?: (row: DSObject, data: Dictionary<unknown>) => void,
): FormattedSearchData[] => {
  if (
    results === undefined
    || results.searchData === undefined
    || results.searchData.data === undefined
    || results.searchData.data === null
  ) {
    return [];
  }

  const lowerCaseRes = results.searchData.data.map((row) => lowercaseKeys(row));

  return lowerCaseRes.map((row) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values = row.directoryProperties?.map((d: any) => {
      const column = _.trimStart(d.displayName || d.DisplayName, '_');
      let data = _.join(d.values || d.Values, ' ');

      if (Array.isArray(d.values) && d.values.length === 1) {
        [data] = d.values;
      }

      if (Array.isArray(d.Values) && d.Values.length === 1) {
        [data] = d.Values;
      }

      return [
        column,
        data,
      ];
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const metadata = row.metadataProperties?.map((d: any) => [
      _.trimStart(d.displayName || d.DisplayName, '_'),
      _.join(d.values || d.Values, ' '),
    ]);

    const sp = { searchParams: results.params };
    const data = _.fromPairs(_.union(values, metadata, extraProps));

    if (typeof rowFunc === 'function') {
      rowFunc(row, data);
    }

    _.keys(data).forEach((col: string) => {
      if (timeColumns.includes(col)) {
        data[`${col}`] = formatTimeOutput({ time: moment(_.get(data, col)), ...timeFormatOptions });
      }
    });

    return _.assign({ DisplayName: row.displayName ? row.displayName : '' }, data, sp) as FormattedSearchData;
  });
};

export const formatCompanyData = (
  results: SearchResults | undefined,
  timeFormatOptions: TimeFormatOptions,
  extraProps: string[][] | undefined,
): FormattedSearchData[] => formatSearchData(
  results,
  timeFormatOptions,
  extraProps,
  (row, data) => {
    // Add ContextId
    // eslint-disable-next-line no-param-reassign
    data.ContextId = row.contextId;
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function recursiveSearch(data: GenericTreeNodeItemModel | TreeNodeModel, searchval: string): any {
  if (data.displayName === searchval) {
    return true;
  } if (data.children) {
    for (let i = 0; i < data.children.length; i += 1) {
      const currentChild = data.children[+`${i}`];
      const result = recursiveSearch(currentChild, searchval);
      if (result !== false) {
        return result;
      }
    }
    return false;
  }
  return false;
}

export function clientSideFilterTree(data: GenericTreeNodeItemModel[], contains: string) {
  if (!Array.isArray(data)) {
    return [];
  }
  const filteredData: GenericTreeNodeItemModel[] = [];
  data.forEach((item) => {
    if (recursiveSearch(item, contains)) {
      filteredData.push(item);
    }
  });
  return contains === undefined ? data : filteredData;
}
