import { StatusMessageDataModel } from 'app/helpers/types';
import type { App } from 'app/store';

export async function getAnnouncements(app: App, includeAcknowledged: boolean) {
  return app.clients.announcements.announcementsGetStatus(includeAcknowledged);
}

export async function addAnnouncement(app: App, p: StatusMessageDataModel) {
  return app.clients.announcements.announcementsAddStatus(p);
}

export async function updateAnnouncement(app: App, p: StatusMessageDataModel) {
  return app.clients.announcements.announcementsUpdateStatusMessage(p);
}

export async function acknowledgeAnnouncement(app: App, messageId: string) {
  return app.clients.announcements.announcementsAcknowledge(messageId);
}
