import { HttpLibrary, RequestContext, ResponseContext } from 'app/client';
import { from, Observable } from 'app/client/rxjsStub';
import { AxiosInstance, AxiosResponse } from 'axios';

/**
 * Derived from client\http\isomorphic-fetch.ts
 * but using Axios instead of fetch()
 * because we were already using Axios.
*/
export class AxiosHttpLibrary implements HttpLibrary {
  public constructor(protected axios: AxiosInstance) {
    this.axios = axios;
  }

  public send(request: RequestContext): Observable<ResponseContext> {
    const method = request.getHttpMethod().toString();
    const body = request.getBody();

    const resultPromise = this.axios.request({
      url: request.getUrl(),
      method,
      headers: request.getHeaders(),
      data: body,
    }).then((resp: AxiosResponse) => {
      const headers: { [name: string]: string } = {};
      for (const k in resp.headers) {
        if (resp.headers.hasOwnProperty(k)) {
          // eslint-disable-next-line security/detect-object-injection
          headers[k] = resp.headers[k];
        }
      }

      const responseBody = {
        // axios already converted the response to JSON,
        // but the openapi code doesn't know that, so we re-reserialize
        text: () => Promise.resolve(JSON.stringify(resp.data)),
        binary: () => Promise.resolve(new Blob([resp.data])),
      };

      return new ResponseContext(resp.status, headers, responseBody);
    });

    return from<Promise<ResponseContext>>(resultPromise);
  }
}