import './CompanySidebar.scss';

import { getCompanyByName, updateSearchCompanies } from 'app/actions/companyActions';
import { activateTab } from 'app/actions/tabActions';
import InputWithEnter from 'app/components/shared/InputWithEnter/InputWithEnter';
import AddressListObjectsSB, {
  ALOSearchForm,
} from 'app/components/shared/sidebar-children/AddressListObjectsSB/AddressListObjectsSB';
import ApplicationSB, { ApplicationsSearchForm } from 'app/components/shared/sidebar-children/ApplicationsSB/ApplicationsSB';
import ContractsSB, { ContractsSearchForm } from 'app/components/shared/sidebar-children/ContractsSB/ContractsSB';
import DelegationEntriesSB, {
  DelegationEntriesSearchForm,
} from 'app/components/shared/sidebar-children/DelegationEntriesSB/DelegationEntriesSB';
import DeletedSB from 'app/components/shared/sidebar-children/DeletedSB/DeletedSB';
import DeviceSB, { DeviceSearchForm } from 'app/components/shared/sidebar-children/DeviceSB/DeviceSB';
import DirSyncErrorsSB, {
  DirSyncErrorsSearchForm,
} from 'app/components/shared/sidebar-children/DirSyncErrorsSB/DirSyncErrorsSB';
import EntitlementGrantsSB, {
  EntitlementGrantsSearchForm,
} from 'app/components/shared/sidebar-children/EntitlementGrantsSB/EntitlementGrantsSB';
import GroupLicensingErrorsSB, {
  GroupLicensingErrorsSearchForm,
} from 'app/components/shared/sidebar-children/GroupLicensingErrorsSB/GroupLicensingErrorsSB';
import GroupsSB, { GroupsSearchForm } from 'app/components/shared/sidebar-children/GroupsSB/GroupsSB';
import KeyGroupsSB, { KeyGroupsSearchForm } from 'app/components/shared/sidebar-children/KeyGroupsSB/KeyGroupsSB';
import LicensesSB from 'app/components/shared/sidebar-children/LicensesSB/LicensesSB';
import ObjectsSb from 'app/components/shared/sidebar-children/ObjectsSB/ObjectsSB';
import RoleAssignmentsSB, {
  RoleAssignmentsSearchForm,
} from 'app/components/shared/sidebar-children/RoleAssignmentsSB/RoleAssignmentsSB';
import ScopedMembersSB, {
  ScopedMembersSearchForm,
} from 'app/components/shared/sidebar-children/ScopedMembersSB/ScopedMembersSB';
import ServicePrincipalsSB, {
  ServicePrincipalsForm,
} from 'app/components/shared/sidebar-children/ServicePrincipalsSB/ServicePrincipalsSB';
import SingletObjectsSB, { SingleObjectForm } from 'app/components/shared/sidebar-children/SingleObjectSB/SingleObjectSB';
import SingleUserSB, { SingleUserForm } from 'app/components/shared/sidebar-children/SingleUser/SingleUser';
import TrustedRealmSB, {
  TrustedRealmSearchForm,
} from 'app/components/shared/sidebar-children/TrustedRealmSB/TrustedRealmSB';
import UsersIndexSB, { UsersSearchForm } from 'app/components/shared/sidebar-children/UsersSB/UserIndexSB';
import { generateId, unsafeToNumber } from 'app/helpers/helpers';
import { State } from 'app/reducers/state';
import { DeletedSearchForm, ObjectsSearchForm, TabType } from 'app/typings';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox, Divider, Dropdown, Icon, Input, List, Popup,
} from 'semantic-ui-react';

import { getDefaultObjectCategories } from 'app/components/shared/sidebar-children/ObjectsSB/ObjectsSBConstants';
import { setInitialSearchFrom } from 'app/components/shared/sidebar-children/ObjectsSB/ObjectsSBHelpers';
import { CompanyContextObjectClasses, DirectoryObjectClass } from 'app/helpers/types';
import { defaultSearches, ObjectTypeComponentKeys, ObjectTypes } from './CompanySidebarOptions';

export interface ObjectTypeProps<T = unknown> {
  disabled: boolean, // If form needs to be disabled
  searchEvent: (data: T) => void // The form output
}

type SearchForm = {
  companyIdentifier: string,
  softDeleted?: boolean,
  contextEpoch?: number,
  partitionId?: number
};

const CompanySidebar = () => {
  const dispatch = useDispatch();
  const companiesState = useSelector((state: State) => state.companies);
  const tabsState = useSelector((state: State) => state.tabs);
  const activeTabState = useSelector((state: State) => state.activeTabs);
  const preferencesState = useSelector((state: State) => state.preferences);
  const [objectType, setObjectType] = useState<ObjectTypeComponentKeys>('');
  const [loading, setLoading] = useState<boolean | undefined>(false);
  const autoLoadedComponents = ['Address List Objects', 'Applications', 'Delegation Entries', 'Devices',
    'Groups', 'Key Groups', 'Licenses', 'Service Principals', 'Users', 'Provisioning Status'];
  const emptyFormState = {
    companyIdentifier: '', softDeleted: false, contextEpoch: undefined, partitionId: undefined,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [primaryTabs, setPrimaryTabs] = useState<any>([]);
  const [activePrimaryTab, setActivePrimaryTabs] = useState<string | undefined>();
  const [searchForm, setSearchForm] = useState<SearchForm>(emptyFormState);
  const [previousSearchForm, setPreviousSearchForm] = useState<SearchForm>(emptyFormState);
  const [openedIndex, setOpenedIndex] = useState(preferencesState.data.searchOpenByDefault === true ? 1 : 0);
  const [hideEmptyObjects, setHideEmptyObjects] = useState(preferencesState.data.hideEmptyBrowseObjects);

  useEffect(() => {
    if (companiesState?.error === true) {
      setSearchForm(previousSearchForm);
    }

    setLoading(companiesState?.loading);
  }, [companiesState]);

  useEffect(() => {
    setActivePrimaryTabs(activeTabState.getActiveTab(TabType.Company) ?? undefined);
  }, [activeTabState.getActiveTab(TabType.Company)]);

  useEffect(() => {
    if (openedIndex === 0) setObjectType('Objects' as ObjectTypeComponentKeys);
  }, [openedIndex]);

  useEffect(() => { // When Company Tab state changes
    const tabsWithNoErrors = tabsState?.getTabs(TabType.Company).filter((tab) => tab.name !== 'Error'); // Remove error tabs
    const primeTabs = tabsWithNoErrors?.map((tab, i) => ({ // Format primary tab dropdown options
      key: i,
      text: tab.name,
      value: tab.id,
    }));
    setPrimaryTabs(primeTabs); // Set primary tab dropdown options
    setActivePrimaryTabs(activeTabState.getActiveTab(TabType.Company) ?? undefined); // Set active tab (to latest)
  }, [tabsState?.getTabs(TabType.Company)]);

  function handleClicked(thisIndex : number) {
    if (openedIndex === thisIndex) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(thisIndex);
    }
  }

  const disableCategorySelect = (): boolean => {
    if (tabsState) {
      const activeTabIndex = tabsState.getActiveIndex(TabType.Company, activeTabState.getActiveTab(TabType.Company));
      const companyTabs = tabsState.getTabs(TabType.Company);
      const activeTabData = companyTabs[+`${activeTabIndex}`];
      return !!((activeTabData?.name === 'Error' || activeTabData?.name === 'Loading')); // Disable if loading or error
    }
    return true;
  };

  const disableCompanySelect = (): boolean => {
    const activePrimTab = tabsState?.getTab(TabType.Company, activeTabState.getActiveTab(TabType.Company) as string);
    return !!(activePrimTab?.name === 'Loading' || activePrimTab?.name === 'Error' || primaryTabs.length === 1);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSearch = (search: any) => {
    const activeTab = tabsState?.getTab(TabType.Company, activePrimaryTab as string);
    const finalObj = { ...search, companyIdentifier: activeTab?.name };
    const oType = objectType === 'Devices' && search.deviceId ? 'Device' : objectType;
    dispatch(updateSearchCompanies(finalObj, oType, activePrimaryTab));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewObject = (object: any, notDisabled: any) => {
    if (notDisabled) {
      const form = setInitialSearchFrom('/company', preferencesState.data.pageSize);
      // eslint-disable-next-line
      const DirObjectClass: any = DirectoryObjectClass[object];
      form.filter[0].property = getDefaultObjectCategories(object);
      updateSearch({ ...form, Category: object, objectClass: DirObjectClass });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getObjectCount = (category: any) => {
    const activePrimTab = tabsState?.getTab(TabType.Company, activeTabState.getActiveTab(TabType.Company) as string);
    if (activePrimTab?.contextId && companiesState?.objectCounts[activePrimTab?.contextId]) {
      // eslint-disable-next-line
      const objType = companiesState?.objectCounts[activePrimTab?.contextId].find((obj) => obj.objectClass === CompanyContextObjectClasses[category] as any);
      if (!objType || objType.count === 0) {
        return '';
      }
      return objType?.isLastPage ? `(${objType?.count})` : `(${objType?.count}+)`;
    } return '';
  };

  const whichObjectsToShow = () => { // Triggered from toggle - Hide Empty Object Classes
    const activePrimTab = tabsState?.getTab(TabType.Company, activeTabState.getActiveTab(TabType.Company) as string);
    if (activePrimTab?.contextId && companiesState?.objectCounts[activePrimTab?.contextId]) {
      const objCounts = companiesState?.objectCounts[activePrimTab?.contextId];
      return hideEmptyObjects
        ? objCounts.filter((obj) => (obj.count ?? 0) > 0).map((o) => DirectoryObjectClass[o.objectClass ?? 0]).sort()
        : objCounts.map((o) => DirectoryObjectClass[o.objectClass ?? 0]).sort();
    }
    return ['Loading...'];
  };

  useEffect(() => { //* Auto Load Data using default forms
    if (autoLoadedComponents.includes(objectType)) {
      updateSearch(defaultSearches[`${objectType}`]);
    }
  }, [objectType]);

  const ObjectTypeComponentMap: Record<ObjectTypeComponentKeys, JSX.Element | undefined> = {
    'Address List Objects':
  <AddressListObjectsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: ALOSearchForm) => updateSearch(data)}
  />,
    Applications:
  <ApplicationSB
    disabled={disableCategorySelect()}
    searchEvent={(data: ApplicationsSearchForm) => updateSearch(data)}
  />,
    Contracts:
  <ContractsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: ContractsSearchForm) => updateSearch(data)}
  />,
    'Delegation Entries':
  <DelegationEntriesSB
    disabled={disableCategorySelect()}
    searchEvent={(data: DelegationEntriesSearchForm) => updateSearch(data)}
  />,
    Deleted:
  <DeletedSB
    disabled={disableCategorySelect()}
    searchEvent={(data: DeletedSearchForm) => updateSearch(data)}
  />,
    Devices:
  <DeviceSB
    disabled={disableCategorySelect()}
    searchEvent={(data: DeviceSearchForm) => updateSearch(data)}
  />,
    'DirSync Errors':
  <DirSyncErrorsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: DirSyncErrorsSearchForm) => updateSearch(data)}
  />,
    'Entitlement Grants':
  <EntitlementGrantsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: EntitlementGrantsSearchForm) => updateSearch(data)}
  />,
    'Group Licensing Errors':
  <GroupLicensingErrorsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: GroupLicensingErrorsSearchForm) => updateSearch(data)}
  />,
    Groups:
  <GroupsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: GroupsSearchForm) => updateSearch(data)}
  />,
    'Key Groups':
  <KeyGroupsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: KeyGroupsSearchForm) => updateSearch(data)}
  />,
    Objects:
  <ObjectsSb
    disabled={disableCategorySelect()}
    searchEvent={(data: ObjectsSearchForm) => updateSearch(data)}
  />,
    'Role Assignments':
  <RoleAssignmentsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: RoleAssignmentsSearchForm) => updateSearch(data)}
  />,
    'Service Principals':
  <ServicePrincipalsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: ServicePrincipalsForm) => updateSearch(data)}
  />,
    'Single Object':
  <SingletObjectsSB
    disabled={disableCategorySelect()}
    searchEvent={(data: SingleObjectForm) => updateSearch(data)}
  />,
    'Single User':
  <SingleUserSB
    disabled={disableCategorySelect()}
    searchEvent={(data: SingleUserForm) => updateSearch(data)}
  />,
    Licenses:
  <LicensesSB
    disabled={disableCategorySelect()}
    searchEvent={(data: unknown) => updateSearch(data)}
  />,
    'Scoped Members':
  <ScopedMembersSB
    disabled={disableCategorySelect()}
    searchEvent={(data: ScopedMembersSearchForm) => updateSearch(data)}
  />,
    'Trusted Realm':
  <TrustedRealmSB
    disabled={disableCategorySelect()}
    searchEvent={(data: TrustedRealmSearchForm) => updateSearch(data)}
  />,
    Users:
  <UsersIndexSB
    disabled={disableCategorySelect()}
    searchEvent={(data: UsersSearchForm) => updateSearch(data)}
  />,
    'Provisioning Status': undefined,
    Device: undefined,
    '': undefined,
  };

  const id = _.uniqueId('CompanySidebar-');
  const objectTypeDropdownId = `${id}-ObjectTypeDropdown`;
  const primaryTabDropdownId = `${id}-PrimaryTabDropdown`;

  return (
    <div className="CompanySidebar">
      <Popup
        flowing
        position="bottom left"
        content="Enter either ContextId, Verified Domain, UPN or OcpOrganizationId of the company"
        trigger={(
          <InputWithEnter
            fluid
            disabled={loading}
            placeholder="Search..."
            value={searchForm.companyIdentifier}
            onChange={(e, { value }) => setSearchForm({ ...searchForm, companyIdentifier: value.trimEnd() })}
            action={{
              loading,
              disabled: !searchForm.companyIdentifier || loading,
              color: 'blue',
              icon: 'search',
              onClick: () => {
                dispatch(getCompanyByName(searchForm));
                setPreviousSearchForm(searchForm);
                setSearchForm(emptyFormState);
              },
            }}
          />
        )}
      />
      <Popup
        flowing
        position="bottom left"
        content="If selected, the search will be scoped to the soft deleted object container (aka Recycle Bin)"
        trigger={(
          <Checkbox
            toggle
            disabled={loading}
            label="Soft Deleted"
            checked={searchForm.softDeleted}
            onChange={(e, { checked }) => setSearchForm({ ...searchForm, softDeleted: checked })}
          />
        )}
      />
      <Popup
        flowing
        position="bottom left"
        content="Enter the generation of the context. Leave it empty for the current generation"
        trigger={(
          <Input
            type="number"
            size="small"
            disabled={loading}
            className="epoch margin-top"
            placeholder="Epoch"
            value={searchForm.contextEpoch}
            onChange={
              (e, { value }) => setSearchForm({
                ...searchForm,
                contextEpoch: unsafeToNumber(value),
              })
            }
          />
        )}
      />
      <Popup
        flowing
        position="bottom left"
        content="Enter the identity of the partition containing the context epoch. Leave it empty for the current generation"
        trigger={(
          <Input
            type="number"
            size="small"
            className="partitionId"
            disabled={loading}
            placeholder="PartitionId"
            value={searchForm.partitionId}
            onChange={
              (e, { value }) => setSearchForm({ ...searchForm, partitionId: unsafeToNumber(value) })
            }
          />
        )}
      />

      <Divider className="margin-top" />

      {
        primaryTabs?.length ? (
          // COMPANY DROPDOWN
          <>
            <label className="label" htmlFor={primaryTabDropdownId}>Selected Company</label>
            <Dropdown
              disabled={disableCompanySelect()}
              className="margin-bottom"
              placeholder="Select Available Companies"
              onChange={(e, { value }) => dispatch(activateTab(value as string, TabType.Company))}
              fluid
              selection
              id={primaryTabDropdownId}
              options={primaryTabs}
              value={activePrimaryTab}
            />
            {
              activeTabState.getActiveTab(TabType.Company) && (
                // OBJECT TYPE DROPDOWN
                <>
                  <Button
                    className="blue"
                    onClick={() => { handleClicked(0); setObjectType('Objects' as ObjectTypeComponentKeys); }}
                    primary
                    active={openedIndex === 0}
                    fluid
                    attached={openedIndex === 0 ? 'top' : false}
                  >
                    <Icon className={`float ${openedIndex === 0 ? '' : 'counterclockwise rotated'}`} name="dropdown" />
                    Browse Objects
                  </Button>
                  { openedIndex === 0
                    && (
                      <>
                        <div className="content-browse">
                          {
                        whichObjectsToShow().map((category) => (
                          <List divided relaxed key={generateId()}>
                            <List.Item
                              className={getObjectCount(category) ? 'object-link' : 'disabled-object-link'}
                              onClick={() => viewObject(category, getObjectCount(category))}
                            >
                              {`${category} ${getObjectCount(category)}`}
                              {getObjectCount(category) ? <Icon name="angle right" className="object-link-arrow" /> : ''}
                            </List.Item>
                          </List>
                        ))
                      }
                        </div>
                        <div className="browse-toggle">
                          <Checkbox
                            toggle
                            label="Hide empty object classes"
                            checked={hideEmptyObjects}
                            onChange={(e, { checked }) => setHideEmptyObjects(checked as boolean)}
                          />
                        </div>
                      </>
                    )}

                  <Button
                    className="blue search-type-margin"
                    onClick={() => { handleClicked(1); setObjectType('' as ObjectTypeComponentKeys); }}
                    primary
                    active={openedIndex === 1}
                    fluid
                    attached={openedIndex === 1 ? 'top' : false}
                  >
                    <Icon className={`float ${openedIndex === 1 ? '' : 'counterclockwise rotated'}`} name="dropdown" />
                    Search Objects
                  </Button>
                  { openedIndex === 1
                    && (
                    <div className="content-search">
                      <label className="label" htmlFor={objectTypeDropdownId}>Search Type</label>
                      <Dropdown
                        disabled={disableCategorySelect()}
                        placeholder="Search/Select Search Type"
                        onChange={(e, { value }) => setObjectType(value as ObjectTypeComponentKeys)}
                        fluid
                        search
                        selection
                        id={objectTypeDropdownId}
                        options={ObjectTypes}
                        value={objectType}
                      />
                        { objectType !== '' ? (
                          <div className="component-map">
                            {ObjectTypeComponentMap[`${objectType}`]}
                          </div>
                        ) : null}
                    </div>
                    )}
                </>
              )
            }
          </>
        ) : null
      }

    </div>
  );
};

export default CompanySidebar;
