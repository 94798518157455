export const GET_TOKEN_INFO_REQUESTED = 'GET_TOKEN_INFO_REQUESTED';
export const GET_TOKEN_INFO_COMPLETED = 'GET_TOKEN_INFO_COMPLETED';
export const GET_USER_DETAILS_REQUESTED = 'GET_USER_DETAILS_REQUESTED';
export const GET_USER_DETAILS_COMPLETED = 'GET_USER_DETAILS_COMPLETED';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_COMPLETED = 'LOGOUT_COMPLETED';
