import {
  DirectoryObjectClass,
  DirectoryProperty,
  LegacyAuthZPolicyType,
  PropertyFilterModel,
  PropertySearchRequestModel,
  SearchFilterOperator,
  SystemSettings,
  EnumOptionOfSystemTask,
} from 'app/proxyClients';
import { guidChecker } from 'app/helpers/helpers';
import type { App } from 'app/store';
import { SingleObjectForm } from 'app/components/shared/sidebar-children/SingleObjectSB/SingleObjectSB';
import { GenericSingleInputSBSearchForm } from 'app/components/shared/sidebar-children/GenericSingleInputSB/GenericSingleInputSB';
import { ProductCatalogSBSearchForm } from 'app/components/shared/sidebar-children/ProductCatalogSB/ProductCatalogSB';
import {
  DeletedSearchForm,
  ExportSearchForm,
  ObjectsSearchForm,
  StockKeepingUnitSBSearchForm,
} from 'app/typings';
import { getPageSizeFromState } from './preferences';

export type SystemSettingsTabData = {
  data?: SystemSettings & {
    displayName?: string
  }
  message?: string
};

export default async function getSystemSettings(app: App): Promise<SystemSettingsTabData | null> {
  return app.clients.system.getSystemSettings(undefined);
}

export async function getSystemTasks(app: App): Promise<EnumOptionOfSystemTask[] | null> {
  return app.clients.system.getSystemTaskList(undefined);
}

export async function getSystemDeleted(app: App, p: DeletedSearchForm) {
  return app.clients.search.deletedSystemContext(
    p.objectId,
    p.directoryObjectClass,
  );
}

export async function getSystemExport(app: App, p: ExportSearchForm) {
  return app.clients.system.exportSystemContext(
    p.classes,
    p.properties,
  );
}

export async function getProductCatalog(app: App, p:ProductCatalogSBSearchForm) {
  return app.clients.system.getProductCatalog(
    p.partNumber,
    p.servicePlan,
  );
}

export async function getRoleTemplates(app: App) {
  return app.clients.system.getLegacyAuthZPolicy(
    LegacyAuthZPolicyType.RoleTemplate,
  );
}

export async function getObjects(app: App, p:ObjectsSearchForm) {
  return app.clients.search.pagedObjects(new PropertySearchRequestModel({
    companyIdentifier: '00000000-0000-0000-0000-000000000000',
    softDeleted: p.softDeleted,
    objectClass: p.objectClass,
    filter: p.filter.map((d) => new PropertyFilterModel({
      comparisonOperator: d.comparisonOperator as SearchFilterOperator,
      conditionalOperator: d.conditionalOperator as SearchFilterOperator,
      property: d.property as DirectoryProperty,
      propertyValueString: d.propertyValueString as string,
    })),
    properties: p.properties as DirectoryProperty[],
    resultsLimit: getPageSizeFromState(app),
  }));
}

export async function getPermissionScopes(app: App) {
  return app.clients.system.getLegacyAuthZPolicy(
    LegacyAuthZPolicyType.PermissionScope,
  );
}

export async function getSPTs(app: App) {
  return app.clients.system.getPreAuthorizationLegacyAuthZPolicy();
}

export async function getServices(app: App) {
  return app.clients.service.getServices();
}

export async function getService(app: App, serviceType: string) {
  return app.clients.service.getService(
    serviceType,
  );
}

export async function getServiceInstances(app: App) {
  return app.clients.service.getServiceInstances();
}

export async function getServiceInstance(app: App, serviceInstanceName: string) {
  const index = serviceInstanceName.indexOf('/');
  return app.clients.service.getServiceInstance(
    serviceInstanceName.substr(0, index),
    serviceInstanceName.substr(index + 1),
  );
}

export async function getServicePlan(app: App, p:GenericSingleInputSBSearchForm) {
  if (!p.filter) {
    return app.clients.system.readServicePlans();
  }

  if (guidChecker(p.filter)) {
    return app.clients.system.readServicePlanByServicePlanId(p.filter);
  }

  return app.clients.system.readServicePlanByServicePlanName(p.filter);
}

export async function getSingleObject(app: App, p:SingleObjectForm) {
  return app.clients.object.getObjectFromSystemContext(
    p.objectId,
    p.softDeleted,
  );
}

export async function getStockKeepingUnit(app: App, p:StockKeepingUnitSBSearchForm) {
  if (!p.stockKeepingUnit) {
    return app.clients.system.readStockKeepingUnits();
  }

  if (guidChecker(p.stockKeepingUnit)) {
    return app.clients.system.readStockKeepingUnitBySkuId(p.stockKeepingUnit);
  }

  return app.clients.system.readStockKeepingUnitByPartNumber(p.stockKeepingUnit);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getTrustedCertificates(app: App, p:GenericSingleInputSBSearchForm) {
  return app.clients.system.getSystemContextCacheObjects(
    DirectoryObjectClass.TrustedCertificate,
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getTypeConfiguration(app: App, p:GenericSingleInputSBSearchForm) {
  return app.clients.system.getSystemContextCacheObjects(
    DirectoryObjectClass.TypeConfiguration,
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const systemEndpointMap: Record<string, (app: App, p: any) => Promise<unknown>> = {
  Deleted: getSystemDeleted,
  Export: getSystemExport,
  Objects: getObjects,
  'Permission Scopes': getPermissionScopes,
  'Product Catalog': getProductCatalog,
  'Role Templates': getRoleTemplates,
  SPTs: getSPTs,
  Services: getServices,
  ServiceInstances: getServiceInstances,
  ServicePlan: getServicePlan,
  Settings: getSystemSettings,
  'Single Object': getSingleObject,
  StockKeepingUnit: getStockKeepingUnit,
  TrustedCertificates: getTrustedCertificates,
  TypeConfiguration: getTypeConfiguration,
};
