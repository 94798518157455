import React, { FunctionComponent } from 'react';
import { Button, Transition } from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

const LicensesSB: FunctionComponent<ObjectTypeProps<unknown>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<unknown>) => (
  <Transition transitionOnMount animation="scale" duration={400}>
    <div className="sub-side-bar">
      <Button
        className="margin-top"
        fluid
        primary
        disabled={disabled}
        onClick={() => searchEvent({})}
      >
        Search
      </Button>
    </div>
  </Transition>
);
export default LicensesSB;
