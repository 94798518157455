/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = None 1 = AppPrincipalId 2 = DisplayName 3 = ServicePrincipalName 4 = ServicePrincipalTag
*/
export enum DSExplorerWebHostContractsFilterStringEnum {
    None = 0,
    AppPrincipalId = 1,
    DisplayName = 2,
    ServicePrincipalName = 3,
    ServicePrincipalTag = 4
}
