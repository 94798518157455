export * from '../models/CommonAuthenticationModelAuthRequestModel';
export * from '../models/CommonAuthenticationModelAuthRequestModelUserIdentity';
export * from '../models/CommonAuthenticationModelUserIdentity';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel';
export * from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult';
export * from '../models/CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
export * from '../models/CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
export * from '../models/CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString';
export * from '../models/CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString';
export * from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
export * from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
export * from '../models/CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
export * from '../models/CommonModelsCompanyContextObjectClasses';
export * from '../models/CommonModelsDirSyncSupportedDirectoryProperties';
export * from '../models/CommonModelsDirectoryLinkType';
export * from '../models/CommonModelsDirectoryObjectTreeNodeModel';
export * from '../models/CommonModelsEntitlementGrantValidSourceObjectClasses';
export * from '../models/CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask';
export * from '../models/CommonModelsGenericTreeNodeItemModel';
export * from '../models/CommonModelsRequiredApplicationOauth2Permissions';
export * from '../models/CommonModelsRoleAssignmentValidTargetObjectClasses';
export * from '../models/CommonModelsScopedMemberValidTargetObjectClasses';
export * from '../models/CommonModelsSystemContextObjectClasses';
export * from '../models/CommonModelsTreeNodeModel';
export * from '../models/DSExplorerDataModelsAnnouncementTrackingDataModel';
export * from '../models/DSExplorerDataModelsBaseCosmosDataEntry';
export * from '../models/DSExplorerDataModelsBrowsedObjectSearchModel';
export * from '../models/DSExplorerDataModelsLinkStateCollectionDataModel';
export * from '../models/DSExplorerDataModelsLinkStateDataModel';
export * from '../models/DSExplorerDataModelsStatusMessageDataModel';
export * from '../models/DSExplorerDataModelsUserPreferencesDataModel';
export * from '../models/DSExplorerDataStatusMessageType';
export * from '../models/DSExplorerWebHostContractsFilterStringEnum';
export * from '../models/DSExplorerWebHostModelsBase64Model';
export * from '../models/DSExplorerWebHostModelsBasePropertySearchRequestModel';
export * from '../models/DSExplorerWebHostModelsBulkLinkSearchRequestModel';
export * from '../models/DSExplorerWebHostModelsBulkSearchRequestModel';
export * from '../models/DSExplorerWebHostModelsDateTimeModel';
export * from '../models/DSExplorerWebHostModelsGuidModel';
export * from '../models/DSExplorerWebHostModelsHexModel';
export * from '../models/DSExplorerWebHostModelsNetIdModel';
export * from '../models/DSExplorerWebHostModelsPropertyFilterModel';
export * from '../models/DSExplorerWebHostModelsPropertySearchRequestModel';
export * from '../models/DSExplorerWebHostModelsSidModel';
export * from '../models/MicrosoftOnlineDSExplorerContractAltSecIdType';
export * from '../models/MicrosoftOnlineDSExplorerContractBinaryDSReference';
export * from '../models/MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet';
export * from '../models/MicrosoftOnlineDSExplorerContractCompanyLicenses';
export * from '../models/MicrosoftOnlineDSExplorerContractDSObject';
export * from '../models/MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty';
export * from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty';
export * from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority';
export * from '../models/MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty';
export * from '../models/MicrosoftOnlineDSExplorerContractDSPartitionDetails';
export * from '../models/MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription';
export * from '../models/MicrosoftOnlineDSExplorerContractDSReference';
export * from '../models/MicrosoftOnlineDSExplorerContractDSReferenceSet';
export * from '../models/MicrosoftOnlineDSExplorerContractDelegationType';
export * from '../models/MicrosoftOnlineDSExplorerContractDirectoryPropertyBase';
export * from '../models/MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType';
export * from '../models/MicrosoftOnlineDSExplorerContractLicenseDetail';
export * from '../models/MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail';
export * from '../models/MicrosoftOnlineDSExplorerContractLicenseUnitsDetail';
export * from '../models/MicrosoftOnlineDSExplorerContractMetadataProperty';
export * from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
export * from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
export * from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
export * from '../models/MicrosoftOnlineDSExplorerContractProvisioningStatusDetail';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseBase';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData';
export * from '../models/MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
export * from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus';
export * from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan';
export * from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan';
export * from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetail';
export * from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus';
export * from '../models/MicrosoftOnlineDSExplorerContractSystemSettings';
export * from '../models/MicrosoftOnlineDSExplorerContractUserIdentifierType';
export * from '../models/MicrosoftOnlineDirectoryServicesAssignedPlan';
export * from '../models/MicrosoftOnlineDirectoryServicesAssignedPlanInitialState';
export * from '../models/MicrosoftOnlineDirectoryServicesAuthorizationScopeType';
export * from '../models/MicrosoftOnlineDirectoryServicesCapabilityStatus';
export * from '../models/MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory';
export * from '../models/MicrosoftOnlineDirectoryServicesDirSyncState';
export * from '../models/MicrosoftOnlineDirectoryServicesDirectoryAuthority';
export * from '../models/MicrosoftOnlineDirectoryServicesDirectoryLinkClass';
export * from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
export * from '../models/MicrosoftOnlineDirectoryServicesDirectoryProperty';
export * from '../models/MicrosoftOnlineDirectoryServicesDirectoryReferenceClass';
export * from '../models/MicrosoftOnlineDirectoryServicesProvisionedPlan';
export * from '../models/MicrosoftOnlineDirectoryServicesProvisioningStatus';
export * from '../models/MicrosoftOnlineDirectoryServicesSearchFilterOperator';
export * from '../models/MicrosoftOnlineDirectoryServicesServicePlanProperties';
export * from '../models/MicrosoftOnlineDirectoryServicesSubscriptionStatus';
export * from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameter';
export * from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf';
export * from '../models/SearchDelegationEntriesDelegationTypeParameter';
export * from '../models/SearchDelegationEntriesDelegationTypeParameterOneOf';
export * from '../models/SearchDelegationEntriesUserIdentifierTypeParameter';
export * from '../models/SearchDelegationEntriesUserIdentifierTypeParameterOneOf';
export * from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameter';
export * from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf';
export * from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter';
export * from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf';
export * from '../models/SearchEntitlementGrantsSourceObjectClassParameter';
export * from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameter';
export * from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf';
export * from '../models/SearchRoleAssignmentsTargetObjectClassParameter';
export * from '../models/SearchRoleAssignmentsTargetObjectClassParameterOneOf';
export * from '../models/SearchScopedMembersTargetObjectClassParameter';
export * from '../models/SearchScopedMembersTargetObjectClassParameterOneOf';
export * from '../models/SearchServicePrincipalsFilterTypeParameter';
export * from '../models/SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString';
export * from '../models/SystemXmlLinqXAttribute';
export * from '../models/SystemXmlLinqXAttributeAllOfNextAttribute';
export * from '../models/SystemXmlLinqXAttributeAllOfPreviousAttribute';
export * from '../models/SystemXmlLinqXContainer';
export * from '../models/SystemXmlLinqXContainerAllOfFirstNode';
export * from '../models/SystemXmlLinqXContainerAllOfLastNode';
export * from '../models/SystemXmlLinqXDeclaration';
export * from '../models/SystemXmlLinqXDocument';
export * from '../models/SystemXmlLinqXDocumentAllOfDeclaration';
export * from '../models/SystemXmlLinqXDocumentAllOfDocumentType';
export * from '../models/SystemXmlLinqXDocumentAllOfRoot';
export * from '../models/SystemXmlLinqXDocumentType';
export * from '../models/SystemXmlLinqXElement';
export * from '../models/SystemXmlLinqXElementAllOfFirstAttribute';
export * from '../models/SystemXmlLinqXElementAllOfLastAttribute';
export * from '../models/SystemXmlLinqXName';
export * from '../models/SystemXmlLinqXNamespace';
export * from '../models/SystemXmlLinqXNode';
export * from '../models/SystemXmlLinqXNodeAllOfNextNode';
export * from '../models/SystemXmlLinqXNodeAllOfPreviousNode';
export * from '../models/SystemXmlLinqXObject';
export * from '../models/SystemXmlLinqXObjectDocument';
export * from '../models/SystemXmlXmlNodeType';

import { CommonAuthenticationModelAuthRequestModel } from '../models/CommonAuthenticationModelAuthRequestModel';
import { CommonAuthenticationModelAuthRequestModelUserIdentity } from '../models/CommonAuthenticationModelAuthRequestModelUserIdentity';
import { CommonAuthenticationModelUserIdentity } from '../models/CommonAuthenticationModelUserIdentity';
import { CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult        } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult';
import { CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString } from '../models/CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString';
import { CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString } from '../models/CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString';
import { CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonModelsCompanyContextObjectClasses } from '../models/CommonModelsCompanyContextObjectClasses';
import { CommonModelsDirSyncSupportedDirectoryProperties } from '../models/CommonModelsDirSyncSupportedDirectoryProperties';
import { CommonModelsDirectoryLinkType } from '../models/CommonModelsDirectoryLinkType';
import { CommonModelsDirectoryObjectTreeNodeModel } from '../models/CommonModelsDirectoryObjectTreeNodeModel';
import { CommonModelsEntitlementGrantValidSourceObjectClasses } from '../models/CommonModelsEntitlementGrantValidSourceObjectClasses';
import { CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask } from '../models/CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask';
import { CommonModelsGenericTreeNodeItemModel } from '../models/CommonModelsGenericTreeNodeItemModel';
import { CommonModelsRequiredApplicationOauth2Permissions } from '../models/CommonModelsRequiredApplicationOauth2Permissions';
import { CommonModelsRoleAssignmentValidTargetObjectClasses } from '../models/CommonModelsRoleAssignmentValidTargetObjectClasses';
import { CommonModelsScopedMemberValidTargetObjectClasses } from '../models/CommonModelsScopedMemberValidTargetObjectClasses';
import { CommonModelsSystemContextObjectClasses } from '../models/CommonModelsSystemContextObjectClasses';
import { CommonModelsTreeNodeModel } from '../models/CommonModelsTreeNodeModel';
import { DSExplorerDataModelsAnnouncementTrackingDataModel } from '../models/DSExplorerDataModelsAnnouncementTrackingDataModel';
import { DSExplorerDataModelsBaseCosmosDataEntry } from '../models/DSExplorerDataModelsBaseCosmosDataEntry';
import { DSExplorerDataModelsBrowsedObjectSearchModel    } from '../models/DSExplorerDataModelsBrowsedObjectSearchModel';
import { DSExplorerDataModelsLinkStateCollectionDataModel } from '../models/DSExplorerDataModelsLinkStateCollectionDataModel';
import { DSExplorerDataModelsLinkStateDataModel } from '../models/DSExplorerDataModelsLinkStateDataModel';
import { DSExplorerDataModelsStatusMessageDataModel        } from '../models/DSExplorerDataModelsStatusMessageDataModel';
import { DSExplorerDataModelsUserPreferencesDataModel } from '../models/DSExplorerDataModelsUserPreferencesDataModel';
import { DSExplorerDataStatusMessageType } from '../models/DSExplorerDataStatusMessageType';
import { DSExplorerWebHostContractsFilterStringEnum } from '../models/DSExplorerWebHostContractsFilterStringEnum';
import { DSExplorerWebHostModelsBase64Model } from '../models/DSExplorerWebHostModelsBase64Model';
import { DSExplorerWebHostModelsBasePropertySearchRequestModel } from '../models/DSExplorerWebHostModelsBasePropertySearchRequestModel';
import { DSExplorerWebHostModelsBulkLinkSearchRequestModel              } from '../models/DSExplorerWebHostModelsBulkLinkSearchRequestModel';
import { DSExplorerWebHostModelsBulkSearchRequestModel } from '../models/DSExplorerWebHostModelsBulkSearchRequestModel';
import { DSExplorerWebHostModelsDateTimeModel } from '../models/DSExplorerWebHostModelsDateTimeModel';
import { DSExplorerWebHostModelsGuidModel } from '../models/DSExplorerWebHostModelsGuidModel';
import { DSExplorerWebHostModelsHexModel } from '../models/DSExplorerWebHostModelsHexModel';
import { DSExplorerWebHostModelsNetIdModel } from '../models/DSExplorerWebHostModelsNetIdModel';
import { DSExplorerWebHostModelsPropertyFilterModel     } from '../models/DSExplorerWebHostModelsPropertyFilterModel';
import { DSExplorerWebHostModelsPropertySearchRequestModel         } from '../models/DSExplorerWebHostModelsPropertySearchRequestModel';
import { DSExplorerWebHostModelsSidModel } from '../models/DSExplorerWebHostModelsSidModel';
import { MicrosoftOnlineDSExplorerContractAltSecIdType } from '../models/MicrosoftOnlineDSExplorerContractAltSecIdType';
import { MicrosoftOnlineDSExplorerContractBinaryDSReference       } from '../models/MicrosoftOnlineDSExplorerContractBinaryDSReference';
import { MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet } from '../models/MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet';
import { MicrosoftOnlineDSExplorerContractCompanyLicenses } from '../models/MicrosoftOnlineDSExplorerContractCompanyLicenses';
import { MicrosoftOnlineDSExplorerContractDSObject         } from '../models/MicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty';
import { MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty           } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty';
import { MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority';
import { MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty    } from '../models/MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty';
import { MicrosoftOnlineDSExplorerContractDSPartitionDetails } from '../models/MicrosoftOnlineDSExplorerContractDSPartitionDetails';
import { MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription } from '../models/MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription';
import { MicrosoftOnlineDSExplorerContractDSReference      } from '../models/MicrosoftOnlineDSExplorerContractDSReference';
import { MicrosoftOnlineDSExplorerContractDSReferenceSet } from '../models/MicrosoftOnlineDSExplorerContractDSReferenceSet';
import { MicrosoftOnlineDSExplorerContractDelegationType } from '../models/MicrosoftOnlineDSExplorerContractDelegationType';
import { MicrosoftOnlineDSExplorerContractDirectoryPropertyBase } from '../models/MicrosoftOnlineDSExplorerContractDirectoryPropertyBase';
import { MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType } from '../models/MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType';
import { MicrosoftOnlineDSExplorerContractLicenseDetail         } from '../models/MicrosoftOnlineDSExplorerContractLicenseDetail';
import { MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail';
import { MicrosoftOnlineDSExplorerContractLicenseUnitsDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseUnitsDetail';
import { MicrosoftOnlineDSExplorerContractMetadataProperty } from '../models/MicrosoftOnlineDSExplorerContractMetadataProperty';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractProvisioningStatusDetail } from '../models/MicrosoftOnlineDSExplorerContractProvisioningStatusDetail';
import { MicrosoftOnlineDSExplorerContractResponseBase } from '../models/MicrosoftOnlineDSExplorerContractResponseBase';
import { MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray';
import { MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData         } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData         } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus           } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan        } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan         } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan';
import { MicrosoftOnlineDSExplorerContractSubscriptionDetail } from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetail';
import { MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus } from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus';
import { MicrosoftOnlineDSExplorerContractSystemSettings } from '../models/MicrosoftOnlineDSExplorerContractSystemSettings';
import { MicrosoftOnlineDSExplorerContractUserIdentifierType } from '../models/MicrosoftOnlineDSExplorerContractUserIdentifierType';
import { MicrosoftOnlineDirectoryServicesAssignedPlan        } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlan';
import { MicrosoftOnlineDirectoryServicesAssignedPlanInitialState                } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlanInitialState';
import { MicrosoftOnlineDirectoryServicesAuthorizationScopeType } from '../models/MicrosoftOnlineDirectoryServicesAuthorizationScopeType';
import { MicrosoftOnlineDirectoryServicesCapabilityStatus } from '../models/MicrosoftOnlineDirectoryServicesCapabilityStatus';
import { MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory } from '../models/MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory';
import { MicrosoftOnlineDirectoryServicesDirSyncState } from '../models/MicrosoftOnlineDirectoryServicesDirSyncState';
import { MicrosoftOnlineDirectoryServicesDirectoryAuthority } from '../models/MicrosoftOnlineDirectoryServicesDirectoryAuthority';
import { MicrosoftOnlineDirectoryServicesDirectoryLinkClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryLinkClass';
import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
import { MicrosoftOnlineDirectoryServicesDirectoryProperty } from '../models/MicrosoftOnlineDirectoryServicesDirectoryProperty';
import { MicrosoftOnlineDirectoryServicesDirectoryReferenceClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryReferenceClass';
import { MicrosoftOnlineDirectoryServicesProvisionedPlan         } from '../models/MicrosoftOnlineDirectoryServicesProvisionedPlan';
import { MicrosoftOnlineDirectoryServicesProvisioningStatus } from '../models/MicrosoftOnlineDirectoryServicesProvisioningStatus';
import { MicrosoftOnlineDirectoryServicesSearchFilterOperator } from '../models/MicrosoftOnlineDirectoryServicesSearchFilterOperator';
import { MicrosoftOnlineDirectoryServicesServicePlanProperties } from '../models/MicrosoftOnlineDirectoryServicesServicePlanProperties';
import { MicrosoftOnlineDirectoryServicesSubscriptionStatus } from '../models/MicrosoftOnlineDirectoryServicesSubscriptionStatus';
import { PartitionGetCompaniesByPartitionDirSyncStateFilterParameter } from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameter';
import { PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf } from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf';
import { SearchDelegationEntriesDelegationTypeParameter } from '../models/SearchDelegationEntriesDelegationTypeParameter';
import { SearchDelegationEntriesDelegationTypeParameterOneOf } from '../models/SearchDelegationEntriesDelegationTypeParameterOneOf';
import { SearchDelegationEntriesUserIdentifierTypeParameter } from '../models/SearchDelegationEntriesUserIdentifierTypeParameter';
import { SearchDelegationEntriesUserIdentifierTypeParameterOneOf } from '../models/SearchDelegationEntriesUserIdentifierTypeParameterOneOf';
import { SearchDirSyncProvisioningErrorsErrorCategoryParameter } from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameter';
import { SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf } from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf';
import { SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter } from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter';
import { SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf } from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf';
import { SearchEntitlementGrantsSourceObjectClassParameter } from '../models/SearchEntitlementGrantsSourceObjectClassParameter';
import { SearchRoleAssignmentsAuthorizationScopeTypeParameter } from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameter';
import { SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf } from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf';
import { SearchRoleAssignmentsTargetObjectClassParameter } from '../models/SearchRoleAssignmentsTargetObjectClassParameter';
import { SearchRoleAssignmentsTargetObjectClassParameterOneOf } from '../models/SearchRoleAssignmentsTargetObjectClassParameterOneOf';
import { SearchScopedMembersTargetObjectClassParameter } from '../models/SearchScopedMembersTargetObjectClassParameter';
import { SearchScopedMembersTargetObjectClassParameterOneOf } from '../models/SearchScopedMembersTargetObjectClassParameterOneOf';
import { SearchServicePrincipalsFilterTypeParameter } from '../models/SearchServicePrincipalsFilterTypeParameter';
import { SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString } from '../models/SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString';
import { SystemXmlLinqXAttribute          } from '../models/SystemXmlLinqXAttribute';
import { SystemXmlLinqXAttributeAllOfNextAttribute          } from '../models/SystemXmlLinqXAttributeAllOfNextAttribute';
import { SystemXmlLinqXAttributeAllOfPreviousAttribute          } from '../models/SystemXmlLinqXAttributeAllOfPreviousAttribute';
import { SystemXmlLinqXContainer } from '../models/SystemXmlLinqXContainer';
import { SystemXmlLinqXContainerAllOfFirstNode } from '../models/SystemXmlLinqXContainerAllOfFirstNode';
import { SystemXmlLinqXContainerAllOfLastNode } from '../models/SystemXmlLinqXContainerAllOfLastNode';
import { SystemXmlLinqXDeclaration } from '../models/SystemXmlLinqXDeclaration';
import { SystemXmlLinqXDocument            } from '../models/SystemXmlLinqXDocument';
import { SystemXmlLinqXDocumentAllOfDeclaration } from '../models/SystemXmlLinqXDocumentAllOfDeclaration';
import { SystemXmlLinqXDocumentAllOfDocumentType           } from '../models/SystemXmlLinqXDocumentAllOfDocumentType';
import { SystemXmlLinqXDocumentAllOfRoot                } from '../models/SystemXmlLinqXDocumentAllOfRoot';
import { SystemXmlLinqXDocumentType           } from '../models/SystemXmlLinqXDocumentType';
import { SystemXmlLinqXElement                } from '../models/SystemXmlLinqXElement';
import { SystemXmlLinqXElementAllOfFirstAttribute          } from '../models/SystemXmlLinqXElementAllOfFirstAttribute';
import { SystemXmlLinqXElementAllOfLastAttribute          } from '../models/SystemXmlLinqXElementAllOfLastAttribute';
import { SystemXmlLinqXName } from '../models/SystemXmlLinqXName';
import { SystemXmlLinqXNamespace } from '../models/SystemXmlLinqXNamespace';
import { SystemXmlLinqXNode } from '../models/SystemXmlLinqXNode';
import { SystemXmlLinqXNodeAllOfNextNode } from '../models/SystemXmlLinqXNodeAllOfNextNode';
import { SystemXmlLinqXNodeAllOfPreviousNode } from '../models/SystemXmlLinqXNodeAllOfPreviousNode';
import { SystemXmlLinqXObject } from '../models/SystemXmlLinqXObject';
import { SystemXmlLinqXObjectDocument            } from '../models/SystemXmlLinqXObjectDocument';
import { SystemXmlXmlNodeType } from '../models/SystemXmlXmlNodeType';

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

let enumsMap: Set<string> = new Set<string>([
    "CommonModelsCompanyContextObjectClasses",
    "CommonModelsDirSyncSupportedDirectoryProperties",
    "CommonModelsDirectoryLinkType",
    "CommonModelsEntitlementGrantValidSourceObjectClasses",
    "CommonModelsRoleAssignmentValidTargetObjectClasses",
    "CommonModelsScopedMemberValidTargetObjectClasses",
    "CommonModelsSystemContextObjectClasses",
    "DSExplorerDataStatusMessageType",
    "DSExplorerWebHostContractsFilterStringEnum",
    "MicrosoftOnlineDSExplorerContractAltSecIdType",
    "MicrosoftOnlineDSExplorerContractDelegationType",
    "MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType",
    "MicrosoftOnlineDSExplorerContractMetadataProperty",
    "MicrosoftOnlineDSExplorerContractUserIdentifierType",
    "MicrosoftOnlineDirectoryServicesAuthorizationScopeType",
    "MicrosoftOnlineDirectoryServicesCapabilityStatus",
    "MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory",
    "MicrosoftOnlineDirectoryServicesDirSyncState",
    "MicrosoftOnlineDirectoryServicesDirectoryAuthority",
    "MicrosoftOnlineDirectoryServicesDirectoryLinkClass",
    "MicrosoftOnlineDirectoryServicesDirectoryObjectClass",
    "MicrosoftOnlineDirectoryServicesDirectoryProperty",
    "MicrosoftOnlineDirectoryServicesDirectoryReferenceClass",
    "MicrosoftOnlineDirectoryServicesProvisioningStatus",
    "MicrosoftOnlineDirectoryServicesSearchFilterOperator",
    "MicrosoftOnlineDirectoryServicesServicePlanProperties",
    "MicrosoftOnlineDirectoryServicesSubscriptionStatus",
    "SystemXmlXmlNodeType",
]);

let typeMap: {[index: string]: any} = {
    "CommonAuthenticationModelAuthRequestModel": CommonAuthenticationModelAuthRequestModel,
    "CommonAuthenticationModelAuthRequestModelUserIdentity": CommonAuthenticationModelAuthRequestModelUserIdentity,
    "CommonAuthenticationModelUserIdentity": CommonAuthenticationModelUserIdentity,
    "CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel": CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel,
    "CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult": CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult,
    "CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel": CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel,
    "CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult": CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult,
    "CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel": CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel,
    "CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray": CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray,
    "CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult": CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult,
    "CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel": CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel,
    "CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult": CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult,
    "CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray": CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray,
    "CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray": CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray,
    "CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString": CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString,
    "CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString": CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString,
    "CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray": CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray,
    "CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray": CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray,
    "CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray": CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray,
    "CommonModelsDirectoryObjectTreeNodeModel": CommonModelsDirectoryObjectTreeNodeModel,
    "CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask": CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask,
    "CommonModelsGenericTreeNodeItemModel": CommonModelsGenericTreeNodeItemModel,
    "CommonModelsRequiredApplicationOauth2Permissions": CommonModelsRequiredApplicationOauth2Permissions,
    "CommonModelsTreeNodeModel": CommonModelsTreeNodeModel,
    "DSExplorerDataModelsAnnouncementTrackingDataModel": DSExplorerDataModelsAnnouncementTrackingDataModel,
    "DSExplorerDataModelsBaseCosmosDataEntry": DSExplorerDataModelsBaseCosmosDataEntry,
    "DSExplorerDataModelsBrowsedObjectSearchModel": DSExplorerDataModelsBrowsedObjectSearchModel,
    "DSExplorerDataModelsLinkStateCollectionDataModel": DSExplorerDataModelsLinkStateCollectionDataModel,
    "DSExplorerDataModelsLinkStateDataModel": DSExplorerDataModelsLinkStateDataModel,
    "DSExplorerDataModelsStatusMessageDataModel": DSExplorerDataModelsStatusMessageDataModel,
    "DSExplorerDataModelsUserPreferencesDataModel": DSExplorerDataModelsUserPreferencesDataModel,
    "DSExplorerWebHostModelsBase64Model": DSExplorerWebHostModelsBase64Model,
    "DSExplorerWebHostModelsBasePropertySearchRequestModel": DSExplorerWebHostModelsBasePropertySearchRequestModel,
    "DSExplorerWebHostModelsBulkLinkSearchRequestModel": DSExplorerWebHostModelsBulkLinkSearchRequestModel,
    "DSExplorerWebHostModelsBulkSearchRequestModel": DSExplorerWebHostModelsBulkSearchRequestModel,
    "DSExplorerWebHostModelsDateTimeModel": DSExplorerWebHostModelsDateTimeModel,
    "DSExplorerWebHostModelsGuidModel": DSExplorerWebHostModelsGuidModel,
    "DSExplorerWebHostModelsHexModel": DSExplorerWebHostModelsHexModel,
    "DSExplorerWebHostModelsNetIdModel": DSExplorerWebHostModelsNetIdModel,
    "DSExplorerWebHostModelsPropertyFilterModel": DSExplorerWebHostModelsPropertyFilterModel,
    "DSExplorerWebHostModelsPropertySearchRequestModel": DSExplorerWebHostModelsPropertySearchRequestModel,
    "DSExplorerWebHostModelsSidModel": DSExplorerWebHostModelsSidModel,
    "MicrosoftOnlineDSExplorerContractBinaryDSReference": MicrosoftOnlineDSExplorerContractBinaryDSReference,
    "MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet": MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet,
    "MicrosoftOnlineDSExplorerContractCompanyLicenses": MicrosoftOnlineDSExplorerContractCompanyLicenses,
    "MicrosoftOnlineDSExplorerContractDSObject": MicrosoftOnlineDSExplorerContractDSObject,
    "MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty": MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty,
    "MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty": MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty,
    "MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority": MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority,
    "MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty": MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty,
    "MicrosoftOnlineDSExplorerContractDSPartitionDetails": MicrosoftOnlineDSExplorerContractDSPartitionDetails,
    "MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription": MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription,
    "MicrosoftOnlineDSExplorerContractDSReference": MicrosoftOnlineDSExplorerContractDSReference,
    "MicrosoftOnlineDSExplorerContractDSReferenceSet": MicrosoftOnlineDSExplorerContractDSReferenceSet,
    "MicrosoftOnlineDSExplorerContractDirectoryPropertyBase": MicrosoftOnlineDSExplorerContractDirectoryPropertyBase,
    "MicrosoftOnlineDSExplorerContractLicenseDetail": MicrosoftOnlineDSExplorerContractLicenseDetail,
    "MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail": MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail,
    "MicrosoftOnlineDSExplorerContractLicenseUnitsDetail": MicrosoftOnlineDSExplorerContractLicenseUnitsDetail,
    "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray": MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray,
    "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray": MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray,
    "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray": MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray,
    "MicrosoftOnlineDSExplorerContractProvisioningStatusDetail": MicrosoftOnlineDSExplorerContractProvisioningStatusDetail,
    "MicrosoftOnlineDSExplorerContractResponseBase": MicrosoftOnlineDSExplorerContractResponseBase,
    "MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray": MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray,
    "MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray": MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings,
    "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData": MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData,
    "MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray": MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray,
    "MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus": MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus,
    "MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan": MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan,
    "MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan": MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan,
    "MicrosoftOnlineDSExplorerContractSubscriptionDetail": MicrosoftOnlineDSExplorerContractSubscriptionDetail,
    "MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus": MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus,
    "MicrosoftOnlineDSExplorerContractSystemSettings": MicrosoftOnlineDSExplorerContractSystemSettings,
    "MicrosoftOnlineDirectoryServicesAssignedPlan": MicrosoftOnlineDirectoryServicesAssignedPlan,
    "MicrosoftOnlineDirectoryServicesAssignedPlanInitialState": MicrosoftOnlineDirectoryServicesAssignedPlanInitialState,
    "MicrosoftOnlineDirectoryServicesProvisionedPlan": MicrosoftOnlineDirectoryServicesProvisionedPlan,
    "PartitionGetCompaniesByPartitionDirSyncStateFilterParameter": PartitionGetCompaniesByPartitionDirSyncStateFilterParameter,
    "PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf": PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf,
    "SearchDelegationEntriesDelegationTypeParameter": SearchDelegationEntriesDelegationTypeParameter,
    "SearchDelegationEntriesDelegationTypeParameterOneOf": SearchDelegationEntriesDelegationTypeParameterOneOf,
    "SearchDelegationEntriesUserIdentifierTypeParameter": SearchDelegationEntriesUserIdentifierTypeParameter,
    "SearchDelegationEntriesUserIdentifierTypeParameterOneOf": SearchDelegationEntriesUserIdentifierTypeParameterOneOf,
    "SearchDirSyncProvisioningErrorsErrorCategoryParameter": SearchDirSyncProvisioningErrorsErrorCategoryParameter,
    "SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf": SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf,
    "SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter": SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter,
    "SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf": SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf,
    "SearchEntitlementGrantsSourceObjectClassParameter": SearchEntitlementGrantsSourceObjectClassParameter,
    "SearchRoleAssignmentsAuthorizationScopeTypeParameter": SearchRoleAssignmentsAuthorizationScopeTypeParameter,
    "SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf": SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf,
    "SearchRoleAssignmentsTargetObjectClassParameter": SearchRoleAssignmentsTargetObjectClassParameter,
    "SearchRoleAssignmentsTargetObjectClassParameterOneOf": SearchRoleAssignmentsTargetObjectClassParameterOneOf,
    "SearchScopedMembersTargetObjectClassParameter": SearchScopedMembersTargetObjectClassParameter,
    "SearchScopedMembersTargetObjectClassParameterOneOf": SearchScopedMembersTargetObjectClassParameterOneOf,
    "SearchServicePrincipalsFilterTypeParameter": SearchServicePrincipalsFilterTypeParameter,
    "SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString": SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString,
    "SystemXmlLinqXAttribute": SystemXmlLinqXAttribute,
    "SystemXmlLinqXAttributeAllOfNextAttribute": SystemXmlLinqXAttributeAllOfNextAttribute,
    "SystemXmlLinqXAttributeAllOfPreviousAttribute": SystemXmlLinqXAttributeAllOfPreviousAttribute,
    "SystemXmlLinqXContainer": SystemXmlLinqXContainer,
    "SystemXmlLinqXContainerAllOfFirstNode": SystemXmlLinqXContainerAllOfFirstNode,
    "SystemXmlLinqXContainerAllOfLastNode": SystemXmlLinqXContainerAllOfLastNode,
    "SystemXmlLinqXDeclaration": SystemXmlLinqXDeclaration,
    "SystemXmlLinqXDocument": SystemXmlLinqXDocument,
    "SystemXmlLinqXDocumentAllOfDeclaration": SystemXmlLinqXDocumentAllOfDeclaration,
    "SystemXmlLinqXDocumentAllOfDocumentType": SystemXmlLinqXDocumentAllOfDocumentType,
    "SystemXmlLinqXDocumentAllOfRoot": SystemXmlLinqXDocumentAllOfRoot,
    "SystemXmlLinqXDocumentType": SystemXmlLinqXDocumentType,
    "SystemXmlLinqXElement": SystemXmlLinqXElement,
    "SystemXmlLinqXElementAllOfFirstAttribute": SystemXmlLinqXElementAllOfFirstAttribute,
    "SystemXmlLinqXElementAllOfLastAttribute": SystemXmlLinqXElementAllOfLastAttribute,
    "SystemXmlLinqXName": SystemXmlLinqXName,
    "SystemXmlLinqXNamespace": SystemXmlLinqXNamespace,
    "SystemXmlLinqXNode": SystemXmlLinqXNode,
    "SystemXmlLinqXNodeAllOfNextNode": SystemXmlLinqXNodeAllOfNextNode,
    "SystemXmlLinqXNodeAllOfPreviousNode": SystemXmlLinqXNodeAllOfPreviousNode,
    "SystemXmlLinqXObject": SystemXmlLinqXObject,
    "SystemXmlLinqXObjectDocument": SystemXmlLinqXObjectDocument,
}

type MimeTypeDescriptor = {
    type: string;
    subtype: string;
    subtypeTokens: string[];
};

/**
 * Every mime-type consists of a type, subtype, and optional parameters.
 * The subtype can be composite, including information about the content format.
 * For example: `application/json-patch+json`, `application/merge-patch+json`.
 *
 * This helper transforms a string mime-type into an internal representation.
 * This simplifies the implementation of predicates that in turn define common rules for parsing or stringifying
 * the payload.
 */
const parseMimeType = (mimeType: string): MimeTypeDescriptor => {
    const [type, subtype] = mimeType.split('/');
    return {
        type,
        subtype,
        subtypeTokens: subtype.split('+'),
    };
};

type MimeTypePredicate = (mimeType: string) => boolean;

// This factory creates a predicate function that checks a string mime-type against defined rules.
const mimeTypePredicateFactory = (predicate: (descriptor: MimeTypeDescriptor) => boolean): MimeTypePredicate => (mimeType) => predicate(parseMimeType(mimeType));

// Use this factory when you need to define a simple predicate based only on type and, if applicable, subtype.
const mimeTypeSimplePredicateFactory = (type: string, subtype?: string): MimeTypePredicate => mimeTypePredicateFactory((descriptor) => {
    if (descriptor.type !== type) return false;
    if (subtype != null && descriptor.subtype !== subtype) return false;
    return true;
});

// Creating a set of named predicates that will help us determine how to handle different mime-types
const isTextLikeMimeType = mimeTypeSimplePredicateFactory('text');
const isJsonMimeType = mimeTypeSimplePredicateFactory('application', 'json');
const isJsonLikeMimeType = mimeTypePredicateFactory((descriptor) => descriptor.type === 'application' && descriptor.subtypeTokens.some((item) => item === 'json'));
const isOctetStreamMimeType = mimeTypeSimplePredicateFactory('application', 'octet-stream');
const isFormUrlencodedMimeType = mimeTypeSimplePredicateFactory('application', 'x-www-form-urlencoded');

// Defining a list of mime-types in the order of prioritization for handling.
const supportedMimeTypePredicatesWithPriority: MimeTypePredicate[] = [
    isJsonMimeType,
    isJsonLikeMimeType,
    isTextLikeMimeType,
    isOctetStreamMimeType,
    isFormUrlencodedMimeType,
];

export class ObjectSerializer {
    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if(typeMap[discriminatorType]){
                        return discriminatorType; // use the type given in the discriminator
                    } else {
                        return expectedType; // discriminator did not map to a type
                    }
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string, format: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let date of data) {
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth()+1
                month = month < 10 ? "0" + month.toString() : month.toString()
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();

                return data.getFullYear() + "-" + month + "-" + day;
            } else {
                return data.toISOString();
            }
        } else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }

            // Get the actual type of this object
            type = this.findCorrectType(data, type);

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let attributeType of attributeTypes) {
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string, format: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let date of data) {
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap.has(type)) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let attributeType of attributeTypes) {
                let value = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
                if (value !== undefined) {
                    instance[attributeType.name] = value;
                }
            }
            return instance;
        }
    }


    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    public static normalizeMediaType(mediaType: string | undefined): string | undefined {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }

    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    public static getPreferredMediaType(mediaTypes: Array<string>): string {
        /** According to OAS 3 we should default to json */
        if (mediaTypes.length === 0) {
            return "application/json";
        }

        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);

        for (const predicate of supportedMimeTypePredicatesWithPriority) {
            for (const mediaType of normalMediaTypes) {
                if (mediaType != null && predicate(mediaType)) {
                    return mediaType;
                }
            }
        }

        throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
    }

    /**
     * Convert data to a string according the given media type
     */
    public static stringify(data: any, mediaType: string): string {
        if (isTextLikeMimeType(mediaType)) {
            return String(data);
        }

        if (isJsonLikeMimeType(mediaType)) {
            return JSON.stringify(data);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }

    /**
     * Parse data from a string according to the given media type
     */
    public static parse(rawData: string, mediaType: string | undefined) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }

        if (isTextLikeMimeType(mediaType)) {
            return rawData;
        }

        if (isJsonLikeMimeType(mediaType)) {
            return JSON.parse(rawData);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}
