// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonModelsDirectoryLinkType } from '../models/CommonModelsDirectoryLinkType';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';

/**
 * no description
 */
export class ObjectApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public async objectDownloadObjectLinksFromCompany(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'companyIdentifier' is not null or undefined
        if (companyIdentifier === null || companyIdentifier === undefined) {
            throw new RequiredError("ObjectApi", "objectDownloadObjectLinksFromCompany", "companyIdentifier");
        }


        // verify required parameter 'objectId' is not null or undefined
        if (objectId === null || objectId === undefined) {
            throw new RequiredError("ObjectApi", "objectDownloadObjectLinksFromCompany", "objectId");
        }


        // verify required parameter 'directoryLinkType' is not null or undefined
        if (directoryLinkType === null || directoryLinkType === undefined) {
            throw new RequiredError("ObjectApi", "objectDownloadObjectLinksFromCompany", "directoryLinkType");
        }


        // Path Params
        const localVarPath = '/api/Object/{companyIdentifier}/{objectId}/links/{directoryLinkType}/download'
            .replace('{' + 'companyIdentifier' + '}', encodeURIComponent(String(companyIdentifier)))
            .replace('{' + 'objectId' + '}', encodeURIComponent(String(objectId)))
            .replace('{' + 'directoryLinkType' + '}', encodeURIComponent(String(directoryLinkType)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param softDeleted 
     */
    public async objectGetObject(companyIdentifier: string, objectId: string, softDeleted?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'companyIdentifier' is not null or undefined
        if (companyIdentifier === null || companyIdentifier === undefined) {
            throw new RequiredError("ObjectApi", "objectGetObject", "companyIdentifier");
        }


        // verify required parameter 'objectId' is not null or undefined
        if (objectId === null || objectId === undefined) {
            throw new RequiredError("ObjectApi", "objectGetObject", "objectId");
        }



        // Path Params
        const localVarPath = '/api/Object/{companyIdentifier}/{objectId}'
            .replace('{' + 'companyIdentifier' + '}', encodeURIComponent(String(companyIdentifier)))
            .replace('{' + 'objectId' + '}', encodeURIComponent(String(objectId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (softDeleted !== undefined) {
            requestContext.setQueryParam("softDeleted", ObjectSerializer.serialize(softDeleted, "boolean", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param objectId 
     * @param softDeleted 
     */
    public async objectGetObjectFromSystemContext(objectId: string, softDeleted?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'objectId' is not null or undefined
        if (objectId === null || objectId === undefined) {
            throw new RequiredError("ObjectApi", "objectGetObjectFromSystemContext", "objectId");
        }



        // Path Params
        const localVarPath = '/api/Object/{objectId}'
            .replace('{' + 'objectId' + '}', encodeURIComponent(String(objectId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (softDeleted !== undefined) {
            requestContext.setQueryParam("softDeleted", ObjectSerializer.serialize(softDeleted, "boolean", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public async objectGetObjectLinksFromCompany(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'companyIdentifier' is not null or undefined
        if (companyIdentifier === null || companyIdentifier === undefined) {
            throw new RequiredError("ObjectApi", "objectGetObjectLinksFromCompany", "companyIdentifier");
        }


        // verify required parameter 'objectId' is not null or undefined
        if (objectId === null || objectId === undefined) {
            throw new RequiredError("ObjectApi", "objectGetObjectLinksFromCompany", "objectId");
        }


        // verify required parameter 'directoryLinkType' is not null or undefined
        if (directoryLinkType === null || directoryLinkType === undefined) {
            throw new RequiredError("ObjectApi", "objectGetObjectLinksFromCompany", "directoryLinkType");
        }


        // Path Params
        const localVarPath = '/api/Object/{companyIdentifier}/{objectId}/links/{directoryLinkType}'
            .replace('{' + 'companyIdentifier' + '}', encodeURIComponent(String(companyIdentifier)))
            .replace('{' + 'objectId' + '}', encodeURIComponent(String(objectId)))
            .replace('{' + 'directoryLinkType' + '}', encodeURIComponent(String(directoryLinkType)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param objectId 
     * @param directoryLinkType 
     */
    public async objectGetObjectLinksFromSytemContext(objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'objectId' is not null or undefined
        if (objectId === null || objectId === undefined) {
            throw new RequiredError("ObjectApi", "objectGetObjectLinksFromSytemContext", "objectId");
        }


        // verify required parameter 'directoryLinkType' is not null or undefined
        if (directoryLinkType === null || directoryLinkType === undefined) {
            throw new RequiredError("ObjectApi", "objectGetObjectLinksFromSytemContext", "directoryLinkType");
        }


        // Path Params
        const localVarPath = '/api/Object/{objectId}/links/{directoryLinkType}'
            .replace('{' + 'objectId' + '}', encodeURIComponent(String(objectId)))
            .replace('{' + 'directoryLinkType' + '}', encodeURIComponent(String(directoryLinkType)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param replicaName 
     * @param objectClass 
     * @param contextId 
     * @param contextEpoch 
     * @param partitionId 
     * @param readOnly 
     * @param objectId 
     * @param softDeleted 
     */
    public async objectGetReplicationMetadata(replicaName: string, objectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, contextId: string, contextEpoch: number, partitionId: number, readOnly?: boolean, objectId?: string, softDeleted?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'replicaName' is not null or undefined
        if (replicaName === null || replicaName === undefined) {
            throw new RequiredError("ObjectApi", "objectGetReplicationMetadata", "replicaName");
        }


        // verify required parameter 'objectClass' is not null or undefined
        if (objectClass === null || objectClass === undefined) {
            throw new RequiredError("ObjectApi", "objectGetReplicationMetadata", "objectClass");
        }


        // verify required parameter 'contextId' is not null or undefined
        if (contextId === null || contextId === undefined) {
            throw new RequiredError("ObjectApi", "objectGetReplicationMetadata", "contextId");
        }


        // verify required parameter 'contextEpoch' is not null or undefined
        if (contextEpoch === null || contextEpoch === undefined) {
            throw new RequiredError("ObjectApi", "objectGetReplicationMetadata", "contextEpoch");
        }


        // verify required parameter 'partitionId' is not null or undefined
        if (partitionId === null || partitionId === undefined) {
            throw new RequiredError("ObjectApi", "objectGetReplicationMetadata", "partitionId");
        }





        // Path Params
        const localVarPath = '/replicationMetadata/{replicaName}/{contextId}/{objectClass}/{contextEpoch}/{partitionId}'
            .replace('{' + 'replicaName' + '}', encodeURIComponent(String(replicaName)))
            .replace('{' + 'objectClass' + '}', encodeURIComponent(String(objectClass)))
            .replace('{' + 'contextId' + '}', encodeURIComponent(String(contextId)))
            .replace('{' + 'contextEpoch' + '}', encodeURIComponent(String(contextEpoch)))
            .replace('{' + 'partitionId' + '}', encodeURIComponent(String(partitionId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (readOnly !== undefined) {
            requestContext.setQueryParam("readOnly", ObjectSerializer.serialize(readOnly, "boolean", ""));
        }

        // Query Params
        if (objectId !== undefined) {
            requestContext.setQueryParam("objectId", ObjectSerializer.serialize(objectId, "string", "uuid"));
        }

        // Query Params
        if (softDeleted !== undefined) {
            requestContext.setQueryParam("softDeleted", ObjectSerializer.serialize(softDeleted, "boolean", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class ObjectApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to objectDownloadObjectLinksFromCompany
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async objectDownloadObjectLinksFromCompanyWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to objectGetObject
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async objectGetObjectWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to objectGetObjectFromSystemContext
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async objectGetObjectFromSystemContextWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to objectGetObjectLinksFromCompany
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async objectGetObjectLinksFromCompanyWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to objectGetObjectLinksFromSytemContext
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async objectGetObjectLinksFromSytemContextWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to objectGetReplicationMetadata
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async objectGetReplicationMetadataWithHttpInfo(response: ResponseContext): Promise<HttpInfo<string >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
