import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Divider, List, Accordion, Segment, Icon,
} from 'semantic-ui-react';
import moment from 'moment';
import { State } from 'app/reducers/state';
import TimeFormat from 'app/components/shared/Utilities/TimeFormat';
import './TokenInfo.scss';
import InputSearch from 'src/components/shared/InputSearch/InputSearch';
import { arrToObj, generateId } from 'app/helpers/helpers';

const TokenInfo: FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function buildGroupsList(Groups: any[]) {
    return (
      <List>
        { Groups.length
          ? [...new Set(Groups)].map((group) => (
            <List.Item key={group.val.key ? group.val.key : group.val}>
              {group.val}
            </List.Item>
          )) : <List.Item>No Matching Group Memberships</List.Item>}
      </List>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function buildClaimsList(Claims: any[]) {
    return (
      <List>
        {
          Claims.length
            ? [...new Set(Claims)].map((claim) => (
              <List.Item key={generateId()}>
                {claim.val}
              </List.Item>
            )) : <List.Item>No Matching Claims</List.Item>
        }

      </List>
    );
  }

  const [openedIndex, setOpenedIndex] = useState(-1);

  function handleClicked(thisIndex : number) {
    if (openedIndex === thisIndex) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(thisIndex);
    }
  }

  const preferencesState = useSelector((state: State) => state.preferences);
  const authState = useSelector((state: State) => state.auth);
  const userIdentity = authState?.UserIdentity;
  if (userIdentity && authState) {
    const {
      Name, IsLoggedInFromSaw, HasSawExemption, IdentityProvider, Claims, SecurityGroupMembership,
    } = userIdentity;
    const {
      IssuedTime, ExpirationTime,
    } = authState;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [claims, setClaims] = useState<any[]>(arrToObj(Claims));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [sgMem, setSGMem] = useState<any[]>(arrToObj(SecurityGroupMembership));

    const timeFormatOptions = {
      timeFormat: preferencesState.data.timeFormat,
      timeUtc: preferencesState.data.timeUtc,
      highlight: false,
      highlightColor: preferencesState.data.tableHighlightColor,
    };

    return (
      <div className="token-info">
        <List relaxed>
          <List.Item>
            <List.Header>Name:</List.Header>
            {Name}
          </List.Item>
          <List.Item>
            <List.Header>Issued:</List.Header>
            <TimeFormat time={moment.utc(IssuedTime)} {...timeFormatOptions} />
          </List.Item>
          <List.Item>
            <List.Header>Expiry:</List.Header>
            <TimeFormat time={moment.utc(ExpirationTime)} {...timeFormatOptions} />
          </List.Item>
          <List.Item>
            <List.Header>On SAW:</List.Header>
            {`${IsLoggedInFromSaw} ${HasSawExemption ? '(Has Saw Exception)' : ''}`}
          </List.Item>
          <List.Item>
            <List.Header>Identity Provider:</List.Header>
            {IdentityProvider}
          </List.Item>
        </List>
        <Divider />
        <Accordion styled fluid>
          <Accordion.Title
            active={openedIndex === 0}
            index={0}
            onClick={() => { handleClicked(0); }}
          >
            <Icon name="dropdown" />
            Group Memberships
          </Accordion.Title>
          <Accordion.Content active={openedIndex === 0}>
            {SecurityGroupMembership !== undefined ? (
              <>
                <div className="sg-search">
                  <InputSearch
                    disableTooltip
                    input={arrToObj(SecurityGroupMembership)}
                    output={(highlighted: string[]) => setSGMem(highlighted)}
                  />
                </div>
                {buildGroupsList(sgMem)}
              </>
            ) : 'None'}
          </Accordion.Content>
          <Accordion.Title
            active={openedIndex === 1}
            index={1}
            onClick={() => { handleClicked(1); }}
          >
            <Icon name="dropdown" />
            Full Claims
          </Accordion.Title>
          <Accordion.Content active={openedIndex === 1}>
            {Claims !== undefined ? (
              <>
                <div className="sg-search">
                  <InputSearch
                    disableTooltip
                    input={arrToObj(Claims)}
                    output={(highlighted: string[]) => setClaims(highlighted)}
                  />
                </div>
                {buildClaimsList(claims)}
              </>
            ) : 'None'}
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }

  return <Segment>You are not logged in.</Segment>;
};

export default TokenInfo;
