/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractDSReferenceSet } from '../models/MicrosoftOnlineDSExplorerContractDSReferenceSet';
import { HttpFile } from '../http/http';

export class CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray {
    'result'?: Array<MicrosoftOnlineDSExplorerContractDSReferenceSet> | null;
    'startTime'?: Date;
    'duration'?: string;
    'success'?: boolean;
    'errors'?: Array<string> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "result",
            "baseName": "result",
            "type": "Array<MicrosoftOnlineDSExplorerContractDSReferenceSet>",
            "format": ""
        },
        {
            "name": "startTime",
            "baseName": "startTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "string",
            "format": "duration"
        },
        {
            "name": "success",
            "baseName": "success",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "errors",
            "baseName": "errors",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray.attributeTypeMap;
    }

    public constructor() {
    }
}

