import React, { FunctionComponent } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface SpinnerProps {
  active?: boolean,
  text?: string,
  dimmer?: boolean,
  size?: 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive' | undefined,
  inline?: boolean | 'centered' | undefined,
  id?: string | undefined
}

const Spinner: FunctionComponent<SpinnerProps> = ({
  active = false, text, dimmer, size = 'medium', inline = false, id,
}: SpinnerProps) => (
  <>

    {dimmer
      ? (
        <Dimmer active={active} id={id}>
          <Loader size={size}>{text}</Loader>
        </Dimmer>
      )
      : <Loader inline={inline} size={size} active={active}>{text}</Loader>}
  </>
);
Spinner.defaultProps = {
  active: false,
  text: '',
  dimmer: false,
  size: 'medium',
  inline: false,
  id: undefined,
};

export default Spinner;
