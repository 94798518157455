// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';

/**
 * no description
 */
export class ServiceApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param serviceType 
     */
    public async serviceGetService(serviceType: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'serviceType' is not null or undefined
        if (serviceType === null || serviceType === undefined) {
            throw new RequiredError("ServiceApi", "serviceGetService", "serviceType");
        }


        // Path Params
        const localVarPath = '/api/Service/{serviceType}'
            .replace('{' + 'serviceType' + '}', encodeURIComponent(String(serviceType)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param serviceInstanceName 
     * @param instance 
     */
    public async serviceGetServiceInstance(serviceInstanceName: string, instance: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'serviceInstanceName' is not null or undefined
        if (serviceInstanceName === null || serviceInstanceName === undefined) {
            throw new RequiredError("ServiceApi", "serviceGetServiceInstance", "serviceInstanceName");
        }


        // verify required parameter 'instance' is not null or undefined
        if (instance === null || instance === undefined) {
            throw new RequiredError("ServiceApi", "serviceGetServiceInstance", "instance");
        }


        // Path Params
        const localVarPath = '/api/Service/{serviceInstanceName}/{instance}'
            .replace('{' + 'serviceInstanceName' + '}', encodeURIComponent(String(serviceInstanceName)))
            .replace('{' + 'instance' + '}', encodeURIComponent(String(instance)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     */
    public async serviceGetServiceInstances(_options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // Path Params
        const localVarPath = '/api/Services/Instances';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     */
    public async serviceGetServices(_options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // Path Params
        const localVarPath = '/api/Services';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class ServiceApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to serviceGetService
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async serviceGetServiceWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to serviceGetServiceInstance
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async serviceGetServiceInstanceWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to serviceGetServiceInstances
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async serviceGetServiceInstancesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to serviceGetServices
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async serviceGetServicesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
