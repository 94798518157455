/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 6 = Group 20 = User 22 = ServicePrincipal
*/
export enum CommonModelsEntitlementGrantValidSourceObjectClasses {
    Group = 6,
    User = 20,
    ServicePrincipal = 22
}
