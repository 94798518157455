import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { TimeFormatType } from 'app/typings';

export interface TimeFormatOptions {
  timeFormat: TimeFormatType,
  timeUtc: boolean,
  highlight: boolean,
  highlightColor: string | undefined
}

export type TimeFormatProps = TimeFormatOptions & {
  time: moment.Moment | undefined,
};

export function FormatTime(time : moment.Moment, type : TimeFormatType, useUtc: boolean) {
  const formats: { [name: string]: { DateTime: string } } = {};
  formats[TimeFormatType.Numbers] = {
    DateTime: 'l LT',
  };
  formats[TimeFormatType.ISO] = {
    DateTime: 'YYYY-MM-DDTHH:mm:ssZ',
  };
  formats[TimeFormatType.Short] = {
    DateTime: 'lll',
  };
  formats[TimeFormatType.Long] = {
    DateTime: 'LLL',
  };
  formats[TimeFormatType.Verbose] = {
    DateTime: 'LLLL',
  };
  const formatDict = formats[`${type}`] ?? formats[TimeFormatType.Numbers];
  const momentFormat = formatDict.DateTime;

  let localizedTime = moment(time);
  if (useUtc) {
    localizedTime = moment(time).utc();
  }

  return localizedTime.format(momentFormat);
}

const TimeFormat: FunctionComponent<TimeFormatProps> = ({
  time, timeFormat, timeUtc = false, highlight, highlightColor = undefined,
} : TimeFormatProps) => {
  if (time === undefined) {
    return <></>;
  }

  return (
    <time
      style={{ backgroundColor: highlight ? highlightColor : undefined }}
      dateTime={moment(time).utc().format()}
    >
      {FormatTime(time, timeFormat, timeUtc)}
    </time>
  );
};

export default TimeFormat;
