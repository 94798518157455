/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty';
import { MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty';
import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractDSObject {
    'contextId'?: string;
    'displayName'?: string | null;
    'objectClass'?: MicrosoftOnlineDirectoryServicesDirectoryObjectClass;
    'objectId'?: string;
    'directoryProperties'?: Array<MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty> | null;
    'metadataProperties'?: Array<MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty> | null;
    'isSoftDeleted'?: boolean;
    'isDeleted'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "contextId",
            "baseName": "contextId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string",
            "format": ""
        },
        {
            "name": "objectClass",
            "baseName": "objectClass",
            "type": "MicrosoftOnlineDirectoryServicesDirectoryObjectClass",
            "format": ""
        },
        {
            "name": "objectId",
            "baseName": "objectId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "directoryProperties",
            "baseName": "directoryProperties",
            "type": "Array<MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty>",
            "format": ""
        },
        {
            "name": "metadataProperties",
            "baseName": "metadataProperties",
            "type": "Array<MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty>",
            "format": ""
        },
        {
            "name": "isSoftDeleted",
            "baseName": "isSoftDeleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isDeleted",
            "baseName": "isDeleted",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractDSObject.attributeTypeMap;
    }

    public constructor() {
    }
}



