import '../../App.scss';
import './EnvSwitcher.scss';

import { State } from 'app/reducers/state';
import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Grid, Header, Icon, Image, SemanticWIDTHS, Segment, Popup,
} from 'semantic-ui-react';

import logo from 'app/assets/DsExplorer_Logo_Retro.png';
import _ from 'lodash';

import ThemePicker from 'app/components/Preferences/ThemePicker';

export interface EnvSwitcherProps {
  data: string
}

const EnvSwitcher: FunctionComponent<EnvSwitcherProps> = ({
  data,
}: EnvSwitcherProps) => {
  const preferenceState = useSelector((state: State) => state.preferences);

  const [buttonHovered, setButtonHovered] = useState(false);

  // Parse Env Data
  const envs = data.split(';');

  useEffect(() => {
    // Change the active theme on the container div for the app
    const appElement = document.getElementById('app');
    if (appElement) {
      appElement.className = preferenceState.staged?.activeTheme ?? preferenceState.data.activeTheme;
    }
  }, [preferenceState]);

  const choices = _.without(envs.map((e) => {
    if (e.length === 0) {
      return undefined;
    }

    const parsed = e.split('=');
    const name = parsed[0];
    let address = parsed[1];

    if (preferenceState.staged?.activeTheme !== undefined) {
      if (address.endsWith('/')) {
        address = address.slice(0, -1);
      }

      address = `${address}?themeSwitch=${preferenceState.staged?.activeTheme}`;
    }

    return (
      <Grid.Column key={name}>
        <Button
          fluid
          color="blue"
          size="medium"
          as="a"
          href={address}
          onMouseOver={() => setButtonHovered(true)}
          onMouseOut={() => setButtonHovered(false)}
        >
          {name}
        </Button>
      </Grid.Column>
    );
  }), undefined);

  const themePickerStyle = {
    padding: 0,
  };

  return (
    <div className="env-switcher">
      <Grid
        textAlign="center"
        verticalAlign="middle"
        columns={12}
      >
        <Grid.Column width={12} className="choices">
          <Header as="h2" color="teal" textAlign="center">
            <Image src={logo} fluid className={`icon ${buttonHovered ? 'buttonHovered' : ''}`} />
          </Header>
          <Grid stackable>
            <Grid.Row columns={choices.length as SemanticWIDTHS}>
              {choices}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
      <div className="styleicons">
        { [...Array(12).keys()].map((i) => <Image key={i} className={`styleicon styleicon-${i} ${buttonHovered ? 'buttonHovered' : ''}`} />)}
      </div>
      <div className="themeOptions">
        <Popup
          trigger={(
            <Button animated="vertical">
              <Button.Content hidden>Theme</Button.Content>
              <Button.Content visible>
                <Icon name="options" />
              </Button.Content>
            </Button>
          )}
          content={<Segment className="themePick"><ThemePicker itemsPerRow={3} useStagedTheme /></Segment>}
          on="click"
          position="bottom right"
          className={preferenceState.staged?.activeTheme ?? preferenceState.data.activeTheme}
          style={themePickerStyle}
        />

      </div>
    </div>
  );
};

export default EnvSwitcher;
