/* eslint-disable import/prefer-default-export */
import * as tabTypes from 'app/actions/tabTypes';
import * as indexTypes from 'app/actions/indexStoreTypes';
import { ObjectTypeComponentKeys } from 'app/components/SidebarContent/IndexSidebar/IndexSidebarOptions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function searchIndexStore(params: any, sidebarObjectType: ObjectTypeComponentKeys) {
  return {
    type: tabTypes.SEARCH_INDEX_STORE,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
  };
}

export function findApplicationViaIndex(appId: string, parentTab: string, tabId: string) {
  return {
    type: indexTypes.FIND_APPLICATION_FROM_INDEX_STORE,
    appId,
    parentTab,
    tabId,
  };
}
