import { enumToDropDownList, guidChecker } from 'app/helpers/helpers';
import { CompanyContextObjectClasses, SystemContextObjectClasses } from 'app/proxyClients';
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button, Dropdown, DropdownItemProps, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { DeletedSearchForm } from 'app/typings';

const DeletedSB: FunctionComponent<ObjectTypeProps<DeletedSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<DeletedSearchForm>) => {
  const location = useLocation();

  const objectClassOptions: DropdownItemProps[] = enumToDropDownList(
    location.pathname === '/company' ? CompanyContextObjectClasses : SystemContextObjectClasses,
  );

  const [searchForm, setSearchForm] = useState<DeletedSearchForm>({
    directoryObjectClass: 0,
    objectId: '',
  });

  const id = _.uniqueId('DeletedSB-');
  const directoryObjectClassDropdownId = `${id}-directoryObjectClassDropdown`;

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label" htmlFor={directoryObjectClassDropdownId}>
          Directory Object Class
        </label>
        <Dropdown
          fluid
          search
          selection
          placeholder="Select Directory Object Class"
          disabled={disabled}
          className="margin-bottom"
          options={objectClassOptions}
          id={directoryObjectClassDropdownId}
          value={searchForm.directoryObjectClass}
          onChange={(_e, { value }) => setSearchForm({
            ...searchForm,
            directoryObjectClass: value as number,
          })}
        />

        <label className="label">
          ObjectId of Deleted Object
          <Popup
            flowing
            position="bottom left"
            content="Enter the ObjectId of the deleted object you are looking for.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.objectId.length > 0 && !guidChecker(searchForm.objectId)}
                disabled={disabled}
                value={searchForm.objectId}
                className="last-form-item"
                fluid
                placeholder="Search Deleted ObjectId"
                onChange={(_e, { value }) => setSearchForm({
                  ...searchForm,
                  objectId: value,
                })}
              />
            )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ directoryObjectClass: 0, objectId: '' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.objectId === ''}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default DeletedSB;
