import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { guidChecker } from 'app/helpers/helpers';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

export type DeviceSearchForm = Record<string, unknown> & {
  deviceId: string,
};

export const defaultDevicesForm = {
  deviceId: '',
};

const DeviceSB: FunctionComponent<ObjectTypeProps<DeviceSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<DeviceSearchForm>) => {
  const [searchForm, setSearchForm] = useState<DeviceSearchForm>(defaultDevicesForm);

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          DeviceId
          <Popup
            flowing
            position="bottom left"
            content="Enter the DeviceId of the Device you are looking for.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.deviceId.length > 0 && !guidChecker(searchForm.deviceId)}
                disabled={disabled}
                value={searchForm.deviceId}
                className="last-form-item"
                fluid
                placeholder="Search DeviceId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, deviceId: value })}
              />
                      )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm(defaultDevicesForm)}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default DeviceSB;
