import {
  displayPartitionDetails, getCompaniesByPartition, getPartitionDetails, getPartitionFailovers,
} from 'app/actions/partitionActions';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PartitionCompaniesForm } from 'app/typings';
import {
  Button, Checkbox, Divider, Dropdown, Input, Transition,
} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { arrayToDropDownList, enumToDropDownList } from 'app/helpers/helpers';
import { DirSyncState } from 'app/proxyClients';
import { State } from 'app/reducers/state';
import moment from 'moment';

enum PartitionTabs {
  Companies,
  Failovers,
  PartitionDetails,
}

const PartitionSidebar = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<PartitionTabs>(PartitionTabs.Companies);
  const [failoverStarted, setFailoverStarted] = useState<Date | undefined>(undefined);
  const partitionState = useSelector((state: State) => state.partition);
  const [partitionIds, setPartitionIds] = useState<number[]>([]);

  const defaultValues = {
    partitionId: -1,
    createdAfter: undefined,
    createdBefore: undefined,
    contextIds: '',
    si: '',
    siPrefix: '',
    dirSyncEnabled: false,
    dirSyncState: undefined,
  };

  const [partitionForm, setPartitionForm] = useState<PartitionCompaniesForm>(defaultValues);

  useEffect(() => {
    const ids = partitionState?.partitionIds?.map((item) => item.partitionId).filter((id) => id !== 0);
    if (ids) {
      setPartitionIds(_.sortBy(ids));
    }
  }, [partitionState?.partitionIds]);

  useEffect(() => {
    if (!partitionState?.partitionIds?.length) {
      dispatch(getPartitionDetails());
    }
  }, []);

  const updateSearch = () => {
    if (activeTab === PartitionTabs.Companies) {
      // eslint-disable-next-line
      const form: any = { ...partitionForm };
      form.createdAfter = moment(form.createdAfter);
      form.createdBefore = moment(form.createdBefore);
      dispatch(getCompaniesByPartition(partitionForm));
    }
    if (activeTab === PartitionTabs.Failovers) {
      const failForm = { startedAfter: moment(failoverStarted), partitionId: partitionForm.partitionId };
      dispatch(getPartitionFailovers(failForm));
    }
    if (activeTab === PartitionTabs.PartitionDetails && partitionState?.partitionIds) {
      const idDetails = partitionState?.partitionIds?.filter((item) => item.partitionId === +partitionForm.partitionId);
      dispatch(displayPartitionDetails(idDetails[0]));
    }
  };

  const resetFunc = () => {
    if (activeTab === PartitionTabs.Companies) {
      setPartitionForm(defaultValues);
    }
    if (activeTab === PartitionTabs.Failovers) {
      setFailoverStarted(undefined);
    }
    if (activeTab === PartitionTabs.PartitionDetails) {
      setPartitionForm({ ...partitionForm, partitionId: -1 });
    }
  };

  const disableSearch = (): boolean => {
    if (activeTab === PartitionTabs.Companies) {
      return _.isEqual(defaultValues, partitionForm) || partitionForm.partitionId === -1;
    }
    if (activeTab === PartitionTabs.Failovers) {
      return !failoverStarted || partitionForm.partitionId === -1;
    }
    return partitionForm.partitionId === -1;
  };

  return (
    <div className="sub-side-bar">
      <label className="label" htmlFor="partitionId">
        PartitionId
      </label>
      <Dropdown
        fluid
        search
        loading={!partitionIds.length}
        selection
        placeholder="Select Partition ID"
        id="partitionId"
        className="last-form-item"
        options={arrayToDropDownList(partitionIds, false)}
        value={partitionForm.partitionId}
        onChange={(e, { value }) => setPartitionForm({ ...partitionForm, partitionId: value as number })}
      />
      <Button.Group className="margin-bottom">
        <Button
          compact
          primary
          active={activeTab === 0}
          className="btn-select"
          onClick={() => setActiveTab(PartitionTabs.Companies)}
        >
          Companies
        </Button>
        <Button
          compact
          primary
          active={activeTab === 1}
          className="btn-select"
          onClick={() => setActiveTab(PartitionTabs.Failovers)}
        >
          Failovers
        </Button>
        <Button
          compact
          primary
          active={activeTab === 2}
          className="btn-select"
          onClick={() => {
            setActiveTab(PartitionTabs.PartitionDetails);
          }}
        >
          Partition Details
        </Button>
      </Button.Group>
      <Divider className="partition-hr" />
      {
          activeTab === 0 && (
            <Transition transitionOnMount animation="scale" duration={400}>
              <div>
                <label className="label" htmlFor="created-after">
                  Created After
                </label>
                <SemanticDatepicker
                  pointing="right"
                  showToday={false}
                  maxDate={new Date()}
                  value={partitionForm.createdAfter}
                  allowOnlyNumbers
                  className="margin-bottom full-width"
                  id="created-after"
                  onChange={(e, data) => setPartitionForm({ ...partitionForm, createdAfter: data.value as Date })}
                />
                <label className="label" htmlFor="created-before">Created Before</label>
                <SemanticDatepicker
                  pointing="right"
                  showToday={false}
                  maxDate={new Date()}
                  value={partitionForm.createdBefore}
                  allowOnlyNumbers
                  className="margin-bottom full-width"
                  id="created-before"
                  onChange={(e, data) => setPartitionForm({ ...partitionForm, createdBefore: data.value as Date })}
                />
                <label className="label" htmlFor="context-ids">ContextIds</label>
                <Input
                  fluid
                  className="margin-bottom"
                  id="context-ids"
                  placeholder="One or more contextIds (semicolon separated)"
                  value={partitionForm.contextIds}
                  onChange={(_e, { value }) => setPartitionForm({
                    ...partitionForm, contextIds: value,
                  })}
                />
                <label className="label" htmlFor="si">Authorized Service Instance</label>
                <Input
                  fluid
                  className="margin-bottom"
                  id="si"
                  placeholder="AuthorizedServiceInstance"
                  value={partitionForm.si}
                  onChange={(_e, { value }) => setPartitionForm({
                    ...partitionForm, si: value,
                  })}
                />
                <label className="label" htmlFor="si-prefix">Authorized Service Instance Prefix</label>
                <Input
                  fluid
                  className="margin-bottom"
                  id="si-prefix"
                  placeholder="AuthorizedServiceInstancePrefix"
                  value={partitionForm.siPrefix}
                  onChange={(_e, { value }) => setPartitionForm({
                    ...partitionForm, siPrefix: value,
                  })}
                />
                <label className="label" htmlFor="dir-sync-state">DirSync State</label>
                <Dropdown
                  clearable
                  placeholder="DirSync State"
                  selection
                  fluid
                  className="last-form-item"
                  id="dir-sync-state"
                  options={enumToDropDownList(DirSyncState)}
                  value={partitionForm.dirSyncState}
                  onChange={(e, { value }) => setPartitionForm({
                    ...partitionForm, dirSyncState: value,
                  })}
                />
                <Checkbox
                  className="last-form-item"
                  toggle
                  label="DirSync Enabled Only"
                  checked={partitionForm.dirSyncEnabled}
                  onChange={(e, { checked }) => setPartitionForm({
                    ...partitionForm, dirSyncEnabled: checked,
                  })}
                />
              </div>
            </Transition>
          )
      }
      {
          activeTab === 1 && (
            <Transition transitionOnMount animation="scale" duration={400}>
              <div>
                <label className="label" htmlFor="failover-started">Started After</label>
                <SemanticDatepicker
                  pointing="right"
                  showToday={false}
                  maxDate={new Date()}
                  value={failoverStarted}
                  allowOnlyNumbers
                  className="last-form-item full-width"
                  id="failover-started"
                  onChange={(e, data) => setFailoverStarted(data.value as Date)}
                />
              </div>
            </Transition>
          )
      }
      <Transition transitionOnMount animation="scale" duration={400}>
        <div className="align-center">
          <Button
            compact
            className="sidebar-btn margin-right"
            onClick={() => resetFunc()}
          >
            Reset Search
          </Button>
          <Button
            disabled={disableSearch()}
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => updateSearch()}
          >
            Search
          </Button>
        </div>
      </Transition>
    </div>
  );
};

export default PartitionSidebar;
