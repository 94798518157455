/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
import { MicrosoftOnlineDirectoryServicesDirectoryReferenceClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryReferenceClass';
import { SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString } from '../models/SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractBinaryDSReference {
    'cloudSid'?: string | null;
    'objectClass'?: MicrosoftOnlineDirectoryServicesDirectoryObjectClass;
    'objectId'?: string;
    'referenceClass'?: MicrosoftOnlineDirectoryServicesDirectoryReferenceClass;
    'targetDeleted'?: boolean;
    'propertyBag'?: Array<SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "cloudSid",
            "baseName": "cloudSid",
            "type": "string",
            "format": ""
        },
        {
            "name": "objectClass",
            "baseName": "objectClass",
            "type": "MicrosoftOnlineDirectoryServicesDirectoryObjectClass",
            "format": ""
        },
        {
            "name": "objectId",
            "baseName": "objectId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "referenceClass",
            "baseName": "referenceClass",
            "type": "MicrosoftOnlineDirectoryServicesDirectoryReferenceClass",
            "format": ""
        },
        {
            "name": "targetDeleted",
            "baseName": "targetDeleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "propertyBag",
            "baseName": "propertyBag",
            "type": "Array<SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractBinaryDSReference.attributeTypeMap;
    }

    public constructor() {
    }
}



