import React, { FunctionComponent, useState } from 'react';
import {
  Button, Checkbox, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { guidChecker } from 'app/helpers/helpers';

export type ApplicationsSearchForm = Record<string, unknown> & {
  domainInUse: string,
  appIds: string,
  availableToOtherTenants: boolean | undefined,
};

export const defaultApplicationsForm = {
  domainInUse: '', appIds: '', availableToOtherTenants: false,
};

const ApplicationSB: FunctionComponent<ObjectTypeProps<ApplicationsSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ApplicationsSearchForm>) => {
  const [searchForm, setSearchForm] = useState<ApplicationsSearchForm>(defaultApplicationsForm);

  const preSearch = () => {
    if (searchForm.domainInUse !== '' || searchForm.appIds !== '') {
      searchEvent({ ...searchForm, availableToOtherTenants: undefined });
    } else {
      searchEvent(searchForm);
    }
  };

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          DomainName
          <Popup
            flowing
            position="bottom left"
            content="Search for domain name being used in host name in AppIdentifierUri"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.domainInUse}
                className="margin-bottom"
                fluid
                placeholder="Search DomainName"
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, domainInUse: value })}
              />
                      )}
          />
        </label>
        <label className="label">
          AppId
          <Popup
            flowing
            position="bottom left"
            content="Search for applications by the AppId.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)"
            trigger={(
              <Input
                error={searchForm.appIds.length > 0 && !guidChecker(searchForm.appIds)}
                disabled={disabled}
                value={searchForm.appIds}
                className="last-form-item"
                fluid
                placeholder="Search AppId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, appIds: value })}
              />
                      )}
          />
        </label>
        {(searchForm.domainInUse === '' && searchForm.appIds === '') ? (
          <Popup
            flowing
            position="bottom left"
            content="The search will be scoped to available other tenants only or not. Will not search both"
            trigger={(
              <Checkbox
                disabled={disabled}
                className="last-form-item"
                toggle
                label="Available To Other Tenants &ensp;&emsp; (Conjunctive Search)"
                checked={searchForm.availableToOtherTenants}
                onChange={(_e, { checked }) => setSearchForm({
                  ...searchForm,
                  availableToOtherTenants: checked,
                })}
              />
                    )}
          />
        ) : null}
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm(defaultApplicationsForm)}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => preSearch()}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default ApplicationSB;
