/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail';
import { MicrosoftOnlineDSExplorerContractSubscriptionDetail } from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetail';
import { MicrosoftOnlineDirectoryServicesCapabilityStatus } from '../models/MicrosoftOnlineDirectoryServicesCapabilityStatus';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractLicenseDetail {
    'accountId'?: string;
    'capabilityStatus'?: MicrosoftOnlineDirectoryServicesCapabilityStatus;
    'consumedUnits'?: number;
    'maximumOverageUnitsDetail'?: MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail | null;
    'prepaidUnitsDetail'?: MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail | null;
    'skuId'?: string;
    'subscriptions'?: Array<MicrosoftOnlineDSExplorerContractSubscriptionDetail> | null;
    'totalTrialUnitsDetail'?: MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "accountId",
            "baseName": "accountId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "capabilityStatus",
            "baseName": "capabilityStatus",
            "type": "MicrosoftOnlineDirectoryServicesCapabilityStatus",
            "format": ""
        },
        {
            "name": "consumedUnits",
            "baseName": "consumedUnits",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "maximumOverageUnitsDetail",
            "baseName": "maximumOverageUnitsDetail",
            "type": "MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail",
            "format": ""
        },
        {
            "name": "prepaidUnitsDetail",
            "baseName": "prepaidUnitsDetail",
            "type": "MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail",
            "format": ""
        },
        {
            "name": "skuId",
            "baseName": "skuId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "subscriptions",
            "baseName": "subscriptions",
            "type": "Array<MicrosoftOnlineDSExplorerContractSubscriptionDetail>",
            "format": ""
        },
        {
            "name": "totalTrialUnitsDetail",
            "baseName": "totalTrialUnitsDetail",
            "type": "MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractLicenseDetail.attributeTypeMap;
    }

    public constructor() {
    }
}



