import { enumToDropDownList, guidChecker } from 'app/helpers/helpers';
import { DelegationType, UserIdentifierType } from 'app/proxyClients';
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Dropdown, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface DelegationEntriesSearchForm {
  sourceObjectId: string,
  targetObjectId: string,
  delegationType: number | undefined,
  userIdentifierType: number | undefined,
  userIdentifierString: string,
  alternativeSecurityIdString: string
}

export const defaultDelegationEntriesForm = {
  sourceObjectId: '',
  targetObjectId: '',
  delegationType: undefined,
  userIdentifierType: undefined,
  userIdentifierString: '',
  alternativeSecurityIdString: '',
};

const DelegationEntriesSB: FunctionComponent<ObjectTypeProps<DelegationEntriesSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<DelegationEntriesSearchForm>) => {
  const [searchForm, setSearchForm] = useState<DelegationEntriesSearchForm>(defaultDelegationEntriesForm);
  const id = _.uniqueId('DelegationEntriesSB-');
  const delegationTypeDropdownId = `${id}-delegationTypeDropdown`;
  const userIdentifierStringentifierTypeDropdownId = `${id}-userIdentifierStringentifierTypeDropdown`;

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Source ObjectId
          <Popup
            flowing
            position="bottom left"
            content="Tip: The type of objects for source and target being sought are Service Principal.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.sourceObjectId.length > 0 && !guidChecker(searchForm.sourceObjectId)}
                disabled={disabled}
                value={searchForm.sourceObjectId}
                className="margin-bottom"
                fluid
                placeholder="Search Source ObjectId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, sourceObjectId: value })}
              />
                      )}
          />
        </label>
        <label className="label">
          Target ObjectId
          <Popup
            flowing
            position="bottom left"
            content="Tip: The type of objects for source and target being sought are Service Principal.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.targetObjectId.length > 0 && !guidChecker(searchForm.targetObjectId)}
                disabled={disabled}
                value={searchForm.targetObjectId}
                className="margin-bottom"
                fluid
                placeholder="Search Target ObjectId"
                onChange={(_e, { value }) => setSearchForm({
                  ...searchForm,
                  targetObjectId: value,
                })}
              />
                      )}
          />
        </label>
        <label className="label" htmlFor={delegationTypeDropdownId}>DelegationType</label>
        <Dropdown
          selection
          clearable
          fluid
          placeholder="Please select Delegation Type"
          disabled={disabled}
          className="margin-bottom"
          id={delegationTypeDropdownId}
          options={enumToDropDownList(DelegationType)}
          value={searchForm.delegationType}
          onChange={(e, { value }) => setSearchForm({ ...searchForm, delegationType: value as number })}
        />
        <label className="label" htmlFor={userIdentifierStringentifierTypeDropdownId}>UserIdentifierType</label>
        <Dropdown
          selection
          fluid
          clearable
          placeholder="Please select User ID Type"
          disabled={disabled}
          className="margin-bottom"
          id={userIdentifierStringentifierTypeDropdownId}
          options={enumToDropDownList(UserIdentifierType)}
          value={searchForm.userIdentifierType}
          onChange={(e, { value }) => setSearchForm({ ...searchForm, userIdentifierType: value as number })}
        />
        <label className="label">
          UserIdentifier
          <Popup
            flowing
            position="bottom left"
            content="UserIdentifier to filter on (Base64String format)"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.userIdentifierString}
                className="margin-bottom"
                fluid
                placeholder="Search UserIdentifier"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, userIdentifierString: value })}
              />
                      )}
          />
        </label>
        <label className="label">
          AltSecId
          <Popup
            flowing
            position="bottom left"
            content="AltSecId to filter on (Base64String format)"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.alternativeSecurityIdString}
                className="last-form-item"
                fluid
                placeholder="Search AltSecId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, alternativeSecurityIdString: value })}
              />
                      )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm(defaultDelegationEntriesForm)}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => {
              searchEvent(searchForm);
            }}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default DelegationEntriesSB;
