import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface ServicePlanSBSearchForm {
  servicePlan: string,
}

const ServicePlanSB: FunctionComponent<ObjectTypeProps<ServicePlanSBSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ServicePlanSBSearchForm>) => {
  const [searchForm, setSearchForm] = useState<ServicePlanSBSearchForm>({
    servicePlan: '',
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          PlanId or ServicePlanName
          <Popup
            flowing
            position="bottom left"
            content="Enter either the PlanId or ServicePlanName of the ServicePlan you are looking for"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.servicePlan}
                className="last-form-item"
                fluid
                placeholder="Enter either the PlanId or ServicePlanName of the ServicePlan you are looking for"
                // eslint-disable-next-line max-len
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, servicePlan: value })}
              />
                    )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ servicePlan: '' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default ServicePlanSB;
