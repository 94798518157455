export enum TimeFormatType {
  'Numbers' = 'Numbers',
  'ISO' = 'ISO',
  'Short' = 'Short',
  'Long' = 'Long',
  'Verbose' = 'Verbose',
}

export type ValidPreferences = {
  hideSidebar: boolean,
  activeTheme: string,
  hiddenColumns: string[],
  availableColumnsToHide: string[],
  tableFontSize: string,
  pageSize: number,
  timeFormat: TimeFormatType,
  timeUtc: boolean,
  persistTabs: boolean,
  tableHighlight: boolean,
  tableHighlightColor: string,
  rowClickBehavior: string,
  showAnnouncements: boolean,
  tabPersistBehavior: string,
  showPinButton: boolean,
  searchOpenByDefault: boolean,
  hideEmptyBrowseObjects: boolean,
  openSingleSearchResult: boolean
};
