import { getSingleLicense } from 'app/actions/companyActions';
import DSTable from 'app/components/shared/DSTable/DSTable';
import { formatLicenseData, LicenseResults } from 'app/reducers/shared';
import { State } from 'app/reducers/state';
import { TabType } from 'app/typings';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataFormatType } from '../shared/DSTable/DSTableTypes';

import ZeroState from '../shared/ZeroState/ZeroState';

const License: React.FunctionComponent<LicenseResults> = ({ searchData, params }: LicenseResults) => {
  const licenseResults = searchData;
  const tabsState = useSelector((state: State) => state.activeTabs);
  const dispatch = useDispatch();
  const rowClickEvent = (row: _.Dictionary<string>) => {
    dispatch(getSingleLicense({
      companyIdentifier: row.ContextId,
      skuId: row.Sku,
    }, row.DisplayName, tabsState?.getActiveTab(TabType.Company)));
  };

  if (_.isNil(licenseResults) || _.isNil(licenseResults.data)) {
    return null;
  }

  const getZeroStateText = () => {
    if (params) {
      return `No ${params.searchType} Found`;
    }
    return 'No Results Found';
  };

  const tableData = formatLicenseData({ searchData, params }) || [];
  return (
    tableData.length ? (
      <DSTable
        compact
        striped
        hide={['ContextId', 'ObjectClass', 'searchParams']}
        rowSelectable
        rowClickEvent={rowClickEvent}
        dataFormatType={DataFormatType.License}
        tableData={tableData}
      />
    ) : <ZeroState text={getZeroStateText()} />
  );
};

export default License;
