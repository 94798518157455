import './CompanyModal.scss';

import ContentModal from 'app/components/shared/ContentModal/ContentModal';
import XMLView from 'app/components/shared/XMLView/XMLView';
import { DownloadFile, findHighLight, tryParseJSONAarray } from 'app/helpers/helpers';
import { State } from 'app/reducers/state';
import debounce from 'lodash.debounce';
import React, {
  FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import {
  Button, Checkbox, CheckboxProps, Icon, Input, List, Segment,
} from 'semantic-ui-react';

export type CompanyRow = {
  'Value(s)': string[],
  Name: string,
  'Last Write Time': string | JSX.Element,
  'USN Orig': string,
  'Originating Authority': string,
  Version: string,
  'USN Local': string,
  'Originating Timestamp': string | JSX.Element
};

export type CompanyModalProps = {
  setModalOpen: (x: boolean) => void;
  closeEvent?: () => void,
  selectedRow: CompanyRow;
  modalOpen: boolean;
};

const CompanyContentModal: FunctionComponent<CompanyModalProps> = ({
  selectedRow,
  setModalOpen,
  modalOpen,
  closeEvent,
}: CompanyModalProps) => {
  const preferenceState = useSelector((state: State) => state.preferences);
  const [values, setValues] = useState<string[]>([]);
  const [prestineValues, setPrestineValues] = useState<string[]>([]);
  const [wordWrap, setWordWrap] = useState(true);
  const [jsonView, setJsonView] = useState(false);
  const [xmlView, setXmlView] = useState(false);
  const [allowXmlView, setAllowXmlView] = useState(true);
  const [allowJsonView, setAllowJsonView] = useState(true);
  const [searchString, setSearchString] = useState('');

  const downloadTxtFile = () => {
    const content = selectedRow['Value(s)'].join('\n');
    const name = `${selectedRow.Name}-Values.txt`;
    DownloadFile(name, content, 'text/plain');
  };

  function getThemeBasedOnPreference() {
    switch (preferenceState.data.activeTheme) {
      case 'dark':
        return 'ocean';
      case 'hotdog':
        return 'grayscale';
      case 'forest':
        return 'shapeshifter';
      case 'synthwave':
        return 'tube';
      case 'solarized-dark':
        return 'solarized';
      default:
        return 'rjv-default';
    }
  }

  // This should only run when we change the selected row.
  useEffect(() => {
    // Reset the View from last time it was opened and check JSON/XML
    setJsonView(false);
    setXmlView(false);
    //* JSON Check
    const result = tryParseJSONAarray(selectedRow['Value(s)']);
    setAllowJsonView(result.valid);
    //* XML Check
    if (window.DOMParser) {
      const parser = new DOMParser();
      try {
        const xmlContent = parser.parseFromString(selectedRow['Value(s)'].join(' '), 'text/xml');
        if (!xmlContent || (xmlContent.childNodes.length > 0 && xmlContent.childNodes[0].nodeName === 'html')) {
          setAllowXmlView(false);
        } else {
          setAllowXmlView(true);
        }
      } catch (error) {
        setAllowXmlView(false);
      }
    }
    setValues(selectedRow['Value(s)']); // Used for default view (May be highlighted)
    setPrestineValues(selectedRow['Value(s)']); // Used for JSON/XML views
  }, [selectedRow]);

  const triggerClose = () => {
    setJsonView(false);
    setModalOpen(false);
    if (typeof closeEvent === 'function') {
      closeEvent();
    }
  };

  const toggleXmlView = (_e: React.FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    if (checked) setJsonView(false);
    setXmlView(checked as boolean);
  };

  const toggleJSONView = (_e: React.FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    if (checked) setXmlView(false);
    setJsonView(checked as boolean);
  };

  const actionContent = (
    <>
      <Checkbox
        toggle
        className="modal-text-wrap"
        label="Word Wrap"
        checked={wordWrap}
        onChange={(e, { checked }) => setWordWrap(checked as boolean)}
      />
      {allowJsonView ? (
        <Checkbox
          toggle
          className="modal-text-wrap"
          label="JSON"
          checked={jsonView}
          onChange={toggleJSONView}
        />
      ) : undefined}

      {allowXmlView ? (
        <Checkbox
          toggle
          disabled={!allowXmlView}
          className="modal-text-wrap"
          label="XML"
          checked={xmlView}
          onChange={toggleXmlView}
        />
      ) : undefined}

      <Button icon="download" onClick={() => downloadTxtFile()} content="Notepad" />
      <Button className="ok-btn blue" primary onClick={triggerClose}>OK</Button>
    </>
  );

  const getContentView = () => {
    //* JSON
    if (jsonView) {
      const jsonContent = tryParseJSONAarray(prestineValues);
      return (
        <div className={`value-container json-container ${wordWrap ? 'wrap' : ''}`}>
          <ReactJson
            theme={getThemeBasedOnPreference()}
            groupArraysAfterLength={25}
            indentWidth={2}
            src={jsonContent || {}}
          />
        </div>
      );
    }

    //* XML
    if (xmlView) {
      const xmlContent = `<_root_>${prestineValues.join(' ')}</_root_>`;
      return (
        <div className={`value-container xml-container ${wordWrap ? 'wrap' : ''}`}>
          <XMLView xml={xmlContent} collapsible />
        </div>
      );
    }

    //* Standard View
    return (
      <div className={`value-container ${wordWrap ? 'wrap' : ''}`}>
        <Segment>
          {
            values.length ? values
              // eslint-disable-next-line react/no-array-index-key
              .map((value, i) => <p className="property-value" key={`value-${i}`}>{value}</p>)
              : <p className="property-value">No Values Found</p>
          }
        </Segment>
      </div>
    );
  };

  const label = (
    <div className="ui label">{`${values.length.toString()} Row(s)`}</div>
  );

  const closeBtn = (
    <Icon
      className="input-close-btn"
      name="close"
      link
      onClick={() => {
        setSearchString('');
        setValues(selectedRow['Value(s)']);
        setPrestineValues(selectedRow['Value(s)']);
      }}
    />
  );

  const searchValues = (value: string, highlight: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const matchingItems: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const matchingItemsPrestine: any[] = [];
    selectedRow['Value(s)'].forEach((v) => {
      if (v.toLowerCase().includes(value.toLowerCase())) {
        if (matchingItems.indexOf(v) === -1) { // Check if string was already added
          matchingItems.push(highlight ? findHighLight(v, value, preferenceState.data.tableHighlightColor) : v);
          matchingItemsPrestine.push(v);
        }
      }
    });
    setValues(matchingItems);
    setPrestineValues(matchingItemsPrestine);
  };

  const changeHandler = (value: string, highlight: boolean) => searchValues(value, highlight);
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [selectedRow['Value(s)'], preferenceState]);
  useEffect(() => () => debouncedChangeHandler.cancel(), []);

  const modalContent = (
    <>
      <List className="modal-info" relaxed horizontal>
        <List.Item>
          <List.Header>Last Write Time:</List.Header>
          {selectedRow['Last Write Time']}
        </List.Item>
        <List.Item>
          <List.Header>USN Originating:</List.Header>
          {selectedRow['USN Orig']}
        </List.Item>
        <List.Item>
          <List.Header>Originating Authority:</List.Header>
          {selectedRow['Originating Authority']}
        </List.Item>
      </List>
      <List className="modal-info" relaxed horizontal>
        <List.Item>
          <List.Header>Version:</List.Header>
          {selectedRow.Version}
        </List.Item>
        <List.Item>
          <List.Header>USN Local:</List.Header>
          {selectedRow['USN Local']}
        </List.Item>
        <List.Item>
          <List.Header>Originating Timestamp:</List.Header>
          {selectedRow['Originating Timestamp']}
        </List.Item>
      </List>
      <div className="search-container-modal">
        <Input
          label={label}
          size="small"
          className="comp-modal-input"
          value={searchString}
          icon={searchString === '' ? 'search' : closeBtn}
          placeholder="Search Values..."
          onChange={(e, { value }) => {
            setSearchString(value);
            debouncedChangeHandler(value, preferenceState.data.tableHighlight);
          }}
        />
      </div>
      {getContentView()}
    </>
  );

  return (
    <ContentModal
      className="CompanyContentModal"
      closeEvent={triggerClose}
      style="success"
      open={modalOpen}
      header={selectedRow.Name}
      message={modalContent}
      actions={actionContent}
    />
  );
};

CompanyContentModal.defaultProps = {
  closeEvent: undefined,
};

export default CompanyContentModal;
