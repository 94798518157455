import {
  Input, InputProps,
} from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';

/* eslint-disable */
const InputWithEnter: FunctionComponent<InputProps> = (props: InputProps) => React.createElement(
  Input,
  {
    ...props,
    onKeyPress: (e: React.KeyboardEvent<Input>) => {
      if (e.key === 'Enter') {
        if (props.action !== undefined && props.action.onClick !== undefined) {
          props.action.onClick();
        }
      } else if (props.onKeyPress !== undefined) {
        props.onKeyPress(e);
      }
    },
  },
);
/* eslint-enable */
export default InputWithEnter;
