import { logDebug } from 'app/constants/constants';
import type { App } from 'app/store';

export function loginUser(app: App) {
  return app.authenticate({
    strategy: 'oauth',
    redirectionUrl: window.location.href,
  }).then((response: unknown) => /* Logged in */ response)
    .catch((e: unknown) => {
    // Show login page (potentially with `e.message`)
      throw e;
    });
}

export function logout(app: App) {
  logDebug('Signing out');
  window.localStorage.clear();
  return app.clients.auth.signout(false);
}
