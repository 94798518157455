import ContentModal from 'app/components/shared/ContentModal/ContentModal';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReplicationMetaData, removeReplMetadataFromState } from 'app/actions/companyActions';
import { State } from 'app/reducers/state';
import { DownloadFile } from 'app/helpers/helpers';
import { Button } from 'semantic-ui-react';
import Spinner from '../shared/Spinner/Spinner';
import ZeroState from '../shared/ZeroState/ZeroState';
import { RMParams } from '../shared/DSTable/DSTableTypes';
import './ReplicationMetadata.scss';

interface ReplMetadataModalProps {
  p: RMParams,
  close: () => void,
}
const ReplicationMetadataModal: FunctionComponent<ReplMetadataModalProps> = ({
  p, close,
}: ReplMetadataModalProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rmData, setRMData] = useState<string | undefined>(undefined);

  const replMetadataState = useSelector((state: State) => state.replicationMetadata);

  useEffect(() => {
    setLoading(true);
    dispatch(getReplicationMetaData(p));
    return () => {
      dispatch(removeReplMetadataFromState(p.objectId));
    };
  }, []);

  useEffect(() => {
    const data = replMetadataState?.getReplMetadataFromState(p.objectId);
    if (data) {
      setRMData(data);
      setLoading(false);
    }
  }, [replMetadataState]);

  function buildTableContents() {
    if (loading) {
      return (
        <div className="spinner-container">
          <Spinner size="large" text="Loading..." />
        </div>
      );
    }

    if (!rmData) {
      return <ZeroState text="No Replication Metadata Found" />;
    }

    return (
      <pre>
        {rmData}
      </pre>
    );
  }

  const downloadTxtFile = () => {
    const content = rmData as string;
    const name = `Replication-Metadata-ObjectId-${p.objectId}`;
    DownloadFile(name, content, 'text/plain');
  };

  const actionContent = (
    <>
      <Button icon="download" onClick={() => downloadTxtFile()} content="Notepad" />
      <Button className="ok-btn blue" primary onClick={() => close()}>OK</Button>
    </>
  );

  return (
    <ContentModal
      className="ReplMetadataModal"
      icon="database"
      style="success"
      header={`Replication Metadata (ObjectId: ${p.objectId})`}
      message={buildTableContents()}
      open
      actions={actionContent}
    />
  );
};
export default ReplicationMetadataModal;
