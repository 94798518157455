/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus } from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractSubscriptionDetail {
    'accountId'?: string | null;
    'maximumOverageUnits'?: number | null;
    'nextLifecycleDate'?: Date | null;
    'prepaidUnits'?: number | null;
    'skuId'?: string | null;
    'startDate'?: Date | null;
    'subscriptionId'?: string | null;
    'subscriptionStatus'?: MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus | null;
    'trialSubscription'?: boolean | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "accountId",
            "baseName": "accountId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "maximumOverageUnits",
            "baseName": "maximumOverageUnits",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "nextLifecycleDate",
            "baseName": "nextLifecycleDate",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "prepaidUnits",
            "baseName": "prepaidUnits",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "skuId",
            "baseName": "skuId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "startDate",
            "baseName": "startDate",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "subscriptionId",
            "baseName": "subscriptionId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "subscriptionStatus",
            "baseName": "subscriptionStatus",
            "type": "MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus",
            "format": ""
        },
        {
            "name": "trialSubscription",
            "baseName": "trialSubscription",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractSubscriptionDetail.attributeTypeMap;
    }

    public constructor() {
    }
}

