/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = None 2 = AuthOrig 3 = DLMemRejectPerms 4 = DLMemSubmitPerms 5 = ManagedBy 6 = Manager 7 = Member 8 = MSExchBypassModerationFromDLMembersLink 9 = MSExchBypassModerationLink 10 = MSExchCoManagedByLink 11 = MSExchModeratedByLink 12 = PublicDelegates 13 = UnauthOrig 14 = MSExchDelegateListLink 15 = CloudPublicDelegates 16 = DelegationEntry 17 = CloudMSExchDelegateListLink 18 = RegisteredUsers 19 = RegisteredOwner 20 = EntitlementGrant 21 = Owner 22 = PendingMember 23 = AllowAccessTo 24 = GroupBasedLicenseErrorOccured 25 = InvitedBy 26 = ScopedMember 27 = DefaultPolicy 28 = LocalizedAppPermissions 29 = RoleAssignment 30 = AltRecipient 31 = EligibleMember 33 = SocialIdpBind 36 = ResourceAccount 37 = EligibleScopedMember 38 = EligibleRoleAssignment 39 = AssignedLabel 40 = HasPreAuthorizedApplication 44 = PlrToPELink 45 = DeviceCreatedFromTemplate 46 = AgreementAcceptance 47 = Sponsors 48 = DirectSourceOfAuthorityPolicy 49 = DirectLinkedCertificateAuthority 51 = DirectLinkedCertificateAuthorities 52 = CompanyBrandingLocalization 53 = EligibleOwner
*/
export enum MicrosoftOnlineDirectoryServicesDirectoryLinkClass {
    None = 0,
    AuthOrig = 2,
    DLMemRejectPerms = 3,
    DLMemSubmitPerms = 4,
    ManagedBy = 5,
    Manager = 6,
    Member = 7,
    MSExchBypassModerationFromDLMembersLink = 8,
    MSExchBypassModerationLink = 9,
    MSExchCoManagedByLink = 10,
    MSExchModeratedByLink = 11,
    PublicDelegates = 12,
    UnauthOrig = 13,
    MSExchDelegateListLink = 14,
    CloudPublicDelegates = 15,
    DelegationEntry = 16,
    CloudMSExchDelegateListLink = 17,
    RegisteredUsers = 18,
    RegisteredOwner = 19,
    EntitlementGrant = 20,
    Owner = 21,
    PendingMember = 22,
    AllowAccessTo = 23,
    GroupBasedLicenseErrorOccured = 24,
    InvitedBy = 25,
    ScopedMember = 26,
    DefaultPolicy = 27,
    LocalizedAppPermissions = 28,
    RoleAssignment = 29,
    AltRecipient = 30,
    EligibleMember = 31,
    SocialIdpBind = 33,
    ResourceAccount = 36,
    EligibleScopedMember = 37,
    EligibleRoleAssignment = 38,
    AssignedLabel = 39,
    HasPreAuthorizedApplication = 40,
    PlrToPELink = 44,
    DeviceCreatedFromTemplate = 45,
    AgreementAcceptance = 46,
    Sponsors = 47,
    DirectSourceOfAuthorityPolicy = 48,
    DirectLinkedCertificateAuthority = 49,
    DirectLinkedCertificateAuthorities = 51,
    CompanyBrandingLocalization = 52,
    EligibleOwner = 53
}
