import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export type GroupsSearchForm = Record<string, unknown> & {
  domainName: string,
  text: string,
};

export const defaultGroupsForm = {
  domainName: '', text: '',
};

const GroupsSB: FunctionComponent<ObjectTypeProps<GroupsSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<GroupsSearchForm>) => {
  const [searchForm, setSearchForm] = useState<GroupsSearchForm>(defaultGroupsForm);

  const searchDomainNameContent = 'If set, the search will be scoped to '
    + 'objects from this domain (either by UPN or ProxyAddress)';
  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          DomainName
          <Popup
            flowing
            position="bottom left"
            content={searchDomainNameContent}
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.domainName}
                className="margin-bottom"
                fluid
                placeholder="Search DomainName"
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, domainName: value })}
              />
                      )}
          />
        </label>
        <label className="label">
          Text
          <Popup
            flowing
            position="bottom left"
            content="If set, this yields LDAP search filter (|(displayName=text*)(mail=text*))"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.text}
                className="last-form-item"
                fluid
                placeholder="Search Text"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, text: value })}
              />
                      )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm(defaultGroupsForm)}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default GroupsSB;
