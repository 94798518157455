import './Diagnostics.scss';

import { app } from 'app/store';
import axios from 'axios';
import { isArray } from 'lodash';
import React, {
  Fragment,
  FunctionComponent, useEffect, useState,
} from 'react';
import {
  Accordion, Icon, List,
} from 'semantic-ui-react';

const Diagnostics: FunctionComponent = () => {
  const [appConfig, setAppConfig] = useState(undefined);
  const [serviceConfig, setServicelConfig] = useState({});
  const [openedIndex, setOpenedIndex] = useState(0);

  function handleClicked(thisIndex : number) {
    if (openedIndex === thisIndex) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(thisIndex);
    }
  }

  const getDataFromServer = (url: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    axios.get(`${app.config.getServer()}/public/ping/${url}`).then((res: any) => {
      setServicelConfig(Object.assign(serviceConfig, res.data));
    });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setAppConfig(app.config.getRunningConfig() as any);
    getDataFromServer('mdsconfig');
    getDataFromServer('appconfig');
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const genListFromObject = (obj: any): any => (
    Object.keys(obj).map((key) => {
      if (typeof obj[`${key}`] === 'object' && obj[`${key}`] !== null && !isArray(obj[`${key}`])) {
        return (
          <Fragment key={`${key}-frag`}>
            <List.Item key={`${key}`}>
              <span className="obj-key">{`${key}`}</span>
              <List relaxed>{genListFromObject(obj[`${key}`])}</List>
            </List.Item>
          </Fragment>
        );
      }

      let content;

      if (isArray(obj[`${key}`])) {
        content = obj[`${key}`].join(', ');
      }

      content = obj[`${key}`] as string;

      if (typeof obj[`${key}`] === 'boolean') {
        content = obj[`${key}`] === true
          ? 'Enabled'
          : 'Disabled';
      }

      return (
        <List.Item key={key}>
          <span className="obj-key">{`${key}:  `}</span>
          {content}
        </List.Item>
      );
    })
  );

  return (
    <div className="diagnostics">
      <Accordion styled fluid>

        {/* APP CONFIG */}
        <Accordion.Title
          active={openedIndex === 0}
          index={0}
          onClick={() => { handleClicked(0); }}
        >
          <Icon name="dropdown" />
          App Config
        </Accordion.Title>
        <Accordion.Content active={openedIndex === 0}>
          {appConfig
            ? (
              <List relaxed bulleted>
                {genListFromObject(appConfig) }
              </List>
            ) : <h4>No App Config Found</h4>}
        </Accordion.Content>

        {/* SERVICE CONFIG */}
        <Accordion.Title
          active={openedIndex === 1}
          index={1}
          onClick={() => { handleClicked(1); }}
        >
          <Icon name="dropdown" />
          Service Config
        </Accordion.Title>
        <Accordion.Content active={openedIndex === 1}>
          {serviceConfig ? (
            <List relaxed bulleted>
              {genListFromObject(serviceConfig)}
            </List>
          ) : <h4>No Service Config Found</h4>}
        </Accordion.Content>

      </Accordion>
    </div>
  );
};

export default Diagnostics;
