import { Action } from 'redux';
import * as CompanyTypes from 'app/actions/companyTypes';
import _ from 'lodash';
import { RMParams } from 'app/components/shared/DSTable/DSTableTypes';

export type ReplicationMetaData = {
  objectId: string
  replData: string
};

export type ReplicationMetadataState = {
  entries: ReplicationMetaData[],
  getReplMetadataFromState: (objectId: string) => string | undefined
};

const state: ReplicationMetadataState = {
  entries: [],
  getReplMetadataFromState(objectId: string) {
    const entries = _.find(this.entries, (o: ReplicationMetaData) => o.objectId === objectId);
    return entries?.replData;
  },
};

type ReplMetadataActions =
  GetReplicationMetadataCompleted | RemoveReplicationMetadataFromState;

type GetReplicationMetadataCompleted = Action<typeof CompanyTypes.GET_REPLICATION_METADATA_COMPLETED> & {
  resp: string
  params: RMParams
};
type RemoveReplicationMetadataFromState = Action<typeof CompanyTypes.REMOVE_REPLICATION_METADATA_FROM_STATE> & {
  objectId: string
};

export default (replMetadataState: ReplicationMetadataState = state, action: ReplMetadataActions): ReplicationMetadataState => {
  switch (action.type) {
    case CompanyTypes.GET_REPLICATION_METADATA_COMPLETED:
      if (replMetadataState.getReplMetadataFromState(action.params.objectId) === undefined) {
        return {
          ...replMetadataState, entries: [...replMetadataState.entries, { objectId: action.params.objectId, replData: action.resp }],
        };
      }
      return {
        ...replMetadataState,
      };
    case CompanyTypes.REMOVE_REPLICATION_METADATA_FROM_STATE: {
      if (replMetadataState.entries.findIndex((obj) => obj.objectId === action.objectId) === -1) {
        return {
          ...replMetadataState,
        };
      }
      const matchedIndex = replMetadataState.entries.findIndex((obj) => obj.objectId === action.objectId);
      return {
        ...replMetadataState, entries: [...replMetadataState.entries].filter((d, idx) => idx !== matchedIndex),
      };
    }
    default:
      return replMetadataState;
  }
};
