import { DSExplorerDataModelsUserPreferencesDataModel } from 'app/client';
import type { App } from 'app/store';

export async function getPreferences(app: App) {
  return app.clients.preferences.preferencesGetPreferences();
}

export async function addPreferences(app: App, p: DSExplorerDataModelsUserPreferencesDataModel) {
  return app.clients.preferences.preferencesAddPreferences(p);
}

export function getPageSizeFromState(app: App) {
  const state = app.store?.getState();
  return state?.preferences.data.pageSize;
}
