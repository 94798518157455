import DSTable from 'app/components/shared/DSTable/DSTable';
import React, { FunctionComponent } from 'react';
import {
  Grid, Header, Icon, Segment, Transition,
} from 'semantic-ui-react';
import { DataFormatType } from '../shared/DSTable/DSTableTypes';

export type ProvStatusData = {
  authorized: string[],
  provisioned: string[]
};

const ProvisioningStatus: FunctionComponent<ProvStatusData> = ({ provisioned = [], authorized = [] }) => {
  const [pData] = React.useState(provisioned.map((pItem) => ({ 'Provisioned Service Instance': pItem })));
  const [aData] = React.useState(authorized.map((aItem) => ({ 'Authorized Service Instance': aItem })));

  const emptyData = (text: string) => (
    <Transition transitionOnMount animation="scale" duration={600}>
      <Segment className="no-data-found">
        <Header icon>
          <Icon name="searchengin" />
          {text}
        </Header>
      </Segment>
    </Transition>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const genTable = (data: any) => (
    <DSTable
      compact
      striped
      rowSelectable={false}
      tableData={data}
      dataFormatType={DataFormatType.ProvStatus}
    />
  );

  return (
    <Grid columns="equal">
      <Grid.Column className="left-column">
        { aData.length ? genTable(aData) : emptyData('No Authorized Service Instances')}
      </Grid.Column>
      <Grid.Column className="right-column">
        { pData.length ? genTable(pData) : emptyData('No Provisioned Service Instances')}
      </Grid.Column>
    </Grid>
  );
};

export default ProvisioningStatus;
