import './SplitContainer.scss';
import React, {
  useEffect, useState, FunctionComponent, ReactElement,
} from 'react';

import SplitPane from 'react-split-pane';

export interface SplitContainerContents {
  component?: ReactElement,
  key: string
}

interface SplitContainerProps {
  Contents: SplitContainerContents[]
}

const SplitContainer: FunctionComponent<SplitContainerProps> = ({ Contents }: SplitContainerProps) => {
  const [resizerStyle, setResizerStyle] = useState<React.CSSProperties>({ display: 'none' });
  const [pane1Style, setPane1Style] = useState<React.CSSProperties>({ });
  const panes = Contents.map((c) => {
    if (c.component) {
      return React.cloneElement(c.component, { key: c.key });
    }

    return undefined;
  });

  function disableAnimation() {
    setPane1Style({ ...pane1Style, transition: 'none' });
  }

  function enableAnimation() {
    setPane1Style({ ...pane1Style, transition: 'width 400ms ease-out 0s' });
  }

  useEffect(() => {
    if (panes.length === 1) {
      setResizerStyle({ display: 'none' });
      setPane1Style({ width: '100%', transition: 'width 400ms ease-out 0s' });
    } else {
      setResizerStyle({ });
      setPane1Style({ minWidth: '20%', maxWidth: '80%', transition: 'width 400ms ease-in 0s' });
    }
  }, [Contents]);

  return (

    <SplitPane
      split="vertical"
      defaultSize="calc(50%)"
      resizerStyle={resizerStyle}
      pane1Style={pane1Style}
      // eslint-disable-next-line react/jsx-no-bind
      onDragStarted={disableAnimation}
      // eslint-disable-next-line react/jsx-no-bind
      onDragFinished={enableAnimation}
      pane2Style={{ minWidth: '20%', width: '500px' }}
    >

      {panes}
      &nbsp;
    </SplitPane>
  );
};

export default SplitContainer;

SplitContainer.defaultProps = {

};
