/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 7 = Region 9 = RoleTemplate 10 = Scope 11 = Service 12 = ServiceInstance 13 = ServicePlan 14 = StockKeepingUnit 17 = Task 18 = TaskSet 19 = ThrottlePolicy 21 = Datacenter 23 = SliceInstance 25 = FeatureDescriptor 26 = TrustedCertificate 28 = TypeConfiguration 33 = RoleSliceInstance 37 = DataReplicationScope 44 = PermissionScope 45 = ObjectSettingTemplate 47 = WorkloadDataLocation 49 = RoleDefinitionTemplate 53 = RolloutPlan 54 = SecuredEncryptionKey
*/
export enum CommonModelsSystemContextObjectClasses {
    Region = 7,
    RoleTemplate = 9,
    Scope = 10,
    Service = 11,
    ServiceInstance = 12,
    ServicePlan = 13,
    StockKeepingUnit = 14,
    Task = 17,
    TaskSet = 18,
    ThrottlePolicy = 19,
    Datacenter = 21,
    SliceInstance = 23,
    FeatureDescriptor = 25,
    TrustedCertificate = 26,
    TypeConfiguration = 28,
    RoleSliceInstance = 33,
    DataReplicationScope = 37,
    PermissionScope = 44,
    ObjectSettingTemplate = 45,
    WorkloadDataLocation = 47,
    RoleDefinitionTemplate = 49,
    RolloutPlan = 53,
    SecuredEncryptionKey = 54
}
