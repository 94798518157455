import { SagaIterator } from '@redux-saga/types';
import * as companyActions from 'app/actions/companyActions';
import * as companyTypes from 'app/actions/companyTypes';
import { GET_OBJECT_LINKS_COMPLETED, GET_OBJECT_LINKS_FAILED } from 'app/actions/objectLinkTypes';
import { setTabModalError, updateTab } from 'app/actions/tabActions';
import SingleObject from 'app/components/Company/SingleObject';
import {
  DownloadFile,
  assertTrue, generateId, getErrormessage, promiseToSaga,
} from 'app/helpers/helpers';
import {
  DSObject, PagedResponseOfDSObjectArray, ResponseOfDSObject, ResponseOfIDictionaryOfDirectoryLinkClassAndDSObjectArray,
} from 'app/helpers/types';
import { State } from 'app/reducers/state';
import { TabState } from 'app/reducers/tabs';
import {
  companiesEndpointMap,
  getCompanyByIdentifier,
  getCompanyLicense,
  getSingleObject,
  getSingleObjectLinks,
  getCompanyKeyGroups,
  downloadObjLinksFromCompany,
  getCompanyObjectLinkCounts,
  getReplicationMetaDataforObject,
} from 'app/services/companies';
import {
  GenericDictionaryParams, TabGeneratorEffect, TabType,
} from 'app/typings';
import _ from 'lodash';
import React from 'react';
import {
  all,
  call, delay, put, select, take, takeEvery,
} from 'typed-redux-saga';

import type { App } from 'app/store';
import { logDebug, logError } from 'app/constants/constants';
import { Dispatch } from 'redux';
import { DSTableExtraParams } from 'app/components/shared/DSTable/DSTableTypes';
import { getApplicationPermissions } from 'app/services/applications';
import { ACTIVATE_TAB } from 'app/actions/tabTypes';
import { GET_COMPANY_OBJECT_COUNTS, GET_COMPANY_OBJECT_COUNTS_COMPLETED, GET_COMPANY_OBJECT_COUNTS_FAILED } from 'app/actions/companyTypes';
import { createTabWithAsyncOperation, createTabWithComponent } from './tabSagas';

const getCompanyTabDisplayName = (data: ResponseOfDSObject) => data.data?.displayName;
const getTabState = (state: State) => state.tabs as TabState;

type FetchServicePrincipalsFromMultiTenantAppRequest = GenericDictionaryParams & {
  appId: string,
  contextId: string,
  tabId: string,
  parentTab: string
};

function* fetchCompanyDataAsync(app: App, params: companyActions.GetCompanyByNameAction): TabGeneratorEffect<void> {
  yield* put({ type: GET_COMPANY_OBJECT_COUNTS }); // Trigger loading
  const { refreshTab = false, ...companyNameParams } = params;
  yield* call(
    createTabWithAsyncOperation, app, {
      params: companyNameParams,
      tabType: 'Company',
      tabRoutingArea: TabType.Company,
      actionBegin: companyTypes.GET_COMPANY_BY_NAME,
      actionComplete: companyTypes.GET_COMPANY_BY_NAME_COMPLETED,
      actionFailed: companyTypes.GET_COMPANY_BY_NAME_FAILED,
      asyncDataFetch: promiseToSaga(getCompanyByIdentifier),
      getDisplayName: getCompanyTabDisplayName,
      refreshTab,
    },
  );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getObjectLinkCOunts(app: App, { resp }: any): any {
  let objectCounts;
  try {
    objectCounts = yield call(getCompanyObjectLinkCounts, app, resp.data.contextId);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield* put({ type: GET_COMPANY_OBJECT_COUNTS_FAILED });
    logError(`Failed to get object counts for ${resp.data.displayName}`);
  }
  yield* put({
    type: GET_COMPANY_OBJECT_COUNTS_COMPLETED,
    resp: {
      counts: objectCounts,
      contextId: resp.data.contextId,
    },
  });
}

function* openCompanyDetailsAsync(app: App, { resp, tabId, params }: { resp: ResponseOfDSObject, tabId: string, params: GenericDictionaryParams }) {
  const tabState = yield* select(getTabState);
  const isPinnedTab = tabState.isPinnedTab(tabId, true);
  const companyTab = isPinnedTab ? tabState.getPinnedTab(tabId, true) : tabState.getTab(TabType.Company, tabId);

  if (!companyTab) {
    return;
  }

  if (isPinnedTab && companyTab) {
    companyTab.refreshed += 1;
  }

  // eslint-disable-next-line
  function refreshDelegate(dispatch: Dispatch<any>, sortColumn?: string, sortOrder?: 'asc' | 'desc' | null | undefined) {
    dispatch({
      ...params, tabId, refreshTab: true, DSTableParams: { sortColumn, sortOrder },
    });
  }

  const refreshParams = _.omit(params, ['tabId', 'refreshTab']);
  const existingContextDetails = isPinnedTab ? companyTab : companyTab.children.tabs[0];

  if (params.refreshTab && existingContextDetails) {
    const newKey = generateId();
    const updateTabAction = updateTab(
      existingContextDetails,
      'Context Details',
      React.createElement(SingleObject, {
        id: existingContextDetails.id as string,
        key: newKey,
        dataResult: resp,
        refreshDelegate,
        DSTableParams: params.DSTableParams as DSTableExtraParams,
      }),
      companyTypes.GET_COMPANY_BY_NAME,
      refreshParams,
      tabId,
    );

    // Dropping the Action and Params since the Context Details tab is
    // created automatically by the parent tab.
    const metadata = _.omit(updateTabAction.tab.metadata, ['action', 'params']);
    yield* put({ ...updateTabAction, tab: { ...updateTabAction.tab, metadata } });

    // Exit -- Nothing more needed when only refreshing.
    return;
  }

  const newId = generateId();
  assertTrue(resp.data !== undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* call(createTabWithComponent as any,
    'Context Details',
    newId,
    TabType.Company,
    React.createElement(SingleObject, {
      id: newId, dataResult: resp, refreshDelegate, DSTableParams: {},
    }),
    true,
    tabId,
    null,
    false);

  // Waiting for a quarter second to allow the details to finish opening
  // before blasting out children
  yield* delay(250);
  if (params && Array.isArray(params.children)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < params.children.length; i++) {
      const theChild = params.children[+`${i}`];
      if (params.children[+`${i}`].params) {
        theChild.params.parentTab = tabId;
        yield* delay(50);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        yield* put(theChild.params as any);
      }
    }
  }
}

function* getCompanyTab(contextId: string) {
  const tabState = yield* select(getTabState);
  const companyTab = tabState.findByContextId(contextId);
  return companyTab?.id as string | undefined;
}

function* fetchSingleObjectAsync(app: App, params: companyActions.GetSingleObjectAction): TabGeneratorEffect<void> {
  const { refreshTab = false } = params;
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'SingleObject',
    parentTab: params.parentTab,
    tabRoutingArea: TabType.Company,
    actionBegin: companyTypes.GET_SINGLE_OBJECT,
    actionComplete: companyTypes.GET_SINGLE_OBJECT_COMPLETED,
    actionFailed: companyTypes.GET_SINGLE_OBJECT_FAILED,
    asyncDataFetch: promiseToSaga(getSingleObject),
    getDisplayName: getCompanyTabDisplayName,
    refreshTab,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
}

function* openKeyGroupAsync(app: App, params: companyActions.GetKeyGroupParams): TabGeneratorEffect<void> {
  let parentTab;

  parentTab = yield* call(getCompanyTab, params.contextId);

  if (!parentTab) {
    // Open the Company Tab
    yield* put(companyActions.getCompanyByName({ companyIdentifier: params.contextId }));

    // Wait for Company Parent Tab
    yield* take(ACTIVATE_TAB);

    // Wait for Context Details
    yield* take(ACTIVATE_TAB);

    // Check for Parent Tab again
    parentTab = yield* call(getCompanyTab, params.contextId);
  }

  if (parentTab) {
    yield* put(companyActions.getSingleObject({
      contextId: params.contextId,
      objectId: params.objectId,
    }, parentTab));
  }
}

function formatObjectLinkResponse(links: ResponseOfIDictionaryOfDirectoryLinkClassAndDSObjectArray) {
  let result;
  if (links && links.data !== undefined) {
    result = _.keysIn(links.data).map((v) => {
      if (links.data !== undefined) {
        return (_.get(links.data, v) as DSObject[]).map((o) => ({ ...o, linkClass: v }));
      }

      return undefined;
    });
  }

  return _.flatten(result);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchSingleObjectLinksAsync(app: App, p: companyActions.GetSingleObjectAction): any {
  let sourceLinks; //* source=0
  let targetLinks; //* target=1
  try {
    sourceLinks = yield call(getSingleObjectLinks, app, { companyIdentifier: p.data.contextId, objectId: p.data.objectId, directoryLinkType: 0 });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const { response } = e;
    yield* put({ type: GET_OBJECT_LINKS_FAILED, error: response.Message });
    logError(`Failed to get Object (${p.data.objectId}) Source Links from Company. Response from the server was: ${response.Message}`);
    return;
  }
  try {
    targetLinks = yield call(getSingleObjectLinks, app, { companyIdentifier: p.data.contextId, objectId: p.data.objectId, directoryLinkType: 1 });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const { response } = e;
    yield* put({ type: GET_OBJECT_LINKS_FAILED, error: response.Message });
    logError(`Failed to get Single Object (${p.data.objectId}) Target Links from Company. Response from the server was: ${response.Message}`);
    return;
  }
  yield* put({
    type: GET_OBJECT_LINKS_COMPLETED,
    resp: {
      totalLinks: targetLinks && sourceLinks ? targetLinks.linkCount + sourceLinks.linkCount : 0,
      targetLinks: { ...targetLinks, data: formatObjectLinkResponse(targetLinks) },
      sourceLinks: { ...sourceLinks, data: formatObjectLinkResponse(sourceLinks) },
      objectId: p.data.objectId,
    },
  });
}

function* fetchSingleLicenseAsync(app: App, params: companyActions.GetSingleLicenseParams): TabGeneratorEffect<void> {
  const getSingleLicenseTabName = () => params.licenseName;
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'SingleLicense',
    parentTab: params.parentTab,
    tabRoutingArea: TabType.Company,
    actionBegin: companyTypes.GET_SINGLE_LICENSE,
    actionComplete: companyTypes.GET_SINGLE_LICENSE_COMPLETED,
    actionFailed: companyTypes.GET_SINGLE_LICENSE_FAILED,
    asyncDataFetch: promiseToSaga(getCompanyLicense),
    getDisplayName: getSingleLicenseTabName,
  });
}

function* fetchAppPermissionsAsync(app: App, params: companyActions.GetApplicationPermissionParams): TabGeneratorEffect<void> {
  const [, resp] = yield* all([
    delay(500),
    call(getApplicationPermissions, app, params.companyIdentifier, params.appId)]);

  yield* put({
    type: companyTypes.GET_APP_PERMISSIONS_COMPLETED,
    appId: params.appId,
    resp,
  });
}

function* fetchReplicationMetaDataAsync(app: App, params: companyActions.GetReplicationMetaDataParams): TabGeneratorEffect<void> {
  const resp = yield* call(getReplicationMetaDataforObject, app, params);

  yield* put({
    type: companyTypes.GET_REPLICATION_METADATA_COMPLETED,
    resp,
    params,
  });
}

function* downloadCompLinkObj(app: App, params: companyActions.DownloadCompanyObjectLinks): TabGeneratorEffect<void> {
  const resp = yield* call(downloadObjLinksFromCompany, app, params);
  const data = resp.data?.Member || resp.data?.Manager;
  if (data) {
    const content = JSON.stringify(data);
    const name = `${params.directoryLinkType === 0 ? 'Source' : 'Target'}-links-${params.objectId}.txt`;
    DownloadFile(name, content, 'text/plain');
  }
}

function* fetchKeyGroupsAsync(app: App, params: companyActions.GetKeyGroupReferenceParams): TabGeneratorEffect<void> {
  // Search for KeyGroup
  const resp = yield* call(getCompanyKeyGroups, app, { keyGroupId: params.keyGroupId, companyIdentifier: params.contextId });

  if (!resp || !Array.isArray(resp.data)) {
    return;
  }

  if (resp.data.length > 0) {
    // We have the search results -- Now get the DSObject itself
    const keyGroupResp = yield* call(getSingleObject, app, {
      contextId: params.contextId,
      objectId: resp.data[0].objectId as string,
    });

    yield* put({
      type: companyTypes.GET_KEY_GROUP_REFERENCE_COMPLETED,
      objectId: params.objectId,
      keyGroupId: params.keyGroupId,
      resp: keyGroupResp?.data,
    });
  } else {
    logDebug('Found more than one key group');
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* displayPropBagComp(app: App, params: any): TabGeneratorEffect<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* call(createTabWithComponent as any,
    params.tabTitle,
    generateId(),
    TabType.Company,
    React.createElement(SingleObject, {
      dataResult: params.propertyBag,
      isPropBag: true,
    }),
    true,
    params.parentTab,
    undefined,
    true);
}

function* fetchServicePrincipalsFromMultiTenantAppAsync(app: App, params: FetchServicePrincipalsFromMultiTenantAppRequest): TabGeneratorEffect<void> {
  // Search
  const endpoint = companiesEndpointMap['Service Principals'];
  try {
    const resp = yield* call(endpoint, app, {
      filterString: params.appId,
      filterType: 1,
      companyIdentifier: params.contextId,
    });

    if (resp instanceof PagedResponseOfDSObjectArray) {
      if (!resp.data || resp.data.length === 0) {
        yield* put(setTabModalError(params.tabId, TabType.Company, params.parentTab, `A Service Principal for appid '${params.appId}' was not found within the tenant with context id '${params.contextId}'.`));
      }

      let tabState = yield* select(getTabState);
      assertTrue(tabState !== undefined);

      let foundTab = _.find(tabState.tabStore[TabType.Company].tabs, ['contextId', params.contextId]);
      if (foundTab === undefined) {
        yield* put(companyActions.getCompanyByName({
          companyIdentifier: params.contextId,
        }));

        // Look for the company tab again
        yield* take(companyTypes.GET_COMPANY_BY_NAME_COMPLETED);

        tabState = yield* select(getTabState);
        assertTrue(tabState !== undefined);

        foundTab = _.find(tabState.tabStore[TabType.Company].tabs, ['contextId', params.contextId]);
      }

      if (foundTab !== undefined && resp.data && resp.data[0] !== undefined) {
        // Company is already open

        yield* put(companyActions.getSingleObject({
          contextId: params.contextId,
          objectId: resp.data[0].objectId as string,
        }, foundTab.id));
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (ex: any) {
    yield* put(setTabModalError(params.tabId, TabType.Company, params.parentTab, getErrormessage(ex)));
  }
}

export default function* watchAll(app: App): SagaIterator<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_COMPANY_BY_NAME, fetchCompanyDataAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_COMPANY_BY_NAME_COMPLETED, openCompanyDetailsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_COMPANY_BY_NAME_COMPLETED, getObjectLinkCOunts, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_SINGLE_OBJECT, fetchSingleObjectAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_SINGLE_OBJECT, fetchSingleObjectLinksAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_SINGLE_LICENSE, fetchSingleLicenseAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.DISPLAY_PROPERTY_BAG_COMP, displayPropBagComp, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.FIND_SP_BY_APPID, fetchServicePrincipalsFromMultiTenantAppAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_APP_PERMISSIONS, fetchAppPermissionsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_KEY_GROUP_REFERENCE, fetchKeyGroupsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.OPEN_KEY_GROUP_OBJECT, openKeyGroupAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.DOWNLOAD_COMPANY_LINKS, downloadCompLinkObj, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>companyTypes.GET_REPLICATION_METADATA, fetchReplicationMetaDataAsync, app);
}
