import './SystemSidebar.scss';

import {
  getPermissionScopes,
  getRoleTemplates,
  getSearchEndpoints,
  getSingleObject,
  getSPTs,
  getSystemContextExport,
  getSystemSettings,
  getTrustedCertificates,
  getTypeConfiguration,
} from 'app/actions/systemActions';
import DeletedSB from 'app/components/shared/sidebar-children/DeletedSB/DeletedSB';
import ExportSB from 'app/components/shared/sidebar-children/ExportSB/ExportSB';
import ObjectsSB from 'app/components/shared/sidebar-children/ObjectsSB/ObjectsSB';
import ProductCatalogSB, {
  ProductCatalogSBSearchForm,
} from 'app/components/shared/sidebar-children/ProductCatalogSB/ProductCatalogSB';
import ServicePlanSB, { ServicePlanSBSearchForm } from 'app/components/shared/sidebar-children/ServicePlanSB/ServicePlanSB';
import SingletObjectsSB, { SingleObjectForm } from 'app/components/shared/sidebar-children/SingleObjectSB/SingleObjectSB';
import StockKeepingUnitSB from 'app/components/shared/sidebar-children/StockKeepingUnitSB/StockKeepingUnitSB';
import { State } from 'app/reducers/state';
import {
  DeletedSearchForm,
  ExportSearchForm,
  ObjectsSearchForm,
  TaskSearchForm,
  StockKeepingUnitSBSearchForm,
  TabType,
} from 'app/typings';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import TaskFilterSB from 'app/components/shared/sidebar-children/TaskFilterSB/TaskFilterSB';
import { ObjectTypeComponentKeys, SystemObjectTypes } from './SystemSidebarOptions';

export interface SidebarProps<T = unknown> {
  disabled: boolean; // If form needs to be disabled
  searchEvent: (data: T) => void; // The form output
}

const SystemSidebar = () => {
  const dispatch = useDispatch();
  const [objectType, setObjectType] = useState<ObjectTypeComponentKeys>('');
  const tabsState = useSelector((state: State) => state.tabs);
  const activeTabState = useSelector((state: State) => state.activeTabs);
  const autoLoadedComponents = ['Services', 'Settings', 'ServiceInstances', 'Product Catalog',
    'TypeConfiguration', 'TrustedCertificate', 'SPTs', 'Permission Scopes', 'Role Templates', 'StockKeepingUnit', 'ServicePlan'];

  const disableCategorySelect = (): boolean => {
    if (tabsState) {
      const activeTabIndex = tabsState.getActiveIndex(TabType.System, activeTabState.getActiveTab(TabType.System));
      const systemTabs = tabsState.getTabs(TabType.System);
      const activeTabData = systemTabs[+`${activeTabIndex}`];
      return !!(
        activeTabData?.name === 'Error' || activeTabData?.name === 'Loading'
      ); // Disable if loading or error
    }
    return true;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSearch = (search: any) => {
    /*
      getSearchEndpoints will load as SEARCH Components,
      all others will be routed and displayed as SYSTEM Components
    */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatchMap: any = {
      Deleted: getSearchEndpoints,
      Export: getSystemContextExport,
      Objects: getSearchEndpoints,
      'Permission Scopes': getPermissionScopes,
      'Product Catalog': getSearchEndpoints,
      'Role Templates': getRoleTemplates,
      SPTs: getSPTs,
      Services: getSearchEndpoints,
      ServiceInstances: getSearchEndpoints,
      ServicePlan: getSearchEndpoints,
      Settings: getSystemSettings,
      'Single Object': getSingleObject,
      StockKeepingUnit: getSearchEndpoints,
      TrustedCertificate: getTrustedCertificates,
      TypeConfiguration: getTypeConfiguration,
    };
    const systemAction = dispatchMap[`${objectType}`];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    systemAction(search, objectType).then((res: any) => {
      dispatch(res);
    });
  };

  useEffect(() => {
    if (autoLoadedComponents.includes(objectType)) { // Auto Load Data on Selection
      updateSearch({});
    }
  }, [objectType]);

  const ObjectTypeComponentMap: Record<
  ObjectTypeComponentKeys,
  JSX.Element | undefined
  > = {
    Deleted: (
      <DeletedSB
        disabled={disableCategorySelect()}
        searchEvent={(data: DeletedSearchForm) => updateSearch(data)}
      />
    ),
    Export: (
      <ExportSB
        disabled={disableCategorySelect()}
        searchEvent={(data: ExportSearchForm) => updateSearch(data)}
      />
    ),
    Objects: (
      <ObjectsSB
        disabled={disableCategorySelect()}
        searchEvent={(data: ObjectsSearchForm) => updateSearch(data)}
      />
    ),
    'Permission Scopes': (
      <TaskFilterSB
        disabled={disableCategorySelect()}
        searchEvent={(data: TaskSearchForm) => updateSearch(data)}
      />
    ),
    'Product Catalog': (
      <ProductCatalogSB
        disabled={disableCategorySelect()}
        searchEvent={(data: ProductCatalogSBSearchForm) => updateSearch(data)}
      />
    ),
    'Role Templates': (
      <TaskFilterSB
        disabled={disableCategorySelect()}
        searchEvent={(data: TaskSearchForm) => updateSearch(data)}
      />
    ),
    SPTs: (
      <TaskFilterSB
        disabled={disableCategorySelect()}
        searchEvent={(data: TaskSearchForm) => updateSearch(data)}
      />
    ),
    Services: undefined,
    ServiceInstances: undefined,
    ServicePlan: (
      <ServicePlanSB
        disabled={disableCategorySelect()}
        searchEvent={(data: ServicePlanSBSearchForm) => updateSearch(data)}
      />
    ),
    Settings: undefined,
    'Single Object': (
      <SingletObjectsSB
        disabled={disableCategorySelect()}
        searchEvent={(data: SingleObjectForm) => updateSearch(data)}
      />
    ),
    StockKeepingUnit: (
      <StockKeepingUnitSB
        disabled={disableCategorySelect()}
        searchEvent={(data: StockKeepingUnitSBSearchForm) => updateSearch(data)}
      />
    ),
    TrustedCertificate: undefined,
    TypeConfiguration: undefined,
    '': undefined,
  };

  return (
    <div className="SystemSidebar">
      <>
        <label className="label" htmlFor="system-object-type">
          System Object Type
        </label>
        <Dropdown
          placeholder="Search/Select Object Type"
          onChange={(e, { value }) => setObjectType(value as ObjectTypeComponentKeys)}
          fluid
          search
          selection
          id="system-object-type"
          options={SystemObjectTypes}
          value={objectType}
        />
        <div className="component-map">
          {ObjectTypeComponentMap[`${objectType}`]}
        </div>
      </>
    </div>
  );
};

export default SystemSidebar;
