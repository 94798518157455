/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractSystemSettings {
    'directoryOperations'?: { [key: string]: boolean; } | null;
    'directoryOperationsAllowedToDisable'?: { [key: string]: boolean; } | null;
    'sliceDirectoryOperations'?: { [key: string]: { [key: string]: boolean; }; } | null;
    'systemFunctionalLevel'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "directoryOperations",
            "baseName": "directoryOperations",
            "type": "{ [key: string]: boolean; }",
            "format": ""
        },
        {
            "name": "directoryOperationsAllowedToDisable",
            "baseName": "directoryOperationsAllowedToDisable",
            "type": "{ [key: string]: boolean; }",
            "format": ""
        },
        {
            "name": "sliceDirectoryOperations",
            "baseName": "sliceDirectoryOperations",
            "type": "{ [key: string]: { [key: string]: boolean; }; }",
            "format": ""
        },
        {
            "name": "systemFunctionalLevel",
            "baseName": "systemFunctionalLevel",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractSystemSettings.attributeTypeMap;
    }

    public constructor() {
    }
}

