import type { App } from 'app/store';
import { KeyGroupsSearchForm } from 'app/components/shared/sidebar-children/KeyGroupsSB/KeyGroupsSB';
import { GroupsSearchForm } from 'app/components/shared/sidebar-children/GroupsSB/GroupsSB';
import { UsersSearchForm } from 'app/components/shared/sidebar-children/UsersSB/UserIndexSB';
import {
  DownloadCompanyObjectLinks,
  GetCompanyByNameParams, GetReplicationMetaDataParams, GetSingleLicenseParams, GetSingleObjectLinksParams, GetSingleObjectParams,
} from 'app/actions/companyActions';
import { ApplicationsSearchForm } from 'app/components/shared/sidebar-children/ApplicationsSB/ApplicationsSB';
import { ContractsSearchForm } from 'app/components/shared/sidebar-children/ContractsSB/ContractsSB';
import { SingleObjectForm } from 'app/components/shared/sidebar-children/SingleObjectSB/SingleObjectSB';
import { TrustedRealmSearchForm } from 'app/components/shared/sidebar-children/TrustedRealmSB/TrustedRealmSB';
import { ServicePrincipalsForm } from 'app/components/shared/sidebar-children/ServicePrincipalsSB/ServicePrincipalsSB';
import { ALOSearchForm } from 'app/components/shared/sidebar-children/AddressListObjectsSB/AddressListObjectsSB';
import { EntitlementGrantsSearchForm } from 'app/components/shared/sidebar-children/EntitlementGrantsSB/EntitlementGrantsSB';
import { DirSyncErrorsSearchForm } from 'app/components/shared/sidebar-children/DirSyncErrorsSB/DirSyncErrorsSB';
import { GroupLicensingErrorsSearchForm } from 'app/components/shared/sidebar-children/GroupLicensingErrorsSB/GroupLicensingErrorsSB';
import { ScopedMembersSearchForm } from 'app/components/shared/sidebar-children/ScopedMembersSB/ScopedMembersSB';
import { RoleAssignmentsSearchForm } from 'app/components/shared/sidebar-children/RoleAssignmentsSB/RoleAssignmentsSB';
import { DelegationEntriesSearchForm } from 'app/components/shared/sidebar-children/DelegationEntriesSB/DelegationEntriesSB';
import { IdentifierSearchForm } from 'app/components/shared/sidebar-children/IdentifierSB/IdentifierSB';
import { AltSecIdSearchForm } from 'app/components/shared/sidebar-children/AltSecIdSB/AltSecIdSB';
import { InvitationSearchForm } from 'app/components/shared/sidebar-children/InvitationTicket/InvitationSB';
import { DeletedSearchForm, ObjectsSearchForm } from 'app/typings';
import {
  DirectoryProperty, PropertyFilterModel, PropertySearchRequestModel, SearchFilterOperator,
} from 'app/proxyClients';
import { DeviceSearchForm } from '../components/shared/sidebar-children/DeviceSB/DeviceSB';
import { getPageSizeFromState } from './preferences';

export async function getCompanyByIdentifier(app: App, p: GetCompanyByNameParams) {
  const {
    companyIdentifier, contextEpoch, partitionId, softDeleted,
  } = p; // Strip "path" and "type" options out.
  return app.clients.company.getCompany(
    companyIdentifier,
    contextEpoch,
    partitionId,
    softDeleted,
  );
}

export async function getCompanyObjectLinkCounts(app: App, companyIdentifier: string) {
  return app.clients.search.browseObjectCounts(companyIdentifier);
}

export async function getSingleObject(app: App, p: GetSingleObjectParams | SingleObjectForm) {
  return app.clients.object.getObject(p.contextId, p.objectId, p.softDeleted);
}

export async function getSingleObjectLinks(app: App, p: GetSingleObjectLinksParams) {
  if (p.companyIdentifier) { // For Company:
    return app.clients.object.getObjectLinksFromCompany(p.companyIdentifier, p.objectId, p.directoryLinkType);
  } // For System:
  return app.clients.object.getObjectLinksFromSytemContext(p.objectId, p.directoryLinkType);
}

export async function downloadObjLinksFromCompany(app: App, p: DownloadCompanyObjectLinks) {
  return app.clients.object.downloadObjectLinksFromCompany(p.companyIdentifier, p.objectId, p.directoryLinkType);
}

export async function getReplicationMetaDataforObject(app: App, p: GetReplicationMetaDataParams) {
  return app.clients.object.getReplicationMetadata(
    p.replicaName,
    p.objectClass,
    p.contextId,
    p.contextEpoch,
    p.partitionId,
    undefined,
    p.objectId,
    undefined,
  );
}
export async function getObjects(app: App, p: ObjectsSearchForm) {
  return app.clients.search.pagedObjects(new PropertySearchRequestModel({
    companyIdentifier: p.companyIdentifier as string,
    properties: p.properties as DirectoryProperty[],
    filter: p.filter.map((d) => new PropertyFilterModel({
      comparisonOperator: d.comparisonOperator as SearchFilterOperator,
      conditionalOperator: d.conditionalOperator as SearchFilterOperator,
      property: d.property as DirectoryProperty,
      propertyValueString: d.propertyValueString as string,
    })),
    softDeleted: p.softDeleted,
    objectClass: p.objectClass,
    resultsLimit: getPageSizeFromState(app),
  }));
}

export async function getCompanyApplications(app: App, p: ApplicationsSearchForm & { companyIdentifier: string }) {
  return app.clients.search.applications(
    p.companyIdentifier,
    p.appIds,
    p.domainInUse,
    p.availableToOtherTenants,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyContracts(app: App, p: ContractsSearchForm & { companyIdentifier: string }) {
  return app.clients.search.contracts(
    p.companyIdentifier,
    p.targetContextId,
    p.displayName,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyUsers(app: App, p: UsersSearchForm & { companyIdentifier: string }) {
  return app.clients.search.users(
    p.companyIdentifier,
    p.adminsOnly,
    undefined,
    p.softDeleted,
    p.domainsInUse,
    p.synchronizedOnly,
    p.textFilter,
    p.assignedLicenseUnsetOnly,
    undefined,
    undefined,
    undefined,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyGroups(app: App, p: GroupsSearchForm & { companyIdentifier: string }) {
  return app.clients.search.groups(
    p.companyIdentifier,
    p.text,
    p.domainName,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyKeyGroups(app: App, p: KeyGroupsSearchForm & { companyIdentifier: string }) {
  return app.clients.search.keyGroups(
    p.companyIdentifier,
    p.keyGroupId,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyTrustedRealm(app: App, p: TrustedRealmSearchForm & { companyIdentifier: string }) {
  return app.clients.trustedRealm.getTrustedRealmByName(
    p.companyIdentifier,
    p.stsName,
  );
}

export async function getCompanyLicenses(app: App, p: unknown & { companyIdentifier: string }) {
  return app.clients.company.readCompanyLicenses(p.companyIdentifier);
}

export async function getCompanyLicense(app: App, p: GetSingleLicenseParams) {
  return app.clients.company.readCompanyLicense(p.companyIdentifier, p.skuId);
}

export async function getCompanyDevice(app: App, p: DeviceSearchForm & { companyIdentifier: string }) {
  return app.clients.device.getDevice(
    p.companyIdentifier,
    p.deviceId,
  );
}

export async function getCompanyDevices(app: App, p: { companyIdentifier: string }) {
  return app.clients.search.devices(
    p.companyIdentifier,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyServicePrincipals(app: App, p: ServicePrincipalsForm & { companyIdentifier: string }) {
  return app.clients.search.servicePrincipals(
    p.companyIdentifier,
    p.filterString,
    p.filterType,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyAddressListObjects(app: App, p: ALOSearchForm & { companyIdentifier: string }) {
  return app.clients.search.addressLists(
    p.companyIdentifier,
    p.exchangeSingleAuthorityOnly,
    p.proxyAddressFilterString,
    p.sourceAnchorFilterString,
    p.softDeleted,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyEntitlementGrants(app: App, p: EntitlementGrantsSearchForm & { companyIdentifier: string }) {
  return app.clients.search.entitlementGrants(
    p.companyIdentifier,
    p.displayNameFilterString,
    p.isIndirectGrants,
    p.sourceObjectId,
    p.targetObjectId,
    p.sourceObjectClass,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyDeleted(app: App, p: DeletedSearchForm & { companyIdentifier: string }) {
  return app.clients.search.deletedCompanyContext(
    p.companyIdentifier,
    p.objectId,
    p.directoryObjectClass,
  );
}

export async function getCompanyDirSycErrors(app: App, p: DirSyncErrorsSearchForm & { companyIdentifier: string }) {
  return app.clients.search.dirSyncProvisioningErrors(
    p.companyIdentifier,
    p.propertyValueFilterString,
    p.propertyNameFilterValue,
    p.errorCategory,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyGroupLicensingErrors(app: App, p: GroupLicensingErrorsSearchForm & { companyIdentifier: string }) {
  return app.clients.search.groupLicensingErrors(
    p.companyIdentifier,
    p.sourceObjectId,
    p.targetObjectId,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyScopedmemebers(app: App, p: ScopedMembersSearchForm & { companyIdentifier: string }) {
  return app.clients.search.scopedMembers(
    p.companyIdentifier,
    p.sourceObjectId,
    p.targetObjectId,
    p.scopeId,
    p.targetObjectClass,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyRoleAssignments(app: App, p: RoleAssignmentsSearchForm & { companyIdentifier: string }) {
  return app.clients.search.roleAssignments(
    p.companyIdentifier,
    p.sourceObjectId,
    p.targetObjectId,
    p.authorizationScopeType === -1 ? null : p.authorizationScopeType,
    p.authorizationScopeId,
    p.isSelfScope,
    p.targetObjectClass,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompanyDelegationEntries(app: App, p: DelegationEntriesSearchForm & { companyIdentifier: string }) {
  return app.clients.search.delegationEntries(
    p.companyIdentifier,
    p.sourceObjectId,
    p.targetObjectId,
    p.alternativeSecurityIdString,
    p.delegationType,
    p.userIdentifierString,
    p.userIdentifierType,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getCompUserById(app: App, p: IdentifierSearchForm & { companyIdentifier: string }) {
  return app.clients.user.getUserByIdentifier(
    p.companyIdentifier,
    p.userIdentifier,
    p.softDeleted,
  );
}

export async function getCompUserByAltSecId(app: App, p: AltSecIdSearchForm & { companyIdentifier: string }) {
  return app.clients.user.getUserByAltSecId(
    p.companyIdentifier,
    p.altSecIdType,
    p.altSecIdKey,
    p.altSecIdProvider,
    p.softDeleted,
  );
}

export async function getCompUserByInvitationTicket(app: App, p: InvitationSearchForm & { companyIdentifier: string }) {
  return app.clients.user.getUserByInvitationTicket(
    p.companyIdentifier,
    p.ticketValue,
    p.softDeleted,
  );
}

export async function getCompProvStatus(app: App, p: { companyIdentifier: string }) {
  return app.clients.company.readCompanyProvisioningStatusDetail(p.companyIdentifier);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function singleUserEndpoints(app: App, p: any): any { // 3 available endpoints for Single User
  if ('userIdentifier' in p) return getCompUserById(app, p);
  if ('ticketValue' in p) return getCompUserByInvitationTicket(app, p);
  if ('altSecIdKey' in p) return getCompUserByAltSecId(app, p);
  return 'No Single User Endpoint Found';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const companiesEndpointMap: Record<string, (app: App, p: any) => Promise<unknown>> = {
  '': getCompanyByIdentifier,
  Applications: getCompanyApplications,
  Contracts: getCompanyContracts,
  Users: getCompanyUsers,
  Groups: getCompanyGroups,
  'Key Groups': getCompanyKeyGroups,
  'Trusted Realm': getCompanyTrustedRealm,
  Licenses: getCompanyLicenses,
  Device: getCompanyDevice,
  Devices: getCompanyDevices,
  'Service Principals': getCompanyServicePrincipals,
  'Single Object': getSingleObject,
  Objects: getObjects,
  'Address List Objects': getCompanyAddressListObjects,
  'Entitlement Grants': getCompanyEntitlementGrants,
  Deleted: getCompanyDeleted,
  'DirSync Errors': getCompanyDirSycErrors,
  'Group Licensing Errors': getCompanyGroupLicensingErrors,
  'Scoped Members': getCompanyScopedmemebers,
  'Role Assignments': getCompanyRoleAssignments,
  'Delegation Entries': getCompanyDelegationEntries,
  'Single User': singleUserEndpoints,
  'Provisioning Status': getCompProvStatus,
};
