import auth from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import * as config from 'app/constants/config';
import * as constants from 'app/constants/constants';
import rootReducer from 'app/reducers/rootReducer';
import { State } from 'app/reducers/state';
import rootSagas from 'app/sagas/rootSagas';
import axios from 'axios';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import {
  applyMiddleware, CombinedState, createStore, Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { defaultActiveTabState } from './reducers/activeTab';
import { defaultAnnouncementsState } from './reducers/announcements';
import { defaultPreferencesState } from './reducers/preferences';
import { defaultEnumState } from './reducers/enums';
import {
  AnnouncementsApi,
  ApplicationApi,
  AuthApi,
  CompanyApi,
  ConfigurationApi,
  DeviceApi,
  IndexStoreApi,
  LinkStateApi,
  ObjectApi,
  PartitionApi,
  PreferencesApi,
  SearchApi,
  ServerConfiguration,
  ServiceApi,
  ToolboxApi,
  TrustedRealmApi,
  UserApi,
} from './client';
import { Configuration, createConfiguration } from './client/configuration';
import { AxiosHttpLibrary } from './helpers/axioshttplibrary';

export type App = feathers.Application<unknown> & {
  constants: typeof constants;
  config: typeof config;
  rest: rest.HandlerResult,
  authentication: unknown,
  store?: Store<CombinedState<State>>,
  clients: {
    applications: ApplicationApi,
    auth: AuthApi,
    announcements: AnnouncementsApi,
    company: CompanyApi
    linkState: LinkStateApi,
    object: ObjectApi,
    toolbox: ToolboxApi,
    trustedRealm: TrustedRealmApi,
    search: SearchApi,
    service: ServiceApi,
    system: ConfigurationApi
    device: DeviceApi,
    preferences: PreferencesApi,
    indexStore: IndexStoreApi,
    partitions: PartitionApi,
    user: UserApi
  }
};

export const history = createBrowserHistory();
export const app:App = feathers<unknown>() as App;

app.configure(auth({
  path: '/public/auth/signin',
  jwtStrategy: 'jwt',
  storageKey: 'feathers-jwt',
  storage: window.localStorage,
}));

const defaultAppState = {
  preferences: defaultPreferencesState,
  announcements: defaultAnnouncementsState,
  activeTabs: defaultActiveTabState,
  enums: defaultEnumState,
};

export function configureApplicationObject() {
  // Add other Constants
  app.constants = { ...constants };

  // Add Config
  app.config = { ...config };

  // Connect to a different URL
  const restClient = rest(app.config.getServer());

  // Configure an AJAX library
  app.configure(restClient.axios(axios));

  if (app.config.shouldSendCredentials() === true) {
    axios.defaults.withCredentials = true;
  }

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response !== undefined && error.response.status === 401) {
        window.location.href = error.response.headers.location;
      }

      // Do something with response error
      return Promise.reject(error);
    },
  );

  // Request interceptor that will forcibly remove auth header
  // when app is not configured to use it.
  axios.interceptors.request.use((requestConfig) => {
    // Ignore requests not intended for the server
    if (!requestConfig.url?.startsWith(app.config.getServer())) {
      return requestConfig;
    }

    if (app.config.shouldSendAuthHeader() === false) {
      if (requestConfig.headers) {
        // eslint-disable-next-line no-param-reassign
        delete requestConfig.headers.Authorization;
      }
    }

    return requestConfig;
  });

  const configuration: Configuration = createConfiguration({
    baseServer: new ServerConfiguration(app.config.getServer(), {}),
    httpApi: new AxiosHttpLibrary(axios),
  });

  app.clients = {
    applications: new ApplicationApi(configuration),
    auth: new AuthApi(configuration),
    announcements: new AnnouncementsApi(configuration),
    company: new CompanyApi(configuration),
    linkState: new LinkStateApi(configuration),
    object: new ObjectApi(configuration),
    toolbox: new ToolboxApi(configuration),
    trustedRealm: new TrustedRealmApi(configuration),
    search: new SearchApi(configuration),
    service: new ServiceApi(configuration),
    system: new ConfigurationApi(configuration),
    device: new DeviceApi(configuration),
    preferences: new PreferencesApi(configuration),
    indexStore: new IndexStoreApi(configuration),
    partitions: new PartitionApi(configuration),
    user: new UserApi(configuration),
  };
}

export const createApplicationStore = (initialState: State = defaultAppState) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
      ),
    ),
  );

  // Set Store Reference
  app.store = store;

  rootSagas.map((saga) => sagaMiddleware.run(saga, app)); // Register all sagas
  return store;
};
