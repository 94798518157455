/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 1 = Account 3 = Contact 4 = Contract 5 = ForeignPrincipal 6 = Group 8 = Role 15 = SubscribedPlan 16 = Subscription 20 = User 22 = ServicePrincipal 24 = KeyGroup 27 = TrustedRealm 29 = Application 30 = ApplicationStub 31 = Device 32 = AltSecIdTenantMap 34 = CollaborationSpace 35 = DeviceConfiguration 36 = TrustedRealmStub 38 = EvoStsConfiguration 39 = PublicFolder 40 = AdministrativeUnit 41 = Policy 42 = Segment 43 = RoleDefinition 50 = ComplexExtensionDefinition 55 = AuthorizationPolicy 56 = KerberosDomain 59 = Label 60 = PermissionGrantPolicy 62 = GroupProxy 64 = UserProxy 65 = AssociationInvitation 66 = CompanyAssociation 76 = PrivateLinkResource 77 = PrivateEndpoint 78 = CustomSecurityAttribute 79 = CertBasedAuthConfiguration 80 = DeviceTemplate 83 = PendingExternalUserProfile 84 = ExternalUserProfile 85 = SpCreationPolicy 86 = PartnerPolicy 87 = Agreement 89 = AttributeSetEntity 90 = PLIds64BitsTenantIdMap 92 = CertBasedConfiguration 100 = MultiTenantOrg 101 = MultiTenantOrgTenant 102 = FederatedIdentityIssuer
*/
export enum CommonModelsCompanyContextObjectClasses {
    Account = 1,
    Contact = 3,
    Contract = 4,
    ForeignPrincipal = 5,
    Group = 6,
    Role = 8,
    SubscribedPlan = 15,
    Subscription = 16,
    User = 20,
    ServicePrincipal = 22,
    KeyGroup = 24,
    TrustedRealm = 27,
    Application = 29,
    ApplicationStub = 30,
    Device = 31,
    AltSecIdTenantMap = 32,
    CollaborationSpace = 34,
    DeviceConfiguration = 35,
    TrustedRealmStub = 36,
    EvoStsConfiguration = 38,
    PublicFolder = 39,
    AdministrativeUnit = 40,
    Policy = 41,
    Segment = 42,
    RoleDefinition = 43,
    ComplexExtensionDefinition = 50,
    AuthorizationPolicy = 55,
    KerberosDomain = 56,
    Label = 59,
    PermissionGrantPolicy = 60,
    GroupProxy = 62,
    UserProxy = 64,
    AssociationInvitation = 65,
    CompanyAssociation = 66,
    PrivateLinkResource = 76,
    PrivateEndpoint = 77,
    CustomSecurityAttribute = 78,
    CertBasedAuthConfiguration = 79,
    DeviceTemplate = 80,
    PendingExternalUserProfile = 83,
    ExternalUserProfile = 84,
    SpCreationPolicy = 85,
    PartnerPolicy = 86,
    Agreement = 87,
    AttributeSetEntity = 89,
    PLIds64BitsTenantIdMap = 90,
    CertBasedConfiguration = 92,
    MultiTenantOrg = 100,
    MultiTenantOrgTenant = 101,
    FederatedIdentityIssuer = 102
}
