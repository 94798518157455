/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDirectoryServicesAssignedPlanInitialState } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlanInitialState';
import { SystemXmlLinqXNode } from '../models/SystemXmlLinqXNode';
import { SystemXmlLinqXNodeAllOfNextNode } from '../models/SystemXmlLinqXNodeAllOfNextNode';
import { SystemXmlLinqXNodeAllOfPreviousNode } from '../models/SystemXmlLinqXNodeAllOfPreviousNode';
import { SystemXmlLinqXObjectDocument } from '../models/SystemXmlLinqXObjectDocument';
import { HttpFile } from '../http/http';

export class SystemXmlLinqXContainerAllOfLastNode {
    'baseUri'?: string;
    'document'?: SystemXmlLinqXObjectDocument | null;
    'parent'?: MicrosoftOnlineDirectoryServicesAssignedPlanInitialState | null;
    'nextNode'?: SystemXmlLinqXNodeAllOfNextNode | null;
    'previousNode'?: SystemXmlLinqXNodeAllOfPreviousNode | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "baseUri",
            "baseName": "baseUri",
            "type": "string",
            "format": ""
        },
        {
            "name": "document",
            "baseName": "document",
            "type": "SystemXmlLinqXObjectDocument",
            "format": ""
        },
        {
            "name": "parent",
            "baseName": "parent",
            "type": "MicrosoftOnlineDirectoryServicesAssignedPlanInitialState",
            "format": ""
        },
        {
            "name": "nextNode",
            "baseName": "nextNode",
            "type": "SystemXmlLinqXNodeAllOfNextNode",
            "format": ""
        },
        {
            "name": "previousNode",
            "baseName": "previousNode",
            "type": "SystemXmlLinqXNodeAllOfPreviousNode",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SystemXmlLinqXContainerAllOfLastNode.attributeTypeMap;
    }

    public constructor() {
    }
}

