/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent, useState } from 'react';
import { Accordion, AccordionTitleProps, Icon } from 'semantic-ui-react';

const Help: FunctionComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, titleProps: AccordionTitleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex as number);
  };

  const faq = [
    {
      question: 'What group do I need to use this application?',
      answer: (
        <p>
          You can join the *ME\pso-clm_MSODSDSExplorer-Reader group to gain access.
          <br />
          You must have a token with the DSE-
          <strong>
            &lt;env&gt;
          </strong>
          -PlatformServiceViewer claim.
          <i>
            &nbsp;Where
            <strong> env </strong>
            is either PPE or PROD.
          </i>
        </p>
      ),
    },
    {
      question: 'Does this application display PII/EUII?',
      answer: 'No. Currently the application is not authorized to display PII or EUII even if you have the proper JIT access.',
    },
    {
      question: 'Can I share my view with anyone else?',
      answer: 'Yes! Click the arrow icon in the Navbar to generate a shareable link for your view.',
    },
    {
      question: 'Will my preferences persist across devices?',
      answer: 'Yes! All UI preferences are saved in our backend database and follow you wherever you login.',
    },
    {
      question: 'Who can I contact if I have an issue with this application?',
      answer: (
        <p>
          Email the team at&nbsp;
          <a href="mailto:DSExplorer@service.microsoft.com">DSExplorer@service.microsoft.com</a>
        </p>
      ),
    },
    {
      question: 'Can I help contribute?',
      answer: (
        <p>
          Yes! Our repo is available here:&nbsp;
          <a target="#" href="https://msazure.visualstudio.com/One/_git/AD-MSODS-DSExplorer">https://msazure.visualstudio.com/One/_git/AD-MSODS-DSExplorer</a>
        </p>
      ),
    },
  ];

  const accordionContent = faq.map((q, index) => (
    <div key={`faq-${index}`}>
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        {q.question}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <div>
          {q.answer}
        </div>
      </Accordion.Content>
    </div>
  ));

  return (
    <>
      <Accordion styled>{accordionContent}</Accordion>
    </>
  );
};

export default Help;
