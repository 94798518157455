import _ from 'lodash';
import {
  GetAnnouncementsCompleted, AddAnnouncementCompleted, UpdateAnnouncementCompleted, AcknowledegeAnnouncementCompleted,
} from 'app/actions/announcementActions';
import moment from 'moment';
import { StatusMessageDataModel } from 'app/helpers/types';
import { Action } from 'redux';
import * as AnnouncementTypes from '../actions/announcementTypes';

export const defaultAnnouncementsState = {
  data: [],
  updated: moment(),
  lastOperation: undefined,
  modalSeen: false,
  mergeUpdatedAnnouncement(updated: StatusMessageDataModel) {
    const excludingCurrent = _.filter(this.data, (o: StatusMessageDataModel) => o.id !== updated.id);
    if (updated.isResolved) {
      return excludingCurrent;
    }

    return _.union(excludingCurrent, [updated]);
  },
};

export type AnnouncementState = {
  data: StatusMessageDataModel[],
  updated: moment.Moment
  lastOperation?: string,
  modalSeen: boolean,
  mergeUpdatedAnnouncement: (updated: StatusMessageDataModel) => StatusMessageDataModel[];
};

export type AnnouncementActions =
  | GetAnnouncementsCompleted
  | AddAnnouncementCompleted
  | UpdateAnnouncementCompleted
  | AcknowledegeAnnouncementCompleted
  | Action<typeof AnnouncementTypes.ADD_ANNOUNCEMENT>
  | Action<typeof AnnouncementTypes.UPDATE_ANNOUNCEMENT>
  | Action<typeof AnnouncementTypes.ACKNOWLEDGE_ANNOUNCEMENT>
  | Action<typeof AnnouncementTypes.ADD_ANNOUNCEMENT_FAILED>
  | Action<typeof AnnouncementTypes.ANNOUCEMENTS_MODAL_SEEN>;

export default (announcementState: AnnouncementState = defaultAnnouncementsState, action: AnnouncementActions): AnnouncementState => {
  switch (action.type) {
    case AnnouncementTypes.ANNOUCEMENTS_MODAL_SEEN:
      return { ...announcementState, modalSeen: true };
    case AnnouncementTypes.ACKNOWLEDGE_ANNOUNCEMENT:
    case AnnouncementTypes.UPDATE_ANNOUNCEMENT:
    case AnnouncementTypes.ADD_ANNOUNCEMENT:
      return { ...announcementState, updated: moment(), lastOperation: 'pending' };
    case AnnouncementTypes.GET_ANNOUNCEMENTS_COMPLETED:
      return {
        ...announcementState, updated: moment(), lastOperation: 'success', data: action.resp.result ?? [],
      };
    case AnnouncementTypes.ADD_ANNOUNCEMENT_COMPLETED:
      return {
        ...announcementState, updated: moment(), lastOperation: 'success', data: _.union(announcementState.data, [action.resp]),
      };
    case AnnouncementTypes.UPDATE_ANNOUNCEMENT_COMPLETED:
      return {
        ...announcementState,
        updated: moment(),
        lastOperation: 'success',
        data: announcementState.mergeUpdatedAnnouncement(action.resp),
      };
    case AnnouncementTypes.ACKNOWLEDGE_ANNOUNCEMENT_COMPLETED: {
      const dataCopy = [...announcementState.data];
      const index = dataCopy.findIndex((item) => item.id === action.resp.result?.messageId);
      dataCopy[+`${index}`].hasBeenAcknowledged = true;
      return {
        ...announcementState, updated: moment(), lastOperation: 'success', data: dataCopy,
      };
    }
    case AnnouncementTypes.ADD_ANNOUNCEMENT_FAILED:
      return { ...announcementState, updated: moment(), lastOperation: 'failed' };
    default:
      return announcementState;
  }
};
