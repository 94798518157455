/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CommonAuthenticationModelUserIdentity } from '../models/CommonAuthenticationModelUserIdentity';
import { HttpFile } from '../http/http';

export class CommonAuthenticationModelAuthRequestModelUserIdentity {
    'name'?: string;
    'isPlatformServiceAdministrator'?: boolean;
    'securityGroupMembership'?: Array<string>;
    'identityProvider'?: string;
    'claims'?: Array<string>;
    'isPlatformServiceOperator'?: boolean;
    'isPlatformServiceViewer'?: boolean;
    'originatingCloudId'?: string;
    'isSawExemptionOperator'?: boolean;
    'isSawExemptionPreviewMember'?: boolean;
    'isLoggedInFromSaw'?: boolean;
    'hasSawExemption'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "isPlatformServiceAdministrator",
            "baseName": "isPlatformServiceAdministrator",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "securityGroupMembership",
            "baseName": "securityGroupMembership",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "identityProvider",
            "baseName": "identityProvider",
            "type": "string",
            "format": ""
        },
        {
            "name": "claims",
            "baseName": "Claims",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "isPlatformServiceOperator",
            "baseName": "isPlatformServiceOperator",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isPlatformServiceViewer",
            "baseName": "isPlatformServiceViewer",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "originatingCloudId",
            "baseName": "originatingCloudId",
            "type": "string",
            "format": ""
        },
        {
            "name": "isSawExemptionOperator",
            "baseName": "isSawExemptionOperator",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isSawExemptionPreviewMember",
            "baseName": "isSawExemptionPreviewMember",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isLoggedInFromSaw",
            "baseName": "isLoggedInFromSaw",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "hasSawExemption",
            "baseName": "hasSawExemption",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CommonAuthenticationModelAuthRequestModelUserIdentity.attributeTypeMap;
    }

    public constructor() {
    }
}

