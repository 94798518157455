import { Action } from 'redux';
import * as PartitionTypes from 'app/actions/partitionTypes';
import { DSPartitionDetails, ResponseOfDSPartitionDetailsOf } from 'app/proxyClients';

export type PartitionState = {
  partitionIds: Array<DSPartitionDetails> | undefined
  error: boolean
  message: string
  loading: boolean
};

const state: PartitionState = {
  partitionIds: [],
  error: false,
  message: '',
  loading: false,
};

type PartitionAction =
    GetPartitionDetails
    | GetPartitionDetailsCompleted
    | GetPartitionDetailsFailed;

type GetPartitionDetails = Action<typeof PartitionTypes.GET_PARTITION_DETAILS>;
type GetPartitionDetailsCompleted = Action<typeof PartitionTypes.GET_PARTITION_DETAILS_COMPLETED> & {
  resp: ResponseOfDSPartitionDetailsOf
};
type GetPartitionDetailsFailed = Action<typeof PartitionTypes.GET_PARTITION_DETAILS_FAILED> & { ex: { message: string } };

export default (partitionState: PartitionState = state, action: PartitionAction): PartitionState => {
  switch (action.type) {
    case PartitionTypes.GET_PARTITION_DETAILS:
      return {
        ...partitionState, error: false, message: '', loading: true,
      };
    case PartitionTypes.GET_PARTITION_DETAILS_COMPLETED:
      return {
        ...partitionState, partitionIds: action.resp.data, error: false, message: '', loading: false,
      };
    case PartitionTypes.GET_PARTITION_DETAILS_FAILED:
      return {
        ...partitionState, error: true, message: action.ex.message, loading: false,
      };
    default:
      return partitionState;
  }
};
