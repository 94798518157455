import React, { FunctionComponent, useState } from 'react';
import {
  Button, Checkbox, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export type UsersSearchForm = Record<string, unknown> & {
  domainsInUse: string,
  textFilter: string,
  adminsOnly: boolean | undefined,
  assignedLicenseUnsetOnly: boolean | undefined,
  synchronizedOnly: boolean | undefined,
  softDeleted: boolean | undefined
};

export const defaultUsersIndexForm: UsersSearchForm = {
  domainsInUse: '',
  textFilter: '',
  adminsOnly: false,
  assignedLicenseUnsetOnly: false,
  synchronizedOnly: false,
  softDeleted: false,
  skipTabCreation: true,
};

const UsersIndexSB: FunctionComponent<ObjectTypeProps<UsersSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<UsersSearchForm>) => {
  const [searchForm, setSearchForm] = useState<UsersSearchForm>(defaultUsersIndexForm);

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Domain Name
          <Popup
            flowing
            position="bottom left"
            content="If set, the search will be scoped to objects from this domain (either by UPN or ProxyAddress)"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.domainsInUse}
                className="margin-bottom"
                fluid
                placeholder="Enter the domain name"
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, domainsInUse: value })}
              />
                    )}
          />
        </label>
        <label className="label">
          Text
          <Popup
            flowing
            position="bottom left"
            content="If set, this yeilds LDAP search filter: (|(displayName=Text*)(mail=Text*))"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.textFilter}
                className="last-form-item"
                fluid
                placeholder="Search DisplayName or Mail"
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, textFilter: value })}
              />
                    )}
          />
        </label>
        <Popup
          flowing
          position="bottom left"
          content="If selected, the search will only return administrators"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="margin-bottom block"
              toggle
              label="Administrators Only"
              checked={searchForm.adminsOnly}
              onChange={(e, { checked }) => setSearchForm({
                ...searchForm,
                adminsOnly: checked,
              })}
            />
                    )}
        />
        <Popup
          flowing
          position="bottom left"
          content="If selected, the search will only include users whose AssignedLicense property is unset"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="margin-bottom block"
              toggle
              label="Unlicensed Only"
              checked={searchForm.assignedLicenseUnsetOnly}
              onChange={(e, { checked }) => setSearchForm({
                ...searchForm,
                assignedLicenseUnsetOnly: checked,
              })}
            />
                    )}
        />
        <Popup
          flowing
          position="bottom left"
          content="If selected, the search will only returns accounts that are synchronized to the client company's directory"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="margin-bottom block"
              toggle
              label="Sync Only"
              checked={searchForm.synchronizedOnly}
              onChange={(e, { checked }) => setSearchForm({
                ...searchForm,
                synchronizedOnly: checked,
              })}
            />
                    )}
        />
        <Popup
          flowing
          position="bottom left"
          content="Restricts search to accounts in the recylce bin"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="last-form-item block"
              toggle
              label="Soft deleted Only"
              checked={searchForm.softDeleted}
              onChange={(e, { checked }) => setSearchForm({
                ...searchForm,
                softDeleted: checked,
              })}
            />
                    )}
        />
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => {
              setSearchForm(defaultUsersIndexForm);
              searchEvent(defaultUsersIndexForm);
            }}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default UsersIndexSB;
