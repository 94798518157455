import type { App } from 'app/store';
import { AltSecIdSearchForm } from 'app/components/shared/sidebar-children/AltSecIdSB/AltSecIdSB';
import { ApplicationIndexSearchForm } from 'app/components/shared/sidebar-children/ApplicationIndexSB/ApplicationIndexSB';
import { TrustedRealmIndexSearchForm } from 'app/components/shared/sidebar-children/TrustedRealmIndexSB/TrustedRealmIndexSB';
import { AltSecIdType } from 'app/proxyClients';
import { getPageSizeFromState } from './preferences';

export async function searchAltSecIdMapStore(app: App, p: AltSecIdSearchForm) {
  return app.clients.indexStore.searchAltSecIdStore(
    p.altSecIdType as AltSecIdType,
    p.altSecIdKey,
    p.altSecIdProvider,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getApplicationStub(app: App, p: ApplicationIndexSearchForm) {
  return app.clients.indexStore.getApplicationStub(
    p.appId,
  );
}

export async function getTrustedRealmStub(app: App, p: TrustedRealmIndexSearchForm) {
  return app.clients.indexStore.getTrustedRealmStub(
    p.issuerUri,
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const indexStoreEndpointMap: Record<string, (app: App, p: any) => Promise<unknown>> = {
  AltSecId: searchAltSecIdMapStore,
  Application: getApplicationStub,
  TrustedRealm: getTrustedRealmStub,
};
