import ContentModal from 'app/components/shared/ContentModal/ContentModal';
import { DownloadFile } from 'app/helpers/helpers';
import React, { FunctionComponent } from 'react';
import { Grid, Button } from 'semantic-ui-react';

export type LicenseRow = {
  'Subscription Id': string,
  'Prepaid Units': string,
  'Max Overage Units': string,
  'Start Date': JSX.Element,
  'Next Lifecycle Date': JSX.Element,
  'Trial Subscription': string,
};

type LicenseModalProps = {
  setModalOpen: (x: boolean) => void;
  selectedRow: LicenseRow;
  modalOpen: boolean;
};

const LicenseModal: FunctionComponent<LicenseModalProps> = ({
  selectedRow,
  setModalOpen,
  modalOpen,
}: LicenseModalProps) => {
  const downloadTxtFile = () => {
    const content = JSON.stringify(selectedRow);
    const name = `${selectedRow['Subscription Id']}-details.txt`;
    DownloadFile(name, content, 'text/plain');
  };

  const modalContent = (
    <>
      <Grid columns="equal" divided>
        <Grid.Row>
          <Grid.Column width={10}>
            <strong>Subscription Id:</strong>
            {' '}
            {selectedRow['Subscription Id']}
          </Grid.Column>
          <Grid.Column>
            <strong>Prepaid Units:</strong>
            {' '}
            {selectedRow['Prepaid Units']}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            <strong>Start Date:</strong>
            {' '}
            {selectedRow['Start Date']}
          </Grid.Column>
          <Grid.Column>
            <strong>Next Lifecycle Date:</strong>
            {' '}
            {selectedRow['Next Lifecycle Date']}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            <strong>Max Overage Units:</strong>
            {' '}
            {selectedRow['Max Overage Units']}
          </Grid.Column>
          <Grid.Column>
            <strong>Trial Subscription:</strong>
            {' '}
            {selectedRow['Trial Subscription']}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );

  const actionContent = (
    <>
      <Button icon="download" onClick={() => downloadTxtFile()} content="Notepad" />
      <Button primary className="ok-btn blue" onClick={() => setModalOpen(false)}>OK</Button>
    </>
  );

  return (
    <ContentModal
      className="CompanyContentModal"
      closeEvent={() => { setModalOpen(false); }}
      style="success"
      open={modalOpen}
      header={selectedRow['Subscription Id']}
      message={modalContent}
      actions={actionContent}
    />
  );
};

export default LicenseModal;
