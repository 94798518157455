/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DSExplorerWebHostModelsPropertyFilterModel } from '../models/DSExplorerWebHostModelsPropertyFilterModel';
import { MicrosoftOnlineDirectoryServicesDirectoryLinkClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryLinkClass';
import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
import { MicrosoftOnlineDirectoryServicesDirectoryProperty } from '../models/MicrosoftOnlineDirectoryServicesDirectoryProperty';
import { HttpFile } from '../http/http';

export class DSExplorerWebHostModelsBulkLinkSearchRequestModel {
    'companyIdentifier'?: string;
    'properties'?: Array<MicrosoftOnlineDirectoryServicesDirectoryProperty>;
    'softDeleted'?: boolean;
    'filter'?: Array<DSExplorerWebHostModelsPropertyFilterModel>;
    'resultsLimit'?: number | null;
    'pageToken'?: string | null;
    'replicaToken'?: string | null;
    'linkClass'?: MicrosoftOnlineDirectoryServicesDirectoryLinkClass;
    'maxLinkRange'?: number;
    'sourceObjectClass'?: MicrosoftOnlineDirectoryServicesDirectoryObjectClass;
    'sourceObjectId'?: string | null;
    'targetObjectClass'?: MicrosoftOnlineDirectoryServicesDirectoryObjectClass;
    'targetObjectId'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "companyIdentifier",
            "baseName": "companyIdentifier",
            "type": "string",
            "format": ""
        },
        {
            "name": "properties",
            "baseName": "properties",
            "type": "Array<MicrosoftOnlineDirectoryServicesDirectoryProperty>",
            "format": ""
        },
        {
            "name": "softDeleted",
            "baseName": "softDeleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "filter",
            "baseName": "filter",
            "type": "Array<DSExplorerWebHostModelsPropertyFilterModel>",
            "format": ""
        },
        {
            "name": "resultsLimit",
            "baseName": "resultsLimit",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "pageToken",
            "baseName": "pageToken",
            "type": "string",
            "format": ""
        },
        {
            "name": "replicaToken",
            "baseName": "replicaToken",
            "type": "string",
            "format": ""
        },
        {
            "name": "linkClass",
            "baseName": "linkClass",
            "type": "MicrosoftOnlineDirectoryServicesDirectoryLinkClass",
            "format": ""
        },
        {
            "name": "maxLinkRange",
            "baseName": "maxLinkRange",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "sourceObjectClass",
            "baseName": "sourceObjectClass",
            "type": "MicrosoftOnlineDirectoryServicesDirectoryObjectClass",
            "format": ""
        },
        {
            "name": "sourceObjectId",
            "baseName": "sourceObjectId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "targetObjectClass",
            "baseName": "targetObjectClass",
            "type": "MicrosoftOnlineDirectoryServicesDirectoryObjectClass",
            "format": ""
        },
        {
            "name": "targetObjectId",
            "baseName": "targetObjectId",
            "type": "string",
            "format": "uuid"
        }    ];

    static getAttributeTypeMap() {
        return DSExplorerWebHostModelsBulkLinkSearchRequestModel.attributeTypeMap;
    }

    public constructor() {
    }
}



