export const IndexStoreObjectTypes = [
  { value: 'AltSecId', text: 'AltSecId' },
  { value: 'Application', text: 'Application' },
  { value: 'TrustedRealm', text: 'TrustedRealm' },
];

export type ObjectTypeComponentKeys =
'AltSecId' |
'Application' |
'TrustedRealm';
