import { DSTableExtraParams } from 'app/components/shared/DSTable/DSTableTypes';
import type { App } from 'app/store';
import { Moment } from 'moment';
import { ReactElement } from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import type { SagaGenerator } from 'typed-redux-saga/dist';
import { GenericAction, GenericDictionaryParams, ErrorMessageCallback } from './sharedTypes';

export enum TabType {
  Company = 'Company',
  System = 'System',
  Key = 'Key',
  Index = 'Index',
  Partition = 'Partition',
}

export type TabCollection = {
  activeTab?: string;
  tabs: TabDetails[];
};

export interface TabDetails {
  id: string | null;
  contextId?: string;
  allowClose?: boolean;
  name?: string;
  component?: ReactElement | undefined;
  metadata: TabMetadata;
  modalContent?: TabModalOptions,
  children: TabCollection;
  refreshed: number;
}

export type TabHistoryItem = TabDetails & {
  tabType: TabType;
  component: ReactElement;
  closedAt: moment.Moment;
  routePath: string;
  name: string;
};

export type PinnedTabDetails = TabDetails & {
  parentTab: string,
  lastIndex: number
};

export type WithTabDetails = {
  tab: TabDetails;
};

export type TabMetadata = {
  params?: Record<string, unknown>;
  created?: Moment;
  tabType?: TabType;
  routePath?: string;
  action?: string;
  children?: TabMetadata[]
};

export type TabGeneratorEffect<TReturn> = SagaGenerator<TReturn>;

export type TabOperation<TServiceParam, TDataReturned> = {
  params: GenericAction<TServiceParam> | TServiceParam;
  paramsAsAction?: boolean;
  extra?: GenericDictionaryParams;
  tabRoutingArea: TabType;
  tabType: string;
  tabId?: string;
  parentTab?: string;
  skipTabCreation?: boolean;
  refreshTab?: boolean;
  DSTableRefreshParams?: DSTableExtraParams
  actionBegin: string;
  actionComplete: string;
  actionFailed: string;
  callback?: (respsonse:TDataReturned) => void,
  asyncDataFetch: (
    app: App,
    p: TServiceParam
  ) => SagaGenerator<TDataReturned>;
  getDisplayName: (data: TDataReturned) => string | undefined;
  errorCallback?: ErrorMessageCallback;
};

export type TabModalOptions = {
  icon?: SemanticICONS,
  header?: string,
  style?: 'success' | 'caution' | 'error',
  content?: string | ReactElement
};
