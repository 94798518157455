/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = None 1 = And 2 = Or 3 = EqualsMatch 4 = PrefixMatch 5 = GreaterOrEqual 6 = LessOrEqual 7 = Any 8 = In 9 = Not 10 = NotEqualsMatch
*/
export enum MicrosoftOnlineDirectoryServicesSearchFilterOperator {
    None = 0,
    And = 1,
    Or = 2,
    EqualsMatch = 3,
    PrefixMatch = 4,
    GreaterOrEqual = 5,
    LessOrEqual = 6,
    Any = 7,
    In = 8,
    Not = 9,
    NotEqualsMatch = 10
}
