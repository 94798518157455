import _ from 'lodash';
import { CreateTabLinkActionRequested } from 'app/actions/tabActions';
import { LinkStateDataModel, LinkStateCollectionDataModel } from 'app/proxyClients';
import type { App } from 'app/store';
import moment from 'moment';

export async function getLinkState(app: App, id:string) {
  return app.clients.linkState.getLinkState(id);
}

export async function addLinkState(app: App, p:CreateTabLinkActionRequested) {
  const tabParams = _.map(p.TabMetadata, (t) => (new LinkStateDataModel({
    tabType: t.tabType,
    data: { ...t.params, children: t.children },
  })));

  return app.clients.linkState.addLinkState(new LinkStateCollectionDataModel({ createdAt: moment(), links: tabParams }));
}
