import companySagas from 'app/sagas/companySagas';
import authSagas from 'app/sagas/authSagas';
import systemSagas from 'app/sagas/systemSagas';
import linkStateSagas from 'app/sagas/linkStateSagas';
import searchSagas from 'app/sagas/searchSagas';
import indexStoreSagas from 'app/sagas/indexStoreSagas';
import preferenceSagas from 'app/sagas/preferenceSagas';
import partitionSagas from 'app/sagas/partitionSagas';
import announcementSagas from 'app/sagas/announcementSagas';

export default [
  announcementSagas,
  companySagas,
  systemSagas,
  authSagas,
  linkStateSagas,
  searchSagas,
  preferenceSagas,
  indexStoreSagas,
  partitionSagas,
];
