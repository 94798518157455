import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button, Checkbox, Input, Popup, Transition,
} from 'semantic-ui-react';
import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import { TabType, ErrorMessageCallback, SingleObjectCallback } from 'app/typings';
import { guidChecker } from 'app/helpers/helpers';

export type SingleObjectForm = Record<string, unknown> & {
  objectId: string;
  softDeleted?: boolean | undefined;
  contextId: string;
  callback?: SingleObjectCallback,
  skipTabCreation: boolean
  errorCallback?: ErrorMessageCallback
};

const SingletObjectsSB: FunctionComponent<ObjectTypeProps<SingleObjectForm>> = ({ disabled, searchEvent }: ObjectTypeProps<SingleObjectForm>) => {
  const tabsState = useSelector((state: State) => state.tabs);
  const activeTabState = useSelector((state: State) => state.activeTabs);
  const [searchForm, setSearchForm] = useState<SingleObjectForm>({
    objectId: '',
    softDeleted: false,
    contextId: '',
    skipTabCreation: false,
  });

  useEffect(() => {
    const activeTab = tabsState?.getTab(
      TabType.Company,
      activeTabState?.getActiveTab(TabType.Company) as string,
    );
    const data = activeTab?.metadata.params?.data as Record<string, unknown> || undefined;
    const contextId = data !== undefined ? data.companyIdentifier as string : '';
    setSearchForm({ ...searchForm, contextId });
  }, [activeTabState?.getActiveTab(TabType.Company)]);

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Single Object ID
          <Popup
            flowing
            position="bottom left"
            content="Enter the ObjectId.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.objectId.length > 0 && !guidChecker(searchForm.objectId)}
                disabled={disabled}
                value={searchForm.objectId}
                className="margin-bottom"
                fluid
                placeholder="Enter the ObjectId of the directory object you are looking for"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, objectId: value })}
              />
              )}
          />
        </label>
        <Popup
          flowing
          position="bottom left"
          content="If selected, the search will be scoped to the soft deleted object container (aka Recycle Bin)"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="margin-bottom"
              toggle
              label="Soft Deleted"
              checked={searchForm.softDeleted}
              onChange={(e, { checked }) => setSearchForm({ ...searchForm, softDeleted: checked })}
            />
            )}
        />

        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({
              objectId: '',
              softDeleted: false,
              contextId: '',
              skipTabCreation: false,
            })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.objectId === '' && !guidChecker(searchForm.objectId)}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default SingletObjectsSB;
