import { guidChecker } from 'app/helpers/helpers';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface GroupLicensingErrorsSearchForm {
  sourceObjectId: string,
  targetObjectId: string,
}

type GroupLicensingErrorsProps = ObjectTypeProps<GroupLicensingErrorsSearchForm>;

const GroupLicensingErrorsSB: FunctionComponent<GroupLicensingErrorsProps> = ({
  disabled,
  searchEvent,
}: GroupLicensingErrorsProps) => {
  const [searchForm, setSearchForm] = useState<GroupLicensingErrorsSearchForm>({
    sourceObjectId: '', targetObjectId: '',
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Source User ObjectId
          <Popup
            flowing
            position="bottom left"
            content="ObjectId of the source (User) object to filter on.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.sourceObjectId.length > 0 && !guidChecker(searchForm.sourceObjectId)}
                disabled={disabled}
                value={searchForm.sourceObjectId}
                className="margin-bottom"
                fluid
                placeholder="Search Source User ObjectId"
                onChange={(_e, { value }) => setSearchForm({
                  ...searchForm,
                  sourceObjectId: value,
                })}
              />
                      )}
          />
        </label>
        <label className="label">
          Target Group ObjectId
          <Popup
            flowing
            position="bottom left"
            content="ObjectId of the target (Group) object to filter on.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.targetObjectId.length > 0 && !guidChecker(searchForm.targetObjectId)}
                disabled={disabled}
                value={searchForm.targetObjectId}
                className="last-form-item"
                fluid
                placeholder="Search Target Group ObjectId"
                onChange={(_e, { value }) => setSearchForm({
                  ...searchForm,
                  targetObjectId: value,
                })}
              />
                      )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({
              sourceObjectId: '', targetObjectId: '',
            })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={
                            searchForm.sourceObjectId === ''
                            || searchForm.targetObjectId === ''
                        }
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default GroupLicensingErrorsSB;
