import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { StockKeepingUnitSBSearchForm } from 'app/typings';

const StockKeepingUnitSB: FunctionComponent<ObjectTypeProps<StockKeepingUnitSBSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<StockKeepingUnitSBSearchForm>) => {
  const [searchForm, setSearchForm] = useState<StockKeepingUnitSBSearchForm>({
    stockKeepingUnit: '',
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          SkuId or PartNumber
          <Popup
            flowing
            position="bottom left"
            content="Enter either the SkuId or PartNumber of the StockKeepingUnit you are looking for"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.stockKeepingUnit}
                className="last-form-item"
                fluid
                placeholder="Enter either the SkuId or PartNumber of the StockKeepingUnit you are looking for"
                // eslint-disable-next-line max-len
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, stockKeepingUnit: value })}
              />
                    )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ stockKeepingUnit: '' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default StockKeepingUnitSB;
