/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString {
    'item1'?: string | null;
    'item2'?: Array<string> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "item1",
            "baseName": "item1",
            "type": "string",
            "format": ""
        },
        {
            "name": "item2",
            "baseName": "item2",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString.attributeTypeMap;
    }

    public constructor() {
    }
}

