import './ZeroState.scss';

import React, { FunctionComponent } from 'react';
import {
  Header, Icon, Segment, SemanticICONS, Transition,
} from 'semantic-ui-react';

interface ZeroStateProps {
  icon?: SemanticICONS,
  text: string | undefined
}

const ZeroState: FunctionComponent<ZeroStateProps> = ({
  icon = 'searchengin',
  text,
}: ZeroStateProps) => (
  <Transition transitionOnMount animation="scale" duration={600}>
    <Segment className="zero-state">
      {!text ? <div className="background-logo" /> : null}
      {text ? (
        <Header icon>
          <Icon name={icon} />
          {text}
        </Header>
      ) : null}
    </Segment>
  </Transition>
);
ZeroState.defaultProps = {
  icon: undefined,
};

export default ZeroState;
