import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { guidChecker } from 'app/helpers/helpers';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

export interface ApplicationIndexSearchForm {
  appId: string,
}

const ApplicationIndexSB: FunctionComponent<ObjectTypeProps<ApplicationIndexSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ApplicationIndexSearchForm>) => {
  const [searchForm, setSearchForm] = useState<ApplicationIndexSearchForm>({
    appId: '',
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          AppId of Application
          <Popup
            flowing
            position="bottom left"
            content="Enter the AppId of the Application you are looking for.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.appId.length > 0 && !guidChecker(searchForm.appId)}
                disabled={disabled}
                value={searchForm.appId}
                className="last-form-item"
                fluid
                placeholder="Search AppId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, appId: value })}
              />
                      )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ appId: '' })}
          >
            Reset Search
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.appId.length === 0 || !guidChecker(searchForm.appId)}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default ApplicationIndexSB;
