import './TreeTable.scss';

import { getSingleObject } from 'app/actions/systemActions';
import SingleObject from 'app/components/Company/SingleObject';
import { generateId } from 'app/helpers/helpers';
import { State } from 'app/reducers/state';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Grid, Icon } from 'semantic-ui-react';

import InputSearch from '../InputSearch/InputSearch';
import Spinner from '../Spinner/Spinner';
import ZeroState from '../ZeroState/ZeroState';

interface AccordionClickDataProps {
  active: boolean;
  index: number;
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  hasChildren: boolean;
  topLevel: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TreeTable = ({ data }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [originalData, setOriginalData] = useState<any>(data);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [chunkedData, setChunkedData] = useState<any>([]);
  const [previewPane, setPreviewPane] = useState<ReactElement>();
  const preferenceState = useSelector((state: State) => state.preferences);
  const [pageSize] = useState(100);
  const [activeTreeItems, setActiveTreeItems] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [scrollId] = useState(generateId());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getObject = (event: Event, item: any, key:string) => {
    event.stopPropagation();
    if (item.objectId) { // Object w/ ID, Fetch Object
      // Set Spinner until Callback

      const spinner = (
        <div className="vertical-center">
          <Spinner active size="huge" inline="centered" />
        </div>
      );

      setPreviewPane(spinner);
      getSingleObject({ objectId: item.objectId }, undefined, (response) => {
        const previewData = (
          <SingleObject key={key} dataResult={response} isPropBag={false} />
        );

        setPreviewPane(previewData);
      }, (ex:string) => {
        // Failed to find Object
        const failed = (
          <ZeroState text={`Object Id: ${item.objectId} -- ${ex}`} />
        );
        setPreviewPane(failed);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, true).then((res: any) => {
        dispatch(res);
      });
    }
    if (!item.objectId && (item.propertyBag && item.propertyBag.length)) { // Object with no id but prop bag -> show bag
      // To open in a new Tab
      // dispatch(displayPropertyBag(item.propertyBag))

      const previewData = (
        <SingleObject key={key} dataResult={item.propertyBag} isPropBag />
      );
      setPreviewPane(previewData);
    }
  };

  const handleOnTitleClick = (e: Event, clickData:AccordionClickDataProps) => {
    if (!activeTreeItems.includes(clickData.key)) {
      // Update Preview Pane only if selecting
      getObject(e, clickData.item, clickData.key);
      setActiveTreeItems([...activeTreeItems, clickData.key]);
    } else {
      setActiveTreeItems(_.without(activeTreeItems, clickData.key));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rootPanels = (tableData: any, parentKey: string) => tableData.map((item: any, i: number) => { // Draw accordions recursivly
    const {
      displayName,
      objectId,
      children,
      propertyBag,
    } = item;
    const isLast = i === (tableData.length - 1);
    const key = parentKey + displayName + i;
    const isActive = activeTreeItems.includes(key);
    const hasChildren = children && children.length !== 0;
    const topLevel = !parentKey;

    const extraClasses:string[] = [];
    if (topLevel) {
      extraClasses.push('topLevel');
    }

    if (!hasChildren) {
      extraClasses.push('no-child');
    }

    const iconElement = (
      <>
        <span className={`treePlumbing ${isLast ? 'last' : ''}`}>
          <span className={`${_.join(extraClasses, ' ')} treeLine`}>&nbsp;</span>
          {hasChildren ? <Icon name={isActive ? 'caret down' : 'caret right'} /> : <span className={`${_.join(extraClasses, ' ')} treeSpace`} />}
        </span>
      </>
    );

    return {
      key,
      title: {
        className: isLast ? 'last' : '',
        onClick: (e:Event, d: Record<string, unknown>) => {
          handleOnTitleClick(e, {
            ...d,
            key,
            item,
            hasChildren,
            topLevel,
          } as AccordionClickDataProps);
        },
        content: (
          <>
            <div className="accord-title">{displayName}</div>
            {(objectId || (propertyBag && propertyBag.length))
              ? (<Icon link name="arrow alternate circle right outline" onClick={(e: Event) => getObject(e, item, key)} />)
              : null}
          </>
        ),
        icon: iconElement,
      },
      content: (hasChildren) ? {
        className: isLast ? 'last' : '',
        content: (
          <>
            <Accordion.Accordion exclusive={false} className="child-accord" panels={rootPanels(children, key)} />
          </>
        ),
      } : null,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setPageData = (pData: any) => { // Chunk data based on page size
    if (pData) {
      const firstPage = pData.slice(0, pageSize);
      setChunkedData(firstPage);
    }
  };

  useEffect(() => {
    setPageData(originalData);
  }, []);

  const fetchMoreData = () => { // Called when scroll hits threshold -> Get next page of data and concat with existing
    const moreData = originalData.slice(chunkedData.length, chunkedData.length + (pageSize));
    setChunkedData(chunkedData.concat(moreData));
  };

  return (
    <div className="Tree-Table-Container">
      <Grid columns={2} divided>
        <Grid.Column width={8} className="tree">
          <div className="Search-Container">
            <InputSearch
              input={data}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              output={(highlighted: any[]) => {
                setPageData(highlighted); setOriginalData(highlighted);
              }}
            />
          </div>
          <div id={scrollId} className="scroll-container">
            <InfiniteScroll
              dataLength={chunkedData.length}
              next={() => fetchMoreData()}
              scrollThreshold={0.9}
              hasMore={chunkedData.length < originalData.length}
              loader={<div className="loader"><Spinner active size="huge" inline="centered" /></div>}
              scrollableTarget={scrollId}
              endMessage={(
                <p className="end-of-results">
                  <b>End of Results</b>
                </p>
            )}
              hasChildren={!!chunkedData.length}
            >
              {chunkedData.length ? <Accordion exclusive={false} className={`font-${preferenceState.data.tableFontSize}`} panels={rootPanels(chunkedData, '')} styled fluid /> : null}
            </InfiniteScroll>
          </div>
        </Grid.Column>
        <Grid.Column width={8} className="preview">
          {previewPane}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default TreeTable;
