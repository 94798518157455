import './SidebarContent.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';

import CompanySidebar from './CompanySidebar/CompanySidebar';
import SystemSidebar from './SystemSidebar/SystemSidebar';
import IndexSidebar from './IndexSidebar/IndexSidebar';
import PartitionSidebar from './PartitionSidebar/PartitionSidebar';

const SidebarContent = () => {
  const location = useLocation();

  const sideBarMap: Record<string, JSX.Element | undefined> = {
    '/company': <CompanySidebar />,
    '/system': <SystemSidebar />,
    '/index': <IndexSidebar />,
    '/partition': <PartitionSidebar />,
  };

  return <div className="SidebarContent">{sideBarMap[location.pathname]}</div>;
};

export default SidebarContent;
