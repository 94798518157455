import './Search.scss';

import { displayPropertyBag, getSingleObject } from 'app/actions/companyActions';
import { displayPartitionCompOrFailover } from 'app/actions/partitionActions';
import {
  getServiceDetail,
  getServiceInstanceDetail,
  getSingleObject as getSingleObjectSystem,
} from 'app/actions/systemActions';
import DSTable from 'app/components/shared/DSTable/DSTable';
import {
  formatCompanyData, formateReferenceSet, formatProvStatus, formatSearchData, formatSingleObject,
} from 'app/components/shared/Utilities/Formatters';
import {
  DSObject,
  PagedResponseOfBinaryDSReferenceSetOf,
  PagedResponseOfDSObjectOf,
  ResponseOfDirectoryObjectTreeNodeModelOf,
  ResponseOfDSObjectOf,
  ResponseOfProvisioningStatusDetail,
} from 'app/proxyClients';

import { State } from 'app/reducers/state';
import { TabType } from 'app/typings';
import React, { FunctionComponent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import _ from 'lodash';
import { toast } from 'react-toastify';
import ProvisioningStatus from '../ProvisioningStatus/ProvisioningStatus';
import TreeTable from '../shared/TreeTable/TreeTable';
import ZeroState from '../shared/ZeroState/ZeroState';
import CompanyContentModal, { CompanyRow } from '../Company/CompanyModal/CompanyContentModal';
import { DataFormatType, DSTableExtraParams, RefreshDSTableDelegate } from '../shared/DSTable/DSTableTypes';

export enum SearchTenantType {
  Company = 0,
  System = 1,
}

export interface SearchRequestProps {
  id?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataResult: any,
  refreshDelegate?: RefreshDSTableDelegate,
  DSTableParams?: DSTableExtraParams
  sidebarObjectType: string | undefined,
  tabType: TabType,
  contextId?: string
}

/* eslint-disable */
const Search: FunctionComponent<SearchRequestProps> = ({
  id: tabId,
  dataResult: results,
  sidebarObjectType = undefined,
  tabType = TabType.Company,
  contextId = undefined,
  refreshDelegate = undefined,
  DSTableParams = undefined
}: SearchRequestProps) => {
  const preferencesState = useSelector((state: State) => state.preferences);
  const activeTabState = useSelector((state: State) => state.activeTabs);
  const linkState = useSelector((s: State) => s.objectLinks); // Used to get object links on single object
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<CompanyRow | null>(null);
  const location = useLocation();

  if (!results) {
    return null;
  }

  if ((Array.isArray(results.searchData) && results.searchData.length === 1)) { // For Search w/ 1 result ([DSObject]) - show as single obj
    const [dsobject] = results.searchData;
    results.searchData = dsobject;
  }

  const isSearch = useMemo<boolean>(() => { return (results.searchData instanceof PagedResponseOfDSObjectOf || (results?.isSearch)) &&  location.pathname !== '/system'}, [results]);
  const isSingleObj = useMemo<boolean>(() => { return results.searchData instanceof DSObject }, [results]);
  const isPagedDSObject = useMemo<boolean>(() => { return results.searchData instanceof ResponseOfDSObjectOf || 
    (results.searchData instanceof PagedResponseOfDSObjectOf && location.pathname === '/system')}, [results]);
  const isDirObjTreeNode = useMemo<boolean>(() => { return results.searchData instanceof ResponseOfDirectoryObjectTreeNodeModelOf }, [results]);
  const isReferenceSet = useMemo<boolean>(() => { return results.searchData instanceof PagedResponseOfBinaryDSReferenceSetOf }, [results]);
  const isProvStatus = useMemo<boolean>(() => { return results.searchData instanceof ResponseOfProvisioningStatusDetail }, [results]);
  const dataFormatType = useMemo<DataFormatType>(() => {
    if (isSingleObj) {
      return DataFormatType.SingleObject;
    }

    if (isReferenceSet) {
      return DataFormatType.ReferenceSet;
    }

    if (isProvStatus) {
      return DataFormatType.ProvStatus;
    }

    if (isSearch) {
      return DataFormatType.CompanySearchData;
    }

    if (isPagedDSObject) {
      return DataFormatType.PagedCompanySearchData;
    }

    return DataFormatType.SearchData;
  }, [results]);

  // For paged datasets -> more pages to load? -> Pass into table
  const [hasMoreData, setHasMoreData] = React.useState((isSearch || isReferenceSet || isPagedDSObject) && results.searchData.nextPage ? !results.searchData.isLastPage : false);
  const [nextPage, setNextPage] = React.useState<string | undefined>(results.searchData?.nextPage); // The Next page URL
  const [moreEventFired, setMoreEventFired] = React.useState(false); // Whether next page event has been called
  const [scrollPosition, setScrollPosition] = React.useState(0); // Keeps scroll on the table from re-rendering on fetch more call
  const [partitionAndEpochPresent] = React.useState(results.searchData && results.searchData.partitionId && results.searchData.contextEpoch ? true : false)

  //let tableData: any = [];
  const hiddenColumns = isSearch
    ? ['ContextId', 'ObjectClass', 'searchParams', 'SingleAuthorityMetadata', 'Target Prop Bag']
    : ['searchParams', 'Target Prop Bag'];

  const getSingleSystemObject = (objectId: string) => {
    getSingleObjectSystem({ objectId }, 'Single Object').then((res: any) => {
      dispatch(res);
    });
  };

  // ROW CLICK ACTION BASED ON TYPE
  const rowClickEvent = (prestineRow: any, highlightedRow: any, i: number) => {
    if (isSearch) { // If Search -> Open single object
      if (location.pathname === '/partition') {
        const partitionType = sidebarObjectType;
        let type = partitionType === 'Partition Companies' ? 'Partition Company' : 'Partition Failover'
        let data = partitionType === 'Partition Companies' ? results.searchData.data[i] : results.searchData.data[i].properties;
        dispatch(displayPartitionCompOrFailover(data, type));
      }
      if (location.pathname !== '/partition') {
         dispatch(getSingleObject({
          objectId: prestineRow.ObjectId,
          contextId: prestineRow.ContextId,
        }, activeTabState?.getActiveTab(TabType.Company)));
      }
      return;
    }
    if (isSingleObj) { // If Single Object -> Open modal with highlighted text
      setSelectedRow(highlightedRow);
      setModalOpen(true);
      return;
    }
    if (isReferenceSet && location.pathname === '/company') {
      // Row Click event for Company Delegation Entries
      dispatch(displayPropertyBag(
        prestineRow['Target Prop Bag'].propertyBag,
        prestineRow['Target Object ID'],
        activeTabState?.getActiveTab(TabType.Company),
      ));
      return;
    }
    if (isPagedDSObject) {
      switch (sidebarObjectType) {
        case 'Services':
          dispatch(getServiceDetail(prestineRow.ServiceType, 'ServiceDetail'));
          return;
        case 'ServiceInstances':
          dispatch(getServiceInstanceDetail(prestineRow.ServiceInstanceName, 'ServiceInstanceDetail'));
          return;
        case 'TypeConfiguration':
        case 'TrustedCertificate':
        case 'ServicePlan':
        case 'StockKeepingUnit':
          getSingleSystemObject(prestineRow.ObjectId);
          return;
        case 'Objects':
          if (tabType === TabType.System) { // System Object
            getSingleSystemObject(prestineRow.ObjectId);
          }
          if (tabType === TabType.Company) { // Company Object
            dispatch(getSingleObject({
              objectId: prestineRow.ObjectId,
              contextId: prestineRow.ContextId,
            }, activeTabState?.getActiveTab(TabType.Company)));
          }
          return;
        default:
          toast.error('Could not find paged object type');
          return;
      }
    }
  };

  const getZeroStateText = () => {
    if (results.params && results.params.searchType) {
      return `No ${results.params.searchType} Found`;
    }
    return 'No Results Found';
  };

  const formatData = (results: any, format: DataFormatType) => {
    let formattedResult = [];
    const timeFormatOptions = { 
      timeFormat: preferencesState.data.timeFormat,
      timeUtc: preferencesState.data.timeUtc,
      highlight: false,
      highlightColor: preferencesState.data.tableHighlightColor
    };

    switch (format) {
      case DataFormatType.SearchData:
        formattedResult = formatSearchData(results, timeFormatOptions);
        break;
      case DataFormatType.CompanySearchData:
        // Not every response comes back with a wrapped searchData value
        formattedResult = (_.has(results, 'searchData')
          ? formatCompanyData(results, timeFormatOptions, undefined)
          : formatCompanyData({ searchData: PagedResponseOfDSObjectOf.fromJS(results) }, timeFormatOptions, undefined)) || [];

        break;
      case DataFormatType.PagedCompanySearchData:
        const data = { ...results };
        delete data.params;
        formattedResult = formatCompanyData(data, timeFormatOptions, undefined) || [];
        break;
      case DataFormatType.SingleObject:
        formattedResult = formatSingleObject(results.searchData, timeFormatOptions);
        break;
      case DataFormatType.ReferenceSet:
        formattedResult = formateReferenceSet(results.searchData.data);
        break;
      case DataFormatType.ProvStatus:
        formattedResult = formatProvStatus(results.searchData.data.provisioningStatusByServicePlan, timeFormatOptions)
        break;
    }

    return formattedResult;
  }

  switch (sidebarObjectType) {
    case 'Objects':
      hiddenColumns.push('ContextId');
      hiddenColumns.push('ObjectClass');
      break;
    case 'StockKeepingUnit':
    case 'TrustedCertificate':
    case 'TypeConfiguration':
    case 'ServiceInstances':
    case 'ServicePlan':
      hiddenColumns.push('ContextId');
      hiddenColumns.push('ObjectClass');
      hiddenColumns.push('DisplayName');
      break;
    case 'Services':
      hiddenColumns.push('ContextId');
      hiddenColumns.push('ServicePrincipalTemplate');
      break;
  }

  const classNames: string[] = [];

  if (isProvStatus) {
    classNames.push('reduced-height-ds-table');
  }

  if (modalOpen) {
    classNames.push('modal-open');
  }

  // Format Data
  const tableData = useMemo(() => { return formatData(results, dataFormatType) }, [results]);
  const whichTableToShow = () => {
    if (isDirObjTreeNode) {
      return <div className="TreeTable"><TreeTable data={results.searchData.data} /></div>;
    }
    return (
      tableData.length ? (
        <>
          {isProvStatus ? ( // Add extra display for Provisioning Status plus class to table to reduce height
            <div className='prov-status'>
              <ProvisioningStatus
                provisioned={results.searchData.data.provisionedServiceInstances}
                authorized={results.searchData.data.authorizedServiceInstances} />
            </div>
          ) : null}

          <DSTable
            compact
            striped
            hide={hiddenColumns}
            rowSelectable
            pageSize={moreEventFired ? tableData.length : undefined}
            rowClickEvent={rowClickEvent}
            tableData={tableData}
            hasMoreData={hasMoreData}
            nextPage={nextPage}
            scrollPosition={scrollPosition}
            objectLinks={isSingleObj ? linkState?.getLinksForObject(results.searchData.objectId) : undefined}
            tabId={tabId}
            className={classNames.join(' ')}
            refreshDelegate={refreshDelegate}
            formatDataDelegate={formatData}
            DSTableParams={DSTableParams}
            dataFormatType={dataFormatType}
            companyId={contextId}
          />
          {
            partitionAndEpochPresent ?
              <div className='pid-and-epoch'>Partition: {results.searchData.partitionId}, Epoch: {results.searchData.contextEpoch}</div> : null
          }

          {selectedRow && <CompanyContentModal modalOpen={modalOpen} setModalOpen={setModalOpen} selectedRow={selectedRow} />}
        </>
      ) : <ZeroState text={getZeroStateText()} />
    );
  };

  return (
    (whichTableToShow())
  );
};
/* eslint-enable */

Search.defaultProps = {
  id: undefined,
  refreshDelegate: undefined,
  DSTableParams: undefined,
  contextId: undefined,
};

export default Search;
