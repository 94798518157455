import React, { FunctionComponent, useState } from 'react';
import {
  Button, Dropdown, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { TaskSearchForm } from 'app/typings/systemTypes';
import { State } from 'app/reducers/state';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const TaskFilterSB: FunctionComponent<ObjectTypeProps<TaskSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<TaskSearchForm>) => {
  const enumState = useSelector((state: State) => state.enums);
  const [searchForm, setSearchForm] = useState<TaskSearchForm>({
    contains: '(any)',
  });

  const taskOptions = _.orderBy(enumState.systemTaskList?.map((t) => ({ key: t.key, text: t.name, value: t.name as string })), ['text']);
  // Add Any Option
  taskOptions.unshift({ key: -1, text: '(any)', value: '(any)' });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label" htmlFor="contains-options">Task Filter</label>
        <Popup
          flowing
          position="bottom left"
          content="Choose a task to filter by. Will only show policies that contain the given task."
          trigger={(
            <Dropdown
              fluid
              search
              selection
              disabled={taskOptions?.length === 0 || disabled}
              className="last-form-item"
              id="contains-options"
              options={taskOptions}
              value={searchForm.contains}
              onChange={(e, { value }) => setSearchForm({ ...searchForm, contains: value })}
            />
        )}
        />
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ contains: '(any)' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={disabled}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default TaskFilterSB;
