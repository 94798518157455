/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractProvisioningStatusDetail {
    'provisioningStatusByServicePlan'?: Array<MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus> | null;
    'authorizedServiceInstances'?: Array<string> | null;
    'provisionedServiceInstances'?: Array<string> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "provisioningStatusByServicePlan",
            "baseName": "provisioningStatusByServicePlan",
            "type": "Array<MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus>",
            "format": ""
        },
        {
            "name": "authorizedServiceInstances",
            "baseName": "authorizedServiceInstances",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "provisionedServiceInstances",
            "baseName": "provisionedServiceInstances",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractProvisioningStatusDetail.attributeTypeMap;
    }

    public constructor() {
    }
}

