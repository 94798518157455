import type { App } from 'app/store';
import { PartitionCompaniesForm, PartitionFailoverForm } from 'app/typings';
import { DirSyncState } from 'app/helpers/types';
import moment from 'moment';
import { getPageSizeFromState } from './preferences';

export async function getPartitionIds(app: App) {
  return app.clients.partitions.partitionGetPartitionIds();
}

export async function getPartitionDetails(app: App) {
  return app.clients.partitions.partitionGetPartitionDetails();
}

export async function getCompaniesByPartition(app: App, p: PartitionCompaniesForm) {
  return app.clients.partitions.partitionGetCompaniesByPartition(
    p.partitionId,
    p.si,
    p.siPrefix,
    p.contextIds,
    p.createdAfter ? moment(p.createdAfter).format('YYYY-MM-DDTHH:mm:ssZ') : undefined,
    p.createdBefore ? moment(p.createdBefore).format('YYYY-MM-DDTHH:mm:ssZ') : undefined,
    p.dirSyncEnabled,
    p.dirSyncState as DirSyncState,
    getPageSizeFromState(app),
    undefined,
    undefined,
  );
}

export async function getPartitionFailovers(app: App, p: PartitionFailoverForm) {
  return app.clients.partitions.partitionGetPartitionFailovers(
    p.partitionId,
    moment(p.startedAfter).format('YYYY-MM-DDTHH:mm:ssZ'),
  );
}
