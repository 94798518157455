import { Action } from 'redux';
import * as ObjectLinkTypes from 'app/actions/objectLinkTypes';
import { ResponseOfDSObjectArray } from 'app/helpers/types';
import _ from 'lodash';

export type ObjectLink = {
  objectId: string
  totalLinks: number,
  targetLinks: ResponseOfDSObjectArray
  sourceLinks: ResponseOfDSObjectArray
};

export type ObjectLinkState = {
  linkObjects: ObjectLink[],
  getLinksForObject: (objectId: string) => ObjectLink | undefined
};

const state: ObjectLinkState = {
  linkObjects: [],
  getLinksForObject(objectId: string) {
    const obj = _.find(this.linkObjects, (o: ObjectLink) => o.objectId === objectId);
    if (obj) {
      return obj;
    }
    return undefined;
  },
};

type ObjectLinksActions =
  GetObjectLinksCompleted | RemoveObjectLinkFromState;

type GetObjectLinksCompleted = Action<typeof ObjectLinkTypes.GET_OBJECT_LINKS_COMPLETED> & {
  resp: ObjectLink
};
type RemoveObjectLinkFromState = Action<typeof ObjectLinkTypes.REMOVE_OBJECT_LINKS_FROM_STATE> & {
  objectId: string
};

export default (objectLinkState: ObjectLinkState = state, action: ObjectLinksActions): ObjectLinkState => {
  switch (action.type) {
    case ObjectLinkTypes.GET_OBJECT_LINKS_COMPLETED:
      if (objectLinkState.linkObjects.findIndex((obj) => obj.objectId === action.resp.objectId) === -1) {
        return {
          ...objectLinkState, linkObjects: [...objectLinkState.linkObjects, action.resp],
        };
      }
      return {
        ...objectLinkState,
      };
    case ObjectLinkTypes.REMOVE_OBJECT_LINKS_FROM_STATE: {
      if (objectLinkState.linkObjects.findIndex((obj) => obj.objectId === action.objectId) === -1) {
        return {
          ...objectLinkState,
        };
      }
      const matchedIndex = objectLinkState.linkObjects.findIndex((obj) => obj.objectId === action.objectId);
      return {
        ...objectLinkState, linkObjects: [...objectLinkState.linkObjects].filter((d, idx) => idx !== matchedIndex),
      };
    }
    default:
      return objectLinkState;
  }
};
