import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router';
import {
  Button, Checkbox, Dropdown, Input, Popup, Transition,
} from 'semantic-ui-react';
import {
  ObjectsSearchForm,
} from 'app/typings';
import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { DirectoryObjectClass, SearchFilterOperator } from 'app/helpers/types';
import { useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import * as ObjectsSBConstants from './ObjectsSBConstants';
import * as Utilities from './ObjectsSBHelpers';

const ObjectsSB: FunctionComponent<ObjectTypeProps<ObjectsSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ObjectsSearchForm>) => {
  const location = useLocation();
  const pageSize = useSelector((state: State) => state.preferences.data.pageSize);

  const initProperties = Utilities.setInitialProperties(
    Utilities.isValidLocation(location.pathname),
  );
  const propertiesDropdown = Utilities.setPropertiesDropdown(
    Utilities.isValidLocation(location.pathname),
  );
  const initialSearchForm = () => {
    const searchForm = Utilities.setInitialSearchFrom(Utilities.isValidLocation(location.pathname), pageSize);

    return searchForm;
  };
  const initialPropertiesDropdowns = Utilities.setInitialDropdowns(
    initProperties,
    Utilities.isValidLocation(location.pathname),
  );
  const [counter, setCounter] = useState<number>(1);
  const [data, setData] = useState<ObjectsSearchForm>(initialSearchForm);

  const [properties, setProperties] = useState<ObjectsSBConstants.TProperties>(
    initialPropertiesDropdowns,
  );
  const handlePropertyChange = (
    value:
    | keyof ObjectsSBConstants.ICompanyObjectsCategories
    | keyof ObjectsSBConstants.ISystemObjectsCategories,
  ) => {
    const keyValue = Utilities.getCategoryArray(
      value,
      Utilities.isValidLocation(location.pathname),
    );
    const newCategories = keyValue.map((k, v) => ({
      key: v,
      text: k,
      value: k,
    }));
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < counter; i++) {
      data.filter[+`${i}`].property = newCategories[0].value;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const DirObjectClass: any = DirectoryObjectClass[value as any];
    setProperties({ ...properties, [value]: newCategories });
    setData({ ...data, Category: value, objectClass: DirObjectClass });
  };

  const handleAdd = () => {
    const newDetails: ObjectsSBConstants.Selections = {
      property: (properties[data.Category] || [{ value: undefined }])[0].value,
      comparisonOperator: ObjectsSBConstants.operands[4],
      propertyValueString: '',
      conditionalOperator: SearchFilterOperator.None,
    };
    data.filter[+`${counter}`] = newDetails;
    data.filter[counter - 1].conditionalOperator = SearchFilterOperator.And;
    setData({ ...data });
    setCounter(counter + 1);
  };

  const handleRemove = () => {
    if (counter - 1 === 0) {
      return;
    }
    const copyList = { ...data };
    copyList.filter.pop();
    setData({ ...copyList });
    data.filter[counter - 2].conditionalOperator = SearchFilterOperator.None; // reset comparator for last selection
    setData({ ...data });
    setCounter(counter - 1); // reset counter
  };

  const searchObjects = (searchForm: ObjectsSearchForm) => {
    const form = { ...searchForm };
    if (form.filter.length > 1) {
      form.filter[form.filter.length - 1].conditionalOperator = form.filter[form.filter.length - 2].conditionalOperator;
    }
    searchEvent(form);
  };

  const id = _.uniqueId('ObjectsSB-');
  const categoriesDropdownId = `${id}-CategoriesDropdown`;

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label" htmlFor={categoriesDropdownId}>
          Object Type
        </label>
        <Popup
          flowing
          position="bottom left"
          content="Choose the directory object class to filter on or keep empty to search accross all relavent object classes"
          trigger={(
            <Dropdown
              fluid
              selection
              search
              className="margin-bottom"
              closeOnChange
              id={categoriesDropdownId}
              disabled={disabled}
              options={propertiesDropdown}
              value={data.Category || ''}
              onChange={(_e, { value }) => {
                handlePropertyChange(
                  value as keyof ObjectsSBConstants.ICompanyObjectsCategories,
                );
              }}
            />
          )}
        />

        <div className="scoping-container">
          <label className="label width-100">
            <div className="scoping-label">Scoping Conditions</div>
            <div className="add-remove-container">
              <Button
                disabled={disabled || counter <= 1}
                attached="left"
                size="mini"
                primary
                icon="minus"
                onClick={() => {
                  handleRemove();
                }}
              />
              <Button
                disabled={disabled || counter >= 4}
                attached="right"
                icon="plus"
                primary
                size="mini"
                onClick={() => {
                  handleAdd();
                }}
              />
            </div>
          </label>
        </div>
        {data.filter.map((_Unused: unknown, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <Popup
              flowing
              position="bottom left"
              content="Choose the directory property to filter by"
              trigger={(
                <Dropdown
                  fluid
                  selection
                  search
                  disabled={disabled}
                  options={properties[data.Category]}
                  value={
                    Utilities.getCategoryArray(
                      data.Category,
                      Utilities.isValidLocation(location.pathname),
                    ).includes(data.filter[+`${index}`].property as string)
                      ? data.filter[+`${index}`].property
                      : (properties[data.Category] || [{ value: undefined }])[0]
                        .value
                  }
                  onChange={(_e, { value }) => {
                    data.filter[+`${index}`].property = value;
                    setData({ ...data });
                  }}
                />
              )}
            />
            <div className="dropdown-input-container">
              <Dropdown
                compact
                selection
                className="operand-dropdown"
                options={ObjectsSBConstants.operandDropdown}
                value={data.filter[+`${index}`].comparisonOperator || ''}
                onChange={(_e, { value }) => {
                  data.filter[+`${index}`].comparisonOperator = value;
                  setData({ ...data });
                }}
              />
              <Popup
                flowing
                position="bottom left"
                content="Enter the value to filter on"
                trigger={(
                  <Input
                    className="objects-input"
                    disabled={disabled || data.filter[+`${index}`].comparisonOperator === 7}
                    placeholder="Enter the value to filter on"
                    value={data.filter[+`${index}`].propertyValueString || ''}
                    onChange={(_e, { value }) => {
                      data.filter[+`${index}`].propertyValueString = value;
                      setData({ ...data });
                    }}
                  />
                )}
              />
            </div>
            {counter - 1 !== index ? (
              <Button.Group className="objects-margin">
                <Button
                  compact
                  className={
                    data.filter[+`${index}`].conditionalOperator === SearchFilterOperator.And ? 'selected' : ''
                  }
                  onClick={() => {
                    data.filter[+`${index}`].conditionalOperator = SearchFilterOperator.And;
                    setData({ ...data });
                  }}
                >
                  And
                </Button>
                <Button.Or />
                <Button
                  compact
                  className={
                    data.filter[+`${index}`].conditionalOperator === SearchFilterOperator.Or ? 'selected' : ''
                  }
                  onClick={() => {
                    data.filter[+`${index}`].conditionalOperator = SearchFilterOperator.Or;
                    setData({ ...data });
                  }}
                >
                  Or
                </Button>
              </Button.Group>
            ) : null}
          </div>
        ))}
        <Popup
          flowing
          position="bottom left"
          content="If selected, the search will be scoped to the soft deleted object container (aka Recycle Bin)"
          trigger={(
            <Checkbox
              className="last-object-item "
              toggle
              label="Soft Deleted"
              checked={data.softDeleted}
              onChange={(_e, { checked }) => setData({ ...data, softDeleted: checked as boolean })}
            />
          )}
        />
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => {
              setData(initialSearchForm);
              setCounter(1);
            }}
          >
            Reset
          </Button>
          <Button
            disabled={Utilities.isSearchFormEmpty(data, counter)}
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchObjects(data)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default ObjectsSB;
