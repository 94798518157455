import './Company.scss';
import DSTable from 'app/components/shared/DSTable/DSTable';
import React, { FunctionComponent } from 'react';
import { LicenseDetail, SubscriptionDetail } from 'app/proxyClients';
import TimeFormat from 'app/components/shared/Utilities/TimeFormat';
import { useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import LicenseModal, { LicenseRow } from './LicenseModal/LicenseModal';
import { DataFormatType } from '../shared/DSTable/DSTableTypes';

interface SingleLicenseProps {
  dataResult: LicenseDetail;
}

const SingleLicense: FunctionComponent<SingleLicenseProps> = ({
  dataResult,
}: SingleLicenseProps) => {
  const preferencesState = useSelector((state: State) => state.preferences);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<LicenseRow | null>(null);

  type TProperties = SubscriptionDetail[];
  const timeFormatOptions = {
    timeFormat: preferencesState.data.timeFormat,
    timeUtc: preferencesState.data.timeUtc,
    highlight: false,
    highlightColor: preferencesState.data.tableHighlightColor,
  };

  const formatCompanyData = (data: TProperties) => data.map((row) => ({
    'Subscription Id': row.subscriptionId || '',
    'Prepaid Units': row.prepaidUnits?.toString() || '',
    'Max Overage Units': row.maximumOverageUnits?.toString() || '',
    'Start Date': <TimeFormat time={row.startDate} {...timeFormatOptions} />,
    'Next Lifecycle Date': <TimeFormat time={row.nextLifecycleDate} {...timeFormatOptions} />,
    'Trial Subscription': row.trialSubscription?.toString() || '',
  }));

  const properties: TProperties = ([] as TProperties).concat(
    dataResult?.subscriptions || [],
  );

  const tableData = formatCompanyData(properties);

  const rowClickEvent = (row: LicenseRow, highlightedRow: LicenseRow) => {
    setSelectedRow(highlightedRow);
    setModalOpen(true);
  };

  return (
    <>
      <DSTable
        compact
        striped
        rowSelectable
        rowClickEvent={rowClickEvent}
        dataFormatType={DataFormatType.License}
        tableData={tableData}
      />
      {selectedRow && (
        <LicenseModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          selectedRow={selectedRow}
        />
      )}
    </>
  );
};

export default SingleLicense;
