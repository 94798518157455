import { Action } from 'redux';
import * as CompanyTypes from 'app/actions/companyTypes';
import { RequiredApplicationOauth2Permissions } from 'app/helpers/types';
import _ from 'lodash';

export type AppPermission = {
  appId: string
  appPermissions: RequiredApplicationOauth2Permissions
};

export type AppPermissionState = {
  entries: AppPermission[],
  getApplicationPermissions: (appId: string) => RequiredApplicationOauth2Permissions[] | undefined
};

const state: AppPermissionState = {
  entries: [],
  getApplicationPermissions(appId: string) {
    const entries = _.filter(this.entries, (o: AppPermission) => o.appId === appId);
    return entries.length > 0 ? entries.flatMap((e) => e.appPermissions) : undefined;
  },
};

type AppPermissionActions =
  GetAppPermissionCompleted | RemoveAppPermissionsFromState;

type GetAppPermissionCompleted = Action<typeof CompanyTypes.GET_APP_PERMISSIONS_COMPLETED> & {
  appId: string,
  resp: RequiredApplicationOauth2Permissions
};
type RemoveAppPermissionsFromState = Action<typeof CompanyTypes.REMOVE_APP_PERMISSIONS_FROM_STATE> & {
  appId: string
};

export default (appPermissionState: AppPermissionState = state, action: AppPermissionActions): AppPermissionState => {
  switch (action.type) {
    case CompanyTypes.GET_APP_PERMISSIONS_COMPLETED:
      if (appPermissionState.getApplicationPermissions(action.appId) === undefined) {
        return {
          ...appPermissionState, entries: [...appPermissionState.entries, { appId: action.appId, appPermissions: action.resp }],
        };
      }
      return {
        ...appPermissionState,
      };
    case CompanyTypes.REMOVE_APP_PERMISSIONS_FROM_STATE: {
      if (appPermissionState.entries.findIndex((obj) => obj.appId === action.appId) === -1) {
        return {
          ...appPermissionState,
        };
      }
      const matchedIndex = appPermissionState.entries.findIndex((obj) => obj.appId === action.appId);
      return {
        ...appPermissionState, entries: [...appPermissionState.entries].filter((d, idx) => idx !== matchedIndex),
      };
    }
    default:
      return appPermissionState;
  }
};
