/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = None 1 = Account 2 = Company 3 = Contact 4 = Contract 5 = ForeignPrincipal 6 = Group 7 = Region 8 = Role 9 = RoleTemplate 10 = Scope 11 = Service 12 = ServiceInstance 13 = ServicePlan 14 = StockKeepingUnit 15 = SubscribedPlan 16 = Subscription 17 = Task 18 = TaskSet 19 = ThrottlePolicy 20 = User 21 = Datacenter 22 = ServicePrincipal 23 = SliceInstance 24 = KeyGroup 25 = FeatureDescriptor 26 = TrustedCertificate 27 = TrustedRealm 28 = TypeConfiguration 29 = Application 30 = ApplicationStub 31 = Device 32 = AltSecIdTenantMap 33 = RoleSliceInstance 34 = CollaborationSpace 35 = DeviceConfiguration 36 = TrustedRealmStub 37 = DataReplicationScope 38 = EvoStsConfiguration 39 = PublicFolder 40 = AdministrativeUnit 41 = Policy 42 = Segment 43 = RoleDefinition 44 = PermissionScope 45 = ObjectSettingTemplate 47 = WorkloadDataLocation 49 = RoleDefinitionTemplate 50 = ComplexExtensionDefinition 53 = RolloutPlan 54 = SecuredEncryptionKey 55 = AuthorizationPolicy 56 = KerberosDomain 57 = TenantTrustRelationship 59 = Label 60 = PermissionGrantPolicy 62 = GroupProxy 63 = RemotePrincipal 64 = UserProxy 65 = AssociationInvitation 66 = CompanyAssociation 76 = PrivateLinkResource 77 = PrivateEndpoint 78 = CustomSecurityAttribute 79 = CertBasedAuthConfiguration 80 = DeviceTemplate 81 = PrivateLinkIdTenantIdMap 83 = PendingExternalUserProfile 84 = ExternalUserProfile 85 = SpCreationPolicy 86 = PartnerPolicy 87 = Agreement 89 = AttributeSetEntity 90 = PLIds64BitsTenantIdMap 91 = CrossTenantInfoSharing 92 = CertBasedConfiguration 94 = SourceOfAuthorityPolicy 99 = PartnerPolicyTemplate 100 = MultiTenantOrg 101 = MultiTenantOrgTenant 102 = FederatedIdentityIssuer 103 = PublicKeyInfrastructure 104 = CertificateAuthorityEntity 105 = DomainExtension 108 = OrganizationalUnitContainer 109 = CompanyBranding 110 = CompanyBrandingLocale
*/
export enum MicrosoftOnlineDirectoryServicesDirectoryObjectClass {
    None = 0,
    Account = 1,
    Company = 2,
    Contact = 3,
    Contract = 4,
    ForeignPrincipal = 5,
    Group = 6,
    Region = 7,
    Role = 8,
    RoleTemplate = 9,
    Scope = 10,
    Service = 11,
    ServiceInstance = 12,
    ServicePlan = 13,
    StockKeepingUnit = 14,
    SubscribedPlan = 15,
    Subscription = 16,
    Task = 17,
    TaskSet = 18,
    ThrottlePolicy = 19,
    User = 20,
    Datacenter = 21,
    ServicePrincipal = 22,
    SliceInstance = 23,
    KeyGroup = 24,
    FeatureDescriptor = 25,
    TrustedCertificate = 26,
    TrustedRealm = 27,
    TypeConfiguration = 28,
    Application = 29,
    ApplicationStub = 30,
    Device = 31,
    AltSecIdTenantMap = 32,
    RoleSliceInstance = 33,
    CollaborationSpace = 34,
    DeviceConfiguration = 35,
    TrustedRealmStub = 36,
    DataReplicationScope = 37,
    EvoStsConfiguration = 38,
    PublicFolder = 39,
    AdministrativeUnit = 40,
    Policy = 41,
    Segment = 42,
    RoleDefinition = 43,
    PermissionScope = 44,
    ObjectSettingTemplate = 45,
    WorkloadDataLocation = 47,
    RoleDefinitionTemplate = 49,
    ComplexExtensionDefinition = 50,
    RolloutPlan = 53,
    SecuredEncryptionKey = 54,
    AuthorizationPolicy = 55,
    KerberosDomain = 56,
    TenantTrustRelationship = 57,
    Label = 59,
    PermissionGrantPolicy = 60,
    GroupProxy = 62,
    RemotePrincipal = 63,
    UserProxy = 64,
    AssociationInvitation = 65,
    CompanyAssociation = 66,
    PrivateLinkResource = 76,
    PrivateEndpoint = 77,
    CustomSecurityAttribute = 78,
    CertBasedAuthConfiguration = 79,
    DeviceTemplate = 80,
    PrivateLinkIdTenantIdMap = 81,
    PendingExternalUserProfile = 83,
    ExternalUserProfile = 84,
    SpCreationPolicy = 85,
    PartnerPolicy = 86,
    Agreement = 87,
    AttributeSetEntity = 89,
    PLIds64BitsTenantIdMap = 90,
    CrossTenantInfoSharing = 91,
    CertBasedConfiguration = 92,
    SourceOfAuthorityPolicy = 94,
    PartnerPolicyTemplate = 99,
    MultiTenantOrg = 100,
    MultiTenantOrgTenant = 101,
    FederatedIdentityIssuer = 102,
    PublicKeyInfrastructure = 103,
    CertificateAuthorityEntity = 104,
    DomainExtension = 105,
    OrganizationalUnitContainer = 108,
    CompanyBranding = 109,
    CompanyBrandingLocale = 110
}
