import './CreateAnnouncement.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button, DropdownItemProps, Form, Icon, Input, Message, Select, TextArea,
} from 'semantic-ui-react';
import { IStatusMessageDataModel } from 'app/proxyClients';
import { useDispatch, useSelector } from 'react-redux';
import { AddAnnouncement, UpdateAnnouncement, AnnouncementsShown } from 'app/actions/announcementActions';
import { State } from 'app/reducers/state';

const severityValues: DropdownItemProps[] = [
  {
    key: 0, text: 'Low', value: 0, image: <Icon color="green" name="alarm" />,
  },
  {
    key: 1, text: 'Medium', value: 1, image: <Icon color="yellow" name="alarm" />,
  },
  {
    key: 2, text: 'High', value: 2, image: <Icon color="red" name="alarm" />,
  },
];

interface CreateAnnouncementProps {
  close: () => void,
  announcementForUpdate?: IStatusMessageDataModel
}

const CreateAnnouncement: FunctionComponent<CreateAnnouncementProps> = ({ close, announcementForUpdate }: CreateAnnouncementProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [createResult, setCreateResult] = useState<'success' | 'fail' | undefined>(undefined);
  const announcementState = useSelector((state: State) => state.announcements);

  const [announcementForm, setAnnouncementForm] = useState({
    id: announcementForUpdate?.id ? announcementForUpdate?.id : undefined,
    title: announcementForUpdate?.title ? announcementForUpdate.title : '',
    message: announcementForUpdate?.message ? announcementForUpdate?.message : '',
    messageType: announcementForUpdate?.messageType ? announcementForUpdate?.messageType : 0,
    isResolved: false,
    createdAt: announcementForUpdate?.createdAt ? announcementForUpdate?.createdAt : '',
  } as IStatusMessageDataModel);

  useEffect(() => {
    if (!loading) {
      return;
    }

    if (announcementState.lastOperation === 'success') {
      setCreateResult('success');
      setLoading(false);
    }

    if (announcementState.lastOperation === 'failed') {
      setCreateResult('fail');
      setLoading(false);
    }
  }, [announcementState]);

  const submitAnnouncement = () => {
    dispatch(AnnouncementsShown());
    setLoading(true);
    if (announcementForm.id) {
      dispatch(UpdateAnnouncement(announcementForm));
    } else {
      dispatch(AddAnnouncement(announcementForm));
    }
  };

  const messageHeader = () => {
    if (createResult === undefined) {
      return undefined;
    }

    if (createResult === 'success') {
      return announcementForUpdate ? 'Successfully Updated Announcement' : 'Successfully Created Announcement';
    }
    return announcementForUpdate ? 'Failed To Update Announcement' : 'Failed To Create New Announcement';
  };

  return (
    <Form loading={loading} onSubmit={() => submitAnnouncement()}>
      <Form.Group widths="equal">
        <Form.Field required>
          <label className="label" htmlFor="announc-title">Announcement Title</label>
          <Input
            fluid
            value={announcementForm.title}
            id="announc-title"
            placeholder="Title"
            onChange={(e, { value }) => setAnnouncementForm({ ...announcementForm, title: value })}
          />
        </Form.Field>
        <Form.Field
          required
          control={Select}
          options={severityValues}
          label={{ children: 'Severity', htmlFor: 'announc-severity', className: 'label' }}
          placeholder="Select Severity"
          value={announcementForm.messageType}
          searchInput={{ id: 'announc-severity' }}
          // eslint-disable-next-line
          onChange={(e: any, { value }: any) => setAnnouncementForm({ ...announcementForm, messageType: value })}
        />
      </Form.Group>
      <Form.Field required>
        <label className="label" htmlFor="announc-message">Message</label>
        <TextArea
          value={announcementForm.message}
          id="announc-message"
          placeholder="Message"
          onChange={(e, { value }) => setAnnouncementForm({ ...announcementForm, message: value as string })}
        />
      </Form.Field>
      {createResult !== undefined ? (
        <Message
          color={createResult === 'success' ? 'green' : 'red'}
          icon={<Icon name={createResult === 'success' ? 'check' : 'close'} color={createResult === 'success' ? 'green' : 'red'} />}
          header={messageHeader()}
        />
      ) : null}
      <div className="announc-btn-div">
        <Button type="reset" onClick={() => { close(); setCreateResult(undefined); }}>Close</Button>
        <Button
          primary
          type="submit"
          disabled={announcementForm.title === '' && announcementForm.message === ''}
        >
          {announcementForUpdate ? 'Update' : 'Create'}
        </Button>
      </div>
    </Form>
  );
};

CreateAnnouncement.defaultProps = {
  announcementForUpdate: undefined,
};

export default CreateAnnouncement;
