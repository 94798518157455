import './Company.scss';

import DSTable from 'app/components/shared/DSTable/DSTable';
import {
  formatPropBagForTable, formatSingleObject,
} from 'app/components/shared/Utilities/Formatters';
import React, { FunctionComponent, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import ZeroState from '../shared/ZeroState/ZeroState';
import CompanyContentModal, { CompanyRow } from './CompanyModal/CompanyContentModal';
import { DataFormatType, DSTableExtraParams, RefreshDSTableDelegate } from '../shared/DSTable/DSTableTypes';

/* eslint-disable */
export interface SingleObjectProps {
  id?: string,
  dataResult: any,
  isPropBag?: boolean,
  refreshDelegate?: RefreshDSTableDelegate,
  DSTableParams?: DSTableExtraParams
}

const SingleObject: FunctionComponent<SingleObjectProps> = ({
  id: tabId,
  dataResult,
  isPropBag = false,
  refreshDelegate = undefined,
  DSTableParams = undefined
}: SingleObjectProps) => {
  const preferencesState = useSelector((state: State) => state.preferences);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<CompanyRow | null>(null);
  const [partitionAndEpochPresent] = React.useState(dataResult && (dataResult.partitionId !== undefined) && (dataResult.contextEpoch !== undefined) && (dataResult.contextId !== "00000000-0000-0000-0000-000000000000") ? true : false)
  const linkState = useSelector((s: State) => s.objectLinks); // Used to get object links on single object

  const tableData = useMemo(() => {
    const timeFormatOptions = {
      timeFormat: preferencesState.data.timeFormat,
      timeUtc: preferencesState.data.timeUtc,
      highlight: false,
      highlightColor: preferencesState.data.tableHighlightColor
    };
    
    if (isPropBag) {
      return formatPropBagForTable(dataResult.data ? dataResult.data as any : dataResult, timeFormatOptions);
    }

    if (DSTableParams) {
      DSTableParams.rmParams = {
        replicaName: dataResult.replicaName,
        contextId: dataResult.contextId,
        objectClass: dataResult.data.objectClass,
        contextEpoch: dataResult.contextEpoch,
        partitionId: dataResult.partitionId,
        objectId: dataResult.data.objectId
      }
    }

    const result = formatSingleObject(dataResult.data || dataResult, timeFormatOptions);
    return result;
  }, [dataResult]);  

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowClickEvent = (row: CompanyRow, highlightedRow: any) => {
    highlightedRow['Value(s)'] = typeof highlightedRow['Value(s)'] === 'string' ? `${highlightedRow['Value(s)']}`.split(',') : highlightedRow['Value(s)']; // For partition details -> selectedRow['Value(s)'] = a string
    setSelectedRow(highlightedRow);
    setModalOpen(true);
  };

  const handleClose = () => {
    setSelectedRow(null);
    setModalOpen(false);
  }

  return (
    <>
      {tableData && tableData.length ? (
        <>
          <DSTable
            rowSelectable
            rowClickEvent={rowClickEvent}
            tableData={tableData}
            tabId={tabId}
            refreshDelegate={refreshDelegate}
            objectLinks={!isPropBag && dataResult.data ? linkState?.getLinksForObject(dataResult.data.objectId) : undefined}
            companyId={dataResult.contextId}
            dataFormatType={isPropBag ? DataFormatType.PropertyBag : DataFormatType.SingleObject}
            DSTableParams={DSTableParams}
          />
          {partitionAndEpochPresent ? <div className='pid-and-epoch'>Partition: {dataResult.partitionId}, Epoch: {dataResult.contextEpoch}</div> : null}
          {selectedRow && <CompanyContentModal modalOpen={modalOpen} setModalOpen={setModalOpen} selectedRow={selectedRow} closeEvent={handleClose} />}
        </>
      ) : <ZeroState text='No Data Found' />}
    </>
  );
};
/* eslint-enable */

export default SingleObject;
