/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractLicenseUnitsDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseUnitsDetail';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail {
    'enabled'?: number;
    'lockedOut'?: number;
    'warned'?: number;
    'suspended'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "enabled",
            "baseName": "enabled",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "lockedOut",
            "baseName": "lockedOut",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "warned",
            "baseName": "warned",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "suspended",
            "baseName": "suspended",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail.attributeTypeMap;
    }

    public constructor() {
    }
}

