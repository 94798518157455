import { activateTab } from 'app/actions/tabActions';

import React, { FunctionComponent, useEffect, useState } from 'react';
import Joyride, {
  ACTIONS, EVENTS, STATUS, CallBackProps, Placement,
} from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getCompanyByName } from 'app/actions/companyActions';
import { TabType } from 'app/typings';
import { State } from 'app/reducers/state';

interface JoyrideTourProps {
  startTour: boolean;
  endTour: () => void
  setUserDropdown: (value: boolean) => void,
}
/* eslint-disable */
const JoyrideTour: FunctionComponent<JoyrideTourProps> = ({
  startTour,
  setUserDropdown,
  endTour,
}: JoyrideTourProps) => {
  const tabState = useSelector((state: State) => state.tabs);
  const theme = useSelector((state: State) => state.preferences.data.activeTheme);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const colorMap: {[key: string]: string} = {
    'system-default': '#2185d0',
    'light': '#2185d0',
    'dark': '#313a43',
    'hotdog': '#c20300',
    'forest': '#113a1a',
    'synthwave': '#3c3573',
    'solarized-light': '#2aa198',
    'solarized-dark': '#3d4183',
  }

  useEffect(() => { // Start Tour based on input
    if (startTour) {
      history.push('/company'); // Make sure user is on Company tab
      if (tabState?.hasTabs(TabType.Company)) {
        // If user has company tabs already open, set to first parent and child tab (where Joyride Id's are present)
        const compTabs = tabState.getTabs(TabType.Company);
        dispatch(activateTab(compTabs[0].id, TabType.Company)); // 1st parent tab
        dispatch(activateTab(compTabs[0].children.tabs[0].id, TabType.Company, compTabs[0].id as string)); // 1st child tab
      } else { // Otherwise pop open a new company tab for the tour 
        dispatch(getCompanyByName({
          companyIdentifier: 'microsoftservices',
          softDeleted: false,
          contextEpoch: undefined,
          partitionId: undefined,
        }));
      }
      setRun(startTour); // Start
    }
  }, [startTour]);

  const steps = [
    {
      target: '#user-preferences-tour',
      title: 'User Preferences',
      content: 'Here you can set themes, time formats, page sizes, table behaviors, the ability to persist tabs and much more! All preferences are saved in our backend database and follow you wherever you login (per environment).',
      disableBeacon: true,
    },
    {
      target: '#short-link-tour',
      title: 'Generate Short Links',
      content: 'Here you can generate and customize short links to share. Links expire after 30 days.',
      disableBeacon: true,
    },
    {
      target: '#token-info-tour',
      title: 'View Token Info',
      content: 'Here you can view additional token information like group memberships, full claims and more.',
      disableBeacon: true,
    },
    {
      target: '#pin-tab-tour',
      title: 'Pin Tabs',
      content: 'Pinning tabs allows you to maintain the selected tabs in your pinned workspace, essentially giving you split screen ability. (Pinned tabs are not organized by Company hierarchy)',
      disableBeacon: true,
    },
    {
      target: '#table-search-tour',
      title: 'Table Search',
      placement: 'bottom-start' as Placement,
      content: 'Search through table data here. Search is scoped only to data currently present on the UI. This WILL NOT run a backend search. (Matching text may be nested within returned rows)',
      disableBeacon: true,
    },
    {
      target: '#actions-menu-tour',
      title: 'Actions Menu',
      content: 'On single objects, the Actions menu will appear if there are available actions. Here you can view things like object links, replication metadata, app permissions and more!',
      disableBeacon: true,
    },
    {
      target: '#context-menu-tour',
      title: 'Context Menu',
      content: 'Our custom context menu can be used for copying and searching values, as well as triggering custom actions (based on object type) like finding service principals, showing app permissions and more!',
      disableBeacon: true,
    },
  ];

  /* 
    You can get Joyride's state changes using the callback prop.
    It will receive an object with the current state. 
  */
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {
      action, index, status, type, step,
    } = data;

    // Close user dropdown if no longer on the step or tour ends (forward or backward)
    if (step.target !== '#token-info-tour' || type === 'tour:end') {
      setUserDropdown(false);
    }

    // Close Context menu if no longer on the step or tour ends (forward or backward)
    if (step.target !== '#context-menu-tour' || type === 'tour:end') {
      const contextMenuEl = document.getElementById('context-menu-tour');
      if (contextMenuEl) contextMenuEl.style.display = 'none';
    }

    // Normal step movement (forward or backward)
    if ([EVENTS.STEP_AFTER].includes(type as any)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }

    // If target not found, can do things to find else continue with steps
    if ([EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      if (step.target === '#token-info-tour') { // Open user dropdown in nav
        setUserDropdown(true);
        setStepIndex(index);
    }
    if (step.target === '#context-menu-tour') { // Open context menu in table
      const contextMenuEl = document.getElementById('context-menu-tour');
      if (contextMenuEl) {
          const { style } = contextMenuEl;
          style.position = 'absolute';
          style.width = 'auto';
          style.display = 'flex';
          style.top = '110px';
          style.left = '50px';
      }
      setStepIndex(index);
    }
    if (step.target !== '#token-info-tour' && step.target !== '#context-menu-tour') { // Continue
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }
    }
    // If done close and reset
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      // You need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      setStepIndex(0);
      endTour();
    }
  };

  return (
    <Joyride // Docs: https://docs.react-joyride.com/
      showSkipButton
      continuous
      showProgress
      hideCloseButton
      locale={{ last: 'Finish', skip: 'Exit' }}
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      stepIndex={stepIndex}
      styles={{
        options: {
          primaryColor: colorMap[theme],
        }
        }
      }
    />
  );
};

/* eslint-enable */
export default JoyrideTour;
