/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDirectoryServicesAssignedPlanInitialState } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlanInitialState';
import { SystemXmlLinqXAttribute } from '../models/SystemXmlLinqXAttribute';
import { SystemXmlLinqXAttributeAllOfPreviousAttribute } from '../models/SystemXmlLinqXAttributeAllOfPreviousAttribute';
import { SystemXmlLinqXName } from '../models/SystemXmlLinqXName';
import { SystemXmlLinqXObjectDocument } from '../models/SystemXmlLinqXObjectDocument';
import { SystemXmlXmlNodeType } from '../models/SystemXmlXmlNodeType';
import { HttpFile } from '../http/http';

export class SystemXmlLinqXAttributeAllOfNextAttribute {
    'baseUri'?: string;
    'document'?: SystemXmlLinqXObjectDocument | null;
    'parent'?: MicrosoftOnlineDirectoryServicesAssignedPlanInitialState | null;
    'isNamespaceDeclaration'?: boolean;
    'name'?: SystemXmlLinqXName;
    'nextAttribute'?: SystemXmlLinqXAttributeAllOfNextAttribute | null;
    'nodeType'?: SystemXmlXmlNodeType;
    'previousAttribute'?: SystemXmlLinqXAttributeAllOfPreviousAttribute | null;
    'value'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "baseUri",
            "baseName": "baseUri",
            "type": "string",
            "format": ""
        },
        {
            "name": "document",
            "baseName": "document",
            "type": "SystemXmlLinqXObjectDocument",
            "format": ""
        },
        {
            "name": "parent",
            "baseName": "parent",
            "type": "MicrosoftOnlineDirectoryServicesAssignedPlanInitialState",
            "format": ""
        },
        {
            "name": "isNamespaceDeclaration",
            "baseName": "isNamespaceDeclaration",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "SystemXmlLinqXName",
            "format": ""
        },
        {
            "name": "nextAttribute",
            "baseName": "nextAttribute",
            "type": "SystemXmlLinqXAttributeAllOfNextAttribute",
            "format": ""
        },
        {
            "name": "nodeType",
            "baseName": "nodeType",
            "type": "SystemXmlXmlNodeType",
            "format": ""
        },
        {
            "name": "previousAttribute",
            "baseName": "previousAttribute",
            "type": "SystemXmlLinqXAttributeAllOfPreviousAttribute",
            "format": ""
        },
        {
            "name": "value",
            "baseName": "value",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SystemXmlLinqXAttributeAllOfNextAttribute.attributeTypeMap;
    }

    public constructor() {
    }
}



