import { SagaIterator } from '@redux-saga/types';
import { AckAnnouncementRequest, AddAnnouncementRequest, GetAnnouncementRequest } from 'app/actions/announcementActions';
import * as AnnouncementTypes from 'app/actions/announcementTypes';
import {
  acknowledgeAnnouncement, addAnnouncement, getAnnouncements, updateAnnouncement,
} from 'app/services/announcements';
import {
  call, put, SagaGenerator, takeEvery,
} from 'typed-redux-saga';

import type { App } from 'app/store';
import { logError } from 'app/constants/constants';

function* fetchAnnouncementsAsync(app: App, params: GetAnnouncementRequest): SagaIterator<void> {
  try {
    const resp = yield* call(getAnnouncements, app, params.includeAcknowledged);
    yield* put({ type: AnnouncementTypes.GET_ANNOUNCEMENTS_COMPLETED, resp });
  } catch (e) {
    if (e instanceof Error) {
      yield* put({ type: AnnouncementTypes.GET_ANNOUNCEMENTS_FAILED, error: e.message });
      logError('Failed to get Announcements. Response from the server was: ', e.message);
    }
  }
}

function* addAnnouncementsAsync(app: App, params: AddAnnouncementRequest): SagaIterator<void> {
  try {
    const resp = yield* call(addAnnouncement, app, params.data);
    if (resp != null) {
      yield* put({ type: AnnouncementTypes.ADD_ANNOUNCEMENT_COMPLETED, resp: resp.result });
    }
  } catch (e) {
    if (e instanceof Error) {
      yield* put({ type: AnnouncementTypes.ADD_ANNOUNCEMENT_FAILED, error: e.message });
      logError('Failed to add Announcement. Response from the server was: ', e.message);
    }
  }
}

function* updateAnnouncementsAsync(app: App, params: AddAnnouncementRequest): SagaIterator<void> {
  try {
    const resp = yield* call(updateAnnouncement, app, params.data);
    if (resp != null) {
      yield* put({ type: AnnouncementTypes.UPDATE_ANNOUNCEMENT_COMPLETED, resp: resp.result });
    }
  } catch (e) {
    if (e instanceof Error) {
      yield* put({ type: AnnouncementTypes.UPDATE_ANNOUNCEMENT_FAILED, error: e.message });
      logError('Failed to update Announcement. Response from the server was: ', e.message);
    }
  }
}

function* acknowledgeAnnouncementAsync(app: App, params: AckAnnouncementRequest): SagaIterator<void> {
  try {
    const resp = yield* call(acknowledgeAnnouncement, app, params.messageId);
    yield* put({ type: AnnouncementTypes.ACKNOWLEDGE_ANNOUNCEMENT_COMPLETED, resp });
  } catch (e) {
    if (e instanceof Error) {
      yield* put({ type: AnnouncementTypes.ACKNOWLEDGE_ANNOUNCEMENT_FAILED, error: e.message });
      logError('Failed to acknowledge Announcement. Response from the server was: ', e.message);
    }
  }
}

export default function* watchAll(app: App): SagaGenerator<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>AnnouncementTypes.GET_ANNOUNCEMENTS, fetchAnnouncementsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>AnnouncementTypes.ADD_ANNOUNCEMENT, addAnnouncementsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>AnnouncementTypes.UPDATE_ANNOUNCEMENT, updateAnnouncementsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>AnnouncementTypes.ACKNOWLEDGE_ANNOUNCEMENT, acknowledgeAnnouncementAsync, app);
}
