/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SearchDelegationEntriesUserIdentifierTypeParameterOneOf } from '../models/SearchDelegationEntriesUserIdentifierTypeParameterOneOf';
import { HttpFile } from '../http/http';

export class SearchDelegationEntriesUserIdentifierTypeParameter {

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    ];

    static getAttributeTypeMap() {
        return SearchDelegationEntriesUserIdentifierTypeParameter.attributeTypeMap;
    }

    public constructor() {
    }
}

