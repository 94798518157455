import './Announcements.scss';

import { AcknowledegeAnnouncement } from 'app/actions/announcementActions';
import { IStatusMessageDataModel, StatusMessageType } from 'app/proxyClients';
import { State } from 'app/reducers/state';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button, Card, Icon, Popup,
} from 'semantic-ui-react';
import { generateId } from 'app/helpers/helpers';

export interface AnnouncementProps {
  announcements: IStatusMessageDataModel[],
  closeModal: () => void
}

// eslint-disable-next-line
export const colorSeverities: any = {
  2: 'red',
  1: 'yellow',
  0: 'green',
};

const Announcements: FunctionComponent<AnnouncementProps> = ({
  announcements,
  closeModal,
}: AnnouncementProps) => {
  const dispatch = useDispatch();
  useSelector((state: State) => state.announcements.data);
  const sortedAnnouncements = [...announcements].sort((a, b) => b.createdAt.unix() - a.createdAt.unix());

  const ackAnnouncement = (id: string) => {
    dispatch(AcknowledegeAnnouncement(id));
    toast.success(
      'Announcement Acknowledged',
      {
        pauseOnHover: true,
        toastId: generateId(),
      },
    );
    if (sortedAnnouncements.length === 1) {
      closeModal();
    }
  };

  return (
    <Card.Group className="announcements" itemsPerRow={1} stackable>
      {sortedAnnouncements.map((a: IStatusMessageDataModel) => (
        <Card key={a.id}>
          <Popup
            content={`Message marked as ${StatusMessageType[a.messageType as StatusMessageType]}`}
            position="bottom center"
            trigger={(
              <Icon name="alarm" color={colorSeverities[a.messageType]} size="large" />
                  )}
          />

          <Card.Header>{a.title}</Card.Header>
          <Card.Meta>{moment.duration(-moment().diff(a.createdAt)).humanize(true)}</Card.Meta>
          <Card.Content>{a.message}</Card.Content>
          <Card.Content extra>
            <div className="ui buttons">
              {!a.hasBeenAcknowledged ? (
                <Button primary color="green" onClick={() => ackAnnouncement(a.id as string)}>
                  Acknowledge
                </Button>
              ) : (
                <Popup
                  content="Message has been acknowledged"
                  position="bottom center"
                  trigger={(
                    <Icon className="row-icon" color="green" name="check" size="large" />
                  )}
                />
              )}

            </div>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );
};

export default Announcements;
