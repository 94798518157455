// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { CommonModelsCompanyContextObjectClasses } from '../models/CommonModelsCompanyContextObjectClasses';
import { CommonModelsSystemContextObjectClasses } from '../models/CommonModelsSystemContextObjectClasses';
import { DSExplorerDataModelsBrowsedObjectSearchModel } from '../models/DSExplorerDataModelsBrowsedObjectSearchModel';
import { DSExplorerWebHostModelsPropertySearchRequestModel } from '../models/DSExplorerWebHostModelsPropertySearchRequestModel';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { SearchDelegationEntriesDelegationTypeParameter } from '../models/SearchDelegationEntriesDelegationTypeParameter';
import { SearchDelegationEntriesUserIdentifierTypeParameter } from '../models/SearchDelegationEntriesUserIdentifierTypeParameter';
import { SearchDirSyncProvisioningErrorsErrorCategoryParameter } from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameter';
import { SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter } from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter';
import { SearchEntitlementGrantsSourceObjectClassParameter } from '../models/SearchEntitlementGrantsSourceObjectClassParameter';
import { SearchRoleAssignmentsAuthorizationScopeTypeParameter } from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameter';
import { SearchRoleAssignmentsTargetObjectClassParameter } from '../models/SearchRoleAssignmentsTargetObjectClassParameter';
import { SearchScopedMembersTargetObjectClassParameter } from '../models/SearchScopedMembersTargetObjectClassParameter';
import { SearchServicePrincipalsFilterTypeParameter } from '../models/SearchServicePrincipalsFilterTypeParameter';

/**
 * no description
 */
export class SearchApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param companyIdentifier 
     * @param exchangeSingleAuthorityOnly 
     * @param proxyAddressFilterString 
     * @param sourceAnchorFilterString 
     * @param softDeleted 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchAddressLists(companyIdentifier?: string, exchangeSingleAuthorityOnly?: boolean, proxyAddressFilterString?: string, sourceAnchorFilterString?: string, softDeleted?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;









        // Path Params
        const localVarPath = '/api/Search/AddressLists';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (exchangeSingleAuthorityOnly !== undefined) {
            requestContext.setQueryParam("exchangeSingleAuthorityOnly", ObjectSerializer.serialize(exchangeSingleAuthorityOnly, "boolean", ""));
        }

        // Query Params
        if (proxyAddressFilterString !== undefined) {
            requestContext.setQueryParam("proxyAddressFilterString", ObjectSerializer.serialize(proxyAddressFilterString, "string", ""));
        }

        // Query Params
        if (sourceAnchorFilterString !== undefined) {
            requestContext.setQueryParam("sourceAnchorFilterString", ObjectSerializer.serialize(sourceAnchorFilterString, "string", ""));
        }

        // Query Params
        if (softDeleted !== undefined) {
            requestContext.setQueryParam("softDeleted", ObjectSerializer.serialize(softDeleted, "boolean", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchApplicationStubs(companyIdentifier?: string, appIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;






        // Path Params
        const localVarPath = '/api/Search/ApplicationStubs';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (appIds !== undefined) {
            requestContext.setQueryParam("appIds", ObjectSerializer.serialize(appIds, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param domainInUse 
     * @param availableToOtherTenants 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchApplications(companyIdentifier?: string, appIds?: string, domainInUse?: string, availableToOtherTenants?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;








        // Path Params
        const localVarPath = '/api/Search/Applications';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (appIds !== undefined) {
            requestContext.setQueryParam("appIds", ObjectSerializer.serialize(appIds, "string", ""));
        }

        // Query Params
        if (domainInUse !== undefined) {
            requestContext.setQueryParam("domainInUse", ObjectSerializer.serialize(domainInUse, "string", ""));
        }

        // Query Params
        if (availableToOtherTenants !== undefined) {
            requestContext.setQueryParam("availableToOtherTenants", ObjectSerializer.serialize(availableToOtherTenants, "boolean", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     */
    public async searchBrowseObjectCounts(companyIdentifier?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;


        // Path Params
        const localVarPath = '/api/Search/BrowseObjectCounts';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param partitionId 
     * @param contextIds 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchCompanies(partitionId?: number, contextIds?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;












        // Path Params
        const localVarPath = '/api/Search/Companies';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (partitionId !== undefined) {
            requestContext.setQueryParam("partitionId", ObjectSerializer.serialize(partitionId, "number", "int32"));
        }

        // Query Params
        if (contextIds !== undefined) {
            requestContext.setQueryParam("contextIds", ObjectSerializer.serialize(contextIds, "string", ""));
        }

        // Query Params
        if (creationTimeAfterOrAt !== undefined) {
            requestContext.setQueryParam("creationTimeAfterOrAt", ObjectSerializer.serialize(creationTimeAfterOrAt, "string", ""));
        }

        // Query Params
        if (creationTimeBeforeOrAt !== undefined) {
            requestContext.setQueryParam("creationTimeBeforeOrAt", ObjectSerializer.serialize(creationTimeBeforeOrAt, "string", ""));
        }

        // Query Params
        if (authorizedServiceInstance !== undefined) {
            requestContext.setQueryParam("authorizedServiceInstance", ObjectSerializer.serialize(authorizedServiceInstance, "string", ""));
        }

        // Query Params
        if (authorizedServiceInstancePrefix !== undefined) {
            requestContext.setQueryParam("authorizedServiceInstancePrefix", ObjectSerializer.serialize(authorizedServiceInstancePrefix, "string", ""));
        }

        // Query Params
        if (dirSyncEnabledOnly !== undefined) {
            requestContext.setQueryParam("dirSyncEnabledOnly", ObjectSerializer.serialize(dirSyncEnabledOnly, "boolean", ""));
        }

        // Query Params
        if (dirSyncStateFilter !== undefined) {
            requestContext.setQueryParam("dirSyncStateFilter", ObjectSerializer.serialize(dirSyncStateFilter, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param targetContextId 
     * @param displayName 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchContracts(companyIdentifier?: string, targetContextId?: string, displayName?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;







        // Path Params
        const localVarPath = '/api/Search/Contracts';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (targetContextId !== undefined) {
            requestContext.setQueryParam("targetContextId", ObjectSerializer.serialize(targetContextId, "string", ""));
        }

        // Query Params
        if (displayName !== undefined) {
            requestContext.setQueryParam("displayName", ObjectSerializer.serialize(displayName, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param alternativeSecurityIdString 
     * @param delegationType 
     * @param userIdentifierString 
     * @param userIdentifierType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchDelegationEntries(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, alternativeSecurityIdString?: string, delegationType?: SearchDelegationEntriesDelegationTypeParameter, userIdentifierString?: string, userIdentifierType?: SearchDelegationEntriesUserIdentifierTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;











        // Path Params
        const localVarPath = '/api/Search/DelegationEntries';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (sourceObjectId !== undefined) {
            requestContext.setQueryParam("sourceObjectId", ObjectSerializer.serialize(sourceObjectId, "string", "uuid"));
        }

        // Query Params
        if (targetObjectId !== undefined) {
            requestContext.setQueryParam("targetObjectId", ObjectSerializer.serialize(targetObjectId, "string", "uuid"));
        }

        // Query Params
        if (alternativeSecurityIdString !== undefined) {
            requestContext.setQueryParam("alternativeSecurityIdString", ObjectSerializer.serialize(alternativeSecurityIdString, "string", ""));
        }

        // Query Params
        if (delegationType !== undefined) {
            requestContext.setQueryParam("delegationType", ObjectSerializer.serialize(delegationType, "SearchDelegationEntriesDelegationTypeParameter", ""));
        }

        // Query Params
        if (userIdentifierString !== undefined) {
            requestContext.setQueryParam("userIdentifierString", ObjectSerializer.serialize(userIdentifierString, "string", ""));
        }

        // Query Params
        if (userIdentifierType !== undefined) {
            requestContext.setQueryParam("userIdentifierType", ObjectSerializer.serialize(userIdentifierType, "SearchDelegationEntriesUserIdentifierTypeParameter", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryObjectClass 
     */
    public async searchDeletedCompanyContext(companyIdentifier?: string, objectId?: string, directoryObjectClass?: CommonModelsCompanyContextObjectClasses, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;




        // Path Params
        const localVarPath = '/api/Search/DeletedCompanyContext';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (objectId !== undefined) {
            requestContext.setQueryParam("objectId", ObjectSerializer.serialize(objectId, "string", "uuid"));
        }

        // Query Params
        if (directoryObjectClass !== undefined) {
            requestContext.setQueryParam("directoryObjectClass", ObjectSerializer.serialize(directoryObjectClass, "CommonModelsCompanyContextObjectClasses", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param objectId 
     * @param directoryObjectClass 
     */
    public async searchDeletedSystemContext(objectId?: string, directoryObjectClass?: CommonModelsSystemContextObjectClasses, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;



        // Path Params
        const localVarPath = '/api/Search/DeletedSystemContext';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (objectId !== undefined) {
            requestContext.setQueryParam("objectId", ObjectSerializer.serialize(objectId, "string", "uuid"));
        }

        // Query Params
        if (directoryObjectClass !== undefined) {
            requestContext.setQueryParam("directoryObjectClass", ObjectSerializer.serialize(directoryObjectClass, "CommonModelsSystemContextObjectClasses", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchDevices(companyIdentifier?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;





        // Path Params
        const localVarPath = '/api/Search/Devices';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param propertyValueFilterString 
     * @param propertyNameFilterValue 
     * @param errorCategory 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchDirSyncProvisioningErrors(companyIdentifier?: string, propertyValueFilterString?: string, propertyNameFilterValue?: SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter, errorCategory?: SearchDirSyncProvisioningErrorsErrorCategoryParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;








        // Path Params
        const localVarPath = '/api/Search/DirSyncProvisioningErrors';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (propertyValueFilterString !== undefined) {
            requestContext.setQueryParam("propertyValueFilterString", ObjectSerializer.serialize(propertyValueFilterString, "string", ""));
        }

        // Query Params
        if (propertyNameFilterValue !== undefined) {
            requestContext.setQueryParam("propertyNameFilterValue", ObjectSerializer.serialize(propertyNameFilterValue, "SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter", ""));
        }

        // Query Params
        if (errorCategory !== undefined) {
            requestContext.setQueryParam("errorCategory", ObjectSerializer.serialize(errorCategory, "SearchDirSyncProvisioningErrorsErrorCategoryParameter", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param displayNameFilterString 
     * @param isIndirectGrants 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param sourceObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchEntitlementGrants(companyIdentifier?: string, displayNameFilterString?: string, isIndirectGrants?: boolean, sourceObjectId?: string, targetObjectId?: string, sourceObjectClass?: SearchEntitlementGrantsSourceObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;










        // Path Params
        const localVarPath = '/api/Search/EntitlementGrants';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (displayNameFilterString !== undefined) {
            requestContext.setQueryParam("displayNameFilterString", ObjectSerializer.serialize(displayNameFilterString, "string", ""));
        }

        // Query Params
        if (isIndirectGrants !== undefined) {
            requestContext.setQueryParam("isIndirectGrants", ObjectSerializer.serialize(isIndirectGrants, "boolean", ""));
        }

        // Query Params
        if (sourceObjectId !== undefined) {
            requestContext.setQueryParam("sourceObjectId", ObjectSerializer.serialize(sourceObjectId, "string", "uuid"));
        }

        // Query Params
        if (targetObjectId !== undefined) {
            requestContext.setQueryParam("targetObjectId", ObjectSerializer.serialize(targetObjectId, "string", "uuid"));
        }

        // Query Params
        if (sourceObjectClass !== undefined) {
            requestContext.setQueryParam("sourceObjectClass", ObjectSerializer.serialize(sourceObjectClass, "SearchEntitlementGrantsSourceObjectClassParameter", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchGroupLicensingErrors(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;







        // Path Params
        const localVarPath = '/api/Search/GroupLicensingErrors';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (sourceObjectId !== undefined) {
            requestContext.setQueryParam("sourceObjectId", ObjectSerializer.serialize(sourceObjectId, "string", "uuid"));
        }

        // Query Params
        if (targetObjectId !== undefined) {
            requestContext.setQueryParam("targetObjectId", ObjectSerializer.serialize(targetObjectId, "string", "uuid"));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param textFilter 
     * @param domainInUse 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchGroups(companyIdentifier?: string, textFilter?: string, domainInUse?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;







        // Path Params
        const localVarPath = '/api/Search/Groups';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (textFilter !== undefined) {
            requestContext.setQueryParam("textFilter", ObjectSerializer.serialize(textFilter, "string", ""));
        }

        // Query Params
        if (domainInUse !== undefined) {
            requestContext.setQueryParam("domainInUse", ObjectSerializer.serialize(domainInUse, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param keyGroupIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchKeyGroups(companyIdentifier?: string, keyGroupIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;






        // Path Params
        const localVarPath = '/api/Search/KeyGroups';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (keyGroupIds !== undefined) {
            requestContext.setQueryParam("keyGroupIds", ObjectSerializer.serialize(keyGroupIds, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public async searchObjects(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsPropertySearchRequestModel' is not null or undefined
        if (dSExplorerWebHostModelsPropertySearchRequestModel === null || dSExplorerWebHostModelsPropertySearchRequestModel === undefined) {
            throw new RequiredError("SearchApi", "searchObjects", "dSExplorerWebHostModelsPropertySearchRequestModel");
        }


        // Path Params
        const localVarPath = '/api/Search/Objects';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsPropertySearchRequestModel, "DSExplorerWebHostModelsPropertySearchRequestModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public async searchPagedObjects(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsPropertySearchRequestModel' is not null or undefined
        if (dSExplorerWebHostModelsPropertySearchRequestModel === null || dSExplorerWebHostModelsPropertySearchRequestModel === undefined) {
            throw new RequiredError("SearchApi", "searchPagedObjects", "dSExplorerWebHostModelsPropertySearchRequestModel");
        }


        // Path Params
        const localVarPath = '/api/Search/PagedObjects';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsPropertySearchRequestModel, "DSExplorerWebHostModelsPropertySearchRequestModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param authorizationScopeType 
     * @param authorizationScopeId 
     * @param isSelfScope 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchRoleAssignments(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, authorizationScopeType?: SearchRoleAssignmentsAuthorizationScopeTypeParameter, authorizationScopeId?: string, isSelfScope?: boolean, targetObjectClass?: SearchRoleAssignmentsTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;











        // Path Params
        const localVarPath = '/api/Search/RoleAssignments';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (sourceObjectId !== undefined) {
            requestContext.setQueryParam("sourceObjectId", ObjectSerializer.serialize(sourceObjectId, "string", "uuid"));
        }

        // Query Params
        if (targetObjectId !== undefined) {
            requestContext.setQueryParam("targetObjectId", ObjectSerializer.serialize(targetObjectId, "string", "uuid"));
        }

        // Query Params
        if (authorizationScopeType !== undefined) {
            requestContext.setQueryParam("authorizationScopeType", ObjectSerializer.serialize(authorizationScopeType, "SearchRoleAssignmentsAuthorizationScopeTypeParameter", ""));
        }

        // Query Params
        if (authorizationScopeId !== undefined) {
            requestContext.setQueryParam("authorizationScopeId", ObjectSerializer.serialize(authorizationScopeId, "string", "uuid"));
        }

        // Query Params
        if (isSelfScope !== undefined) {
            requestContext.setQueryParam("isSelfScope", ObjectSerializer.serialize(isSelfScope, "boolean", ""));
        }

        // Query Params
        if (targetObjectClass !== undefined) {
            requestContext.setQueryParam("targetObjectClass", ObjectSerializer.serialize(targetObjectClass, "SearchRoleAssignmentsTargetObjectClassParameter", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param scopeId 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchScopedMembers(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, scopeId?: string, targetObjectClass?: SearchScopedMembersTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;









        // Path Params
        const localVarPath = '/api/Search/ScopedMembers';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (sourceObjectId !== undefined) {
            requestContext.setQueryParam("sourceObjectId", ObjectSerializer.serialize(sourceObjectId, "string", "uuid"));
        }

        // Query Params
        if (targetObjectId !== undefined) {
            requestContext.setQueryParam("targetObjectId", ObjectSerializer.serialize(targetObjectId, "string", "uuid"));
        }

        // Query Params
        if (scopeId !== undefined) {
            requestContext.setQueryParam("scopeId", ObjectSerializer.serialize(scopeId, "string", "uuid"));
        }

        // Query Params
        if (targetObjectClass !== undefined) {
            requestContext.setQueryParam("targetObjectClass", ObjectSerializer.serialize(targetObjectClass, "SearchScopedMembersTargetObjectClassParameter", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param filterString 
     * @param filterType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchServicePrincipals(companyIdentifier?: string, filterString?: string, filterType?: SearchServicePrincipalsFilterTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;







        // Path Params
        const localVarPath = '/api/Search/ServicePrincipals';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (filterString !== undefined) {
            requestContext.setQueryParam("filterString", ObjectSerializer.serialize(filterString, "string", ""));
        }

        // Query Params
        if (filterType !== undefined) {
            requestContext.setQueryParam("filterType", ObjectSerializer.serialize(filterType, "SearchServicePrincipalsFilterTypeParameter", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param skuIdToExclude 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchSubscriptions(companyIdentifier?: string, skuIdToExclude?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;






        // Path Params
        const localVarPath = '/api/Search/Subscriptions';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (skuIdToExclude !== undefined) {
            requestContext.setQueryParam("skuIdToExclude", ObjectSerializer.serialize(skuIdToExclude, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param adminsOnly 
     * @param enabled 
     * @param softDeleted 
     * @param domainsInUse 
     * @param synchronizedOnly 
     * @param textFilter 
     * @param assignedLicenseUnsetOnly 
     * @param assignedLicenseFilter 
     * @param assignedPlanFilters 
     * @param usageLocation 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async searchUsers(companyIdentifier?: string, adminsOnly?: boolean, enabled?: boolean, softDeleted?: boolean, domainsInUse?: string, synchronizedOnly?: boolean, textFilter?: string, assignedLicenseUnsetOnly?: boolean, assignedLicenseFilter?: string, assignedPlanFilters?: string, usageLocation?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;















        // Path Params
        const localVarPath = '/api/Search/Users';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (companyIdentifier !== undefined) {
            requestContext.setQueryParam("companyIdentifier", ObjectSerializer.serialize(companyIdentifier, "string", ""));
        }

        // Query Params
        if (adminsOnly !== undefined) {
            requestContext.setQueryParam("adminsOnly", ObjectSerializer.serialize(adminsOnly, "boolean", ""));
        }

        // Query Params
        if (enabled !== undefined) {
            requestContext.setQueryParam("enabled", ObjectSerializer.serialize(enabled, "boolean", ""));
        }

        // Query Params
        if (softDeleted !== undefined) {
            requestContext.setQueryParam("softDeleted", ObjectSerializer.serialize(softDeleted, "boolean", ""));
        }

        // Query Params
        if (domainsInUse !== undefined) {
            requestContext.setQueryParam("domainsInUse", ObjectSerializer.serialize(domainsInUse, "string", ""));
        }

        // Query Params
        if (synchronizedOnly !== undefined) {
            requestContext.setQueryParam("synchronizedOnly", ObjectSerializer.serialize(synchronizedOnly, "boolean", ""));
        }

        // Query Params
        if (textFilter !== undefined) {
            requestContext.setQueryParam("textFilter", ObjectSerializer.serialize(textFilter, "string", ""));
        }

        // Query Params
        if (assignedLicenseUnsetOnly !== undefined) {
            requestContext.setQueryParam("assignedLicenseUnsetOnly", ObjectSerializer.serialize(assignedLicenseUnsetOnly, "boolean", ""));
        }

        // Query Params
        if (assignedLicenseFilter !== undefined) {
            requestContext.setQueryParam("assignedLicenseFilter", ObjectSerializer.serialize(assignedLicenseFilter, "string", ""));
        }

        // Query Params
        if (assignedPlanFilters !== undefined) {
            requestContext.setQueryParam("assignedPlanFilters", ObjectSerializer.serialize(assignedPlanFilters, "string", ""));
        }

        // Query Params
        if (usageLocation !== undefined) {
            requestContext.setQueryParam("usageLocation", ObjectSerializer.serialize(usageLocation, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class SearchApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchAddressLists
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchAddressListsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchApplicationStubs
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchApplicationStubsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchApplications
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchApplicationsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchBrowseObjectCounts
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchBrowseObjectCountsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<DSExplorerDataModelsBrowsedObjectSearchModel> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<DSExplorerDataModelsBrowsedObjectSearchModel> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<DSExplorerDataModelsBrowsedObjectSearchModel>", ""
            ) as Array<DSExplorerDataModelsBrowsedObjectSearchModel>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<DSExplorerDataModelsBrowsedObjectSearchModel> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<DSExplorerDataModelsBrowsedObjectSearchModel>", ""
            ) as Array<DSExplorerDataModelsBrowsedObjectSearchModel>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchCompanies
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchCompaniesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchContracts
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchContractsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchDelegationEntries
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchDelegationEntriesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchDeletedCompanyContext
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchDeletedCompanyContextWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchDeletedSystemContext
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchDeletedSystemContextWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchDevices
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchDevicesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchDirSyncProvisioningErrors
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchDirSyncProvisioningErrorsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchEntitlementGrants
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchEntitlementGrantsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchGroupLicensingErrors
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchGroupLicensingErrorsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchGroups
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchGroupsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchKeyGroups
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchKeyGroupsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchObjects
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchObjectsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchPagedObjects
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchPagedObjectsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchRoleAssignments
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchRoleAssignmentsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchScopedMembers
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchScopedMembersWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchServicePrincipals
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchServicePrincipalsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchSubscriptions
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchSubscriptionsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to searchUsers
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async searchUsersWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
