import { enumToDropDownList, guidChecker } from 'app/helpers/helpers';
import { AuthorizationScopeType, RoleAssignmentValidTargetObjectClasses } from 'app/proxyClients';
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Checkbox, Dropdown, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface RoleAssignmentsSearchForm {
  sourceObjectId: string,
  targetObjectClass: number | undefined,
  targetObjectId: string,
  authorizationScopeType: number | undefined,
  authorizationScopeId: string,
  isSelfScope: boolean | undefined
}

const RoleAssignmentsSB: FunctionComponent<ObjectTypeProps<RoleAssignmentsSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<RoleAssignmentsSearchForm>) => {
  const authorizationScopeOptions = enumToDropDownList(AuthorizationScopeType, ['None']);
  const [searchForm, setSearchForm] = useState<RoleAssignmentsSearchForm>({
    sourceObjectId: '',
    targetObjectClass: undefined,
    targetObjectId: '',
    authorizationScopeType: -1,
    authorizationScopeId: '',
    isSelfScope: false,
  });

  const id = _.uniqueId('RoleAssignmentsSB-');
  const targetObjectTypeDropdownId = `${id}-targetObjectTypeDropdown`;
  const authorizationScopeTypeDropdownId = `${id}-authorizationScopeTypeDropdown`;

  // Add "Any" Option
  authorizationScopeOptions.unshift({
    key: -1,
    text: '(any)',
    value: -1,
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Source RoleDefinition ObjectId
          <Popup
            flowing
            position="bottom left"
            content="ObjectId of the source (RoleDefinition) object to filter on.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                disabled={disabled}
                error={searchForm.sourceObjectId.length > 0 && !guidChecker(searchForm.sourceObjectId)}
                value={searchForm.sourceObjectId}
                className="margin-bottom"
                fluid
                placeholder="Source RoleDefinition ObjectId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, sourceObjectId: value })}
              />
                      )}
          />
        </label>
        <label className="label" htmlFor={targetObjectTypeDropdownId}>Target Object Type</label>
        <Dropdown
          selection
          fluid
          clearable
          placeholder="Please select Target Object Type"
          className="margin-bottom"
          id={targetObjectTypeDropdownId}
          disabled={disabled}
          options={enumToDropDownList(RoleAssignmentValidTargetObjectClasses)}
          value={searchForm.targetObjectClass}
          onChange={(e, { value }) => setSearchForm({ ...searchForm, targetObjectClass: value as number })}
        />
        <label className="label">
          Target ObjectId
          <Popup
            flowing
            position="bottom left"
            content="ObjectId of the target object to filter on.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.targetObjectId.length > 0 && !guidChecker(searchForm.targetObjectId)}
                disabled={disabled}
                value={searchForm.targetObjectId}
                className="margin-bottom"
                fluid
                placeholder="Target ObjectId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, targetObjectId: value })}
              />
                      )}
          />
        </label>
        <label className="label" htmlFor={authorizationScopeTypeDropdownId}>Scope Type</label>
        <Dropdown
          selection
          fluid
          placeholder="Please select Scope Type"
          clearable
          className="margin-bottom"
          id={authorizationScopeTypeDropdownId}
          disabled={disabled}
          options={authorizationScopeOptions}
          value={searchForm.authorizationScopeType}
          onChange={(e, { value }) => setSearchForm({ ...searchForm, authorizationScopeType: value as number })}
        />
        <label className="label">
          Scope ObjectId
          <Popup
            flowing
            position="bottom left"
            content="ScopedId (AdministrativeUnit.ObjectId) to filter on.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.authorizationScopeId.length > 0 && !guidChecker(searchForm.authorizationScopeId)}
                disabled={disabled}
                value={searchForm.authorizationScopeId}
                className="margin-bottom"
                fluid
                placeholder="Search Scope ObjectId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, authorizationScopeId: value })}
              />
                      )}
          />
        </label>
        <Popup
          flowing
          position="bottom left"
          content="Whether the administrative scope is marked as self-scope"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="last-form-item"
              toggle
              label="Self-Scope"
              checked={searchForm.isSelfScope}
              onChange={(e, { checked }) => setSearchForm({ ...searchForm, isSelfScope: checked })}
            />
                    )}
        />
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({
              sourceObjectId: '',
              targetObjectClass: undefined,
              targetObjectId: '',
              authorizationScopeType: undefined,
              authorizationScopeId: '',
              isSelfScope: false,
            })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            // disabled={
            //                 searchForm.sourceObjectId === ''
            //                 && searchForm.targetObjectId === ''
            //                 && searchForm.authorizationScopeId === ''
            //             }
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default RoleAssignmentsSB;
