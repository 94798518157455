/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DSExplorerWebHostModelsPropertyFilterModel } from '../models/DSExplorerWebHostModelsPropertyFilterModel';
import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
import { MicrosoftOnlineDirectoryServicesDirectoryProperty } from '../models/MicrosoftOnlineDirectoryServicesDirectoryProperty';
import { HttpFile } from '../http/http';

export class DSExplorerWebHostModelsBulkSearchRequestModel {
    'companyIdentifier'?: string;
    'properties'?: Array<MicrosoftOnlineDirectoryServicesDirectoryProperty>;
    'softDeleted'?: boolean;
    'filter'?: Array<DSExplorerWebHostModelsPropertyFilterModel>;
    'resultsLimit'?: number | null;
    'pageToken'?: string | null;
    'replicaToken'?: string | null;
    'objectClasses'?: Array<MicrosoftOnlineDirectoryServicesDirectoryObjectClass>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "companyIdentifier",
            "baseName": "companyIdentifier",
            "type": "string",
            "format": ""
        },
        {
            "name": "properties",
            "baseName": "properties",
            "type": "Array<MicrosoftOnlineDirectoryServicesDirectoryProperty>",
            "format": ""
        },
        {
            "name": "softDeleted",
            "baseName": "softDeleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "filter",
            "baseName": "filter",
            "type": "Array<DSExplorerWebHostModelsPropertyFilterModel>",
            "format": ""
        },
        {
            "name": "resultsLimit",
            "baseName": "resultsLimit",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "pageToken",
            "baseName": "pageToken",
            "type": "string",
            "format": ""
        },
        {
            "name": "replicaToken",
            "baseName": "replicaToken",
            "type": "string",
            "format": ""
        },
        {
            "name": "objectClasses",
            "baseName": "objectClasses",
            "type": "Array<MicrosoftOnlineDirectoryServicesDirectoryObjectClass>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return DSExplorerWebHostModelsBulkSearchRequestModel.attributeTypeMap;
    }

    public constructor() {
    }
}

