import './ZeroStateTab.scss';

import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import Spinner from 'app/components/shared/Spinner/Spinner';
import ContentModal from 'app/components/shared/ContentModal/ContentModal';
import { Segment, Transition } from 'semantic-ui-react';
import { removeTab } from 'app/actions/tabActions';
import { TabType } from 'app/typings';

interface ZeroStateTabProps {
  text?: string,
  tabId?: string,
  parentTab?: string,
  statusModal?: {
    message?: string,
    error?: boolean,
    closeEvent?: () => void
  }
  tabType?: TabType
}

const ZeroStateTab: FunctionComponent<ZeroStateTabProps> = ({
  text = 'Loading...',
  tabId,
  parentTab,
  statusModal: { error, message, closeEvent } = { error: false },
  tabType,
}: ZeroStateTabProps) => {
  const dispatch = useDispatch();

  return (
    <Transition transitionOnMount animation="scale" duration={600}>
      <Segment className="zero-state-tab" id={tabId}>
        <Spinner text={text} dimmer active={error === false} />
        <ContentModal
          icon="warning sign"
          header="Error"
          style="error"
          message={message}
          open={error}
          closeEvent={() => {
            if (tabId) {
              dispatch(removeTab(tabId, tabType || TabType.Company, parentTab));
            }
            if (closeEvent) {
              closeEvent();
            }
          }}
        />
      </Segment>
    </Transition>

  );
};

ZeroStateTab.defaultProps = {
  text: 'Loading...',
  tabId: undefined,
  parentTab: undefined,
  statusModal: { error: false },
  tabType: TabType.Company,
};

export default ZeroStateTab;
