import * as partitionTypes from 'app/actions/partitionTypes';
import { PartitionCompaniesForm, PartitionFailoverForm } from 'app/typings';

export function getPartitionIds() {
  return {
    type: partitionTypes.GET_PARTITION_IDS,
  };
}

export function getPartitionDetails() {
  return {
    type: partitionTypes.GET_PARTITION_DETAILS,
  };
}

export function getPartitionFailovers(params: PartitionFailoverForm) {
  return {
    type: partitionTypes.GET_PARTITION_FAILOVERS,
    data: params,
    metadata: {
      sidebarObjectType: 'Partition Failovers',
    },
  };
}

export function getCompaniesByPartition(params: PartitionCompaniesForm) {
  return {
    type: partitionTypes.GET_COMPANIES_BY_PARTITION,
    data: params,
    metadata: {
      sidebarObjectType: 'Partition Companies',
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function displayPartitionCompOrFailover(data: any, type: string) {
  return {
    type: partitionTypes.DISPLAY_PARTITION_COMPANY_OR_FAILOVER,
    data,
    metadata: {
      sidebarObjectType: type,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function displayPartitionDetails(data: any) {
  return {
    type: partitionTypes.DISPLAY_PARTITION_DETAILS,
    data,
    metadata: {
      sidebarObjectType: 'Partition Details',
    },
  };
}
