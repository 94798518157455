import {
  call, takeEvery, SagaGenerator, put,
} from 'typed-redux-saga';
import type { App } from 'app/store';
import * as partitionTypes from 'app/actions/partitionTypes';
import {
  createTabWithAsyncOperation, createTabWithComponent,
} from 'app/sagas/tabSagas';
import {
  TabGeneratorEffect,
  TabType,
} from 'app/typings';
import { generateId, promiseToSaga } from 'app/helpers/helpers';
import {
  getCompaniesByPartition,
  getPartitionDetails,
  getPartitionFailovers,
  getPartitionIds,
} from 'app/services/partition';
import React from 'react';
import SingleObject from 'app/components/Company/SingleObject';
import { DSObjectDerivedProperty } from 'app/helpers/types';

function* fetchPartitionIdsAsync(app: App): TabGeneratorEffect<void> {
  yield* call(getPartitionIds, app);
}

function* fetchPartitionDetailsAsync(app: App): TabGeneratorEffect<void> {
  try {
    const resp = yield* call(getPartitionDetails, app);
    yield* put({ type: partitionTypes.GET_PARTITION_DETAILS_COMPLETED, resp });
  } catch (error) {
    yield* put({ type: partitionTypes.GET_PARTITION_DETAILS_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchCompaniesByPartitionAsync(app: App, params: any) {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'Partition',
    tabRoutingArea: TabType.Partition,
    actionBegin: partitionTypes.GET_COMPANIES_BY_PARTITION,
    actionComplete: partitionTypes.GET_COMPANIES_BY_PARTITION_COMPLETED,
    actionFailed: partitionTypes.GET_COMPANIES_BY_PARTITION_FAILED,
    asyncDataFetch: promiseToSaga(getCompaniesByPartition),
    getDisplayName: () => `Partition ${params.data.partitionId} Companies`,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchPartitionFailoversAsync(app: App, params: any) {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'Partition',
    tabRoutingArea: TabType.Partition,
    actionBegin: partitionTypes.GET_PARTITION_FAILOVERS,
    actionComplete: partitionTypes.GET_PARTITION_FAILOVERS_COMPLETED,
    actionFailed: partitionTypes.GET_PARTITION_FAILOVERS_FAILED,
    asyncDataFetch: promiseToSaga(getPartitionFailovers),
    getDisplayName: () => `Partition ${params.data.partitionId} Failovers`,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* displayPartitionDetails(app: App, params: any): TabGeneratorEffect<void> {
  const newId = generateId();
  const name = `Partition ${params.data.partitionId} Details`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* call(createTabWithComponent as any,
    name,
    newId,
    TabType.Partition,
    React.createElement('div', { className: 'secondary-tabs-border' }, React.createElement(SingleObject, {
      dataResult: params.data.properties,
      isPropBag: true,
    })),
    true);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* displayCompanyOrFailoverPartition(app: App, params: any): TabGeneratorEffect<void> {
  const newId = generateId();
  const name = params.metadata.sidebarObjectType === 'Partition Failover'
    ? params.data.find((element: DSObjectDerivedProperty) => element.displayName === 'FailoverStarted').values[0]
    : params.data.displayName || params.data.DisplayName;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* call(createTabWithComponent as any,
    name,
    newId,
    TabType.Partition,
    React.createElement('div', { className: 'secondary-tabs-border' }, React.createElement(SingleObject, {
      dataResult: params.data,
      isPropBag: params.metadata.sidebarObjectType === 'Partition Failover',
    })),
    true);
}

export default function* watchAll(app: App): SagaGenerator<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>partitionTypes.GET_PARTITION_IDS, fetchPartitionIdsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>partitionTypes.GET_PARTITION_DETAILS, fetchPartitionDetailsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>partitionTypes.GET_COMPANIES_BY_PARTITION, fetchCompaniesByPartitionAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>partitionTypes.GET_PARTITION_FAILOVERS, fetchPartitionFailoversAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>partitionTypes.DISPLAY_PARTITION_DETAILS, displayPartitionDetails, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>partitionTypes.DISPLAY_PARTITION_COMPANY_OR_FAILOVER, displayCompanyOrFailoverPartition, app);
}
