export const GET_SYSTEM_SETTINGS = 'GET_SYSTEM_SETTINGS' as const;
export const GET_SYSTEM_SETTINGS_COMPLETED = 'GET_SYSTEM_SETTINGS_COMPLETED' as const;
export const GET_SYSTEM_SETTINGS_FAILED = 'GET_SYSTEM_SETTINGS_FAILED' as const;

export const GET_ROLE_TEMPLATES = 'GET_ROLE_TEMPLATES' as const;
export const GET_ROLE_TEMPLATES_COMPLETED = 'GET_ROLE_TEMPLATES_COMPLETED' as const;
export const GET_ROLE_TEMPLATES_FAILED = 'GET_ROLE_TEMPLATES_FAILED' as const;

export const GET_PERMISSION_SCOPES = 'GET_PERMISSION_SCOPES' as const;
export const GET_PERMISSION_SCOPES_COMPLETED = 'GET_PERMISSION_SCOPES_COMPLETED' as const;
export const GET_PERMISSION_SCOPES_FAILED = 'GET_PERMISSION_SCOPES_FAILED' as const;

export const GET_SERVICE_PRINCIPAL_TEMPLATES = 'GET_SERVICE_PRINCIPAL_TEMPLATES' as const;
export const GET_SERVICE_PRINCIPAL_TEMPLATES_COMPLETED = 'GET_SERVICE_PRINCIPAL_TEMPLATES_COMPLETED' as const;
export const GET_SERVICE_PRINCIPAL_TEMPLATES_FAILED = 'GET_SERVICE_PRINCIPAL_TEMPLATES_FAILED' as const;

export const GET_TRUSTED_CERTIFICATES = 'GET_TRUSTED_CERTIFICATES' as const;
export const GET_TRUSTED_CERTIFICATES_COMPLETED = 'GET_TRUSTED_CERTIFICATES_COMPLETED' as const;
export const GET_TRUSTED_CERTIFICATES_FAILED = 'GET_TRUSTED_CERTIFICATES_FAILED' as const;

export const GET_TYPE_CONFIGURATION = 'GET_TYPE_CONFIGURATION' as const;
export const GET_TYPE_CONFIGURATION_COMPLETED = 'GET_TYPE_CONFIGURATION_COMPLETED' as const;
export const GET_TYPE_CONFIGURATION_FAILED = 'GET_TYPE_CONFIGURATION_FAILED' as const;

export const GET_SYSTEM_CONTEXT_EXPORT = 'GET_SYSTEM_CONTEXT_EXPORT' as const;
export const GET_SYSTEM_CONTEXT_EXPORT_COMPLETED = 'GET_SYSTEM_CONTEXT_EXPORT_COMPLETED' as const;
export const GET_SYSTEM_CONTEXT_EXPORT_FAILED = 'GET_SYSTEM_CONTEXT_EXPORT_FAILED' as const;

export const GET_SYSTEM_SINGLE_OBJECT = 'GET_SYSTEM_SINGLE_OBJECT' as const;
export const GET_SYSTEM_SINGLE_OBJECT_COMPLETED = 'GET_SYSTEM_SINGLE_OBJECT_COMPLETED' as const;
export const GET_SYSTEM_SINGLE_OBJECT_FAILED = 'GET_SYSTEM_SINGLE_OBJECT_FAILED' as const;

export const GET_SERVICE_DETAILS = 'GET_SERVICE_DETAILS' as const;
export const GET_SERVICE_DETAILS_COMPLETED = 'GET_SERVICE_DETAILS_COMPLETED' as const;
export const GET_SERVICE_DETAILS_FAILED = 'GET_SERVICE_DETAILS_FAILED' as const;

export const GET_SERVICE_INSTANCE_DETAILS = 'GET_SERVICE_INSTANCE_DETAILS' as const;
export const GET_SERVICE_INSTANCE_DETAILS_COMPLETED = 'GET_SERVICE_INSTANCE_DETAILS_COMPLETED' as const;
export const GET_SERVICE_INSTANCE_DETAILS_FAILED = 'GET_SERVICE_INSTANCE_DETAILS_FAILED' as const;

export const DISPLAY_PROPERTY_BAG = 'DISPLAY_PROPERTY_BAG' as const;
export const DISPLAY_PROPERTY_BAG_COMPLETED = 'DISPLAY_PROPERTY_BAG_COMPLETED' as const;
export const DISPLAY_PROPERTY_BAG_FAILED = 'DISPLAY_PROPERTY_BAG_FAILED' as const;

export const GET_SYSTEM_TASKS = 'GET_SYSTEM_TASKS' as const;
export const GET_SYSTEM_TASKS_COMPLETED = 'GET_SYSTEM_TASKS_COMPLETED' as const;
export const GET_SYSTEM_TASKS_FAILED = 'GET_SYSTEM_TASKS_FAILED' as const;
