import './AnnouncemenetsManagement.scss';

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button, Container, Icon, Table,
} from 'semantic-ui-react';

import { StatusMessageDataModel } from 'app/helpers/types';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateAnnouncement } from 'app/actions/announcementActions';
import { State } from 'app/reducers/state';
import { colorSeverities } from '../Announcements/Announcements';
import StatusModal from '../shared/StatusModal/StatusModal';
import TimeFormat from '../shared/Utilities/TimeFormat';
import CreateAnnouncement from './CreateAnnouncement/CreateAnnouncement';

const AnnouncementManagement: FunctionComponent = () => {
  const dispatch = useDispatch();
  const preferencesState = useSelector((state: State) => state.preferences);
  const announcementState = useSelector((state: State) => state.announcements);
  const [loading, setLoading] = useState(false);
  const [openAnnouncementModal, setOpenAnnouncementModal] = useState(false);
  const [announcementForUpdate, setAnnouncementForUpdate] = useState<StatusMessageDataModel | undefined>(undefined);
  const [resolveId, setResolveId] = useState<string | undefined | null>(undefined);

  useEffect(() => {
    if (!loading) {
      return;
    }

    if (announcementState.lastOperation !== 'pending') {
      setResolveId(undefined);
      setLoading(false);
    }
  }, [announcementState]);

  const resolveAnnouncement = (announcement: StatusMessageDataModel) => {
    setLoading(true);
    setResolveId(announcement.id);
    dispatch(UpdateAnnouncement({ ...announcement, isResolved: true }));
  };

  const timeFormatOptions = {
    timeFormat: preferencesState.data.timeFormat,
    timeUtc: preferencesState.data.timeUtc,
    highlight: false,
    highlightColor: preferencesState.data.tableHighlightColor,
  };

  const genAnnouncements = () => (!announcementState.data.length
    ? (
      <Table.Row className="empty-announc">
        <Table.Cell colSpan="6">No Current Announcements</Table.Cell>
      </Table.Row>
    )
    : announcementState.data.map((a) => (
      <Table.Row key={a.id}>
        <Table.Cell>{a.title}</Table.Cell>
        <Table.Cell>{a.message}</Table.Cell>
        <Table.Cell><TimeFormat time={a.createdAt} {...timeFormatOptions} /></Table.Cell>
        <Table.Cell textAlign="center"><Icon size="large" color={colorSeverities[a.messageType ?? 0]} name="alarm" /></Table.Cell>
        <Table.Cell textAlign="center">{a.isResolved ? 'Yes' : 'No'}</Table.Cell>
        <Table.Cell>
          <Button
            onClick={() => { setAnnouncementForUpdate(a); setOpenAnnouncementModal(true); }}
            floated="right"
            className="announc-action-btn"
            secondary
            compact
            size="small"
          >
            <Icon name="redo" />
            Update
          </Button>
          <Button
            onClick={() => resolveAnnouncement(a)}
            loading={resolveId === a.id}
            className="announc-action-btn"
            primary
            compact
            size="small"
          >
            <Icon name="check" />
            Resolve
          </Button>
        </Table.Cell>
      </Table.Row>
    )));

  const setAnnouncementClosed = () => {
    setAnnouncementForUpdate(undefined); // Remove any announcement from update
    setOpenAnnouncementModal(false); // Close modal
  };

  return (
    <Container fluid className="Announcements-Management">
      <div className="announc-table">
        <Table celled striped className="announc-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="announc-title" colSpan="6">Announcements Management</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Severity</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Is Resolved</Table.HeaderCell>
              <Table.HeaderCell width={4}>Action(s)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {genAnnouncements()}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="8">
                <Button
                  onClick={() => setOpenAnnouncementModal(true)}
                  floated="right"
                  icon
                  labelPosition="left"
                  primary
                  size="small"
                >
                  <Icon name="plus" />
                  {' '}
                  Create Announcement
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
      <StatusModal
        size="small"
        style="success"
        header={announcementForUpdate ? 'Update Announcement' : 'Create New Announcement'}
        message={<CreateAnnouncement announcementForUpdate={announcementForUpdate} close={setAnnouncementClosed} />}
        open={openAnnouncementModal}
        icon={announcementForUpdate ? 'redo' : 'plus'}
        closeEvent={() => setOpenAnnouncementModal(false)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        disableActions
      />
    </Container>
  );
};

export default AnnouncementManagement;
