import {
  activateTab, addTab, removeTab, updateTab,
} from 'app/actions/tabActions';
import SingleLicense from 'app/components/Company/SingleLicense';
import License from 'app/components/License/License';
import Search, { SearchRequestProps } from 'app/components/Search/Search';
import TabManager from 'app/components/shared/TabManager/TabManager';
import ZeroStateTab from 'app/components/shared/ZeroStateTab/ZeroStateTab';
import System from 'app/components/System/System';
import { assertTrue, generateId, getErrormessage } from 'app/helpers/helpers';
import { ResponseOfLicenseDetail } from 'app/helpers/types';
import { State } from 'app/reducers/state';
import {
  GenericAction, GenericDictionaryParams, TabGeneratorEffect, TabOperation, TabType,
} from 'app/typings';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { Segment } from 'semantic-ui-react';
import {
  all, call, delay, put, select,
} from 'typed-redux-saga';

import type { App } from 'app/store';
import { logDebug } from 'app/constants/constants';
import { Dispatch } from 'redux';
import { DSTableExtraParams, RefreshDSTableDelegate } from 'app/components/shared/DSTable/DSTableTypes';
import SingleObject, { SingleObjectProps } from 'app/components/Company/SingleObject';
import { getSingleObject } from 'app/actions/companyActions';
import { getSingleObject as getSingleObjectSystem } from 'app/actions/systemActions';
import { AllHelper } from './typeHelpers';

const getTabState = (state: State) => state.tabs;
const getLocation = (state: State) => state.router?.location;
const getPrefState = (state: State) => state.preferences;

function* CreateLoadingTab(
  title: string,
  key: string = generateId(),
  tabType: TabType,
  tabDelay = 1000,
  makeActive = true,
  noop = false,
  parentTab?: string,
) {
  if (noop === true) {
    return undefined;
  }

  const currentLocation = yield* select(getLocation);
  assertTrue(currentLocation !== undefined);
  const newTabDispatched = addTab(
    title,
    tabType,
    React.createElement(ZeroStateTab, { key, tabId: key }),
    currentLocation.pathname,
    false, // fromHistory
    undefined, // id
    0,
    parentTab,
  );
  yield* put(newTabDispatched);

  if (makeActive) {
    yield* put(activateTab(key, tabType, parentTab));
  }

  yield* delay(tabDelay);

  return newTabDispatched;
}

export function* UpdateTabComponent(
  type: string,
  id: string,
  tabType: TabType,
  displayName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resp: any,
  action: string,
  refreshTab: boolean,
  params?: { params: GenericDictionaryParams } & GenericDictionaryParams,
  parentTab?: string,
  refreshDelegate?: RefreshDSTableDelegate,
  DSTableParams?: DSTableExtraParams,
) {
  const tabState = yield* select(getTabState);
  const prefState = yield* select(getPrefState);
  const currentLocation = yield* select(getLocation);
  assertTrue(tabState !== undefined);
  assertTrue(prefState !== undefined);
  assertTrue(currentLocation !== undefined);
  const foundTab = tabState.getTab(tabType, id, parentTab) || tabState.getPinnedTab(id);
  let componentType = type;
  let title = displayName;

  if (_.isNil(foundTab)) {
    return undefined;
  }

  if (refreshTab && foundTab) {
    foundTab.refreshed += 1; // Used to redraw the updated/refreshed data
  }

  // If only 1 search result returned and users preferences are set to auto open single result:
  if (Array.isArray(resp.data) && resp.data.length === 1 && componentType === 'Search' && prefState.data.openSingleSearchResult) {
    // Set the tab title
    title = resp.data[0].displayName;

    if (currentLocation.pathname === '/company') {
      yield* put(getSingleObject({
        objectId: resp.data[0].objectId,
        contextId: resp.data[0].contextId,
      }, parentTab));
    }
    if (currentLocation.pathname === '/system') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield* put(getSingleObjectSystem(resp.data[0].objectId, 'Single Object') as any);
    }

    // removes the 1st loading tab that will never be updated
    yield* put(removeTab(id, tabType, parentTab));
    // need to return here or it will continue to draw single (incomplete) object from search
    return undefined;
  }

  // If Search returns only 1 result (an object and not an array) switch type to single object to display properly
  if (componentType === 'Search' && !Array.isArray(resp.data)) {
    switch (params?.searchType) {
      case 'Licenses':
        componentType = 'License';
        break;
      case 'Provisioning Status':
        componentType = 'Search';
        break;
      default:
        componentType = 'SingleObject';
        break;
    }
  }

  let newTabChild;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { params: { data: { contextId }, metadata: { sidebarObjectType = undefined } = {} } } = params as any;

  switch (componentType) {
    case 'Company':
      newTabChild = React.createElement(TabManager, {
        tabType: TabType.Company,
        tabId: id,
      });
      break;
    case 'Index':
      newTabChild = React.createElement('div', { className: 'secondary-tabs-border' }, React.createElement(SingleObject, {
        id,
        dataResult: resp,
        isPropBag: false,
        refreshDelegate,
        DSTableParams,
      } as SingleObjectProps));
      break;
    case 'SingleObject': {
      if (!parentTab) {
        newTabChild = React.createElement('div', { className: 'secondary-tabs-border' }, React.createElement(SingleObject, {
          id,
          dataResult: resp,
          isPropBag: false,
          refreshDelegate,
          DSTableParams,
        } as SingleObjectProps));
      } else {
        newTabChild = React.createElement(SingleObject, {
          id,
          dataResult: resp,
          isPropBag: false,
          refreshDelegate,
          DSTableParams,
        } as SingleObjectProps);
      }
      break;
    }
    case 'Partition': {
      newTabChild = React.createElement('div', { className: 'secondary-tabs-border' }, React.createElement(Search, {
        id,
        dataResult: { searchData: resp },
        sidebarObjectType,
        tabType: foundTab.metadata.tabType,
        refreshDelegate,
        DSTableParams,
      } as SearchRequestProps));
      break;
    }
    case 'Search':
      if (!parentTab) {
        // Search without Parent
        newTabChild = React.createElement('div', { className: 'secondary-tabs-border' }, React.createElement(Search, {
          id,
          dataResult: { searchData: resp },
          sidebarObjectType,
          tabType: foundTab.metadata.tabType,
          refreshDelegate,
          DSTableParams,
        } as SearchRequestProps));
      } else {
        newTabChild = React.createElement(Search, {
          id,
          dataResult: { searchData: resp },
          sidebarObjectType,
          tabType: foundTab.metadata.tabType,
          refreshDelegate,
          DSTableParams,
          contextId: contextId || resp.contextId,
        } as SearchRequestProps);
      }
      break;
    case 'System':
      if (!parentTab) {
        // Search without Parent
        newTabChild = React.createElement('div', { className: 'secondary-tabs-border' }, React.createElement(System, {
          params: { id, ...params },
          searchData: resp,
          refreshDelegate,
          DSTableParams,
        } as GenericDictionaryParams));
      } else {
        newTabChild = React.createElement(System, {
          params: { id, ...params },
          searchData: resp,
          refreshDelegate,
          DSTableParams,
        });
      }
      break;
    case 'Key':
      newTabChild = React.createElement(TabManager, {
        tabType: TabType.Key,
        tabId: id,
      });
      break;
    case 'License':
      newTabChild = React.createElement(License, {
        params: { id, ...params },
        searchData: resp,
      } as GenericDictionaryParams);
      break;
    case 'SingleLicense': {
      const responseData = (resp as ResponseOfLicenseDetail).data;
      assertTrue(responseData !== undefined && responseData !== null);
      newTabChild = parentTab
        ? React.createElement(SingleLicense, {
          dataResult: responseData,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        : React.createElement(ZeroStateTab, (resp as Record<string, any>));
      break;
    }
    default:
      newTabChild = React.createElement(Segment, {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content: (resp as GenericDictionaryParams).message as any,
      });
      break;
  }

  yield* put(updateTab(foundTab, title, newTabChild, action, { ...params?.params, resp }, parentTab));
}

function* UpdateFailedTab(id: string, tabType: TabType, message: string, parentTab?: string) {
  const tabState = yield* select(getTabState);
  assertTrue(tabState !== undefined);
  const foundTab = _.find(tabState.getTabs(tabType, parentTab), ['id', id]);

  if (_.isNil(foundTab)) {
    return undefined;
  }

  yield* put(updateTab(foundTab, 'Error', React.createElement(ZeroStateTab, {
    key: id,
    tabId: id,
    parentTab,
    statusModal: {
      error: true,
      message,
      closeEvent: () => {
        logDebug('Closing Failed Tab');
      },
    },
    tabType,
  }), 'ZERO_STATE_TAB', undefined, parentTab));
}

export function* createTabWithComponent(
  title: string,
  key: string = generateId(),
  tabType: TabType,
  component: ReactElement,
  makeActive = true,
  parentTab?: string,
  additionalMetadata?: GenericDictionaryParams,
  allowCloseOverride?: boolean,
) {
  const currentLocation = yield* select(getLocation);
  assertTrue(currentLocation !== undefined);
  const newTabDispatched = addTab(
    title,
    tabType,
    component,
    currentLocation.pathname,
    false, // fromHistory
    key, // id
    0,
    parentTab,
    tabType !== TabType.Company || allowCloseOverride, // allow Close
    additionalMetadata,
  );

  yield* put(newTabDispatched);

  if (makeActive) {
    yield* put(activateTab(key, tabType, parentTab));
  }
}

type TTabOpParams<TTabOp extends TabOperation<unknown, unknown>> = TTabOp extends TabOperation<infer serviceParams, unknown> ? serviceParams : never;
type TTabOpReturn<TTabOp extends TabOperation<unknown, unknown>> = TTabOp extends TabOperation<unknown, infer dataReturned> ? dataReturned : never;
export function* createTabWithAsyncOperation<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TTabOperation extends TabOperation<any, any>,
  TServiceParam extends TTabOpParams<TTabOperation>,
  TDataReturned extends TTabOpReturn<TTabOperation>>(app: App, params: TabOperation<TServiceParam, TDataReturned>): TabGeneratorEffect<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const p: any = params.params;
  const tabId = p.tabId || generateId();
  const { refreshTab = false } = params;
  const { DSTableParams: tableParams = {} } = p;

  let resp: TDataReturned | undefined;

  try {
    const refreshParams = _.omit(params.params, ['tabId', 'refreshTab', 'DSTableRefreshParams']);
    const paramData = { params: refreshParams, ...params.extra };

    // eslint-disable-next-line
    function refreshCallback(dispatch: Dispatch<any>, DSTableParams?: DSTableExtraParams) {
      dispatch({
        ...params.params, tabId, refreshTab: true, DSTableParams,
      });
    }

    if (refreshTab) {
      resp = yield*
      call(
        params.asyncDataFetch,
        app,
        params.paramsAsAction
          ? <TServiceParam>params.params
          : (<GenericAction<TServiceParam>>params.params).data,
      );
    } else {
      const [, dataResp] = (yield*
      (all as unknown as AllHelper<typeof CreateLoadingTab, typeof params.asyncDataFetch>)([
        call(CreateLoadingTab, 'Loading', tabId, params.tabRoutingArea, 0, true, params.skipTabCreation || false, params.parentTab),
        call(
          params.asyncDataFetch,
          app,
          params.paramsAsAction
            ? <TServiceParam>params.params
            : (<GenericAction<TServiceParam>>params.params).data,
        ),
      ]));

      resp = dataResp;
    }

    if (p.metadata && p.metadata.sidebarObjectType === 'Objects') {
      tableParams.payload = p.data; // need to pass in payload to table for Objects search (paging) POST calls
      if (resp && resp.nextPage) {
        const str = JSON.parse(JSON.stringify(resp.nextPage));
        const pageToken = str.substring( // Need to rip the pageToken out of resp.nextPage
          str.indexOf('pageToken=') + 'pageToken='.length,
          str.lastIndexOf('&replicaToken='),
        );
        const replicaToken = str.substring( // Need to rip the replicaToken out of resp.nextPage
          str.indexOf('replicaToken=') + 'replicaToken='.length,
          str.lastIndexOf('&replica='),
        );
        tableParams.payload.pageToken = decodeURIComponent(pageToken);
        tableParams.payload.replicaToken = decodeURIComponent(replicaToken);
      }
    }

    if (!params.skipTabCreation) {
      yield* call(
        UpdateTabComponent,
        params.tabType,
        tabId,
        params.tabRoutingArea,
        params.getDisplayName(resp as TDataReturned) || '',
        resp,
        params.actionBegin,
        refreshTab,
        paramData,
        params.parentTab,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (dispatch: Dispatch<any>, sortColumn?: string, sortOrder?: 'asc' | 'desc' | null | undefined) => {
          refreshCallback(dispatch, { sortColumn, sortOrder });
        },
        tableParams,
      );
    }

    if (typeof params.callback === 'function') {
      params.callback(resp as TDataReturned);
    }

    yield* put({
      type: params.actionComplete, tabId, resp, params: { refreshTab, ...paramData.params },
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (ex: any) {
    if (typeof params.errorCallback === 'function') {
      params.errorCallback(getErrormessage(ex));
    } else {
      yield* call(UpdateFailedTab, tabId, params.tabRoutingArea, getErrormessage(ex), params.parentTab);
      yield* put({ type: params.actionFailed, ex });
    }
  }
}
