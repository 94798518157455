import { logImageConsole, parseXmlToJson } from 'app/helpers/helpers';
import moment from 'moment';
import logo from 'app/assets/DsExplorer_Logo_Retro.png';
import _ from 'lodash';

export const configFile = 'AppConfig.xml';
export enum ConfigurationKey {
  REACT_APP_SEND_AUTH_HEADER = 'REACT_APP_SEND_AUTH_HEADER',
  REACT_APP_PROXY_BACKEND = 'REACT_APP_PROXY_BACKEND',
  REACT_APP_MOCK_BACKEND = 'REACT_APP_MOCK_BACKEND',
  REACT_APP_SEND_CREDENTIALS = 'REACT_APP_SEND_CREDENTIALS',
  REACT_APP_BYPASS_AUTH = 'REACT_APP_BYPASS_AUTH',
  REACT_APP_DISABLE_CLAIM_WARNING = 'REACT_APP_DISABLE_CLAIM_WARNING',
  REACT_APP_DISABLE_PREFERENCES = 'REACT_APP_DISABLE_PREFERENCES',
  REACT_APP_DISABLE_LINK_SHARE = 'REACT_APP_DISABLE_LINK_SHARE',
  REACT_APP_SAME_HOST = 'REACT_APP_SAME_HOST',
  REACT_APP_DISABLE_ANNOUNCEMENTS = 'REACT_APP_DISABLE_ANNOUNCEMENTS',
  MAX_HISTORY_ITEMS = 'MAX_HISTORY_ITEMS',
  ENV_PEERS = 'ENV_PEERS',
  REQUIRED_CLAIMS = 'REQUIRED_CLAIMS',
}

let fetchedConfig: Record<string, unknown> = {};

export function fetchApplicationConfiguration() {
  return fetch(`${window.location.protocol}//${window.location.host}/${configFile}`)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response;
    })
    .then((response) => response.text())
    .then((data) => {
      fetchedConfig = parseXmlToJson(data);

      return fetchedConfig;
    });
}

function getConfigValue<T>(key: ConfigurationKey, defaultValue?: T) {
  if (key in fetchedConfig) {
    return fetchedConfig[`${key}`];
  }

  return defaultValue;
}

export function useSameHost() {
  return getConfigValue(ConfigurationKey.REACT_APP_SAME_HOST, 'false') === 'true';
}

export function getOriginHost() {
  return `${window.location.protocol}//${window.location.host}`;
}

export function getServer(): string {
  return useSameHost() ? getOriginHost() : getConfigValue(ConfigurationKey.REACT_APP_PROXY_BACKEND, getOriginHost()) as string;
}

export function shouldSendCredentials() {
  return getConfigValue(ConfigurationKey.REACT_APP_SEND_CREDENTIALS, 'true') === 'true';
}

export function shouldSendAuthHeader() {
  return getConfigValue(ConfigurationKey.REACT_APP_SEND_AUTH_HEADER, 'false') === 'true';
}

export function shouldDisableAnnouncements() {
  return getConfigValue(ConfigurationKey.REACT_APP_DISABLE_ANNOUNCEMENTS, 'false') === 'true';
}

export function shouldBypassAuth() {
  return getConfigValue(ConfigurationKey.REACT_APP_BYPASS_AUTH, 'false') === 'true';
}

export function disableClaimsWarning() {
  return getConfigValue(ConfigurationKey.REACT_APP_DISABLE_CLAIM_WARNING, 'false') === 'true';
}

export function shouldDisableLinkShare() {
  return getConfigValue(ConfigurationKey.REACT_APP_DISABLE_LINK_SHARE, 'false') === 'true';
}

export function shouldDisablePreferences() {
  return getConfigValue(ConfigurationKey.REACT_APP_DISABLE_PREFERENCES, 'false') === 'true';
}

export function getPowerShellModuleUrl() {
  return `${window.location.protocol}//${window.location.host}/DSExplorer.PowerShell.zip`;
}

export function allowedMaxHistoryItems() {
  const maxItems = getConfigValue(ConfigurationKey.MAX_HISTORY_ITEMS);
  if (maxItems) {
    return parseInt(maxItems as string, 10);
  }

  return 5;
}

export function getBuildDate() {
  const buildDate = process.env.REACT_APP_BUILD_DATE as string;
  if (buildDate) {
    return moment(buildDate, 'MM/DD/YYYY HH:mm:ss').format('LLL');
  }

  return 'N/A';
}

export function getAppVersion() {
  return process.env.REACT_APP_APP_VERSION as string;
}

export function getEnvPeers() {
  return getConfigValue(ConfigurationKey.ENV_PEERS, ';') as string;
}

export function getRequiredClaims(): string[] {
  const raw = getConfigValue(ConfigurationKey.REQUIRED_CLAIMS, ';') as string;
  return _.split(raw, ';') as string[];
}

export function getRunningConfig() {
  return {
    'App Version': getAppVersion(),
    'Build Date': getBuildDate(),
    Server: getServer(),
    'Allowed Max History Items': allowedMaxHistoryItems(),
    'Origin Host': getOriginHost(),
    'Use Same Host': useSameHost(),
    'Send Credentials': shouldSendCredentials(),
    'Send Auth Header': shouldSendAuthHeader(),
    Announcements: !shouldDisableAnnouncements(),
    'Link Sharing': !shouldDisableLinkShare(),
    Preferences: !shouldDisablePreferences(),
    'Valid Claims': getRequiredClaims(),
  };
}

export function logApplicationConfiguration(withImage = false) {
  const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
  const textColor = darkThemeMq.matches ? '#ced5d8' : 'black';

  function logConfig() {
    // eslint-disable-next-line no-console
    console.log('%c==== Application Configuration ====', `text-decoration: underline; color: ${textColor}; font-weight: bold; font-size: large;`);

    Object.entries(getRunningConfig()).forEach((k) => {
      const value = `${k[0]}: ${k[1]}`;

      // eslint-disable-next-line no-console
      console.log(`%c -> ${value}`, `color: ${textColor}; font-weight: bold; font-size: medium; padding-left: 5px;`);
    }, [
      'border-bottom: 2px solid steelblue;',
    ]);
  }

  if (withImage) {
    logImageConsole({ logo }.logo, 50, () => {
      logConfig();
    });
  } else {
    // eslint-disable-next-line no-console
    console.log('%cWelcome to DSExplorer!', `color: ${textColor}; font-weight: bold; font-size: x-large;`);
    logConfig();
  }
}
