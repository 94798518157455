/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = _ObjectClass 1 = _ObjectId 2 = _Deleted 3 = _InitialDomainName 4 = _WhenCreated 5 = _WhenChanged 6 = _USNOriginating 7 = _USNLocal 8 = _SegmentationMetadata 9 = _CloudSid 10 = _ObjectQuotaUsed 11 = _SingleAuthorityMetadata 12 = _SourceObjectClass 13 = _SourceObjectId 14 = _TargetObjectClass 15 = _TargetObjectId 16 = _TargetDeleted 17 = _ReferenceClass 18 = _ExchangeDualWritesTracker 19 = _SecuredSecretsTracker
*/
export enum MicrosoftOnlineDSExplorerContractMetadataProperty {
    _ObjectClass = 0,
    _ObjectId = 1,
    _Deleted = 2,
    _InitialDomainName = 3,
    _WhenCreated = 4,
    _WhenChanged = 5,
    _USNOriginating = 6,
    _USNLocal = 7,
    _SegmentationMetadata = 8,
    _CloudSid = 9,
    _ObjectQuotaUsed = 10,
    _SingleAuthorityMetadata = 11,
    _SourceObjectClass = 12,
    _SourceObjectId = 13,
    _TargetObjectClass = 14,
    _TargetObjectId = 15,
    _TargetDeleted = 16,
    _ReferenceClass = 17,
    _ExchangeDualWritesTracker = 18,
    _SecuredSecretsTracker = 19
}
