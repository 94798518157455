/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 1 = LiveId 5 = OrgId 6 = Custom
*/
export enum MicrosoftOnlineDSExplorerContractAltSecIdType {
    LiveId = 1,
    OrgId = 5,
    Custom = 6
}
