import './InputSearch.scss';

import { findHighLight } from 'app/helpers/helpers';
import _, { isArray, isNumber, isString } from 'lodash';
import debounce from 'lodash.debounce';
import React, {
  FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { Icon, Input, Popup } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import TimeFormat from '../Utilities/TimeFormat';
/* eslint-disable */
interface SearchInputProps {
  input: any,
  output: any,
  column?: string | undefined,
  direction?: "asc" | "desc" | undefined,
  inputSearch?: string,
  disableTooltip?: boolean
  searchValueEmitter?: (searchVal: string) => void,
}

const InputSearch: FunctionComponent<SearchInputProps> = ({
  input, output, column, direction, searchValueEmitter, inputSearch, disableTooltip
}: SearchInputProps) => {

  const { data: {
    timeFormat,
    timeUtc,
    tableHighlight: highlightTable,
    tableHighlightColor: tableHighlightCol } } = useSelector((state: State) => state.preferences);
  const [originalData, setOriginalData] = useState(input);
  const [searchString, setSearchString] = useState('');
  const [length, setLength] = useState(input.length)

  const timeFormatOptions = useMemo(() => {
    return {
      timeFormat: timeFormat,
      timeUtc: timeUtc,
      highlight: highlightTable,
      highlightColor: tableHighlightCol,
    };
  }, [timeFormat, timeUtc, highlightTable, tableHighlightCol]);

  useEffect(() => { // When new data gets passed into InputSearch -> set data for redraw
    if (inputSearch === '') {
      setOriginalData(input)
    }

    setLength(input.length)

  }, [input])

  useEffect(() => {
    if (inputSearch) {
      setSearchString(inputSearch)
      filterData(inputSearch, highlightTable);
    }
  }, [inputSearch])

  const reset = () => {
    if (searchValueEmitter) searchValueEmitter('')
    setSearchString('');
    output(input, input);
    setLength(input.length);
  }

  const filterData = (searchValue: string, highLight: boolean) => {
    if (!originalData.length) return;
    if ( searchValue === '') return reset();
    if (searchValueEmitter) searchValueEmitter(searchValue);
    
    let list: any = [];
    // FIND ANY ROWS WITH MATCHING DATA
    originalData.forEach((rowObj: any) => { // Loop Thru each row
      _.forOwn(rowObj, (value) => { // Loop Thru each key:value for row object
        if (isString(value)) { // If value is typeof String -> Compare
          if (value.toLowerCase().includes(searchValue.toLowerCase())) {
            if (list.indexOf(rowObj) === -1) { // Check if Object was already added
              list.push(rowObj);
            }
          }
        }
        if (isArray(value)) { // If value is Typeof Array -> Loop thru Array (Strings) -> Compare
          value.forEach((item) => {
            if (isString(item) && item.toLowerCase().includes(searchValue.toLowerCase())) {
              if (list.indexOf(rowObj) === -1) { // Check if Object was already added
                list.push(rowObj);
              }
            }
          });
        }
        if (isNumber(value)) { // If value is Number -> Switch to string and compare
          if (value.toString().includes(searchValue.toLowerCase())) {
            if (list.indexOf(rowObj) === -1) { // Check if Object was already added
              list.push(rowObj);
            }
          }
        }
        if (typeof value === 'object' && value?.type === TimeFormat) {
          const searchDate = new Date(searchValue).setSeconds(0, 0)
          const rowDate = new Date(value.props.time).setSeconds(0, 0)
          if (searchDate === rowDate) {
            if (list.indexOf(rowObj) === -1) { // Check if Object was already added
              list.push(rowObj);
            }
          }
        }
      });
    });

    if (column && direction) {
      list = _.orderBy(list, [column], direction);
    }

    if (highLight) {
      const highLightedList: any = [];

      // HIGHLIGHT MATCHING TEXT
      list.forEach((row: any) => { // Loop thru all rows (w/ matches) and pass value into highlighter func
        const rowCopy = { ...row };
        _.forOwn(rowCopy, (value, key) => {
          if (isString(value)) {
            rowCopy[key] = findHighLight(value, searchValue, tableHighlightCol);
          }
          if (isArray(value)) {
            const valueCopy = [...value];
            value.forEach((item: any, index: number) => {
              if (isString(item)) {
                valueCopy[index] = findHighLight(item, searchValue, tableHighlightCol);
              }
            });
            rowCopy[key] = valueCopy;
          }
          if (isNumber(value)) {
            rowCopy[key] = findHighLight(value.toString(), searchValue, tableHighlightCol);
          }
          if (typeof value === 'object' && value?.type === TimeFormat) {
            const searchDate = new Date(searchValue).setSeconds(0, 0)
            const rowDate = new Date(value.props.time).setSeconds(0, 0)
            if (searchDate === rowDate) {
              rowCopy[key] = <TimeFormat time={value.props.time} {...timeFormatOptions} />
            }
          }
        });
        highLightedList.push(rowCopy);
      });

      output(highLightedList, list); // Send highlighted rows and non-highlighted to parent component
    } else {
      output(list, list);
    }
    setLength(list.length)
  };

  useEffect(() => {
    if ((column && direction) || tableHighlightCol || highlightTable) {
      filterData(searchString, highlightTable);
    }
  }, [column, direction, tableHighlightCol, highlightTable]);

  const changeHandler = (value: string, highlight: boolean) => filterData(value, highlight);
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [column, direction, originalData, tableHighlightCol, highlightTable]); // Debounce for seach performance
  // Stop the invocation of the debounced function after unmounting (Performance)
  useEffect(() => () => {
    debouncedChangeHandler.cancel();
  }, []);

  const closeBtn = (
    <Icon className='input-close-btn' name="close" link onClick={() => { reset() }} />
  );

  const rowLengthDisplay = (
    <div className='ui label'>{length.toString() + ' Row(s)'}</div>
  )

  const label = (
    disableTooltip ? rowLengthDisplay :
      <Popup
        flowing
        position="left center"
        content="Current displayed rows"
        trigger={(rowLengthDisplay)}
      />
  )

  const inputDisplay = (
    <Input
      id="table-search-tour"
      label={label}
      size="small"
      className="Input-Search"
      value={searchString}
      icon={searchString === '' ? 'search' : closeBtn}
      placeholder="Search..."
      onChange={(e, { value }) => {
        setSearchString(value);
        debouncedChangeHandler(value, highlightTable);
      }}
    />
  )

  return (
    disableTooltip ? inputDisplay :
      <Popup
        flowing
        offset={[90]}
        position="bottom left"
        content="UI search only. Matching values may be hidden/nested within rows"
        trigger={(inputDisplay)}
      />
  );
};

InputSearch.defaultProps = {
  column: undefined,
  direction: undefined,
};

/* eslint-enable */

export default InputSearch;
