import './ObjectLinksModal.scss';

import { downloadCompLinks, getSingleObject } from 'app/actions/companyActions';
import { generateId } from 'app/helpers/helpers';
import { DSObject, DSObjectMetadataProperty } from 'app/proxyClients';
import { ObjectLink } from 'app/reducers/objectLinks';
import { State } from 'app/reducers/state';
import { TabType } from 'app/typings';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  Button, Menu, Tab, Table,
} from 'semantic-ui-react';

import _ from 'lodash';
import ZeroState from '../ZeroState/ZeroState';
import InputSearch from '../InputSearch/InputSearch';

interface ObjectLinksProps {
  objectLinks: ObjectLink,
  companyId: string
}

interface LinkSearch {
  highlighted: DSObject[] | [],
  prestine: DSObject[] | []
}

function getMetadata(property: string, metadata?: DSObjectMetadataProperty[]) {
  if (!metadata) {
    return undefined;
  }

  const found = _.find(metadata, { displayName: property }) as DSObjectMetadataProperty | undefined;
  if (found !== undefined && Array.isArray(found.values) && found.values.length > 0) {
    return found.values[0];
  }

  return undefined;
}

const ObjectLinksModal: FunctionComponent<ObjectLinksProps> = ({
  objectLinks,
  companyId,
}: ObjectLinksProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const activeTabState = useSelector((state: State) => state.activeTabs);

  const [sourceLinks, setSourceLinks] = useState<LinkSearch>({ highlighted: [], prestine: [] });
  const [targetLinks, setTargetLinks] = useState<LinkSearch>({ highlighted: [], prestine: [] });

  useEffect(() => {
    if (objectLinks) {
      if (objectLinks?.sourceLinks?.data?.length) {
        setSourceLinks({ highlighted: objectLinks?.sourceLinks?.data, prestine: objectLinks?.sourceLinks?.data });
      }
      if (objectLinks?.targetLinks?.data?.length) {
        setTargetLinks({ highlighted: objectLinks?.targetLinks?.data, prestine: objectLinks?.targetLinks?.data });
      }
    }
  }, [objectLinks]);

  const openRowClick = (type: string, i: number) => {
    const link = type === 'source' ? sourceLinks.prestine[+`${i}`] : targetLinks.prestine[+`${i}`];
    if (location.pathname === '/company') {
      dispatch(getSingleObject({
        objectId: link.objectId,
        contextId: link.contextId,
      }, activeTabState?.getActiveTab(TabType.Company)));
    }
  };

  const generateTable = (data: DSObject[], type: string) => {
    const displayData = type === 'source' ? sourceLinks.highlighted : targetLinks.highlighted;
    return (
      <>
        <div className="tableActionContainer">
          <div className="Search-Container">
            <InputSearch
              input={data}
              output={(highlighted: DSObject[], prestine: DSObject[]) => (type === 'source'
                ? setSourceLinks({ highlighted, prestine })
                : setTargetLinks({ highlighted, prestine }))}
            />
          </div>
          {
            data.length && location.pathname === '/company' ? (
              <div className="tableActions">
                <Button
                  icon="download"
                  className="downloadLinksBtn"
                  primary
                  color="green"
                  onClick={() => dispatch(downloadCompLinks(
                    { companyIdentifier: companyId, objectId: objectLinks.objectId, directoryLinkType: type === 'source' ? 0 : 1 },
                  ))}
                  content={`Download ${type === 'source' ? 'Source' : 'Target'} Link(s)`}
                />
              </div>
            ) : null
          }

        </div>
        <Table celled compact selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Display Name</Table.HeaderCell>
              <Table.HeaderCell>Link</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Object ID</Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">View Object</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              displayData.map((link, i) => {
                const objectClass = getMetadata('_ObjectClass', link.metadataProperties);

                return (
                  <Table.Row key={generateId()}>
                    <Table.Cell>{link.displayName}</Table.Cell>
                    <Table.Cell>
                      {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (link as any).linkClass
                      }
                    </Table.Cell>
                    <Table.Cell>{ objectClass ?? 'N/A'}</Table.Cell>
                    <Table.Cell>{link.objectId}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        onClick={() => openRowClick(type, i)}
                        className="openObjectBtn"
                        compact
                        primary
                        icon="share square"
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </>
    );
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item key="Source" className="tab primary-tab-menu-item">
          Source Links (Referencing)
        </Menu.Item>
      ),
      pane: {
        key: 'slinks',
        content: objectLinks && objectLinks?.sourceLinks?.data?.length
          ? generateTable(objectLinks?.sourceLinks?.data, 'source')
          : <ZeroState text="No Source Links Found" />,
      },
    },
    {
      menuItem: (
        <Menu.Item key="Target" className="tab primary-tab-menu-item">
          Target Links (Referenced By)
        </Menu.Item>
      ),
      pane: {
        key: 'tlinks',
        content: objectLinks && objectLinks?.targetLinks?.data?.length
          ? generateTable(objectLinks?.targetLinks?.data, 'target')
          : <ZeroState text="No Target Links Found" />,
      },
    },
  ];

  return <Tab className="object-links-tabs" defaultActiveIndex={sourceLinks.prestine.length ? 0 : 1} renderActiveOnly={false} panes={panes} />;
};

export default ObjectLinksModal;
