/* eslint-disable react/no-array-index-key */
import './LinkStateShare.scss';

import { clearTabLinkState, createTabLinkState } from 'app/actions/tabActions';
import { State } from 'app/reducers/state';
import { TabState } from 'app/reducers/tabs';
import { TabDetails, TabMetadata } from 'app/typings';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion, Button, Checkbox, CheckboxProps, Divider, List, Popup,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';

interface LinkStateProps {
  setOnLinkGenerateCallback(data: TabMetadata[]): void
}

const LinkStateShare: FunctionComponent<LinkStateProps> = ({ setOnLinkGenerateCallback }) => {
  const tabState = useSelector((state:State) => state.tabs) as TabState;
  const allTabs = tabState.allTabs(true) as TabDetails[];
  const linkState = useSelector((state: State) => state.tabs?.linkState);

  const [selected, setSelected] = useState<string[]>(allTabs.map((t) => t.id as string));
  const dispatch = useDispatch();

  function getSelectedTabMetadata(tabSelection:string[] = selected) {
    const selectedParentTabs = _.intersectionWith(tabState.allTabs(), tabSelection, (tab, id) => tab.id === id);

    return _.flatMap(selectedParentTabs, (t) => {
      const selectedChildTabs = _.intersectionWith(t.children.tabs, tabSelection, (tab, id) => tab.id === id);
      const children = _.without(_.flatMap(selectedChildTabs, (childTab:TabDetails) => {
        if (childTab.metadata.action) {
          return childTab.metadata;
        }

        return undefined;
      }), undefined);

      return { ...t.metadata, children };
    }) as TabMetadata[];
  }

  useEffect(() => {
    // Anything in here is fired on component mount.
    dispatch(createTabLinkState(getSelectedTabMetadata()));

    // eslint-disable-next-line react/prop-types
    setOnLinkGenerateCallback(getSelectedTabMetadata());

    return () => {
      // Anything in here is fired on component unmount.
      dispatch(clearTabLinkState());
    };
  }, []);

  function onCheckChange(data:CheckboxProps, tab:TabDetails) {
    let result;
    const children = [...tab.children.tabs.map((t) => t.id as string)];
    if (tab.id) {
      if (data.checked) {
        // Check for parent
        const potentialParent = _.find(allTabs, (parent) => {
          const childrenOfPotentialParent = _.flatMap(parent.children.tabs, (child) => child.id) as string[];
          return childrenOfPotentialParent.indexOf(tab.id as string) > -1;
        });

        result = [...selected, tab.id, ...children, potentialParent?.id || ''];
      } else {
        result = _.without(selected, tab.id, ...children);
      }
    }

    if (result) {
      setSelected(result);

      // eslint-disable-next-line react/prop-types
      setOnLinkGenerateCallback(getSelectedTabMetadata(result));
    }
  }

  function buildTabTree(tabs:TabDetails[]) {
    if (tabs.length === 0) {
      return undefined;
    }

    return tabs.map((t) => {
      if (!t.id || !t.metadata.action) {
        return undefined;
      }

      const isChecked = selected.indexOf(t.id) > -1;

      return (
        <List.Item key={t.id}>
          <List.Icon>
            <Checkbox onChange={(_e, data) => onCheckChange(data, t)} checked={isChecked} />
          </List.Icon>
          <List.Content verticalAlign="middle">
            <List.Header>{t.name}</List.Header>
            <List.List>{buildTabTree(t.children.tabs)}</List.List>
          </List.Content>
        </List.Item>
      );
    });
  }

  function generateHierarchy() {
    const parentTabs = tabState.allTabs();

    if (parentTabs) {
      return (
        <>
          <List>
            {buildTabTree(parentTabs)}
          </List>
        </>
      );
    }

    return undefined;
  }

  const tabSelectionFragment = (
    <>
      <Divider />
      Select Tabs to Share:
      <Button.Group size="tiny" floated="right">
        <Checkbox
          toggle
          label="Select All Tabs"
          checked={selected.length > 0}
          onChange={(e, { checked }) => (!checked ? setSelected([]) : setSelected(_.flatMap(allTabs, (t) => t.id) as string[]))}
        />
      </Button.Group>
      <Accordion styled className="tabs-to-share">
        <Accordion.Content active className="tabs-space">
          {generateHierarchy()}
        </Accordion.Content>
      </Accordion>
    </>
  );

  if (linkState) {
    const linkText = `${window.origin}/#linkState=${linkState.link}`;
    const completedMessage = (
      <>
        Use the link below to share your current view with others or customize the shared tabs by selecting or deselecting items from below.
        <br />
        <br />
        <Popup
          flowing
          position="right center"
          content="Click to copy short link"
          trigger={(
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => {
                navigator.clipboard.writeText(linkText);
                toast.success('Short link copied');
              }}
              onClick={() => {
                navigator.clipboard.writeText(linkText);
                toast.success('Short link copied');
              }}
              className="link-text"
            >
              {linkText}
            </div>
          )}
        />
      </>
    );
    const runningMessage = (
      <>
        Use the link below to share your current view with others or customize the shared tabs by selecting or deselecting items from below.
        <br />
        <br />
        Link is generating, please wait one moment...
      </>
    );
    const failedMessage = (
      <>
        Failed to generate link:
        <br />
        {linkState.error}
      </>
    );

    let message;
    switch (linkState.status) {
      case 'Requested':
        message = runningMessage;
        break;
      case 'Completed':
        message = completedMessage;
        break;
      case 'Failed':
        message = failedMessage;
        break;
      default:
        message = failedMessage;
        break;
    }

    return (
      <>
        {message}
        {tabSelectionFragment}
      </>
    );
  }

  return (
    <>
      Failed to generate link state.
    </>
  );
};

export default LinkStateShare;
