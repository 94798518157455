import * as tabTypes from 'app/actions/tabTypes';
import { ObjectTypeComponentKeys } from 'app/components/SidebarContent/CompanySidebar/CompanySidebarOptions';
import {
  call, SagaGenerator, takeEvery, put,
} from 'typed-redux-saga';
import type { App } from 'app/store';
import { companiesEndpointMap } from 'app/services/companies';
import { systemEndpointMap } from 'app/services/system';
import { createTabWithAsyncOperation } from 'app/sagas/tabSagas';
import { GenericDictionaryParams, TabGeneratorEffect, TabType } from 'app/typings';
import { indexStoreEndpointMap } from 'app/services/indexStore';
import { getSingleObject, GetSingleObjectParams } from 'app/actions/companyActions';

type SearchRequest = GenericDictionaryParams & {
  metadata: {
    sidebarObjectType: ObjectTypeComponentKeys,
  },
  parentTab?: string,
  data: GenericDictionaryParams
  refreshTab: boolean
};

function* executeCompanySearchAsync(app: App, params: SearchRequest): SagaGenerator<unknown> {
  const endpoint = companiesEndpointMap[params.metadata.sidebarObjectType];
  return yield* call(endpoint, app, params.data);
}

function* fetchCompanySearchTabDataAsync(app: App, params: SearchRequest): TabGeneratorEffect<void> {
  if (params.metadata.sidebarObjectType === 'Single Object') {
    yield* put(getSingleObject(params.data as GetSingleObjectParams, params.parentTab));
  } else {
    const { refreshTab = false, ...searchRequestParams } = params;
    yield* call(createTabWithAsyncOperation, app, {
      params: (JSON.parse(JSON.stringify(searchRequestParams))), // Need a deep copy, ...searchRequestParams was not copying nested array
      paramsAsAction: true,
      tabType: 'Search',
      parentTab: params.parentTab,
      tabRoutingArea: TabType.Company,
      actionBegin: tabTypes.SEARCH_TAB_REQUESTED,
      actionComplete: tabTypes.SEARCH_TAB_COMPLETED,
      actionFailed: tabTypes.SEARCH_TAB_FAILED,
      asyncDataFetch: executeCompanySearchAsync,
      getDisplayName: () => (params.metadata.sidebarObjectType === 'Objects' ? `${params.data.Category}s` : `${params.metadata.sidebarObjectType}`),
      refreshTab,
      extra: {
        searchType: params.metadata.sidebarObjectType,
      },
    });
  }
}

function* executeSystemSearchAsync(app: App, params: SearchRequest): SagaGenerator<unknown> {
  const endpoint = systemEndpointMap[params.metadata.sidebarObjectType];
  return yield* call(endpoint, app, params.data);
}

function* fetchSystemSearchTabDataAsync(app: App, params: SearchRequest): TabGeneratorEffect<void> {
  const { refreshTab = false, ...searchRequestParams } = params;
  yield* call(createTabWithAsyncOperation, app, {
    params: (JSON.parse(JSON.stringify(searchRequestParams))),
    paramsAsAction: true,
    tabType: 'Search',
    parentTab: params.parentTab,
    tabRoutingArea: TabType.System,
    actionBegin: tabTypes.SEARCH_SYSTEM_CONTEXT,
    actionComplete: tabTypes.SEARCH_SYSTEM_CONTEXT_COMPLETED,
    actionFailed: tabTypes.SEARCH_SYSTEM_CONTEXT_FAILED,
    asyncDataFetch: executeSystemSearchAsync,
    getDisplayName: () => (params.metadata.sidebarObjectType === 'Objects' ? `${params.data.Category}s` : `${params.metadata.sidebarObjectType}`),
    refreshTab,
    extra: {
      searchType: params.metadata.sidebarObjectType,
    },
  });
}

function* executeIndexSearchAsync(app: App, params: SearchRequest): SagaGenerator<unknown> {
  const endpoint = indexStoreEndpointMap[params.metadata.sidebarObjectType];
  return yield* call(endpoint, app, params.data);
}

function* fetchIndexStoreSearchTabDataAsync(app: App, params: SearchRequest): TabGeneratorEffect<void> {
  const { refreshTab = false, ...searchRequestParams } = params;
  yield* call(createTabWithAsyncOperation, app, {
    params: searchRequestParams,
    paramsAsAction: true,
    tabType: 'Index',
    parentTab: params.parentTab,
    tabRoutingArea: TabType.Index,
    actionBegin: tabTypes.SEARCH_INDEX_STORE,
    actionComplete: tabTypes.SEARCH_INDEX_STORE_COMPLETED,
    actionFailed: tabTypes.SEARCH_INDEX_STORE_FAILED,
    asyncDataFetch: executeIndexSearchAsync,
    getDisplayName: () => `${params.metadata.sidebarObjectType}`,
    refreshTab,
    extra: {
      searchType: params.metadata.sidebarObjectType,
    },
  });
}

export default function* watchAll(app: App) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>tabTypes.SEARCH_TAB_REQUESTED, fetchCompanySearchTabDataAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>tabTypes.SEARCH_SYSTEM_CONTEXT, fetchSystemSearchTabDataAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>tabTypes.SEARCH_INDEX_STORE, fetchIndexStoreSearchTabDataAsync, app);
}
