import React, { FunctionComponent } from 'react';
import {
  Button, Icon,
} from 'semantic-ui-react';

import { updateUserPreferences } from 'app/actions/preferenceActions';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import { toast } from 'react-toastify';
import { AcknowledegeAnnouncement } from 'app/actions/announcementActions';
import { generateId } from 'app/helpers/helpers';

export const AnnouncementsShowOnLoginButton: FunctionComponent = () => {
  const dispatch = useDispatch();
  const preferenceState = useSelector((state: State) => state.preferences);
  const { showAnnouncements } = preferenceState.data;

  return (
    <Button
      key="showAtLogin"
      positive={showAnnouncements}
      onClick={() => {
        dispatch(updateUserPreferences({ ...preferenceState.data, showAnnouncements: !showAnnouncements }));
      }}
    >
      <Icon name={showAnnouncements ? 'check' : 'close'} />
      Show At Login
    </Button>
  );
};

interface AckAllProps {
  closeModal: () => void
}

export const AnnouncementsAckAllButton: FunctionComponent<AckAllProps> = ({ closeModal }: AckAllProps) => {
  const dispatch = useDispatch();
  const announcementState = useSelector((state: State) => state.announcements);
  const announcements = announcementState.data;

  const unacked = announcements.filter((a) => !a.hasBeenAcknowledged);

  return (
    <Button
      key="ackAll"
      positive
      disabled={unacked.length === 0}
      onClick={() => {
        Object.values(unacked).forEach((a) => dispatch(AcknowledegeAnnouncement(a.id as string)));
        toast.success(
          'Announcement(s) Acknowledged',
          {
            pauseOnHover: true,
            toastId: generateId(),
          },
        );
        closeModal();
      }}
    >
      {unacked.length === 0 ? 'Acknowledge all' : `Acknowledge All (${unacked.length})`}
    </Button>
  );
};
