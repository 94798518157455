import * as announcementTypes from 'app/actions/announcementTypes';
import {
  BasicResponseOfAnnouncementTrackingDataModel,
  BasicResponseOfStatusMessageDataModelArray,
  StatusMessageDataModel,
} from 'app/helpers/types';
import { Action } from 'redux';

export type UpdateAnnouncementCompleted = Action<typeof announcementTypes.UPDATE_ANNOUNCEMENT_COMPLETED> & {
  resp: StatusMessageDataModel
};

export type AddAnnouncementCompleted = Action<typeof announcementTypes.ADD_ANNOUNCEMENT_COMPLETED> & {
  resp: StatusMessageDataModel
};

export type GetAnnouncementsCompleted = Action<typeof announcementTypes.GET_ANNOUNCEMENTS_COMPLETED> & {
  resp: BasicResponseOfStatusMessageDataModelArray
};

export type AcknowledegeAnnouncementCompleted = Action<typeof announcementTypes.ACKNOWLEDGE_ANNOUNCEMENT_COMPLETED> & {
  resp: BasicResponseOfAnnouncementTrackingDataModel
};

export type AddAnnouncementRequest = Action<
  typeof announcementTypes.ADD_ANNOUNCEMENT
> & {
  data: StatusMessageDataModel
};

export type AckAnnouncementRequest = Action<
  typeof announcementTypes.ACKNOWLEDGE_ANNOUNCEMENT
> & {
  messageId: string
};

export type GetAnnouncementRequest = Action<
  typeof announcementTypes.GET_ANNOUNCEMENTS
> & {
  includeAcknowledged: boolean
};

export function GetAnnouncements(includeAcknowledged = true) {
  return {
    type: announcementTypes.GET_ANNOUNCEMENTS,
    includeAcknowledged,
  };
}

export function AddAnnouncement(data: StatusMessageDataModel) {
  return {
    type: announcementTypes.ADD_ANNOUNCEMENT,
    data,
  };
}

export function UpdateAnnouncement(data: StatusMessageDataModel) {
  return {
    type: announcementTypes.UPDATE_ANNOUNCEMENT,
    data,
  };
}

export function AcknowledegeAnnouncement(messageId: string) {
  return {
    type: announcementTypes.ACKNOWLEDGE_ANNOUNCEMENT,
    messageId,
  };
}

export function AnnouncementsShown() {
  return {
    type: announcementTypes.ANNOUCEMENTS_MODAL_SEEN,
  };
}
