import type { SidebarProps } from 'app/components/SidebarContent/SystemSidebar/SystemSidebar';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { getSystemContextExport } from 'app/actions/systemActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Transition,
  Dropdown,
  Button,
} from 'semantic-ui-react';
import _ from 'lodash';
import { DirectoryObjectClass, SystemContextObjectClasses } from 'app/proxyClients';
import { ExportSearchForm } from 'app/typings';
import { State } from 'app/reducers/state';
import { enumToDropDownList } from 'app/helpers/helpers';

const ExportSB: FunctionComponent<SidebarProps<ExportSearchForm>> = ({
  disabled,
}: SidebarProps<ExportSearchForm>) => {
  const [searchForm, setSearchForm] = useState<ExportSearchForm>({
    classes: [],
    properties: [],
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const systemStateLoading = useSelector((state: State) => state.system?.loading);

  useEffect(() => {
    setLoading(systemStateLoading as boolean);
    if (!systemStateLoading) searchForm.classes = [];
  }, [systemStateLoading]);

  const id = _.uniqueId('ExportSB-');
  const exportObjectClassDropdownId = `${id}-exportObjectClassDropdown`;

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label" htmlFor={exportObjectClassDropdownId}>
          Object Classes
        </label>
        <Dropdown
          fluid
          clearable
          selection
          multiple
          search
          placeholder="Select Object Classes to be exported"
          disabled={disabled}
          className="last-form-item"
          options={enumToDropDownList(SystemContextObjectClasses, undefined, true)}
          id={exportObjectClassDropdownId}
          value={searchForm.classes}
          onChange={(_e, { value }) => setSearchForm({
            ...searchForm,
            classes: value as DirectoryObjectClass[],
          })}
        />
        <Button
          loading={loading}
          disabled={!searchForm.classes.length}
          fluid
          icon="download"
          onClick={() => dispatch(getSystemContextExport(searchForm))}
          content="Export"
        />
      </div>
    </Transition>
  );
};

export default ExportSB;
