export const SystemObjectTypes = [
  { value: 'Deleted', text: 'Deleted' },
  { value: 'Export', text: 'Export' },
  { value: 'Objects', text: 'Objects' },
  { value: 'Permission Scopes', text: 'Permission Scopes' },
  { value: 'Product Catalog', text: 'Product Catalog' },
  { value: 'Role Templates', text: 'Role Templates' },
  { value: 'SPTs', text: 'SPTs' },
  { value: 'Services', text: 'Services' },
  { value: 'ServiceInstances', text: 'ServiceInstances' },
  { value: 'ServicePlan', text: 'ServicePlan' },
  { value: 'Settings', text: 'Settings' },
  { value: 'Single Object', text: 'Single Object' },
  { value: 'StockKeepingUnit', text: 'StockKeepingUnit' },
  { value: 'TrustedCertificate', text: 'TrustedCertificate' },
  { value: 'TypeConfiguration', text: 'TypeConfiguration' },
];

export type ObjectTypeComponentKeys =
'Deleted' |
'Export' |
'Objects' |
'Permission Scopes' |
'Product Catalog' |
'Role Templates' |
'SPTs' |
'Services' |
'ServiceInstances' |
'ServicePlan' |
'Settings' |
'Single Object' |
'StockKeepingUnit' |
'TrustedCertificate' |
'TypeConfiguration' |
'';
