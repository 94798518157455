import './AppPermissionsModal.scss';

import { getAppPermissions, removeAppPermissionFromState } from 'app/actions/companyActions';
import ContentModal from 'app/components/shared/ContentModal/ContentModal';
import { DirectoryObjectClass, IRequiredApplicationOauth2Permissions } from 'app/proxyClients';
import { State } from 'app/reducers/state';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, List, Table } from 'semantic-ui-react';

import InputSearch from '../shared/InputSearch/InputSearch';
import Spinner from '../shared/Spinner/Spinner';
import ZeroState from '../shared/ZeroState/ZeroState';

interface AppPermissionsModalProps {
  companyId: string,
  appId: string,
  close: () => void,
}
const AppPermissionsModal: FunctionComponent<AppPermissionsModalProps> = ({
  companyId, appId, close,
}: AppPermissionsModalProps) => {
  const dispatch = useDispatch();
  const appPermissions = useSelector((state: State) => state.appPermissions);
  const [loading, setLoading] = useState(false);
  const [appPerms, setAppPerms] = useState<IRequiredApplicationOauth2Permissions[] | undefined>(undefined);
  const [toggledRows, setToggledRows] = useState<boolean[]>([]);

  const genIcon = (b: boolean) => (b
    ? <Icon size="large" name="check circle outline" />
    : <Icon size="large" name="times circle outline" />);

  const toggleRow = (i: number) => {
    const copy = [...toggledRows];
    copy[+`${i}`] = !toggledRows[+`${i}`];
    setToggledRows(copy);
  };

  const renderItemCaret = (rowId: number) => (toggledRows[+`${rowId}`]
    ? <Icon name="caret down" />
    : <Icon name="caret right" />);

  function buildTableContents() {
    if (loading) {
      return (
        <div className="spinner-container">
          <Spinner size="large" text="Loading..." />
        </div>
      );
    }

    if (appPerms === undefined) {
      return <ZeroState text={`No Permissions Found for AppId: ${appId}`} />;
    }

    const rows = appPerms?.map((a, index: number) => {
      const key = `${index}-${a.entitlementId}`;
      return (
        <React.Fragment key={`${key}-frag`}>
          <Table.Row key={key} onClick={() => toggleRow(index)}>
            <Table.Cell>{renderItemCaret(index)}</Table.Cell>
            <Table.Cell>{a.claimValue}</Table.Cell>
            <Table.Cell>{a.name}</Table.Cell>
            <Table.Cell textAlign="center">{genIcon(a.resourceApplicationFound)}</Table.Cell>
            <Table.Cell textAlign="center">{genIcon(a.entitlementFound)}</Table.Cell>
          </Table.Row>
          {
            toggledRows[+`${index}`] ? (
              <Table.Row disabled key={`${key}-expanded-row`}>
                <Table.Cell key={`${key}-cell`} colSpan="5" className="more-info">
                  <List>
                    <List.Item>
                      <List.Header>Description:</List.Header>
                      <List.Description>{a.description}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>ResourceAppId:</List.Header>
                      <List.Description>{a.resourceAppId}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>EntitlementId:</List.Header>
                      <List.Description>{a.entitlementId}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>DirectAccessGrant:</List.Header>
                      <List.Description>{a.directAccessGrant ? 'True' : 'False'}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>ImpersonationAccessGrants:</List.Header>
                      <List.Description>
                        {a?.impersonationAccessGrants?.length
                          ? a.impersonationAccessGrants.map((v) => DirectoryObjectClass[`${v}`]).join(', ')
                          : 'None'}
                      </List.Description>
                    </List.Item>
                  </List>
                </Table.Cell>
              </Table.Row>
            ) : null
          }
        </React.Fragment>
      );
    });

    return (
      <>
        { appPerms ? (
          <InputSearch
            disableTooltip
            input={appPerms}
            output={(highlighted: IRequiredApplicationOauth2Permissions[]) => setAppPerms(highlighted)}
          />
        ) : null}
        <Table striped selectable>
          <Table.Header>
            <Table.Row disabled>
              <Table.HeaderCell className="carrot-icon" />
              <Table.HeaderCell>ClaimValue</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">ResourceAppFound</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">EntitlementFound</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {appPerms.length
              ? rows
              : (<Table.Row><Table.Cell textAlign="center" colSpan="5">No Permissions Found</Table.Cell></Table.Row>)}
          </Table.Body>
        </Table>
      </>
    );
  }

  useEffect(() => {
    let entries = appPermissions?.getApplicationPermissions(appId);
    if (entries && entries.length) {
      setToggledRows(Array(entries.length).fill(false));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      entries = entries.sort((a: any, b: any) => ((a.claimValue > b.claimValue) ? 1 : -1));
    }
    setAppPerms(entries);
    setLoading(false);
  }, [appPermissions]);

  useEffect(() => {
    setLoading(true);
    dispatch(getAppPermissions(companyId, appId));
    // Cleanup
    return () => {
      dispatch(removeAppPermissionFromState(appId));
    };
  }, []);

  return (
    <ContentModal
      className="AppPermissionsModal"
      icon="shield alternate"
      style="success"
      header={`Application Permissions (AppId: ${appId})`}
      message={buildTableContents()}
      open
      closeEvent={() => close()}
    />
  );
};
export default AppPermissionsModal;
