import './Preferences.scss';
import { debounce } from 'lodash';
import { commitPreferences, stageUserPreferences } from 'app/actions/preferenceActions';
import { FormatTime } from 'app/components/shared/Utilities/TimeFormat';
import { arrayToDropDownList } from 'app/helpers/helpers';
import { defaultPreferencesState } from 'app/reducers/preferences';
import { TimeFormatType } from 'app/typings';
import { State } from 'app/reducers/state';

import moment from 'moment';
import React, {
  FunctionComponent, useCallback, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { HexColorPicker } from 'react-colorful';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox, CheckboxProps, Divider, Dropdown, DropdownProps, Grid, Menu, Popup, Tab,
} from 'semantic-ui-react';
import ThemePicker from './ThemePicker';
import useClickOutside from './useClickOutside';

const Preferences: FunctionComponent = () => {
  const dispatch = useDispatch();
  const preferencesState = useSelector((state: State) => state.preferences);
  const [preferences, setPreferences] = useState(preferencesState.data);

  const {
    hiddenColumns,
    availableColumnsToHide,
    tableFontSize,
    pageSize,
    timeFormat,
    timeUtc,
    persistTabs,
    tableHighlight,
    rowClickBehavior,
    showAnnouncements,
    tabPersistBehavior,
    showPinButton,
    searchOpenByDefault,
    hideEmptyBrowseObjects,
    openSingleSearchResult,
  } = preferences;

  // eslint-disable-next-line
  const updatePreferences = (data: any) => {
    const updated = { ...preferences, ...data };
    setPreferences(updated); //* Set in state locally
    dispatch(stageUserPreferences(updated));
  };

  useLayoutEffect(() => () => {
    dispatch(commitPreferences(true));
  }, []);

  //* Color Picker Debounce
  const changeColorHandler = (color: string) => updatePreferences({ tableHighlightColor: color });
  const debouncedColorChangeHandler = useMemo(() => debounce(changeColorHandler, 300), []);

  const titleColumnWidthApp = 6;
  const titleColumnWidthTable = 5;

  const tabPersistBehaviorList = ['Discard', 'Merge'];
  const rowClickList = ['Single Click', 'Double Click'];
  const pageSizeOptions = [100, 250, 500, 1000];

  const availableFontSizes = ['Smaller', 'Small', 'Medium', 'Large', 'Larger', 'Huge'];
  const availableFontSizesOptions = availableFontSizes.map((c) => ({ key: c, text: c, value: c.toLowerCase() }));

  const now = moment();
  const availableTimeFormats: TimeFormatType[] = Object.values(TimeFormatType);
  const availableTimeOptions = availableTimeFormats.map((c) => ({
    key: c, text: c, description: FormatTime(now, c, timeUtc), value: c,
  }));

  //* USED FOR COLOR PICKER
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const popover: any = useRef();
  const [isOpen, toggle] = useState(false);
  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  function isDropdownProps(data: unknown): data is DropdownProps {
    return (data as DropdownProps).selection !== undefined;
  }

  function isCheckboxProps(data: unknown): data is CheckboxProps {
    return (data as CheckboxProps).checked !== undefined;
  }

  const onHandleChange = (_e: React.FormEvent, data: DropdownProps | CheckboxProps) => {
    // eslint-disable-next-line
    let newState: any = null;
    if (data.name) {
      if (isCheckboxProps(data)) {
        newState = { [data.name]: data.checked };
      } else if (isDropdownProps(data)) {
        newState = { [data.name]: data.value };
        if (data.name === 'hiddenColumns') { //* If hiding columns
          //* Get the latest added ([]) or subtracted column ([column])
          const diff = hiddenColumns.filter((x: string) => !newState.hiddenColumns.includes(x));
          //* If subtracted column, is it in the default availableColumnsToHide list?
          if (diff.length && !defaultPreferencesState.data.availableColumnsToHide.includes(diff[0])) {
            //* If not in default value array -> remove it from local state as well as saved state
            newState.availableColumnsToHide = availableColumnsToHide.filter((column) => column !== diff[0]);
          }
        }
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('Preference option found without name.');
    }
    if (newState) {
      updatePreferences(newState);
    }
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item key="Theme" className="tab primary-tab-menu-item">
          Theme
        </Menu.Item>
      ),
      pane: {
        key: 'theme-pref',
        content: (<ThemePicker updatePreferences={updatePreferences} />),
      },
    },
    {
      menuItem: (
        <Menu.Item key="App Preferences" className="tab primary-tab-menu-item">
          App
        </Menu.Item>
      ),
      pane: {
        key: 'app-pref',
        content: (
          <Grid columns="equal">
            <Grid.Row className="margin-top">
              <Grid.Column width={6}>
                <Popup
                  content="Show service announcements (if any) at login."
                  position="left center"
                  trigger={<p>Show Announcements on Login:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  toggle
                  checked={showAnnouncements}
                  name="showAnnouncements"
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={titleColumnWidthApp}>
                <Popup
                  content="If true, previously opened tabs will be reopened when navigating back to DSExplorer from the same browser."
                  position="left center"
                  trigger={<p>Persist Tabs:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  toggle
                  checked={persistTabs}
                  name="persistTabs"
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={titleColumnWidthApp}>
                <Popup
                  content="If true, the pin icon will be present on all tabs. Otherwise tabs can only be pinned using the context menu."
                  position="left center"
                  trigger={<p>Show Pin Button on Tabs:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  toggle
                  checked={showPinButton}
                  name="showPinButton"
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={titleColumnWidthApp}>
                <Popup
                  content="Specify what happens to persisted tabs when following a sharing link that will open new tabs."
                  position="left center"
                  trigger={<p>When Following Links, Persisted Tab Behavior:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Tab Persistence Behavior"
                  selection
                  fluid
                  className="tabPersistBehavior"
                  name="tabPersistBehavior"
                  options={arrayToDropDownList(tabPersistBehaviorList, false)}
                  value={tabPersistBehavior}
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={titleColumnWidthApp}>
                <Popup
                  content="The format of times and dates throughout the website."
                  position="left center"
                  trigger={<p>Time Format:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Time Format"
                  selection
                  fluid
                  className="timeFormat"
                  name="timeFormat"
                  options={availableTimeOptions}
                  value={timeFormat}
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="margin-bottom">
              <Grid.Column width={titleColumnWidthApp}>
                <Popup
                  content="If true, all times will be left in UTC. Otherwise, all times will be converted to your local timezone."
                  position="left center"
                  trigger={<p>UTC Times:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  toggle
                  checked={timeUtc}
                  className="timeUtc"
                  name="timeUtc"
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ),
      },
    },
    {
      menuItem: (
        <Menu.Item key="Table Preferences" className="tab primary-tab-menu-item">
          Table
        </Menu.Item>
      ),
      pane: {
        key: 'table-pref',
        content: (
          <Grid columns="equal">
            <Grid.Row className="margin-top">
              <Grid.Column width={titleColumnWidthTable}>
                <Popup
                  content="Specify the click behavior required to open the details of a table row."
                  position="left center"
                  trigger={<p>Open Row Click Behavior:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Click Behavior"
                  selection
                  fluid
                  className="clickBehavior"
                  name="rowClickBehavior"
                  options={arrayToDropDownList(rowClickList, false)}
                  value={rowClickBehavior}
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={titleColumnWidthTable}>
                <Popup
                  content="The list of columns to be hidden in tables throughout the website. Manual entries are allowed."
                  position="left center"
                  trigger={<p>Hide Columns:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Columns to Hide"
                  multiple
                  fluid
                  search
                  allowAdditions
                  selection
                  clearable
                  className="columns"
                  name="hiddenColumns"
                  onAddItem={(e, { value }) => {
                    updatePreferences({
                      availableColumnsToHide: [...availableColumnsToHide, value],
                      hiddenColumns: [...hiddenColumns, value],
                    });
                  }}
                  options={arrayToDropDownList(availableColumnsToHide || [], false)}
                  value={hiddenColumns || []}
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={titleColumnWidthTable}>
                <Popup
                  content="The size of font in tables throughout the website."
                  position="left center"
                  trigger={<p>Table Font Size:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Table Font Size"
                  selection
                  fluid
                  className="font"
                  name="tableFontSize"
                  options={availableFontSizesOptions}
                  value={tableFontSize}
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={titleColumnWidthTable}>
                <Popup
                  content="Larger page sizes may cause performace hit."
                  position="left center"
                  trigger={<p>Page Size:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Page Size"
                  selection
                  fluid
                  className="font"
                  name="pageSize"
                  options={arrayToDropDownList(pageSizeOptions, false)}
                  value={pageSize}
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row className={`${!preferences.tableHighlight ? 'margin-bottom' : ''}`}>
              <Grid.Column width={titleColumnWidthTable}>
                <Popup
                  content="Highlight text on table search."
                  position="left center"
                  trigger={<p>Table Highlight:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  toggle
                  checked={tableHighlight}
                  name="tableHighlight"
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>

            {preferences.tableHighlight ? (
              <Grid.Row className="margin-bottom">
                <Grid.Column width={titleColumnWidthTable}>
                  <Popup
                    content="Color of the table text match highlight."
                    position="left center"
                    trigger={<p>Highlight Color:</p>}
                  />
                </Grid.Column>
                <Grid.Column>
                  <div
                    aria-hidden="true"
                    className="swatch"
                    style={{ backgroundColor: preferences.tableHighlightColor }}
                    onClick={() => toggle(true)}
                  />
                  {isOpen && (
                    <div className="popover" ref={popover}>
                      <HexColorPicker
                        color={preferences.tableHighlightColor}
                        onChange={(color) => debouncedColorChangeHandler(color)}
                      />
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </Grid>
        ),
      },
    },
    {
      menuItem: (
        <Menu.Item key="Search Preferences" className="tab primary-tab-menu-item">
          Search/Browse
        </Menu.Item>
      ),
      pane: {
        key: 'search-pref',
        content: (
          <Grid columns="equal">
            <Grid.Row className="margin-top">
              <Grid.Column width={titleColumnWidthApp}>
                <Popup
                  content="If turned off, Browse Objects will be open by default."
                  position="left center"
                  trigger={<p>Search Objects Open By Default:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  toggle
                  checked={searchOpenByDefault}
                  name="searchOpenByDefault"
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={titleColumnWidthApp}>
                <Popup
                  content="Specify whether empty object classes are hidden by default in Browse Objects."
                  position="left center"
                  trigger={<p>Hide Empty Objects (Browse):</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  toggle
                  checked={hideEmptyBrowseObjects}
                  name="hideEmptyBrowseObjects"
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="margin-bottom">
              <Grid.Column width={titleColumnWidthApp}>
                <Popup
                  content="If search returns only 1 item, skip search results page and automatically open object."
                  position="left center"
                  trigger={<p>Auto Open Single Result:</p>}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  toggle
                  checked={openSingleSearchResult}
                  name="openSingleSearchResult"
                  onChange={onHandleChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ),
      },
    },
  ];

  return (
    <>
      <Tab className="preferences-tabs" renderActiveOnly={false} panes={panes} />
    </>
  );
};

export default Preferences;
