/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
import { HttpFile } from '../http/http';

export class CommonModelsRequiredApplicationOauth2Permissions {
    'resourceAppId'?: string;
    'entitlementId'?: string;
    'name'?: string | null;
    'description'?: string | null;
    'claimValue'?: string | null;
    'entitlementFound'?: boolean;
    'resourceApplicationFound'?: boolean;
    'directAccessGrant'?: boolean;
    'impersonationAccessGrants'?: Array<MicrosoftOnlineDirectoryServicesDirectoryObjectClass> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "resourceAppId",
            "baseName": "resourceAppId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "entitlementId",
            "baseName": "entitlementId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "claimValue",
            "baseName": "claimValue",
            "type": "string",
            "format": ""
        },
        {
            "name": "entitlementFound",
            "baseName": "entitlementFound",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "resourceApplicationFound",
            "baseName": "resourceApplicationFound",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "directAccessGrant",
            "baseName": "directAccessGrant",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "impersonationAccessGrants",
            "baseName": "impersonationAccessGrants",
            "type": "Array<MicrosoftOnlineDirectoryServicesDirectoryObjectClass>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CommonModelsRequiredApplicationOauth2Permissions.attributeTypeMap;
    }

    public constructor() {
    }
}

