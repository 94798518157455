import _ from 'lodash';
import type { App } from 'app/store';
import {
  call, put, delay, takeEvery, take, select, SagaGenerator,
} from 'typed-redux-saga';

import * as authTypes from 'app/actions/authTypes';
import * as TabTypes from 'app/actions/tabTypes';
import { State } from 'app/reducers/state';
import {
  GetTabLinkAction, CreateTabLinkActionRequested,
} from 'app/actions/tabActions';
import { LocalStoreTabsName } from 'app/reducers/tabs';
import { getLinkState, addLinkState } from 'app/services/linkState';
import { LinkStateDataModel } from 'app/proxyClients';
import { TabType } from 'app/typings';
import { SagaIterator } from '@redux-saga/types';

const getAuthState = (state:State) => state.auth;
const getPreferences = (state:State) => state.preferences;

function* restoreTabsImplAsync(data : LinkStateDataModel[] | undefined) {
  if (!Array.isArray(data)) {
    return;
  }

  for (let i = 0; i < data.length; i += 1) {
    const t = data[+`${i}`];
    yield* delay(50);

    if (t.data === undefined || ('type' in t.data) === false) {
      // eslint-disable-next-line no-continue
      continue;
    }

    switch (t.tabType) {
      case TabType.Company:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        yield* put(t.data as any);
        break;
      case TabType.Key:
      case TabType.System:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        yield* put(t.data as any);
        break;
      case TabType.Index:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        yield* put(t.data as any);
        break;
      case TabType.Partition:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        yield* put(t.data as any);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(`Not implemented: restore ${t.tabType} tabs`);
    }
  }
}

function* dispatchTabCreationAsync(app: App, params:GetTabLinkAction): SagaIterator<void> {
  if (app.config.shouldDisableLinkShare()) {
    return;
  }

  const authState = yield* select(getAuthState);
  if (authState === undefined) throw new Error('Expected authState to be defined');
  if (!authState.isLoggedIn()) {
    yield* take(authTypes.LOGIN_COMPLETED);
  }

  const resp = yield* call(getLinkState, app, params.id);

  if (!resp) {
    return;
  }

  yield* restoreTabsImplAsync(resp.result?.links);
}

function* restoreTabsAsync(): SagaIterator<void> {
  const authState = yield* select(getAuthState);
  if (authState === undefined) throw new Error('Expected authState to be defined');
  if (!authState.isLoggedIn()) {
    yield* take(authTypes.LOGIN_COMPLETED);
  }

  const preferences = yield* select(getPreferences);
  if (!preferences.data.persistTabs || !window.localStorage[`${LocalStoreTabsName}`]) {
    yield* put({ type: TabTypes.RESTORE_TABS_COMPLETED });
    return;
  }
  const tabs = JSON.parse(window.localStorage[`${LocalStoreTabsName}`]);

  const tabParams = _.map(tabs, (t) => (new LinkStateDataModel({
    tabType: t.tabType,
    data: { ...t.params, children: t.children },
  })));

  yield* restoreTabsImplAsync(tabParams);

  // If we have tabs to restore than we should be able to wait for the following actions
  for (let i = 0; i < tabs.length; i += 1) {
    yield* take(TabTypes.ADD_TAB);
    yield* take(TabTypes.UPDATE_TAB);
  }

  yield* put({ type: TabTypes.RESTORE_TABS_COMPLETED });
}

function* addLinkStateAsync(app: App, params: CreateTabLinkActionRequested): SagaIterator<void> {
  if (app.config.shouldDisableLinkShare()) {
    return;
  }

  const preferences = yield* select(getPreferences);

  // Discard "Saved" tabs if Creating Link State tabs
  if (preferences.data.tabPersistBehavior.toLowerCase() === 'discard') {
    delete window.localStorage[`${LocalStoreTabsName}`];
  }

  try {
    const resp = yield* call(addLinkState, app, params);
    yield* put({ type: TabTypes.CREATE_TAB_LINK_STATE_COMPLETED, linkState: resp });
  } catch (e) {
    yield* put({ type: TabTypes.CREATE_TAB_LINK_STATE_FAILED, error: (e as Error).message });
  }
}

export default function* watchAll(app: App): SagaGenerator<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>TabTypes.APP_BOOT_STRAP_TAB_LINKS, dispatchTabCreationAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>TabTypes.RESTORE_TABS, restoreTabsAsync);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>TabTypes.CREATE_TAB_LINK_STATE, addLinkStateAsync, app);
}
