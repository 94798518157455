import * as authTypes from 'app/actions/authTypes';

export function login() {
  return {
    type: authTypes.LOGIN_REQUESTED,
  };
}

export function logout() {
  return {
    type: authTypes.LOGOUT_REQUESTED,
  };
}
