import './Toolbox.scss';

import { generateGuid, generateId } from 'app/helpers/helpers';
import { BasicToolboxResponseOfListOfString } from 'app/proxyClients';
import fetchConversion from 'app/services/toolbox';
import { app } from 'app/store';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button, Card, Form, Input, Popup, Transition,
} from 'semantic-ui-react';

import _ from 'lodash';
import { ITool, Tools } from './ToolboxConstants';
import ZeroStateToolbox from './ZeroStateToolbox/ZeroStateToolbox';

const Toolbox = () => {
  const [userInput, setInput] = useState<Record<string, string>>({});
  const [toolbox] = useState<ITool[]>(Object.values(Tools));
  type ResultType = BasicToolboxResponseOfListOfString;
  const [results, setResults] = useState<Record<number, ResultType | null>>({});

  const handleInput = (input: string, index: number) => {
    setInput({ ...userInput, [index]: input });
  };

  const handleSubmit = async (
    index: number,
    tool: ITool,
    input: string,
  ) => {
    if (!input) {
      return null;
    }
    const payload = { [tool.name]: input };
    try {
      const result = await fetchConversion(app, tool, payload);
      setResults({ ...results, [index]: result });
      // eslint-disable-next-line
    } catch (e: any) {
      toast.error(e.message as string, { toastId: generateId() });
      setResults({ ...results, [index]: null }); // Clear the Tool when error
    }
    return undefined;
  };

  const handleClear = (index: number) => {
    setResults({ ...results, [index]: null });
    setInput({ ...userInput, [index]: '' });
  };

  const copyValue = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.success(`Value copied to clipboard: ${value}`, {
      pauseOnHover: true,
      toastId: generateId(),
    });
  };

  const generateValue = (tool: ITool, index: number) => {
    if (tool.name === 'dateTime') {
      const localISOTime = (new Date(Date.now())).toISOString();
      handleSubmit(index, tool, localISOTime);
      handleInput(localISOTime, index);
    }
    if (tool.name === 'guid') {
      const guid = generateGuid();
      handleSubmit(index, tool, guid);
      handleInput(guid, index);
    }
  };

  return (
    <div className="toolbox-container">
      {toolbox.map((tool: ITool, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Card key={index} className="tool">
          <Card.Content className="tool-top-content">
            <Card.Header className="tool-header">{tool.header}</Card.Header>
            <Form>
              <Form.Field className="tool-input-container">
                <Input
                  className="tool-input-text"
                  icon
                  fluid
                  placeholder={tool.placeholder}
                  value={userInput[+`${index}`] || ''}
                  onChange={(e, { value }) => handleInput(value, index)}
                  action
                  onKeyPress={(e: React.KeyboardEvent<Input>) => {
                    if (e.key === 'Enter') {
                      handleSubmit(index, tool, userInput[+`${index}`]);
                    }
                  }}
                >
                  <input />
                  {
                    tool.name === 'dateTime' || tool.name === 'guid'
                      ? (
                        <Button
                          type="button"
                          onClick={() => generateValue(tool, index)}
                        >
                          Generate
                        </Button>
                      )
                      : null
                  }
                  <Button disabled={!userInput[+`${index}`]} icon="close" type="button" onClick={() => handleClear(index)} />
                </Input>
              </Form.Field>
            </Form>
          </Card.Content>
          {results[+`${index}`] && (results[+`${index}`] as ResultType).type === tool.type ? (
            <Transition transitionOnMount animation="scale" duration={400}>
              <Card.Content className="tool-results-container" extra>
                {(results[+`${index}`] as ResultType).result?.map((value: string, i: number) => (
                  <Popup
                    flowing
                    key={value}
                    position="right center"
                    content="Click to copy!"
                    trigger={(
                      // eslint-disable-next-line react/no-array-index-key
                      <Button className="tool-results-list" primary key={i} compact onClick={() => copyValue(value)}>{value}</Button>
                    )}
                  />
                ))}
              </Card.Content>
            </Transition>
          ) : (
            <ZeroStateToolbox text="Enter value to be converted" />
          )}
          <Card.Content className="tool-convert-button-container">
            <Button
              disabled={!userInput[+`${index}`] || !_.isEmpty(results[+`${index}`])}
              className="tool-convert-button"
              fluid
              color="blue"
              onClick={() => handleSubmit(index, tool, userInput[+`${index}`])}
            >
              Convert
            </Button>
          </Card.Content>
        </Card>
      ))}
    </div>
  );
};

export default Toolbox;
