import { enumToDropDownList } from 'app/helpers/helpers';
import { AltSecIdType } from 'app/helpers/types';
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Dropdown, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface AltSecIdSearchForm {
  altSecIdType: AltSecIdType,
  altSecIdKey : string,
  altSecIdProvider: string,
  softDeleted: boolean
}

const AltSecIdSB: FunctionComponent<ObjectTypeProps<AltSecIdSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<AltSecIdSearchForm>) => {
  const [searchForm, setSearchForm] = useState<AltSecIdSearchForm>({
    altSecIdType: AltSecIdType.LiveId, altSecIdKey: '', altSecIdProvider: '', softDeleted: false,
  });

  const id = _.uniqueId('AltSecIdSB-');
  const directObjectClassDropdownId = `${id}-DirectoryObjectClassDropdown`;

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label" htmlFor={directObjectClassDropdownId}>Directory Object Class</label>
        <Dropdown
          fluid
          selection
          placeholder="Select Directory Object Class"
          id={directObjectClassDropdownId}
          disabled={disabled}
          className="margin-bottom"
          options={enumToDropDownList(AltSecIdType)}
          value={searchForm.altSecIdType}
          onChange={(e, { value }) => setSearchForm({ ...searchForm, altSecIdType: value as AltSecIdType })}
        />
        <label className="label">
          Alt Sec Key
          <Popup
            flowing
            position="bottom left"
            content="Alt Sec Key"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.altSecIdKey}
                className="margin-bottom"
                fluid
                placeholder="Search Key"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, altSecIdKey: value })}
              />
                      )}
          />
        </label>
        <label className="label">
          Alt Sec Provider
          <Popup
            flowing
            position="bottom left"
            content="Alt Sec Provider"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.altSecIdProvider}
                className="last-form-item"
                fluid
                placeholder="Search Provider"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, altSecIdProvider: value })}
              />
                      )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({
              altSecIdType: AltSecIdType.LiveId, altSecIdKey: '', altSecIdProvider: '', softDeleted: false,
            })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.altSecIdKey === ''}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default AltSecIdSB;
