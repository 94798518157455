/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractDSReference } from '../models/MicrosoftOnlineDSExplorerContractDSReference';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractDSReferenceSet {
    'source'?: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData | null;
    'targets'?: Array<MicrosoftOnlineDSExplorerContractDSReference> | null;
    'more'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "source",
            "baseName": "source",
            "type": "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData",
            "format": ""
        },
        {
            "name": "targets",
            "baseName": "targets",
            "type": "Array<MicrosoftOnlineDSExplorerContractDSReference>",
            "format": ""
        },
        {
            "name": "more",
            "baseName": "more",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractDSReferenceSet.attributeTypeMap;
    }

    public constructor() {
    }
}

