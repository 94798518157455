/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = None 1 = Element 2 = Attribute 3 = Text 4 = CDATA 5 = EntityReference 6 = Entity 7 = ProcessingInstruction 8 = Comment 9 = Document 10 = DocumentType 11 = DocumentFragment 12 = Notation 13 = Whitespace 14 = SignificantWhitespace 15 = EndElement 16 = EndEntity 17 = XmlDeclaration
*/
export enum SystemXmlXmlNodeType {
    None = 0,
    Element = 1,
    Attribute = 2,
    Text = 3,
    CDATA = 4,
    EntityReference = 5,
    Entity = 6,
    ProcessingInstruction = 7,
    Comment = 8,
    Document = 9,
    DocumentType = 10,
    DocumentFragment = 11,
    Notation = 12,
    Whitespace = 13,
    SignificantWhitespace = 14,
    EndElement = 15,
    EndEntity = 16,
    XmlDeclaration = 17
}
