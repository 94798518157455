import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Dropdown, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { guidChecker } from 'app/helpers/helpers';
import { FilterStringEnum } from 'app/helpers/types';

export interface ServicePrincipalsForm {
  filterString: string,
  filterType: FilterStringEnum,
}

export const defaultSPForm = {
  filterString: '',
  filterType: 0,
};

const ServicePrincipalSB: FunctionComponent<ObjectTypeProps<ServicePrincipalsForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ServicePrincipalsForm>) => {
  const [searchForm, setSearchForm] = useState<ServicePrincipalsForm>(defaultSPForm);

  const id = _.uniqueId('ServicePrincipalSidebar-');
  const spId = `${id}-servicePrincipalType`;

  const servicePrincipalOptions = [
    { text: 'None', value: 0 },
    { text: 'AppPrincipalId', value: 1 },
    { text: 'DisplayName', value: 2 },
    { text: 'ServicePrincipalName', value: 3 },
    { text: 'ServicePrincipalTag', value: 4 },
  ];

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label" htmlFor={spId}>Service Principal Filter</label>
        <Dropdown
          placeholder="Search/Select Index Object Type"
          onChange={(e, { value }) => setSearchForm({ ...searchForm, filterType: value as number })}
          fluid
          className={searchForm.filterType !== 0 ? 'margin-bottom' : 'last-form-item'}
          search
          selection
          id={spId}
          options={servicePrincipalOptions}
          value={searchForm.filterType}
        />

        {searchForm.filterType !== 0 ? (
          <label className="label">
            {servicePrincipalOptions[searchForm.filterType].text}
            <Popup
              flowing
              position="bottom left"
              content={`If set, the search will be scoped to objects from this ${servicePrincipalOptions[searchForm.filterType].text}. 
              AppPrincipalId must be a guid and should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx).`}
              trigger={(
                <Input
                  error={servicePrincipalOptions[searchForm.filterType].text === 'AppPrincipalId'
                  && (searchForm.filterString.length > 0 && !guidChecker(searchForm.filterString))}
                  disabled={disabled}
                  value={searchForm.filterString}
                  className="last-form-item"
                  fluid
                  placeholder={`Search ${servicePrincipalOptions[searchForm.filterType].text}`}
                  onChange={(e, { value }) => setSearchForm({ ...searchForm, filterString: value })}
                />
          )}
            />
          </label>
        ) : null}

        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm(defaultSPForm)}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.filterType !== 0 && searchForm.filterString === ''}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default ServicePrincipalSB;
