/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = Disabled 1 = Enabled 2 = PendingEnabled 3 = PendingDisabledDraining 4 = PendingDisabledTransferring
*/
export enum MicrosoftOnlineDirectoryServicesDirSyncState {
    Disabled = 0,
    Enabled = 1,
    PendingEnabled = 2,
    PendingDisabledDraining = 3,
    PendingDisabledTransferring = 4
}
