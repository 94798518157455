// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { DSExplorerWebHostModelsBulkLinkSearchRequestModel } from '../models/DSExplorerWebHostModelsBulkLinkSearchRequestModel';
import { DSExplorerWebHostModelsBulkSearchRequestModel } from '../models/DSExplorerWebHostModelsBulkSearchRequestModel';

/**
 * no description
 */
export class BulkSearchApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public async bulkSearchSearch(dSExplorerWebHostModelsBulkSearchRequestModel: Array<DSExplorerWebHostModelsBulkSearchRequestModel>, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsBulkSearchRequestModel' is not null or undefined
        if (dSExplorerWebHostModelsBulkSearchRequestModel === null || dSExplorerWebHostModelsBulkSearchRequestModel === undefined) {
            throw new RequiredError("BulkSearchApi", "bulkSearchSearch", "dSExplorerWebHostModelsBulkSearchRequestModel");
        }


        // Path Params
        const localVarPath = '/api/BulkSearch';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsBulkSearchRequestModel, "Array<DSExplorerWebHostModelsBulkSearchRequestModel>", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsBulkLinkSearchRequestModel 
     */
    public async bulkSearchSearchLinks(dSExplorerWebHostModelsBulkLinkSearchRequestModel: DSExplorerWebHostModelsBulkLinkSearchRequestModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsBulkLinkSearchRequestModel' is not null or undefined
        if (dSExplorerWebHostModelsBulkLinkSearchRequestModel === null || dSExplorerWebHostModelsBulkLinkSearchRequestModel === undefined) {
            throw new RequiredError("BulkSearchApi", "bulkSearchSearchLinks", "dSExplorerWebHostModelsBulkLinkSearchRequestModel");
        }


        // Path Params
        const localVarPath = '/api/BulkSearch/SearchLinks';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsBulkLinkSearchRequestModel, "DSExplorerWebHostModelsBulkLinkSearchRequestModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public async bulkSearchStreamableBulkSearch(dSExplorerWebHostModelsBulkSearchRequestModel: DSExplorerWebHostModelsBulkSearchRequestModel, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'dSExplorerWebHostModelsBulkSearchRequestModel' is not null or undefined
        if (dSExplorerWebHostModelsBulkSearchRequestModel === null || dSExplorerWebHostModelsBulkSearchRequestModel === undefined) {
            throw new RequiredError("BulkSearchApi", "bulkSearchStreamableBulkSearch", "dSExplorerWebHostModelsBulkSearchRequestModel");
        }


        // Path Params
        const localVarPath = '/api/BulkSearch/stream';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(dSExplorerWebHostModelsBulkSearchRequestModel, "DSExplorerWebHostModelsBulkSearchRequestModel", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class BulkSearchApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to bulkSearchSearch
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async bulkSearchSearchWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to bulkSearchSearchLinks
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async bulkSearchSearchLinksWithHttpInfo(response: ResponseContext): Promise<HttpInfo<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray", ""
            ) as CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to bulkSearchStreamableBulkSearch
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async bulkSearchStreamableBulkSearchWithHttpInfo(response: ResponseContext): Promise<HttpInfo<void >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, undefined);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
