// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { MicrosoftOnlineDSExplorerContractAltSecIdType } from '../models/MicrosoftOnlineDSExplorerContractAltSecIdType';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail';

/**
 * no description
 */
export class UserApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param companyIdentifier 
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param softDeleted 
     */
    public async userGetUserByAltSecId(companyIdentifier: string, altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, softDeleted?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'companyIdentifier' is not null or undefined
        if (companyIdentifier === null || companyIdentifier === undefined) {
            throw new RequiredError("UserApi", "userGetUserByAltSecId", "companyIdentifier");
        }






        // Path Params
        const localVarPath = '/api/User/ByAltSecId/{companyIdentifier}'
            .replace('{' + 'companyIdentifier' + '}', encodeURIComponent(String(companyIdentifier)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (altSecIdType !== undefined) {
            requestContext.setQueryParam("altSecIdType", ObjectSerializer.serialize(altSecIdType, "MicrosoftOnlineDSExplorerContractAltSecIdType", ""));
        }

        // Query Params
        if (altSecIdKey !== undefined) {
            requestContext.setQueryParam("altSecIdKey", ObjectSerializer.serialize(altSecIdKey, "string", ""));
        }

        // Query Params
        if (altSecIdProvider !== undefined) {
            requestContext.setQueryParam("altSecIdProvider", ObjectSerializer.serialize(altSecIdProvider, "string", ""));
        }

        // Query Params
        if (softDeleted !== undefined) {
            requestContext.setQueryParam("softDeleted", ObjectSerializer.serialize(softDeleted, "boolean", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param userIdentifier 
     * @param softDeleted 
     */
    public async userGetUserByIdentifier(companyIdentifier: string, userIdentifier: string, softDeleted?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'companyIdentifier' is not null or undefined
        if (companyIdentifier === null || companyIdentifier === undefined) {
            throw new RequiredError("UserApi", "userGetUserByIdentifier", "companyIdentifier");
        }


        // verify required parameter 'userIdentifier' is not null or undefined
        if (userIdentifier === null || userIdentifier === undefined) {
            throw new RequiredError("UserApi", "userGetUserByIdentifier", "userIdentifier");
        }



        // Path Params
        const localVarPath = '/api/User/ByIdentifier/{companyIdentifier}/{userIdentifier}'
            .replace('{' + 'companyIdentifier' + '}', encodeURIComponent(String(companyIdentifier)))
            .replace('{' + 'userIdentifier' + '}', encodeURIComponent(String(userIdentifier)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (softDeleted !== undefined) {
            requestContext.setQueryParam("softDeleted", ObjectSerializer.serialize(softDeleted, "boolean", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param ticketValue 
     * @param softDeleted 
     */
    public async userGetUserByInvitationTicket(companyIdentifier: string, ticketValue: string, softDeleted?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'companyIdentifier' is not null or undefined
        if (companyIdentifier === null || companyIdentifier === undefined) {
            throw new RequiredError("UserApi", "userGetUserByInvitationTicket", "companyIdentifier");
        }


        // verify required parameter 'ticketValue' is not null or undefined
        if (ticketValue === null || ticketValue === undefined) {
            throw new RequiredError("UserApi", "userGetUserByInvitationTicket", "ticketValue");
        }



        // Path Params
        const localVarPath = '/api/User/ByInvitationTicket/{companyIdentifier}/{ticketValue}'
            .replace('{' + 'companyIdentifier' + '}', encodeURIComponent(String(companyIdentifier)))
            .replace('{' + 'ticketValue' + '}', encodeURIComponent(String(ticketValue)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (softDeleted !== undefined) {
            requestContext.setQueryParam("softDeleted", ObjectSerializer.serialize(softDeleted, "boolean", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param companyIdentifier 
     * @param userObjectId 
     * @param isSoftDeleted 
     */
    public async userGetUserProvisioningStatusDetail(companyIdentifier: string, userObjectId: string, isSoftDeleted?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'companyIdentifier' is not null or undefined
        if (companyIdentifier === null || companyIdentifier === undefined) {
            throw new RequiredError("UserApi", "userGetUserProvisioningStatusDetail", "companyIdentifier");
        }


        // verify required parameter 'userObjectId' is not null or undefined
        if (userObjectId === null || userObjectId === undefined) {
            throw new RequiredError("UserApi", "userGetUserProvisioningStatusDetail", "userObjectId");
        }



        // Path Params
        const localVarPath = '/api/User/ProvisioningStatus/{companyIdentifier}/{userObjectId}'
            .replace('{' + 'companyIdentifier' + '}', encodeURIComponent(String(companyIdentifier)))
            .replace('{' + 'userObjectId' + '}', encodeURIComponent(String(userObjectId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (isSoftDeleted !== undefined) {
            requestContext.setQueryParam("isSoftDeleted", ObjectSerializer.serialize(isSoftDeleted, "boolean", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class UserApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to userGetUserByAltSecId
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async userGetUserByAltSecIdWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to userGetUserByIdentifier
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async userGetUserByIdentifierWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to userGetUserByInvitationTicket
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async userGetUserByInvitationTicketWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to userGetUserProvisioningStatusDetail
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async userGetUserProvisioningStatusDetailWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
