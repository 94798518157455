import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Checkbox, Input, Popup, Transition,
} from 'semantic-ui-react';

export interface InvitationSearchForm {
  ticketValue: string,
  softDeleted: boolean
}

const InvitationIndexSB: FunctionComponent<ObjectTypeProps<InvitationSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<InvitationSearchForm>) => {
  const [searchForm, setSearchForm] = useState<InvitationSearchForm>({
    ticketValue: '', softDeleted: false,
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Invitation Ticket
          <Popup
            flowing
            position="bottom left"
            content="Search Invitation Ticket"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.ticketValue}
                className="margin-bottom"
                fluid
                placeholder="Invitation Ticket"
                onChange={(e, { value }) => setSearchForm({
                  ...searchForm,
                  ticketValue: value,
                })}
              />
                          )}
          />
        </label>
        <Popup
          flowing
          position="bottom left"
          content="If selected, the search will be scoped to the soft deleted object container (aka Recycle Bin)"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="last-form-item"
              toggle
              label="Soft Deleted"
              checked={searchForm.softDeleted}
              onChange={(e, { checked }) => setSearchForm({ ...searchForm, softDeleted: checked as boolean })}
            />
            )}
        />
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ ticketValue: '', softDeleted: false })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.ticketValue === ''}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default InvitationIndexSB;
