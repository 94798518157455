import _ from 'lodash';
import * as TabTypes from 'app/actions/tabTypes';
import {
  ActivateTabAction, RemoveTabAction,
} from 'app/actions/tabActions';
import {
  TabType,
} from 'app/typings';

type ActiveTabRecord = {
  parent: string,
  child: string | null
};
type ActiveTabTypeStore = {
  active: string,
  tabs: ActiveTabRecord[]
};
type TabStore = Record<TabType, ActiveTabTypeStore | null>;

type ValidActiveTabActions =
  | ActivateTabAction
  | RemoveTabAction;

export interface ActiveTabState {
  tabStore: TabStore;
  removeTab: (action: RemoveTabAction) => TabStore;
  getActiveTab: (type: TabType, parentTab?: string) => string | undefined;
  setActiveTab: (action: ActivateTabAction) => TabStore;
}

export const defaultActiveTabState = {
  tabStore: {
    Company: null,
    System: null,
    Key: null,
    Index: null,
    Partition: null,
  } as TabStore,
  getActiveTab(type: TabType, parentTab?: string) {
    if (this.tabStore[`${type}`] === null) {
      return undefined;
    }

    if (!parentTab) {
      return (this.tabStore[`${type}`] as ActiveTabTypeStore).active;
    }
    const found = _.find(this.tabStore[`${type}`]?.tabs, (o) => o.parent === parentTab);
    if (!found) {
      return undefined;
    }

    return found.child as string | undefined;
  },
  setActiveTab(action: ActivateTabAction): TabStore {
    const { [action.tabType]: currentTabType, ...otherTabTypes } = this.tabStore;

    // If no parent
    if (!action.parentTab) {
      const { active, ...otherProperties } = currentTabType ?? { tabs: [] };

      return {
        ...otherTabTypes,
        [action.tabType]: { ...otherProperties, active: action.id },
      } as TabStore;
    }

    // If we have parent -- Need to create a parent and
    if (action.parentTab) {
      const existingRecords = _.filter(currentTabType?.tabs, (o) => o.parent !== action.parentTab);

      const newRecord = { parent: action.parentTab, child: action.id };
      return {
        ...otherTabTypes,
        [action.tabType]: { tabs: [...existingRecords, newRecord], active: action.parentTab },
      } as TabStore;
    }

    return this.tabStore;
  },
  removeTab(action: RemoveTabAction) {
    const updatingStore = this.tabStore[action.tabType] as ActiveTabTypeStore;

    if (updatingStore && action.id === updatingStore.active) {
      updatingStore.active = '';
    }

    const filtered = _.reject(updatingStore.tabs, (o) => {
      const directMatch = action.id === o.child && action.parentTab === o.parent;
      const onlyParent = action.id && action.parentTab === undefined && o.parent === action.id;
      return directMatch || onlyParent;
    }) as ActiveTabRecord[];

    let activeTab = updatingStore.active;
    if (filtered.length === 0) {
      activeTab = '';
    }

    return { ...this.tabStore, [action.tabType]: { ...updatingStore, active: activeTab, tabs: filtered } };
  },
};

type ActiveTabActions = ValidActiveTabActions | never;
export default (
  tabState: ActiveTabState = defaultActiveTabState,
  action: ActiveTabActions,
): ActiveTabState => {
  switch (action.type) {
    case TabTypes.REMOVE_TAB:
      return { ...tabState, tabStore: tabState.removeTab(action) };
    case TabTypes.ACTIVATE_TAB:
      return { ...tabState, tabStore: tabState.setActiveTab(action) };
    default:
      return tabState;
  }
};
