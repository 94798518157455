import './System.scss';

import { clientSideFilterTree } from 'app/components/shared/Utilities/Formatters';
import React from 'react';

import { TabType } from 'app/typings';
import Search from '../Search/Search';
import TreeTable from '../shared/TreeTable/TreeTable';
import SystemSettings from './SystemSettings/SystemSettings';
import SingleObject from '../Company/SingleObject';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const System = ({ params, searchData }: any) => {
  const SystemChildren: Record<string, JSX.Element | undefined> = {
    'Single Object': <SingleObject dataResult={searchData} />,
    Settings: <SystemSettings settings={searchData.data} />,
    'Permission Scopes': <TreeTable data={clientSideFilterTree(searchData.data, params.params.data?.contains)} />,
    'Role Templates': <TreeTable data={clientSideFilterTree(searchData.data, params.params.data?.contains)} />,
    SPTs: <TreeTable data={clientSideFilterTree(searchData.data, params.params.data?.contains)} />,
    ServiceDetail: <SingleObject dataResult={searchData} />,
    ServiceInstanceDetail: <SingleObject dataResult={searchData} />,
    TrustedCertificate: <Search dataResult={{ searchData }} sidebarObjectType="TrustedCertificate" tabType={TabType.System} />,
    TypeConfiguration: <Search dataResult={{ searchData }} sidebarObjectType="TypeConfiguration" tabType={TabType.System} />,
  };

  return (
    <div className="System">
      {
        params.params.metadata.sidebarObjectType
          ? SystemChildren[params.params.metadata.sidebarObjectType]
          : SystemChildren['Single Object']
      }
    </div>
  );
};

export default System;
