/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority';
import { MicrosoftOnlineDirectoryServicesDirectoryProperty } from '../models/MicrosoftOnlineDirectoryServicesDirectoryProperty';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty {
    'displayName'?: string | null;
    'values'?: Array<string> | null;
    'lastWriteTime'?: Date | null;
    'ldapName'?: string | null;
    'localUpdateSequenceNumber'?: number | null;
    'name'?: MicrosoftOnlineDirectoryServicesDirectoryProperty;
    'originatingAuthority'?: MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority | null;
    'originatingUpdateSequenceNumber'?: number | null;
    'originatingTimestamp'?: Date | null;
    'version'?: number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string",
            "format": ""
        },
        {
            "name": "values",
            "baseName": "values",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "lastWriteTime",
            "baseName": "lastWriteTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "ldapName",
            "baseName": "ldapName",
            "type": "string",
            "format": ""
        },
        {
            "name": "localUpdateSequenceNumber",
            "baseName": "localUpdateSequenceNumber",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "MicrosoftOnlineDirectoryServicesDirectoryProperty",
            "format": ""
        },
        {
            "name": "originatingAuthority",
            "baseName": "originatingAuthority",
            "type": "MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority",
            "format": ""
        },
        {
            "name": "originatingUpdateSequenceNumber",
            "baseName": "originatingUpdateSequenceNumber",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "originatingTimestamp",
            "baseName": "originatingTimestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "version",
            "baseName": "version",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty.attributeTypeMap;
    }

    public constructor() {
    }
}



