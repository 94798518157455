import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { guidChecker } from 'app/helpers/helpers';

export type KeyGroupsSearchForm = Record<string, unknown> & {
  keyGroupId: string,
};

export const defaultKeyGroupsForm = {
  keyGroupId: '',
};

const KeyGroupsSB: FunctionComponent<ObjectTypeProps<KeyGroupsSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<KeyGroupsSearchForm>) => {
  const [searchForm, setSearchForm] = useState<KeyGroupsSearchForm>(defaultKeyGroupsForm);

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          KeyGroupId
          <Popup
            flowing
            position="bottom left"
            content="Enter the KeyGroupId of the KeyGroup object you are looking for.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.keyGroupId.length > 0 && !guidChecker(searchForm.keyGroupId)}
                disabled={disabled}
                value={searchForm.keyGroupId}
                className="last-form-item"
                fluid
                placeholder="Search KeyGroupId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, keyGroupId: value })}
              />
                      )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm(defaultKeyGroupsForm)}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default KeyGroupsSB;
