// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray';
import { PartitionGetCompaniesByPartitionDirSyncStateFilterParameter } from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameter';

/**
 * no description
 */
export class PartitionApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param partitionId 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param contextIdsString 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public async partitionGetCompaniesByPartition(partitionId: number, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, contextIdsString?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: PartitionGetCompaniesByPartitionDirSyncStateFilterParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'partitionId' is not null or undefined
        if (partitionId === null || partitionId === undefined) {
            throw new RequiredError("PartitionApi", "partitionGetCompaniesByPartition", "partitionId");
        }












        // Path Params
        const localVarPath = '/api/Partition/GetCompaniesByPartition/{partitionId}'
            .replace('{' + 'partitionId' + '}', encodeURIComponent(String(partitionId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (authorizedServiceInstance !== undefined) {
            requestContext.setQueryParam("authorizedServiceInstance", ObjectSerializer.serialize(authorizedServiceInstance, "string", ""));
        }

        // Query Params
        if (authorizedServiceInstancePrefix !== undefined) {
            requestContext.setQueryParam("authorizedServiceInstancePrefix", ObjectSerializer.serialize(authorizedServiceInstancePrefix, "string", ""));
        }

        // Query Params
        if (contextIdsString !== undefined) {
            requestContext.setQueryParam("contextIdsString", ObjectSerializer.serialize(contextIdsString, "string", ""));
        }

        // Query Params
        if (creationTimeAfterOrAt !== undefined) {
            requestContext.setQueryParam("creationTimeAfterOrAt", ObjectSerializer.serialize(creationTimeAfterOrAt, "string", ""));
        }

        // Query Params
        if (creationTimeBeforeOrAt !== undefined) {
            requestContext.setQueryParam("creationTimeBeforeOrAt", ObjectSerializer.serialize(creationTimeBeforeOrAt, "string", ""));
        }

        // Query Params
        if (dirSyncEnabledOnly !== undefined) {
            requestContext.setQueryParam("dirSyncEnabledOnly", ObjectSerializer.serialize(dirSyncEnabledOnly, "boolean", ""));
        }

        // Query Params
        if (dirSyncStateFilter !== undefined) {
            requestContext.setQueryParam("dirSyncStateFilter", ObjectSerializer.serialize(dirSyncStateFilter, "PartitionGetCompaniesByPartitionDirSyncStateFilterParameter", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (replicaToken !== undefined) {
            requestContext.setQueryParam("replicaToken", ObjectSerializer.serialize(replicaToken, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     */
    public async partitionGetPartitionDetails(_options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // Path Params
        const localVarPath = '/api/Partition/PartitionDetails';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param partitionId 
     * @param startedAtOrAfter 
     */
    public async partitionGetPartitionFailovers(partitionId: number, startedAtOrAfter?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'partitionId' is not null or undefined
        if (partitionId === null || partitionId === undefined) {
            throw new RequiredError("PartitionApi", "partitionGetPartitionFailovers", "partitionId");
        }



        // Path Params
        const localVarPath = '/api/Partition/GetPartitionFailovers/{partitionId}'
            .replace('{' + 'partitionId' + '}', encodeURIComponent(String(partitionId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (startedAtOrAfter !== undefined) {
            requestContext.setQueryParam("startedAtOrAfter", ObjectSerializer.serialize(startedAtOrAfter, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     */
    public async partitionGetPartitionIds(_options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // Path Params
        const localVarPath = '/api/Partition/PartitionIds';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class PartitionApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to partitionGetCompaniesByPartition
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async partitionGetCompaniesByPartitionWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray", ""
            ) as MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to partitionGetPartitionDetails
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async partitionGetPartitionDetailsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to partitionGetPartitionFailovers
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async partitionGetPartitionFailoversWithHttpInfo(response: ResponseContext): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray", ""
            ) as MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to partitionGetPartitionIds
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async partitionGetPartitionIdsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<number> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<number> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<number>", "int32"
            ) as Array<number>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<number> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<number>", "int32"
            ) as Array<number>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
