import React, { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import DSTable from 'app/components/shared/DSTable/DSTable';
import ZeroState from 'app/components/shared/ZeroState/ZeroState';
import { SystemSettings as TSystemSettings } from 'app/helpers/types';
import { DataFormatType } from 'app/components/shared/DSTable/DSTableTypes';

type SystemTableData = {
  'Directory Operation': string;
  'Non-Sliced': JSX.Element;
  'Slice 1': string;
  'Slice 2': string;
  'Can Be Disabled': JSX.Element;
};

const SystemSettings = ({ settings }: { settings: TSystemSettings }) => {
  const [tableData, setTableData] = useState<SystemTableData[]>([]);

  const formatSystemData = (data: TSystemSettings) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const obj = data.directoryOperations!;

    setTableData(
      Object.keys(obj).map((key) => ({
        'Directory Operation': key,
        'Non-Sliced': <Checkbox disabled checked={obj[`${key}`]} />,
        'Slice 1': '',
        'Slice 2': '',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        'Can Be Disabled': <Checkbox disabled checked={data.directoryOperationsAllowedToDisable![`${key}`]} />,
      })),
    );
  };

  useEffect(() => {
    formatSystemData(settings);
  }, [settings]);

  return (
    <div className="System">
      <h4 className="system-func-level">
        SystemFunctionalLevel:
        {settings.systemFunctionalLevel}
      </h4>
      <div className="note">
        NOTE: Wherever a slice-specific setting is not defined (checkboxes are missing),
        the Non-Sliced setting applies.
      </div>
      {tableData.length ? (
        <div className="settings-table">
          <DSTable
            compact
            striped
            rowSelectable={false}
            tableData={tableData}
            dataFormatType={DataFormatType.SystemSettings}
          />
        </div>
      ) : <ZeroState text="No Settings found" />}
    </div>
  );
};

export default SystemSettings;
