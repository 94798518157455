import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { History } from 'history';
import auth from 'app/reducers/auth';
import announcements from './announcements';
import companies from './companies';
import tabs from './tabs';
import preferences from './preferences';
import system from './system';
import partition from './partition';
import activeTab from './activeTab';
import objectLinks from './objectLinks';
import enums from './enums';
import appPermissions from './appPermissions';
import keyGroups from './keyGroups';
import replicationMetadata from './replicationMetadata';

export default (history: History) => combineReducers({
  companies,
  announcements,
  auth,
  tabs,
  activeTabs: activeTab,
  partition,
  preferences,
  system,
  objectLinks,
  appPermissions,
  keyGroups,
  replicationMetadata,
  enums,
  router: connectRouter(history),
});
