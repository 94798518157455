import {
  IDSObject,
  IDSObjectDirectoryProperty,
  IDSObjectMetadataProperty,
  IPagedResponseOfDSObjectOf,
  IResponseOfCompanyLicenses,
  IResponseOfDSObject,
} from 'app/proxyClients';
import { FormattedSearchData } from 'app/typings';
import _ from 'lodash';

export function isErrorResponse(
  maybeError: unknown,
): maybeError is ErrorResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(<any>maybeError).ExceptionMessage;
}

export type DirectoryProperty =
  | IDSObjectMetadataProperty
  | IDSObjectDirectoryProperty;

export type DirectoryResult = IDSObject;

export type DirectoryResponse = IResponseOfDSObject;

export type PagedResponse = IPagedResponseOfDSObjectOf;

export type LicenseResponse = IResponseOfCompanyLicenses;

export type ErrorResponse = {
  ExceptionMessage: string;
  InnerException: { InnerException: { ExceptionMessage: string } };
};

export type LicenseResults = {
  params?: Record<string, unknown> & {
    searchType: string;
  };
  searchData?: LicenseResponse;
};

export type SearchData = PagedResponse;

export const formatLicenseData = (
  results: LicenseResults | undefined,
): FormattedSearchData[] => {
  if (
    results === undefined
    || results.searchData === undefined
    || results.searchData.data === undefined
    || results.searchData.data.stockKeepingUnits === undefined
  ) {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const accountName = Object.values(results.searchData.data.accounts!)[0] || '';
  const licenses = results.searchData.data.licenses !== undefined
    ? results.searchData.data.licenses
    : [];

  return Object.entries(results.searchData.data.stockKeepingUnits).map(([skuId, skuName]) => {
    const metadata: unknown[][] = [];
    const sku = [['Sku', skuId]];
    const license = _.find(licenses, (o) => o.skuId === skuId);
    metadata.push(
      ['ConsumedUnits', license?.consumedUnits],
      ['LockedOut', license?.prepaidUnitsDetail?.lockedOut],
      ['Suspended', license?.prepaidUnitsDetail?.suspended],
      ['Warned', license?.prepaidUnitsDetail?.warned],
      ['Enabled', license?.prepaidUnitsDetail?.enabled],
    );
    const data = _.fromPairs(_.union(sku, metadata));
    const sp = { searchParams: results.params };

    data.ContextId = results.searchData?.contextId;

    return _.assign(
      { DisplayName: `${accountName}:${skuName}` },
      data,
      sp,
    ) as FormattedSearchData;
  });
};
