import { SearchFilterOperator } from 'app/proxyClients';
import type { StrictDropdownProps, DropdownItemProps } from 'semantic-ui-react';

export type PossibleCategories =
  | keyof ICompanyObjectsCategories
  | keyof ISystemObjectsCategories;

export type TProperties =
  | ({
    Account: DropdownItemProps[];
    '': DropdownItemProps[];
  } & Partial<Record<PossibleCategories, DropdownItemProps[]>>)
  | ({
    Datacenter: DropdownItemProps[];
    '': DropdownItemProps[];
  } & Partial<Record<PossibleCategories, DropdownItemProps[]>>);

export interface Selections {
  property: StrictDropdownProps['value'];
  comparisonOperator: StrictDropdownProps['value'];
  propertyValueString: unknown;
  conditionalOperator: SearchFilterOperator;
}

export interface ICompanyObjectsCategories {
  Account: string[];
  AdministrativeUnit: string[];
  Agreement: string[];
  AltSecIdTenantMap: string[];
  Application: string[];
  ApplicationStub: string[];
  AssociationInvitation: string[];
  AttributeSetEntity: string[];
  AuthorizationPolicy: string[];
  CertBasedAuthConfiguration: string[];
  CertBasedConfiguration: string[];
  CollaborationSpace: string[];
  CompanyAssociation: string[];
  ComplexExtensionDefinition: string[];
  CustomSecurityAttribute: string[];
  Contact: string[];
  Contract: string[];
  Device: string[];
  DeviceConfiguration: string[];
  DeviceTemplate: string[];
  EvoStsConfiguration: string[];
  ExternalUserProfile: string[];
  FederatedIdentityIssuer: string[];
  ForeignPrincipal: string[];
  Group: string[];
  GroupProxy: string[];
  KeyGroup: string[];
  KerberosDomain: string[];
  Label: string[];
  MultiTenantOrg: string[];
  MultiTenantOrgTenant: string[];
  PartnerPolicy: string[];
  PermissionGrantPolicy: string[];
  PendingExternalUserProfile: string[];
  PLIds64BitsTenantIdMap: string[];
  Policy: string[];
  PrivateEndpoint: string[];
  PrivateLinkResource: string[];
  PublicFolder: string[];
  RemotePrincipal: string[];
  Role: string[];
  RoleDefinition: string[];
  Segment: string[];
  ServicePrincipal: string[];
  SpCreationPolicy: string[];
  SubscribedPlan: string[];
  Subscription: string[];
  TrustedRealm: string[];
  TrustedRealmStub: string[];
  User: string[];
  UserProxy: string[];
}

export interface ISystemObjectsCategories {
  Datacenter: string[];
  DataReplicationScope: string[];
  FeatureDescriptor: string[];
  ObjectSettingTemplate: string[];
  PermissionScope: string[];
  Region: string[];
  RoleDefinitionTemplate: string[];
  RoleSliceInstance: string[];
  RoleTemplate: string[];
  RolloutPlan: string[];
  Scope: string[];
  SecuredEncryptionKey: string[];
  Service: string[];
  ServicePlan: string[];
  SliceInstance: string[];
  StockKeepingUnit: string[];
  Task: string[];
  TaskSet: string[];
  ThrottlePolicy: string[];
  TrustedCertificate: string[];
  TypeConfiguration: string[];
  WorkloadDataLocation: string[];
}

export const SystemCategories: ISystemObjectsCategories = {
  Datacenter: ['SiteName'],
  DataReplicationScope: ['DataReplicationScopeName'],
  FeatureDescriptor: ['DisplayName', 'FeatureDescriptorId'],
  ObjectSettingTemplate: ['DisplayName', 'ObjectSettingTemplateId'],
  PermissionScope: ['IncludesClaimsFrom'],
  Region: ['RegionName'],
  RoleDefinitionTemplate: [
    'Builtin',
    'DisplayName',
    'IncludesPermissionsFrom',
    'RoleDisabled',
    'RoleTemplateId',
  ],
  RoleSliceInstance: ['RoleName'],
  RoleTemplate: [
    'DisplayName',
    'IncludesPermissionsFrom',
    'MailNickname',
    'RoleDisabled',
    'RoleHidden',
    'RoleTemplateId',
    'WellKnownObject',
  ],
  RolloutPlan: ['Environment', 'RolloutPlanName'],
  Scope: ['Builtin', 'DisplayName'],
  SecuredEncryptionKey: ['SecuredKeyId', 'SecuredPartitionId'],
  Service: ['ServiceType'],
  ServicePlan: ['PlanId', 'ServicePlanName', 'ServiceType'],
  SliceInstance: [
    'AuthoritativeSlice',
    'AuthorizedIdentity',
    'ServiceInstanceName',
  ],
  StockKeepingUnit: ['PartNumber', 'SkuId'],
  Task: ['DisplayName'],
  TaskSet: ['Builtin', 'DisplayName'],
  ThrottlePolicy: ['DisplayName', 'ThrottlePolicyId'],
  TrustedCertificate: ['Thumbprint'],
  TypeConfiguration: ['TypeName'],
  WorkloadDataLocation: [
    'WorkloadDataLocationCountryLetterCode',
    'WorkloadDataLocationName',
    'WorkloadDataLocationRegionName',
  ],
};

export const CompanyCategories: ICompanyObjectsCategories = {
  Account: [
    'AccountId',
    'BelongsToFirstLoginObjectSet',
    'CreatedOn',
    'DisplayName',
  ],
  AdministrativeUnit: [
    'CreatedOn',
    'DisplayName',
    'IsMemberManagementRestricted',
    'IsMembershipHidden',
    'MembershipRule',
    'MembershipRuleProcessingState',
    'MembershipType',
  ],
  Agreement: ['AgreementId', 'AgreementTypeId', 'CreatedOn', 'DisplayName'],
  AltSecIdTenantMap: ['CreatedOn'],
  Application: [
    'AppAuxiliaryId',
    'AppCategory',
    'AppCreatedDateTime',
    'AppId',
    'AppIdentifierUri',
    'ApplicationTag',
    'ApplicationTemplateId',
    'AvailableToOtherTenants',
    'DisabledByMicrosoftStatus',
    'DisplayName',
    'IsAuthorizationServiceEnabled',
    'LastRestorationTimestamp',
    'MicrosoftPolicyGroup',
    'MsaAppId',
    'PublisherDomain',
    'RecordConsentConditions',
    'SegregatedAppContextId',
    'SignInAudience',
    'SiteId',
    'SoftDeletionTimestamp',
    'UniqueName',
  ],
  ApplicationStub: [
    'AppAuxiliaryId',
    'AppId',
    'AppIdentifierUri',
    'CreatedOn',
    'MultiStepOperation',
  ],
  AssociationInvitation: [
    'AcceptedOn',
    'DisplayName',
    'InvitedByContextId',
    'InvitedContextId',
  ],
  AttributeSetEntity: ['AttributeSetName'],
  AuthorizationPolicy: ['CreatedOn', 'DisplayName', 'PolicyIdentifier'],
  CertBasedAuthConfiguration: ['CreatedOn', 'DisplayName'],
  CertBasedConfiguration: ['CreatedOn', 'DisplayName'],
  CollaborationSpace: [
    'AccountEnabled',
    'CreatedOn',
    'DisplayName',
    'Mail',
    'MailNickname',
    'ProvisioningSince',
    'UserPrincipalName',
  ],
  CompanyAssociation: ['DisplayName'],
  ComplexExtensionDefinition: [
    'CreatedOn',
    'ExtensionAttributeStatus',
    'OwnerApplicationId',
    'TargetObjectClasses',
    'UniqueDisplayName',
  ],
  CustomSecurityAttribute: [
    'AdvancedOptions',
    'AttributeIdentifier',
    'AttributeSet',
    'CustomSecurityAttributeName',
    'DataType',
    'Status',
  ],
  Contact: [
    'City',
    'CloudMSRtcIsSipEnabled',
    'CloudMSRtcOwnerUrn',
    'CloudMSRtcPolicyAssignments',
    'CloudMSRtcPool',
    'CloudSipLine',
    'Country',
    'CreatedOn',
    'Department',
    'DirSyncEnabled',
    'DisplayName',
    'FOPEConflictProxyAddress',
    'GivenName',
    'JobTitle',
    'LastDirSyncTime',
    'Mail',
    'MailNickname',
    'MigrationSourceAnchor',
    'MigrationState',
    'ProxyAddresses',
    'SipProxyAddress',
    'SourceAnchor',
    'State',
    'Surname',
  ],
  Contract: [
    'ContractId',
    'ContractType',
    'CreatedOn',
    'DisplayName',
    'ManagedTenantDomainNames',
    'TargetContextId',
  ],
  Device: [
    'AccountEnabled',
    'ApproximateLastLogonTimestamp',
    'Capabilities',
    'CompliantAppsManagementAppId',
    'CreatedOn',
    'CustomDeviceId',
    'DeviceExternalSourceName',
    'DeviceId',
    'DeviceOSType',
    'DeviceOSVersion',
    'DevicePhysicalIds',
    'DeviceSourceType',
    'DeviceTrustType',
    'DirSyncEnabled',
    'DisplayName',
    'ExchangeActiveSyncId',
    'HasOnPremisesShadow',
    'HostNames',
    'IntuneDeviceId',
    'IsCompliant',
    'IsManaged',
    'LastDirSyncTime',
    'MdmAppId',
    'OnPremiseSecurityIdentifier',
    'ProfileType',
    'SourceAnchor',
    'SystemLabel',
  ],
  DeviceConfiguration: ['CreatedOn'],
  DeviceTemplate: ['CreatedOn', 'DeviceOSType'],
  EvoStsConfiguration: ['CreatedOn'],
  ExternalUserProfile: ['DisplayName'],
  FederatedIdentityIssuer: ['CreatedOn', 'DisplayName'],
  ForeignPrincipal: [
    'Builtin',
    'CreatedOn',
    'DisplayName',
    'ForeignContextId',
    'ForeignPrincipalId',
  ],
  Group: [
    'Classification',
    'CreatedByAppId',
    'DirSyncEnabled',
    'DisplayName',
    'ExternalGroupState',
    'FOPEConflictProxyAddress',
    'GroupMembershipType',
    'GroupTags',
    'GroupType',
    'HasOnPremisesShadow',
    'InfoCatalogs',
    'IntuneGroupId',
    'IsAssignableToRole',
    'IsGroupAuthorizationPoliciesSet',
    'IsMembershipHidden',
    'IsMembershipRuleLocked',
    'IsPublic',
    'LastDirSyncTime',
    'LastRestorationTimestamp',
    'Mail',
    'MailEnabled',
    'MailNickname',
    'MembershipRule',
    'MembershipRuleProcessingState',
    'MigrationSourceAnchor',
    'MigrationState',
    'OnPremiseSecurityIdentifier',
    'ProxyAddresses',
    'RenewedDateTime',
    'ResourceBehaviorOptions',
    'ResourceProvisioningOptions',
    'SecurityEnabled',
    'SoftDeletionTimestamp',
    'SourceAnchor',
    'SourceContext',
    'TakeoverConflicts',
    'WellKnownObject',
  ],
  GroupProxy: [
    'GroupType',
    'LastRestorationTimestamp',
    'OriginalContextId',
    'OriginalGroupId',
    'SoftDeletionTimestamp',
  ],
  KeyGroup: ['CreatedOn', 'DisplayName', 'KeyGroupId'],
  KerberosDomain: [
    'DirSyncEnabled',
    'DisplayName',
    'KerberosDomainDnsName',
    'LastDirSyncTime',
    'LastRestorationTimestamp',
    'SoftDeletionTimestamp',
    'SourceAnchor',
  ],
  Label: ['DisplayName', 'LabelId'],
  MultiTenantOrg: ['DisplayName'],
  MultiTenantOrgTenant: ['DisplayName'],
  PartnerPolicy: ['PartnerIdentifier', 'LastRestorationTimestamp', 'PartnerPolicyType', 'SoftDeletionTimestamp'],
  PermissionGrantPolicy: ['DisplayName', 'PolicyIdentifier'],
  PendingExternalUserProfile: [
    'AccountEnabled',
    'City',
    'Country',
    'CreatedBy',
    'Department',
    'DisplayName',
    'IsDiscoverable',
    'JobTitle',
    'LastRestorationTimestamp',
    'OrganizationName',
    'SignInMail',
    'SignInPhoneNumber',
    'SoftDeletionTimestamp',
    'State',
    'SupervisorId',
    'UsageLocation',
  ],
  PLIds64BitsTenantIdMap: ['PrivateLinkIds64Bits'],
  Policy: [
    'CreatedOn',
    'DisplayName',
    'PolicyIdentifier',
    'PolicyType',
    'TenantDefaultPolicy',
  ],
  PrivateEndpoint: ['PrivateLinkIds'],
  PrivateLinkResource: ['DisplayName'],
  PublicFolder: [
    'CreatedOn',
    'DirSyncEnabled',
    'DisplayName',
    'FOPEConflictProxyAddress',
    'LastDirSyncTime',
    'Mail',
    'MailNickname',
    'MSExchBypassAudit',
    'MSExchMailboxAuditEnable',
    'ProxyAddresses',
    'SourceAnchor',
  ],
  RemotePrincipal: [
    'CreatedOn',
    'DisplayName',
    'RemotePrincipalId',
    'RemoteTenantId',
    'SourcedFrom',
  ],
  Role: [
    'BelongsToFirstLoginObjectSet',
    'Builtin',
    'CreatedOn',
    'DisplayName',
    'MailNickname',
    'RoleDisabled',
    'RoleTemplateId',
    'WellKnownObject',
  ],
  RoleDefinition: [
    'Builtin',
    'CreatedOn',
    'DisplayName',
    'RoleCustomId',
    'RoleDisabled',
    'RoleTemplateId',
  ],
  Segment: ['CreatedOn'],
  ServicePrincipal: [
    'AccountEnabled',
    'AlternativeNames',
    'AppCategory',
    'ApplicationTemplateId',
    'AppPrincipalId',
    'CreatedByConsetConditions',
    'CreatedOn',
    'DisplayName',
    'ManagedIdentityResourceId',
    'MicrosoftPolicyGroup',
    'MsaAppId',
    'ObjectIdHistory',
    'PreferredSingleSignOnMode',
    'PreferredTokenSigningKeyEndDateTime',
    'PublisherName',
    'ServicePrincipalName',
    'ServicePrincipalTag',
    'ServicePrincipalType',
  ],
  SpCreationPolicy: ['PolicyIdentifier'],
  SubscribedPlan: [
    'AccountId',
    'AuthorizedServiceInstance',
    'BelongsToFirstLoginObjectSet',
    'CreatedOn',
    'PlanId',
    'ServiceType',
  ],
  Subscription: [
    'AccountId',
    'BelongsToFirstLoginObjectSet',
    'CreatedOn',
    'OcpSubscriptionId',
    'SkuId',
    'SubscriptionStatus',
    'TrialSubscription',
  ],
  TrustedRealm: [
    'CreatedOn',
    'DisplayName',
    'DomainName',
    'IdPType',
    'IssuerUri',
    'StsName',
  ],
  TrustedRealmStub: ['CreatedOn', 'IssuerUri', 'MultiStepOperation'],
  User: [
    'AcceptedOn',
    'AccountEnabled',
    'AgeGroup',
    'BelongsToFirstLoginObjectSet',
    'BecServiceInstance',
    'City',
    'CloudMSRtcIsSipEnabled',
    'CloudMSRtcOwnerUrn',
    'CloudMSRtcPolicyAssignments',
    'CloudMSRtcPool',
    'CloudSipLine',
    'ConsentProvidedForMinor',
    'Country',
    'CreatedOn',
    'CreationType',
    'Department',
    'DirSyncEnabled',
    'DisplayName',
    'EmployeeId',
    'GivenName',
    'HasOnPremisesShadow',
    'InfoCatalogs',
    'InvitedAs',
    'InviteResources',
    'IsCompromised',
    'IsOrgIdReconciliationPending',
    'IsResourceAccount',
    'IsSynchronizationServiceAccount',
    'JobTitle',
    'LastDirSyncTime',
    'LastRestorationTimestamp',
    'Mail',
    'MailNickname',
    'MigrationSourceAnchor',
    'MigrationState',
    'MostRecentPreviousTenant',
    'MSExchBypassAudit',
    'MSExchMailboxAuditEnable',
    'MSExchMailboxGuid',
    'O365UserReleaseTrack',
    'OnPremiseSecurityIdentifier',
    'OnPremisesUserPrincipalName',
    'OrgIdReconciliationTimestamp',
    'OtherMail',
    'PreviousTenantIds',
    'ProxyAddresses',
    'SignInName',
    'SipProxyAddress',
    'SoftDeletionTimestamp',
    'SourceAnchor',
    'State',
    'Surname',
    'TakeoverConflicts',
    'UsageLocation',
    'UserPrincipalName',
    'UserState',
    'UserStateChangedOn',
    'UserType',
    'WindowsLiveNetId',
  ],
  UserProxy: [
    'LastRestorationTimestamp',
    'OriginalContextId',
    'OriginalUserId',
    'SoftDeletionTimestamp',
  ],
};

export function getDefaultObjectCategories(object: string) {
  switch (object) {
    case 'ApplicationStub':
    case 'AltSecIdTenantMap':
    case 'ComplexExtensionDefinition':
    case 'DeviceConfiguration':
    case 'DeviceTemplate':
    case 'EvoStsConfiguration':
    case 'Segment':
    case 'SubscribedPlan':
    case 'Subscription':
    case 'TrustedRealmStub':
      return 'CreatedOn';
    case 'GroupProxy':
      return 'GroupType';
    case 'PLIds64BitsTenantIdMap':
      return 'PrivateLinkIds64Bits';
    case 'PrivateEndpoint':
      return 'PrivateLinkIds';
    case 'UserProxy':
      return 'LastRestorationTimestamp';
    default:
      return 'DisplayName';
  }
}

const operandLabels = ['==', 'StartsWith', '>=', '<=', 'Any', 'In', 'Not'];
export const operands = [
  SearchFilterOperator.EqualsMatch,
  SearchFilterOperator.PrefixMatch,
  SearchFilterOperator.GreaterOrEqual,
  SearchFilterOperator.LessOrEqual,
  SearchFilterOperator.Any,
  SearchFilterOperator.In,
  SearchFilterOperator.Not];

export const operandDropdown = operands.map((value, index) => ({
  key: index,
  text: operandLabels[+`${index}`],
  value,
}));
