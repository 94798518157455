import auth from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import * as config from 'app/constants/config';
import * as constants from 'app/constants/constants';
import rootReducer from 'app/reducers/rootReducer';
import { State } from 'app/reducers/state';
import rootSagas from 'app/sagas/rootSagas';
import axios from 'axios';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import {
  applyMiddleware, CombinedState, createStore, Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import * as DSEClients from './proxyClients';
import { defaultActiveTabState } from './reducers/activeTab';
import { defaultAnnoucementsState } from './reducers/announcements';
import { defaultPreferencesState } from './reducers/preferences';
import { defaultEnumState } from './reducers/enums';

export type App = feathers.Application<unknown> & {
  constants: typeof constants;
  config: typeof config;
  rest: rest.HandlerResult,
  authentication: unknown,
  store?: Store<CombinedState<State>>,
  clients: {
    applications: DSEClients.ApplicationClient,
    auth: DSEClients.AuthClient,
    announcements: DSEClients.AnnouncementsClient,
    company: DSEClients.CompanyClient
    linkState: DSEClients.LinkStateClient,
    object: DSEClients.ObjectClient,
    toolbox: DSEClients.ToolboxClient,
    trustedRealm: DSEClients.TrustedRealmClient,
    search: DSEClients.SearchClient,
    service: DSEClients.ServiceClient,
    system: DSEClients.ConfigurationClient
    device: DSEClients.DeviceClient,
    preferences: DSEClients.PreferencesClient,
    indexStore: DSEClients.IndexStoreClient,
    partitions: DSEClients.PartitionClient,
    user: DSEClients.UserClient
  }
};

export const history = createBrowserHistory();
export const app:App = feathers<unknown>() as App;

app.configure(auth({
  path: '/public/auth/signin',
  jwtStrategy: 'jwt',
  storageKey: 'feathers-jwt',
  storage: window.localStorage,
}));

const defaultAppState = {
  preferences: defaultPreferencesState,
  announcements: defaultAnnoucementsState,
  activeTabs: defaultActiveTabState,
  enums: defaultEnumState,
};

export function configureApplicationObject() {
  // Add other Constants
  app.constants = { ...constants };

  // Add Config
  app.config = { ...config };

  // Connect to a different URL
  const restClient = rest(app.config.getServer());

  // Configure an AJAX library
  app.configure(restClient.axios(axios));

  if (app.config.shouldSendCredentials() === true) {
    axios.defaults.withCredentials = true;
  }

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response !== undefined && error.response.status === 401) {
        window.location.href = error.response.headers.location;
      }

      // Do something with response error
      return Promise.reject(error);
    },
  );

  // Request interceptor that will forcibly remove auth header
  // when app is not configured to use it.
  axios.interceptors.request.use((requestConfig) => {
    // Ignore requests not intended for the server
    if (!requestConfig.url?.startsWith(app.config.getServer())) {
      return requestConfig;
    }

    if (app.config.shouldSendAuthHeader() === false) {
      if (requestConfig.headers) {
        // eslint-disable-next-line no-param-reassign
        delete requestConfig.headers.Authorization;
      }
    }

    return requestConfig;
  });

  app.clients = {
    applications: new DSEClients.ApplicationClient(undefined, axios),
    auth: new DSEClients.AuthClient(undefined, axios),
    announcements: new DSEClients.AnnouncementsClient(undefined, axios),
    company: new DSEClients.CompanyClient(undefined, axios),
    linkState: new DSEClients.LinkStateClient(undefined, axios),
    object: new DSEClients.ObjectClient(undefined, axios),
    toolbox: new DSEClients.ToolboxClient(undefined, axios),
    trustedRealm: new DSEClients.TrustedRealmClient(undefined, axios),
    search: new DSEClients.SearchClient(undefined, axios),
    service: new DSEClients.ServiceClient(undefined, axios),
    system: new DSEClients.ConfigurationClient(undefined, axios),
    device: new DSEClients.DeviceClient(undefined, axios),
    preferences: new DSEClients.PreferencesClient(undefined, axios),
    indexStore: new DSEClients.IndexStoreClient(undefined, axios),
    partitions: new DSEClients.PartitionClient(undefined, axios),
    user: new DSEClients.UserClient(undefined, axios),
  };
}

export const createApplicationStore = (initialState: State = defaultAppState) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
      ),
    ),
  );

  // Set Store Reference
  app.store = store;

  rootSagas.map((saga) => sagaMiddleware.run(saga, app)); // Register all sagas
  return store;
};
