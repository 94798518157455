/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = HighTrust 1 = Admin 2 = User
*/
export enum MicrosoftOnlineDSExplorerContractDelegationType {
    HighTrust = 0,
    Admin = 1,
    User = 2
}
