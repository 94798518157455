import type { App } from 'app/store';
import _ from 'lodash';
import {
  put, takeEvery, SagaGenerator, select, call,
} from 'typed-redux-saga';
import * as PreferencesTypes from 'app/actions/preferenceTypes';
import { SagaIterator } from '@redux-saga/types';
import { CommitUserPreferencesRequested, updateUserPreferences, UpdateUserPreferencesActionRequested } from 'app/actions/preferenceActions';
import { addPreferences, getPreferences } from 'app/services/preferences';
import { BasicResponseOfUserPreferencesDataModel, IUserPreferencesDataModel, UserPreferencesDataModel } from 'app/proxyClients';
import { State } from 'app/reducers/state';
import { defaultPreferencesState } from 'app/reducers/preferences';
import { toast } from 'react-toastify';
import { generateId } from 'app/helpers/helpers';
import moment from 'moment';

const getAuthState = (state:State) => state.auth;
const getPreferencesState = (state:State) => state.preferences;

function* dispatchSavedPreferencesAsync(app: App, params:CommitUserPreferencesRequested): SagaIterator<void> {
  const preferencesState = yield* select(getPreferencesState);

  if (preferencesState.staged) {
    yield* put(updateUserPreferences(preferencesState.staged, params.includeToast));
  }
}

function* dispatchUpdatePreferencesAsync(app: App, params:UpdateUserPreferencesActionRequested): SagaIterator<void> {
  if (!params.data) {
    return;
  }

  const preferencesState = yield* select(getPreferencesState);
  const newData: IUserPreferencesDataModel = { ...preferencesState, data: { ...preferencesState.data, ...params.data }, createdAt: moment() };

  // Enumerate all keys and remove any that are
  // not part of the defaultPreferencesState object.
  _.keys(newData).forEach((key) => {
    if (!Object.prototype.hasOwnProperty.call(defaultPreferencesState, key) && newData.data !== undefined) {
      delete newData.data[`${key}`];
    }
  });

  if (app.config.shouldDisablePreferences()) {
    const preferencesDataModel = new UserPreferencesDataModel(newData);
    const mockResponseOfPreferences = new BasicResponseOfUserPreferencesDataModel({
      result: preferencesDataModel,
      startTime: moment(),
      duration: moment.duration(0),
    });

    yield* put({ type: PreferencesTypes.UPDATE_USER_PREFERENCES_COMPLETED, resp: mockResponseOfPreferences });
    return;
  }

  try {
    const resp = yield* call(addPreferences, app, newData);
    yield* put({ type: PreferencesTypes.UPDATE_USER_PREFERENCES_COMPLETED, resp });
    if (params.includeToast) {
      toast.success('User Preferences Updated', {
        pauseOnHover: true,
        toastId: generateId(),
      });
    }
  } catch (e) {
    if (e instanceof Error) {
      yield* put({ type: PreferencesTypes.UPDATE_USER_PREFERENCES_FAILED, error: e.message, resp: newData });
      // eslint-disable-next-line no-console
      console.error('Respone from server: ', e.message);
    }

    if (params.includeToast) {
      toast.error('Failed to save Preferences. Will only be available locally.', {
        pauseOnHover: true,
        toastId: generateId(),
      });
    }
  }
}

function* dispatchGetUserPreferences(app: App): SagaIterator<void> {
  const authState = yield* select(getAuthState);
  const preferencesState = yield* select(getPreferencesState);

  const preferencesDataModel = new UserPreferencesDataModel({ data: preferencesState.data, createdAt: moment() });
  preferencesDataModel.username = authState?.UserIdentity?.Name;
  const mockResponseOfPreferences = new BasicResponseOfUserPreferencesDataModel({
    result: preferencesDataModel,
    startTime: moment(),
    duration: moment.duration(0),
  });

  if (app.config.shouldDisablePreferences()) {
    yield* put({ type: PreferencesTypes.GET_USER_PREFERENCES_COMPLETED, resp: mockResponseOfPreferences });
    return;
  }

  try {
    const resp = yield* call(getPreferences, app);

    if (resp && resp.result) {
      // Set Username on Preferences
      resp.result.username = authState?.UserIdentity?.Name;
    }

    yield* put({ type: PreferencesTypes.GET_USER_PREFERENCES_COMPLETED, resp });
  } catch (e) {
    if (e instanceof Error) {
      yield* put({ type: PreferencesTypes.GET_USER_PREFERENCES_FAILED, error: e.message });
      // eslint-disable-next-line no-console
      console.error('Respone from server: ', e.message);
    }

    yield* put({ type: PreferencesTypes.GET_USER_PREFERENCES_COMPLETED, resp: mockResponseOfPreferences });
  }
}

export default function* watchAll(app: App): SagaGenerator<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>PreferencesTypes.UPDATE_USER_PREFERENCES, dispatchUpdatePreferencesAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>PreferencesTypes.GET_USER_PREFERENCES, dispatchGetUserPreferences, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>PreferencesTypes.COMMIT_PREFERENCES, dispatchSavedPreferencesAsync, app);
}
