import React, { useState, useEffect, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import moment from 'moment';

const Clock: FunctionComponent = () => {
  const preferencesState = useSelector((state: State) => state.preferences);
  const { timeUtc } = preferencesState.data;

  const getNow = () => {
    if (timeUtc) {
      return moment().utc();
    }

    return moment();
  };

  const timeFormat = 'LT z';
  const [time, setTime] = useState(getNow().format(timeFormat));

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getNow().format(timeFormat));
    }, 1000);

    return () => clearInterval(timer);
  });

  return (
    <div id="clock">{time}</div>
  );
};

export default Clock;
