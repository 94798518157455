import _ from 'lodash';
import React, { useEffect } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import './ContextMenu.scss';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { findApplicationViaIndex } from 'app/actions/indexStoreActions';
import { State } from 'app/reducers/state';
import { assertTrue, DownloadFile } from 'app/helpers/helpers';
import { TabType } from 'app/typings';
import { findServicePrincipalByAppId, getKeyGroupReference } from 'app/actions/companyActions';
import { setTabModalContent } from 'app/actions/tabActions';
import AppPermissionsModal from 'app/components/AppPermissionsModal/AppPermissionsModal';
import KeyGroupModal from 'app/components/KeyGroupModal/KeyGroupModal';
import ReplicationMetadataModal from 'app/components/ReplicationMetadataModal/ReplicationMetadataModal';
import { RMParams, RowRecord, RowRecordValues } from '../DSTable/DSTableTypes';
import TenantListModal from '../TenantListModal/TenantListModal';

export enum CustomActions {
  FindApplication,
  FindKeyGroup,
  FindServicePrincipal,
  ShowAppPermissions,
  ShowReplMetadata,
  DownloadObject,
}

interface ContextMenuProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any,
  x: number,
  y: number,
  showMenu: boolean,
  closeMenu: () => void
  onSearch: (s: string) => void
  openRow: () => void,
  disableOpenRow: boolean,
  rowEventData?: RowRecord,
  tableData: RowRecord[],
  tabId?: string,
  companyId: string | undefined,
  dispatchCustomAction: CustomActions | undefined
  rmParams?: RMParams | undefined
}

export function getRecordValues(tableData: RowRecord[], column: string) {
  const record = _.find(tableData, (o) => o.Name === column);
  if (record !== undefined && 'Value(s)' in record) {
    return record['Value(s)'] as RowRecordValues[];
  }

  return undefined;
}

export function getRecordValue(tableData: RowRecord[], column: string) {
  const records = getRecordValues(tableData, column);
  if (records !== undefined) {
    return records[0];
  }

  return undefined;
}

const ContextMenu = ({
  x,
  y,
  showMenu,
  closeMenu,
  event,
  onSearch,
  openRow,
  tableData,
  rowEventData,
  tabId,
  dispatchCustomAction,
  companyId,
  disableOpenRow = false,
  rmParams = undefined,
}: ContextMenuProps) => {
  const style = () => ({
    top: y,
    left: x,
    position: 'absolute',
    display: showMenu ? 'flex' : 'none',
    width: 'auto',
  });
  const activeTabState = useSelector((state: State) => state.activeTabs);
  const tabState = useSelector((state: State) => state.tabs);
  const dispatch = useDispatch();

  const copyVal = () => {
    const { innerText } = event.event.target;
    navigator.clipboard.writeText(innerText);
    const val = innerText > 100 ? `${innerText.substring(0, 100)}...` : innerText;
    toast.success(`Copied value: ${val}`);
    closeMenu();
  };

  const searchFromContext = () => {
    const { innerText } = event.event.target;
    onSearch(innerText > 100 ? `${innerText.substring(0, 50)}` : innerText);
    closeMenu();
  };

  const openRowFromContext = () => {
    openRow();
    closeMenu();
  };

  const dispatchContextAction = (customAction: CustomActions) => {
    const parentTab = activeTabState.tabStore.Company?.active;
    assertTrue(parentTab !== undefined);
    const currentTenantDetails = tabState?.getTab(TabType.Company, parentTab);
    assertTrue(currentTenantDetails !== undefined);
    const { contextId } = currentTenantDetails;

    let action;
    switch (customAction) {
      case CustomActions.FindApplication: {
        const appPrincipalId = getRecordValue(tableData, 'AppPrincipalId') as string;
        action = findApplicationViaIndex(appPrincipalId, parentTab, tabId as string);
        break;
      }
      case CustomActions.FindKeyGroup: {
        const objectId = getRecordValue(tableData, '_ObjectId') as string;
        const sharedKeyReference = getRecordValues(tableData, 'SharedKeyReference') as string[];
        // eslint-disable-next-line no-useless-escape
        const reg = new RegExp(/KeyGroupId=\"(\S*)\" ContextId=\"(\S*)\"/i);

        sharedKeyReference.forEach((k) => {
          // Because this is a string we'll have to parse out the KeyGroup and ContextId information.
          const match = k.match(reg);
          if (match && Array.isArray(match)) {
            dispatch(getKeyGroupReference(objectId, match[1], match[2]));
          }
        });

        action = setTabModalContent(tabId as string, TabType.Company, parentTab, {
          content: React.createElement(KeyGroupModal, {
            objectId,
            close: () => {
              dispatch(setTabModalContent(tabId as string, TabType.Company, parentTab));
            },
          }),
        });
        break;
      }
      case CustomActions.FindServicePrincipal: {
        const appId = getRecordValue(tableData, 'AppId') as string;
        action = setTabModalContent(tabId as string, TabType.Company, parentTab, {
          content: React.createElement(TenantListModal, {
            exclude: [contextId as string],
            close: (selection: string) => {
              dispatch(setTabModalContent(tabId as string, TabType.Company, parentTab));
              if (selection) {
                dispatch(findServicePrincipalByAppId(appId, selection, tabId as string, parentTab));
              }
            },
          }),
        });
        break;
      }
      case CustomActions.ShowAppPermissions: {
        const appId = getRecordValue(tableData, 'AppId') as string;
        assertTrue(companyId !== undefined);
        action = setTabModalContent(tabId as string, TabType.Company, parentTab, {
          content: React.createElement(AppPermissionsModal, {
            companyId,
            appId,
            close: () => {
              dispatch(setTabModalContent(tabId as string, TabType.Company, parentTab));
            },
          }),
        });
        break;
      }
      case CustomActions.ShowReplMetadata: {
        const p = rmParams as RMParams;
        action = setTabModalContent(tabId as string, TabType.Company, parentTab, {
          content: React.createElement(ReplicationMetadataModal, {
            p,
            close: () => {
              dispatch(setTabModalContent(tabId as string, TabType.Company, parentTab));
            },
          }),
        });
        break;
      }
      case CustomActions.DownloadObject: {
        const activeChildTab = tabState?.getTab(TabType.Company, parentTab)?.children.tabs.filter((tab) => tab.id === tabId);
        assertTrue(activeChildTab !== undefined);
        const dataForFormatting = activeChildTab[0].component?.props.dataResult.data;
        const content = JSON.stringify(dataForFormatting);
        const objectId = getRecordValue(tableData, '_ObjectId') as string;
        const objectClass = getRecordValue(tableData, '_ObjectClass') as string;
        DownloadFile(`${objectClass}-${objectId}`, content, 'application/json');
        break;
      }
      default:
        break;
    }

    if (action) {
      dispatch(action);
    }

    closeMenu();
  };

  useEffect(() => {
    if (dispatchCustomAction !== undefined) dispatchContextAction(dispatchCustomAction);
  }, [dispatchCustomAction]);

  const CustomContextMenus = [];
  const objectClassValue = getRecordValue(tableData, '_ObjectClass');

  if (rowEventData) {
    //* FIND APPLICATION
    if (objectClassValue === 'ServicePrincipal') {
      if (rowEventData.Name === 'AppPrincipalId') {
        CustomContextMenus.push((
          <Menu.Item
            key={rowEventData.Name as string}
            className="context-menu-item bordered-top"
            onClick={() => dispatchContextAction(CustomActions.FindApplication)}
          >
            <div className="icon-div"><Icon name="arrow alternate circle right outline" /></div>
            <div className="menu-item-text">Find Application</div>
          </Menu.Item>
        ));
      }
    }
    if (objectClassValue === 'Application') {
      const multiTenantApp = getRecordValue(tableData, 'AvailableToOtherTenants');

      //* FIND SERVICE PRINCIPAL
      if (rowEventData.Name === 'AppId' && multiTenantApp === 'true') {
        CustomContextMenus.push((
          <Menu.Item
            key={rowEventData.Name as string}
            className="context-menu-item bordered-top"
            onClick={() => dispatchContextAction(CustomActions.FindServicePrincipal)}
          >
            <div className="icon-div"><Icon name="arrow alternate circle right outline" /></div>
            <div className="menu-item-text">Find Service Principal</div>
          </Menu.Item>
        ));
      }

      //* SHOW APP PERMISSIONS
      if (rowEventData.Name === 'RequiredResourceAccess') {
        CustomContextMenus.push((
          <Menu.Item
            key={rowEventData.Name as string}
            className="context-menu-item bordered-top"
            onClick={() => dispatchContextAction(CustomActions.ShowAppPermissions)}
          >
            <div className="icon-div"><Icon name="arrow alternate circle right outline" /></div>
            <div className="menu-item-text">Show App Permissions</div>
          </Menu.Item>
        ));
      }
    }
  }

  return (
    <Menu vertical style={style()} className="context-menu" id="context-menu-tour">
      {!disableOpenRow ? (
        <Menu.Item className="context-menu-item bordered-bottom" onClick={() => openRowFromContext()}>
          <div className="icon-div"><Icon name="zoom" /></div>
          <div className="menu-item-text">Open row</div>
        </Menu.Item>
      ) : null}
      <Menu.Item className="context-menu-item" onClick={() => copyVal()}>
        <div className="icon-div"><Icon name="copy" /></div>
        <div className="menu-item-text">Copy value</div>
      </Menu.Item>
      <Menu.Item className="context-menu-item" onClick={() => searchFromContext()}>
        <div className="icon-div"><Icon name="find" /></div>
        <div className="menu-item-text">Find in table</div>
      </Menu.Item>
      {CustomContextMenus}
    </Menu>
  );
};

ContextMenu.defaultProps = {
  rowEventData: undefined,
  tabId: '',
  rmParams: undefined,
};

export default ContextMenu;
