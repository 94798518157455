/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDirectoryServicesAssignedPlanInitialState } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlanInitialState';
import { SystemXmlLinqXContainerAllOfFirstNode } from '../models/SystemXmlLinqXContainerAllOfFirstNode';
import { SystemXmlLinqXContainerAllOfLastNode } from '../models/SystemXmlLinqXContainerAllOfLastNode';
import { SystemXmlLinqXElement } from '../models/SystemXmlLinqXElement';
import { SystemXmlLinqXElementAllOfFirstAttribute } from '../models/SystemXmlLinqXElementAllOfFirstAttribute';
import { SystemXmlLinqXElementAllOfLastAttribute } from '../models/SystemXmlLinqXElementAllOfLastAttribute';
import { SystemXmlLinqXName } from '../models/SystemXmlLinqXName';
import { SystemXmlLinqXNodeAllOfNextNode } from '../models/SystemXmlLinqXNodeAllOfNextNode';
import { SystemXmlLinqXNodeAllOfPreviousNode } from '../models/SystemXmlLinqXNodeAllOfPreviousNode';
import { SystemXmlLinqXObjectDocument } from '../models/SystemXmlLinqXObjectDocument';
import { SystemXmlXmlNodeType } from '../models/SystemXmlXmlNodeType';
import { HttpFile } from '../http/http';

export class SystemXmlLinqXDocumentAllOfRoot {
    'baseUri'?: string;
    'document'?: SystemXmlLinqXObjectDocument | null;
    'parent'?: MicrosoftOnlineDirectoryServicesAssignedPlanInitialState | null;
    'nextNode'?: SystemXmlLinqXNodeAllOfNextNode | null;
    'previousNode'?: SystemXmlLinqXNodeAllOfPreviousNode | null;
    'firstNode'?: SystemXmlLinqXContainerAllOfFirstNode | null;
    'lastNode'?: SystemXmlLinqXContainerAllOfLastNode | null;
    'firstAttribute'?: SystemXmlLinqXElementAllOfFirstAttribute | null;
    'hasAttributes'?: boolean;
    'hasElements'?: boolean;
    'isEmpty'?: boolean;
    'lastAttribute'?: SystemXmlLinqXElementAllOfLastAttribute | null;
    'name'?: SystemXmlLinqXName;
    'nodeType'?: SystemXmlXmlNodeType;
    'value'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "baseUri",
            "baseName": "baseUri",
            "type": "string",
            "format": ""
        },
        {
            "name": "document",
            "baseName": "document",
            "type": "SystemXmlLinqXObjectDocument",
            "format": ""
        },
        {
            "name": "parent",
            "baseName": "parent",
            "type": "MicrosoftOnlineDirectoryServicesAssignedPlanInitialState",
            "format": ""
        },
        {
            "name": "nextNode",
            "baseName": "nextNode",
            "type": "SystemXmlLinqXNodeAllOfNextNode",
            "format": ""
        },
        {
            "name": "previousNode",
            "baseName": "previousNode",
            "type": "SystemXmlLinqXNodeAllOfPreviousNode",
            "format": ""
        },
        {
            "name": "firstNode",
            "baseName": "firstNode",
            "type": "SystemXmlLinqXContainerAllOfFirstNode",
            "format": ""
        },
        {
            "name": "lastNode",
            "baseName": "lastNode",
            "type": "SystemXmlLinqXContainerAllOfLastNode",
            "format": ""
        },
        {
            "name": "firstAttribute",
            "baseName": "firstAttribute",
            "type": "SystemXmlLinqXElementAllOfFirstAttribute",
            "format": ""
        },
        {
            "name": "hasAttributes",
            "baseName": "hasAttributes",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "hasElements",
            "baseName": "hasElements",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isEmpty",
            "baseName": "isEmpty",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "lastAttribute",
            "baseName": "lastAttribute",
            "type": "SystemXmlLinqXElementAllOfLastAttribute",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "SystemXmlLinqXName",
            "format": ""
        },
        {
            "name": "nodeType",
            "baseName": "nodeType",
            "type": "SystemXmlXmlNodeType",
            "format": ""
        },
        {
            "name": "value",
            "baseName": "value",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SystemXmlLinqXDocumentAllOfRoot.attributeTypeMap;
    }

    public constructor() {
    }
}



