/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CommonAuthenticationModelAuthRequestModelUserIdentity } from '../models/CommonAuthenticationModelAuthRequestModelUserIdentity';
import { HttpFile } from '../http/http';

export class CommonAuthenticationModelAuthRequestModel {
    'accessToken'?: string | null;
    'strategy'?: string | null;
    'redirectionUrl'?: string | null;
    'issuedTime'?: Date;
    'expirationTime'?: Date;
    'userIdentity'?: CommonAuthenticationModelAuthRequestModelUserIdentity | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "accessToken",
            "baseName": "accessToken",
            "type": "string",
            "format": ""
        },
        {
            "name": "strategy",
            "baseName": "strategy",
            "type": "string",
            "format": ""
        },
        {
            "name": "redirectionUrl",
            "baseName": "redirectionUrl",
            "type": "string",
            "format": "uri"
        },
        {
            "name": "issuedTime",
            "baseName": "issuedTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "expirationTime",
            "baseName": "expirationTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "userIdentity",
            "baseName": "userIdentity",
            "type": "CommonAuthenticationModelAuthRequestModelUserIdentity",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CommonAuthenticationModelAuthRequestModel.attributeTypeMap;
    }

    public constructor() {
    }
}

