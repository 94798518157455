/* eslint-disable no-underscore-dangle */
import './KeyGroupModal.scss';
import {
  openKeyGroupObject,
  removeKeyGroupReference,
} from 'app/actions/companyActions';
import ContentModal from 'app/components/shared/ContentModal/ContentModal';
import { State } from 'app/reducers/state';
import React, {
  FunctionComponent, ReactFragment, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Icon, List, Table,
} from 'semantic-ui-react';
import X2JS from 'x2js';
import { KeyGroup } from 'app/reducers/keyGroups';
import moment, { Moment } from 'moment';
import { base64ToHex } from 'app/helpers/helpers';
import Spinner from '../shared/Spinner/Spinner';
import ZeroState from '../shared/ZeroState/ZeroState';

interface KeyGroupModalProps {
  objectId: string,
  close: () => void,
}

interface KeyDescription {
  objectId: string,
  contextId: string,
  keyGroupId: string,
  _KeyIdentifier: string,
  _Version: number,
  _KeyType: string,
  _KeyUsage: string,
  _StartTimestamp: Moment,
  _EndTimestamp: Moment,
  _ApplicationKeyIdentifier?: ReactFragment
  _IsPrimary?: string
  _DisplayName?: string,
}

const KeyGroupModal: FunctionComponent<KeyGroupModalProps> = ({
  objectId, close,
}: KeyGroupModalProps) => {
  const dispatch = useDispatch();
  const keyGroupState = useSelector((state: State) => state.keyGroups);
  const [loading, setLoading] = useState(false);
  const [keyGroups, setKeyGroups] = useState<KeyGroup[] | undefined>(undefined);
  const [toggledRows, setToggledRows] = useState<boolean[]>([]);

  const toggleRow = (i: number) => {
    const copy = [...toggledRows];
    copy[+`${i}`] = !toggledRows[+`${i}`];
    setToggledRows(copy);
  };

  const renderItemCaret = (rowId: number) => (toggledRows[+`${rowId}`]
    ? <Icon name="caret down" />
    : <Icon name="caret right" />);

  function getKeyDescriptions(): KeyDescription[] {
    const x2js = new X2JS();
    if (!keyGroups) {
      return [];
    }

    return keyGroups.flatMap((kg) => {
      if (!kg.keyGroup.directoryProperties) {
        return [];
      }

      const desc = kg.keyGroup.directoryProperties.find((p) => p.displayName === 'KeyDescription');
      if (desc == null || !Array.isArray(desc.values)) {
        return [];
      }

      return desc.values.map((v) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { KeyDescription: kd } = x2js.xml2js(v) as any;
        kd.objectId = kg.keyGroup.objectId;
        kd.contextId = kg.keyGroup.contextId;
        kd.keyGroupId = kg.keyGroupId;
        kd._Version = Number(kd._Version);
        kd._StartTimestamp = moment(kd._StartTimestamp);
        kd._EndTimestamp = moment(kd._EndTimestamp);

        if (kd._ApplicationKeyIdentifier) {
          const hexString = base64ToHex(kd._ApplicationKeyIdentifier);
          if (hexString) {
            kd._ApplicationKeyIdentifier = (
              <>
                <strong>Base64: </strong>
                {kd._ApplicationKeyIdentifier}
                <span>
                  <strong>Hex: </strong>
                  {hexString}
                </span>
              </>
            );
          }
        }

        return kd;
      });
    });
  }

  function handleOpenKeyGroup(desc: KeyDescription) {
    dispatch(openKeyGroupObject({
      objectId: desc.objectId,
      contextId: desc.contextId,
    }));
  }

  function buildKeyGroupButton(desc: KeyDescription) {
    return (
      <Button
        icon
        onClick={(e) => {
          e.stopPropagation();
          handleOpenKeyGroup(desc);
        }}
      >
        <Icon name="long arrow alternate right" />
      </Button>
    );
  }

  function buildTableContents() {
    if (loading) {
      return (
        <div className="spinner-container">
          <Spinner size="large" text="Loading..." />
        </div>
      );
    }

    if (keyGroups === undefined) {
      return <ZeroState text={`No Associated Key Groups Found for Service Principal: ${objectId}`} />;
    }

    const rows = getKeyDescriptions().map((k, index: number) => {
      const key = `${index}-${k._KeyIdentifier}`;
      return (
        <React.Fragment key={`${key}-frag`}>
          <Table.Row key={key} onClick={() => toggleRow(index)}>
            <Table.Cell>{renderItemCaret(index)}</Table.Cell>
            <Table.Cell>{k._KeyIdentifier}</Table.Cell>
            <Table.Cell>{k._KeyType}</Table.Cell>
            <Table.Cell textAlign="center">{buildKeyGroupButton(k)}</Table.Cell>
          </Table.Row>
          {
            toggledRows[+`${index}`] ? (
              <Table.Row disabled key={`${key}-expanded-row`}>
                <Table.Cell key={`${key}-cell`} colSpan="5" className="more-info">
                  <List>
                    <List.Item>
                      <List.Header>Version:</List.Header>
                      <List.Description>{k._Version}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>Key Usage:</List.Header>
                      <List.Description>{k._KeyUsage}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>Name:</List.Header>
                      <List.Description>{k._DisplayName}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>Starting:</List.Header>
                      <List.Description>{k._StartTimestamp.format()}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>Ending:</List.Header>
                      <List.Description>{k._EndTimestamp.format()}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>Is Primary:</List.Header>
                      <List.Description>{k._IsPrimary || 'N/A'}</List.Description>
                    </List.Item>
                    <List.Item>
                      <List.Header>Application Key Identifier:</List.Header>
                      <List.Description>{k._ApplicationKeyIdentifier || 'N/A'}</List.Description>
                    </List.Item>
                  </List>
                </Table.Cell>
              </Table.Row>
            ) : null
          }
        </React.Fragment>
      );
    });

    return (
      <>
        <Table striped selectable>
          <Table.Header>
            <Table.Row disabled>
              <Table.HeaderCell className="carrot-icon" />
              <Table.HeaderCell>Key Identifier</Table.HeaderCell>
              <Table.HeaderCell>Key Type</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Open Key Group</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {keyGroups.length
              ? rows
              : (<Table.Row><Table.Cell textAlign="center" colSpan="5">No Key Groups Found</Table.Cell></Table.Row>)}
          </Table.Body>
        </Table>
      </>
    );
  }

  useEffect(() => {
    let entries = keyGroupState?.getKeyGroups(objectId);
    if (entries && entries.length) {
      setToggledRows(Array(entries.length).fill(false));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      entries = entries.sort((a: any, b: any) => ((a.claimValue > b.claimValue) ? 1 : -1));
    }
    setKeyGroups(entries);
    setLoading(false);
  }, [keyGroupState]);

  useEffect(() => {
    setLoading(true);
    // Cleanup
    return () => {
      dispatch(removeKeyGroupReference(objectId));
    };
  }, []);

  return (
    <ContentModal
      className="KeyGroupModal"
      icon="key"
      style="success"
      header={`Key Group Details (${objectId})`}
      message={buildTableContents()}
      open
      closeEvent={() => close()}
    />
  );
};
export default KeyGroupModal;
