import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import XMLViewer from 'react-xml-viewer';
import { State } from 'app/reducers/state';

export type XMLViewProps = {
  xml: string,
  collapsible?: boolean,
  className?: string
};

const XMLView: FunctionComponent<XMLViewProps> = ({
  xml,
  collapsible,
  className,
}: XMLViewProps) => {
  const preferenceStates = useSelector((state: State) => state.preferences);
  const { activeTheme } = preferenceStates.data;

  // Light / Default Theme
  const customTheme = {
    attributeKeyColor: 'rgb(51 166 141)',
    attributeValueColor: 'rgb(170 60 224)',
    cdataColor: '#1D781D',
    commentColor: '#aaa',
    separatorColor: 'rgb(170 60 224)',
    tagColor: '#2185d0',
    textColor: '#333',
    overflowBreak: 'false',
  };

  if (activeTheme === 'dark') {
    customTheme.tagColor = 'rgb(107 191 255)';
    customTheme.attributeKeyColor = 'rgb(23 229 184)';
    customTheme.attributeValueColor = 'rgb(197 195 195)';
    customTheme.textColor = '#FFFFFF';
    customTheme.separatorColor = 'rgb(197 195 195)';
  }

  if (activeTheme === 'hotdog') {
    customTheme.tagColor = '#fefd0e';
    customTheme.attributeKeyColor = '#000003';
    customTheme.attributeValueColor = '#c6c3c6';
    customTheme.textColor = '#fcf1f1';
    customTheme.separatorColor = '#c6c3c6';
  }

  if (activeTheme === 'forest') {
    customTheme.tagColor = 'rgb(184 229 101)';
    customTheme.attributeKeyColor = 'rgb(94 226 127)';
    customTheme.attributeValueColor = 'rgb(197 195 195)';
    customTheme.textColor = '#FFFFFF';
    customTheme.separatorColor = 'rgb(94 226 127)';
  }

  if (activeTheme === 'synthwave') {
    customTheme.tagColor = 'rgb(14 240 255)';
    customTheme.attributeKeyColor = 'rgb(237 128 255)';
    customTheme.attributeValueColor = '#cceaff';
    customTheme.textColor = '#edebe1';
    customTheme.separatorColor = 'rgb(255 45 160)';
  }

  if (activeTheme === 'solarized-light') {
    customTheme.tagColor = '#cb4b16';
    customTheme.attributeKeyColor = '#859900';
    customTheme.attributeValueColor = '#2aa198';
    customTheme.textColor = '#586e75';
    customTheme.separatorColor = '#586e75';
  }

  if (activeTheme === 'solarized-dark') {
    customTheme.tagColor = '#6c71c4';
    customTheme.attributeKeyColor = '#268bd2';
    customTheme.attributeValueColor = '#2aa198';
    customTheme.textColor = '#93a1a1';
    customTheme.separatorColor = '#93a1a1';
  }

  return (
    <div className={`xml-viewer ${className}`}>
      <Segment>
        <XMLViewer xml={xml} theme={customTheme} collapsible={collapsible} />
      </Segment>
    </div>
  );
};

XMLView.defaultProps = {
  className: '',
  collapsible: true,
};

export default XMLView;
