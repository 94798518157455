import { PropertySearchRequestModel } from 'app/helpers/types';
import { ObjectLink } from 'app/reducers/objectLinks';
import { Action, Dispatch } from 'redux';

export type ChangeSortAction = Action<'CHANGE_SORT'> & {
  column: string
};

export type RowRecordValues = string | number | string[] | undefined | JSX.Element;
export type RowRecord = Record<string, RowRecordValues>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RefreshDSTableDelegate = (dispatch: Dispatch<any>, sortColumn?: string, sortOrder?: 'asc' | 'desc' | null | undefined) => void;

export interface DSTableProps<TRow extends RowRecord = RowRecord> {
  tableData: TRow[]
  hide?: string[],
  inverted?: boolean,
  celled?: boolean,
  singleLine?: boolean,
  striped?: boolean,
  compact?: boolean,
  fixed?: boolean,
  companyId?: string,
  rowSelectable?: boolean,
  rowClickEvent?: (row: TRow, index: TRow, i: number) => void,
  pageSize?: number,
  hasMoreData?: boolean,
  nextPage?: string,
  // getMoreDataEvent?: (scrollPosition: number) => void,
  scrollPosition?: number,
  objectLinks?: ObjectLink | undefined,
  tabId?: string,
  className?: string,
  refreshDelegate?: RefreshDSTableDelegate,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatDataDelegate?: (results: any, format: DataFormatType) => TRow[]
  dataFormatType?: DataFormatType,
  DSTableParams?: DSTableExtraParams
}

export interface DSTableExtraParams {
  sortColumn?: string,
  sortOrder?: 'asc' | 'desc' | null | undefined,
  payload?: PropertySearchRequestModel
  rmParams?: RMParams
}

export type RMParams = {
  replicaName: string,
  contextId: string,
  objectClass: number,
  contextEpoch: number,
  partitionId: number,
  objectId: string
};

export type SortState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any, // Highlighted Data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataPrestine: any, // Original Data
  direction: 'ascending' | 'descending' | undefined,
  column: string | undefined
};

export type SortAction = {
  type: 'CHANGE_SORT',
  column: string
};

export type FilterAction = {
  type: 'FILTER_TABLE',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataPrestine: any,
};

export type FilterTableAction = Action<'FILTER_TABLE'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataPrestine: any,
};

export enum DataFormatType {
  SearchData = 0,
  CompanySearchData = 1,
  PagedCompanySearchData = 2,
  SingleObject = 3,
  ReferenceSet = 4,
  ProvStatus = 5,
  PropertyBag = 6,
  License = 7,
  SystemSettings = 8,
}

export type SortReducer = React.Reducer<SortState, SortAction | FilterAction>;
