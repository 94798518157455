/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDirectoryServicesCapabilityStatus } from '../models/MicrosoftOnlineDirectoryServicesCapabilityStatus';
import { MicrosoftOnlineDirectoryServicesProvisioningStatus } from '../models/MicrosoftOnlineDirectoryServicesProvisioningStatus';
import { SystemXmlLinqXDocumentAllOfRoot } from '../models/SystemXmlLinqXDocumentAllOfRoot';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDirectoryServicesProvisionedPlan {
    'subscribedPlanId'?: string;
    'serviceInstance'?: string | null;
    'capabilityStatus'?: MicrosoftOnlineDirectoryServicesCapabilityStatus;
    'assignedTimestamp'?: Date;
    'provisioningStatus'?: MicrosoftOnlineDirectoryServicesProvisioningStatus;
    'provisionedTimestamp'?: Date;
    'receivedTimestamp'?: Date;
    'errorDetail'?: SystemXmlLinqXDocumentAllOfRoot | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "subscribedPlanId",
            "baseName": "subscribedPlanId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "serviceInstance",
            "baseName": "serviceInstance",
            "type": "string",
            "format": ""
        },
        {
            "name": "capabilityStatus",
            "baseName": "capabilityStatus",
            "type": "MicrosoftOnlineDirectoryServicesCapabilityStatus",
            "format": ""
        },
        {
            "name": "assignedTimestamp",
            "baseName": "assignedTimestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "provisioningStatus",
            "baseName": "provisioningStatus",
            "type": "MicrosoftOnlineDirectoryServicesProvisioningStatus",
            "format": ""
        },
        {
            "name": "provisionedTimestamp",
            "baseName": "provisionedTimestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "receivedTimestamp",
            "baseName": "receivedTimestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "errorDetail",
            "baseName": "errorDetail",
            "type": "SystemXmlLinqXDocumentAllOfRoot",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDirectoryServicesProvisionedPlan.attributeTypeMap;
    }

    public constructor() {
    }
}



