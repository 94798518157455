import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'

import { CommonAuthenticationModelAuthRequestModel } from '../models/CommonAuthenticationModelAuthRequestModel';
import { CommonAuthenticationModelAuthRequestModelUserIdentity } from '../models/CommonAuthenticationModelAuthRequestModelUserIdentity';
import { CommonAuthenticationModelUserIdentity } from '../models/CommonAuthenticationModelUserIdentity';
import { CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult';
import { CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString } from '../models/CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString';
import { CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString } from '../models/CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString';
import { CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonModelsCompanyContextObjectClasses } from '../models/CommonModelsCompanyContextObjectClasses';
import { CommonModelsDirSyncSupportedDirectoryProperties } from '../models/CommonModelsDirSyncSupportedDirectoryProperties';
import { CommonModelsDirectoryLinkType } from '../models/CommonModelsDirectoryLinkType';
import { CommonModelsDirectoryObjectTreeNodeModel } from '../models/CommonModelsDirectoryObjectTreeNodeModel';
import { CommonModelsEntitlementGrantValidSourceObjectClasses } from '../models/CommonModelsEntitlementGrantValidSourceObjectClasses';
import { CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask } from '../models/CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask';
import { CommonModelsGenericTreeNodeItemModel } from '../models/CommonModelsGenericTreeNodeItemModel';
import { CommonModelsRequiredApplicationOauth2Permissions } from '../models/CommonModelsRequiredApplicationOauth2Permissions';
import { CommonModelsRoleAssignmentValidTargetObjectClasses } from '../models/CommonModelsRoleAssignmentValidTargetObjectClasses';
import { CommonModelsScopedMemberValidTargetObjectClasses } from '../models/CommonModelsScopedMemberValidTargetObjectClasses';
import { CommonModelsSystemContextObjectClasses } from '../models/CommonModelsSystemContextObjectClasses';
import { CommonModelsTreeNodeModel } from '../models/CommonModelsTreeNodeModel';
import { DSExplorerDataModelsAnnouncementTrackingDataModel } from '../models/DSExplorerDataModelsAnnouncementTrackingDataModel';
import { DSExplorerDataModelsBaseCosmosDataEntry } from '../models/DSExplorerDataModelsBaseCosmosDataEntry';
import { DSExplorerDataModelsBrowsedObjectSearchModel } from '../models/DSExplorerDataModelsBrowsedObjectSearchModel';
import { DSExplorerDataModelsLinkStateCollectionDataModel } from '../models/DSExplorerDataModelsLinkStateCollectionDataModel';
import { DSExplorerDataModelsLinkStateDataModel } from '../models/DSExplorerDataModelsLinkStateDataModel';
import { DSExplorerDataModelsStatusMessageDataModel } from '../models/DSExplorerDataModelsStatusMessageDataModel';
import { DSExplorerDataModelsUserPreferencesDataModel } from '../models/DSExplorerDataModelsUserPreferencesDataModel';
import { DSExplorerDataStatusMessageType } from '../models/DSExplorerDataStatusMessageType';
import { DSExplorerWebHostContractsFilterStringEnum } from '../models/DSExplorerWebHostContractsFilterStringEnum';
import { DSExplorerWebHostModelsBase64Model } from '../models/DSExplorerWebHostModelsBase64Model';
import { DSExplorerWebHostModelsBasePropertySearchRequestModel } from '../models/DSExplorerWebHostModelsBasePropertySearchRequestModel';
import { DSExplorerWebHostModelsBulkLinkSearchRequestModel } from '../models/DSExplorerWebHostModelsBulkLinkSearchRequestModel';
import { DSExplorerWebHostModelsBulkSearchRequestModel } from '../models/DSExplorerWebHostModelsBulkSearchRequestModel';
import { DSExplorerWebHostModelsDateTimeModel } from '../models/DSExplorerWebHostModelsDateTimeModel';
import { DSExplorerWebHostModelsGuidModel } from '../models/DSExplorerWebHostModelsGuidModel';
import { DSExplorerWebHostModelsHexModel } from '../models/DSExplorerWebHostModelsHexModel';
import { DSExplorerWebHostModelsNetIdModel } from '../models/DSExplorerWebHostModelsNetIdModel';
import { DSExplorerWebHostModelsPropertyFilterModel } from '../models/DSExplorerWebHostModelsPropertyFilterModel';
import { DSExplorerWebHostModelsPropertySearchRequestModel } from '../models/DSExplorerWebHostModelsPropertySearchRequestModel';
import { DSExplorerWebHostModelsSidModel } from '../models/DSExplorerWebHostModelsSidModel';
import { MicrosoftOnlineDSExplorerContractAltSecIdType } from '../models/MicrosoftOnlineDSExplorerContractAltSecIdType';
import { MicrosoftOnlineDSExplorerContractBinaryDSReference } from '../models/MicrosoftOnlineDSExplorerContractBinaryDSReference';
import { MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet } from '../models/MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet';
import { MicrosoftOnlineDSExplorerContractCompanyLicenses } from '../models/MicrosoftOnlineDSExplorerContractCompanyLicenses';
import { MicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty';
import { MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty';
import { MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority';
import { MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty';
import { MicrosoftOnlineDSExplorerContractDSPartitionDetails } from '../models/MicrosoftOnlineDSExplorerContractDSPartitionDetails';
import { MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription } from '../models/MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription';
import { MicrosoftOnlineDSExplorerContractDSReference } from '../models/MicrosoftOnlineDSExplorerContractDSReference';
import { MicrosoftOnlineDSExplorerContractDSReferenceSet } from '../models/MicrosoftOnlineDSExplorerContractDSReferenceSet';
import { MicrosoftOnlineDSExplorerContractDelegationType } from '../models/MicrosoftOnlineDSExplorerContractDelegationType';
import { MicrosoftOnlineDSExplorerContractDirectoryPropertyBase } from '../models/MicrosoftOnlineDSExplorerContractDirectoryPropertyBase';
import { MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType } from '../models/MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType';
import { MicrosoftOnlineDSExplorerContractLicenseDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseDetail';
import { MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail';
import { MicrosoftOnlineDSExplorerContractLicenseUnitsDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseUnitsDetail';
import { MicrosoftOnlineDSExplorerContractMetadataProperty } from '../models/MicrosoftOnlineDSExplorerContractMetadataProperty';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractProvisioningStatusDetail } from '../models/MicrosoftOnlineDSExplorerContractProvisioningStatusDetail';
import { MicrosoftOnlineDSExplorerContractResponseBase } from '../models/MicrosoftOnlineDSExplorerContractResponseBase';
import { MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray';
import { MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan';
import { MicrosoftOnlineDSExplorerContractSubscriptionDetail } from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetail';
import { MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus } from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus';
import { MicrosoftOnlineDSExplorerContractSystemSettings } from '../models/MicrosoftOnlineDSExplorerContractSystemSettings';
import { MicrosoftOnlineDSExplorerContractUserIdentifierType } from '../models/MicrosoftOnlineDSExplorerContractUserIdentifierType';
import { MicrosoftOnlineDirectoryServicesAssignedPlan } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlan';
import { MicrosoftOnlineDirectoryServicesAssignedPlanInitialState } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlanInitialState';
import { MicrosoftOnlineDirectoryServicesAuthorizationScopeType } from '../models/MicrosoftOnlineDirectoryServicesAuthorizationScopeType';
import { MicrosoftOnlineDirectoryServicesCapabilityStatus } from '../models/MicrosoftOnlineDirectoryServicesCapabilityStatus';
import { MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory } from '../models/MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory';
import { MicrosoftOnlineDirectoryServicesDirSyncState } from '../models/MicrosoftOnlineDirectoryServicesDirSyncState';
import { MicrosoftOnlineDirectoryServicesDirectoryAuthority } from '../models/MicrosoftOnlineDirectoryServicesDirectoryAuthority';
import { MicrosoftOnlineDirectoryServicesDirectoryLinkClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryLinkClass';
import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
import { MicrosoftOnlineDirectoryServicesDirectoryProperty } from '../models/MicrosoftOnlineDirectoryServicesDirectoryProperty';
import { MicrosoftOnlineDirectoryServicesDirectoryReferenceClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryReferenceClass';
import { MicrosoftOnlineDirectoryServicesProvisionedPlan } from '../models/MicrosoftOnlineDirectoryServicesProvisionedPlan';
import { MicrosoftOnlineDirectoryServicesProvisioningStatus } from '../models/MicrosoftOnlineDirectoryServicesProvisioningStatus';
import { MicrosoftOnlineDirectoryServicesSearchFilterOperator } from '../models/MicrosoftOnlineDirectoryServicesSearchFilterOperator';
import { MicrosoftOnlineDirectoryServicesServicePlanProperties } from '../models/MicrosoftOnlineDirectoryServicesServicePlanProperties';
import { MicrosoftOnlineDirectoryServicesSubscriptionStatus } from '../models/MicrosoftOnlineDirectoryServicesSubscriptionStatus';
import { PartitionGetCompaniesByPartitionDirSyncStateFilterParameter } from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameter';
import { PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf } from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf';
import { SearchDelegationEntriesDelegationTypeParameter } from '../models/SearchDelegationEntriesDelegationTypeParameter';
import { SearchDelegationEntriesDelegationTypeParameterOneOf } from '../models/SearchDelegationEntriesDelegationTypeParameterOneOf';
import { SearchDelegationEntriesUserIdentifierTypeParameter } from '../models/SearchDelegationEntriesUserIdentifierTypeParameter';
import { SearchDelegationEntriesUserIdentifierTypeParameterOneOf } from '../models/SearchDelegationEntriesUserIdentifierTypeParameterOneOf';
import { SearchDirSyncProvisioningErrorsErrorCategoryParameter } from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameter';
import { SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf } from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf';
import { SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter } from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter';
import { SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf } from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf';
import { SearchEntitlementGrantsSourceObjectClassParameter } from '../models/SearchEntitlementGrantsSourceObjectClassParameter';
import { SearchRoleAssignmentsAuthorizationScopeTypeParameter } from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameter';
import { SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf } from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf';
import { SearchRoleAssignmentsTargetObjectClassParameter } from '../models/SearchRoleAssignmentsTargetObjectClassParameter';
import { SearchRoleAssignmentsTargetObjectClassParameterOneOf } from '../models/SearchRoleAssignmentsTargetObjectClassParameterOneOf';
import { SearchScopedMembersTargetObjectClassParameter } from '../models/SearchScopedMembersTargetObjectClassParameter';
import { SearchScopedMembersTargetObjectClassParameterOneOf } from '../models/SearchScopedMembersTargetObjectClassParameterOneOf';
import { SearchServicePrincipalsFilterTypeParameter } from '../models/SearchServicePrincipalsFilterTypeParameter';
import { SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString } from '../models/SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString';
import { SystemXmlLinqXAttribute } from '../models/SystemXmlLinqXAttribute';
import { SystemXmlLinqXAttributeAllOfNextAttribute } from '../models/SystemXmlLinqXAttributeAllOfNextAttribute';
import { SystemXmlLinqXAttributeAllOfPreviousAttribute } from '../models/SystemXmlLinqXAttributeAllOfPreviousAttribute';
import { SystemXmlLinqXContainer } from '../models/SystemXmlLinqXContainer';
import { SystemXmlLinqXContainerAllOfFirstNode } from '../models/SystemXmlLinqXContainerAllOfFirstNode';
import { SystemXmlLinqXContainerAllOfLastNode } from '../models/SystemXmlLinqXContainerAllOfLastNode';
import { SystemXmlLinqXDeclaration } from '../models/SystemXmlLinqXDeclaration';
import { SystemXmlLinqXDocument } from '../models/SystemXmlLinqXDocument';
import { SystemXmlLinqXDocumentAllOfDeclaration } from '../models/SystemXmlLinqXDocumentAllOfDeclaration';
import { SystemXmlLinqXDocumentAllOfDocumentType } from '../models/SystemXmlLinqXDocumentAllOfDocumentType';
import { SystemXmlLinqXDocumentAllOfRoot } from '../models/SystemXmlLinqXDocumentAllOfRoot';
import { SystemXmlLinqXDocumentType } from '../models/SystemXmlLinqXDocumentType';
import { SystemXmlLinqXElement } from '../models/SystemXmlLinqXElement';
import { SystemXmlLinqXElementAllOfFirstAttribute } from '../models/SystemXmlLinqXElementAllOfFirstAttribute';
import { SystemXmlLinqXElementAllOfLastAttribute } from '../models/SystemXmlLinqXElementAllOfLastAttribute';
import { SystemXmlLinqXName } from '../models/SystemXmlLinqXName';
import { SystemXmlLinqXNamespace } from '../models/SystemXmlLinqXNamespace';
import { SystemXmlLinqXNode } from '../models/SystemXmlLinqXNode';
import { SystemXmlLinqXNodeAllOfNextNode } from '../models/SystemXmlLinqXNodeAllOfNextNode';
import { SystemXmlLinqXNodeAllOfPreviousNode } from '../models/SystemXmlLinqXNodeAllOfPreviousNode';
import { SystemXmlLinqXObject } from '../models/SystemXmlLinqXObject';
import { SystemXmlLinqXObjectDocument } from '../models/SystemXmlLinqXObjectDocument';
import { SystemXmlXmlNodeType } from '../models/SystemXmlXmlNodeType';
import { ObservableAnnouncementsApi } from './ObservableAPI';

import { AnnouncementsApiRequestFactory, AnnouncementsApiResponseProcessor} from "../apis/AnnouncementsApi";
export class PromiseAnnouncementsApi {
    private api: ObservableAnnouncementsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AnnouncementsApiRequestFactory,
        responseProcessor?: AnnouncementsApiResponseProcessor
    ) {
        this.api = new ObservableAnnouncementsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param messageId 
     */
    public announcementsAcknowledgeWithHttpInfo(messageId?: string, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel>> {
        const result = this.api.announcementsAcknowledgeWithHttpInfo(messageId, _options);
        return result.toPromise();
    }

    /**
     * @param messageId 
     */
    public announcementsAcknowledge(messageId?: string, _options?: Configuration): Promise<CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel> {
        const result = this.api.announcementsAcknowledge(messageId, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerDataModelsStatusMessageDataModel 
     */
    public announcementsAddStatusWithHttpInfo(dSExplorerDataModelsStatusMessageDataModel: DSExplorerDataModelsStatusMessageDataModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel>> {
        const result = this.api.announcementsAddStatusWithHttpInfo(dSExplorerDataModelsStatusMessageDataModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerDataModelsStatusMessageDataModel 
     */
    public announcementsAddStatus(dSExplorerDataModelsStatusMessageDataModel: DSExplorerDataModelsStatusMessageDataModel, _options?: Configuration): Promise<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel> {
        const result = this.api.announcementsAddStatus(dSExplorerDataModelsStatusMessageDataModel, _options);
        return result.toPromise();
    }

    /**
     * @param includeAcknowledged 
     */
    public announcementsGetStatusWithHttpInfo(includeAcknowledged?: boolean, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray>> {
        const result = this.api.announcementsGetStatusWithHttpInfo(includeAcknowledged, _options);
        return result.toPromise();
    }

    /**
     * @param includeAcknowledged 
     */
    public announcementsGetStatus(includeAcknowledged?: boolean, _options?: Configuration): Promise<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray> {
        const result = this.api.announcementsGetStatus(includeAcknowledged, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerDataModelsStatusMessageDataModel 
     */
    public announcementsUpdateStatusMessageWithHttpInfo(dSExplorerDataModelsStatusMessageDataModel: DSExplorerDataModelsStatusMessageDataModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel>> {
        const result = this.api.announcementsUpdateStatusMessageWithHttpInfo(dSExplorerDataModelsStatusMessageDataModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerDataModelsStatusMessageDataModel 
     */
    public announcementsUpdateStatusMessage(dSExplorerDataModelsStatusMessageDataModel: DSExplorerDataModelsStatusMessageDataModel, _options?: Configuration): Promise<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel> {
        const result = this.api.announcementsUpdateStatusMessage(dSExplorerDataModelsStatusMessageDataModel, _options);
        return result.toPromise();
    }


}



import { ObservableApplicationApi } from './ObservableAPI';

import { ApplicationApiRequestFactory, ApplicationApiResponseProcessor} from "../apis/ApplicationApi";
export class PromiseApplicationApi {
    private api: ObservableApplicationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ApplicationApiRequestFactory,
        responseProcessor?: ApplicationApiResponseProcessor
    ) {
        this.api = new ObservableApplicationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param companyIdentifier 
     * @param applicationId 
     */
    public applicationGetApplicationWithHttpInfo(companyIdentifier: string, applicationId: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.applicationGetApplicationWithHttpInfo(companyIdentifier, applicationId, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param applicationId 
     */
    public applicationGetApplication(companyIdentifier: string, applicationId: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.applicationGetApplication(companyIdentifier, applicationId, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param applicationId 
     */
    public applicationGetApplicationPermissionsWithHttpInfo(companyIdentifier: string, applicationId: string, _options?: Configuration): Promise<HttpInfo<Array<CommonModelsRequiredApplicationOauth2Permissions>>> {
        const result = this.api.applicationGetApplicationPermissionsWithHttpInfo(companyIdentifier, applicationId, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param applicationId 
     */
    public applicationGetApplicationPermissions(companyIdentifier: string, applicationId: string, _options?: Configuration): Promise<Array<CommonModelsRequiredApplicationOauth2Permissions>> {
        const result = this.api.applicationGetApplicationPermissions(companyIdentifier, applicationId, _options);
        return result.toPromise();
    }


}



import { ObservableAuthApi } from './ObservableAPI';

import { AuthApiRequestFactory, AuthApiResponseProcessor} from "../apis/AuthApi";
export class PromiseAuthApi {
    private api: ObservableAuthApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthApiRequestFactory,
        responseProcessor?: AuthApiResponseProcessor
    ) {
        this.api = new ObservableAuthApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param interactiveReturn 
     */
    public authAuthenticationUrlGETWithHttpInfo(interactiveReturn?: boolean, _options?: Configuration): Promise<HttpInfo<HttpFile>> {
        const result = this.api.authAuthenticationUrlGETWithHttpInfo(interactiveReturn, _options);
        return result.toPromise();
    }

    /**
     * @param interactiveReturn 
     */
    public authAuthenticationUrlGET(interactiveReturn?: boolean, _options?: Configuration): Promise<HttpFile> {
        const result = this.api.authAuthenticationUrlGET(interactiveReturn, _options);
        return result.toPromise();
    }

    /**
     * @param commonAuthenticationModelAuthRequestModel 
     */
    public authAuthenticationUrlPOSTWithHttpInfo(commonAuthenticationModelAuthRequestModel: CommonAuthenticationModelAuthRequestModel, _options?: Configuration): Promise<HttpInfo<HttpFile>> {
        const result = this.api.authAuthenticationUrlPOSTWithHttpInfo(commonAuthenticationModelAuthRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param commonAuthenticationModelAuthRequestModel 
     */
    public authAuthenticationUrlPOST(commonAuthenticationModelAuthRequestModel: CommonAuthenticationModelAuthRequestModel, _options?: Configuration): Promise<HttpFile> {
        const result = this.api.authAuthenticationUrlPOST(commonAuthenticationModelAuthRequestModel, _options);
        return result.toPromise();
    }

    /**
     */
    public authGetAuthIdentityWithHttpInfo(_options?: Configuration): Promise<HttpInfo<HttpFile>> {
        const result = this.api.authGetAuthIdentityWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public authGetAuthIdentity(_options?: Configuration): Promise<HttpFile> {
        const result = this.api.authGetAuthIdentity(_options);
        return result.toPromise();
    }

    /**
     * @param forceRemove 
     */
    public authSignoutWithHttpInfo(forceRemove?: boolean, _options?: Configuration): Promise<HttpInfo<HttpFile>> {
        const result = this.api.authSignoutWithHttpInfo(forceRemove, _options);
        return result.toPromise();
    }

    /**
     * @param forceRemove 
     */
    public authSignout(forceRemove?: boolean, _options?: Configuration): Promise<HttpFile> {
        const result = this.api.authSignout(forceRemove, _options);
        return result.toPromise();
    }


}



import { ObservableBulkSearchApi } from './ObservableAPI';

import { BulkSearchApiRequestFactory, BulkSearchApiResponseProcessor} from "../apis/BulkSearchApi";
export class PromiseBulkSearchApi {
    private api: ObservableBulkSearchApi

    public constructor(
        configuration: Configuration,
        requestFactory?: BulkSearchApiRequestFactory,
        responseProcessor?: BulkSearchApiResponseProcessor
    ) {
        this.api = new ObservableBulkSearchApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public bulkSearchSearchWithHttpInfo(dSExplorerWebHostModelsBulkSearchRequestModel: Array<DSExplorerWebHostModelsBulkSearchRequestModel>, _options?: Configuration): Promise<HttpInfo<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.bulkSearchSearchWithHttpInfo(dSExplorerWebHostModelsBulkSearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public bulkSearchSearch(dSExplorerWebHostModelsBulkSearchRequestModel: Array<DSExplorerWebHostModelsBulkSearchRequestModel>, _options?: Configuration): Promise<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.bulkSearchSearch(dSExplorerWebHostModelsBulkSearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsBulkLinkSearchRequestModel 
     */
    public bulkSearchSearchLinksWithHttpInfo(dSExplorerWebHostModelsBulkLinkSearchRequestModel: DSExplorerWebHostModelsBulkLinkSearchRequestModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const result = this.api.bulkSearchSearchLinksWithHttpInfo(dSExplorerWebHostModelsBulkLinkSearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsBulkLinkSearchRequestModel 
     */
    public bulkSearchSearchLinks(dSExplorerWebHostModelsBulkLinkSearchRequestModel: DSExplorerWebHostModelsBulkLinkSearchRequestModel, _options?: Configuration): Promise<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        const result = this.api.bulkSearchSearchLinks(dSExplorerWebHostModelsBulkLinkSearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public bulkSearchStreamableBulkSearchWithHttpInfo(dSExplorerWebHostModelsBulkSearchRequestModel: DSExplorerWebHostModelsBulkSearchRequestModel, _options?: Configuration): Promise<HttpInfo<void>> {
        const result = this.api.bulkSearchStreamableBulkSearchWithHttpInfo(dSExplorerWebHostModelsBulkSearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public bulkSearchStreamableBulkSearch(dSExplorerWebHostModelsBulkSearchRequestModel: DSExplorerWebHostModelsBulkSearchRequestModel, _options?: Configuration): Promise<void> {
        const result = this.api.bulkSearchStreamableBulkSearch(dSExplorerWebHostModelsBulkSearchRequestModel, _options);
        return result.toPromise();
    }


}



import { ObservableCompanyApi } from './ObservableAPI';

import { CompanyApiRequestFactory, CompanyApiResponseProcessor} from "../apis/CompanyApi";
export class PromiseCompanyApi {
    private api: ObservableCompanyApi

    public constructor(
        configuration: Configuration,
        requestFactory?: CompanyApiRequestFactory,
        responseProcessor?: CompanyApiResponseProcessor
    ) {
        this.api = new ObservableCompanyApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param companyIdentifier 
     * @param contextEpoch 
     * @param partitionId 
     * @param softDeleted 
     */
    public companyGetCompanyWithHttpInfo(companyIdentifier: string, contextEpoch?: number, partitionId?: number, softDeleted?: boolean, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.companyGetCompanyWithHttpInfo(companyIdentifier, contextEpoch, partitionId, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param contextEpoch 
     * @param partitionId 
     * @param softDeleted 
     */
    public companyGetCompany(companyIdentifier: string, contextEpoch?: number, partitionId?: number, softDeleted?: boolean, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.companyGetCompany(companyIdentifier, contextEpoch, partitionId, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sku 
     */
    public companyReadCompanyLicenseWithHttpInfo(companyIdentifier: string, sku: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail>> {
        const result = this.api.companyReadCompanyLicenseWithHttpInfo(companyIdentifier, sku, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sku 
     */
    public companyReadCompanyLicense(companyIdentifier: string, sku: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail> {
        const result = this.api.companyReadCompanyLicense(companyIdentifier, sku, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     */
    public companyReadCompanyLicensesWithHttpInfo(companyIdentifier: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses>> {
        const result = this.api.companyReadCompanyLicensesWithHttpInfo(companyIdentifier, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     */
    public companyReadCompanyLicenses(companyIdentifier: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses> {
        const result = this.api.companyReadCompanyLicenses(companyIdentifier, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     */
    public companyReadCompanyProvisioningStatusDetailWithHttpInfo(companyIdentifier: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail>> {
        const result = this.api.companyReadCompanyProvisioningStatusDetailWithHttpInfo(companyIdentifier, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     */
    public companyReadCompanyProvisioningStatusDetail(companyIdentifier: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail> {
        const result = this.api.companyReadCompanyProvisioningStatusDetail(companyIdentifier, _options);
        return result.toPromise();
    }


}



import { ObservableConfigurationApi } from './ObservableAPI';

import { ConfigurationApiRequestFactory, ConfigurationApiResponseProcessor} from "../apis/ConfigurationApi";
export class PromiseConfigurationApi {
    private api: ObservableConfigurationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ConfigurationApiRequestFactory,
        responseProcessor?: ConfigurationApiResponseProcessor
    ) {
        this.api = new ObservableConfigurationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param objectClasses 
     * @param properties 
     */
    public configurationExportSystemContextWithHttpInfo(objectClasses?: Array<MicrosoftOnlineDirectoryServicesDirectoryObjectClass>, properties?: Array<MicrosoftOnlineDirectoryServicesDirectoryProperty>, _options?: Configuration): Promise<HttpInfo<HttpFile>> {
        const result = this.api.configurationExportSystemContextWithHttpInfo(objectClasses, properties, _options);
        return result.toPromise();
    }

    /**
     * @param objectClasses 
     * @param properties 
     */
    public configurationExportSystemContext(objectClasses?: Array<MicrosoftOnlineDirectoryServicesDirectoryObjectClass>, properties?: Array<MicrosoftOnlineDirectoryServicesDirectoryProperty>, _options?: Configuration): Promise<HttpFile> {
        const result = this.api.configurationExportSystemContext(objectClasses, properties, _options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     */
    public configurationGetAvailableSecondaryReplicaWithHttpInfo(partitionId: number, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.configurationGetAvailableSecondaryReplicaWithHttpInfo(partitionId, _options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     */
    public configurationGetAvailableSecondaryReplica(partitionId: number, _options?: Configuration): Promise<string> {
        const result = this.api.configurationGetAvailableSecondaryReplica(partitionId, _options);
        return result.toPromise();
    }

    /**
     */
    public configurationGetEnvironmentConfigurationWithHttpInfo(_options?: Configuration): Promise<HttpInfo<{ [key: string]: string; }>> {
        const result = this.api.configurationGetEnvironmentConfigurationWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public configurationGetEnvironmentConfiguration(_options?: Configuration): Promise<{ [key: string]: string; }> {
        const result = this.api.configurationGetEnvironmentConfiguration(_options);
        return result.toPromise();
    }

    /**
     * @param policyType 
     */
    public configurationGetLegacyAuthZPolicyWithHttpInfo(policyType: MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray>> {
        const result = this.api.configurationGetLegacyAuthZPolicyWithHttpInfo(policyType, _options);
        return result.toPromise();
    }

    /**
     * @param policyType 
     */
    public configurationGetLegacyAuthZPolicy(policyType: MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray> {
        const result = this.api.configurationGetLegacyAuthZPolicy(policyType, _options);
        return result.toPromise();
    }

    /**
     */
    public configurationGetPreAuthorizationLegacyAuthZPolicyWithHttpInfo(_options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray>> {
        const result = this.api.configurationGetPreAuthorizationLegacyAuthZPolicyWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public configurationGetPreAuthorizationLegacyAuthZPolicy(_options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray> {
        const result = this.api.configurationGetPreAuthorizationLegacyAuthZPolicy(_options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     */
    public configurationGetPrimaryReplicaServerNameWithHttpInfo(partitionId: number, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.configurationGetPrimaryReplicaServerNameWithHttpInfo(partitionId, _options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     */
    public configurationGetPrimaryReplicaServerName(partitionId: number, _options?: Configuration): Promise<string> {
        const result = this.api.configurationGetPrimaryReplicaServerName(partitionId, _options);
        return result.toPromise();
    }

    /**
     * @param partNumberFilter 
     * @param servicePlanFilter 
     */
    public configurationGetProductCatalogWithHttpInfo(partNumberFilter?: string, servicePlanFilter?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray>> {
        const result = this.api.configurationGetProductCatalogWithHttpInfo(partNumberFilter, servicePlanFilter, _options);
        return result.toPromise();
    }

    /**
     * @param partNumberFilter 
     * @param servicePlanFilter 
     */
    public configurationGetProductCatalog(partNumberFilter?: string, servicePlanFilter?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray> {
        const result = this.api.configurationGetProductCatalog(partNumberFilter, servicePlanFilter, _options);
        return result.toPromise();
    }

    /**
     * @param directoryObjectClass 
     */
    public configurationGetSystemContextCacheObjectsWithHttpInfo(directoryObjectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.configurationGetSystemContextCacheObjectsWithHttpInfo(directoryObjectClass, _options);
        return result.toPromise();
    }

    /**
     * @param directoryObjectClass 
     */
    public configurationGetSystemContextCacheObjects(directoryObjectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.configurationGetSystemContextCacheObjects(directoryObjectClass, _options);
        return result.toPromise();
    }

    /**
     * @param getCachedCopy 
     */
    public configurationGetSystemSettingsWithHttpInfo(getCachedCopy?: boolean, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings>> {
        const result = this.api.configurationGetSystemSettingsWithHttpInfo(getCachedCopy, _options);
        return result.toPromise();
    }

    /**
     * @param getCachedCopy 
     */
    public configurationGetSystemSettings(getCachedCopy?: boolean, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings> {
        const result = this.api.configurationGetSystemSettings(getCachedCopy, _options);
        return result.toPromise();
    }

    /**
     */
    public configurationGetSystemTaskListWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask>>> {
        const result = this.api.configurationGetSystemTaskListWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public configurationGetSystemTaskList(_options?: Configuration): Promise<Array<CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask>> {
        const result = this.api.configurationGetSystemTaskList(_options);
        return result.toPromise();
    }

    /**
     * @param servicePlanId 
     */
    public configurationReadServicePlanByServicePlanIdWithHttpInfo(servicePlanId: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.configurationReadServicePlanByServicePlanIdWithHttpInfo(servicePlanId, _options);
        return result.toPromise();
    }

    /**
     * @param servicePlanId 
     */
    public configurationReadServicePlanByServicePlanId(servicePlanId: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.configurationReadServicePlanByServicePlanId(servicePlanId, _options);
        return result.toPromise();
    }

    /**
     * @param servicePlanName 
     */
    public configurationReadServicePlanByServicePlanNameWithHttpInfo(servicePlanName: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.configurationReadServicePlanByServicePlanNameWithHttpInfo(servicePlanName, _options);
        return result.toPromise();
    }

    /**
     * @param servicePlanName 
     */
    public configurationReadServicePlanByServicePlanName(servicePlanName: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.configurationReadServicePlanByServicePlanName(servicePlanName, _options);
        return result.toPromise();
    }

    /**
     */
    public configurationReadServicePlansWithHttpInfo(_options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.configurationReadServicePlansWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public configurationReadServicePlans(_options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.configurationReadServicePlans(_options);
        return result.toPromise();
    }

    /**
     * @param partNumber 
     */
    public configurationReadStockKeepingUnitByPartNumberWithHttpInfo(partNumber: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.configurationReadStockKeepingUnitByPartNumberWithHttpInfo(partNumber, _options);
        return result.toPromise();
    }

    /**
     * @param partNumber 
     */
    public configurationReadStockKeepingUnitByPartNumber(partNumber: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.configurationReadStockKeepingUnitByPartNumber(partNumber, _options);
        return result.toPromise();
    }

    /**
     * @param skuId 
     */
    public configurationReadStockKeepingUnitBySkuIdWithHttpInfo(skuId: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.configurationReadStockKeepingUnitBySkuIdWithHttpInfo(skuId, _options);
        return result.toPromise();
    }

    /**
     * @param skuId 
     */
    public configurationReadStockKeepingUnitBySkuId(skuId: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.configurationReadStockKeepingUnitBySkuId(skuId, _options);
        return result.toPromise();
    }

    /**
     */
    public configurationReadStockKeepingUnitsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.configurationReadStockKeepingUnitsWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public configurationReadStockKeepingUnits(_options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.configurationReadStockKeepingUnits(_options);
        return result.toPromise();
    }


}



import { ObservableDeviceApi } from './ObservableAPI';

import { DeviceApiRequestFactory, DeviceApiResponseProcessor} from "../apis/DeviceApi";
export class PromiseDeviceApi {
    private api: ObservableDeviceApi

    public constructor(
        configuration: Configuration,
        requestFactory?: DeviceApiRequestFactory,
        responseProcessor?: DeviceApiResponseProcessor
    ) {
        this.api = new ObservableDeviceApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param companyIdentifier 
     * @param deviceId 
     */
    public deviceGetDeviceWithHttpInfo(companyIdentifier: string, deviceId: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.deviceGetDeviceWithHttpInfo(companyIdentifier, deviceId, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param deviceId 
     */
    public deviceGetDevice(companyIdentifier: string, deviceId: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.deviceGetDevice(companyIdentifier, deviceId, _options);
        return result.toPromise();
    }


}



import { ObservableIndexStoreApi } from './ObservableAPI';

import { IndexStoreApiRequestFactory, IndexStoreApiResponseProcessor} from "../apis/IndexStoreApi";
export class PromiseIndexStoreApi {
    private api: ObservableIndexStoreApi

    public constructor(
        configuration: Configuration,
        requestFactory?: IndexStoreApiRequestFactory,
        responseProcessor?: IndexStoreApiResponseProcessor
    ) {
        this.api = new ObservableIndexStoreApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param applicationId 
     */
    public indexStoreGetApplicationStubWithHttpInfo(applicationId: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.indexStoreGetApplicationStubWithHttpInfo(applicationId, _options);
        return result.toPromise();
    }

    /**
     * @param applicationId 
     */
    public indexStoreGetApplicationStub(applicationId: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.indexStoreGetApplicationStub(applicationId, _options);
        return result.toPromise();
    }

    /**
     * @param issuerUri 
     */
    public indexStoreGetTrustedRealmStubWithHttpInfo(issuerUri?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.indexStoreGetTrustedRealmStubWithHttpInfo(issuerUri, _options);
        return result.toPromise();
    }

    /**
     * @param issuerUri 
     */
    public indexStoreGetTrustedRealmStub(issuerUri?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.indexStoreGetTrustedRealmStub(issuerUri, _options);
        return result.toPromise();
    }

    /**
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public indexStoreSearchAltSecIdStoreWithHttpInfo(altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.indexStoreSearchAltSecIdStoreWithHttpInfo(altSecIdType, altSecIdKey, altSecIdProvider, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public indexStoreSearchAltSecIdStore(altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.indexStoreSearchAltSecIdStore(altSecIdType, altSecIdKey, altSecIdProvider, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }


}



import { ObservableLinkStateApi } from './ObservableAPI';

import { LinkStateApiRequestFactory, LinkStateApiResponseProcessor} from "../apis/LinkStateApi";
export class PromiseLinkStateApi {
    private api: ObservableLinkStateApi

    public constructor(
        configuration: Configuration,
        requestFactory?: LinkStateApiRequestFactory,
        responseProcessor?: LinkStateApiResponseProcessor
    ) {
        this.api = new ObservableLinkStateApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param dSExplorerDataModelsLinkStateCollectionDataModel 
     */
    public linkStateAddLinkStateWithHttpInfo(dSExplorerDataModelsLinkStateCollectionDataModel: DSExplorerDataModelsLinkStateCollectionDataModel, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.linkStateAddLinkStateWithHttpInfo(dSExplorerDataModelsLinkStateCollectionDataModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerDataModelsLinkStateCollectionDataModel 
     */
    public linkStateAddLinkState(dSExplorerDataModelsLinkStateCollectionDataModel: DSExplorerDataModelsLinkStateCollectionDataModel, _options?: Configuration): Promise<string> {
        const result = this.api.linkStateAddLinkState(dSExplorerDataModelsLinkStateCollectionDataModel, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public linkStateGetLinkStateWithHttpInfo(id: string, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel>> {
        const result = this.api.linkStateGetLinkStateWithHttpInfo(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public linkStateGetLinkState(id: string, _options?: Configuration): Promise<CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel> {
        const result = this.api.linkStateGetLinkState(id, _options);
        return result.toPromise();
    }


}



import { ObservableObjectApi } from './ObservableAPI';

import { ObjectApiRequestFactory, ObjectApiResponseProcessor} from "../apis/ObjectApi";
export class PromiseObjectApi {
    private api: ObservableObjectApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ObjectApiRequestFactory,
        responseProcessor?: ObjectApiResponseProcessor
    ) {
        this.api = new ObservableObjectApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectDownloadObjectLinksFromCompanyWithHttpInfo(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.objectDownloadObjectLinksFromCompanyWithHttpInfo(companyIdentifier, objectId, directoryLinkType, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectDownloadObjectLinksFromCompany(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.objectDownloadObjectLinksFromCompany(companyIdentifier, objectId, directoryLinkType, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetObjectWithHttpInfo(companyIdentifier: string, objectId: string, softDeleted?: boolean, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.objectGetObjectWithHttpInfo(companyIdentifier, objectId, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetObject(companyIdentifier: string, objectId: string, softDeleted?: boolean, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.objectGetObject(companyIdentifier, objectId, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetObjectFromSystemContextWithHttpInfo(objectId: string, softDeleted?: boolean, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.objectGetObjectFromSystemContextWithHttpInfo(objectId, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetObjectFromSystemContext(objectId: string, softDeleted?: boolean, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.objectGetObjectFromSystemContext(objectId, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectGetObjectLinksFromCompanyWithHttpInfo(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.objectGetObjectLinksFromCompanyWithHttpInfo(companyIdentifier, objectId, directoryLinkType, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectGetObjectLinksFromCompany(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.objectGetObjectLinksFromCompany(companyIdentifier, objectId, directoryLinkType, _options);
        return result.toPromise();
    }

    /**
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectGetObjectLinksFromSytemContextWithHttpInfo(objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.objectGetObjectLinksFromSytemContextWithHttpInfo(objectId, directoryLinkType, _options);
        return result.toPromise();
    }

    /**
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectGetObjectLinksFromSytemContext(objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Promise<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.objectGetObjectLinksFromSytemContext(objectId, directoryLinkType, _options);
        return result.toPromise();
    }

    /**
     * @param replicaName 
     * @param objectClass 
     * @param contextId 
     * @param contextEpoch 
     * @param partitionId 
     * @param readOnly 
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetReplicationMetadataWithHttpInfo(replicaName: string, objectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, contextId: string, contextEpoch: number, partitionId: number, readOnly?: boolean, objectId?: string, softDeleted?: boolean, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.objectGetReplicationMetadataWithHttpInfo(replicaName, objectClass, contextId, contextEpoch, partitionId, readOnly, objectId, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param replicaName 
     * @param objectClass 
     * @param contextId 
     * @param contextEpoch 
     * @param partitionId 
     * @param readOnly 
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetReplicationMetadata(replicaName: string, objectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, contextId: string, contextEpoch: number, partitionId: number, readOnly?: boolean, objectId?: string, softDeleted?: boolean, _options?: Configuration): Promise<string> {
        const result = this.api.objectGetReplicationMetadata(replicaName, objectClass, contextId, contextEpoch, partitionId, readOnly, objectId, softDeleted, _options);
        return result.toPromise();
    }


}



import { ObservablePartitionApi } from './ObservableAPI';

import { PartitionApiRequestFactory, PartitionApiResponseProcessor} from "../apis/PartitionApi";
export class PromisePartitionApi {
    private api: ObservablePartitionApi

    public constructor(
        configuration: Configuration,
        requestFactory?: PartitionApiRequestFactory,
        responseProcessor?: PartitionApiResponseProcessor
    ) {
        this.api = new ObservablePartitionApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param partitionId 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param contextIdsString 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public partitionGetCompaniesByPartitionWithHttpInfo(partitionId: number, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, contextIdsString?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: PartitionGetCompaniesByPartitionDirSyncStateFilterParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.partitionGetCompaniesByPartitionWithHttpInfo(partitionId, authorizedServiceInstance, authorizedServiceInstancePrefix, contextIdsString, creationTimeAfterOrAt, creationTimeBeforeOrAt, dirSyncEnabledOnly, dirSyncStateFilter, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param contextIdsString 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public partitionGetCompaniesByPartition(partitionId: number, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, contextIdsString?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: PartitionGetCompaniesByPartitionDirSyncStateFilterParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.partitionGetCompaniesByPartition(partitionId, authorizedServiceInstance, authorizedServiceInstancePrefix, contextIdsString, creationTimeAfterOrAt, creationTimeBeforeOrAt, dirSyncEnabledOnly, dirSyncStateFilter, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     */
    public partitionGetPartitionDetailsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray>> {
        const result = this.api.partitionGetPartitionDetailsWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public partitionGetPartitionDetails(_options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray> {
        const result = this.api.partitionGetPartitionDetails(_options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     * @param startedAtOrAfter 
     */
    public partitionGetPartitionFailoversWithHttpInfo(partitionId: number, startedAtOrAfter?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray>> {
        const result = this.api.partitionGetPartitionFailoversWithHttpInfo(partitionId, startedAtOrAfter, _options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     * @param startedAtOrAfter 
     */
    public partitionGetPartitionFailovers(partitionId: number, startedAtOrAfter?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray> {
        const result = this.api.partitionGetPartitionFailovers(partitionId, startedAtOrAfter, _options);
        return result.toPromise();
    }

    /**
     */
    public partitionGetPartitionIdsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<number>>> {
        const result = this.api.partitionGetPartitionIdsWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public partitionGetPartitionIds(_options?: Configuration): Promise<Array<number>> {
        const result = this.api.partitionGetPartitionIds(_options);
        return result.toPromise();
    }


}



import { ObservablePreferencesApi } from './ObservableAPI';

import { PreferencesApiRequestFactory, PreferencesApiResponseProcessor} from "../apis/PreferencesApi";
export class PromisePreferencesApi {
    private api: ObservablePreferencesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: PreferencesApiRequestFactory,
        responseProcessor?: PreferencesApiResponseProcessor
    ) {
        this.api = new ObservablePreferencesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param dSExplorerDataModelsUserPreferencesDataModel 
     */
    public preferencesAddPreferencesWithHttpInfo(dSExplorerDataModelsUserPreferencesDataModel: DSExplorerDataModelsUserPreferencesDataModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel>> {
        const result = this.api.preferencesAddPreferencesWithHttpInfo(dSExplorerDataModelsUserPreferencesDataModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerDataModelsUserPreferencesDataModel 
     */
    public preferencesAddPreferences(dSExplorerDataModelsUserPreferencesDataModel: DSExplorerDataModelsUserPreferencesDataModel, _options?: Configuration): Promise<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel> {
        const result = this.api.preferencesAddPreferences(dSExplorerDataModelsUserPreferencesDataModel, _options);
        return result.toPromise();
    }

    /**
     */
    public preferencesGetPreferencesWithHttpInfo(_options?: Configuration): Promise<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel>> {
        const result = this.api.preferencesGetPreferencesWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public preferencesGetPreferences(_options?: Configuration): Promise<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel> {
        const result = this.api.preferencesGetPreferences(_options);
        return result.toPromise();
    }


}



import { ObservableSearchApi } from './ObservableAPI';

import { SearchApiRequestFactory, SearchApiResponseProcessor} from "../apis/SearchApi";
export class PromiseSearchApi {
    private api: ObservableSearchApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SearchApiRequestFactory,
        responseProcessor?: SearchApiResponseProcessor
    ) {
        this.api = new ObservableSearchApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param companyIdentifier 
     * @param exchangeSingleAuthorityOnly 
     * @param proxyAddressFilterString 
     * @param sourceAnchorFilterString 
     * @param softDeleted 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchAddressListsWithHttpInfo(companyIdentifier?: string, exchangeSingleAuthorityOnly?: boolean, proxyAddressFilterString?: string, sourceAnchorFilterString?: string, softDeleted?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchAddressListsWithHttpInfo(companyIdentifier, exchangeSingleAuthorityOnly, proxyAddressFilterString, sourceAnchorFilterString, softDeleted, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param exchangeSingleAuthorityOnly 
     * @param proxyAddressFilterString 
     * @param sourceAnchorFilterString 
     * @param softDeleted 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchAddressLists(companyIdentifier?: string, exchangeSingleAuthorityOnly?: boolean, proxyAddressFilterString?: string, sourceAnchorFilterString?: string, softDeleted?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchAddressLists(companyIdentifier, exchangeSingleAuthorityOnly, proxyAddressFilterString, sourceAnchorFilterString, softDeleted, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchApplicationStubsWithHttpInfo(companyIdentifier?: string, appIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchApplicationStubsWithHttpInfo(companyIdentifier, appIds, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchApplicationStubs(companyIdentifier?: string, appIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchApplicationStubs(companyIdentifier, appIds, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param domainInUse 
     * @param availableToOtherTenants 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchApplicationsWithHttpInfo(companyIdentifier?: string, appIds?: string, domainInUse?: string, availableToOtherTenants?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchApplicationsWithHttpInfo(companyIdentifier, appIds, domainInUse, availableToOtherTenants, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param domainInUse 
     * @param availableToOtherTenants 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchApplications(companyIdentifier?: string, appIds?: string, domainInUse?: string, availableToOtherTenants?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchApplications(companyIdentifier, appIds, domainInUse, availableToOtherTenants, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     */
    public searchBrowseObjectCountsWithHttpInfo(companyIdentifier?: string, _options?: Configuration): Promise<HttpInfo<Array<DSExplorerDataModelsBrowsedObjectSearchModel>>> {
        const result = this.api.searchBrowseObjectCountsWithHttpInfo(companyIdentifier, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     */
    public searchBrowseObjectCounts(companyIdentifier?: string, _options?: Configuration): Promise<Array<DSExplorerDataModelsBrowsedObjectSearchModel>> {
        const result = this.api.searchBrowseObjectCounts(companyIdentifier, _options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     * @param contextIds 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchCompaniesWithHttpInfo(partitionId?: number, contextIds?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchCompaniesWithHttpInfo(partitionId, contextIds, creationTimeAfterOrAt, creationTimeBeforeOrAt, authorizedServiceInstance, authorizedServiceInstancePrefix, dirSyncEnabledOnly, dirSyncStateFilter, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param partitionId 
     * @param contextIds 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchCompanies(partitionId?: number, contextIds?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchCompanies(partitionId, contextIds, creationTimeAfterOrAt, creationTimeBeforeOrAt, authorizedServiceInstance, authorizedServiceInstancePrefix, dirSyncEnabledOnly, dirSyncStateFilter, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param targetContextId 
     * @param displayName 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchContractsWithHttpInfo(companyIdentifier?: string, targetContextId?: string, displayName?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchContractsWithHttpInfo(companyIdentifier, targetContextId, displayName, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param targetContextId 
     * @param displayName 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchContracts(companyIdentifier?: string, targetContextId?: string, displayName?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchContracts(companyIdentifier, targetContextId, displayName, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param alternativeSecurityIdString 
     * @param delegationType 
     * @param userIdentifierString 
     * @param userIdentifierType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDelegationEntriesWithHttpInfo(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, alternativeSecurityIdString?: string, delegationType?: SearchDelegationEntriesDelegationTypeParameter, userIdentifierString?: string, userIdentifierType?: SearchDelegationEntriesUserIdentifierTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray>> {
        const result = this.api.searchDelegationEntriesWithHttpInfo(companyIdentifier, sourceObjectId, targetObjectId, alternativeSecurityIdString, delegationType, userIdentifierString, userIdentifierType, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param alternativeSecurityIdString 
     * @param delegationType 
     * @param userIdentifierString 
     * @param userIdentifierType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDelegationEntries(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, alternativeSecurityIdString?: string, delegationType?: SearchDelegationEntriesDelegationTypeParameter, userIdentifierString?: string, userIdentifierType?: SearchDelegationEntriesUserIdentifierTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray> {
        const result = this.api.searchDelegationEntries(companyIdentifier, sourceObjectId, targetObjectId, alternativeSecurityIdString, delegationType, userIdentifierString, userIdentifierType, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryObjectClass 
     */
    public searchDeletedCompanyContextWithHttpInfo(companyIdentifier?: string, objectId?: string, directoryObjectClass?: CommonModelsCompanyContextObjectClasses, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchDeletedCompanyContextWithHttpInfo(companyIdentifier, objectId, directoryObjectClass, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryObjectClass 
     */
    public searchDeletedCompanyContext(companyIdentifier?: string, objectId?: string, directoryObjectClass?: CommonModelsCompanyContextObjectClasses, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchDeletedCompanyContext(companyIdentifier, objectId, directoryObjectClass, _options);
        return result.toPromise();
    }

    /**
     * @param objectId 
     * @param directoryObjectClass 
     */
    public searchDeletedSystemContextWithHttpInfo(objectId?: string, directoryObjectClass?: CommonModelsSystemContextObjectClasses, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchDeletedSystemContextWithHttpInfo(objectId, directoryObjectClass, _options);
        return result.toPromise();
    }

    /**
     * @param objectId 
     * @param directoryObjectClass 
     */
    public searchDeletedSystemContext(objectId?: string, directoryObjectClass?: CommonModelsSystemContextObjectClasses, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchDeletedSystemContext(objectId, directoryObjectClass, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDevicesWithHttpInfo(companyIdentifier?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchDevicesWithHttpInfo(companyIdentifier, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDevices(companyIdentifier?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchDevices(companyIdentifier, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param propertyValueFilterString 
     * @param propertyNameFilterValue 
     * @param errorCategory 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDirSyncProvisioningErrorsWithHttpInfo(companyIdentifier?: string, propertyValueFilterString?: string, propertyNameFilterValue?: SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter, errorCategory?: SearchDirSyncProvisioningErrorsErrorCategoryParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchDirSyncProvisioningErrorsWithHttpInfo(companyIdentifier, propertyValueFilterString, propertyNameFilterValue, errorCategory, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param propertyValueFilterString 
     * @param propertyNameFilterValue 
     * @param errorCategory 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDirSyncProvisioningErrors(companyIdentifier?: string, propertyValueFilterString?: string, propertyNameFilterValue?: SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter, errorCategory?: SearchDirSyncProvisioningErrorsErrorCategoryParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchDirSyncProvisioningErrors(companyIdentifier, propertyValueFilterString, propertyNameFilterValue, errorCategory, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param displayNameFilterString 
     * @param isIndirectGrants 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param sourceObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchEntitlementGrantsWithHttpInfo(companyIdentifier?: string, displayNameFilterString?: string, isIndirectGrants?: boolean, sourceObjectId?: string, targetObjectId?: string, sourceObjectClass?: SearchEntitlementGrantsSourceObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const result = this.api.searchEntitlementGrantsWithHttpInfo(companyIdentifier, displayNameFilterString, isIndirectGrants, sourceObjectId, targetObjectId, sourceObjectClass, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param displayNameFilterString 
     * @param isIndirectGrants 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param sourceObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchEntitlementGrants(companyIdentifier?: string, displayNameFilterString?: string, isIndirectGrants?: boolean, sourceObjectId?: string, targetObjectId?: string, sourceObjectClass?: SearchEntitlementGrantsSourceObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        const result = this.api.searchEntitlementGrants(companyIdentifier, displayNameFilterString, isIndirectGrants, sourceObjectId, targetObjectId, sourceObjectClass, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchGroupLicensingErrorsWithHttpInfo(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const result = this.api.searchGroupLicensingErrorsWithHttpInfo(companyIdentifier, sourceObjectId, targetObjectId, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchGroupLicensingErrors(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        const result = this.api.searchGroupLicensingErrors(companyIdentifier, sourceObjectId, targetObjectId, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param textFilter 
     * @param domainInUse 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchGroupsWithHttpInfo(companyIdentifier?: string, textFilter?: string, domainInUse?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchGroupsWithHttpInfo(companyIdentifier, textFilter, domainInUse, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param textFilter 
     * @param domainInUse 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchGroups(companyIdentifier?: string, textFilter?: string, domainInUse?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchGroups(companyIdentifier, textFilter, domainInUse, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param keyGroupIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchKeyGroupsWithHttpInfo(companyIdentifier?: string, keyGroupIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchKeyGroupsWithHttpInfo(companyIdentifier, keyGroupIds, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param keyGroupIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchKeyGroups(companyIdentifier?: string, keyGroupIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchKeyGroups(companyIdentifier, keyGroupIds, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public searchObjectsWithHttpInfo(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchObjectsWithHttpInfo(dSExplorerWebHostModelsPropertySearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public searchObjects(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchObjects(dSExplorerWebHostModelsPropertySearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public searchPagedObjectsWithHttpInfo(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchPagedObjectsWithHttpInfo(dSExplorerWebHostModelsPropertySearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public searchPagedObjects(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchPagedObjects(dSExplorerWebHostModelsPropertySearchRequestModel, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param authorizationScopeType 
     * @param authorizationScopeId 
     * @param isSelfScope 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchRoleAssignmentsWithHttpInfo(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, authorizationScopeType?: SearchRoleAssignmentsAuthorizationScopeTypeParameter, authorizationScopeId?: string, isSelfScope?: boolean, targetObjectClass?: SearchRoleAssignmentsTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const result = this.api.searchRoleAssignmentsWithHttpInfo(companyIdentifier, sourceObjectId, targetObjectId, authorizationScopeType, authorizationScopeId, isSelfScope, targetObjectClass, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param authorizationScopeType 
     * @param authorizationScopeId 
     * @param isSelfScope 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchRoleAssignments(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, authorizationScopeType?: SearchRoleAssignmentsAuthorizationScopeTypeParameter, authorizationScopeId?: string, isSelfScope?: boolean, targetObjectClass?: SearchRoleAssignmentsTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        const result = this.api.searchRoleAssignments(companyIdentifier, sourceObjectId, targetObjectId, authorizationScopeType, authorizationScopeId, isSelfScope, targetObjectClass, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param scopeId 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchScopedMembersWithHttpInfo(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, scopeId?: string, targetObjectClass?: SearchScopedMembersTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const result = this.api.searchScopedMembersWithHttpInfo(companyIdentifier, sourceObjectId, targetObjectId, scopeId, targetObjectClass, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param scopeId 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchScopedMembers(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, scopeId?: string, targetObjectClass?: SearchScopedMembersTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        const result = this.api.searchScopedMembers(companyIdentifier, sourceObjectId, targetObjectId, scopeId, targetObjectClass, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param filterString 
     * @param filterType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchServicePrincipalsWithHttpInfo(companyIdentifier?: string, filterString?: string, filterType?: SearchServicePrincipalsFilterTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchServicePrincipalsWithHttpInfo(companyIdentifier, filterString, filterType, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param filterString 
     * @param filterType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchServicePrincipals(companyIdentifier?: string, filterString?: string, filterType?: SearchServicePrincipalsFilterTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchServicePrincipals(companyIdentifier, filterString, filterType, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param skuIdToExclude 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchSubscriptionsWithHttpInfo(companyIdentifier?: string, skuIdToExclude?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchSubscriptionsWithHttpInfo(companyIdentifier, skuIdToExclude, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param skuIdToExclude 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchSubscriptions(companyIdentifier?: string, skuIdToExclude?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchSubscriptions(companyIdentifier, skuIdToExclude, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param adminsOnly 
     * @param enabled 
     * @param softDeleted 
     * @param domainsInUse 
     * @param synchronizedOnly 
     * @param textFilter 
     * @param assignedLicenseUnsetOnly 
     * @param assignedLicenseFilter 
     * @param assignedPlanFilters 
     * @param usageLocation 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchUsersWithHttpInfo(companyIdentifier?: string, adminsOnly?: boolean, enabled?: boolean, softDeleted?: boolean, domainsInUse?: string, synchronizedOnly?: boolean, textFilter?: string, assignedLicenseUnsetOnly?: boolean, assignedLicenseFilter?: string, assignedPlanFilters?: string, usageLocation?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.searchUsersWithHttpInfo(companyIdentifier, adminsOnly, enabled, softDeleted, domainsInUse, synchronizedOnly, textFilter, assignedLicenseUnsetOnly, assignedLicenseFilter, assignedPlanFilters, usageLocation, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param adminsOnly 
     * @param enabled 
     * @param softDeleted 
     * @param domainsInUse 
     * @param synchronizedOnly 
     * @param textFilter 
     * @param assignedLicenseUnsetOnly 
     * @param assignedLicenseFilter 
     * @param assignedPlanFilters 
     * @param usageLocation 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchUsers(companyIdentifier?: string, adminsOnly?: boolean, enabled?: boolean, softDeleted?: boolean, domainsInUse?: string, synchronizedOnly?: boolean, textFilter?: string, assignedLicenseUnsetOnly?: boolean, assignedLicenseFilter?: string, assignedPlanFilters?: string, usageLocation?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.searchUsers(companyIdentifier, adminsOnly, enabled, softDeleted, domainsInUse, synchronizedOnly, textFilter, assignedLicenseUnsetOnly, assignedLicenseFilter, assignedPlanFilters, usageLocation, pageSize, pageToken, replicaToken, _options);
        return result.toPromise();
    }


}



import { ObservableServiceApi } from './ObservableAPI';

import { ServiceApiRequestFactory, ServiceApiResponseProcessor} from "../apis/ServiceApi";
export class PromiseServiceApi {
    private api: ObservableServiceApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ServiceApiRequestFactory,
        responseProcessor?: ServiceApiResponseProcessor
    ) {
        this.api = new ObservableServiceApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param serviceType 
     */
    public serviceGetServiceWithHttpInfo(serviceType: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.serviceGetServiceWithHttpInfo(serviceType, _options);
        return result.toPromise();
    }

    /**
     * @param serviceType 
     */
    public serviceGetService(serviceType: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.serviceGetService(serviceType, _options);
        return result.toPromise();
    }

    /**
     * @param serviceInstanceName 
     * @param instance 
     */
    public serviceGetServiceInstanceWithHttpInfo(serviceInstanceName: string, instance: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.serviceGetServiceInstanceWithHttpInfo(serviceInstanceName, instance, _options);
        return result.toPromise();
    }

    /**
     * @param serviceInstanceName 
     * @param instance 
     */
    public serviceGetServiceInstance(serviceInstanceName: string, instance: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.serviceGetServiceInstance(serviceInstanceName, instance, _options);
        return result.toPromise();
    }

    /**
     */
    public serviceGetServiceInstancesWithHttpInfo(_options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.serviceGetServiceInstancesWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public serviceGetServiceInstances(_options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.serviceGetServiceInstances(_options);
        return result.toPromise();
    }

    /**
     */
    public serviceGetServicesWithHttpInfo(_options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const result = this.api.serviceGetServicesWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public serviceGetServices(_options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        const result = this.api.serviceGetServices(_options);
        return result.toPromise();
    }


}



import { ObservableToolboxApi } from './ObservableAPI';

import { ToolboxApiRequestFactory, ToolboxApiResponseProcessor} from "../apis/ToolboxApi";
export class PromiseToolboxApi {
    private api: ObservableToolboxApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ToolboxApiRequestFactory,
        responseProcessor?: ToolboxApiResponseProcessor
    ) {
        this.api = new ObservableToolboxApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param dSExplorerWebHostModelsBase64Model 
     */
    public toolboxBase64ConverterWithHttpInfo(dSExplorerWebHostModelsBase64Model: DSExplorerWebHostModelsBase64Model, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const result = this.api.toolboxBase64ConverterWithHttpInfo(dSExplorerWebHostModelsBase64Model, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsBase64Model 
     */
    public toolboxBase64Converter(dSExplorerWebHostModelsBase64Model: DSExplorerWebHostModelsBase64Model, _options?: Configuration): Promise<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        const result = this.api.toolboxBase64Converter(dSExplorerWebHostModelsBase64Model, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsDateTimeModel 
     */
    public toolboxDateTimeConverterWithHttpInfo(dSExplorerWebHostModelsDateTimeModel: DSExplorerWebHostModelsDateTimeModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const result = this.api.toolboxDateTimeConverterWithHttpInfo(dSExplorerWebHostModelsDateTimeModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsDateTimeModel 
     */
    public toolboxDateTimeConverter(dSExplorerWebHostModelsDateTimeModel: DSExplorerWebHostModelsDateTimeModel, _options?: Configuration): Promise<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        const result = this.api.toolboxDateTimeConverter(dSExplorerWebHostModelsDateTimeModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsGuidModel 
     */
    public toolboxGuidConverterWithHttpInfo(dSExplorerWebHostModelsGuidModel: DSExplorerWebHostModelsGuidModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const result = this.api.toolboxGuidConverterWithHttpInfo(dSExplorerWebHostModelsGuidModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsGuidModel 
     */
    public toolboxGuidConverter(dSExplorerWebHostModelsGuidModel: DSExplorerWebHostModelsGuidModel, _options?: Configuration): Promise<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        const result = this.api.toolboxGuidConverter(dSExplorerWebHostModelsGuidModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsHexModel 
     */
    public toolboxHexConverterWithHttpInfo(dSExplorerWebHostModelsHexModel: DSExplorerWebHostModelsHexModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const result = this.api.toolboxHexConverterWithHttpInfo(dSExplorerWebHostModelsHexModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsHexModel 
     */
    public toolboxHexConverter(dSExplorerWebHostModelsHexModel: DSExplorerWebHostModelsHexModel, _options?: Configuration): Promise<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        const result = this.api.toolboxHexConverter(dSExplorerWebHostModelsHexModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsNetIdModel 
     */
    public toolboxNetIdConverterWithHttpInfo(dSExplorerWebHostModelsNetIdModel: DSExplorerWebHostModelsNetIdModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const result = this.api.toolboxNetIdConverterWithHttpInfo(dSExplorerWebHostModelsNetIdModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsNetIdModel 
     */
    public toolboxNetIdConverter(dSExplorerWebHostModelsNetIdModel: DSExplorerWebHostModelsNetIdModel, _options?: Configuration): Promise<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        const result = this.api.toolboxNetIdConverter(dSExplorerWebHostModelsNetIdModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsSidModel 
     */
    public toolboxSidConverterWithHttpInfo(dSExplorerWebHostModelsSidModel: DSExplorerWebHostModelsSidModel, _options?: Configuration): Promise<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const result = this.api.toolboxSidConverterWithHttpInfo(dSExplorerWebHostModelsSidModel, _options);
        return result.toPromise();
    }

    /**
     * @param dSExplorerWebHostModelsSidModel 
     */
    public toolboxSidConverter(dSExplorerWebHostModelsSidModel: DSExplorerWebHostModelsSidModel, _options?: Configuration): Promise<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        const result = this.api.toolboxSidConverter(dSExplorerWebHostModelsSidModel, _options);
        return result.toPromise();
    }


}



import { ObservableTrustedRealmApi } from './ObservableAPI';

import { TrustedRealmApiRequestFactory, TrustedRealmApiResponseProcessor} from "../apis/TrustedRealmApi";
export class PromiseTrustedRealmApi {
    private api: ObservableTrustedRealmApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TrustedRealmApiRequestFactory,
        responseProcessor?: TrustedRealmApiResponseProcessor
    ) {
        this.api = new ObservableTrustedRealmApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param companyIdentifier 
     * @param issuerUri 
     */
    public trustedRealmGetTrustedRealmByIssuerUriWithHttpInfo(companyIdentifier: string, issuerUri: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.trustedRealmGetTrustedRealmByIssuerUriWithHttpInfo(companyIdentifier, issuerUri, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param issuerUri 
     */
    public trustedRealmGetTrustedRealmByIssuerUri(companyIdentifier: string, issuerUri: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.trustedRealmGetTrustedRealmByIssuerUri(companyIdentifier, issuerUri, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param stsName 
     */
    public trustedRealmGetTrustedRealmByNameWithHttpInfo(companyIdentifier: string, stsName: string, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.trustedRealmGetTrustedRealmByNameWithHttpInfo(companyIdentifier, stsName, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param stsName 
     */
    public trustedRealmGetTrustedRealmByName(companyIdentifier: string, stsName: string, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.trustedRealmGetTrustedRealmByName(companyIdentifier, stsName, _options);
        return result.toPromise();
    }


}



import { ObservableUserApi } from './ObservableAPI';

import { UserApiRequestFactory, UserApiResponseProcessor} from "../apis/UserApi";
export class PromiseUserApi {
    private api: ObservableUserApi

    public constructor(
        configuration: Configuration,
        requestFactory?: UserApiRequestFactory,
        responseProcessor?: UserApiResponseProcessor
    ) {
        this.api = new ObservableUserApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param companyIdentifier 
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param softDeleted 
     */
    public userGetUserByAltSecIdWithHttpInfo(companyIdentifier: string, altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, softDeleted?: boolean, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.userGetUserByAltSecIdWithHttpInfo(companyIdentifier, altSecIdType, altSecIdKey, altSecIdProvider, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param softDeleted 
     */
    public userGetUserByAltSecId(companyIdentifier: string, altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, softDeleted?: boolean, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.userGetUserByAltSecId(companyIdentifier, altSecIdType, altSecIdKey, altSecIdProvider, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param userIdentifier 
     * @param softDeleted 
     */
    public userGetUserByIdentifierWithHttpInfo(companyIdentifier: string, userIdentifier: string, softDeleted?: boolean, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.userGetUserByIdentifierWithHttpInfo(companyIdentifier, userIdentifier, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param userIdentifier 
     * @param softDeleted 
     */
    public userGetUserByIdentifier(companyIdentifier: string, userIdentifier: string, softDeleted?: boolean, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.userGetUserByIdentifier(companyIdentifier, userIdentifier, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param ticketValue 
     * @param softDeleted 
     */
    public userGetUserByInvitationTicketWithHttpInfo(companyIdentifier: string, ticketValue: string, softDeleted?: boolean, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const result = this.api.userGetUserByInvitationTicketWithHttpInfo(companyIdentifier, ticketValue, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param ticketValue 
     * @param softDeleted 
     */
    public userGetUserByInvitationTicket(companyIdentifier: string, ticketValue: string, softDeleted?: boolean, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        const result = this.api.userGetUserByInvitationTicket(companyIdentifier, ticketValue, softDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param userObjectId 
     * @param isSoftDeleted 
     */
    public userGetUserProvisioningStatusDetailWithHttpInfo(companyIdentifier: string, userObjectId: string, isSoftDeleted?: boolean, _options?: Configuration): Promise<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail>> {
        const result = this.api.userGetUserProvisioningStatusDetailWithHttpInfo(companyIdentifier, userObjectId, isSoftDeleted, _options);
        return result.toPromise();
    }

    /**
     * @param companyIdentifier 
     * @param userObjectId 
     * @param isSoftDeleted 
     */
    public userGetUserProvisioningStatusDetail(companyIdentifier: string, userObjectId: string, isSoftDeleted?: boolean, _options?: Configuration): Promise<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail> {
        const result = this.api.userGetUserProvisioningStatusDetail(companyIdentifier, userObjectId, isSoftDeleted, _options);
        return result.toPromise();
    }


}



