import SingleObject from 'app/components/Company/SingleObject';
import {
  call, takeEvery, SagaGenerator, put, all,
} from 'typed-redux-saga';

import type { App } from 'app/store';
import * as systemTypes from 'app/actions/systemTypes';
import getSystemSettings, {
  getPermissionScopes,
  getRoleTemplates,
  getService,
  getServiceInstance,
  getSingleObject,
  getSPTs,
  getSystemExport,
  getTrustedCertificates,
  getTypeConfiguration,
  getSystemTasks,
} from 'app/services/system';
import {
  createTabWithAsyncOperation, createTabWithComponent,
} from 'app/sagas/tabSagas';
import {
  GenericDictionaryParams,
  TabGeneratorEffect,
  TabType,
  TaskSearchForm,
} from 'app/typings';
import {
  promiseToSaga, DownloadBlob, generateId,
} from 'app/helpers/helpers';
import { SingleObjectForm } from 'app/components/shared/sidebar-children/SingleObjectSB/SingleObjectSB';
import { GenericSingleInputSBSearchForm } from 'app/components/shared/sidebar-children/GenericSingleInputSB/GenericSingleInputSB';
import React from 'react';
import { IResponseOfDSObject } from 'app/proxyClients';
import { GET_OBJECT_LINKS_COMPLETED, GET_OBJECT_LINKS_FAILED } from 'app/actions/objectLinkTypes';
import { logError } from 'app/constants/constants';
import { getSingleObjectLinks } from 'app/services/companies';

const getSystemTabDisplayName = (data: IResponseOfDSObject) => data.data?.displayName || 'Single Object';

function* fetchSystemSettingsAsync(app: App, params: GenericDictionaryParams): TabGeneratorEffect<void> {
  const { ...systemSettingsParams } = params;
  yield* call(createTabWithAsyncOperation, app, {
    params: { ...systemSettingsParams, data: {} },
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_SYSTEM_SETTINGS,
    actionComplete: systemTypes.GET_SYSTEM_SETTINGS_COMPLETED,
    actionFailed: systemTypes.GET_SYSTEM_SETTINGS_FAILED,
    asyncDataFetch: promiseToSaga(getSystemSettings),
    getDisplayName: () => 'Settings',
  });
}

function* fetchRoleTemplatesAsync(app: App, params: TaskSearchForm): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_ROLE_TEMPLATES,
    actionComplete: systemTypes.GET_ROLE_TEMPLATES_COMPLETED,
    actionFailed: systemTypes.GET_ROLE_TEMPLATES_FAILED,
    asyncDataFetch: promiseToSaga(getRoleTemplates),
    getDisplayName: () => 'Role Templates',
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchServiceDetails(app: App, params: any): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_SERVICE_DETAILS,
    actionComplete: systemTypes.GET_SERVICE_DETAILS_COMPLETED,
    actionFailed: systemTypes.GET_SERVICE_DETAILS_FAILED,
    asyncDataFetch: promiseToSaga(getService),
    getDisplayName: () => `${params.data}`,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchServiceInstanceDetails(app: App, params: any): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_SERVICE_INSTANCE_DETAILS,
    actionComplete: systemTypes.GET_SERVICE_INSTANCE_DETAILS_COMPLETED,
    actionFailed: systemTypes.GET_SERVICE_INSTANCE_DETAILS_FAILED,
    asyncDataFetch: promiseToSaga(getServiceInstance),
    getDisplayName: () => `${params.data}`,
  });
}

function* fetchPermissionScopes(app: App, params: TaskSearchForm): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_PERMISSION_SCOPES,
    actionComplete: systemTypes.GET_PERMISSION_SCOPES_COMPLETED,
    actionFailed: systemTypes.GET_PERMISSION_SCOPES_FAILED,
    asyncDataFetch: promiseToSaga(getPermissionScopes),
    getDisplayName: () => 'Permission Scopes',
  });
}

function* fetchServicePrincipalTemplatesAsync(app: App, params: TaskSearchForm): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_SERVICE_PRINCIPAL_TEMPLATES,
    actionComplete: systemTypes.GET_SERVICE_PRINCIPAL_TEMPLATES_COMPLETED,
    actionFailed: systemTypes.GET_SERVICE_PRINCIPAL_TEMPLATES_FAILED,
    asyncDataFetch: promiseToSaga(getSPTs),
    getDisplayName: () => 'Service Principal Templates',
  });
}

function* fetchTrustedCertificatesAsync(app: App, params: GenericSingleInputSBSearchForm): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_TRUSTED_CERTIFICATES,
    actionComplete: systemTypes.GET_TRUSTED_CERTIFICATES_COMPLETED,
    actionFailed: systemTypes.GET_TRUSTED_CERTIFICATES_FAILED,
    asyncDataFetch: promiseToSaga(getTrustedCertificates),
    getDisplayName: () => 'Trusted Certificates',
  });
}

function* fetchTypeConfigurationAsync(app: App, params: GenericSingleInputSBSearchForm): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_TYPE_CONFIGURATION,
    actionComplete: systemTypes.GET_TYPE_CONFIGURATION_COMPLETED,
    actionFailed: systemTypes.GET_TYPE_CONFIGURATION_FAILED,
    asyncDataFetch: promiseToSaga(getTypeConfiguration),
    getDisplayName: () => 'Type Configuration',
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchSystemContextExportAsync(app: App, params: any): TabGeneratorEffect<void> {
  try {
    const resp = yield* call(getSystemExport, app, params.data);
    DownloadBlob(resp?.fileName as string, resp?.data as Blob);
    yield* put({ type: systemTypes.GET_SYSTEM_CONTEXT_EXPORT_COMPLETED });
  } catch (error) {
    yield* put({ type: systemTypes.GET_SYSTEM_CONTEXT_EXPORT_FAILED });
  }
}

function* fetchSingleObjectAsync(app: App, params: SingleObjectForm): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'System',
    tabRoutingArea: TabType.System,
    actionBegin: systemTypes.GET_SYSTEM_SINGLE_OBJECT,
    actionComplete: systemTypes.GET_SYSTEM_SINGLE_OBJECT_COMPLETED,
    actionFailed: systemTypes.GET_SYSTEM_SINGLE_OBJECT_FAILED,
    asyncDataFetch: promiseToSaga(getSingleObject),
    getDisplayName: getSystemTabDisplayName,
    callback: params.callback,
    skipTabCreation: params.skipTabCreation,
    errorCallback: params.errorCallback,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchSystemSingleObjectLinksAsync(app: App, p: any): any {
  //* source=0, target=1
  try {
    const [sourceLinks, targetLinks] = yield all([
      call(getSingleObjectLinks, app, { objectId: p.data.objectId, directoryLinkType: 0 }),
      call(getSingleObjectLinks, app, { objectId: p.data.objectId, directoryLinkType: 1 }),
    ]);
    yield* put({ type: GET_OBJECT_LINKS_COMPLETED, resp: { targetLinks, sourceLinks, objectId: p.data.objectId } });
  } catch (e) {
    if (e instanceof Error) {
      yield* put({ type: GET_OBJECT_LINKS_FAILED, error: e.message });
      logError('Failed to get Single Object Links from System Context. Response from the server was: ', e.message);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchSystemTasks(app: App): any {
  try {
    const resp = yield* call(getSystemTasks, app);
    yield* put({ type: systemTypes.GET_SYSTEM_TASKS_COMPLETED, resp });
  } catch (e) {
    if (e instanceof Error) {
      yield* put({ type: systemTypes.GET_SYSTEM_TASKS_FAILED, error: e.message });
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* displayPropBag(app: App, params: any): TabGeneratorEffect<void> {
  const newId = generateId();
  let name = 'Single Object';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params.propertyBag.forEach((item: any) => {
    if (item.item1 === 'DisplayName') {
      name = item.item2.join();
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* call(createTabWithComponent as any,
    name,
    newId,
    TabType.System,
    React.createElement('div', { className: 'secondary-tabs-border' }, React.createElement(SingleObject, {
      dataResult: params.propertyBag,
      isPropBag: true,
    })),
    true);
}

export default function* watchAll(app: App): SagaGenerator<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_SYSTEM_SETTINGS, fetchSystemSettingsAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_ROLE_TEMPLATES, fetchRoleTemplatesAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_PERMISSION_SCOPES, fetchPermissionScopes, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_SERVICE_PRINCIPAL_TEMPLATES, fetchServicePrincipalTemplatesAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_TRUSTED_CERTIFICATES, fetchTrustedCertificatesAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_TYPE_CONFIGURATION, fetchTypeConfigurationAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_SYSTEM_CONTEXT_EXPORT, fetchSystemContextExportAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_SYSTEM_SINGLE_OBJECT, fetchSingleObjectAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_SYSTEM_SINGLE_OBJECT, fetchSystemSingleObjectLinksAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_SERVICE_DETAILS, fetchServiceDetails, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_SERVICE_INSTANCE_DETAILS, fetchServiceInstanceDetails, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.GET_SYSTEM_TASKS, fetchSystemTasks, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>systemTypes.DISPLAY_PROPERTY_BAG, displayPropBag, app);
}
