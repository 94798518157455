/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan';
import { MicrosoftOnlineDirectoryServicesServicePlanProperties } from '../models/MicrosoftOnlineDirectoryServicesServicePlanProperties';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus {
    'servicePlanId'?: string;
    'servicePlanName'?: string | null;
    'subscribedPlanProperties'?: { [key: string]: string; } | null;
    'assignedPlan'?: MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan | null;
    'provisionedPlan'?: MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan | null;
    'provisionedPlanErrorDetails'?: string | null;
    'isCompanyLevel'?: boolean;
    'isImplicitlyProvisioned'?: boolean;
    'apCapabilityStatus'?: string | null;
    'servicePlanProperties'?: MicrosoftOnlineDirectoryServicesServicePlanProperties;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "servicePlanId",
            "baseName": "servicePlanId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "servicePlanName",
            "baseName": "servicePlanName",
            "type": "string",
            "format": ""
        },
        {
            "name": "subscribedPlanProperties",
            "baseName": "subscribedPlanProperties",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "assignedPlan",
            "baseName": "assignedPlan",
            "type": "MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan",
            "format": ""
        },
        {
            "name": "provisionedPlan",
            "baseName": "provisionedPlan",
            "type": "MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan",
            "format": ""
        },
        {
            "name": "provisionedPlanErrorDetails",
            "baseName": "provisionedPlanErrorDetails",
            "type": "string",
            "format": ""
        },
        {
            "name": "isCompanyLevel",
            "baseName": "isCompanyLevel",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isImplicitlyProvisioned",
            "baseName": "isImplicitlyProvisioned",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "apCapabilityStatus",
            "baseName": "apCapabilityStatus",
            "type": "string",
            "format": ""
        },
        {
            "name": "servicePlanProperties",
            "baseName": "servicePlanProperties",
            "type": "MicrosoftOnlineDirectoryServicesServicePlanProperties",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus.attributeTypeMap;
    }

    public constructor() {
    }
}



