export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS' as const;
export const GET_ANNOUNCEMENTS_COMPLETED = 'GET_ANNOUNCEMENTS_COMPLETED' as const;
export const GET_ANNOUNCEMENTS_FAILED = 'GET_ANNOUNCEMENTS_FAILED' as const;
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT' as const;
export const ADD_ANNOUNCEMENT_COMPLETED = 'ADD_ANNOUNCEMENT_COMPLETED' as const;
export const ADD_ANNOUNCEMENT_FAILED = 'ADD_ANNOUNCEMENT_FAILED' as const;
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT' as const;
export const UPDATE_ANNOUNCEMENT_COMPLETED = 'UPDATE_ANNOUNCEMENT_COMPLETED' as const;
export const UPDATE_ANNOUNCEMENT_FAILED = 'UPDATE_ANNOUNCEMENT_FAILED' as const;
export const ACKNOWLEDGE_ANNOUNCEMENT = 'ACKNOWLEDGE_ANNOUNCEMENT' as const;
export const ACKNOWLEDGE_ANNOUNCEMENT_COMPLETED = 'ACKNOWLEDGE_ANNOUNCEMENT_COMPLETED' as const;
export const ACKNOWLEDGE_ANNOUNCEMENT_FAILED = 'ACKNOWLEDGE_ANNOUNCEMENT_FAILED' as const;
export const ANNOUCEMENTS_MODAL_SEEN = 'ANNOUCEMENTS_MODAL_SEEN' as const;
