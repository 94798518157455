/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DSExplorerDataModelsLinkStateDataModel } from '../models/DSExplorerDataModelsLinkStateDataModel';
import { HttpFile } from '../http/http';

export class DSExplorerDataModelsLinkStateCollectionDataModel {
    'id'?: string | null;
    'createdAt'?: Date;
    'links'?: Array<DSExplorerDataModelsLinkStateDataModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "links",
            "baseName": "links",
            "type": "Array<DSExplorerDataModelsLinkStateDataModel>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return DSExplorerDataModelsLinkStateCollectionDataModel.attributeTypeMap;
    }

    public constructor() {
    }
}

