import type { App } from 'app/store';

// eslint-disable-next-line import/prefer-default-export
export async function getApplication(app: App, companyIdentifier: string, appId: string) {
  return app.clients.applications.applicationGetApplication(
    companyIdentifier,
    appId,
  );
}

export async function getApplicationPermissions(app: App, companyIdentifier: string, appId: string) {
  return app.clients.applications.applicationGetApplicationPermissions(
    companyIdentifier,
    appId,
  );
}
