import _ from 'lodash';
import moment from 'moment';
import * as TabTypes from 'app/actions/tabTypes';
import {
  AddTabAction,
  ValidTabActions,
  RemoveTabAction,
  UpdateTabAction,
  SetTabModalContentAction,
} from 'app/actions/tabActions';
import {
  TabCollection,
  TabDetails,
  TabType,
  TabMetadata,
  TabHistoryItem,
  PinnedTabDetails,
} from 'app/typings';
import { logDebug } from 'app/constants/constants';
import { allowedMaxHistoryItems } from 'app/constants/config';
import { ResponseOfDSObject } from 'app/helpers/types';
import { replaceRecord, removeRecord, replaceAtIndex } from './helpers';

type TabStore = Record<TabType, TabCollection>;

export type LinkState = {
  status: 'Requested' | 'Completed' | 'Expired' | 'Failed';
  link?: string;
  error?: string;
};

export const LocalStoreTabsName = 'tabs';

function setLocalStoreTabs(tabState: TabState) {
  window.localStorage[`${LocalStoreTabsName}`] = JSON.stringify(tabState.allTabMetadata());
}

export interface TabState {
  tabStore: TabStore;
  history: TabHistoryItem[];
  allTabs: (includeChildren?: boolean) => TabDetails[];
  allTabMetadata: () => TabMetadata[];
  hasTabs: (type: TabType) => boolean;
  findTab: (id: string | null) => TabDetails | undefined;
  findByContextId: (id: string) => TabDetails | undefined;
  getTab: (
    type: TabType,
    id: string | undefined,
    parentTab?: string
  ) => TabDetails | undefined;
  getTabs: (type: TabType, parentTab?: string) => TabDetails[];
  getActiveIndex: (type: TabType, tabId: string | undefined, parentTab?: string) => number;
  addTab: (action: AddTabAction) => TabStore;
  removeTab: (action: RemoveTabAction) => TabStore;
  replaceTab: (action: UpdateTabAction) => TabStore;
  setTabModalContent: (action: SetTabModalContentAction) => TabStore;
  addTabHistory: (action: RemoveTabAction) => TabHistoryItem[];
  removeTabHistory: (action: AddTabAction) => TabHistoryItem[];
  linkState?: LinkState;
  isPinnedTab: (id: string, findByParent?: boolean) => boolean;
  addPinnedTab: (type: TabType, id: string, parentTab: string) => PinnedTabDetails[];
  getPinnedTab: (id: string, findByParent?: boolean) => PinnedTabDetails | undefined;
  replacePinnedTab: (action: UpdateTabAction) => PinnedTabDetails[] | undefined;
  removePinnedTab: (type: TabType, id: string) => PinnedTabDetails[];
  setPinnedTabModalContent: (action: SetTabModalContentAction) => PinnedTabDetails[];
  pinned?: PinnedTabDetails[];
}

const INITIAL_STATE: TabState = {
  tabStore: {
    Company: { tabs: [] },
    System: { tabs: [] },
    Key: { tabs: [] },
    Index: { tabs: [] },
    Partition: { tabs: [] },
  },
  history: [],
  allTabs(includeChildren?: boolean) {
    const parents = _.flatMap(this.tabStore, (t) => t.tabs);
    if (includeChildren) {
      const children = _.flatMapDeep(this.tabStore, (t) => t.tabs.map((c) => c.children.tabs));
      return _.flatMap([...parents, ...children]);
    }

    return parents;
  },
  allTabMetadata() {
    const allTabs = this.allTabs();

    // Capture the Metadata from every tab
    const tabData = _.flatMap(allTabs, (tab: TabDetails) => {
      // Add Children
      const children = _.without(_.flatMap(tab.children.tabs, (childTab: TabDetails) => {
        if (childTab.metadata.action) {
          return childTab.metadata;
        }

        return undefined;
      }), undefined);

      return { ...tab.metadata, children };
    });

    return tabData as TabMetadata[];
  },
  findTab(id: string | null) {
    return _.find(this.allTabs(), ['id', id]);
  },
  findByContextId(id: string) {
    return _.find(this.tabStore.Company.tabs, ['contextId', id]);
  },
  getTab(type: TabType, id: string | undefined, parentTab?: string) {
    const foundTab = _.find(this.tabStore[`${type}`].tabs, ['id', parentTab ?? id]);
    if (foundTab && parentTab) {
      return _.find(foundTab.children.tabs, ['id', id]);
    }

    return foundTab;
  },
  getTabs(type: TabType, parentTab?: string) {
    if (parentTab) {
      return this.getTab(type, parentTab)?.children.tabs as TabDetails[];
    }

    return this.tabStore[`${type}`].tabs;
  },
  hasTabs(type: TabType) {
    return this.tabStore[`${type}`].tabs.length > 0;
  },
  getActiveIndex(type: TabType, tabId: string | undefined, parentTab?: string) {
    if (tabId == null) {
      return -1;
    }

    const tabs = this.getTabs(type, parentTab);
    if (tabs !== undefined) {
      const index = _.findIndex(tabs, (t) => t.id === tabId);

      // Not found -- Grab the highest index
      if (index === -1) {
        return tabs.length - 1;
      }

      return index;
    }

    return -1;
  },
  addTab(action: AddTabAction) {
    const {
      [action.tab.metadata.tabType as TabType]: previousCollection,
      ...remainingState
    } = this.tabStore;

    if (action.fromHistory) {
      // Remove Tab from History List
      logDebug('Creating Tab from History', action.tab);
    }

    if (action.parentTab) {
      // Adding as a child tab
      return this.replaceTab(action as unknown as UpdateTabAction);
    }

    return {
      ...(remainingState as Record<TabType, TabCollection>),
      [action.tab.metadata.tabType as TabType]: {
        ...previousCollection,
        tabs: _.union(previousCollection.tabs, [action.tab]),
      },
    };
  },
  addPinnedTab(type: TabType, id: string, parentTab: string) {
    const existingPinnedTabs = this.pinned;
    const newTab = this.getTab(type, id, parentTab);

    if (newTab) {
      const parent = this.getTab(type, parentTab);
      const lastIndex = parent ? _.indexOf(parent.children.tabs, newTab) : _.indexOf(this.tabStore[`${type}`].tabs, newTab);
      return _.union(existingPinnedTabs, [{ ...newTab, parentTab, lastIndex }]);
    }

    return existingPinnedTabs ?? [];
  },
  setPinnedTabModalContent(action: SetTabModalContentAction) {
    const existingPinnedTabs = this.pinned;
    const index = _.findIndex(existingPinnedTabs, { id: action.id });

    if (index > -1 && this.pinned) {
      return replaceAtIndex(this.pinned, { ...this.pinned[+`${index}`], modalContent: action.options }, index);
    }

    return this.pinned ?? [];
  },
  removePinnedTab(type: TabType, id: string) {
    const existingPinnedTabs = this.pinned;
    const found = _.find(existingPinnedTabs, { id });

    if (found) {
      return _.without(existingPinnedTabs, found);
    }

    return existingPinnedTabs ?? [];
  },
  replacePinnedTab(action: UpdateTabAction) {
    const existingPinnedTabs = this.pinned;
    const index = _.findIndex(existingPinnedTabs, { id: action.tab.id });

    if (index > -1 && this.pinned) {
      return replaceAtIndex(this.pinned, action.tab, index) as PinnedTabDetails[];
    }
    return this.pinned ?? [];
  },
  getPinnedTab(id: string, findByParent?: boolean) {
    const existingPinnedTabs = this.pinned;
    const pinnedTab = findByParent ? _.find(existingPinnedTabs, (t) => t.parentTab === id) : _.find(existingPinnedTabs, { id });
    return pinnedTab || undefined;
  },
  removeTab(action: RemoveTabAction) {
    const { [action.tabType]: previousCollection, ...remainingState } = this.tabStore;

    if (action.parentTab) {
      const parentTab = this.getTab(
        action.tabType,
        action.parentTab,
      ) as TabDetails;
      const childTab = this.getTab(
        action.tabType,
        action.id,
        action.parentTab,
      ) as TabDetails;

      const updateAction = {
        ...action,
        parentTab: undefined,
        tab: {
          ...parentTab,
          children: {
            ...parentTab.children,
            tabs: _.without(parentTab.children.tabs, childTab),

          },
        },
      };

      return this.replaceTab(updateAction as unknown as UpdateTabAction);
    }

    return {
      ...(remainingState as Record<TabType, TabCollection>),
      [action.tabType]: {
        ...previousCollection,
        tabs: removeRecord(
          previousCollection,
          'tabs',
          (r: TabDetails) => r.id === action.id,
        ),
      },
    };
  },
  replaceTab(action: UpdateTabAction) {
    const {
      [action.tab.metadata.tabType as TabType]: previousCollection,
      ...remainingState
    } = this.tabStore;
    let replacementTab = action.tab;

    if (action.parentTab && action.tab.metadata.tabType) {
      // Replace "Tab" object and then replace with normal flow below
      const parentTab = this.getTab(
        action.tab.metadata.tabType,
        action.parentTab,
      ) as TabDetails;

      if (parentTab === undefined) {
        return this.tabStore;
      }

      const parentCollection = parentTab.children as TabCollection;

      if (_.findIndex(parentCollection.tabs, ['id', action.tab.id]) === -1) {
        // If the tab being added is marked as No Close -- Move it up to the start of the new collection
        let newTabsCollection = action.tab.allowClose === false ? [action.tab, ...parentCollection.tabs] : [...parentCollection.tabs, action.tab];
        if (action.atIndex && action.atIndex >= 0) {
          /// If we were given an explicit insertion index use that instead.
          newTabsCollection = [
            ..._.slice(parentCollection.tabs, 0, action.atIndex),
            action.tab,
            ..._.slice(parentCollection.tabs, action.atIndex, parentCollection.tabs.length),
          ];
        }

        replacementTab = {
          ...parentTab,
          children: {
            ...parentTab.children,
            tabs: newTabsCollection,
          },
        };
      } else {
        replacementTab = {
          ...parentTab,
          children: {
            ...parentTab.children,
            tabs: replaceRecord(
              parentCollection,
              action.tab,
              'tabs',
              (r: TabDetails) => r.id === action.tab.id,
            ),
          },
        };
      }
    }

    if (action.tab.metadata.tabType === TabType.Company && action.resp !== undefined) {
      replacementTab.contextId = (action.resp as ResponseOfDSObject).contextId;
    }

    return {
      ...(remainingState as Record<TabType, TabCollection>),
      [action.tab.metadata.tabType as TabType]: {
        ...previousCollection,
        tabs: replaceRecord(
          previousCollection,
          replacementTab,
          'tabs',
          (r: TabDetails) => r.id === replacementTab.id,
        ),
      },
    };
  },
  isPinnedTab(id: string, findByParent?: boolean): boolean {
    const isPinned = findByParent ? _.find(this.pinned, (t) => t.parentTab === id) : _.find(this.pinned, { id });
    return isPinned !== undefined;
  },
  setTabModalContent(action: SetTabModalContentAction): TabStore {
    const {
      [action.tabType as TabType]: previousCollection,
      ...remainingState
    } = this.tabStore;

    const currentTab = { ...this.getTab(action.tabType, action.id, action.parentTab), modalContent: action.options } as TabDetails;
    if (currentTab) {
      // We have the new child tab -- Now we need to replace it within the parent tab collection
      const parentTab = this.getTab(
        action.tabType,
        action.parentTab as string,
      ) as TabDetails;
      const parentCollection = parentTab.children as TabCollection;

      const replacementTab = {
        ...parentTab,
        children: {
          ...parentTab.children,
          tabs: replaceRecord(
            parentCollection,
            currentTab,
            'tabs',
            (r: TabDetails) => r.id === currentTab.id,
          ),
        },
      };

      const result = {
        ...(remainingState as Record<TabType, TabCollection>),
        [action.tabType as TabType]: {
          ...previousCollection,
          tabs: replaceRecord(
            previousCollection,
            replacementTab,
            'tabs',
            (r: TabDetails) => r.id === replacementTab.id,
          ),
        },
      };

      return result;
    }

    return this.tabStore;
  },
  addTabHistory(action: RemoveTabAction): TabHistoryItem[] {
    const foundTab = this.getTab(action.tabType, action.id);

    // Already in the list -- Don't add again
    if (_.find(this.history, ['id', action.id])) {
      return this.history;
    }

    // Already found another matching tab of the same type with the same name -- Don't add again
    if (
      _.find(this.history, {
        tabType: foundTab?.metadata.tabType as TabType,
        name: foundTab?.name,
      })
    ) {
      return this.history;
    }

    // If the found tab is missing any of the required properties
    // for a HistoryItem -- Skip adding it.
    if (foundTab as TabHistoryItem) {
      return _.takeRight(
        _.union<TabHistoryItem>(this.history, [
          {
            ...(foundTab as TabHistoryItem),
            closedAt: moment(),
            tabType: action.tabType,
          },
        ]),
        allowedMaxHistoryItems(),
      );
    }
    return this.history;
  },
  removeTabHistory(action: AddTabAction): TabHistoryItem[] {
    return removeRecord(
      this,
      'history',
      (r: TabHistoryItem) => r.id === action.tab.id,
    );
  },
};

export default (
  tabState: TabState = INITIAL_STATE,
  action: ValidTabActions,
): TabState => {
  // use newState when changing state so we capture it in the local store.
  let newState: TabState;
  switch (action.type) {
    case TabTypes.ADD_TAB:
      if (action.fromHistory) {
        newState = {
          ...tabState,
          tabStore: tabState.addTab(action),
          history: tabState.removeTabHistory(action),
        };
      } else {
        newState = { ...tabState, tabStore: tabState.addTab(action) };
      }
      break;
    case TabTypes.REMOVE_TAB:
      if (action.id) {
        newState = {
          ...tabState,
          tabStore: tabState.removeTab(action),
          history: tabState.addTabHistory(action),
        };
        break;
      }
      return tabState;
    case TabTypes.SET_TAB_MODAL_CONTENT:
      // This action will need to determine if the tab being sought is "pinned" vs "unpinned"
      // and then call the appropriate update
      return tabState.isPinnedTab(action.id as string)
        ? { ...tabState, pinned: tabState.setPinnedTabModalContent(action) }
        : { ...tabState, tabStore: tabState.setTabModalContent(action) };
    case TabTypes.UPDATE_TAB:
      newState = tabState.isPinnedTab(action.tab.id as string)
        ? { ...tabState, pinned: tabState.replacePinnedTab(action) }
        : { ...tabState, tabStore: tabState.replaceTab(action) };
      break;
    case TabTypes.CREATE_TAB_LINK_STATE:
      return { ...tabState, linkState: { status: 'Requested' } };
    case TabTypes.CREATE_TAB_LINK_STATE_COMPLETED:
      return {
        ...tabState,
        linkState: { status: 'Completed', link: action.linkState },
      };
    case TabTypes.CREATE_TAB_LINK_STATE_FAILED:
      return {
        ...tabState,
        linkState: { status: 'Failed', error: action.error },
      };
    case TabTypes.PIN_TAB:
      return {
        ...tabState,
        pinned: tabState.addPinnedTab(action.tabType, action.id as string, action.parentTab),
      };
    case TabTypes.UNPIN_TAB:
      return {
        ...tabState,
        pinned: tabState.removePinnedTab(action.tabType, action.id as string),
      };
    case TabTypes.CLEAR_TAB_LINK_STATE:
      return {
        ...tabState,
        linkState: { ...tabState.linkState, status: 'Expired' },
      };
    default:
      return tabState;
  }

  setLocalStoreTabs(newState);
  return newState;
};
