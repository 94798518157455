export const GET_PARTITION_IDS = 'GET_PARTITION_IDS' as const;
export const GET_PARTITION_IDS_COMPLETED = 'GET_PARTITION_IDS_COMPLETED' as const;
export const GET_PARTITION_IDS_FAILED = 'GET_PARTITION_IDS_FAILED' as const;

export const GET_PARTITION_DETAILS = 'GET_PARTITION_DETAILS' as const;
export const GET_PARTITION_DETAILS_COMPLETED = 'GET_PARTITION_DETAILS_COMPLETED' as const;
export const GET_PARTITION_DETAILS_FAILED = 'GET_PARTITION_DETAILS_FAILED' as const;

export const DISPLAY_PARTITION_DETAILS = 'DISPLAY_PARTITION_DETAILS' as const;
export const GET_PARTITION_COMPANY_DETAILS = 'GET_PARTITION_COMPANY_DETAILS' as const;

export const GET_COMPANIES_BY_PARTITION = 'GET_COMPANIES_BY_PARTITION' as const;
export const GET_COMPANIES_BY_PARTITION_COMPLETED = 'GET_COMPANIES_BY_PARTITION_COMPLETED' as const;
export const GET_COMPANIES_BY_PARTITION_FAILED = 'GET_COMPANIES_BY_PARTITION_FAILED' as const;

export const DISPLAY_PARTITION_COMPANY_OR_FAILOVER = 'DISPLAY_PARTITION_COMPANY_OR_FAILOVER' as const;
export const DISPLAY_PARTITION_COMPANY_OR_FAILOVER_COMPLETED = 'DISPLAY_PARTITION_COMPANY_OR_FAILOVER_COMPLETED' as const;
export const DISPLAY_PARTITION_COMPANY_OR_FAILOVER_FAILED = 'DISPLAY_PARTITION_COMPANY_OR_FAILOVER_FAILED' as const;

export const GET_PARTITION_FAILOVERS = 'GET_PARTITION_FAILOVERS' as const;
export const GET_PARTITION_FAILOVERS_COMPLETED = 'GET_PARTITION_FAILOVERS_COMPLETED' as const;
export const GET_PARTITION_FAILOVERS_FAILED = 'GET_PARTITION_FAILOVERS_FAILED' as const;
