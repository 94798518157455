/** *
 * Syntactic sugar.
 *
 * Rename some generated types to shorter, more readable
 * names that align better with existing code.
*/
export {
  CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel as BasicResponseOfAnnouncementTrackingDataModel,
  CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray as BasicResponseOfStatusMessageDataModelArray,
  CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel as BasicResponseOfUserPreferencesDataModel,
  CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString as BasicToolboxResponseOfListOfString,
  CommonModelsCompanyContextObjectClasses as CompanyContextObjectClasses,
  CommonModelsDirSyncSupportedDirectoryProperties as DirSyncSupportedDirectoryProperties,
  CommonModelsDirectoryLinkType as DirectoryLinkType,
  CommonModelsEntitlementGrantValidSourceObjectClasses as EntitlementGrantValidSourceObjectClasses,
  CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask as EnumOptionOfSystemTask,
  CommonModelsGenericTreeNodeItemModel as GenericTreeNodeItemModel,
  CommonModelsRequiredApplicationOauth2Permissions as RequiredApplicationOauth2Permissions,
  CommonModelsRoleAssignmentValidTargetObjectClasses as RoleAssignmentValidTargetObjectClasses,
  CommonModelsScopedMemberValidTargetObjectClasses as ScopedMemberValidTargetObjectClasses,
  CommonModelsSystemContextObjectClasses as SystemContextObjectClasses,
  CommonModelsTreeNodeModel as TreeNodeModel,
  DSExplorerDataModelsBrowsedObjectSearchModel as BrowsedObjectSearchModel,
  DSExplorerDataModelsLinkStateDataModel as LinkStateDataModel,
  DSExplorerDataModelsStatusMessageDataModel as StatusMessageDataModel,
  DSExplorerDataModelsUserPreferencesDataModel as UserPreferencesDataModel,
  DSExplorerDataStatusMessageType as StatusMessageType,
  DSExplorerWebHostContractsFilterStringEnum as FilterStringEnum,
  DSExplorerWebHostModelsPropertyFilterModel as PropertyFilterModel,
  DSExplorerWebHostModelsPropertySearchRequestModel as PropertySearchRequestModel,
  MicrosoftOnlineDSExplorerContractAltSecIdType as AltSecIdType,
  MicrosoftOnlineDSExplorerContractDSObject as DSObject,
  MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty as DSObjectDerivedProperty,
  MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty as DSObjectDirectoryProperty,
  MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty as DSObjectMetadataProperty,
  MicrosoftOnlineDSExplorerContractDSPartitionDetails as DSPartitionDetails,
  MicrosoftOnlineDSExplorerContractDSReferenceSet as DSReferenceSet,
  MicrosoftOnlineDSExplorerContractDelegationType as DelegationType,
  MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType as LegacyAuthZPolicyType,
  MicrosoftOnlineDSExplorerContractLicenseDetail as LicenseDetail,
  // eslint-disable-next-line max-len
  MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray as PagedResponseOfBinaryDSReferenceSetArray,
  MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray as PagedResponseOfDSObjectArray,
  MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray as ResponseOfDirectoryObjectTreeNodeModelArray,
  MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses as ResponseOfCompanyLicenses,
  MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject as ResponseOfDSObject,
  MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray as ResponseOfDSObjectArray,
  MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray as ResponseOfDSPartitionDetailsArray,
  MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail as ResponseOfLicenseDetail,
  MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail as ResponseOfProvisioningStatusDetail,
  // eslint-disable-next-line max-len
  MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray as ResponseOfIDictionaryOfDirectoryLinkClassAndDSObjectArray,
  MicrosoftOnlineDSExplorerContractSubscriptionDetail as SubscriptionDetail,
  MicrosoftOnlineDSExplorerContractSystemSettings as SystemSettings,
  MicrosoftOnlineDSExplorerContractUserIdentifierType as UserIdentifierType,
  MicrosoftOnlineDirectoryServicesAuthorizationScopeType as AuthorizationScopeType,
  MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory as DirSyncProvisioningErrorCategory,
  MicrosoftOnlineDirectoryServicesDirSyncState as DirSyncState,
  MicrosoftOnlineDirectoryServicesDirectoryObjectClass as DirectoryObjectClass,
  MicrosoftOnlineDirectoryServicesDirectoryProperty as DirectoryProperty,
  MicrosoftOnlineDirectoryServicesProvisioningStatus as ProvisioningStatus,
  MicrosoftOnlineDirectoryServicesSearchFilterOperator as SearchFilterOperator,
} from 'app/client';