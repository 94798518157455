import './DSTable.scss';
import 'ag-grid-community/styles/ag-grid-no-native-widgets.css'; // Core grid CSS, always needed
import './ag-theme-system-default.scss';
import './ag-theme-light.scss';
import './ag-theme-dark.scss';
import './ag-theme-forest.scss';
import './ag-theme-hotdog.scss';
import './ag-theme-synthwave.scss';
import './ag-theme-solarized-dark.scss';
import './ag-theme-solarized-light.scss';
import { fetchMoreData } from 'app/helpers/helpers';
import { ObjectLink } from 'app/reducers/objectLinks';
import { State } from 'app/reducers/state';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import {
  ColDef,
  ICellRendererParams,
  RowClickedEvent,
  IDatasource,
  IGetRowsParams,
  CellContextMenuEvent,
  GridReadyEvent,
  SortChangedEvent,
  ColumnState,
  GridApi,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import ContentModal from '../ContentModal/ContentModal';
import ContextMenu, { CustomActions, getRecordValue } from '../ContextMenu/ContextMenu';
import InputSearch from '../InputSearch/InputSearch';
import ObjectLinksModal from '../ObjectLinksModal/ObjectLinksModal';
import { DataFormatType, DSTableProps, RowRecord } from './DSTableTypes';
import RefreshButton from '../RefreshButton/RefreshButton';
import ReactComponentRenderer from './ReactComponentRenderer';
import { applyFilter } from './DSTableDataFilter';
import {
  applyColumnCustomization, applySorting, calculateRowHeight,
} from './DSTableShared';
import Spinner from '../Spinner/Spinner';

export type DSTableCellRenderingParams = {
  columnIndex: number,
  column: DSTableColumn,
  rowData: RowRecord,
  rowIndex: number,
  className: string
};

export type DSTableColumn = {
  i: number,
  name: string,
  width: number,
};
export type ColumnWidthChangeEvent = {
  columnIndex: number,
  width: number
};
export type DSTableHeaderCellRenderingParams = {
  columnIndex: number,
  column: DSTableColumn,
  className: string
};

const DSTable = <TRow extends RowRecord>({
  tableData: initialTableData,
  hide = [],
  rowSelectable = false,
  rowClickEvent,
  hasMoreData = false,
  nextPage: initialNextPageValue = undefined,
  companyId = undefined,
  scrollPosition = 0,
  objectLinks = undefined,
  tabId = undefined,
  className = undefined,
  refreshDelegate = undefined,
  formatDataDelegate = undefined,
  dataFormatType = DataFormatType.SearchData,
  DSTableParams = undefined,
}: DSTableProps<TRow>) => {
  const preferenceState = useSelector((state: State) => state.preferences);
  const [customAction, fireCustomAction] = useState<CustomActions | undefined>(undefined);
  const [searchString, setSearchString] = useState(''); // Emitted from Input Search
  const {
    rowClickBehavior, timeFormat, timeUtc, pageSize,
  } = preferenceState.data;

  const isSingleClickEnabled = rowClickBehavior === 'Single Click';
  const isDoubleClickEnabled = !isSingleClickEnabled;
  const hideColumns = _.union(hide, []);

  const [nextPage, setNextPage] = useState<string | undefined>(initialNextPageValue);
  const [dataCache, setCachedData] = useState(initialTableData);
  const [inputFilteredTableData, setInputFilteredTableData] = useState<RowRecord[]>([]);
  const [nonHeightlightedTableData, setNonHeighlightedTableData] = useState<RowRecord[]>([]);
  const [navtiveFilteredTableData, setNativeFilteredTableData] = useState<RowRecord[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [sortingModel, setSortingModel] = useState<ColumnState | undefined>();
  const [contextEvent, setContextEvent] = useState<CellContextMenuEvent<TRow>>();
  const [searchFromContext, setSearchFromContext] = useState<string>('');
  const [showObjectLinksModal, setShowObjectLinksModal] = useState(false);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const gridRef = useRef<AgGridReact>(null);

  // eslint-disable-next-line
  const onBtShowLoading = useCallback(() => { gridRef.current!.api.showLoadingOverlay()}, []);

  const loadingOverlayComponent = useMemo(() => Spinner, []);
  const loadingOverlayComponentParams = useMemo(() => ({
    active: true,
  }), []);

  const allowedTableHeaders = _.without(initialTableData[0] && Object.keys(initialTableData[0]).map((header) => {
    if (_.indexOf(hideColumns, header) === -1 && _.indexOf(preferenceState.data.hiddenColumns, header) === -1) {
      return header;
    }

    return undefined;
  }), undefined);

  function createColumns() {
    const columns: ColDef[] = [];

    for (let index = 0; index < allowedTableHeaders.length; index += 1) {
      const field = _.get(allowedTableHeaders, index) as string;

      columns.push({
        flex: 1,
        field,
        sortIndex: DSTableParams?.sortColumn === field ? 0 : null,
        sort: DSTableParams?.sortColumn === field ? DSTableParams.sortOrder : null,
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.context.currentSearchString !== '') {
            // Search String results must always be rendered as components
            // to allow for highlighting
            return {
              component: ReactComponentRenderer,
            };
          }

          if (_.has(params.data, '__moment')) {
            return {
              component: ReactComponentRenderer,
            };
          }

          // Default
          return undefined;
        },
      });
    }

    // Apply Known Customizations
    applyColumnCustomization(columns, timeFormat, timeUtc);
    return columns as ColDef[];
  }

  const columnDefs = useMemo<ColDef[]>(() => createColumns(), [preferenceState]);
  const defaultColDef = useMemo<ColDef>(() => ({
    editable: false,
    sortable: true,
    minWidth: 30,
    filter: false,
    resizable: true,
    autoHeight: false,
    wrapText: false,
    headerCheckboxSelection: false,
  }), []);

  const filterAndSort = (currentDataCache: RowRecord[], request: IGetRowsParams, moreData: boolean) => {
    const { currentTimeUtc } = request.context;
    // eslint-disable-next-line
    const sortedData: any = applySorting(columnDefs, currentDataCache, request.sortModel);
    const filteredData = applyFilter(sortedData, request.filterModel, columnDefs, currentTimeUtc, preferenceState.data.timeFormat);
    setNativeFilteredTableData(filteredData);

    request.successCallback(
      filteredData.slice(request.startRow + scrollPosition, request.endRow + scrollPosition),
      moreData ? undefined : filteredData.length,
    );
  };

  const dataSource = useMemo<IDatasource>(() => ({
    getRows: (request: IGetRowsParams) => {
      // We need to use values from the context to avoid a stale closure value

      const { currentNextPage, currentDataCache, currentSearchString } = request.context;

      // Check for local filtering
      if (currentSearchString !== '') {
        // Keep filtered data AND non-highlighted in sync for sorting
        // eslint-disable-next-line
        const sortedNonHeightlightedData: any = applySorting(columnDefs, nonHeightlightedTableData, request.sortModel);
        setNonHeighlightedTableData(sortedNonHeightlightedData);
        // eslint-disable-next-line
        const sortedFilteredData: any = applySorting(columnDefs, inputFilteredTableData, request.sortModel);
        request.successCallback(sortedFilteredData, inputFilteredTableData.length);
        return;
      }

      // Otherwise fetch normal data flow
      if ((request.startRow + request.endRow) > currentDataCache.length && currentNextPage) {
        fetchMoreData(currentNextPage, DSTableParams?.payload || undefined).then(((d) => {
          setNextPage(d.nextPage);

          let formattedData = d.data;
          if (typeof formatDataDelegate === 'function') {
            formattedData = formatDataDelegate(d, dataFormatType);
          }

          const updatedCache = _.concat(currentDataCache, formattedData);
          filterAndSort(updatedCache, request, !d.isLastPage);

          // Update Cache
          setCachedData(updatedCache);
        }));
      } else {
        filterAndSort(currentDataCache, request, hasMoreData);
      }
    },
  }), [initialTableData, inputFilteredTableData, searchString]);

  const closeContextMenu = () => {
    setShowMenu(false);
    setContextEvent(undefined);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDocumentClick = (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (contextEvent && (e.target.outerHTML !== (contextEvent as any).event.target.outerHTML)) {
        closeContextMenu();
      }
    };

    const handleContextMenu = (event: MouseEvent) => {
      // eslint-disable-next-line
      const el: any = event.target;
      if (el.tagName === 'DIV' || el.tagName === 'A') {
        event.preventDefault();
      }
    };

    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('contextmenu', handleContextMenu);

    // Cleanup
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [contextEvent]);

  useEffect(() => {
    if (gridAPI === undefined) {
      return;
    }

    const rowHeight = calculateRowHeight(preferenceState.staged?.tableFontSize || preferenceState.data.tableFontSize);
    const gridBodyCtrl = _.get(gridAPI, 'gridBodyCtrl');

    if (gridBodyCtrl && gridBodyCtrl.bodyScrollFeature.rowModel.rowHeight !== rowHeight) {
      gridAPI.resetRowHeights();

      let gridHeight = 0;
      gridAPI?.forEachNode((rowNode) => {
        rowNode.setRowHeight(rowHeight);
        rowNode.setRowTop(gridHeight);
        gridHeight += rowHeight;
      });

      gridAPI.onRowHeightChanged();

      gridBodyCtrl.bodyScrollFeature.rowModel.rowHeight = rowHeight;
      gridBodyCtrl.bodyScrollFeature.heightScaler.setModelHeight(gridHeight);
    }
  }, [preferenceState.staged?.tableFontSize, preferenceState.data.tableFontSize]);

  const handleRowClicked = (event: RowClickedEvent) => {
    if (event.type === 'rowClicked' && !isSingleClickEnabled) {
      return;
    }

    if (event.type === 'rowDoubleClicked' && !isDoubleClickEnabled) {
      return;
    }

    if (typeof rowClickEvent === 'function') {
      // Have to find the row within the non Heighlighted data
      const rowIndex = event.rowIndex as number;
      // eslint-disable-next-line
      const rawData = searchString ? nonHeightlightedTableData[rowIndex] : event.data;
      rowClickEvent(rawData as TRow, event.data, event.rowIndex as number);
    }
  };

  const handleContextMenu = (event: CellContextMenuEvent<TRow>) => {
    if (event.event) {
      event.event.preventDefault();
      event.event.stopImmediatePropagation();
      setShowMenu(true);
      setContextEvent(event);
    }
  };

  const handleSortChanged = (event: SortChangedEvent) => {
    const sortState = _.filter(event.columnApi.getColumnState(), (c) => typeof (c.sortIndex) === 'number' && c.sort) as ColumnState[];
    if (sortState.length) {
      setSortingModel(_.first(sortState));
    } else {
      setSortingModel(undefined);
    }
  };

  const handleGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
  };

  const showTableActions = () => {
    const hasDownload = dataFormatType === 3;
    const linksPresent = objectLinks && (objectLinks.sourceLinks.data?.length || objectLinks.targetLinks.data?.length);
    const isApp = getRecordValue(initialTableData, '_ObjectClass') === 'Application';
    const isSP = getRecordValue(initialTableData, '_ObjectClass') === 'ServicePrincipal';
    const availToOther = getRecordValue(initialTableData, 'AvailableToOtherTenants') === 'true';
    return !!(isApp || isSP || availToOther || refreshDelegate || linksPresent || hasDownload);
  };

  const getTableActions = () => {
    const actions = [];
    if (objectLinks && objectLinks.totalLinks > 0) {
      actions.push(<Dropdown.Item
        key="object-links"
        icon="linkify"
        disabled={!objectLinks.sourceLinks.data?.length && !objectLinks.targetLinks.data?.length}
        text={`Show Object Links ${objectLinks.totalLinks ? `(${objectLinks.totalLinks})` : ''}`}
        onClick={() => setShowObjectLinksModal(true)}
      />);
    }

    if (dataFormatType === 3) {
      actions.push(<Dropdown.Item
        key="download-object"
        icon="download"
        text="Download Object (JSON)"
        onClick={() => fireCustomAction(CustomActions.DownloadObject)}
      />);
    }

    if (DSTableParams && DSTableParams.rmParams) {
      actions.push(<Dropdown.Item
        key="repl-metadata"
        icon="database"
        text="Show Replication Metadata"
        onClick={() => fireCustomAction(CustomActions.ShowReplMetadata)}
      />);
    }

    if (getRecordValue(initialTableData, '_ObjectClass') === 'ServicePrincipal') {
      actions.push(<Dropdown.Item
        key="key-groups"
        icon="key"
        text="Show KeyGroup(s)"
        onClick={() => fireCustomAction(CustomActions.FindKeyGroup)}
      />);
    }

    if (getRecordValue(initialTableData, '_ObjectClass') === 'Application') {
      actions.push(<Dropdown.Item
        key="app-permissions"
        icon="shield alternate"
        text="Show App Permissions"
        onClick={() => fireCustomAction(CustomActions.ShowAppPermissions)}
      />);
    }

    if (getRecordValue(initialTableData, 'AvailableToOtherTenants') === 'true') {
      actions.push(<Dropdown.Item
        key="find-sp"
        icon="search"
        text="Find Service Principal"
        onClick={() => fireCustomAction(CustomActions.FindServicePrincipal)}
      />);
    }

    if (getRecordValue(initialTableData, '_ObjectClass') === 'ServicePrincipal') {
      actions.push(<Dropdown.Item
        key="find-app"
        icon="search"
        text="Find Application"
        onClick={() => fireCustomAction(CustomActions.FindApplication)}
      />);
    }

    return actions;
  };

  //* TABLE ACTIONS DISPLAY
  const buildTableActions = () => {
    const actions = getTableActions();

    if (actions.length === 0) {
      return null;
    }

    return (
      <div className="tableActions">
        <Dropdown
          id="actions-menu-tour"
          text="Actions"
          icon="ellipsis vertical"
          labeled
          direction="left"
          compact
          button
          className="icon tableActionBtn"
        >
          <Dropdown.Menu>
            {actions}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const currentContext = useMemo(() => ({
    currentNextPage: nextPage,
    currentDataCache: dataCache,
    currentSearchString: searchString,
    currentTimeUtc: timeUtc,
  }), [nextPage, dataCache, searchString]);

  const getContextMenu = () => {
    // The context menu logic below is to calculate where we place the context menu -- IF
    // launched via a PointerEvent. However, the "CustomActions" are also processed by the
    // ContextMenu object even if we don't display the menu.

    const pointerEvent = contextEvent?.event as PointerEvent;

    let computedXValue = 0;
    let computedYValue = 0;

    if (pointerEvent) {
      let srcElement = pointerEvent.srcElement as HTMLElement;
      if (srcElement.localName === 'time') {
        // For some reason these time elements don't seem to register the offsetLeft properly.
        srcElement = srcElement.parentElement as HTMLElement;
      }

      const viewPortContainer = srcElement.closest('.ag-body-viewport') as HTMLElement;
      const rootWrapper = srcElement?.closest('.ag-root-wrapper') as HTMLElement;

      if (viewPortContainer && rootWrapper) {
        computedXValue = srcElement.offsetLeft + pointerEvent.offsetX;
        computedYValue = contextEvent?.node.rowTop as number + pointerEvent.offsetY + viewPortContainer.offsetTop + rootWrapper.offsetTop;
      }
    }

    return (
      <ContextMenu
        disableOpenRow={!rowSelectable}
        companyId={companyId}
        openRow={() => (contextEvent?.node.selectable && rowClickEvent
          ? rowClickEvent(initialTableData[contextEvent?.node.rowIndex as number],
            contextEvent?.data as TRow, contextEvent?.node.rowIndex as number)
          : null)}
        event={contextEvent}
        x={computedXValue}
        y={computedYValue}
        showMenu={showMenu}
        onSearch={(search) => {
          setContextEvent(undefined);
          setSearchFromContext(search);
          setSearchString(search);
        }}
        closeMenu={() => { setShowMenu(false); fireCustomAction(undefined); }}
        rowEventData={contextEvent?.data}
        tableData={initialTableData}
        tabId={tabId}
        dispatchCustomAction={customAction}
        rmParams={DSTableParams && DSTableParams.rmParams ? DSTableParams.rmParams : undefined}
      />
    );
  };

  const activeTheme = preferenceState.staged?.activeTheme || preferenceState.data.activeTheme;
  const fontSize = preferenceState.staged?.tableFontSize || preferenceState.data.tableFontSize;
  const rowHeight = calculateRowHeight(fontSize);

  return (
    <div style={{ height: 'min-content' }}>
      {getContextMenu()}

      <div className="tableActionContainer">
        { /* TABLE SEARCH */}
        <div className="Search-Container">
          <InputSearch
            inputSearch={searchFromContext}
            column={sortingModel?.colId}
            direction={sortingModel?.sort || undefined}
            input={navtiveFilteredTableData}
            searchValueEmitter={(search) => {
              setSearchString(search);
            }}
            output={(highlighted: TRow[], nonHeightlighted: TRow[]) => {
              setNonHeighlightedTableData(nonHeightlighted);
              setInputFilteredTableData(highlighted);
            }}
          />
        </div>
        {
              refreshDelegate ? (
                <RefreshButton
                  refreshDelegate={refreshDelegate}
                  sortColumn={sortingModel?.colId}
                  sortOrder={sortingModel?.sort}
                  loading={onBtShowLoading}
                />
              ) : null
            }
        {showTableActions() ? buildTableActions() : null}
      </div>
      <div className={`ag-wrapper-dstable ag-theme-${activeTheme} font-${fontSize} ${className}`} style={{ height: 'min-content' }}>
        <AgGridReact
          ref={gridRef}
          loadingOverlayComponent={loadingOverlayComponent}
          loadingOverlayComponentParams={loadingOverlayComponentParams}
          domLayout="autoHeight"
          datasource={dataSource}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onRowClicked={handleRowClicked}
          onRowDoubleClicked={handleRowClicked}
          onCellContextMenu={handleContextMenu}
          onSortChanged={handleSortChanged}
          onGridReady={handleGridReady}
          rowModelType="infinite"
          rowHeight={rowHeight}
          headerHeight={34}
          cacheBlockSize={pageSize}
          maxBlocksInCache={1}
          context={currentContext}
          rowBuffer={50}
          suppressColumnVirtualisation
          icons={{
            sortAscending: '<i class="icon sort alphabet up"/>',
            sortDescending: '<i class="icon sort alphabet down"/>',
            columnMoveHide: '<i class="icon eye slash"/>',
            columnMoveMove: '<i class="icon expand arrows alternate"/>',
            smallDown: '<i class="icon sort down"/>',
            filter: '<i class="icon filter"/>',
            menu: '<i class="icon bars"/>',
          }}
        />
        <div style={{ height: 0 }}>
          {/* Used for Object Links Modal */}
          <ContentModal
            icon="linkify"
            header="Object Links"
            style="success"
            message={<ObjectLinksModal companyId={companyId as string} objectLinks={objectLinks as ObjectLink} />}
            open={showObjectLinksModal}
            closeEvent={() => setShowObjectLinksModal(false)}
          />
        </div>
      </div>
    </div>
  );
};

DSTable.defaultProps = {
  inverted: false,
  celled: false,
  hide: [],
  singleLine: false,
  striped: false,
  compact: false,
  fixed: false,
  rowSelectable: false,
  rowClickEvent: undefined,
};

export default DSTable;
