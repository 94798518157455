import { Action } from 'redux';
import * as SystemTypes from 'app/actions/systemTypes';
import { EnumOptionOfSystemTask } from 'app/helpers/types';

export type EnumState = {
  systemTaskList: Array<EnumOptionOfSystemTask> | undefined
};

export const defaultEnumState = {
  systemTaskList: [],
};

type EnumActions =
  Action<typeof SystemTypes.GET_SYSTEM_TASKS_COMPLETED> & {
    resp: EnumOptionOfSystemTask[] | undefined
  };

export default (enumState: EnumState = defaultEnumState, action: EnumActions): EnumState => {
  switch (action.type) {
    case SystemTypes.GET_SYSTEM_TASKS_COMPLETED:
      return {
        ...enumState, systemTaskList: action.resp,
      };
    default:
      return enumState;
  }
};
