import IdentifierIndexSB, { IdentifierSearchForm } from 'app/components/shared/sidebar-children/IdentifierSB/IdentifierSB';
import InvitationIndexSB, { InvitationSearchForm } from 'app/components/shared/sidebar-children/InvitationTicket/InvitationSB';
import AltSecIdSB, { AltSecIdSearchForm } from 'app/components/shared/sidebar-children/AltSecIdSB/AltSecIdSB';
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { Dropdown, Transition } from 'semantic-ui-react';

const singleUserObjectOptions = [
  { text: 'Identifier', value: 'Identifier' },
  { text: 'Invitation Ticket', value: 'InvitationTicket' },
  { text: 'AltSecId', value: 'AltSecId' },
];

export type SingleUserForm = IdentifierSearchForm | InvitationSearchForm | AltSecIdSearchForm;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const singleUserBar: FunctionComponent<any> = ({
  disabled,
  searchEvent,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => {
  type ObjectTypeComponentsValues = 'Identifier' | 'InvitationTicket' | 'AltSecId';

  const ObjectTypeComponentMap: Record<ObjectTypeComponentsValues, JSX.Element> = {
    Identifier:
  <IdentifierIndexSB
    disabled={disabled}
    searchEvent={(data: IdentifierSearchForm) => searchEvent(data)}
  />,
    InvitationTicket:
  <InvitationIndexSB
    disabled={disabled}
    searchEvent={(data: InvitationSearchForm) => searchEvent(data)}
  />,
    AltSecId:
  <AltSecIdSB
    disabled={disabled}
    searchEvent={(data: AltSecIdSearchForm) => searchEvent(data)}
  />,
  };

  const [objectType, setObjectType] = useState<ObjectTypeComponentsValues>('AltSecId');
  const id = _.uniqueId('IndexSidebar-');
  const indexObjectTypeDropdownId = `${id}-IndexObjectTypeDropdown`;

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="IndexSidebar">
        <label className="label" htmlFor={indexObjectTypeDropdownId}>Index Object Type</label>
        <Dropdown
          placeholder="Search/Select Index Object Type"
          onChange={(e, { value }) => setObjectType(value as ObjectTypeComponentsValues)}
          fluid
          disabled={disabled}
          search
          selection
          id={indexObjectTypeDropdownId}
          options={singleUserObjectOptions}
          value={objectType}
        />
        <div className="component-map">
          {ObjectTypeComponentMap[`${objectType}`]}
        </div>
      </div>
    </Transition>
  );
};

export default singleUserBar;
