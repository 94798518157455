import { searchIndexStore } from 'app/actions/indexStoreActions';
import AltSecIdSB, { AltSecIdSearchForm } from 'app/components/shared/sidebar-children/AltSecIdSB/AltSecIdSB';
import ApplicationIndexSB, { ApplicationIndexSearchForm } from 'app/components/shared/sidebar-children/ApplicationIndexSB/ApplicationIndexSB';
import TrustedRealmIndexSB, { TrustedRealmIndexSearchForm } from 'app/components/shared/sidebar-children/TrustedRealmIndexSB/TrustedRealmIndexSB';
import { State } from 'app/reducers/state';
import { TabType } from 'app/typings';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { IndexStoreObjectTypes, ObjectTypeComponentKeys } from './IndexSidebarOptions';

const IndexSidebar = () => {
  const dispatch = useDispatch();
  const tabsState = useSelector((state: State) => state.tabs);
  const activeTabState = useSelector((state: State) => state.activeTabs);
  const [objectType, setObjectType] = useState<ObjectTypeComponentKeys>('AltSecId');
  const [activePrimaryTab, setActivePrimaryTabs] = useState<string | undefined>(undefined);

  useEffect(() => {
    setActivePrimaryTabs(activeTabState.getActiveTab(TabType.Index));
  }, [activeTabState.getActiveTab(TabType.Index)]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSearch = (search: any) => {
    const activeTab = tabsState?.getTab(TabType.Index, activePrimaryTab as string);
    const finalObj = { ...search, companyIdentifier: activeTab?.name };
    dispatch(searchIndexStore(finalObj, objectType));
  };

  const disableCategorySelect = (): boolean => {
    if (tabsState) {
      const activeTab = activeTabState.getActiveTab(TabType.Index);
      const activeTabData = tabsState.getTab(TabType.Index, activeTab);
      return !!((activeTabData?.name === 'Error' || activeTabData?.name === 'Loading')); // Disable if loading or error
    }
    return true;
  };

  const ObjectTypeComponentMap: Record<ObjectTypeComponentKeys, JSX.Element> = {
    AltSecId:
  <AltSecIdSB
    disabled={disableCategorySelect()}
    searchEvent={(data: AltSecIdSearchForm) => updateSearch(data)}
  />,
    Application:
  <ApplicationIndexSB
    disabled={disableCategorySelect()}
    searchEvent={(data: ApplicationIndexSearchForm) => updateSearch(data)}
  />,
    TrustedRealm:
  <TrustedRealmIndexSB
    disabled={disableCategorySelect()}
    searchEvent={(data: TrustedRealmIndexSearchForm) => updateSearch(data)}
  />,
  };

  const id = _.uniqueId('IndexSidebar-');
  const indexObjectTypeDropdownId = `${id}-IndexObjectTypeDropdown`;

  return (
    <div className="IndexSidebar">
      <label className="label" htmlFor={indexObjectTypeDropdownId}>Index Object Type</label>
      <Dropdown
        placeholder="Search/Select Index Object Type"
        onChange={(e, { value }) => setObjectType(value as ObjectTypeComponentKeys)}
        fluid
        search
        selection
        id={indexObjectTypeDropdownId}
        options={IndexStoreObjectTypes}
        value={objectType}
      />
      <div className="component-map">
        {ObjectTypeComponentMap[`${objectType}`]}
      </div>
    </div>
  );
};

export default IndexSidebar;
