/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CommonModelsTreeNodeModel } from '../models/CommonModelsTreeNodeModel';
import { HttpFile } from '../http/http';

export class CommonModelsDirectoryObjectTreeNodeModel {
    'displayName'?: string | null;
    'objectId'?: string | null;
    'children'?: Array<CommonModelsTreeNodeModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string",
            "format": ""
        },
        {
            "name": "objectId",
            "baseName": "objectId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "children",
            "baseName": "children",
            "type": "Array<CommonModelsTreeNodeModel>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CommonModelsDirectoryObjectTreeNodeModel.attributeTypeMap;
    }

    public constructor() {
    }
}

