import {
  UpdateUserPreferencesCompleted, GetUserPreferencesCompleted, LoadLocalUserPreferences, UpdateUserPreferencesFailed, StageUserPreferencesRequested,
} from 'app/actions/preferenceActions';
import { TimeFormatType, ValidPreferences } from 'app/typings';
import moment from 'moment';
import * as PreferencesTypes from '../actions/preferenceTypes';

export const defaultPreferencesState = {
  data: {
    hideSidebar: false,
    activeTheme: 'system-default',
    hiddenColumns: [],
    availableColumnsToHide: ['#', 'Version', 'Last Write Time', 'USN Local', 'USN Orig', 'Originating Authority', 'Originating Timestamp'],
    tableFontSize: 'small',
    pageSize: 100,
    timeFormat: TimeFormatType.Short,
    timeUtc: false,
    persistTabs: true,
    tableHighlight: true,
    tableHighlightColor: '#fff200bf',
    rowClickBehavior: 'Single Click',
    showAnnouncements: true,
    tabPersistBehavior: 'Discard',
    showPinButton: true,
    searchOpenByDefault: true,
    hideEmptyBrowseObjects: false,
    openSingleSearchResult: false,
  },
  createdAt: moment(),
  username: 'N/A',
};

export type UserPreferencesState = {
  data: ValidPreferences,
  staged?: ValidPreferences
};

export type PrefencesAction =
  | UpdateUserPreferencesCompleted
  | GetUserPreferencesCompleted
  | UpdateUserPreferencesFailed
  | LoadLocalUserPreferences
  | StageUserPreferencesRequested;

function mergePreferences(incomingData: ValidPreferences) {
  return { ...defaultPreferencesState.data, ...incomingData };
}

export default (preferencesState: UserPreferencesState = defaultPreferencesState, action: PrefencesAction): UserPreferencesState => {
  switch (action.type) {
    case PreferencesTypes.STAGE_USER_PREFERENCES:
      return { ...preferencesState, staged: action.data as ValidPreferences };
    case PreferencesTypes.LOAD_LOCAL_USER_PREFERENCES:
      try {
        if (window.localStorage.preferences !== undefined) {
          return JSON.parse(window.localStorage.preferences);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Failed to load local preferences. Reverting to defaults.', error);
        return defaultPreferencesState;
      }

      return preferencesState;
    case PreferencesTypes.UPDATE_USER_PREFERENCES_COMPLETED:
    case PreferencesTypes.GET_USER_PREFERENCES_COMPLETED:
      if (action.resp.result) {
        const newPrefs = {
          ...preferencesState,
          data: mergePreferences(action.resp.result.data as ValidPreferences),
          staged: undefined,
          username: action.resp.result.username,
          createdAt: action.resp.result.createdAt,
        };
        window.localStorage.preferences = JSON.stringify(newPrefs);
        return newPrefs;
      }

      return preferencesState;
    case PreferencesTypes.UPDATE_USER_PREFERENCES_FAILED:
      window.localStorage.preferences = JSON.stringify(action.resp);
      return {
        data: mergePreferences(action.resp.data as ValidPreferences),
      };
    default:
      return preferencesState;
  }
};
