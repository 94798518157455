import {
  call, takeEvery, SagaGenerator, select, put, take,
} from 'typed-redux-saga';
import type { App } from 'app/store';
import * as indexStoreTypes from 'app/actions/indexStoreTypes';
import * as companyTypes from 'app/actions/companyTypes';

import { getApplicationStub, getTrustedRealmStub } from 'app/services/indexStore';
import {
  createTabWithAsyncOperation,
} from 'app/sagas/tabSagas';
import {
  GenericDictionaryParams,
  TabGeneratorEffect,
  TabType,
} from 'app/typings';
import { State } from 'app/reducers/state';
import { assertTrue, getErrormessage, promiseToSaga } from 'app/helpers/helpers';
import { DSObjectDirectoryProperty, IResponseOfDSObject } from 'app/proxyClients';
import _ from 'lodash';
import { getCompanyByName, getSingleObject } from 'app/actions/companyActions';
import { getApplication } from 'app/services/applications';
import { setTabModalError } from 'app/actions/tabActions';

const getTabState = (state: State) => state.tabs;
const getSingleObjectDisplayName = (data: IResponseOfDSObject) => data.data?.displayName;

type FetchApplicationFromIndexRequest = GenericDictionaryParams & {
  appId: string,
  tabId: string,
  parentTab: string
};

function* fetchApplicationStubAsync(app: App, params: GenericDictionaryParams): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'Index',
    tabRoutingArea: TabType.Index,
    actionBegin: indexStoreTypes.GET_APPLICATION_STUB,
    actionComplete: indexStoreTypes.GET_APPLICATION_STUB_COMPLETED,
    actionFailed: indexStoreTypes.GET_APPLICATION_STUB_FAILED,
    asyncDataFetch: promiseToSaga(getApplicationStub),
    getDisplayName: getSingleObjectDisplayName,
  });
}

function* fetchTrustedRealmStubAsync(app: App, params: GenericDictionaryParams): TabGeneratorEffect<void> {
  yield* call(createTabWithAsyncOperation, app, {
    params,
    tabType: 'Index',
    tabRoutingArea: TabType.Index,
    actionBegin: indexStoreTypes.GET_TRUSTED_REALM_STUB,
    actionComplete: indexStoreTypes.GET_TRUSTED_REALM_STUB_COMPLETED,
    actionFailed: indexStoreTypes.GET_TRUSTED_REALM_STUB_FAILED,
    asyncDataFetch: promiseToSaga(getTrustedRealmStub),
    getDisplayName: getSingleObjectDisplayName,
  });
}

function* fetchApplicationFromIndexAsync(app: App, params: FetchApplicationFromIndexRequest): TabGeneratorEffect<void> {
  try {
    const appStub = yield* call(getApplicationStub, app, {
      appId: params.appId,
    });

    // From the AppStub "data" we need to find the DirectoryProperties array
    // and then then AppContextId property from within that array.
    if (Array.isArray(appStub?.data?.directoryProperties)) {
      const appContextIdProperty = _.find(appStub?.data?.directoryProperties, { displayName: 'AppContextId' }) as DSObjectDirectoryProperty;
      if (appContextIdProperty !== undefined && Array.isArray(appContextIdProperty.values)) {
        const appContextId = appContextIdProperty.values[0];

        const appResp = yield* call(getApplication, app, appContextId, params.appId as string);

        if (appResp) {
          let tabState = yield* select(getTabState);
          assertTrue(tabState !== undefined);

          let foundTab = _.find(tabState.tabStore[TabType.Company].tabs, ['contextId', appContextId]);
          if (foundTab === undefined) {
            yield* put(getCompanyByName({
              companyIdentifier: appContextId,
            }));

            // Look for the company tab again
            yield* take(companyTypes.GET_COMPANY_BY_NAME_COMPLETED);

            tabState = yield* select(getTabState);
            assertTrue(tabState !== undefined);

            foundTab = _.find(tabState.tabStore[TabType.Company].tabs, ['contextId', appContextId]);
          }

          if (foundTab !== undefined) {
            // Company is already open
            yield* put(getSingleObject({
              contextId: appContextId,
              objectId: appResp.data?.objectId as string,
            }, foundTab.id));
          }
        } else {
          yield* put(setTabModalError(params.tabId, TabType.Company, params.parentTab, 'Application not found'));
        }
      }
    } else {
      yield* put(setTabModalError(params.tabId, TabType.Company, params.parentTab, 'App Stub not found'));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (ex: any) {
    yield* put(setTabModalError(params.tabId, TabType.Company, params.parentTab, getErrormessage(ex)));
  }
}

export default function* watchAll(app: App): SagaGenerator<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>indexStoreTypes.GET_APPLICATION_STUB, fetchApplicationStubAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>indexStoreTypes.GET_TRUSTED_REALM_STUB, fetchTrustedRealmStubAsync, app);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield* takeEvery(<any>indexStoreTypes.FIND_APPLICATION_FROM_INDEX_STORE, fetchApplicationFromIndexAsync, app);
}
