/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = Unknown 1 = NetId 2 = GuestNetId 3 = AuthorizationCode
*/
export enum MicrosoftOnlineDSExplorerContractUserIdentifierType {
    Unknown = 0,
    NetId = 1,
    GuestNetId = 2,
    AuthorizationCode = 3
}
