import _ from 'lodash';
import * as CompanyTypes from 'app/actions/companyTypes';
import * as tabTypes from 'app/actions/tabTypes';
import {
  DirectoryResponse,
  PagedResponse,
  ErrorResponse,
  isErrorResponse,

  LicenseResults,
} from 'app/reducers/shared';
import { Action } from 'redux';
import { SearchResults } from 'app/typings';
import { BrowsedObjectSearchModel } from 'app/helpers/types';

export type CompaniesState = {
  companies: Array<Company>;
  objectCounts: Record<string, BrowsedObjectSearchModel[]>;
  error: boolean;
  message: string;
  loading: boolean;
  findByContextId(contextId?: string): Company | undefined;
};

type Company = DirectoryResponse & {
  displayName?: string | null;
  SearchResults: Record<string, SearchResults | LicenseResults>;
};

const state: CompaniesState = {
  companies: [],
  objectCounts: {},
  error: false,
  message: '',
  loading: false,
  findByContextId(contextId) {
    return _.find(this.companies, ['contextId', contextId]);
  },
};

export type CompanyActions =
  | GetCompanyByNameFailed
  | GetCompanyByNameCompletedAction
  | GetCompanyByName
  | GetCompanyObjectCountsCompleted
  | GetCompanyObjectCountsFailed
  | ResetAction
  | UpdateSearchCompanies
  | RemoveCompanyTabAction
  | CompanySearchObjectType;
type RemoveCompanyTabAction = Action<typeof CompanyTypes.REMOVE_COMPANY_TAB> & {
  contextId: unknown;
  index: number;
};
type ResetAction = Action<typeof CompanyTypes.RESET_ERROR>;
type GetCompanyByName = Action<typeof CompanyTypes.GET_COMPANY_BY_NAME>;
type GetCompanyObjectCountsCompleted = Action<
  typeof CompanyTypes.GET_COMPANY_OBJECT_COUNTS_COMPLETED
> & { resp: { contextId: string, counts: BrowsedObjectSearchModel[] } };
type GetCompanyObjectCountsFailed = Action<typeof CompanyTypes.GET_COMPANY_OBJECT_COUNTS_FAILED>;
type UpdateSearchCompanies = Action<
  typeof CompanyTypes.UPDATE_SEARCH_COMPANIES
>;
type GetCompanyByNameFailed = Action<
  typeof CompanyTypes.GET_COMPANY_BY_NAME_FAILED
> & { error: { message: string } };
type GetCompanyByNameCompletedAction = Action<
  typeof CompanyTypes.GET_COMPANY_BY_NAME_COMPLETED
> & {
  resp: ErrorResponse | Company;
};
type CompanySearchObjectType = Action<typeof tabTypes.SEARCH_TAB_COMPLETED> & {
  resp: ErrorResponse | PagedResponse;
  params: Record<string, unknown> & {
    searchType: string;
  };
  searchType: string;
};

function replaceCompany(
  currentState: CompaniesState,
  resp: PagedResponse,
  updatedCompany?: Company,
): Array<Company> {
  const currentCompany = _.find(currentState.companies, [
    'contextId',
    resp.contextId,
  ]);
  if (currentCompany === undefined || updatedCompany === undefined) {
    return currentState.companies;
  }

  const stateWithoutCurrentCompany = _.without(
    currentState.companies,
    currentCompany,
  );
  return [...stateWithoutCurrentCompany, updatedCompany];
}

function updateCompanySearch(
  currentState: CompaniesState,
  searchType: string,
  resp: SearchResults,
) {
  const currentCompany = _.find(currentState.companies, [
    'contextId',
    resp.searchData?.contextId,
  ]);
  if (currentCompany === undefined) {
    return undefined;
  }

  return {
    ...currentCompany,
    SearchResults: {
      ...currentCompany.SearchResults,
      [searchType]: resp,
    },
  };
}

export default (
  companiesState: CompaniesState = state,
  action: CompanyActions,
): CompaniesState => {
  switch (action.type) {
    case tabTypes.SEARCH_TAB_COMPLETED:
      return isErrorResponse(action.resp)
        ? {
          ...companiesState,
          error: true,
          loading: false,
          message: action.resp.ExceptionMessage,
        }
        : {
          ...companiesState,
          // eslint-disable-next-line max-len
          companies: replaceCompany(
            companiesState,
            action.resp,
            updateCompanySearch(companiesState, action.searchType, {
              params: action.params,
              searchData: action.resp,
            }),
          ),
        };
    case CompanyTypes.GET_COMPANY_BY_NAME_COMPLETED: // Search successful with results or error
      return isErrorResponse(action.resp)
        ? {
          ...companiesState,
          companies: [...companiesState.companies],
          error: true,
          message: action.resp.InnerException.InnerException.ExceptionMessage,
          loading: false,
        } // No results found
        : {
          ...companiesState,
          companies: [
            ...companiesState.companies,
            { ...action.resp, displayName: action.resp.data?.displayName, data: undefined },
          ], // We don't need to keep the company Data any longer
          error: false,
          message: '',
          loading: false,
        }; // Results found
    case CompanyTypes.GET_COMPANY_BY_NAME_FAILED: // Failed to search
      return {
        ...companiesState,
        error: true,
        message: action.error.message,
        loading: false,
      };
    case CompanyTypes.GET_COMPANY_OBJECT_COUNTS_COMPLETED:
      return {
        ...companiesState,
        error: false,
        loading: false,
        objectCounts: { ...companiesState.objectCounts, [action.resp.contextId]: action.resp.counts },
      };
    case CompanyTypes.GET_COMPANY_OBJECT_COUNTS_FAILED:
      return {
        ...companiesState,
        error: true,
        loading: false,
      };
    case CompanyTypes.GET_COMPANY_BY_NAME: // Trigger search
      return {
        ...companiesState,
        loading: true,
        message: '',
        error: false,
      };
    case CompanyTypes.UPDATE_SEARCH_COMPANIES: // Trigger update search
      return {
        ...companiesState,
        loading: true,
        message: '',
        error: false,
      };
    case CompanyTypes.RESET_ERROR: // Reset errors
      return {
        ...companiesState,
        loading: false,
        message: '',
        error: false,
      };
    case CompanyTypes.REMOVE_COMPANY_TAB: // Remove a company tab
      return {
        ...companiesState,
        companies: companiesState.companies.filter(
          (comp, i) => comp.contextId !== action.contextId || i !== action.index,
        ),
      };
    default:
      return companiesState;
  }
};
