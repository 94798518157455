/* eslint-disable no-bitwise */
import './TabManager.scss';

import { State } from 'app/reducers/state';
import React, {
  useEffect, useState, FunctionComponent, ReactElement,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Icon, Menu, TabProps,
} from 'semantic-ui-react';
import {
  removeTab, activateTab, setTabModalContent, pinTab,
} from 'app/actions/tabActions';
import {
  TabDetails,
  TabType,
} from 'app/typings';
import _ from 'lodash';
import removeObjectLinks from 'app/actions/objectLinkActions';
import ZeroState from '../ZeroState/ZeroState';
import PaginatedTab, { PaginatedContextMenuProps } from '../PaginatedTab/PaginatedTab';
import ContentModal from '../ContentModal/ContentModal';
import TabManagerContextMenu, { ContextOptions } from '../ContextMenu/TabManagerContextMenu';

interface TabManagerProps {
  tabType: TabType,
  tabId?: string,
  hidepagingcontrolswhendisabled?: string,
  className?: string,
}

export type Pane = {
  key: string,
  menuItem: {
    key: React.ReactElement['key']
  },
  pane: {
    key: React.ReactElement['key'],
    content: ReactElement
  }
};

const TabManager: FunctionComponent<TabManagerProps> = (props: TabManagerProps) => {
  const dispatch = useDispatch();
  const preferenceState = useSelector((state: State) => state.preferences);
  const [panes, setPanes] = useState<Pane[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [contextData, setContextData] = useState<PaginatedContextMenuProps>();
  const [activeIndex, setActiveIndex] = useState<number>();

  const closeContextMenu = () => {
    setShowMenu(false);
    setContextData(undefined);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDocumentClick = (e: any) => {
      if (contextData && (e.target.outerHTML !== contextData.target.outerHTML)) {
        closeContextMenu();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleContextClick = (e: any) => {
      if (contextData && (e.target.parentElement !== contextData.target.parentElement)) {
        closeContextMenu();
      }
    };

    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('contextmenu', handleContextClick);

    return function cleanup() {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('contextmenu', handleContextClick);
    };
  }, [contextData]);

  const handleTabContext = (tab: PaginatedContextMenuProps) => {
    setContextData(tab);
    setShowMenu(true);
  };

  const {
    tabType, tabId, hidepagingcontrolswhendisabled, className,
  } = props;
  const tabState = useSelector((state: State) => state.tabs);
  const activeTab = useSelector((state: State) => state.activeTabs.getActiveTab(tabType, tabId));

  const dispatchPinTab = (e: React.MouseEvent | undefined, id: string | undefined, type: TabType, parentTab?: string) => {
    if (e) {
      e.stopPropagation();
    }

    dispatch(pinTab(id, type, parentTab as string));
    dispatch(removeTab(id, type, parentTab)); // Remove tab and trigger new state to be returned
  };

  const dispatchCloseTab = (e: React.MouseEvent | undefined, id: string | undefined, type: TabType, parentTab?: string) => {
    if (e) {
      e.stopPropagation();
    }

    const closingIndex = _.findIndex(panes, ['key', id]);
    if (activeIndex === closingIndex && closingIndex > 0) {
      dispatch(activateTab(panes[activeIndex - 1].key, tabType, parentTab));
      setActiveIndex(activeIndex - 1);
    }
    dispatch(removeTab(id, type, parentTab)); // Remove tab and trigger new state to be returned
  };

  const dispatchRemoveObjectLink = (tabDetails: TabDetails) => {
    // eslint-disable-next-line
    const tabParams: any = tabDetails.metadata?.params;
    // Only remove if single object
    if (tabParams && tabParams.type === 'GET_SINGLE_OBJECT') dispatch(removeObjectLinks(tabParams.data.objectId));
  };

  let tabsToShow: TabDetails[] | undefined = [];

  if (tabId) {
    tabsToShow = tabState?.getTab(tabType, tabId)?.children.tabs;
  } else {
    tabsToShow = tabState?.getTabs(tabType);
  }

  // eslint-disable-next-line consistent-return
  function getModalContent(tab: TabDetails) {
    if (!tab.modalContent) {
      return undefined;
    }

    const {
      icon = 'warning sign',
      header = 'Error',
      style = 'error',
      content = undefined,
    } = tab.modalContent;

    if (typeof tab.modalContent.content === 'string') {
      return (
        <ContentModal
          icon={icon}
          header={header}
          style={style}
          message={content}
          open
          closeEvent={() => {
            dispatch(setTabModalContent(tab.id as string, tabType, tabId as string));
          }}
        />
      );
    }

    // Otherwise
    return tab.modalContent.content;
  }

  useEffect(() => {
    if (tabsToShow) { // If any tabs
      const { showPinButton: pinButtonPreference } = preferenceState.data;
      const showPinButton = tabType === TabType.Company
        ? tabId && pinButtonPreference
        : tabType !== 'Key' && tabType !== 'Index' && tabType !== 'Partition' && pinButtonPreference;

      const tabPanes = tabsToShow.map((t) => {
        const contentModal = getModalContent(t);

        return {
          key: t.id,
          parent: tabId,
          tabType,
          allowClose: t.allowClose,
          // Format into tabs
          menuItem: (
            <Menu.Item key={`tab-${t.id}`} className="tab primary-tab-menu-item">
              {/* eslint-disable-next-line */}
              {t.name && t.name.length > 40 ? (<span title={t.name}>{_.truncate(t.name, { length: 40 })}</span>) : t.name}
              {(showPinButton) ? (
                <Button
                  id="pin-tab-tour"
                  className={`tab-action-button pin ${t.allowClose === false ? 'rightMarg' : ''}`}
                  icon
                  onClick={(e) => { dispatchPinTab(e, t.id, tabType, tabId); }}
                >
                  <Icon name="pin" />
                </Button>
              ) : undefined}
              {
                t.allowClose !== false ? (
                  <Button
                    className="tab-action-button reduceMargin"
                    icon
                    onClick={(e) => { dispatchCloseTab(e, t.id, tabType, tabId); dispatchRemoveObjectLink(t); }}
                  >
                    <Icon name="close" />
                  </Button>
                ) : undefined
              }

            </Menu.Item>
          ),
          pane: {
            key: `pane-${t.id}-${t.refreshed}`, // tab id for each unique tab + refresh counter so only refreshed tabs get re-drawn
            content: (
              <div style={{
                height: 'unset', display: 'flex', flexDirection: 'column',
              }}
              >
                {t.component}
                {contentModal}
              </div>),
          },
        };
      });

      setPanes(tabPanes as Pane[]);

      const index = tabState?.getActiveIndex(tabType, activeTab, tabId) as number;
      setActiveIndex(index);

      // Only run when no active tab has been found
      if (index > -1 && activeTab === '') {
        dispatch(activateTab(tabPanes[+`${index}`].key, tabType));
      }
    } else {
      setPanes([]);
    }
  }, [tabsToShow, preferenceState]); // Whenever Tabs change OR preferences

  useEffect(() => {
    const index = tabState?.getActiveIndex(tabType, activeTab, tabId) as number;
    setActiveIndex(index);
  }, [activeTab]);

  const dispatchActivateTab = (data: TabProps) => {
    if (data.panes && data.activeIndex !== undefined) {
      const currentIndex = data.activeIndex as number;
      if (data.panes[+`${currentIndex}`]) {
        const { key } = data.panes[+`${currentIndex}`] as Pane;
        dispatch(activateTab(key, tabType, tabId));
      }
    }
  };

  const classNames = ['tabManager', tabType, className];

  if (tabId) {
    // Cheap way to tell if this is a TabManager inside another TabManager
    classNames.push('secondary-tabs-container');
  } else {
    classNames.push('parent-tab-manager');
  }

  const paginatedTab = React.createElement(PaginatedTab, {
    hidepagingcontrolswhendisabled,
    className: classNames.join(' '),
    renderActiveOnly: false,
    // eslint-disable-next-line
    activeIndex: activeIndex == -1 ? 0 : activeIndex,
    onTabChange: (_e, data) => dispatchActivateTab(data),
    panes,
    tabContextDelegate: handleTabContext,
  });

  const {
    x = 0, y = 0, tab = '', parent, e: contextEvent, tabType: contextTabType, allowClose = true,
  } = contextData || { tabType };

  let contextOptions = ContextOptions.all;
  if (!allowClose) {
    // Remove Close options if not allowed
    contextOptions &= ~ContextOptions.closeTab;
  }

  if (tabType !== TabType.Company && tabType !== TabType.System) {
    // Only Allow Pinning for Company and System
    contextOptions &= ~ContextOptions.pinTab;
  }

  if (tabType === TabType.Company && !tabId) {
    // No Pinning Allowed of the Company Parent
    contextOptions &= ~ContextOptions.pinTab;
  }

  return (
    <>
      <TabManagerContextMenu
        e={contextEvent}
        tab={tab}
        parentTab={parent}
        tabType={contextTabType}
        x={x}
        y={y}
        showMenu={showMenu}
        contextOptions={contextOptions}
        closeMenu={closeContextMenu}
        dispatchCloseTab={dispatchCloseTab}
        dispatchPinTab={dispatchPinTab}
      />
      {
        tabsToShow && tabsToShow.length
          ? paginatedTab
          : <ZeroState text="" />
      }
    </>
  );
};

export default TabManager;

TabManager.defaultProps = {
  tabId: undefined,
  hidepagingcontrolswhendisabled: 'false',
  className: '',
};
