import React, { ReactNode } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { defaultAddressListObjectsForm } from 'app/components/shared/sidebar-children/AddressListObjectsSB/AddressListObjectsSB';
import { defaultApplicationsForm } from 'app/components/shared/sidebar-children/ApplicationsSB/ApplicationsSB';
import { defaultDelegationEntriesForm } from 'app/components/shared/sidebar-children/DelegationEntriesSB/DelegationEntriesSB';
import { defaultDevicesForm } from 'app/components/shared/sidebar-children/DeviceSB/DeviceSB';
import { defaultGroupsForm } from 'app/components/shared/sidebar-children/GroupsSB/GroupsSB';
import { defaultKeyGroupsForm } from 'app/components/shared/sidebar-children/KeyGroupsSB/KeyGroupsSB';
import { defaultSPForm } from 'app/components/shared/sidebar-children/ServicePrincipalsSB/ServicePrincipalsSB';
import { defaultUsersIndexForm } from 'app/components/shared/sidebar-children/UsersSB/UserIndexSB';
import { CompanyContextObjectClasses } from 'app/proxyClients';
import _ from 'lodash';

interface FragmentProps {
  key: React.Key | null | undefined,
  children: ReactNode
}

function Fragment({ key, children }: FragmentProps) {
  return <React.Fragment key={key}>{children}</React.Fragment>;
}

export const ObjectTypes = [
  { value: 'Single Object', text: 'Single Object' },
  { value: 'Objects', text: 'Objects' },
  {
    key: 'objectTypeDivider',
    as: Fragment,
    content: <Dropdown.Divider />,
  },
  { value: 'Address List Objects', text: 'Address List Objects' },
  { value: 'Applications', text: 'Applications' },
  { value: 'Contracts', text: 'Contracts' },
  { value: 'Delegation Entries', text: 'Delegation Entries' },
  { value: 'Deleted', text: 'Deleted' },
  { value: 'Devices', text: 'Devices' },
  { value: 'DirSync Errors', text: 'DirSync Errors' },
  { value: 'Entitlement Grants', text: 'Entitlement Grants' },
  { value: 'Group Licensing Errors', text: 'Group Licensing Errors' },
  { value: 'Groups', text: 'Groups' },
  { value: 'Key Groups', text: 'Key Groups' },
  { value: 'Licenses', text: 'Licenses' },
  { value: 'Provisioning Status', text: 'Provisioning Status' },
  { value: 'Role Assignments', text: 'Role Assignments' },
  { value: 'Scoped Members', text: 'Scoped Members' },
  { value: 'Service Principals', text: 'Service Principals' },
  { value: 'Single User', text: 'Single User' },
  { value: 'Trusted Realm', text: 'Trusted Realm' },
  { value: 'Users', text: 'Users' },
];

export const ContextObjects = _.keysIn(CompanyContextObjectClasses);

export type ObjectTypeComponentKeys =
'Address List Objects'
| 'Applications'
| 'Contracts'
| 'Delegation Entries'
| 'Deleted'
| 'Device'
| 'Devices'
| 'DirSync Errors'
| 'Entitlement Grants'
| 'Group Licensing Errors'
| 'Groups'
| 'Key Groups'
| 'Provisioning Status'
| 'Role Assignments'
| 'Service Principals'
| 'Single Object'
| 'Single User'
| 'Licenses'
| 'Objects'
| 'Scoped Members'
| 'Trusted Realm'
| 'Users'
| '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultSearches: any = {
  'Address List Objects': defaultAddressListObjectsForm,
  Applications: defaultApplicationsForm,
  'Delegation Entries': defaultDelegationEntriesForm,
  Devices: defaultDevicesForm,
  Groups: defaultGroupsForm,
  'Key Groups': defaultKeyGroupsForm,
  Licenses: {},
  'Service Principals': defaultSPForm,
  Users: defaultUsersIndexForm,
  'Provisioning Status': {},
};
