/* eslint-disable no-console */
import axios from 'axios';
import { SagaReturnType } from 'redux-saga/effects';
import { DropdownItemProps } from 'semantic-ui-react';
import { call, SagaGenerator } from 'typed-redux-saga';
import { Buffer } from 'buffer';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { toast } from 'react-toastify';

// Download a file
export function DownloadFile(fileName: string, fileContent: string, fileType: string) {
  const element = document.createElement('a');
  const file = new Blob([fileContent], { type: fileType });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

export function DownloadBlob(fileName: string, file: Blob) {
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

export function getQueryStringParams(query: string): Record<string, string> {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params: Record<string, string>, param) => {
        const [key, value] = param.split('=');
        // eslint-disable-next-line no-param-reassign
        params[`${key}`] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
}

export function decodeBase64(s: string) {
  const decoded = Buffer.from(s, 'base64').toString();
  return decoded;
}

export function parseXmlToJson(xml: string) {
  const json: Record<string, unknown> = {};
  // eslint-disable-next-line
  for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
    const key = res[1] || res[3];
    const value = res[2] && parseXmlToJson(res[2]);
    json[`${key}`] = ((value && Object.keys(value).length) ? value : res[2]) || null;
  }
  return json;
}

export function numberGenerator(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function generateId() {
  // Generate unique IDs for use as pseudo-private/protected names.
  // Similar in concept to
  // <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
  //
  // The goals of this function are twofold:
  //
  // * Provide a way to generate a string guaranteed to be unique when compared
  //   to other strings generated by this function.
  // * Make the string complex enough that it is highly unlikely to be
  //   accidentally duplicated by hand (this is key if you're using `ID`
  //   as a private/protected name on an object).
  //
  // Use:
  //
  //     var privateName = generateId();
  //     var o = { 'public': 'foo' };
  //     o[privateName] = 'bar';
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

export function isSidebarDisabled(route: string): boolean {
  const sidebarDisabledRoutes = ['/toolbox', '/announcementManagement'];
  if (sidebarDisabledRoutes.indexOf(route) > -1) {
    return true;
  }
  return false;
}

export function guidChecker(guid: string): boolean {
  // eslint-disable-next-line
  const reg = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/i); // UUID Version 1 through 5
  return guid === '' ? false : reg.test(guid);
}

export function generateGuid(): string {
  return uuidv4();
}

export function unsafeToNumber(maybeNumber: unknown): number {
  const toNumber = Number(maybeNumber);
  if (Number.isNaN(toNumber)) throw new Error(`Expected ${maybeNumber} to be a number.`);

  return toNumber;
}

export function assertTrue(condition: boolean): asserts condition {
  if (!condition) throw new Error('Assertion failed');
}

export function promiseToSaga<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TFn extends ((...args: any[]) => Promise<any>)>(
  fn: TFn): (...args: Parameters<TFn>) => SagaGenerator<SagaReturnType<TFn>> {
  return function* sagafied(...args: Parameters<TFn>): SagaGenerator<SagaReturnType<TFn>> {
    return yield* call(fn, ...args);
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function enumToDropDownList(enumObj: any, excludeList?: string[], sorted?: boolean): DropdownItemProps[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const enumMembers: any[] = Object.keys(enumObj).map((key: any) => enumObj[`${key}`]);
  const enumValues: string[] = enumMembers.filter((v) => typeof v === 'string' && !excludeList?.includes(v)).sort();
  const dropDownOptions = enumValues.map((option: string, i: number) => ({
    key: i + 1,
    text: option,
    value: enumObj[`${option}`],
  }));

  if (sorted) {
    return _.orderBy(dropDownOptions, (o) => o.text);
  }

  return dropDownOptions;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrToObj = (arr: any[] | undefined) => (arr ? arr.map((val) => ({ val })) : []);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arrayToDropDownList(array: any[], useIndex: boolean): DropdownItemProps[] {
  const dropDownOptions = array.map(
    (arrayItem: string, i: number) => ({
      key: i + 1,
      text: arrayItem,
      value: useIndex ? i : arrayItem,
    }),
  );
  return dropDownOptions;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchMoreData(url: string, payload?: any) {
  if (payload) {
    const res = await axios.post(url, payload);
    return res.data;
  }
  const res = await axios.get(url);
  return res.data;
}

function isClassComponent(component: unknown) {
  return (
    typeof component === 'function'
    && !!component.prototype.isReactComponent
  );
}

function isFunctionComponent(component: unknown) {
  return (
    typeof component === 'function'
    && String(component).includes('return React.createElement')
  );
}

export function isReactComponent(component: unknown) {
  return (
    isClassComponent(component)
    || isFunctionComponent(component)
  );
}

export function logImageConsole(src: string, scale = 100, callback: () => void, additionalStyle?: string[]) {
  if (console.image === undefined) {
    console.image = (url, size = 100, cb, addedStyle) => {
      const image = new Image();
      image.onload = () => {
        const style = _.union(addedStyle, [
          'font-size: 1px;',
          `padding: ${(image.height / 100) * size}px ${(image.width / 100) * size}px;`,
          `background: url(${url}) no-repeat;`,
          'background-size: contain;',
        ]).join(' ');

        console.log('%c ', style);
        if (typeof cb === 'function') {
          cb();
        }
      };

      image.src = url;
    };
  }

  if (typeof console.image === 'function') {
    console.image(src, scale, callback, additionalStyle);
  }
}

export type ParsedJSON = {
  value?: Record<string, unknown>,
  valid: boolean
};

export function tryParseJSON(item: unknown): ParsedJSON {
  if (Array.isArray(item) && item.length > 0 && item[0].$$typeof && item[0].$$typeof.toString() === 'Symbol(react.element)') {
    return tryParseJSON(item.map((i) => {
      // eslint-disable-next-line no-underscore-dangle
      if (i.props && i.props.time && i.props.time._isAMomentObject) {
        return new Date(i.props.time.format());
      }

      // Some other type of data -- not yet handled perhaps?
      return 'JSON Parsing Failed';
    }));
  }

  let result = typeof item !== 'string'
    ? JSON.stringify(item)
    : item;

  try {
    result = JSON.parse(result);
  } catch (e) {
    return { value: undefined, valid: false };
  }

  if (typeof result === 'object' && result !== null) {
    return { value: result, valid: true };
  }

  return { value: undefined, valid: false };
}

export function tryParseJSONAarray(item: unknown[]): ParsedJSON {
  let contents;

  // eslint-disable-next-line no-useless-escape
  if (item.length > 0 && typeof item[0] === 'string' && item[0].indexOf('{\"') > -1) {
    // Assuming array of JSON encoded strings
    try {
      contents = item.map((i) => JSON.parse(i as string));
    } catch (e) {
      return { value: undefined, valid: false };
    }
  } else {
    contents = tryParseJSON(item).value;
  }

  return { value: { ...contents }, valid: true };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrormessage(ex: any) {
  const message = ex.Message || ex.ExceptionMessage || ex.message;
  if (typeof ex.response === 'object' && typeof ex.response.data === 'object' && typeof ex.response.data.message === 'string') {
    return `${message}: ${ex.response.data.message}`;
  }
  return message;
}

export function showToastError(message: string) {
  toast.error(message, { toastId: generateId() });
}

export const findHighLight = (value: string, sVal: string, color?: string) => {
  // eslint-disable-next-line
  const parts = value.split(new RegExp(`(${sVal})`, 'gi'));
  return (
    <span key={generateId()}>
      {parts.map((part) => (part.toLowerCase() === sVal.toLowerCase()
        ? <b key={generateId()} style={{ backgroundColor: color || 'none' }}>{part}</b> : part))}
    </span>
  );
};

export function base64ToHex(rawData: string) {
  try {
    const buffer = Buffer.from(rawData, 'base64');
    return _.map(buffer, (x) => x.toString(16).padStart(2, '0')).join('-').toUpperCase();
  } catch (error) {
    return null;
  }
}
