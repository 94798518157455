import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { State } from 'app/reducers/state';
import { decodeBase64 } from 'app/helpers/helpers';
import './TokenViewer.scss';
import XMLView from '../shared/XMLView/XMLView';

const TokenViewer: FunctionComponent = () => {
  const authState = useSelector((state: State) => state.auth);

  if (authState) {
    const xml = decodeBase64(authState.accessToken || '');

    return (
      <XMLView xml={xml} collapsible className="token-viewer" />
    );
  }

  return <Segment>You are not logged in.</Segment>;
};

export default TokenViewer;
