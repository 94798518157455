import { guidChecker } from 'app/helpers/helpers';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export type ContractsSearchForm = Record<string, unknown> & {
  displayName: string,
  targetContextId: string,
};

const ContractsSB: FunctionComponent<ObjectTypeProps<ContractsSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ContractsSearchForm>) => {
  const [searchForm, setSearchForm] = useState<ContractsSearchForm>({
    displayName: '', targetContextId: '',
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          DisplayName
          <Popup
            flowing
            position="bottom left"
            content="DisplayName of the objects that you are looking for"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.displayName}
                className="margin-bottom"
                fluid
                placeholder="Search DisplayName"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, displayName: value })}
              />
                  )}
          />
        </label>
        <label className="label">
          TargetContextId
          <Popup
            flowing
            position="bottom left"
            content="Enter the TargetContextId of the Contract objects you are looking for.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.targetContextId.length > 0 && !guidChecker(searchForm.targetContextId)}
                disabled={disabled}
                value={searchForm.targetContextId}
                className="margin-bottom last-form-item"
                fluid
                placeholder="Search TargetContextId"
                onChange={(e, { value }) => setSearchForm({
                  ...searchForm,
                  targetContextId: value,
                })}
              />
                  )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ displayName: '', targetContextId: '' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.displayName === '' && !guidChecker(searchForm.targetContextId)}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default ContractsSB;
