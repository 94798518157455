import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface ProductCatalogSBSearchForm {
  partNumber: string;
  servicePlan: string;
}

const ProductCatalogSB: FunctionComponent<
ObjectTypeProps<ProductCatalogSBSearchForm>
> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ProductCatalogSBSearchForm>) => {
  const [searchForm, setSearchForm] = useState<ProductCatalogSBSearchForm>({
    partNumber: '',
    servicePlan: '',
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Part Number
          <Popup
            flowing
            position="bottom left"
            content="Enter the Part Number to filter on"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.partNumber}
                className="margin-bottom"
                fluid
                placeholder="Enter Part Number you are looking to filter on"
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, partNumber: value })}
              />
            )}
          />
        </label>
        <label className="label">
          Service Plan
          <Popup
            flowing
            position="bottom left"
            content="Enter the Service Plan to filter on"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.servicePlan}
                className="last-form-item"
                fluid
                placeholder="Enter Service Plan you are looking to filter on"
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, servicePlan: value })}
              />
            )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ partNumber: '', servicePlan: '' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={disabled}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default ProductCatalogSB;
