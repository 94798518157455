export const REMOVE_TAB = 'REMOVE_TAB' as const;
export const PIN_TAB = 'PIN_TAB' as const;
export const UNPIN_TAB = 'UNPIN_TAB' as const;
export const REPLACE_PINNED_TAB = 'REPLACE_PINNED_TAB' as const;
export const ADD_TAB = 'ADD_TAB' as const;
export const UPDATE_TAB = 'UPDATE_TAB' as const;
export const ACTIVATE_TAB = 'ACTIVATE_TAB' as const;
export const RESTORE_TABS = 'RESTORE_TABS' as const;
export const RESTORE_TABS_COMPLETED = 'RESTORE_TABS_COMPLETED' as const;
export const APP_BOOT_STRAP_TAB_LINKS = 'APP_BOOT_STRAP_TAB_LINKS' as const;

export const CREATE_TAB_LINK_STATE = 'CREATE_TAB_LINK_STATE' as const;
export const CREATE_TAB_LINK_STATE_FAILED = 'CREATE_TAB_LINK_STATE_FAILED' as const;
export const CREATE_TAB_LINK_STATE_COMPLETED = 'CREATE_TAB_LINK_STATE_COMPLETED' as const;
export const CLEAR_TAB_LINK_STATE = 'CLEAR_TAB_LINK_STATE' as const;

export const SEARCH_TAB_REQUESTED = 'SEARCH_TAB_REQUESTED' as const;
export const SEARCH_TAB_COMPLETED = 'SEARCH_TAB_COMPLETED' as const;
export const SEARCH_TAB_FAILED = 'SEARCH_TAB_FAILED' as const;

export const SEARCH_SYSTEM_CONTEXT = 'SEARCH_SYSTEM_CONTEXT' as const;
export const SEARCH_SYSTEM_CONTEXT_COMPLETED = 'SEARCH_SYSTEM_CONTEXT_COMPLETED' as const;
export const SEARCH_SYSTEM_CONTEXT_FAILED = 'SEARCH_SYSTEM_CONTEXT_FAILED' as const;

export const SEARCH_INDEX_STORE = 'SEARCH_INDEX_STORE' as const;
export const SEARCH_INDEX_STORE_COMPLETED = 'SEARCH_INDEX_STORE_COMPLETED' as const;
export const SEARCH_INDEX_STORE_FAILED = 'SEARCH_INDEX_STORE_FAILED' as const;

export const SET_TAB_MODAL_CONTENT = 'SET_TAB_MODAL_CONTENT' as const;
