import React, { FunctionComponent } from 'react';
import { Card, Icon, SemanticICONS } from 'semantic-ui-react';
import './ZeroStateToolbox.scss';

interface ZeroStateProps {
  icon?: SemanticICONS;
  text?: string;
}

const ZeroStateToolbox: FunctionComponent<ZeroStateProps> = ({
  icon = 'edit',
  text,
}: ZeroStateProps) => (
  <Card.Content className="zero-state-container" extra>
    <Icon size="big" name={icon} />
    <p className="zero-state-text">
      {text}
    </p>
  </Card.Content>
);

ZeroStateToolbox.defaultProps = {
  icon: 'edit',
  text: undefined,
};

export default ZeroStateToolbox;
