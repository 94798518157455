/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = None 1 = RoleTemplate 2 = PermissionScope 3 = PreAuthorization
*/
export enum MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType {
    None = 0,
    RoleTemplate = 1,
    PermissionScope = 2,
    PreAuthorization = 3
}
