/* eslint-disable @typescript-eslint/lines-between-class-members */
import './PaginatedTab.scss';

import _ from 'lodash';
import { Ref } from '@fluentui/react-component-ref';
import React, {
  createRef,
} from 'react';
import {
  Button, Icon, Menu, MenuItemProps, TabPane, TabProps,
} from 'semantic-ui-react';
import { GenericDictionaryParams, TabType } from 'app/typings';

interface PaginatedTabProps extends TabProps {
  tabContextDelegate?: (tab: PaginatedContextMenuProps) => void,
  hidepagingcontrolswhendisabled?: string
}

export interface PaginatedContextMenuProps {
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  tab: string,
  parent?: string,
  tabType: TabType,
  x: number,
  y: number,
  target: HTMLElement,
  allowClose: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class PaginatedTab extends React.Component<PaginatedTabProps, any> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<PaginatedTabProps>;
  ref: React.RefObject<HTMLElement>;
  ticking: boolean;
  mounted: boolean;
  frameId: number;

  constructor(props: PaginatedTabProps) {
    super(props);

    this.ref = createRef();
    this.ticking = false;
    this.mounted = false;
    this.frameId = 0;

    const { activeIndex = 0 } = this.props;

    this.state = {
      activeIndex,
      itemIndex: 0,
    };

    this.handleScrollForward = this.handleScrollForward.bind(this);
    this.handleScrollBack = this.handleScrollBack.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleContextClick = this.handleContextClick.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.handleUpdate();
  }

  componentWillUnmount() {
    if (this.frameId) {
      cancelAnimationFrame(this.frameId);
    }

    this.mounted = false;
  }

  handleUpdate = () => {
    if (this.ticking) {
      return;
    }

    this.ticking = true;
    this.frameId = requestAnimationFrame(this.update);
  };

  handleContextClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    const { panes, tabContextDelegate } = this.props;

    if (!panes) {
      return;
    }

    e.persist();
    e.preventDefault();
    if (e.type === 'contextmenu') {
      const foundIndex = _.findIndex(e.currentTarget.childNodes, (n) => n === e.target);

      const { itemIndex = 0 } = this.state;
      const scrolledActiveIndex = foundIndex - itemIndex;

      const pe = e.nativeEvent as PointerEvent;
      const srcElement: HTMLElement = e.target as HTMLElement;

      /* This is some seriously awful hack -- I don't know why the offsetY is different between
      The TabManager and PinnedTabManager -- so to compensate we are checking for the parent
      pinned-tab-container and making an adjustment for it's offsetHeight if found
      */
      const wrapperElement = srcElement.closest('.pinned-tab-container');

      const x = srcElement.offsetLeft + pe.offsetX;
      const y = srcElement.offsetTop + pe.offsetY + (wrapperElement === null ? 0 : (srcElement.parentElement as HTMLElement).offsetHeight);

      if (typeof tabContextDelegate === 'function') {
        const v = panes[+`${scrolledActiveIndex}`] as GenericDictionaryParams;
        if (v) {
          tabContextDelegate({
            e,
            tab: v.key as string,
            parent: v.parent as string | undefined,
            tabType: v.tabType as TabType,
            x,
            y,
            target: srcElement,
            allowClose: v.allowClose as boolean,
          });
        }
      }
    }
  }

  handleItemClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, { index }: MenuItemProps) => {
    const { onTabChange } = this.props;

    this.setState({ activeIndex: index }, () => {
      if (typeof onTabChange === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onTabChange(e as any, { ...this.props, activeIndex: index });
      }
    });
  };

  handleScrollBack(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { activeIndex = 0 } = this.state;
    const { panes } = this.props;

    if (panes && panes.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { key } = panes[activeIndex - 1] as any;
      this.handleItemClick(e, { key, index: activeIndex - 1 });
    }
  }

  handleScrollForward(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { activeIndex = 0 } = this.state;
    const { panes } = this.props;

    if (panes && (activeIndex + 1) <= panes.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { key } = panes[activeIndex + 1] as any;
      this.handleItemClick(e, { key, index: activeIndex + 1 });
    }
  }

  handleTabClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, props: MenuItemProps) {
    const { itemIndex = 0 } = this.state;
    if (typeof this.handleItemClick === 'function') {
      this.handleItemClick(e, { ...props, index: props.index + itemIndex });
    }
  }

  static getDerivedStateFromProps(props: PaginatedTabProps, state: GenericDictionaryParams) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (props.activeIndex !== state.activeIndex) {
      return {
        activeIndex: props.activeIndex,
      };
    }

    return null;
  }

  // This should return a point in the lower right hand corner of the element.
  getVisibilityTabPoint = (clientRect: DOMRect, elem: HTMLElement) => ({
    x: clientRect.left + (elem.offsetWidth / 1.1),
    y: clientRect.top + elem.offsetHeight / 1.1,
  });

  isVisible = (elem: HTMLElement) => {
    if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.');
    const clientRect = elem.getBoundingClientRect();

    if (elem.offsetWidth + elem.offsetHeight + clientRect.height
      + clientRect.width === 0) {
      return false;
    }
    const visibilityTestingPoint = this.getVisibilityTabPoint(clientRect, elem);

    if (visibilityTestingPoint.x < 0) return false;
    if (visibilityTestingPoint.x > (document.documentElement.clientWidth || window.innerWidth)) return false;
    if (visibilityTestingPoint.y < 0) return false;
    if (visibilityTestingPoint.y > (document.documentElement.clientHeight || window.innerHeight)) return false;

    const pointContainer = document.elementFromPoint(visibilityTestingPoint.x, visibilityTestingPoint.y);
    return pointContainer === elem;
  };

  isModalCovering = (elem: HTMLElement) => {
    if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.');

    const clientRect = elem.getBoundingClientRect();
    const visibilityTestingPoint = this.getVisibilityTabPoint(clientRect, elem);
    const pointContainer = document.elementFromPoint(visibilityTestingPoint.x, visibilityTestingPoint.y);

    if (!pointContainer) {
      return false;
    }

    return pointContainer.classList.contains('dimmed')
      || pointContainer.classList.contains('dimmer')
      || pointContainer.classList.contains('modals');
  };

  isContextMenuActive = (elem: HTMLElement) => {
    if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.');

    const clientRect = elem.getBoundingClientRect();
    const visibilityTestingPoint = this.getVisibilityTabPoint(clientRect, elem);
    const pointContainer = document.elementFromPoint(visibilityTestingPoint.x, visibilityTestingPoint.y);

    if (!pointContainer) {
      return false;
    }

    const contextMenu = pointContainer.closest('.context-menu');
    if (contextMenu) {
      return getComputedStyle(contextMenu).display !== 'none';
    }

    return false;
  };

  update = () => {
    if (!this.mounted || !this.ref.current) {
      return;
    }

    const { itemIndex = 0, activeTabWidth } = this.state;
    const { panes } = this.props;

    this.ticking = false;

    if (panes) {
      const activeTab = this.ref.current.querySelector('.active.item.tab.primary-tab-menu-item');
      if (activeTab) {
        const isVisible = this.isVisible(activeTab as HTMLElement);

        if (!isVisible) {
          const activeTabClientRect = activeTab.getBoundingClientRect();
          const parentClientRect = this.ref.current.getBoundingClientRect();

          if (activeTabClientRect.x > parentClientRect.x) {
            if (!this.isModalCovering(activeTab as HTMLElement) && !this.isContextMenuActive(activeTab as HTMLElement)) {
              this.setState({ activeTabWidth: activeTabClientRect.x, itemIndex: itemIndex + 1 });
            }
          }

          if (activeTabClientRect.x < parentClientRect.x && itemIndex > 0) {
            this.setState({ itemIndex: itemIndex - 1 });
          }
        }

        if (isVisible && itemIndex > 0) {
          // Active Tab is Visible -- Going to check to see if any others can be made visible
          const parentClientRect = this.ref.current.getBoundingClientRect();

          if (parentClientRect.width > activeTabWidth) {
            this.setState({ itemIndex: itemIndex - 1 });
          }
        }
      }

      if (!activeTab && itemIndex > 0) {
        this.setState({ itemIndex: itemIndex - 1 });
      }

      if (itemIndex < 0) {
        // This shouldn't happen.. But, just in case.
        this.setState({ itemIndex: 0 });
      }

      // Check to make sure the last menu tab is visible
      if (this.ref.current) {
        const lastChild = this.ref.current.lastChild as HTMLElement;
        if (lastChild) {
          this.setState({ allTabsVisible: this.isVisible(lastChild) });
        }
      }
    }

    this.handleUpdate();
  };

  renderItems() {
    const { panes, renderActiveOnly } = this.props;
    const { activeIndex } = this.state;

    if (renderActiveOnly) return _.invoke(_.get(panes, `[${activeIndex}]`), 'render', this.props);
    return _.map(panes, ({ pane }, index) => React.createElement(TabPane, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...pane as any,
      active: index === activeIndex,
    }));
  }

  renderMenu() {
    const {
      menu, panes = [], menuPosition, hidepagingcontrolswhendisabled,
    } = this.props;

    const hideControls = hidepagingcontrolswhendisabled !== 'false';
    const { activeIndex = 0, itemIndex = 0, allTabsVisible = true } = this.state;

    if (menu && menu.tabular === true && menuPosition === 'right') {
      menu.tabular = 'right';
    }

    const trimmedPanes = _.takeRight(panes, panes.length - itemIndex);
    const menuItems = _.map(trimmedPanes, 'menuItem');

    // Need to take the provided active index and compensate for
    // the item index shift
    const scrolledActiveIndex = activeIndex - itemIndex;
    return (
      <>
        <div className="menuWrapper">
          <Ref innerRef={this.ref}>
            <Menu
              tabular
              items={menuItems}
              onItemClick={this.handleTabClick}
              activeIndex={scrolledActiveIndex} // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown={this.handleContextClick}
              onContextMenu={this.handleContextClick}
            />
          </Ref>
          <div>
            <Button
              disabled={activeIndex === 0}
              onClick={this.handleScrollBack}
              icon
              compact
              size="small"
              className={(trimmedPanes.length === panes.length && allTabsVisible && hideControls) ? 'hidden' : ''}
            >
              <Icon name="caret left" />
            </Button>
            <Button
              disabled={activeIndex === panes.length - 1}
              onClick={this.handleScrollForward}
              icon
              compact
              size="small"
              className={(trimmedPanes.length === panes.length && allTabsVisible && hideControls) ? 'hidden' : ''}
            >
              <Icon name="caret right" />
            </Button>
          </div>
        </div>
      </>
    );
  }

  render() {
    const menu = this.renderMenu();
    const { className = '' } = this.props;

    return (
      <div className={className}>
        {menu.props.attached !== 'bottom' && menu}
        {this.renderItems()}
        {menu.props.attached === 'bottom' && menu}
      </div>
    );
  }
}

export default PaginatedTab;

PaginatedTab.defaultProps = {
  tabContextDelegate: undefined,
  hidepagingcontrolswhendisabled: 'false',
};
