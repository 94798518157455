import { enumToDropDownList, guidChecker } from 'app/helpers/helpers';
import { ScopedMemberValidTargetObjectClasses } from 'app/proxyClients';
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Dropdown, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';

export interface ScopedMembersSearchForm {
  sourceObjectId: string;
  scopeId: string;
  targetObjectClass: number | undefined;
  targetObjectId: string;
}

const ScopedMembersSB: FunctionComponent<ObjectTypeProps<ScopedMembersSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<ScopedMembersSearchForm>) => {
  const [searchForm, setSearchForm] = useState<ScopedMembersSearchForm>({
    sourceObjectId: '',
    scopeId: '',
    targetObjectClass: undefined,
    targetObjectId: '',
  });

  const id = _.uniqueId('ScopedMembersSB-');
  const targetDropdownId = `${id}-targetDropdown`;

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Role (RoleId)
          <Popup
            flowing
            position="bottom left"
            content="Enter the roleId of the object of the role you are looking for.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.sourceObjectId.length > 0 && !guidChecker(searchForm.sourceObjectId)}
                disabled={disabled}
                value={searchForm.sourceObjectId}
                className="margin-bottom"
                fluid
                placeholder="Search RoleId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, sourceObjectId: value })}
              />
          )}
          />
        </label>
        <label className="label">
          Scope (ScopeId)
          <Popup
            flowing
            position="bottom left"
            content="Enter the ScopeId (AdministrativeUnit ObjectId) of the object you are looking for.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
            trigger={(
              <Input
                error={searchForm.scopeId.length > 0 && !guidChecker(searchForm.scopeId)}
                disabled={disabled}
                value={searchForm.scopeId}
                className="margin-bottom"
                fluid
                placeholder="Search ScopeId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, scopeId: value })}
              />
          )}
          />
        </label>
        <label className="label" htmlFor={targetDropdownId}>Target Object Class</label>
        <div className="dropdown-input-container">
          <Dropdown
            compact
            clearable
            fluid
            selection
            placeholder="Please select Target Object Class"
            className="margin-bottom"
            id={targetDropdownId}
            options={enumToDropDownList(ScopedMemberValidTargetObjectClasses)}
            value={searchForm.targetObjectClass || ''}
            onChange={(_e, { value }) => setSearchForm({ ...searchForm, targetObjectClass: value as number })}
          />
          <label className="label">
            Target ObjectId
            <Popup
              flowing
              position="bottom left"
              content="Enter objectId of target object you are looking for.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)."
              trigger={(
                <Input
                  error={searchForm.targetObjectId.length > 0 && !guidChecker(searchForm.targetObjectId)}
                  className="last-form-item"
                  fluid
                  disabled={disabled}
                  placeholder="Search users or groups"
                  value={searchForm.targetObjectId}
                  onChange={(_e, { value }) => setSearchForm({ ...searchForm, targetObjectId: value })}
                />
            )}
            />
          </label>
        </div>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
          >
            Reset
          </Button>
          <Button
            disabled={
              searchForm.sourceObjectId === ''
              || searchForm.scopeId === ''
              || searchForm.targetObjectId === ''
            }
            compact
            className="sidebar-btn"
            color="blue"
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default ScopedMembersSB;
