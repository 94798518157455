import _, { ListIterator } from 'lodash';

export function replaceAtIndex<T>(state:T[], record:T, index:number) {
  return [..._.slice(state, 0, index), record, ..._.slice(state, index + 1)];
}

export function replaceRecordUnsafe<
TKey extends keyof TState,
TState extends Pick<TState, TKey>,
TElement extends TState[TKey] extends Array<infer Item> ? Item: never,
>(
  state: TState,
  record:TElement,
  collection: TKey,
  iteratee:ListIterator<TElement, boolean>,
):TElement[] {
  const index = _.findIndex(state[collection as TKey], iteratee);
  return replaceAtIndex(state[collection as TKey], record, index);
}

export function replaceRecord< TRecord extends TElement,
  TKey extends keyof TState,
  TState extends Pick<TState, TKey>,
  TElement extends TState[TKey] extends Array<infer Item> ? Item : never,
>(
  state:TState,
  record:TRecord,
  collection:TKey,
  iteratee:ListIterator<TRecord, boolean>,
):TRecord[] {
  const index = _.findIndex(state[collection as TKey], iteratee);
  return replaceAtIndex(state[collection as TKey], record, index);
}

export function removeRecord<
  TKey extends keyof TState,
  TState extends Pick<TState, TKey>,
  TElement extends TState[TKey] extends Array<infer Item> ? Item : never>(
  state: TState,
  collection: TKey,
  iteratee:ListIterator<TElement, boolean>,
):TElement[] {
  const index = _.findIndex(state[collection as TKey], iteratee);
  const list = state[collection as TKey] as TElement[];

  if (index === -1) {
    return list;
  }

  return [..._.slice(list, 0, index), ..._.slice(list, index + 1)];
}
