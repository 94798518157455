import React, { FunctionComponent, useState } from 'react';
import {
  Button, Checkbox, Dropdown, Input, Popup, Transition,
} from 'semantic-ui-react';

import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import { enumToDropDownList, guidChecker } from 'app/helpers/helpers';
import { EntitlementGrantValidSourceObjectClasses } from 'app/proxyClients';

export interface EntitlementGrantsSearchForm {
  sourceObjectId: string,
  sourceObjectClass: number,
  targetObjectId: string,
  displayNameFilterString: string,
  isIndirectGrants: boolean,
}

const EntitlementGrantsSB: FunctionComponent<ObjectTypeProps<EntitlementGrantsSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<EntitlementGrantsSearchForm>) => {
  const [searchForm, setSearchForm] = useState<EntitlementGrantsSearchForm>({
    sourceObjectId: '', sourceObjectClass: 6, targetObjectId: '', displayNameFilterString: '', isIndirectGrants: false,
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          Source ObjectId
          <Popup
            flowing
            position="bottom left"
            content="ObjectId of the source object to filter on.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)"
            trigger={(
              <Input
                error={searchForm.sourceObjectId.length > 0 && !guidChecker(searchForm.sourceObjectId)}
                disabled={disabled}
                value={searchForm.sourceObjectId}
                className="margin-bottom"
                fluid
                placeholder="Search Source ObjectId"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, sourceObjectId: value })}
              />
                      )}
          />
        </label>

        <label className="label">
          Source Object Class
          <Popup
            flowing
            position="bottom left"
            content="The DirectoryObjectClass of the link source"
            trigger={(
              <Dropdown
                fluid
                selection
                disabled={disabled}
                className="margin-bottom"
                options={enumToDropDownList(EntitlementGrantValidSourceObjectClasses)}
                value={searchForm.sourceObjectClass as number}
                onChange={(e, { value }) => setSearchForm({ ...searchForm, sourceObjectClass: value as number })}
              />
                      )}
          />
        </label>

        <label className="label">
          Target ObjectId
          <Popup
            flowing
            position="bottom left"
            content="ObjectId of the target object to filter on.
            Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)"
            trigger={(
              <Input
                error={searchForm.targetObjectId.length > 0 && !guidChecker(searchForm.targetObjectId)}
                disabled={disabled}
                value={searchForm.targetObjectId}
                className="margin-bottom"
                fluid
                placeholder="Search Target ObjectId"
                onChange={(_e, { value }) => setSearchForm({
                  ...searchForm,
                  targetObjectId: value,
                })}
              />
                      )}
          />
        </label>
        <label className="label">
          DisplayName
          <Popup
            flowing
            position="bottom left"
            content="DisplayName of the objects you are looking for"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.displayNameFilterString}
                className="margin-bottom"
                fluid
                placeholder="Search DisplayName"
                onChange={(e, { value }) => setSearchForm({ ...searchForm, displayNameFilterString: value })}
              />
                      )}
          />
        </label>
        <Popup
          flowing
          position="bottom left"
          content="Whether to search for indirect grants (granted through a group membership)"
          trigger={(
            <Checkbox
              disabled={disabled}
              className="last-form-item"
              toggle
              label="Indirect Grants"
              checked={searchForm.isIndirectGrants}
              onChange={(_e, { checked }) => setSearchForm({
                ...searchForm,
                isIndirectGrants: checked as boolean,
              })}
            />
                    )}
        />
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({
              sourceObjectId: '', sourceObjectClass: 6, targetObjectId: '', displayNameFilterString: '', isIndirectGrants: false,
            })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={
                            searchForm.sourceObjectId === ''
                            && searchForm.targetObjectId === ''
                        }
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default EntitlementGrantsSB;
