import moment from 'moment';

export const isDebugMode = window.console !== undefined;

export const takeDebugAction = ((func: () => void) => {
  if (isDebugMode && typeof func === 'function') {
    func();
  }
});

export function logDebug(message: string, ...args: unknown[]) {
  if (isDebugMode) {
    if (arguments.length > 1) {
      if (args.length === 1) {
        // eslint-disable-next-line no-console
        console.debug(`${moment().format()} - ${message}`, args[0]);
      } else {
        // eslint-disable-next-line no-console
        console.debug(`${moment().format()} - ${message}`, args);
      }
    } else {
      // eslint-disable-next-line no-console
      console.debug(`${moment().format()} - ${message}`);
    }
  }
}

export function logError(message: string, ...args: unknown[]) {
  takeDebugAction(() => {
    // eslint-disable-next-line no-console
    console.error(`${moment().format()} - ${message}`, args.length > 1 ? args : '');
  });
}
