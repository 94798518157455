import * as systemTypes from 'app/actions/systemTypes';
import * as tabTypes from 'app/actions/tabTypes';
import {
  GenericSingleInputSBSearchForm,
} from 'app/components/shared/sidebar-children/GenericSingleInputSB/GenericSingleInputSB';
import { ObjectTypeComponentKeys } from 'app/components/SidebarContent/SystemSidebar/SystemSidebarOptions';
import {
  ErrorMessageCallback, ExportSearchForm, SingleObjectCallback, TaskSearchForm,
} from 'app/typings';

export async function getSystemSettings() {
  return {
    type: systemTypes.GET_SYSTEM_SETTINGS,
    metadata: {
      sidebarObjectType: 'Settings',
    },
  };
}

export function getSystemContextExport(params: ExportSearchForm, sidebarObjectType?: ObjectTypeComponentKeys) {
  return {
    type: systemTypes.GET_SYSTEM_CONTEXT_EXPORT,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
  };
}

export async function getRoleTemplates(params:TaskSearchForm, sidebarObjectType?: ObjectTypeComponentKeys) {
  return {
    type: systemTypes.GET_ROLE_TEMPLATES,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
  };
}

export async function getPermissionScopes(params:TaskSearchForm, sidebarObjectType?: ObjectTypeComponentKeys) {
  const searchData = params.contains === '(any)' ? {} : { ...params };
  return {
    type: systemTypes.GET_PERMISSION_SCOPES,
    data: searchData,
    metadata: {
      sidebarObjectType,
    },
  };
}

export async function getSPTs(params:TaskSearchForm, sidebarObjectType?: ObjectTypeComponentKeys) {
  return {
    type: systemTypes.GET_SERVICE_PRINCIPAL_TEMPLATES,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
  };
}

export async function getTrustedCertificates(params:GenericSingleInputSBSearchForm, sidebarObjectType?: ObjectTypeComponentKeys) {
  return {
    type: systemTypes.GET_TRUSTED_CERTIFICATES,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
  };
}

export async function getTypeConfiguration(params:GenericSingleInputSBSearchForm, sidebarObjectType?: ObjectTypeComponentKeys) {
  return {
    type: systemTypes.GET_TYPE_CONFIGURATION,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
  };
}

export async function getSystemTaskList() {
  return {
    type: systemTypes.GET_SYSTEM_TASKS,
  };
}

export async function getSingleObject(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params:any,
  sidebarObjectType?: ObjectTypeComponentKeys,
  callback?: SingleObjectCallback,
  errorCallback?: ErrorMessageCallback,
  skipTabCreation = false,
) {
  return {
    type: systemTypes.GET_SYSTEM_SINGLE_OBJECT,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
    callback,
    errorCallback,
    skipTabCreation,
  };
}

export function getServiceDetail(serviceType: string, sidebarObjectType: string) {
  return {
    type: systemTypes.GET_SERVICE_DETAILS,
    data: serviceType,
    metadata: {
      sidebarObjectType,
    },
  };
}

export function getServiceInstanceDetail(serviceType: string, sidebarObjectType: string) {
  return {
    type: systemTypes.GET_SERVICE_INSTANCE_DETAILS,
    data: serviceType,
    metadata: {
      sidebarObjectType,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getSearchEndpoints(params: any, sidebarObjectType: ObjectTypeComponentKeys) { // All remaining endpoints not mapped above
  return {
    type: tabTypes.SEARCH_SYSTEM_CONTEXT,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function displayPropertyBag(propertyBag: any, sidebarObjectType?: string) {
  return {
    type: systemTypes.DISPLAY_PROPERTY_BAG,
    propertyBag,
    metadata: {
      sidebarObjectType,
    },
  };
}
