/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* 0 = None 16 = HasDelegatedAccessToReference 20 = HasEntitlementGrantReference 24 = GroupBasedLicenseErrorOccuredReference 26 = ScopedMemberReference 28 = LocalizedAppPermissionsReference 29 = RoleAssignmentReference 33 = SocialIdpBindData 37 = EligibleScopedMemberReference 38 = EligibleRoleAssignmentReference 40 = HasPreAuthorizedApplicationReference 46 = AgreementAcceptanceReference
*/
export enum MicrosoftOnlineDirectoryServicesDirectoryReferenceClass {
    None = 0,
    HasDelegatedAccessToReference = 16,
    HasEntitlementGrantReference = 20,
    GroupBasedLicenseErrorOccuredReference = 24,
    ScopedMemberReference = 26,
    LocalizedAppPermissionsReference = 28,
    RoleAssignmentReference = 29,
    SocialIdpBindData = 33,
    EligibleScopedMemberReference = 37,
    EligibleRoleAssignmentReference = 38,
    HasPreAuthorizedApplicationReference = 40,
    AgreementAcceptanceReference = 46
}
