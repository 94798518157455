export const GET_COMPANY_BY_NAME = 'GET_COMPANY_BY_NAME' as const;
export const GET_COMPANY_BY_NAME_COMPLETED = 'GET_COMPANY_BY_NAME_COMPLETED' as const;
export const GET_COMPANY_BY_NAME_FAILED = 'GET_COMPANY_BY_NAME_FAILED' as const;
export const GET_COMPANY_OBJECT_COUNTS = 'GET_COMPANY_OBJECT_COUNTS' as const;
export const GET_COMPANY_OBJECT_COUNTS_COMPLETED = 'GET_COMPANY_OBJECT_COUNTS_COMPLETED' as const;
export const GET_COMPANY_OBJECT_COUNTS_FAILED = 'GET_COMPANY_OBJECT_COUNTS_FAILED' as const;
export const REMOVE_COMPANY_TAB = 'REMOVE_COMPANY_TAB' as const;
export const RESET_ERROR = 'RESET_ERROR' as const;
export const UPDATE_SEARCH_COMPANIES = 'UPDATE_SEARCH_COMPANIES' as const;
export const GET_SINGLE_OBJECT = 'GET_SINGLE_OBJECT' as const;
export const GET_SINGLE_OBJECT_COMPLETED = 'GET_SINGLE_OBJECT_COMPLETED' as const;
export const GET_SINGLE_OBJECT_FAILED = 'GET_SINGLE_OBJECT_FAILED' as const;
export const GET_SINGLE_LICENSE = 'GET_SINGLE_LICENSE' as const;
export const GET_SINGLE_LICENSE_COMPLETED = 'GET_SINGLE_LICENSE_COMPLETED' as const;
export const GET_SINGLE_LICENSE_FAILED = 'GET_SINGLE_LICENSE_FAILED' as const;
export const DISPLAY_PROPERTY_BAG_COMP = 'DISPLAY_PROPERTY_BAG_COMP' as const;
export const FIND_SP_BY_APPID = 'FIND_SP_BY_APPID' as const;
export const GET_APP_PERMISSIONS = 'GET_APP_PERMISSIONS' as const;
export const GET_APP_PERMISSIONS_COMPLETED = 'GET_APP_PERMISSIONS_COMPLETED' as const;
export const REMOVE_APP_PERMISSIONS_FROM_STATE = 'REMOVE_APP_PERMISSIONS_FROM_STATE' as const;
export const GET_KEY_GROUP_REFERENCE = 'GET_KEY_GROUP_REFERENCE' as const;
export const GET_KEY_GROUP_REFERENCE_COMPLETED = 'GET_KEY_GROUP_REFERENCE_COMPLETED' as const;
export const GET_KEY_GROUP_REFERENCE_FAILED = 'GET_KEY_GROUP_REFERENCE_FAILED' as const;
export const REMOVE_KEY_GROUP_REFERENCE = 'REMOVE_KEY_GROUP_REFERENCE' as const;
export const OPEN_KEY_GROUP_OBJECT = 'OPEN_KEY_GROUP_OBJECT' as const;
export const DOWNLOAD_COMPANY_LINKS = 'DOWNLOAD_COMPANY_LINKS' as const;
export const GET_REPLICATION_METADATA = 'GET_REPLICATION_METADATA' as const;
export const GET_REPLICATION_METADATA_COMPLETED = 'GET_REPLICATION_METADATA_COMPLETED' as const;
export const REMOVE_REPLICATION_METADATA_FROM_STATE = 'REMOVE_REPLICATION_METADATA_FROM_STATE' as const;
