import { StatusMessageDataModel } from 'app/proxyClients';
import type { App } from 'app/store';

export async function getAnnouncements(app: App, includeAcknowledged: boolean) {
  return app.clients.announcements.getStatus(includeAcknowledged);
}

export async function addAnnouncement(app: App, p: StatusMessageDataModel) {
  return app.clients.announcements.addStatus(p);
}

export async function updateAnnouncement(app: App, p: StatusMessageDataModel) {
  return app.clients.announcements.updateStatusMessage(p);
}

export async function acknowledgeAnnouncement(app: App, messageId: string) {
  return app.clients.announcements.acknowledge(messageId);
}
