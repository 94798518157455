import './StatusModal.scss';

import React, { FunctionComponent, useEffect, ReactElement } from 'react';
import {
  Button, ButtonProps, Header, Icon, Modal, SemanticICONS,
} from 'semantic-ui-react';

/* eslint-disable */
export interface StatusModalProps {
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen',
  header: string,
  message: string | ReactElement | undefined,
  style: 'success' | 'caution' | 'error',
  open: boolean | undefined,
  closeEvent?: () => void,
  icon?: SemanticICONS,
  actions?: (ButtonProps | ReactElement)[],
  closeOnEscape?: boolean,
  closeOnDimmerClick?: boolean,
  disableActions?: boolean
}
/* eslint-enable */

const StatusModal: FunctionComponent<StatusModalProps> = ({
  size = 'tiny',
  header,
  message,
  style = 'success',
  open,
  icon,
  closeEvent,
  actions = [{ content: 'Ok', color: 'blue' }],
  closeOnEscape = true,
  closeOnDimmerClick = true,
  disableActions = false,
}: StatusModalProps) => {
  const [openModal, setOpenModal] = React.useState<boolean | undefined>(false);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const triggerCloseEvent = () => {
    setOpenModal(false);
    if (closeEvent) closeEvent();
  };

  return (
    <Modal
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnEscape={closeOnEscape}
      className="status-modal"
      size={size}
      mountNode={document.querySelector('.App')}
      onClose={() => triggerCloseEvent()}
      onOpen={() => setOpenModal(true)}
      open={openModal}
    >
      <Header className={style}>
        {icon && <Icon size="tiny" className="status-modal-icon" name={icon} />}
        {header}
      </Header>
      <Modal.Content>
        <Modal.Description>
          {message}
        </Modal.Description>
      </Modal.Content>
      { !disableActions
        ? (
          <Modal.Actions>
            {actions.map(
              (a, i) => {
                // For convenience, allow passing in button props so the whole button object need not be made.
                const buttonProps = a as ButtonProps;
                if (buttonProps.content) {
                  return (
                    <Button
                      key={buttonProps.key === undefined ? i : buttonProps.key}
                      className={buttonProps.className}
                      color={buttonProps.color}
                      disabled={buttonProps.disabled}
                      onClick={(event, data) => {
                        event.persist();

                        if (buttonProps.onClick) {
                          buttonProps.onClick(event, data);
                        }

                        if (event.defaultPrevented === false) {
                          triggerCloseEvent();
                        }
                      }}
                    >
                      {buttonProps.content}
                    </Button>
                  );
                }

                return a as ReactElement;
              },
            )}
          </Modal.Actions>
        ) : null}
    </Modal>
  );
};
export default StatusModal;
