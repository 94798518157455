import './RefreshButton.scss';

import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { RefreshDSTableDelegate } from '../DSTable/DSTableTypes';

interface RefreshProps {
  refreshDelegate?: RefreshDSTableDelegate,
  sortColumn?: string,
  sortOrder?: 'asc' | 'desc' | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loading: any
}

const RefreshButton: FunctionComponent<RefreshProps> = ({
  refreshDelegate,
  sortColumn,
  sortOrder,
  loading,
}: RefreshProps) => {
  const dispatch = useDispatch();
  const handleOnClick = () => {
    if (typeof refreshDelegate === 'function') {
      loading();
      refreshDelegate(dispatch, sortColumn, sortOrder);
    }
  };

  const button = refreshDelegate === undefined ? null : (
    <Button
      compact
      primary
      fluid
      icon
      color="green"
      title="Refresh Data"
      onClick={handleOnClick}
    >
      <Icon name="refresh" />
    </Button>
  );

  return button ? <div className="refreshButton">{button}</div> : null;
};

RefreshButton.defaultProps = {
  refreshDelegate: undefined,
  sortColumn: undefined,
  sortOrder: 'asc',
};

export default RefreshButton;
