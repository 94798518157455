import React, {
  FunctionComponent, SyntheticEvent, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { State } from 'app/reducers/state';
import _ from 'lodash';
import ContentModal from '../ContentModal/ContentModal';
import ZeroState from '../ZeroState/ZeroState';

interface TenantListModalProps {
  exclude?: string[],
  close: (selection: string) => void,
}

const TenantListModal: FunctionComponent<TenantListModalProps> = ({
  exclude = [], close,
}: TenantListModalProps) => {
  const [selection, setSelection] = useState<string>();
  const companyState = useSelector((state: State) => state.companies);

  const selectionChanged = (e: SyntheticEvent, data: Record<string, unknown>) => {
    if ('value' in data) {
      setSelection(data.value as string);
    }
  };

  const companyListOptions = _.reject(companyState?.companies.map((c) => ({
    key: c.contextId,
    text: c.displayName,
    value: c.contextId,
  })), (o) => exclude?.includes(o.key as string)) as [];

  const content = (
    companyListOptions.length
      ? (
        <Dropdown
          placeholder="Select Tenant"
          fluid
          selection
          options={companyListOptions}
          onChange={selectionChanged}
        />
      ) : <ZeroState icon="warning sign" text="Please open additional tenant(s) other than the one currently selected" />
  );

  return (
    <ContentModal
      icon="list alternate outline"
      header="Select Tenant"
      style="success"
      message={content}
      open
      closeEvent={() => {
        close(selection as string);
      }}
    />
  );
};

TenantListModal.defaultProps = {
  exclude: [],
};

export default TenantListModal;
