import * as tabTypes from 'app/actions/tabTypes';
import { ReactElement } from 'react';
import { Action } from 'redux';
import {
  GenericDictionaryParams,
  TabDetails,
  TabMetadata,
  TabModalOptions,
  TabType,
  WithTabDetails,
} from 'app/typings';

export type TabActionDetails<T> = Action<T> & {
  id?: string | null;
};

export type GetTabLinkAction = Action<
  typeof tabTypes.APP_BOOT_STRAP_TAB_LINKS
> & {
  id: string;
};

export type CreateTabLinkActionRequested = Action<
  typeof tabTypes.CREATE_TAB_LINK_STATE
> & {
  TabType: TabType;
  TabMetadata: TabMetadata[];
};

export type CreateTabLinkActionCompleted = Action<
  typeof tabTypes.CREATE_TAB_LINK_STATE_COMPLETED
> & {
  linkState: string;
};

export type CreateTabLinkActionFailed = Action<
  typeof tabTypes.CREATE_TAB_LINK_STATE_FAILED
> & {
  error: string;
};

export type AddTabAction = TabActionDetails<typeof tabTypes.ADD_TAB> &
WithTabDetails & {
  fromHistory?: boolean;
  parentTab?: string;
  atIndex?: number
};
export type RemoveTabAction = TabActionDetails<typeof tabTypes.REMOVE_TAB> & {
  id: string | undefined;
  tabType: TabType;
  parentTab?: string;
};

export type PinTabAction = TabActionDetails<typeof tabTypes.PIN_TAB> & {
  id: string | undefined;
  tabType: TabType;
  parentTab: string;
};

export type UnPinTabAction = TabActionDetails<typeof tabTypes.UNPIN_TAB> & {
  id: string | undefined;
  tabType: TabType;
};

export type SetTabModalContentAction = TabActionDetails<typeof tabTypes.SET_TAB_MODAL_CONTENT> & {
  id: string | undefined;
  tabType: TabType;
  parentTab?: string;
  options?: TabModalOptions;
};

export type UpdateTabAction = TabActionDetails<typeof tabTypes.UPDATE_TAB> &
WithTabDetails & {
  parentTab?: string;
  resp: unknown;
  atIndex?: number
};
export type ActivateTabAction = TabActionDetails<
  typeof tabTypes.ACTIVATE_TAB
> & {
  tabType: TabType;
  parentTab?: string;
};
export type ValidTabActions =
  | AddTabAction
  | RemoveTabAction
  | PinTabAction
  | UnPinTabAction
  | SetTabModalContentAction
  | UpdateTabAction
  | ActivateTabAction
  | CreateTabLinkActionRequested
  | CreateTabLinkActionCompleted
  | CreateTabLinkActionFailed
  | Action<typeof tabTypes.CLEAR_TAB_LINK_STATE>;

export function addTab(
  name: string,
  tabType: TabType,
  component: ReactElement,
  routePath: string,
  fromHistory = false,
  id: string | undefined,
  refreshed = 0,
  parentTab?: string,
  allowClose = true,
  additionalMetadata?: GenericDictionaryParams,
  children?: TabDetails[],
  atIndex?: number,
): AddTabAction {
  return {
    type: tabTypes.ADD_TAB,
    tab: {
      component,
      name,
      allowClose,
      id: id || (typeof component.key === 'number' ? (component.key as number).toString() : component.key) || undefined,
      metadata: {
        tabType,
        routePath,
        ...additionalMetadata,
      },
      children: { tabs: children || [] },
      refreshed,
    },
    fromHistory,
    parentTab,
    atIndex,
  };
}

export function setTabModalContent(
  id: string,
  tabType: TabType,
  parentTab: string,
  options?: TabModalOptions,
): SetTabModalContentAction {
  return {
    type: tabTypes.SET_TAB_MODAL_CONTENT,
    id,
    tabType,
    parentTab,
    options,
  };
}

export function setTabModalError(
  id: string,
  tabType: TabType,
  parentTab: string,
  message: string,
): SetTabModalContentAction {
  return setTabModalContent(
    id,
    tabType,
    parentTab,
    { style: 'error', content: message },
  );
}

export function removeTab(
  id: string | undefined,
  tabType: TabType,
  parentTab?: string,
): RemoveTabAction {
  return {
    type: tabTypes.REMOVE_TAB,
    id,
    tabType,
    parentTab,
  };
}

export function pinTab(
  id: string | undefined,
  tabType: TabType,
  parentTab: string,
): PinTabAction {
  return {
    type: tabTypes.PIN_TAB,
    id,
    tabType,
    parentTab,
  };
}

export function unpinTab(
  id: string | undefined,
  tabType: TabType,
): UnPinTabAction {
  return {
    type: tabTypes.UNPIN_TAB,
    id,
    tabType,
  };
}

export function updateTab(
  existingTab: TabDetails,
  name: string | undefined,
  component: ReactElement,
  action: string,
  actionParams?: Record<string, unknown>,
  parentTab?: string,
): UpdateTabAction {
  const { component: previous, ...rest } = existingTab;

  // This may have to be tweaked to ensure each type of tab
  // can be created from the params stored.
  const { resp, ...remainingParams } = actionParams ?? {};
  const tabParams: Record<string, unknown> = {
    type: action,
    ...remainingParams,
  };

  return {
    type: tabTypes.UPDATE_TAB,
    tab: {
      ...rest,
      component,
      name,
      metadata: {
        ...(existingTab.metadata as TabMetadata),
        params: tabParams,
        action,
      },
    },
    parentTab,
    resp,
  };
}

export function activateTab(
  id: string | undefined,
  tabType: TabType,
  parentTab?: string,
): ActivateTabAction {
  return {
    type: tabTypes.ACTIVATE_TAB,
    id,
    tabType,
    parentTab,
  };
}

export function appBootStrapTabLinks(id: string) {
  return {
    type: tabTypes.APP_BOOT_STRAP_TAB_LINKS,
    id,
  };
}

export function createTabLinkState(selectedTabs: TabMetadata[]) {
  return {
    type: tabTypes.CREATE_TAB_LINK_STATE,
    TabMetadata: selectedTabs,
  };
}

export function clearTabLinkState() {
  return {
    type: tabTypes.CLEAR_TAB_LINK_STATE,
  };
}
