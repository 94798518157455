import _ from 'lodash';
import { CreateTabLinkActionRequested } from 'app/actions/tabActions';
import { LinkStateDataModel } from 'app/helpers/types';
import type { App } from 'app/store';

export async function getLinkState(app: App, id:string) {
  return app.clients.linkState.linkStateGetLinkState(id);
}

export async function addLinkState(app: App, p:CreateTabLinkActionRequested) {
  const tabParams = _.map(p.TabMetadata, (t): LinkStateDataModel => ({
    tabType: t.tabType,
    data: { ...t.params, children: t.children },
  }));

  return app.clients.linkState.linkStateAddLinkState({ createdAt: new Date(), links: tabParams });
}
