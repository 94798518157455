/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDirectoryServicesAssignedPlanInitialState } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlanInitialState';
import { MicrosoftOnlineDirectoryServicesCapabilityStatus } from '../models/MicrosoftOnlineDirectoryServicesCapabilityStatus';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDirectoryServicesAssignedPlan {
    'subscribedPlanId'?: string;
    'serviceInstance'?: string | null;
    'capabilityStatus'?: MicrosoftOnlineDirectoryServicesCapabilityStatus;
    'assignedTimestamp'?: Date;
    'initialState'?: MicrosoftOnlineDirectoryServicesAssignedPlanInitialState | null;
    'capability'?: MicrosoftOnlineDirectoryServicesAssignedPlanInitialState | null;
    'servicePlanId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "subscribedPlanId",
            "baseName": "subscribedPlanId",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "serviceInstance",
            "baseName": "serviceInstance",
            "type": "string",
            "format": ""
        },
        {
            "name": "capabilityStatus",
            "baseName": "capabilityStatus",
            "type": "MicrosoftOnlineDirectoryServicesCapabilityStatus",
            "format": ""
        },
        {
            "name": "assignedTimestamp",
            "baseName": "assignedTimestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "initialState",
            "baseName": "initialState",
            "type": "MicrosoftOnlineDirectoryServicesAssignedPlanInitialState",
            "format": ""
        },
        {
            "name": "capability",
            "baseName": "capability",
            "type": "MicrosoftOnlineDirectoryServicesAssignedPlanInitialState",
            "format": ""
        },
        {
            "name": "servicePlanId",
            "baseName": "servicePlanId",
            "type": "string",
            "format": "uuid"
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDirectoryServicesAssignedPlan.attributeTypeMap;
    }

    public constructor() {
    }
}



