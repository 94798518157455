import type { App } from 'app/store';
import {
  all, call, put, select, takeLatest, SagaGenerator, take,
} from 'typed-redux-saga';
import { loginUser, logout } from 'app/services/auth';
import { push } from 'connected-react-router';
import * as companyTypes from 'app/actions/companyTypes';
import * as authTypes from 'app/actions/authTypes';
import { GET_USER_PREFERENCES } from 'app/actions/preferenceTypes';
import { RESTORE_TABS, RESTORE_TABS_COMPLETED } from 'app/actions/tabTypes';
import { State } from 'app/reducers/state';
import { GetAnnouncements } from 'app/actions/announcementActions';
import { GET_SYSTEM_TASKS } from 'app/actions/systemTypes';
import { getCompanyByName, getSingleObject } from 'app/actions/companyActions';
import _ from 'lodash';

const getLocation = (state: State) => state.router?.location;

function* callLoginAsync(app: App) {
  if (app.config.shouldBypassAuth()) {
    yield* put({ type: authTypes.LOGIN_COMPLETED, resp: {} });
    return;
  }

  try {
    const currentLocation = yield* select(getLocation);
    const resp = yield* call(loginUser, app);

    yield* all([
      take(RESTORE_TABS_COMPLETED),
      put(push('/')),
      put({ type: authTypes.LOGIN_COMPLETED, resp }),

      // Fire after login completes.
      put(GetAnnouncements(true)),
      put({ type: GET_USER_PREFERENCES }),
      put({ type: RESTORE_TABS }),
      put({ type: GET_SYSTEM_TASKS }),

    ]);

    // All tabs restored from this point
    if (currentLocation?.pathname.toLowerCase().startsWith('/company/')) {
      const parts = currentLocation.pathname.toLowerCase().replace('/company/', '').split('/');

      if (parts.length > 0) {
        yield* put(getCompanyByName({ companyIdentifier: _.nth(parts, 0) as string }, true));
      }

      if (parts.length > 1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { tabId } = yield* take(<any>companyTypes.GET_COMPANY_BY_NAME_COMPLETED) as any;
        if (tabId) {
          yield* put(getSingleObject({ contextId: _.nth(parts, 0) as string, objectId: _.nth(parts, 1) as string }, tabId));
        }
      }
    }
  } catch (error) {
    yield* put({ type: authTypes.LOGIN_FAILED });
  }
}

function* callLogoutAsync(app: App): SagaGenerator<void> {
  yield* call(logout, app);
  yield* put({ type: authTypes.LOGOUT_COMPLETED });
  window.location.reload();
}

export default function* watchAll(app: App): SagaGenerator<void> {
  yield* all([
    takeLatest(authTypes.LOGIN_REQUESTED, callLoginAsync, app),
    takeLatest(authTypes.LOGOUT_REQUESTED, callLogoutAsync, app),
  ]);
}
