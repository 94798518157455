import {
  ColDef, SortModelItem, ValueFormatterParams, ValueGetterFunc, ValueGetterParams,
} from 'ag-grid-community';
import { TimeFormatType } from 'app/typings';
import _ from 'lodash';
import moment from 'moment';
import { timeColumns } from '../Utilities/Formatters';
import { FormatTime } from '../Utilities/TimeFormat';
import { RowRecord } from './DSTableTypes';
import ReactComponentRenderer from './ReactComponentRenderer';

interface TextColumnOptions {
  name: string,
  flex?: number,
  width?: number,
  includeFilter?: boolean,
  makeCenterAligned?: boolean,
  extra?: Record<string, unknown>
}

interface NumericColumnOptions {
  name: string,
  width?: number,
  makeCenterAligned?: boolean,
  includeValueGetter?: boolean,
  extra?: Record<string, unknown>
}

// eslint-disable-next-line consistent-return
function dateFormatter(params: ValueFormatterParams, timeFormat: TimeFormatType, timeUtc: boolean) {
  if (moment.isMoment(params.value)) {
    return FormatTime(params.value, timeFormat, timeUtc);
  }
}

// eslint-disable-next-line consistent-return
function dateValueGetter(params: ValueGetterParams) {
  const { field } = params.colDef;
  if (field && timeColumns.includes(field) && _.has(params.data, field) && _.has(params.data[`${field}`], 'props')) {
    return params.data[`${field}`].props.time;
  }
}

function stringNumberGetter(params: ValueGetterParams) {
  if (params.data === undefined) {
    return undefined;
  }

  const { field } = params.colDef;
  if (field) {
    return parseInt(_.get(params.data, field) as string, 10);
  }

  return undefined;
}

function createNumericColumn({
  name, width = 110, makeCenterAligned = false, includeValueGetter = false,
}: NumericColumnOptions) {
  const options = {
    name,
    options: {
      filter: 'agNumberColumnFilter',
      flex: undefined,
      width,
    },
  };

  if (includeValueGetter) {
    _.assign(options.options, {
      valueGetter: stringNumberGetter,
    });
  }

  if (makeCenterAligned) {
    _.assign(options.options, {
      headerClass: 'text-center',
      cellStyle: {
        textAlign: 'center',
      },
    });
  }

  return options;
}

function createTextColumn({
  name,
  flex = 1,
  width = undefined,
  includeFilter = false,
  makeCenterAligned = false,
  extra = undefined,
}: TextColumnOptions) {
  const options = {
    name,
    options: {
      flex: width === undefined ? flex : undefined,
      width,
    },
  };

  if (includeFilter) {
    _.assign(options.options, {
      filter: 'agTextColumnFilter',
    });
  }

  if (makeCenterAligned) {
    _.assign(options.options, {
      headerClass: 'text-center',
      cellStyle: {
        textAlign: 'center',
      },
    });
  }

  if (extra) {
    _.assign(options.options, extra);
  }

  return options;
}

export function applyColumnCustomization(columns: ColDef[], timeFormat: TimeFormatType, timeUtc: boolean) {
  const cutomizations = [
    createTextColumn({ name: 'Name', width: 250, includeFilter: true }),
    createTextColumn({ name: 'Deleted', width: 250 }),
    createNumericColumn({ name: '#', width: 80 }),
    createNumericColumn({ name: 'Prepaid Units', width: 140 }),
    createNumericColumn({ name: 'Max Overage Units', width: 170 }),
    createNumericColumn({
      name: 'ConsumedUnits', width: 160, makeCenterAligned: true,
    }),
    createNumericColumn({ name: 'LockedOut', width: 130, makeCenterAligned: true }),
    createNumericColumn({ name: 'Suspended', width: 120, makeCenterAligned: true }),
    createNumericColumn({ name: 'Warned', width: 110, makeCenterAligned: true }),
    createNumericColumn({ name: 'Enabled', width: 120, makeCenterAligned: true }),
    createTextColumn({ name: 'Value(s)', flex: 2, includeFilter: true }),
    createTextColumn({ name: 'Directory Operation', flex: 2 }),
    createTextColumn({
      name: 'Non-Sliced',
      makeCenterAligned: true,
      extra: {
        cellRendererSelector: () => ({
          component: ReactComponentRenderer,
        }),
      },
    }),
    createTextColumn({
      name: 'Slice 1',
      makeCenterAligned: true,
      extra: {
        cellRendererSelector: () => ({
          component: ReactComponentRenderer,
        }),
      },
    }),
    createTextColumn({
      name: 'Slice 2',
      makeCenterAligned: true,
      extra: {
        cellRendererSelector: () => ({
          component: ReactComponentRenderer,
        }),
      },
    }),
    createTextColumn({
      name: 'Can Be Disabled',
      makeCenterAligned: true,
      extra: {
        cellRendererSelector: () => ({
          component: ReactComponentRenderer,
        }),
      },
    }),
  ];

  cutomizations.forEach((c) => {
    const foundColumn = _.find(columns, (o) => o.field === c.name);

    if (foundColumn !== undefined) {
      _.assign(foundColumn, c.options);
    }
  });

  // Apply Date Formatter to all known Time Columns
  const foundTimeColumns = _.intersectionWith(columns, timeColumns, (o, t) => t === o.field);

  foundTimeColumns.forEach((c) => {
    _.assign(c, {
      valueGetter: dateValueGetter,
      valueFormatter: (params: ValueFormatterParams) => dateFormatter(params, timeFormat, timeUtc),
      filter: 'agDateColumnFilter',
      cellRendererSelector: null,
    });
  });
}

export const applySorting = (columnDefs: ColDef[], data: RowRecord[], sortModel: SortModelItem[]) => {
  if (sortModel.length === 0) {
    return data;
  }

  let column = _.first(sortModel)?.colId;
  const order = _.first(sortModel)?.sort ?? 'asc';

  const foundColumn = _.find(columnDefs, (c) => c.field === column);

  if (foundColumn !== undefined && typeof foundColumn.valueGetter === 'function') {
    data.forEach((row) => {
      const valueGetter = ((foundColumn as ColDef).valueGetter as ValueGetterFunc);

      const value = valueGetter({
        colDef: foundColumn,
        data: row,
      } as ValueGetterParams);

      if (value !== undefined) {
        _.assign(row, {
          __sortedValueField: value,
        });

        // Update the sort column
        column = '__sortedValueField';
      }
    });
  }

  return _.orderBy(data, [column], [order]);
};

export const calculateRowHeight = (fontSize: string) => {
  switch (fontSize) {
    case 'smaller':
      return 22;
    case 'small':
      return 26;
    case 'medium':
      return 31;
    case 'large':
      return 34;
    case 'larger':
      return 37;
    case 'huge':
      return 40;
    default:
      return 32;
  }
};
