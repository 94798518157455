export const GET_APPLICATION_STUB = 'GET_APPLICATION_STUB' as const;
export const GET_APPLICATION_STUB_COMPLETED = 'GET_APPLICATION_STUB_COMPLETED' as const;
export const GET_APPLICATION_STUB_FAILED = 'GET_APPLICATION_STUB_FAILED' as const;

export const GET_TRUSTED_REALM_STUB = 'GET_TRUSTED_REALM_STUB' as const;
export const GET_TRUSTED_REALM_STUB_COMPLETED = 'GET_TRUSTED_REALM_STUB_COMPLETED' as const;
export const GET_TRUSTED_REALM_STUB_FAILED = 'GET_TRUSTED_REALM_STUB_FAILED' as const;

export const FIND_APPLICATION_FROM_INDEX_STORE = 'FIND_APPLICATION_FROM_INDEX_STORE' as const;
export const FIND_APPLICATION_FROM_INDEX_STORE_COMPLETED = 'FIND_APPLICATION_FROM_INDEX_STORE_COMPLETED' as const;
export const FIND_APPLICATION_FROM_INDEX_STORE_FAILED = 'FIND_APPLICATION_FROM_INDEX_STORE_FAILED' as const;
