import 'semantic-ui-offline/semantic.min.css';

import _ from 'lodash';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { getQueryStringParams } from 'app/helpers/helpers';

import { LOGIN_REQUESTED } from 'app/actions/authTypes';
import { appBootStrapTabLinks } from 'app/actions/tabActions';
import { Store } from 'redux';
import { LOAD_LOCAL_USER_PREFERENCES } from './actions/preferenceTypes';
import AppWrapper from './App';
import EnvSwitcher from './components/EnvSwitcher/EnvSwitcher';
import {
  createApplicationStore, history, app, configureApplicationObject,
} from './store';
import { fetchApplicationConfiguration, configFile } from './constants/config';
import ContentModal from './components/shared/ContentModal/ContentModal';

async function AuthenticateApp(store: Store) {
  const searchParams = getQueryStringParams(window.location.search);
  const hashParams = getQueryStringParams(window.location.hash);
  const queryString = { ...searchParams, ...hashParams };

  if (_.keys(queryString).length > 0) {
    // We can grab start-up parameters out of the query string sent to the application
    // such as (auth token, pages, etc...)
    app.constants.logDebug('App Start-up query string', queryString);
  }

  if (queryString.linkState) {
    store.dispatch(appBootStrapTabLinks(queryString.linkState));
  }

  store.dispatch({ type: LOAD_LOCAL_USER_PREFERENCES });
  store.dispatch({ type: LOGIN_REQUESTED });
}

// MAIN ENTRY POINT //
fetchApplicationConfiguration().then(() => {
  // Sets the config, creates Proxy Clients, etc...
  configureApplicationObject();

  app.config.logApplicationConfiguration(false);
  const store = createApplicationStore();

  // Ensure the switcher does not trigger the auth set up.
  if (window.location.pathname.toLowerCase() === '/envswitch') {
    store.dispatch({ type: LOAD_LOCAL_USER_PREFERENCES });

    ReactDOM.render(
      <Provider store={store}><EnvSwitcher data={app.config.getEnvPeers()} /></Provider>,
      document.getElementById('app'),
    );
  } else {
    // Handle Authentication
    AuthenticateApp(store);

    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppWrapper />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('app'),
    );
  }
}).catch((e) => {
  // eslint-disable-next-line
  console.error('Response from the server was:', e);

  ReactDOM.render(
    <ContentModal
      icon="warning sign"
      header="Error Loading Config"
      style="error"
      message={`An error occurred while retrieving the file '${configFile}' from the server.`}
      open
    />,
    document.getElementById('app'),
  );
});
