import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { CommonAuthenticationModelAuthRequestModel } from '../models/CommonAuthenticationModelAuthRequestModel';
import { CommonAuthenticationModelAuthRequestModelUserIdentity } from '../models/CommonAuthenticationModelAuthRequestModelUserIdentity';
import { CommonAuthenticationModelUserIdentity } from '../models/CommonAuthenticationModelUserIdentity';
import { CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray';
import { CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelResult';
import { CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel';
import { CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult } from '../models/CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModelResult';
import { CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/CommonContractsBasicResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString } from '../models/CommonContractsBasicResponseOfSystemCollectionsGenericListOfSystemString';
import { CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString } from '../models/CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString';
import { CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
import { CommonModelsCompanyContextObjectClasses } from '../models/CommonModelsCompanyContextObjectClasses';
import { CommonModelsDirSyncSupportedDirectoryProperties } from '../models/CommonModelsDirSyncSupportedDirectoryProperties';
import { CommonModelsDirectoryLinkType } from '../models/CommonModelsDirectoryLinkType';
import { CommonModelsDirectoryObjectTreeNodeModel } from '../models/CommonModelsDirectoryObjectTreeNodeModel';
import { CommonModelsEntitlementGrantValidSourceObjectClasses } from '../models/CommonModelsEntitlementGrantValidSourceObjectClasses';
import { CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask } from '../models/CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask';
import { CommonModelsGenericTreeNodeItemModel } from '../models/CommonModelsGenericTreeNodeItemModel';
import { CommonModelsRequiredApplicationOauth2Permissions } from '../models/CommonModelsRequiredApplicationOauth2Permissions';
import { CommonModelsRoleAssignmentValidTargetObjectClasses } from '../models/CommonModelsRoleAssignmentValidTargetObjectClasses';
import { CommonModelsScopedMemberValidTargetObjectClasses } from '../models/CommonModelsScopedMemberValidTargetObjectClasses';
import { CommonModelsSystemContextObjectClasses } from '../models/CommonModelsSystemContextObjectClasses';
import { CommonModelsTreeNodeModel } from '../models/CommonModelsTreeNodeModel';
import { DSExplorerDataModelsAnnouncementTrackingDataModel } from '../models/DSExplorerDataModelsAnnouncementTrackingDataModel';
import { DSExplorerDataModelsBaseCosmosDataEntry } from '../models/DSExplorerDataModelsBaseCosmosDataEntry';
import { DSExplorerDataModelsBrowsedObjectSearchModel } from '../models/DSExplorerDataModelsBrowsedObjectSearchModel';
import { DSExplorerDataModelsLinkStateCollectionDataModel } from '../models/DSExplorerDataModelsLinkStateCollectionDataModel';
import { DSExplorerDataModelsLinkStateDataModel } from '../models/DSExplorerDataModelsLinkStateDataModel';
import { DSExplorerDataModelsStatusMessageDataModel } from '../models/DSExplorerDataModelsStatusMessageDataModel';
import { DSExplorerDataModelsUserPreferencesDataModel } from '../models/DSExplorerDataModelsUserPreferencesDataModel';
import { DSExplorerDataStatusMessageType } from '../models/DSExplorerDataStatusMessageType';
import { DSExplorerWebHostContractsFilterStringEnum } from '../models/DSExplorerWebHostContractsFilterStringEnum';
import { DSExplorerWebHostModelsBase64Model } from '../models/DSExplorerWebHostModelsBase64Model';
import { DSExplorerWebHostModelsBasePropertySearchRequestModel } from '../models/DSExplorerWebHostModelsBasePropertySearchRequestModel';
import { DSExplorerWebHostModelsBulkLinkSearchRequestModel } from '../models/DSExplorerWebHostModelsBulkLinkSearchRequestModel';
import { DSExplorerWebHostModelsBulkSearchRequestModel } from '../models/DSExplorerWebHostModelsBulkSearchRequestModel';
import { DSExplorerWebHostModelsDateTimeModel } from '../models/DSExplorerWebHostModelsDateTimeModel';
import { DSExplorerWebHostModelsGuidModel } from '../models/DSExplorerWebHostModelsGuidModel';
import { DSExplorerWebHostModelsHexModel } from '../models/DSExplorerWebHostModelsHexModel';
import { DSExplorerWebHostModelsNetIdModel } from '../models/DSExplorerWebHostModelsNetIdModel';
import { DSExplorerWebHostModelsPropertyFilterModel } from '../models/DSExplorerWebHostModelsPropertyFilterModel';
import { DSExplorerWebHostModelsPropertySearchRequestModel } from '../models/DSExplorerWebHostModelsPropertySearchRequestModel';
import { DSExplorerWebHostModelsSidModel } from '../models/DSExplorerWebHostModelsSidModel';
import { MicrosoftOnlineDSExplorerContractAltSecIdType } from '../models/MicrosoftOnlineDSExplorerContractAltSecIdType';
import { MicrosoftOnlineDSExplorerContractBinaryDSReference } from '../models/MicrosoftOnlineDSExplorerContractBinaryDSReference';
import { MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet } from '../models/MicrosoftOnlineDSExplorerContractBinaryDSReferenceSet';
import { MicrosoftOnlineDSExplorerContractCompanyLicenses } from '../models/MicrosoftOnlineDSExplorerContractCompanyLicenses';
import { MicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDerivedProperty';
import { MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryProperty';
import { MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority } from '../models/MicrosoftOnlineDSExplorerContractDSObjectDirectoryPropertyAllOfOriginatingAuthority';
import { MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty } from '../models/MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty';
import { MicrosoftOnlineDSExplorerContractDSPartitionDetails } from '../models/MicrosoftOnlineDSExplorerContractDSPartitionDetails';
import { MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription } from '../models/MicrosoftOnlineDSExplorerContractDSPartitionFailoverDescription';
import { MicrosoftOnlineDSExplorerContractDSReference } from '../models/MicrosoftOnlineDSExplorerContractDSReference';
import { MicrosoftOnlineDSExplorerContractDSReferenceSet } from '../models/MicrosoftOnlineDSExplorerContractDSReferenceSet';
import { MicrosoftOnlineDSExplorerContractDelegationType } from '../models/MicrosoftOnlineDSExplorerContractDelegationType';
import { MicrosoftOnlineDSExplorerContractDirectoryPropertyBase } from '../models/MicrosoftOnlineDSExplorerContractDirectoryPropertyBase';
import { MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType } from '../models/MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType';
import { MicrosoftOnlineDSExplorerContractLicenseDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseDetail';
import { MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseDetailMaximumOverageUnitsDetail';
import { MicrosoftOnlineDSExplorerContractLicenseUnitsDetail } from '../models/MicrosoftOnlineDSExplorerContractLicenseUnitsDetail';
import { MicrosoftOnlineDSExplorerContractMetadataProperty } from '../models/MicrosoftOnlineDSExplorerContractMetadataProperty';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractProvisioningStatusDetail } from '../models/MicrosoftOnlineDSExplorerContractProvisioningStatusDetail';
import { MicrosoftOnlineDSExplorerContractResponseBase } from '../models/MicrosoftOnlineDSExplorerContractResponseBase';
import { MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray';
import { MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicensesAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetailAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetailAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings';
import { MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData } from '../models/MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettingsAllOfData';
import { MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray } from '../models/MicrosoftOnlineDSExplorerContractResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatus';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusAssignedPlan';
import { MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan } from '../models/MicrosoftOnlineDSExplorerContractServicePlanProvisioningStatusProvisionedPlan';
import { MicrosoftOnlineDSExplorerContractSubscriptionDetail } from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetail';
import { MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus } from '../models/MicrosoftOnlineDSExplorerContractSubscriptionDetailSubscriptionStatus';
import { MicrosoftOnlineDSExplorerContractSystemSettings } from '../models/MicrosoftOnlineDSExplorerContractSystemSettings';
import { MicrosoftOnlineDSExplorerContractUserIdentifierType } from '../models/MicrosoftOnlineDSExplorerContractUserIdentifierType';
import { MicrosoftOnlineDirectoryServicesAssignedPlan } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlan';
import { MicrosoftOnlineDirectoryServicesAssignedPlanInitialState } from '../models/MicrosoftOnlineDirectoryServicesAssignedPlanInitialState';
import { MicrosoftOnlineDirectoryServicesAuthorizationScopeType } from '../models/MicrosoftOnlineDirectoryServicesAuthorizationScopeType';
import { MicrosoftOnlineDirectoryServicesCapabilityStatus } from '../models/MicrosoftOnlineDirectoryServicesCapabilityStatus';
import { MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory } from '../models/MicrosoftOnlineDirectoryServicesDirSyncProvisioningErrorCategory';
import { MicrosoftOnlineDirectoryServicesDirSyncState } from '../models/MicrosoftOnlineDirectoryServicesDirSyncState';
import { MicrosoftOnlineDirectoryServicesDirectoryAuthority } from '../models/MicrosoftOnlineDirectoryServicesDirectoryAuthority';
import { MicrosoftOnlineDirectoryServicesDirectoryLinkClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryLinkClass';
import { MicrosoftOnlineDirectoryServicesDirectoryObjectClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryObjectClass';
import { MicrosoftOnlineDirectoryServicesDirectoryProperty } from '../models/MicrosoftOnlineDirectoryServicesDirectoryProperty';
import { MicrosoftOnlineDirectoryServicesDirectoryReferenceClass } from '../models/MicrosoftOnlineDirectoryServicesDirectoryReferenceClass';
import { MicrosoftOnlineDirectoryServicesProvisionedPlan } from '../models/MicrosoftOnlineDirectoryServicesProvisionedPlan';
import { MicrosoftOnlineDirectoryServicesProvisioningStatus } from '../models/MicrosoftOnlineDirectoryServicesProvisioningStatus';
import { MicrosoftOnlineDirectoryServicesSearchFilterOperator } from '../models/MicrosoftOnlineDirectoryServicesSearchFilterOperator';
import { MicrosoftOnlineDirectoryServicesServicePlanProperties } from '../models/MicrosoftOnlineDirectoryServicesServicePlanProperties';
import { MicrosoftOnlineDirectoryServicesSubscriptionStatus } from '../models/MicrosoftOnlineDirectoryServicesSubscriptionStatus';
import { PartitionGetCompaniesByPartitionDirSyncStateFilterParameter } from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameter';
import { PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf } from '../models/PartitionGetCompaniesByPartitionDirSyncStateFilterParameterOneOf';
import { SearchDelegationEntriesDelegationTypeParameter } from '../models/SearchDelegationEntriesDelegationTypeParameter';
import { SearchDelegationEntriesDelegationTypeParameterOneOf } from '../models/SearchDelegationEntriesDelegationTypeParameterOneOf';
import { SearchDelegationEntriesUserIdentifierTypeParameter } from '../models/SearchDelegationEntriesUserIdentifierTypeParameter';
import { SearchDelegationEntriesUserIdentifierTypeParameterOneOf } from '../models/SearchDelegationEntriesUserIdentifierTypeParameterOneOf';
import { SearchDirSyncProvisioningErrorsErrorCategoryParameter } from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameter';
import { SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf } from '../models/SearchDirSyncProvisioningErrorsErrorCategoryParameterOneOf';
import { SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter } from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter';
import { SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf } from '../models/SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameterOneOf';
import { SearchEntitlementGrantsSourceObjectClassParameter } from '../models/SearchEntitlementGrantsSourceObjectClassParameter';
import { SearchRoleAssignmentsAuthorizationScopeTypeParameter } from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameter';
import { SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf } from '../models/SearchRoleAssignmentsAuthorizationScopeTypeParameterOneOf';
import { SearchRoleAssignmentsTargetObjectClassParameter } from '../models/SearchRoleAssignmentsTargetObjectClassParameter';
import { SearchRoleAssignmentsTargetObjectClassParameterOneOf } from '../models/SearchRoleAssignmentsTargetObjectClassParameterOneOf';
import { SearchScopedMembersTargetObjectClassParameter } from '../models/SearchScopedMembersTargetObjectClassParameter';
import { SearchScopedMembersTargetObjectClassParameterOneOf } from '../models/SearchScopedMembersTargetObjectClassParameterOneOf';
import { SearchServicePrincipalsFilterTypeParameter } from '../models/SearchServicePrincipalsFilterTypeParameter';
import { SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString } from '../models/SystemTupleOfSystemStringAndSystemCollectionsGenericICollectionOfSystemString';
import { SystemXmlLinqXAttribute } from '../models/SystemXmlLinqXAttribute';
import { SystemXmlLinqXAttributeAllOfNextAttribute } from '../models/SystemXmlLinqXAttributeAllOfNextAttribute';
import { SystemXmlLinqXAttributeAllOfPreviousAttribute } from '../models/SystemXmlLinqXAttributeAllOfPreviousAttribute';
import { SystemXmlLinqXContainer } from '../models/SystemXmlLinqXContainer';
import { SystemXmlLinqXContainerAllOfFirstNode } from '../models/SystemXmlLinqXContainerAllOfFirstNode';
import { SystemXmlLinqXContainerAllOfLastNode } from '../models/SystemXmlLinqXContainerAllOfLastNode';
import { SystemXmlLinqXDeclaration } from '../models/SystemXmlLinqXDeclaration';
import { SystemXmlLinqXDocument } from '../models/SystemXmlLinqXDocument';
import { SystemXmlLinqXDocumentAllOfDeclaration } from '../models/SystemXmlLinqXDocumentAllOfDeclaration';
import { SystemXmlLinqXDocumentAllOfDocumentType } from '../models/SystemXmlLinqXDocumentAllOfDocumentType';
import { SystemXmlLinqXDocumentAllOfRoot } from '../models/SystemXmlLinqXDocumentAllOfRoot';
import { SystemXmlLinqXDocumentType } from '../models/SystemXmlLinqXDocumentType';
import { SystemXmlLinqXElement } from '../models/SystemXmlLinqXElement';
import { SystemXmlLinqXElementAllOfFirstAttribute } from '../models/SystemXmlLinqXElementAllOfFirstAttribute';
import { SystemXmlLinqXElementAllOfLastAttribute } from '../models/SystemXmlLinqXElementAllOfLastAttribute';
import { SystemXmlLinqXName } from '../models/SystemXmlLinqXName';
import { SystemXmlLinqXNamespace } from '../models/SystemXmlLinqXNamespace';
import { SystemXmlLinqXNode } from '../models/SystemXmlLinqXNode';
import { SystemXmlLinqXNodeAllOfNextNode } from '../models/SystemXmlLinqXNodeAllOfNextNode';
import { SystemXmlLinqXNodeAllOfPreviousNode } from '../models/SystemXmlLinqXNodeAllOfPreviousNode';
import { SystemXmlLinqXObject } from '../models/SystemXmlLinqXObject';
import { SystemXmlLinqXObjectDocument } from '../models/SystemXmlLinqXObjectDocument';
import { SystemXmlXmlNodeType } from '../models/SystemXmlXmlNodeType';

import { AnnouncementsApiRequestFactory, AnnouncementsApiResponseProcessor} from "../apis/AnnouncementsApi";
export class ObservableAnnouncementsApi {
    private requestFactory: AnnouncementsApiRequestFactory;
    private responseProcessor: AnnouncementsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AnnouncementsApiRequestFactory,
        responseProcessor?: AnnouncementsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AnnouncementsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AnnouncementsApiResponseProcessor();
    }

    /**
     * @param messageId 
     */
    public announcementsAcknowledgeWithHttpInfo(messageId?: string, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel>> {
        const requestContextPromise = this.requestFactory.announcementsAcknowledge(messageId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.announcementsAcknowledgeWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param messageId 
     */
    public announcementsAcknowledge(messageId?: string, _options?: Configuration): Observable<CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel> {
        return this.announcementsAcknowledgeWithHttpInfo(messageId, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsAnnouncementTrackingDataModel>) => apiResponse.data));
    }

    /**
     * @param dSExplorerDataModelsStatusMessageDataModel 
     */
    public announcementsAddStatusWithHttpInfo(dSExplorerDataModelsStatusMessageDataModel: DSExplorerDataModelsStatusMessageDataModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel>> {
        const requestContextPromise = this.requestFactory.announcementsAddStatus(dSExplorerDataModelsStatusMessageDataModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.announcementsAddStatusWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerDataModelsStatusMessageDataModel 
     */
    public announcementsAddStatus(dSExplorerDataModelsStatusMessageDataModel: DSExplorerDataModelsStatusMessageDataModel, _options?: Configuration): Observable<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel> {
        return this.announcementsAddStatusWithHttpInfo(dSExplorerDataModelsStatusMessageDataModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel>) => apiResponse.data));
    }

    /**
     * @param includeAcknowledged 
     */
    public announcementsGetStatusWithHttpInfo(includeAcknowledged?: boolean, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray>> {
        const requestContextPromise = this.requestFactory.announcementsGetStatus(includeAcknowledged, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.announcementsGetStatusWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param includeAcknowledged 
     */
    public announcementsGetStatus(includeAcknowledged?: boolean, _options?: Configuration): Observable<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray> {
        return this.announcementsGetStatusWithHttpInfo(includeAcknowledged, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModelArray>) => apiResponse.data));
    }

    /**
     * @param dSExplorerDataModelsStatusMessageDataModel 
     */
    public announcementsUpdateStatusMessageWithHttpInfo(dSExplorerDataModelsStatusMessageDataModel: DSExplorerDataModelsStatusMessageDataModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel>> {
        const requestContextPromise = this.requestFactory.announcementsUpdateStatusMessage(dSExplorerDataModelsStatusMessageDataModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.announcementsUpdateStatusMessageWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerDataModelsStatusMessageDataModel 
     */
    public announcementsUpdateStatusMessage(dSExplorerDataModelsStatusMessageDataModel: DSExplorerDataModelsStatusMessageDataModel, _options?: Configuration): Observable<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel> {
        return this.announcementsUpdateStatusMessageWithHttpInfo(dSExplorerDataModelsStatusMessageDataModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsStatusMessageDataModel>) => apiResponse.data));
    }

}

import { ApplicationApiRequestFactory, ApplicationApiResponseProcessor} from "../apis/ApplicationApi";
export class ObservableApplicationApi {
    private requestFactory: ApplicationApiRequestFactory;
    private responseProcessor: ApplicationApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ApplicationApiRequestFactory,
        responseProcessor?: ApplicationApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ApplicationApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ApplicationApiResponseProcessor();
    }

    /**
     * @param companyIdentifier 
     * @param applicationId 
     */
    public applicationGetApplicationWithHttpInfo(companyIdentifier: string, applicationId: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.applicationGetApplication(companyIdentifier, applicationId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.applicationGetApplicationWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param applicationId 
     */
    public applicationGetApplication(companyIdentifier: string, applicationId: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.applicationGetApplicationWithHttpInfo(companyIdentifier, applicationId, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param applicationId 
     */
    public applicationGetApplicationPermissionsWithHttpInfo(companyIdentifier: string, applicationId: string, _options?: Configuration): Observable<HttpInfo<Array<CommonModelsRequiredApplicationOauth2Permissions>>> {
        const requestContextPromise = this.requestFactory.applicationGetApplicationPermissions(companyIdentifier, applicationId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.applicationGetApplicationPermissionsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param applicationId 
     */
    public applicationGetApplicationPermissions(companyIdentifier: string, applicationId: string, _options?: Configuration): Observable<Array<CommonModelsRequiredApplicationOauth2Permissions>> {
        return this.applicationGetApplicationPermissionsWithHttpInfo(companyIdentifier, applicationId, _options).pipe(map((apiResponse: HttpInfo<Array<CommonModelsRequiredApplicationOauth2Permissions>>) => apiResponse.data));
    }

}

import { AuthApiRequestFactory, AuthApiResponseProcessor} from "../apis/AuthApi";
export class ObservableAuthApi {
    private requestFactory: AuthApiRequestFactory;
    private responseProcessor: AuthApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthApiRequestFactory,
        responseProcessor?: AuthApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AuthApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AuthApiResponseProcessor();
    }

    /**
     * @param interactiveReturn 
     */
    public authAuthenticationUrlGETWithHttpInfo(interactiveReturn?: boolean, _options?: Configuration): Observable<HttpInfo<HttpFile>> {
        const requestContextPromise = this.requestFactory.authAuthenticationUrlGET(interactiveReturn, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.authAuthenticationUrlGETWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param interactiveReturn 
     */
    public authAuthenticationUrlGET(interactiveReturn?: boolean, _options?: Configuration): Observable<HttpFile> {
        return this.authAuthenticationUrlGETWithHttpInfo(interactiveReturn, _options).pipe(map((apiResponse: HttpInfo<HttpFile>) => apiResponse.data));
    }

    /**
     * @param commonAuthenticationModelAuthRequestModel 
     */
    public authAuthenticationUrlPOSTWithHttpInfo(commonAuthenticationModelAuthRequestModel: CommonAuthenticationModelAuthRequestModel, _options?: Configuration): Observable<HttpInfo<HttpFile>> {
        const requestContextPromise = this.requestFactory.authAuthenticationUrlPOST(commonAuthenticationModelAuthRequestModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.authAuthenticationUrlPOSTWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param commonAuthenticationModelAuthRequestModel 
     */
    public authAuthenticationUrlPOST(commonAuthenticationModelAuthRequestModel: CommonAuthenticationModelAuthRequestModel, _options?: Configuration): Observable<HttpFile> {
        return this.authAuthenticationUrlPOSTWithHttpInfo(commonAuthenticationModelAuthRequestModel, _options).pipe(map((apiResponse: HttpInfo<HttpFile>) => apiResponse.data));
    }

    /**
     */
    public authGetAuthIdentityWithHttpInfo(_options?: Configuration): Observable<HttpInfo<HttpFile>> {
        const requestContextPromise = this.requestFactory.authGetAuthIdentity(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.authGetAuthIdentityWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public authGetAuthIdentity(_options?: Configuration): Observable<HttpFile> {
        return this.authGetAuthIdentityWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<HttpFile>) => apiResponse.data));
    }

    /**
     * @param forceRemove 
     */
    public authSignoutWithHttpInfo(forceRemove?: boolean, _options?: Configuration): Observable<HttpInfo<HttpFile>> {
        const requestContextPromise = this.requestFactory.authSignout(forceRemove, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.authSignoutWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param forceRemove 
     */
    public authSignout(forceRemove?: boolean, _options?: Configuration): Observable<HttpFile> {
        return this.authSignoutWithHttpInfo(forceRemove, _options).pipe(map((apiResponse: HttpInfo<HttpFile>) => apiResponse.data));
    }

}

import { BulkSearchApiRequestFactory, BulkSearchApiResponseProcessor} from "../apis/BulkSearchApi";
export class ObservableBulkSearchApi {
    private requestFactory: BulkSearchApiRequestFactory;
    private responseProcessor: BulkSearchApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: BulkSearchApiRequestFactory,
        responseProcessor?: BulkSearchApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new BulkSearchApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new BulkSearchApiResponseProcessor();
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public bulkSearchSearchWithHttpInfo(dSExplorerWebHostModelsBulkSearchRequestModel: Array<DSExplorerWebHostModelsBulkSearchRequestModel>, _options?: Configuration): Observable<HttpInfo<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.bulkSearchSearch(dSExplorerWebHostModelsBulkSearchRequestModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.bulkSearchSearchWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public bulkSearchSearch(dSExplorerWebHostModelsBulkSearchRequestModel: Array<DSExplorerWebHostModelsBulkSearchRequestModel>, _options?: Configuration): Observable<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.bulkSearchSearchWithHttpInfo(dSExplorerWebHostModelsBulkSearchRequestModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsBulkLinkSearchRequestModel 
     */
    public bulkSearchSearchLinksWithHttpInfo(dSExplorerWebHostModelsBulkLinkSearchRequestModel: DSExplorerWebHostModelsBulkLinkSearchRequestModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const requestContextPromise = this.requestFactory.bulkSearchSearchLinks(dSExplorerWebHostModelsBulkLinkSearchRequestModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.bulkSearchSearchLinksWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsBulkLinkSearchRequestModel 
     */
    public bulkSearchSearchLinks(dSExplorerWebHostModelsBulkLinkSearchRequestModel: DSExplorerWebHostModelsBulkLinkSearchRequestModel, _options?: Configuration): Observable<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        return this.bulkSearchSearchLinksWithHttpInfo(dSExplorerWebHostModelsBulkLinkSearchRequestModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBulkSearchRequestResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public bulkSearchStreamableBulkSearchWithHttpInfo(dSExplorerWebHostModelsBulkSearchRequestModel: DSExplorerWebHostModelsBulkSearchRequestModel, _options?: Configuration): Observable<HttpInfo<void>> {
        const requestContextPromise = this.requestFactory.bulkSearchStreamableBulkSearch(dSExplorerWebHostModelsBulkSearchRequestModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.bulkSearchStreamableBulkSearchWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsBulkSearchRequestModel 
     */
    public bulkSearchStreamableBulkSearch(dSExplorerWebHostModelsBulkSearchRequestModel: DSExplorerWebHostModelsBulkSearchRequestModel, _options?: Configuration): Observable<void> {
        return this.bulkSearchStreamableBulkSearchWithHttpInfo(dSExplorerWebHostModelsBulkSearchRequestModel, _options).pipe(map((apiResponse: HttpInfo<void>) => apiResponse.data));
    }

}

import { CompanyApiRequestFactory, CompanyApiResponseProcessor} from "../apis/CompanyApi";
export class ObservableCompanyApi {
    private requestFactory: CompanyApiRequestFactory;
    private responseProcessor: CompanyApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: CompanyApiRequestFactory,
        responseProcessor?: CompanyApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new CompanyApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new CompanyApiResponseProcessor();
    }

    /**
     * @param companyIdentifier 
     * @param contextEpoch 
     * @param partitionId 
     * @param softDeleted 
     */
    public companyGetCompanyWithHttpInfo(companyIdentifier: string, contextEpoch?: number, partitionId?: number, softDeleted?: boolean, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.companyGetCompany(companyIdentifier, contextEpoch, partitionId, softDeleted, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.companyGetCompanyWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param contextEpoch 
     * @param partitionId 
     * @param softDeleted 
     */
    public companyGetCompany(companyIdentifier: string, contextEpoch?: number, partitionId?: number, softDeleted?: boolean, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.companyGetCompanyWithHttpInfo(companyIdentifier, contextEpoch, partitionId, softDeleted, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param sku 
     */
    public companyReadCompanyLicenseWithHttpInfo(companyIdentifier: string, sku: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail>> {
        const requestContextPromise = this.requestFactory.companyReadCompanyLicense(companyIdentifier, sku, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.companyReadCompanyLicenseWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param sku 
     */
    public companyReadCompanyLicense(companyIdentifier: string, sku: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail> {
        return this.companyReadCompanyLicenseWithHttpInfo(companyIdentifier, sku, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractLicenseDetail>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     */
    public companyReadCompanyLicensesWithHttpInfo(companyIdentifier: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses>> {
        const requestContextPromise = this.requestFactory.companyReadCompanyLicenses(companyIdentifier, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.companyReadCompanyLicensesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     */
    public companyReadCompanyLicenses(companyIdentifier: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses> {
        return this.companyReadCompanyLicensesWithHttpInfo(companyIdentifier, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractCompanyLicenses>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     */
    public companyReadCompanyProvisioningStatusDetailWithHttpInfo(companyIdentifier: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail>> {
        const requestContextPromise = this.requestFactory.companyReadCompanyProvisioningStatusDetail(companyIdentifier, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.companyReadCompanyProvisioningStatusDetailWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     */
    public companyReadCompanyProvisioningStatusDetail(companyIdentifier: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail> {
        return this.companyReadCompanyProvisioningStatusDetailWithHttpInfo(companyIdentifier, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail>) => apiResponse.data));
    }

}

import { ConfigurationApiRequestFactory, ConfigurationApiResponseProcessor} from "../apis/ConfigurationApi";
export class ObservableConfigurationApi {
    private requestFactory: ConfigurationApiRequestFactory;
    private responseProcessor: ConfigurationApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ConfigurationApiRequestFactory,
        responseProcessor?: ConfigurationApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ConfigurationApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ConfigurationApiResponseProcessor();
    }

    /**
     * @param objectClasses 
     * @param properties 
     */
    public configurationExportSystemContextWithHttpInfo(objectClasses?: Array<MicrosoftOnlineDirectoryServicesDirectoryObjectClass>, properties?: Array<MicrosoftOnlineDirectoryServicesDirectoryProperty>, _options?: Configuration): Observable<HttpInfo<HttpFile>> {
        const requestContextPromise = this.requestFactory.configurationExportSystemContext(objectClasses, properties, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationExportSystemContextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param objectClasses 
     * @param properties 
     */
    public configurationExportSystemContext(objectClasses?: Array<MicrosoftOnlineDirectoryServicesDirectoryObjectClass>, properties?: Array<MicrosoftOnlineDirectoryServicesDirectoryProperty>, _options?: Configuration): Observable<HttpFile> {
        return this.configurationExportSystemContextWithHttpInfo(objectClasses, properties, _options).pipe(map((apiResponse: HttpInfo<HttpFile>) => apiResponse.data));
    }

    /**
     * @param partitionId 
     */
    public configurationGetAvailableSecondaryReplicaWithHttpInfo(partitionId: number, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.configurationGetAvailableSecondaryReplica(partitionId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetAvailableSecondaryReplicaWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param partitionId 
     */
    public configurationGetAvailableSecondaryReplica(partitionId: number, _options?: Configuration): Observable<string> {
        return this.configurationGetAvailableSecondaryReplicaWithHttpInfo(partitionId, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     */
    public configurationGetEnvironmentConfigurationWithHttpInfo(_options?: Configuration): Observable<HttpInfo<{ [key: string]: string; }>> {
        const requestContextPromise = this.requestFactory.configurationGetEnvironmentConfiguration(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetEnvironmentConfigurationWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public configurationGetEnvironmentConfiguration(_options?: Configuration): Observable<{ [key: string]: string; }> {
        return this.configurationGetEnvironmentConfigurationWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<{ [key: string]: string; }>) => apiResponse.data));
    }

    /**
     * @param policyType 
     */
    public configurationGetLegacyAuthZPolicyWithHttpInfo(policyType: MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray>> {
        const requestContextPromise = this.requestFactory.configurationGetLegacyAuthZPolicy(policyType, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetLegacyAuthZPolicyWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param policyType 
     */
    public configurationGetLegacyAuthZPolicy(policyType: MicrosoftOnlineDSExplorerContractLegacyAuthZPolicyType, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray> {
        return this.configurationGetLegacyAuthZPolicyWithHttpInfo(policyType, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray>) => apiResponse.data));
    }

    /**
     */
    public configurationGetPreAuthorizationLegacyAuthZPolicyWithHttpInfo(_options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray>> {
        const requestContextPromise = this.requestFactory.configurationGetPreAuthorizationLegacyAuthZPolicy(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetPreAuthorizationLegacyAuthZPolicyWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public configurationGetPreAuthorizationLegacyAuthZPolicy(_options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray> {
        return this.configurationGetPreAuthorizationLegacyAuthZPolicyWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsGenericTreeNodeItemModelArray>) => apiResponse.data));
    }

    /**
     * @param partitionId 
     */
    public configurationGetPrimaryReplicaServerNameWithHttpInfo(partitionId: number, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.configurationGetPrimaryReplicaServerName(partitionId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetPrimaryReplicaServerNameWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param partitionId 
     */
    public configurationGetPrimaryReplicaServerName(partitionId: number, _options?: Configuration): Observable<string> {
        return this.configurationGetPrimaryReplicaServerNameWithHttpInfo(partitionId, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param partNumberFilter 
     * @param servicePlanFilter 
     */
    public configurationGetProductCatalogWithHttpInfo(partNumberFilter?: string, servicePlanFilter?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray>> {
        const requestContextPromise = this.requestFactory.configurationGetProductCatalog(partNumberFilter, servicePlanFilter, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetProductCatalogWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param partNumberFilter 
     * @param servicePlanFilter 
     */
    public configurationGetProductCatalog(partNumberFilter?: string, servicePlanFilter?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray> {
        return this.configurationGetProductCatalogWithHttpInfo(partNumberFilter, servicePlanFilter, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfCommonModelsDirectoryObjectTreeNodeModelArray>) => apiResponse.data));
    }

    /**
     * @param directoryObjectClass 
     */
    public configurationGetSystemContextCacheObjectsWithHttpInfo(directoryObjectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.configurationGetSystemContextCacheObjects(directoryObjectClass, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetSystemContextCacheObjectsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param directoryObjectClass 
     */
    public configurationGetSystemContextCacheObjects(directoryObjectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.configurationGetSystemContextCacheObjectsWithHttpInfo(directoryObjectClass, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param getCachedCopy 
     */
    public configurationGetSystemSettingsWithHttpInfo(getCachedCopy?: boolean, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings>> {
        const requestContextPromise = this.requestFactory.configurationGetSystemSettings(getCachedCopy, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetSystemSettingsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param getCachedCopy 
     */
    public configurationGetSystemSettings(getCachedCopy?: boolean, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings> {
        return this.configurationGetSystemSettingsWithHttpInfo(getCachedCopy, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractSystemSettings>) => apiResponse.data));
    }

    /**
     */
    public configurationGetSystemTaskListWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask>>> {
        const requestContextPromise = this.requestFactory.configurationGetSystemTaskList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationGetSystemTaskListWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public configurationGetSystemTaskList(_options?: Configuration): Observable<Array<CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask>> {
        return this.configurationGetSystemTaskListWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<CommonModelsEnumOptionOfMicrosoftOnlineDirectoryServicesSystemTask>>) => apiResponse.data));
    }

    /**
     * @param servicePlanId 
     */
    public configurationReadServicePlanByServicePlanIdWithHttpInfo(servicePlanId: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.configurationReadServicePlanByServicePlanId(servicePlanId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationReadServicePlanByServicePlanIdWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param servicePlanId 
     */
    public configurationReadServicePlanByServicePlanId(servicePlanId: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.configurationReadServicePlanByServicePlanIdWithHttpInfo(servicePlanId, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param servicePlanName 
     */
    public configurationReadServicePlanByServicePlanNameWithHttpInfo(servicePlanName: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.configurationReadServicePlanByServicePlanName(servicePlanName, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationReadServicePlanByServicePlanNameWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param servicePlanName 
     */
    public configurationReadServicePlanByServicePlanName(servicePlanName: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.configurationReadServicePlanByServicePlanNameWithHttpInfo(servicePlanName, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     */
    public configurationReadServicePlansWithHttpInfo(_options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.configurationReadServicePlans(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationReadServicePlansWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public configurationReadServicePlans(_options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.configurationReadServicePlansWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param partNumber 
     */
    public configurationReadStockKeepingUnitByPartNumberWithHttpInfo(partNumber: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.configurationReadStockKeepingUnitByPartNumber(partNumber, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationReadStockKeepingUnitByPartNumberWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param partNumber 
     */
    public configurationReadStockKeepingUnitByPartNumber(partNumber: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.configurationReadStockKeepingUnitByPartNumberWithHttpInfo(partNumber, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param skuId 
     */
    public configurationReadStockKeepingUnitBySkuIdWithHttpInfo(skuId: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.configurationReadStockKeepingUnitBySkuId(skuId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationReadStockKeepingUnitBySkuIdWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param skuId 
     */
    public configurationReadStockKeepingUnitBySkuId(skuId: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.configurationReadStockKeepingUnitBySkuIdWithHttpInfo(skuId, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     */
    public configurationReadStockKeepingUnitsWithHttpInfo(_options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.configurationReadStockKeepingUnits(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.configurationReadStockKeepingUnitsWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public configurationReadStockKeepingUnits(_options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.configurationReadStockKeepingUnitsWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

}

import { DeviceApiRequestFactory, DeviceApiResponseProcessor} from "../apis/DeviceApi";
export class ObservableDeviceApi {
    private requestFactory: DeviceApiRequestFactory;
    private responseProcessor: DeviceApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: DeviceApiRequestFactory,
        responseProcessor?: DeviceApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new DeviceApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new DeviceApiResponseProcessor();
    }

    /**
     * @param companyIdentifier 
     * @param deviceId 
     */
    public deviceGetDeviceWithHttpInfo(companyIdentifier: string, deviceId: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.deviceGetDevice(companyIdentifier, deviceId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deviceGetDeviceWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param deviceId 
     */
    public deviceGetDevice(companyIdentifier: string, deviceId: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.deviceGetDeviceWithHttpInfo(companyIdentifier, deviceId, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

}

import { IndexStoreApiRequestFactory, IndexStoreApiResponseProcessor} from "../apis/IndexStoreApi";
export class ObservableIndexStoreApi {
    private requestFactory: IndexStoreApiRequestFactory;
    private responseProcessor: IndexStoreApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: IndexStoreApiRequestFactory,
        responseProcessor?: IndexStoreApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new IndexStoreApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new IndexStoreApiResponseProcessor();
    }

    /**
     * @param applicationId 
     */
    public indexStoreGetApplicationStubWithHttpInfo(applicationId: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.indexStoreGetApplicationStub(applicationId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.indexStoreGetApplicationStubWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param applicationId 
     */
    public indexStoreGetApplicationStub(applicationId: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.indexStoreGetApplicationStubWithHttpInfo(applicationId, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param issuerUri 
     */
    public indexStoreGetTrustedRealmStubWithHttpInfo(issuerUri?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.indexStoreGetTrustedRealmStub(issuerUri, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.indexStoreGetTrustedRealmStubWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param issuerUri 
     */
    public indexStoreGetTrustedRealmStub(issuerUri?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.indexStoreGetTrustedRealmStubWithHttpInfo(issuerUri, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public indexStoreSearchAltSecIdStoreWithHttpInfo(altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.indexStoreSearchAltSecIdStore(altSecIdType, altSecIdKey, altSecIdProvider, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.indexStoreSearchAltSecIdStoreWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public indexStoreSearchAltSecIdStore(altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.indexStoreSearchAltSecIdStoreWithHttpInfo(altSecIdType, altSecIdKey, altSecIdProvider, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

}

import { LinkStateApiRequestFactory, LinkStateApiResponseProcessor} from "../apis/LinkStateApi";
export class ObservableLinkStateApi {
    private requestFactory: LinkStateApiRequestFactory;
    private responseProcessor: LinkStateApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: LinkStateApiRequestFactory,
        responseProcessor?: LinkStateApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new LinkStateApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new LinkStateApiResponseProcessor();
    }

    /**
     * @param dSExplorerDataModelsLinkStateCollectionDataModel 
     */
    public linkStateAddLinkStateWithHttpInfo(dSExplorerDataModelsLinkStateCollectionDataModel: DSExplorerDataModelsLinkStateCollectionDataModel, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.linkStateAddLinkState(dSExplorerDataModelsLinkStateCollectionDataModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.linkStateAddLinkStateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerDataModelsLinkStateCollectionDataModel 
     */
    public linkStateAddLinkState(dSExplorerDataModelsLinkStateCollectionDataModel: DSExplorerDataModelsLinkStateCollectionDataModel, _options?: Configuration): Observable<string> {
        return this.linkStateAddLinkStateWithHttpInfo(dSExplorerDataModelsLinkStateCollectionDataModel, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param id 
     */
    public linkStateGetLinkStateWithHttpInfo(id: string, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel>> {
        const requestContextPromise = this.requestFactory.linkStateGetLinkState(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.linkStateGetLinkStateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param id 
     */
    public linkStateGetLinkState(id: string, _options?: Configuration): Observable<CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel> {
        return this.linkStateGetLinkStateWithHttpInfo(id, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsLinkStateCollectionDataModel>) => apiResponse.data));
    }

}

import { ObjectApiRequestFactory, ObjectApiResponseProcessor} from "../apis/ObjectApi";
export class ObservableObjectApi {
    private requestFactory: ObjectApiRequestFactory;
    private responseProcessor: ObjectApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ObjectApiRequestFactory,
        responseProcessor?: ObjectApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ObjectApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ObjectApiResponseProcessor();
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectDownloadObjectLinksFromCompanyWithHttpInfo(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Observable<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.objectDownloadObjectLinksFromCompany(companyIdentifier, objectId, directoryLinkType, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.objectDownloadObjectLinksFromCompanyWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectDownloadObjectLinksFromCompany(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Observable<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.objectDownloadObjectLinksFromCompanyWithHttpInfo(companyIdentifier, objectId, directoryLinkType, _options).pipe(map((apiResponse: HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetObjectWithHttpInfo(companyIdentifier: string, objectId: string, softDeleted?: boolean, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.objectGetObject(companyIdentifier, objectId, softDeleted, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.objectGetObjectWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetObject(companyIdentifier: string, objectId: string, softDeleted?: boolean, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.objectGetObjectWithHttpInfo(companyIdentifier, objectId, softDeleted, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetObjectFromSystemContextWithHttpInfo(objectId: string, softDeleted?: boolean, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.objectGetObjectFromSystemContext(objectId, softDeleted, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.objectGetObjectFromSystemContextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetObjectFromSystemContext(objectId: string, softDeleted?: boolean, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.objectGetObjectFromSystemContextWithHttpInfo(objectId, softDeleted, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectGetObjectLinksFromCompanyWithHttpInfo(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Observable<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.objectGetObjectLinksFromCompany(companyIdentifier, objectId, directoryLinkType, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.objectGetObjectLinksFromCompanyWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectGetObjectLinksFromCompany(companyIdentifier: string, objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Observable<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.objectGetObjectLinksFromCompanyWithHttpInfo(companyIdentifier, objectId, directoryLinkType, _options).pipe(map((apiResponse: HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectGetObjectLinksFromSytemContextWithHttpInfo(objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Observable<HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.objectGetObjectLinksFromSytemContext(objectId, directoryLinkType, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.objectGetObjectLinksFromSytemContextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param objectId 
     * @param directoryLinkType 
     */
    public objectGetObjectLinksFromSytemContext(objectId: string, directoryLinkType: CommonModelsDirectoryLinkType, _options?: Configuration): Observable<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.objectGetObjectLinksFromSytemContextWithHttpInfo(objectId, directoryLinkType, _options).pipe(map((apiResponse: HttpInfo<CommonContractsObjectLinksResponseOfSystemCollectionsGenericIDictionaryOfMicrosoftOnlineDirectoryServicesDirectoryLinkClassAndMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param replicaName 
     * @param objectClass 
     * @param contextId 
     * @param contextEpoch 
     * @param partitionId 
     * @param readOnly 
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetReplicationMetadataWithHttpInfo(replicaName: string, objectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, contextId: string, contextEpoch: number, partitionId: number, readOnly?: boolean, objectId?: string, softDeleted?: boolean, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.objectGetReplicationMetadata(replicaName, objectClass, contextId, contextEpoch, partitionId, readOnly, objectId, softDeleted, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.objectGetReplicationMetadataWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param replicaName 
     * @param objectClass 
     * @param contextId 
     * @param contextEpoch 
     * @param partitionId 
     * @param readOnly 
     * @param objectId 
     * @param softDeleted 
     */
    public objectGetReplicationMetadata(replicaName: string, objectClass: MicrosoftOnlineDirectoryServicesDirectoryObjectClass, contextId: string, contextEpoch: number, partitionId: number, readOnly?: boolean, objectId?: string, softDeleted?: boolean, _options?: Configuration): Observable<string> {
        return this.objectGetReplicationMetadataWithHttpInfo(replicaName, objectClass, contextId, contextEpoch, partitionId, readOnly, objectId, softDeleted, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

}

import { PartitionApiRequestFactory, PartitionApiResponseProcessor} from "../apis/PartitionApi";
export class ObservablePartitionApi {
    private requestFactory: PartitionApiRequestFactory;
    private responseProcessor: PartitionApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: PartitionApiRequestFactory,
        responseProcessor?: PartitionApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new PartitionApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new PartitionApiResponseProcessor();
    }

    /**
     * @param partitionId 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param contextIdsString 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public partitionGetCompaniesByPartitionWithHttpInfo(partitionId: number, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, contextIdsString?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: PartitionGetCompaniesByPartitionDirSyncStateFilterParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.partitionGetCompaniesByPartition(partitionId, authorizedServiceInstance, authorizedServiceInstancePrefix, contextIdsString, creationTimeAfterOrAt, creationTimeBeforeOrAt, dirSyncEnabledOnly, dirSyncStateFilter, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.partitionGetCompaniesByPartitionWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param partitionId 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param contextIdsString 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public partitionGetCompaniesByPartition(partitionId: number, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, contextIdsString?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: PartitionGetCompaniesByPartitionDirSyncStateFilterParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.partitionGetCompaniesByPartitionWithHttpInfo(partitionId, authorizedServiceInstance, authorizedServiceInstancePrefix, contextIdsString, creationTimeAfterOrAt, creationTimeBeforeOrAt, dirSyncEnabledOnly, dirSyncStateFilter, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     */
    public partitionGetPartitionDetailsWithHttpInfo(_options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray>> {
        const requestContextPromise = this.requestFactory.partitionGetPartitionDetails(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.partitionGetPartitionDetailsWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public partitionGetPartitionDetails(_options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray> {
        return this.partitionGetPartitionDetailsWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionDetailsArray>) => apiResponse.data));
    }

    /**
     * @param partitionId 
     * @param startedAtOrAfter 
     */
    public partitionGetPartitionFailoversWithHttpInfo(partitionId: number, startedAtOrAfter?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray>> {
        const requestContextPromise = this.requestFactory.partitionGetPartitionFailovers(partitionId, startedAtOrAfter, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.partitionGetPartitionFailoversWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param partitionId 
     * @param startedAtOrAfter 
     */
    public partitionGetPartitionFailovers(partitionId: number, startedAtOrAfter?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray> {
        return this.partitionGetPartitionFailoversWithHttpInfo(partitionId, startedAtOrAfter, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSPartitionFailoverDescriptionArray>) => apiResponse.data));
    }

    /**
     */
    public partitionGetPartitionIdsWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<number>>> {
        const requestContextPromise = this.requestFactory.partitionGetPartitionIds(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.partitionGetPartitionIdsWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public partitionGetPartitionIds(_options?: Configuration): Observable<Array<number>> {
        return this.partitionGetPartitionIdsWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<number>>) => apiResponse.data));
    }

}

import { PreferencesApiRequestFactory, PreferencesApiResponseProcessor} from "../apis/PreferencesApi";
export class ObservablePreferencesApi {
    private requestFactory: PreferencesApiRequestFactory;
    private responseProcessor: PreferencesApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: PreferencesApiRequestFactory,
        responseProcessor?: PreferencesApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new PreferencesApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new PreferencesApiResponseProcessor();
    }

    /**
     * @param dSExplorerDataModelsUserPreferencesDataModel 
     */
    public preferencesAddPreferencesWithHttpInfo(dSExplorerDataModelsUserPreferencesDataModel: DSExplorerDataModelsUserPreferencesDataModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel>> {
        const requestContextPromise = this.requestFactory.preferencesAddPreferences(dSExplorerDataModelsUserPreferencesDataModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.preferencesAddPreferencesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerDataModelsUserPreferencesDataModel 
     */
    public preferencesAddPreferences(dSExplorerDataModelsUserPreferencesDataModel: DSExplorerDataModelsUserPreferencesDataModel, _options?: Configuration): Observable<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel> {
        return this.preferencesAddPreferencesWithHttpInfo(dSExplorerDataModelsUserPreferencesDataModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel>) => apiResponse.data));
    }

    /**
     */
    public preferencesGetPreferencesWithHttpInfo(_options?: Configuration): Observable<HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel>> {
        const requestContextPromise = this.requestFactory.preferencesGetPreferences(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.preferencesGetPreferencesWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public preferencesGetPreferences(_options?: Configuration): Observable<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel> {
        return this.preferencesGetPreferencesWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicResponseOfDSExplorerDataModelsUserPreferencesDataModel>) => apiResponse.data));
    }

}

import { SearchApiRequestFactory, SearchApiResponseProcessor} from "../apis/SearchApi";
export class ObservableSearchApi {
    private requestFactory: SearchApiRequestFactory;
    private responseProcessor: SearchApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SearchApiRequestFactory,
        responseProcessor?: SearchApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SearchApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SearchApiResponseProcessor();
    }

    /**
     * @param companyIdentifier 
     * @param exchangeSingleAuthorityOnly 
     * @param proxyAddressFilterString 
     * @param sourceAnchorFilterString 
     * @param softDeleted 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchAddressListsWithHttpInfo(companyIdentifier?: string, exchangeSingleAuthorityOnly?: boolean, proxyAddressFilterString?: string, sourceAnchorFilterString?: string, softDeleted?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchAddressLists(companyIdentifier, exchangeSingleAuthorityOnly, proxyAddressFilterString, sourceAnchorFilterString, softDeleted, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchAddressListsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param exchangeSingleAuthorityOnly 
     * @param proxyAddressFilterString 
     * @param sourceAnchorFilterString 
     * @param softDeleted 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchAddressLists(companyIdentifier?: string, exchangeSingleAuthorityOnly?: boolean, proxyAddressFilterString?: string, sourceAnchorFilterString?: string, softDeleted?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchAddressListsWithHttpInfo(companyIdentifier, exchangeSingleAuthorityOnly, proxyAddressFilterString, sourceAnchorFilterString, softDeleted, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchApplicationStubsWithHttpInfo(companyIdentifier?: string, appIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchApplicationStubs(companyIdentifier, appIds, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchApplicationStubsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchApplicationStubs(companyIdentifier?: string, appIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchApplicationStubsWithHttpInfo(companyIdentifier, appIds, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param domainInUse 
     * @param availableToOtherTenants 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchApplicationsWithHttpInfo(companyIdentifier?: string, appIds?: string, domainInUse?: string, availableToOtherTenants?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchApplications(companyIdentifier, appIds, domainInUse, availableToOtherTenants, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchApplicationsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param appIds 
     * @param domainInUse 
     * @param availableToOtherTenants 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchApplications(companyIdentifier?: string, appIds?: string, domainInUse?: string, availableToOtherTenants?: boolean, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchApplicationsWithHttpInfo(companyIdentifier, appIds, domainInUse, availableToOtherTenants, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     */
    public searchBrowseObjectCountsWithHttpInfo(companyIdentifier?: string, _options?: Configuration): Observable<HttpInfo<Array<DSExplorerDataModelsBrowsedObjectSearchModel>>> {
        const requestContextPromise = this.requestFactory.searchBrowseObjectCounts(companyIdentifier, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchBrowseObjectCountsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     */
    public searchBrowseObjectCounts(companyIdentifier?: string, _options?: Configuration): Observable<Array<DSExplorerDataModelsBrowsedObjectSearchModel>> {
        return this.searchBrowseObjectCountsWithHttpInfo(companyIdentifier, _options).pipe(map((apiResponse: HttpInfo<Array<DSExplorerDataModelsBrowsedObjectSearchModel>>) => apiResponse.data));
    }

    /**
     * @param partitionId 
     * @param contextIds 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchCompaniesWithHttpInfo(partitionId?: number, contextIds?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchCompanies(partitionId, contextIds, creationTimeAfterOrAt, creationTimeBeforeOrAt, authorizedServiceInstance, authorizedServiceInstancePrefix, dirSyncEnabledOnly, dirSyncStateFilter, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchCompaniesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param partitionId 
     * @param contextIds 
     * @param creationTimeAfterOrAt 
     * @param creationTimeBeforeOrAt 
     * @param authorizedServiceInstance 
     * @param authorizedServiceInstancePrefix 
     * @param dirSyncEnabledOnly 
     * @param dirSyncStateFilter 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchCompanies(partitionId?: number, contextIds?: string, creationTimeAfterOrAt?: string, creationTimeBeforeOrAt?: string, authorizedServiceInstance?: string, authorizedServiceInstancePrefix?: string, dirSyncEnabledOnly?: boolean, dirSyncStateFilter?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchCompaniesWithHttpInfo(partitionId, contextIds, creationTimeAfterOrAt, creationTimeBeforeOrAt, authorizedServiceInstance, authorizedServiceInstancePrefix, dirSyncEnabledOnly, dirSyncStateFilter, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param targetContextId 
     * @param displayName 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchContractsWithHttpInfo(companyIdentifier?: string, targetContextId?: string, displayName?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchContracts(companyIdentifier, targetContextId, displayName, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchContractsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param targetContextId 
     * @param displayName 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchContracts(companyIdentifier?: string, targetContextId?: string, displayName?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchContractsWithHttpInfo(companyIdentifier, targetContextId, displayName, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param alternativeSecurityIdString 
     * @param delegationType 
     * @param userIdentifierString 
     * @param userIdentifierType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDelegationEntriesWithHttpInfo(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, alternativeSecurityIdString?: string, delegationType?: SearchDelegationEntriesDelegationTypeParameter, userIdentifierString?: string, userIdentifierType?: SearchDelegationEntriesUserIdentifierTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray>> {
        const requestContextPromise = this.requestFactory.searchDelegationEntries(companyIdentifier, sourceObjectId, targetObjectId, alternativeSecurityIdString, delegationType, userIdentifierString, userIdentifierType, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchDelegationEntriesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param alternativeSecurityIdString 
     * @param delegationType 
     * @param userIdentifierString 
     * @param userIdentifierType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDelegationEntries(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, alternativeSecurityIdString?: string, delegationType?: SearchDelegationEntriesDelegationTypeParameter, userIdentifierString?: string, userIdentifierType?: SearchDelegationEntriesUserIdentifierTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray> {
        return this.searchDelegationEntriesWithHttpInfo(companyIdentifier, sourceObjectId, targetObjectId, alternativeSecurityIdString, delegationType, userIdentifierString, userIdentifierType, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractBinaryDSReferenceSetArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryObjectClass 
     */
    public searchDeletedCompanyContextWithHttpInfo(companyIdentifier?: string, objectId?: string, directoryObjectClass?: CommonModelsCompanyContextObjectClasses, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchDeletedCompanyContext(companyIdentifier, objectId, directoryObjectClass, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchDeletedCompanyContextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param objectId 
     * @param directoryObjectClass 
     */
    public searchDeletedCompanyContext(companyIdentifier?: string, objectId?: string, directoryObjectClass?: CommonModelsCompanyContextObjectClasses, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchDeletedCompanyContextWithHttpInfo(companyIdentifier, objectId, directoryObjectClass, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param objectId 
     * @param directoryObjectClass 
     */
    public searchDeletedSystemContextWithHttpInfo(objectId?: string, directoryObjectClass?: CommonModelsSystemContextObjectClasses, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchDeletedSystemContext(objectId, directoryObjectClass, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchDeletedSystemContextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param objectId 
     * @param directoryObjectClass 
     */
    public searchDeletedSystemContext(objectId?: string, directoryObjectClass?: CommonModelsSystemContextObjectClasses, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchDeletedSystemContextWithHttpInfo(objectId, directoryObjectClass, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDevicesWithHttpInfo(companyIdentifier?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchDevices(companyIdentifier, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchDevicesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDevices(companyIdentifier?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchDevicesWithHttpInfo(companyIdentifier, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param propertyValueFilterString 
     * @param propertyNameFilterValue 
     * @param errorCategory 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDirSyncProvisioningErrorsWithHttpInfo(companyIdentifier?: string, propertyValueFilterString?: string, propertyNameFilterValue?: SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter, errorCategory?: SearchDirSyncProvisioningErrorsErrorCategoryParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchDirSyncProvisioningErrors(companyIdentifier, propertyValueFilterString, propertyNameFilterValue, errorCategory, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchDirSyncProvisioningErrorsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param propertyValueFilterString 
     * @param propertyNameFilterValue 
     * @param errorCategory 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchDirSyncProvisioningErrors(companyIdentifier?: string, propertyValueFilterString?: string, propertyNameFilterValue?: SearchDirSyncProvisioningErrorsPropertyNameFilterValueParameter, errorCategory?: SearchDirSyncProvisioningErrorsErrorCategoryParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchDirSyncProvisioningErrorsWithHttpInfo(companyIdentifier, propertyValueFilterString, propertyNameFilterValue, errorCategory, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param displayNameFilterString 
     * @param isIndirectGrants 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param sourceObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchEntitlementGrantsWithHttpInfo(companyIdentifier?: string, displayNameFilterString?: string, isIndirectGrants?: boolean, sourceObjectId?: string, targetObjectId?: string, sourceObjectClass?: SearchEntitlementGrantsSourceObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const requestContextPromise = this.requestFactory.searchEntitlementGrants(companyIdentifier, displayNameFilterString, isIndirectGrants, sourceObjectId, targetObjectId, sourceObjectClass, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchEntitlementGrantsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param displayNameFilterString 
     * @param isIndirectGrants 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param sourceObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchEntitlementGrants(companyIdentifier?: string, displayNameFilterString?: string, isIndirectGrants?: boolean, sourceObjectId?: string, targetObjectId?: string, sourceObjectClass?: SearchEntitlementGrantsSourceObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        return this.searchEntitlementGrantsWithHttpInfo(companyIdentifier, displayNameFilterString, isIndirectGrants, sourceObjectId, targetObjectId, sourceObjectClass, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchGroupLicensingErrorsWithHttpInfo(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const requestContextPromise = this.requestFactory.searchGroupLicensingErrors(companyIdentifier, sourceObjectId, targetObjectId, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchGroupLicensingErrorsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchGroupLicensingErrors(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        return this.searchGroupLicensingErrorsWithHttpInfo(companyIdentifier, sourceObjectId, targetObjectId, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param textFilter 
     * @param domainInUse 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchGroupsWithHttpInfo(companyIdentifier?: string, textFilter?: string, domainInUse?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchGroups(companyIdentifier, textFilter, domainInUse, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchGroupsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param textFilter 
     * @param domainInUse 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchGroups(companyIdentifier?: string, textFilter?: string, domainInUse?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchGroupsWithHttpInfo(companyIdentifier, textFilter, domainInUse, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param keyGroupIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchKeyGroupsWithHttpInfo(companyIdentifier?: string, keyGroupIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchKeyGroups(companyIdentifier, keyGroupIds, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchKeyGroupsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param keyGroupIds 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchKeyGroups(companyIdentifier?: string, keyGroupIds?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchKeyGroupsWithHttpInfo(companyIdentifier, keyGroupIds, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public searchObjectsWithHttpInfo(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchObjects(dSExplorerWebHostModelsPropertySearchRequestModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchObjectsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public searchObjects(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchObjectsWithHttpInfo(dSExplorerWebHostModelsPropertySearchRequestModel, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public searchPagedObjectsWithHttpInfo(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchPagedObjects(dSExplorerWebHostModelsPropertySearchRequestModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchPagedObjectsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsPropertySearchRequestModel 
     */
    public searchPagedObjects(dSExplorerWebHostModelsPropertySearchRequestModel: DSExplorerWebHostModelsPropertySearchRequestModel, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchPagedObjectsWithHttpInfo(dSExplorerWebHostModelsPropertySearchRequestModel, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param authorizationScopeType 
     * @param authorizationScopeId 
     * @param isSelfScope 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchRoleAssignmentsWithHttpInfo(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, authorizationScopeType?: SearchRoleAssignmentsAuthorizationScopeTypeParameter, authorizationScopeId?: string, isSelfScope?: boolean, targetObjectClass?: SearchRoleAssignmentsTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const requestContextPromise = this.requestFactory.searchRoleAssignments(companyIdentifier, sourceObjectId, targetObjectId, authorizationScopeType, authorizationScopeId, isSelfScope, targetObjectClass, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchRoleAssignmentsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param authorizationScopeType 
     * @param authorizationScopeId 
     * @param isSelfScope 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchRoleAssignments(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, authorizationScopeType?: SearchRoleAssignmentsAuthorizationScopeTypeParameter, authorizationScopeId?: string, isSelfScope?: boolean, targetObjectClass?: SearchRoleAssignmentsTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        return this.searchRoleAssignmentsWithHttpInfo(companyIdentifier, sourceObjectId, targetObjectId, authorizationScopeType, authorizationScopeId, isSelfScope, targetObjectClass, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param scopeId 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchScopedMembersWithHttpInfo(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, scopeId?: string, targetObjectClass?: SearchScopedMembersTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>> {
        const requestContextPromise = this.requestFactory.searchScopedMembers(companyIdentifier, sourceObjectId, targetObjectId, scopeId, targetObjectClass, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchScopedMembersWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param sourceObjectId 
     * @param targetObjectId 
     * @param scopeId 
     * @param targetObjectClass 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchScopedMembers(companyIdentifier?: string, sourceObjectId?: string, targetObjectId?: string, scopeId?: string, targetObjectClass?: SearchScopedMembersTargetObjectClassParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray> {
        return this.searchScopedMembersWithHttpInfo(companyIdentifier, sourceObjectId, targetObjectId, scopeId, targetObjectClass, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSReferenceSetArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param filterString 
     * @param filterType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchServicePrincipalsWithHttpInfo(companyIdentifier?: string, filterString?: string, filterType?: SearchServicePrincipalsFilterTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchServicePrincipals(companyIdentifier, filterString, filterType, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchServicePrincipalsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param filterString 
     * @param filterType 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchServicePrincipals(companyIdentifier?: string, filterString?: string, filterType?: SearchServicePrincipalsFilterTypeParameter, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchServicePrincipalsWithHttpInfo(companyIdentifier, filterString, filterType, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param skuIdToExclude 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchSubscriptionsWithHttpInfo(companyIdentifier?: string, skuIdToExclude?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchSubscriptions(companyIdentifier, skuIdToExclude, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchSubscriptionsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param skuIdToExclude 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchSubscriptions(companyIdentifier?: string, skuIdToExclude?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchSubscriptionsWithHttpInfo(companyIdentifier, skuIdToExclude, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param adminsOnly 
     * @param enabled 
     * @param softDeleted 
     * @param domainsInUse 
     * @param synchronizedOnly 
     * @param textFilter 
     * @param assignedLicenseUnsetOnly 
     * @param assignedLicenseFilter 
     * @param assignedPlanFilters 
     * @param usageLocation 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchUsersWithHttpInfo(companyIdentifier?: string, adminsOnly?: boolean, enabled?: boolean, softDeleted?: boolean, domainsInUse?: string, synchronizedOnly?: boolean, textFilter?: string, assignedLicenseUnsetOnly?: boolean, assignedLicenseFilter?: string, assignedPlanFilters?: string, usageLocation?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.searchUsers(companyIdentifier, adminsOnly, enabled, softDeleted, domainsInUse, synchronizedOnly, textFilter, assignedLicenseUnsetOnly, assignedLicenseFilter, assignedPlanFilters, usageLocation, pageSize, pageToken, replicaToken, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchUsersWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param adminsOnly 
     * @param enabled 
     * @param softDeleted 
     * @param domainsInUse 
     * @param synchronizedOnly 
     * @param textFilter 
     * @param assignedLicenseUnsetOnly 
     * @param assignedLicenseFilter 
     * @param assignedPlanFilters 
     * @param usageLocation 
     * @param pageSize 
     * @param pageToken 
     * @param replicaToken 
     */
    public searchUsers(companyIdentifier?: string, adminsOnly?: boolean, enabled?: boolean, softDeleted?: boolean, domainsInUse?: string, synchronizedOnly?: boolean, textFilter?: string, assignedLicenseUnsetOnly?: boolean, assignedLicenseFilter?: string, assignedPlanFilters?: string, usageLocation?: string, pageSize?: number, pageToken?: string, replicaToken?: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.searchUsersWithHttpInfo(companyIdentifier, adminsOnly, enabled, softDeleted, domainsInUse, synchronizedOnly, textFilter, assignedLicenseUnsetOnly, assignedLicenseFilter, assignedPlanFilters, usageLocation, pageSize, pageToken, replicaToken, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractPagedResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

}

import { ServiceApiRequestFactory, ServiceApiResponseProcessor} from "../apis/ServiceApi";
export class ObservableServiceApi {
    private requestFactory: ServiceApiRequestFactory;
    private responseProcessor: ServiceApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ServiceApiRequestFactory,
        responseProcessor?: ServiceApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ServiceApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ServiceApiResponseProcessor();
    }

    /**
     * @param serviceType 
     */
    public serviceGetServiceWithHttpInfo(serviceType: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.serviceGetService(serviceType, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.serviceGetServiceWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param serviceType 
     */
    public serviceGetService(serviceType: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.serviceGetServiceWithHttpInfo(serviceType, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param serviceInstanceName 
     * @param instance 
     */
    public serviceGetServiceInstanceWithHttpInfo(serviceInstanceName: string, instance: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.serviceGetServiceInstance(serviceInstanceName, instance, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.serviceGetServiceInstanceWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param serviceInstanceName 
     * @param instance 
     */
    public serviceGetServiceInstance(serviceInstanceName: string, instance: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.serviceGetServiceInstanceWithHttpInfo(serviceInstanceName, instance, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     */
    public serviceGetServiceInstancesWithHttpInfo(_options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.serviceGetServiceInstances(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.serviceGetServiceInstancesWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public serviceGetServiceInstances(_options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.serviceGetServiceInstancesWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

    /**
     */
    public serviceGetServicesWithHttpInfo(_options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>> {
        const requestContextPromise = this.requestFactory.serviceGetServices(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.serviceGetServicesWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public serviceGetServices(_options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray> {
        return this.serviceGetServicesWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObjectArray>) => apiResponse.data));
    }

}

import { ToolboxApiRequestFactory, ToolboxApiResponseProcessor} from "../apis/ToolboxApi";
export class ObservableToolboxApi {
    private requestFactory: ToolboxApiRequestFactory;
    private responseProcessor: ToolboxApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ToolboxApiRequestFactory,
        responseProcessor?: ToolboxApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ToolboxApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ToolboxApiResponseProcessor();
    }

    /**
     * @param dSExplorerWebHostModelsBase64Model 
     */
    public toolboxBase64ConverterWithHttpInfo(dSExplorerWebHostModelsBase64Model: DSExplorerWebHostModelsBase64Model, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const requestContextPromise = this.requestFactory.toolboxBase64Converter(dSExplorerWebHostModelsBase64Model, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.toolboxBase64ConverterWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsBase64Model 
     */
    public toolboxBase64Converter(dSExplorerWebHostModelsBase64Model: DSExplorerWebHostModelsBase64Model, _options?: Configuration): Observable<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        return this.toolboxBase64ConverterWithHttpInfo(dSExplorerWebHostModelsBase64Model, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsDateTimeModel 
     */
    public toolboxDateTimeConverterWithHttpInfo(dSExplorerWebHostModelsDateTimeModel: DSExplorerWebHostModelsDateTimeModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const requestContextPromise = this.requestFactory.toolboxDateTimeConverter(dSExplorerWebHostModelsDateTimeModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.toolboxDateTimeConverterWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsDateTimeModel 
     */
    public toolboxDateTimeConverter(dSExplorerWebHostModelsDateTimeModel: DSExplorerWebHostModelsDateTimeModel, _options?: Configuration): Observable<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        return this.toolboxDateTimeConverterWithHttpInfo(dSExplorerWebHostModelsDateTimeModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsGuidModel 
     */
    public toolboxGuidConverterWithHttpInfo(dSExplorerWebHostModelsGuidModel: DSExplorerWebHostModelsGuidModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const requestContextPromise = this.requestFactory.toolboxGuidConverter(dSExplorerWebHostModelsGuidModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.toolboxGuidConverterWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsGuidModel 
     */
    public toolboxGuidConverter(dSExplorerWebHostModelsGuidModel: DSExplorerWebHostModelsGuidModel, _options?: Configuration): Observable<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        return this.toolboxGuidConverterWithHttpInfo(dSExplorerWebHostModelsGuidModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsHexModel 
     */
    public toolboxHexConverterWithHttpInfo(dSExplorerWebHostModelsHexModel: DSExplorerWebHostModelsHexModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const requestContextPromise = this.requestFactory.toolboxHexConverter(dSExplorerWebHostModelsHexModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.toolboxHexConverterWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsHexModel 
     */
    public toolboxHexConverter(dSExplorerWebHostModelsHexModel: DSExplorerWebHostModelsHexModel, _options?: Configuration): Observable<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        return this.toolboxHexConverterWithHttpInfo(dSExplorerWebHostModelsHexModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsNetIdModel 
     */
    public toolboxNetIdConverterWithHttpInfo(dSExplorerWebHostModelsNetIdModel: DSExplorerWebHostModelsNetIdModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const requestContextPromise = this.requestFactory.toolboxNetIdConverter(dSExplorerWebHostModelsNetIdModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.toolboxNetIdConverterWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsNetIdModel 
     */
    public toolboxNetIdConverter(dSExplorerWebHostModelsNetIdModel: DSExplorerWebHostModelsNetIdModel, _options?: Configuration): Observable<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        return this.toolboxNetIdConverterWithHttpInfo(dSExplorerWebHostModelsNetIdModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>) => apiResponse.data));
    }

    /**
     * @param dSExplorerWebHostModelsSidModel 
     */
    public toolboxSidConverterWithHttpInfo(dSExplorerWebHostModelsSidModel: DSExplorerWebHostModelsSidModel, _options?: Configuration): Observable<HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>> {
        const requestContextPromise = this.requestFactory.toolboxSidConverter(dSExplorerWebHostModelsSidModel, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.toolboxSidConverterWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param dSExplorerWebHostModelsSidModel 
     */
    public toolboxSidConverter(dSExplorerWebHostModelsSidModel: DSExplorerWebHostModelsSidModel, _options?: Configuration): Observable<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString> {
        return this.toolboxSidConverterWithHttpInfo(dSExplorerWebHostModelsSidModel, _options).pipe(map((apiResponse: HttpInfo<CommonContractsBasicToolboxResponseOfSystemCollectionsGenericListOfSystemString>) => apiResponse.data));
    }

}

import { TrustedRealmApiRequestFactory, TrustedRealmApiResponseProcessor} from "../apis/TrustedRealmApi";
export class ObservableTrustedRealmApi {
    private requestFactory: TrustedRealmApiRequestFactory;
    private responseProcessor: TrustedRealmApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: TrustedRealmApiRequestFactory,
        responseProcessor?: TrustedRealmApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new TrustedRealmApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new TrustedRealmApiResponseProcessor();
    }

    /**
     * @param companyIdentifier 
     * @param issuerUri 
     */
    public trustedRealmGetTrustedRealmByIssuerUriWithHttpInfo(companyIdentifier: string, issuerUri: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.trustedRealmGetTrustedRealmByIssuerUri(companyIdentifier, issuerUri, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trustedRealmGetTrustedRealmByIssuerUriWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param issuerUri 
     */
    public trustedRealmGetTrustedRealmByIssuerUri(companyIdentifier: string, issuerUri: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.trustedRealmGetTrustedRealmByIssuerUriWithHttpInfo(companyIdentifier, issuerUri, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param stsName 
     */
    public trustedRealmGetTrustedRealmByNameWithHttpInfo(companyIdentifier: string, stsName: string, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.trustedRealmGetTrustedRealmByName(companyIdentifier, stsName, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trustedRealmGetTrustedRealmByNameWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param stsName 
     */
    public trustedRealmGetTrustedRealmByName(companyIdentifier: string, stsName: string, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.trustedRealmGetTrustedRealmByNameWithHttpInfo(companyIdentifier, stsName, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

}

import { UserApiRequestFactory, UserApiResponseProcessor} from "../apis/UserApi";
export class ObservableUserApi {
    private requestFactory: UserApiRequestFactory;
    private responseProcessor: UserApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: UserApiRequestFactory,
        responseProcessor?: UserApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new UserApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new UserApiResponseProcessor();
    }

    /**
     * @param companyIdentifier 
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param softDeleted 
     */
    public userGetUserByAltSecIdWithHttpInfo(companyIdentifier: string, altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, softDeleted?: boolean, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.userGetUserByAltSecId(companyIdentifier, altSecIdType, altSecIdKey, altSecIdProvider, softDeleted, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userGetUserByAltSecIdWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param altSecIdType 
     * @param altSecIdKey 
     * @param altSecIdProvider 
     * @param softDeleted 
     */
    public userGetUserByAltSecId(companyIdentifier: string, altSecIdType?: MicrosoftOnlineDSExplorerContractAltSecIdType, altSecIdKey?: string, altSecIdProvider?: string, softDeleted?: boolean, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.userGetUserByAltSecIdWithHttpInfo(companyIdentifier, altSecIdType, altSecIdKey, altSecIdProvider, softDeleted, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param userIdentifier 
     * @param softDeleted 
     */
    public userGetUserByIdentifierWithHttpInfo(companyIdentifier: string, userIdentifier: string, softDeleted?: boolean, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.userGetUserByIdentifier(companyIdentifier, userIdentifier, softDeleted, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userGetUserByIdentifierWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param userIdentifier 
     * @param softDeleted 
     */
    public userGetUserByIdentifier(companyIdentifier: string, userIdentifier: string, softDeleted?: boolean, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.userGetUserByIdentifierWithHttpInfo(companyIdentifier, userIdentifier, softDeleted, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param ticketValue 
     * @param softDeleted 
     */
    public userGetUserByInvitationTicketWithHttpInfo(companyIdentifier: string, ticketValue: string, softDeleted?: boolean, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>> {
        const requestContextPromise = this.requestFactory.userGetUserByInvitationTicket(companyIdentifier, ticketValue, softDeleted, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userGetUserByInvitationTicketWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param ticketValue 
     * @param softDeleted 
     */
    public userGetUserByInvitationTicket(companyIdentifier: string, ticketValue: string, softDeleted?: boolean, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject> {
        return this.userGetUserByInvitationTicketWithHttpInfo(companyIdentifier, ticketValue, softDeleted, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractDSObject>) => apiResponse.data));
    }

    /**
     * @param companyIdentifier 
     * @param userObjectId 
     * @param isSoftDeleted 
     */
    public userGetUserProvisioningStatusDetailWithHttpInfo(companyIdentifier: string, userObjectId: string, isSoftDeleted?: boolean, _options?: Configuration): Observable<HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail>> {
        const requestContextPromise = this.requestFactory.userGetUserProvisioningStatusDetail(companyIdentifier, userObjectId, isSoftDeleted, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userGetUserProvisioningStatusDetailWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param companyIdentifier 
     * @param userObjectId 
     * @param isSoftDeleted 
     */
    public userGetUserProvisioningStatusDetail(companyIdentifier: string, userObjectId: string, isSoftDeleted?: boolean, _options?: Configuration): Observable<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail> {
        return this.userGetUserProvisioningStatusDetailWithHttpInfo(companyIdentifier, userObjectId, isSoftDeleted, _options).pipe(map((apiResponse: HttpInfo<MicrosoftOnlineDSExplorerContractResponseOfMicrosoftOnlineDSExplorerContractProvisioningStatusDetail>) => apiResponse.data));
    }

}
