import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

export interface TrustedRealmIndexSearchForm {
  issuerUri: string,
}

const TrustedRealmIndexSB: FunctionComponent<ObjectTypeProps<TrustedRealmIndexSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<TrustedRealmIndexSearchForm>) => {
  const [searchForm, setSearchForm] = useState<TrustedRealmIndexSearchForm>({
    issuerUri: '',
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          IssuerUri of TrustedRealm
          <Popup
            flowing
            position="bottom left"
            content="Enter the IssuerUri of the TrustedRealm you are looking for"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.issuerUri}
                className="last-form-item"
                fluid
                placeholder="Enter the IssuerUri of the TrustedRealm"
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, issuerUri: value })}
              />
                    )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ issuerUri: '' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.issuerUri === ''}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default TrustedRealmIndexSB;
