/* eslint-disable no-bitwise */
import _ from 'lodash';
import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import './ContextMenu.scss';
import { useSelector } from 'react-redux';
import { State } from 'app/reducers/state';
import { PinnedTabDetails, TabType } from 'app/typings';

export enum ContextOptions {
  none = 0,
  pinTab = 1,
  closeTab = 2,
  closeAllTabs = 4,
  closeAllRight = 8,
  closeAllTabExceptThis = 16,
  all = pinTab | closeTab | closeAllTabs | closeAllRight | closeAllTabExceptThis,
  allButClose = all & ~closeTab & ~closeAllTabs,
  allButPin = all & ~pinTab,
}

interface TabManagerContextMenuProps {
  e?: React.MouseEvent<HTMLElement, MouseEvent>,
  tab: string,
  parentTab?: string,
  tabType: TabType,
  x: number,
  y: number,
  showMenu: boolean,
  closeMenu: () => void,
  contextOptions?: ContextOptions,
  dispatchCloseTab: (e: React.MouseEvent | undefined, id: string | undefined, type: TabType, parentTab?: string) => void,
  dispatchPinTab?: (e: React.MouseEvent | undefined, id: string | undefined, type: TabType, parentTab?: string) => void,
}

const TabManagerContextMenu = ({
  tab,
  parentTab,
  tabType,
  e,
  x,
  y,
  showMenu,
  contextOptions = ContextOptions.none,
  closeMenu,
  dispatchCloseTab,
  dispatchPinTab,
}: TabManagerContextMenuProps) => {
  const style = () => ({
    top: y,
    left: x,
    position: 'absolute',
    display: showMenu ? 'flex' : 'none',
    width: 'auto',
    minHeight: 'unset',
  });
  const tabState = useSelector((state: State) => state.tabs);

  const pinTab = () => {
    if (typeof dispatchPinTab === 'function') {
      dispatchPinTab(e, tab, tabType, parentTab);
    }

    closeMenu();
  };

  const closePinnedTabs = (tabs: PinnedTabDetails[]) => {
    if (!tabState) {
      return;
    }

    const uniquelyIndexedTabs = _.uniqWith(tabs, (tabx, taby) => tabx.lastIndex === taby.lastIndex);
    if (uniquelyIndexedTabs.length === 1) {
      // Everyone has the same index -- We'll process these in reverse to get
      // them unpinned as added
      tabs.reverse().forEach((t) => {
        dispatchCloseTab(e, t.id, tabType, t.parentTab);
      });
    } else {
      _.orderBy(tabs, ['lastIndex'], ['asc']).forEach((t) => {
        dispatchCloseTab(e, t.id, tabType, t.parentTab);
      });
    }
  };

  const closeAllTab = () => {
    if (!tabState) {
      closeMenu();
      return;
    }

    if (tab === 'pinned-tab-container') {
      // Special Logic for Pinned Tab Container
      closePinnedTabs(tabState.pinned as PinnedTabDetails[]);
      closeMenu();
      return;
    }

    if (tab && parentTab) {
      const foundParent = tabState.findTab(parentTab);
      if (foundParent) {
        const foundChild = _.find(foundParent.children.tabs, ['id', tab]);
        if (foundChild) {
          // We are assumed to be closing the children of the currently active "Parent"
          foundParent.children.tabs.forEach((t) => {
            if (t.allowClose) {
              dispatchCloseTab(undefined, t.id, tabType, parentTab);
            }
          });
        }
      }
    }

    if (tab && parentTab === undefined) {
      const allTabs = tabState.tabStore[`${tabType}`].tabs;
      allTabs.forEach((t) => {
        dispatchCloseTab(undefined, t.id, tabType, parentTab);
      });
    }

    if (_.find(tabState.pinned, ['id', tab])) {
      closePinnedTabs(tabState.pinned as PinnedTabDetails[]);
    }

    closeMenu();
  };

  const closeTab = () => {
    if (!tabState) {
      closeMenu();
      return;
    }

    if (tab && parentTab) {
      const foundParent = tabState.findTab(parentTab);
      if (foundParent) {
        const foundChild = _.find(foundParent.children.tabs, ['id', tab]);
        if (foundChild && foundChild.allowClose) {
          dispatchCloseTab(e, tab, tabType, parentTab);
        }
      }
    }

    if (tab && parentTab === undefined) {
      const foundParent = _.find(tabState.tabStore[`${tabType}`].tabs, ['id', tab]);
      if (foundParent && foundParent.allowClose) {
        dispatchCloseTab(e, tab, tabType, undefined);
      }
    }

    if (_.find(tabState.pinned, ['id', tab])) {
      dispatchCloseTab(e, tab, tabType, parentTab);
    }

    closeMenu();
  };

  const closeAllTabsRight = () => {
    if (!tabState) {
      closeMenu();
      return;
    }

    if (tab && parentTab) {
      const foundParent = tabState.findTab(parentTab);
      if (foundParent) {
        const foundIndex = _.findIndex(foundParent.children.tabs, ['id', tab]);
        const tabsToClose = _.takeRight(foundParent.children.tabs, foundParent.children.tabs.length - foundIndex - 1);
        tabsToClose.forEach((t) => {
          if (t.allowClose) {
            dispatchCloseTab(undefined, t.id, tabType, parentTab);
          }
        });
      }
    }

    if (tab && parentTab === undefined) {
      const foundIndex = _.findIndex(tabState.tabStore[`${tabType}`].tabs, ['id', tab]);

      if (foundIndex > -1) {
        const tabsToClose = _.takeRight(tabState.tabStore[`${tabType}`].tabs, tabState.tabStore[`${tabType}`].tabs.length - foundIndex - 1);
        tabsToClose.forEach((t) => {
          if (t.allowClose) {
            dispatchCloseTab(undefined, t.id, tabType, undefined);
          }
        });
      }
    }

    const foundPinnedIndex = _.findIndex(tabState.pinned, ['id', tab]);
    if (foundPinnedIndex > -1 && tabState.pinned) {
      const tabsToClose = _.takeRight(tabState.pinned, tabState.pinned.length - foundPinnedIndex - 1);
      closePinnedTabs(tabsToClose);
    }

    closeMenu();
  };

  const closeAllTabExceptThis = () => {
    if (!tabState) {
      closeMenu();
      return;
    }

    if (tab && parentTab) {
      const foundParent = tabState.findTab(parentTab);
      if (foundParent) {
        const foundChild = _.find(foundParent.children.tabs, ['id', tab]);
        if (foundChild) {
          // We are assumed to be closing the children of the currently active "Parent"
          foundParent.children.tabs.forEach((t) => {
            if (t.allowClose && t.id !== tab) {
              dispatchCloseTab(undefined, t.id, tabType, parentTab);
            }
          });
        }
      }
    }

    if (tab && parentTab === undefined) {
      const allTabs = tabState.tabStore[`${tabType}`].tabs;
      allTabs.forEach((t) => {
        if (t.id !== tab) {
          dispatchCloseTab(undefined, t.id, tabType, parentTab);
        }
      });
    }

    if (tabState.pinned && _.find(tabState.pinned, ['id', tab])) {
      const tabsToClose = _.filter(tabState.pinned, (n) => n.id !== tab);
      closePinnedTabs(tabsToClose);
    }

    closeMenu();
  };

  const allowClose = (contextOptions & ContextOptions.closeTab) === ContextOptions.closeTab;
  const allowPin = (contextOptions & ContextOptions.pinTab) === ContextOptions.pinTab;
  const allowCloseAll = (contextOptions & ContextOptions.closeAllTabs) === ContextOptions.closeAllTabs;
  const allowCloseAllRight = (contextOptions & ContextOptions.closeAllRight) === ContextOptions.closeAllRight;
  const allowClooseAllButThis = (contextOptions & ContextOptions.closeAllTabExceptThis) === ContextOptions.closeAllTabExceptThis;
  const showBorder = !allowPin && (allowCloseAll || allowCloseAllRight || allowClooseAllButThis);

  return (
    <Menu vertical style={style()} className="tabManager context-menu">
      { allowClose ? (
        <Menu.Item className={`context-menu-item ${showBorder ? 'bordered-bottom' : 'no-border'}`} onClick={() => closeTab()}>
          <div className="icon-div"><Icon name="close" /></div>
          <div className="menu-item-text">Close</div>
        </Menu.Item>
      ) : null}
      {allowPin ? (
        <Menu.Item className={`context-menu-item ${!showBorder ? 'bordered-bottom' : 'no-border'}`} onClick={() => pinTab()}>
          <div className="icon-div"><Icon name="map pin" /></div>
          <div className="menu-item-text">Pin</div>
        </Menu.Item>
      ) : null}
      { allowCloseAll ? (
        <Menu.Item className="context-menu-item" onClick={() => closeAllTab()}>
          <div className="icon-div"><Icon name="window close outline" /></div>
          <div className="menu-item-text">Close All</div>
        </Menu.Item>
      ) : null}
      { allowCloseAllRight ? (
        <Menu.Item className="context-menu-item" onClick={() => closeAllTabsRight()}>
          <div className="icon-div"><Icon name="caret square right outline" /></div>
          <div className="menu-item-text">Close All to Right</div>
        </Menu.Item>
      ) : null}
      { allowClooseAllButThis ? (
        <Menu.Item className="context-menu-item" onClick={() => closeAllTabExceptThis()}>
          <div className="icon-div"><Icon name="window close" /></div>
          <div className="menu-item-text">Close All Tabs but this</div>
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

TabManagerContextMenu.defaultProps = {
  e: undefined,
  parentTab: undefined,
  dispatchPinTab: undefined,
  contextOptions: ContextOptions.none,
};

export default TabManagerContextMenu;
