import './ThemePreview.scss';

import React, { FunctionComponent } from 'react';

interface ThemePreviewProps {
  theme: string,
}

const ThemePreview: FunctionComponent<ThemePreviewProps> = ({
  theme,
}: ThemePreviewProps) => {
  function create(themeName : string) {
    return (
      <div className={`${themeName}-preview`}>
        <div className="themePreview">
          <div className="previewToolbar" />
          <div className="previewSidebar" />
          <div className="previewContent">
            <div className="previewTable">
              <div className="previewContentTop" />
              <div className="previewContentFirstRow" />
              <div className="previewContentSecondRow" />
              <div className="previewContentFirstRow" />
              <div className="previewContentSecondRow" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // We need to create two previews for "system-default", and will hide half of each.
  if (theme.toLowerCase() === 'system default') {
    return (
      <div className="themePreview system-default-preview">
        { create('light') }
        { create('dark') }
      </div>
    );
  }

  return create(theme);
};

export default ThemePreview;
