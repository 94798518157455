import * as companyTypes from 'app/actions/companyTypes';
import * as tabTypes from 'app/actions/tabTypes';
import { ObjectTypeComponentKeys } from 'app/components/SidebarContent/CompanySidebar/CompanySidebarOptions';
import { GenericAction } from 'app/typings';

export type GetCompanyByNameParams = Record<string, unknown> & {
  companyIdentifier: string,
  softDeleted?: boolean,
  contextEpoch?: number,
  partitionId?: number,
};
export type GetCompanyByNameAction = GetCompanyByNameParams & {
  refreshTab: boolean,
};

export type GetSingleObjectParams = Record<string, unknown> & {
  parentTab?: string,
  contextId: string,
  objectId: string,
  softDeleted?: boolean,
};

export type GetSingleObjectAction = GenericAction<GetSingleObjectParams> & {
  parentTab?: string | null,
  refreshTab: boolean
};

export type GetKeyGroupParams = Record<string, unknown> & {
  contextId: string,
  objectId: string,
};

export type GetSingleLicenseParams = Record<string, unknown> & {
  parentTab?: string,
  companyIdentifier: string,
  skuId: string,
  licenseName?: string,
};

export type GetSingleObjectLinksParams = Record<string, unknown> & {
  companyIdentifier?: string,
  objectId: string,
  directoryLinkType: number
};

export type DownloadCompanyObjectLinks = Record<string, unknown> & {
  companyIdentifier: string,
  objectId: string,
  directoryLinkType: number
};

export type GetApplicationPermissionParams = Record<string, unknown> & {
  companyIdentifier: string,
  appId: string
};

export type GetReplicationMetaDataParams = {
  replicaName: string,
  contextId: string,
  objectClass: number,
  contextEpoch: number,
  partitionId: number,
  objectId: string
};

export type GetKeyGroupReferenceParams = Record<string, unknown> & {
  objectId: string,
  keyGroupId: string
  contextId: string
};

export function getCompanyByName({ ...params }: GetCompanyByNameParams, refreshTab?: boolean) {
  return {
    type: companyTypes.GET_COMPANY_BY_NAME,
    data: { ...params },
    refreshTab,
    metadata: {
      sidebarObjectType: '',
      showSearchResultsTab: false,
    },
  };
}

export function removeCompanyTab(index: number, contextId: string) {
  return {
    type: companyTypes.REMOVE_COMPANY_TAB,
    index,
    contextId,
  };
}

export function resetError() {
  return {
    type: companyTypes.RESET_ERROR,
  };
}

export function downloadCompLinks({ ...params }: DownloadCompanyObjectLinks) {
  return {
    type: companyTypes.DOWNLOAD_COMPANY_LINKS,
    ...params,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateSearchCompanies(params: any, sidebarObjectType: ObjectTypeComponentKeys, parentTab?: string | null) {
  return {
    type: tabTypes.SEARCH_TAB_REQUESTED,
    data: { ...params },
    metadata: {
      sidebarObjectType,
    },
    parentTab,
  };
}

export function getSingleObject({ ...params }: GetSingleObjectParams, parentTab?: string | null) {
  return {
    type: companyTypes.GET_SINGLE_OBJECT,
    data: { ...params },
    parentTab,
  };
}

export function openKeyGroupObject({ ...params }: GetKeyGroupParams) {
  return {
    type: companyTypes.OPEN_KEY_GROUP_OBJECT,
    ...params,
  };
}

export function getSingleLicense({ ...params }: GetSingleLicenseParams, licenseName: string, parentTab?: string | null) {
  return {
    type: companyTypes.GET_SINGLE_LICENSE,
    data: { ...params },
    licenseName,
    parentTab,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function displayPropertyBag(propertyBag: any, tabTitle: string, parentTab?: string | null) {
  return {
    type: companyTypes.DISPLAY_PROPERTY_BAG_COMP,
    propertyBag,
    parentTab,
    tabTitle,
  };
}

export function findServicePrincipalByAppId(appId: string, contextId: string, tabId: string, parentTab: string) {
  return {
    type: companyTypes.FIND_SP_BY_APPID,
    appId,
    contextId,
    tabId,
    parentTab,
  };
}

export function getAppPermissions(companyIdentifier: string, appId: string) {
  return {
    type: companyTypes.GET_APP_PERMISSIONS,
    companyIdentifier,
    appId,
  };
}

export function removeAppPermissionFromState(appId: string) {
  return {
    type: companyTypes.REMOVE_APP_PERMISSIONS_FROM_STATE,
    appId,
  };
}

export function getReplicationMetaData({ ...params }: GetReplicationMetaDataParams) {
  return {
    type: companyTypes.GET_REPLICATION_METADATA,
    ...params,
  };
}

export function removeReplMetadataFromState(objectId: string) {
  return {
    type: companyTypes.REMOVE_REPLICATION_METADATA_FROM_STATE,
    objectId,
  };
}

export function getKeyGroupReference(objectId: string, keyGroupId: string, contextId: string) {
  return {
    type: companyTypes.GET_KEY_GROUP_REFERENCE,
    objectId,
    keyGroupId,
    contextId,
  };
}

export function removeKeyGroupReference(objectId: string) {
  return {
    type: companyTypes.REMOVE_KEY_GROUP_REFERENCE,
    objectId,
  };
}
