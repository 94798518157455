import React, { FunctionComponent } from 'react';
import { List } from 'semantic-ui-react';
import { app } from 'app/store';

interface AppVersionProps {
  appVersion?: string,
  buildDate?: string,
}

const AppVersion: FunctionComponent<AppVersionProps> = ({
  appVersion, buildDate,
}: AppVersionProps) => (
  <>
    <List>
      <List.Item>{`Version: ${appVersion}`}</List.Item>
      <List.Item>{`Build Date: ${buildDate}`}</List.Item>
    </List>
    <br />

    Check out our
    {' '}
    <a href={`${app.config.getServer()}/swagger`} target="_blank" rel="noreferrer">Swagger Endpoint</a>
    {' '}
    for more ways to use DSExplorer!
  </>
);

AppVersion.defaultProps = {
  appVersion: 'N/A',
  buildDate: 'N/A',
};

export default AppVersion;
