import './Navbar.scss';

import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { State } from 'app/reducers/state';
import {
  Button, Dropdown, Icon, Menu, Popup,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { AuthState } from 'app/reducers/auth';
import { isSidebarDisabled } from 'app/helpers/helpers';
import { TabState } from 'app/reducers/tabs';
import { logout } from 'app/actions/authActions';
import Clock from 'app/components/Navbar/Clock';
import {
  addTab,
  activateTab,
} from 'app/actions/tabActions';
import { app } from 'app/store';
import { getPowerShellModuleUrl } from 'app/constants/config';
import _ from 'lodash';
import { StatusMessageDataModel } from 'app/helpers/types';
import { TabHistoryItem } from 'app/typings';
import { colorSeverities } from '../Announcements/Announcements';

/* eslint-disable */
type NavbarProps = {
  toggleSidebar: () => void,
  sideBarVisible: boolean,
  showClock: boolean,
  userDropdown: boolean,
  infoOnClick: () => void,
  helpOnClick: () => void,
  tokenInfoOnClick: () => void,
  tokenViewerOnClick: () => void,
  diagnosticsOnClick: () => void,
  deepLinkOnClick: () => void,
  preferencesOnClick: () => void,
  announcementsOnClick: () => void,
  startTourOnClick: () => void,
  announcements: StatusMessageDataModel[],
  tabs?: TabState,
  auth?: AuthState,
  history?: TabHistoryItem[],
  addTab: typeof addTab,
  push: typeof push,
  logout: typeof logout,
  activateTab: typeof activateTab
};

class Navbar extends Component<NavbarProps, any> {
  constructor(props: NavbarProps) {
    super(props);
    this.state = { 
      activeItem: 'Company',
      dropdownOpen: false,
      animationActive: true
    };
    setTimeout(() => {
      this.setState({animationActive: false});
    }, 8000);
  }

  componentWillReceiveProps(nextProps: { userDropdown: boolean; }) {
    if (nextProps.userDropdown !== this.state.userDropdown) {
      this.setState({ dropdownOpen: nextProps.userDropdown });
    }
  }

  onHistoryClick(item: TabHistoryItem) {
    const { push: propsPush, addTab: propsAddTab, activateTab: propsActivateTab } = this.props;

    if (item.metadata.routePath) {
      propsPush(item.metadata.routePath);
    }

    propsAddTab(
      item.name,
      item.tabType,
      item.component,
      item.routePath,
      true,
      item.id,
      item.refreshed,
      undefined,
      item.allowClose,
      item.metadata,
      item.children.tabs,
    );

    // Activate Tab
    propsActivateTab(
      item.id,
      item.tabType,
      undefined, // parent tab
    );
  }

  buildHistoryList() {
    const now = moment();
    const { history } = this.props;

    return (
      <Dropdown.Menu>
        <Dropdown.Header icon="history" content="Recently Closed Tabs" />
        <Dropdown.Divider />
        {history?.map((h) => (
          <Dropdown.Item
            key={h.id}
            content={(
              <div>
                {h.name}
                <div className="description">{`${moment.duration(h.closedAt.diff(now)).humanize(true)}`}</div>
              </div>
            )}
            onClick={() => this.onHistoryClick(h)}
          />
        ))}
      </Dropdown.Menu>
    );
  }

  getUnackedAnnouncements() {
    const { announcements } = this.props;
    return  announcements.filter(announcement => announcement.hasBeenAcknowledged === false);
  }

  announcementIcon() {
    return this.getUnackedAnnouncements().length > 0 ? 'alarm' : 'alarm mute';
  }

  announcementIconColor() {
    const { announcements } = this.props;
    
    if (this.getUnackedAnnouncements().length > 0) {
      const highestSevAnnouncement = _.maxBy(announcements, 'messageType');
      return colorSeverities[highestSevAnnouncement?.messageType as number];
    }
    
    return undefined;
  }

  showAnnouncementsIcon(): boolean {
    const { announcements } = this.props;
    return announcements.length > 0;
  }

  render() {
    const { activeItem, dropdownOpen, animationActive } = this.state;
    const unackedAnnouncements = this.getUnackedAnnouncements();
    const {
      toggleSidebar,
      sideBarVisible,
      showClock,
      auth,
      tabs,
      history,
      infoOnClick,
      tokenInfoOnClick,
      tokenViewerOnClick,
      diagnosticsOnClick,
      deepLinkOnClick,
      helpOnClick,
      preferencesOnClick,
      announcementsOnClick,
      startTourOnClick,
      logout: logoutOnClick,
    } = this.props;

    return (
      <div className="Navbar">
        <Menu color="blue" inverted stackable>
          <Menu.Item header>DSExplorer</Menu.Item>

          <Menu.Item
            name="Company"
            as={Link}
            to="/company"
            active={activeItem === 'Company'}
            onClick={() => this.setState({ activeItem: 'Company' })}
            content="Company"
          />

          <Menu.Item
            name="System"
            as={Link}
            to="/system"
            active={activeItem === 'System'}
            onClick={() => this.setState({ activeItem: 'System' })}
            content="System"
          />

          <Menu.Item
            name="Index"
            as={Link}
            to="/index"
            active={activeItem === 'Index'}
            onClick={() => this.setState({ activeItem: 'Index' })}
            content="Index"
          />

          <Menu.Item
            name="Partition"
            as={Link}
            to="/partition"
            active={activeItem === 'Partition'}
            onClick={() => this.setState({ activeItem: 'Partition' })}
            content="Partition"
          />

          <Menu.Item
            name="Toolbox"
            as={Link}
            to="/toolbox"
            active={activeItem === 'Toolbox'}
            onClick={() => this.setState({ activeItem: 'Toolbox' })}
            content="Toolbox"
          />

          {
            auth?.UserIdentity?.IsPlatformServiceAdministrator
              ? (
                <Dropdown text="Admin" className="link item">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="bullhorn"
                      text="Announcements Management"
                      as={Link}
                      to="/announcementManagement"
                      onClick={() => this.setState({ activeItem: 'Admin' })}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              ) : null
          }

          <Dropdown text="History" className="link item" disabled={history?.length === 0}>
            {this.buildHistoryList()}
          </Dropdown>

          <Menu.Menu position="right">
              <Menu.Item id="tour-start">
                <Button as='a' 
                        color="blue" 
                        id={animationActive ? "take-a-tour" : ''} 
                        onClick={() => { this.setState({ activeItem: 'Company' }); startTourOnClick()}}>
                  <Icon name="binoculars" /> Take a Tour!
                </Button>
              </Menu.Item>
            {showClock
              ? (
                <Menu.Item>
                  <Clock />
                </Menu.Item>
              ) : <></>}
            <Menu.Item>
              {
                this.showAnnouncementsIcon()
                  ? (
                    <Popup
                      content={
                        unackedAnnouncements.length > 0 ?
                        unackedAnnouncements.some(a => a.messageType === 2) ? `Important Announcements` : `${unackedAnnouncements.length} unacknowledged Announcements` :
                        'Announcements'}
                      position="bottom center"
                      open={unackedAnnouncements.some(a => a.messageType === 2) ? true : undefined}
                      trigger={(
                        <Button color="blue" icon onClick={() => announcementsOnClick()}>
                          <Icon color={this.announcementIconColor()} fitted name={this.announcementIcon()} />
                        </Button>
                      )}
                    />
                  ) : null
              }

              <Popup
                content="Toggle Sidebar"
                position="bottom center"
                trigger={(
                  // eslint-disable-next-line no-restricted-globals
                  <Button disabled={isSidebarDisabled(location.pathname)} color="blue" icon onClick={() => toggleSidebar()}>
                    <Icon fitted name={sideBarVisible ? 'toggle on' : 'toggle off'} />
                  </Button>
                )}
              />
              <Popup
                content="Download PS Module"
                position="bottom center"
                trigger={(
                  <Button as='a' href={getPowerShellModuleUrl()} color="blue" icon>
                    <Icon fitted name="terminal" />
                  </Button>
                )}
              />
              <Popup
                content="User Preferences"
                position="bottom center"
                trigger={(
                  <Button color="blue" icon id="user-preferences-tour" onClick={() => preferencesOnClick()}>
                    <Icon fitted name="settings" />
                  </Button>
                )}
              />

              <Popup
                content="Short Link"
                position="bottom center"
                trigger={(
                  <Button
                    id="short-link-tour"
                    disabled={tabs?.allTabs().length === 0 || app.config.shouldDisableLinkShare()}
                    color="blue"
                    icon
                    onClick={() => deepLinkOnClick()}
                  >
                    <Icon fitted name="external alternate" />
                  </Button>
                )}
              />

              <Popup
                content="App Info"
                position="bottom center"
                trigger={(
                  <Button color="blue" icon onClick={() => infoOnClick()}>
                    <Icon link fitted name="info" />
                  </Button>
                )}
              />
              <Popup
                content="Help/FAQ"
                position="bottom center"
                trigger={(
                  <Button color="blue" icon onClick={() => helpOnClick()}>
                    <Icon link fitted name="help" />
                  </Button>
                )}
              />
            </Menu.Item>
            <Dropdown open={dropdownOpen} text={auth?.UserIdentity?.Name} className="link item" onClick={() => this.setState({dropdownOpen: !dropdownOpen})}>
              <Dropdown.Menu>
                <Dropdown.Item icon="sign-out" text="Logout" onClick={() => logoutOnClick()} />
                <Dropdown.Item icon="drivers license" id="token-info-tour" text="Token Information" onClick={() => tokenInfoOnClick()} />
                <Dropdown.Item icon="shield alternate" text="Token Viewer" onClick={() => tokenViewerOnClick()} />
                <Dropdown.Item icon="setting" text="Diagnostics" onClick={() => diagnosticsOnClick()} />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  auth: state.auth,
  router: state.router,
  tabs: state.tabs,
  history: state.tabs?.history,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    addTab, activateTab, push, logout,
  }, dispatch);
}
/* eslint-enable */
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
