import './TabManager.scss';

import { State } from 'app/reducers/state';
import React, {
  useEffect, useState, FunctionComponent, ReactElement,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Menu, Tab,
} from 'semantic-ui-react';
import { unpinTab, addTab, setTabModalContent } from 'app/actions/tabActions';
import {
  PinnedTabDetails,
  TabDetails,
  TabType,
} from 'app/typings';
import _ from 'lodash';
import ZeroState from '../ZeroState/ZeroState';
import PaginatedTab, { PaginatedContextMenuProps } from '../PaginatedTab/PaginatedTab';
import ContentModal from '../ContentModal/ContentModal';
import { Pane } from './TabManager';
import TabManagerContextMenu, { ContextOptions } from '../ContextMenu/TabManagerContextMenu';

interface TabManagerProps {
  tabType: TabType,
  className?: string,
}

const PinnedTabManager: FunctionComponent<TabManagerProps> = (props: TabManagerProps) => {
  const dispatch = useDispatch();
  const tabState = useSelector((state: State) => state.tabs);
  const [activeIndex, setActiveIndex] = useState<number>();
  const [panes, setPanes] = useState<Pane[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [contextData, setContextData] = useState<PaginatedContextMenuProps>();
  const {
    tabType, className,
  } = props;

  const closeContextMenu = () => {
    setShowMenu(false);
    setContextData(undefined);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDocumentClick = (e: any) => {
      if (contextData && (e.target.outerHTML !== contextData.target.outerHTML)) {
        closeContextMenu();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleContextClick = (e: any) => {
      if (contextData && (e.target.parentElement !== contextData.target.parentElement)) {
        closeContextMenu();
      }
    };

    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('contextmenu', handleContextClick);

    return function cleanup() {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('contextmenu', handleContextClick);
    };
  }, [contextData]);

  const handleTabContext = (tab: PaginatedContextMenuProps) => {
    setTimeout(() => {
      setContextData(tab);
      setShowMenu(true);
    }, 25);
  };

  const handleContextClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.persist();
    e.preventDefault();

    if (e.type === 'contextmenu') {
      const pe = e.nativeEvent as PointerEvent;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const srcElement: any = (e.target as HTMLElement);
      const x = srcElement.offsetLeft + pe.offsetX;
      const y = srcElement.offsetTop + pe.offsetY;

      handleTabContext({
        e,
        tab: 'pinned-tab-container',
        parent: undefined,
        tabType,
        x,
        y,
        target: srcElement,
        allowClose: true,
      });
    }
  };

  const dispatchUnpinTab = (e: React.MouseEvent | undefined, id: string | undefined, type: TabType, parentTab?: string) => {
    if (e) {
      e.stopPropagation();
    }
    const tabsToShow = _.filter(tabState?.pinned, (t) => t.metadata.tabType === tabType);
    const closingIndex = _.findIndex(tabsToShow, ['id', id]);
    if (activeIndex === closingIndex) {
      setActiveIndex(activeIndex - 1);
    }

    const foundTab = _.find(tabState?.pinned, ['id', id]) as PinnedTabDetails;
    if (foundTab) {
      dispatch(unpinTab(id, type));
      dispatch(addTab(
        foundTab.name as string,
        tabType,
        foundTab.component as ReactElement,
        foundTab.metadata.routePath as string,
        false, // fromHistory
        foundTab.id, // id
        foundTab.refreshed,
        parentTab,
        foundTab.allowClose,
        foundTab.metadata,
        foundTab.children.tabs,
        foundTab.lastIndex,
      ));
    }
  };

  // eslint-disable-next-line consistent-return
  function getModalContent(tab: TabDetails) {
    if (!tab.modalContent) {
      return undefined;
    }

    const {
      icon = 'warning sign',
      header = 'Error',
      style = 'error',
      content = undefined,
    } = tab.modalContent;

    if (typeof tab.modalContent.content === 'string') {
      return (
        <ContentModal
          icon={icon}
          header={header}
          style={style}
          message={content}
          open
          closeEvent={() => {
            dispatch(setTabModalContent(tab.id as string, tabType, 'pinned'));
          }}
        />
      );
    }

    // Otherwise
    return tab.modalContent.content;
  }

  useEffect(() => {
    const tabsToShow = _.filter(tabState?.pinned, (t) => t.metadata.tabType === tabType);
    if (tabsToShow && tabsToShow.length > 0) { // If any tabs
      const tabPanes = tabsToShow.map((t) => {
        const contentModal = getModalContent(t);

        return {
          key: t.id,
          parent: t.parentTab,
          tabType,
          // Format into tabs
          menuItem: (
            <Menu.Item key={`pinned-tab-${t.id}`} className="tab primary-tab-menu-item">
              {/* eslint-disable-next-line */}
              {t.name && t.name.length > 40 ? (<span title={t.name}>{_.truncate(t.name, { length: 40 })}</span>) : t.name}
              <Button
                onClick={(e) => { dispatchUnpinTab(e, t.id, tabType, t.parentTab); }}
                className="tab-action-button"
                icon="close"
              />
            </Menu.Item>
          ),
          pane: {
            key: `pinned-pane-${t.id}-${t.refreshed}`,
            content: (
              <div style={{
                flex: '1 1 auto', height: 'unset', display: 'flex', flexDirection: 'column',
              }}
              >
                {t.component}
                {contentModal}
              </div>),
          },
        };
      });

      setPanes(tabPanes as Pane[]);
    } else {
      setPanes([]);
    }
  }, [tabState?.pinned]); // Whenever Tabs change

  const classNames = ['tabManager', tabType, className];
  const pinnedTabPane = {
    menuItem: (
      <Menu.Item
        key="pinned-tab-container"
        className="tab primary-tab-menu-item"
        onKeyDown={handleContextClick}
        onContextMenu={handleContextClick}
      >
        Pinned Tabs
      </Menu.Item>
    ),
    pane: {
      key: 'pinAnchor',
      content: (
        <div style={{ flex: '1 1 auto' }}>
          <PaginatedTab
            className={`${classNames.join(' ')} secondary-tabs-container`}
            renderActiveOnly={false}
            // eslint-disable-next-line
            activeIndex={activeIndex === -1 || activeIndex === undefined ? 0 : activeIndex}
            onTabChange={(_e, data) => setActiveIndex(data.activeIndex as number)}
            panes={panes}
            tabContextDelegate={handleTabContext}
          />
        </div>),
    },
  };

  const {
    x = 0, y = 0, tab = '', parent, e: contextEvent, tabType: contextTabType,
  } = contextData || { tabType };

  const contextOptions = contextData?.tab === 'pinned-tab-container' ? ContextOptions.closeAllTabs : ContextOptions.allButPin;

  return (
    <>
      <TabManagerContextMenu
        e={contextEvent}
        tab={tab}
        parentTab={parent}
        tabType={contextTabType}
        x={x}
        y={y}
        showMenu={showMenu}
        contextOptions={contextOptions}
        closeMenu={closeContextMenu}
        dispatchCloseTab={dispatchUnpinTab}
      />
      {
        panes && panes.length
          ? (
            <div className={`${classNames.join(' ')} pinned-tab-container`}>
              <Tab panes={[pinnedTabPane]} renderActiveOnly={false} />
            </div>
          )
          : <ZeroState text="" />
      }
    </>
  );
};

export default PinnedTabManager;

PinnedTabManager.defaultProps = {
  className: '',
};
