import * as preferenceTypes from 'app/actions/preferenceTypes';
import { BasicResponseOfUserPreferencesDataModel, IUserPreferencesDataModel } from 'app/proxyClients';
import { ValidPreferences } from 'app/typings';
import { Action } from 'redux';

export type UpdateUserPreferencesActionRequested = Action<
  typeof preferenceTypes.UPDATE_USER_PREFERENCES
> & {
  data?: ValidPreferences,
  includeToast: boolean
};

export type StageUserPreferencesRequested = Action<
  typeof preferenceTypes.STAGE_USER_PREFERENCES
> & {
  data?: ValidPreferences
};

export type CommitUserPreferencesRequested = Action<
  typeof preferenceTypes.COMMIT_PREFERENCES
> & {
  includeToast: boolean
};

export type LoadLocalUserPreferences = Action<typeof preferenceTypes.LOAD_LOCAL_USER_PREFERENCES> & {
};

export type GetUserPreferencesCompleted = Action<typeof preferenceTypes.GET_USER_PREFERENCES_COMPLETED> & {
  resp: BasicResponseOfUserPreferencesDataModel
};

export type UpdateUserPreferencesCompleted = Action<typeof preferenceTypes.UPDATE_USER_PREFERENCES_COMPLETED> & {
  resp: BasicResponseOfUserPreferencesDataModel
};

export type UpdateUserPreferencesFailed = Action<typeof preferenceTypes.UPDATE_USER_PREFERENCES_FAILED> & {
  error: string,
  resp: IUserPreferencesDataModel
};

export function loadLocalUserPreferences() {
  return {
    type: preferenceTypes.LOAD_LOCAL_USER_PREFERENCES,
  };
}

export function getUserPreferences() {
  return {
    type: preferenceTypes.GET_USER_PREFERENCES,
  };
}

export function updateUserPreferences(data?: ValidPreferences, includeToast = true) {
  return {
    type: preferenceTypes.UPDATE_USER_PREFERENCES,
    data,
    includeToast,
  };
}

export function commitPreferences(includeToast = true) {
  return {
    type: preferenceTypes.COMMIT_PREFERENCES,
    includeToast,
  };
}

export function stageUserPreferences(data?: ValidPreferences) {
  return {
    type: preferenceTypes.STAGE_USER_PREFERENCES,
    data,
  };
}
