import { Action } from 'redux';
import * as CompanyTypes from 'app/actions/companyTypes';
import { DSObject } from 'app/proxyClients';
import _ from 'lodash';

export type KeyGroup = {
  keyGroupId: string,
  keyGroup: DSObject
};

export type KeyGroupEntry = {
  objectId: string,
  keyGroups: KeyGroup[]
};

export type KeyGroupState = {
  entries: KeyGroupEntry[],
  getKeyGroups: (objectId: string) => KeyGroup[] | undefined,
};

function addToKeyGroupEntry(state: KeyGroupState, objectId: string, keyGroup: KeyGroup) {
  const entry = _.find(state.entries, (o: KeyGroupEntry) => o.objectId === objectId);
  const existing = _.without(state.entries, entry);

  const replacementEntry = {
    ...entry,
    keyGroups: [...entry?.keyGroups as KeyGroup[], keyGroup],
  } as KeyGroupEntry;

  return {
    ...state,
    entries: [...existing, replacementEntry],
  } as KeyGroupState;
}

const state: KeyGroupState = {
  entries: [],
  getKeyGroups(objectId: string) {
    const entries = _.filter(this.entries, (o: KeyGroupEntry) => o.objectId === objectId);
    return entries.length > 0 ? entries[0].keyGroups : undefined;
  },
};

type KeyGroupActions =
  GetKeyGroupCompleted | RemoveKeyGroupFromState;

type GetKeyGroupCompleted = Action<typeof CompanyTypes.GET_KEY_GROUP_REFERENCE_COMPLETED> & {
  objectId: string,
  keyGroupId: string,
  resp: DSObject
};
type RemoveKeyGroupFromState = Action<typeof CompanyTypes.REMOVE_KEY_GROUP_REFERENCE> & {
  objectId: string
};

export default (keyGroupState: KeyGroupState = state, action: KeyGroupActions): KeyGroupState => {
  switch (action.type) {
    case CompanyTypes.GET_KEY_GROUP_REFERENCE_COMPLETED:
      if (keyGroupState.getKeyGroups(action.objectId) === undefined) {
        return {
          ...keyGroupState,
          entries: [...keyGroupState.entries, {
            objectId: action.objectId,
            keyGroups: [{ keyGroupId: action.keyGroupId, keyGroup: action.resp }],
          }],
        };
      }

      return addToKeyGroupEntry(keyGroupState, action.objectId, { keyGroupId: action.keyGroupId, keyGroup: action.resp });

    case CompanyTypes.REMOVE_KEY_GROUP_REFERENCE: {
      if (keyGroupState.entries.findIndex((obj) => obj.objectId === action.objectId) === -1) {
        return {
          ...keyGroupState,
        };
      }
      const matchedIndex = keyGroupState.entries.findIndex((obj) => obj.objectId === action.objectId);
      return {
        ...keyGroupState, entries: [...keyGroupState.entries].filter((d, idx) => idx !== matchedIndex),
      };
    }
    default:
      return keyGroupState;
  }
};
