import * as AuthTypes from 'app/actions/authTypes';
import { Action } from 'redux';
import * as Config from 'app/constants/config';

export type UserIdentity = {
  Claims?: string[],
  HasSawExemption?: boolean,
  IdentityProvider?: string,
  IsLoggedInFromSaw?: boolean
  IsPlatformServiceAdministrator?: boolean
  IsPlatformServiceOperator?: boolean
  IsPlatformServiceViewer?: boolean
  IsSawExemptionOperator?: boolean
  IsSawExemptionPreviewMember?: boolean
  Name?: string,
  OriginatingCloudId?: string
  SecurityGroupMembership?: string[]
};

export type AuthState = {
  hasRole: () => boolean,
  isLoggedIn: () => boolean,
  IssuedTime?: Date,
  ExpirationTime?: Date,
  status: string,
  accessToken?: string,
  UserIdentity?: UserIdentity
};

const INITIAL_STATE: AuthState = {
  status: 'Not Logged In',
  IssuedTime: undefined,
  ExpirationTime: undefined,
  UserIdentity: {},
  hasRole() {
    return true;
  },
  isLoggedIn() {
    if (Config.shouldBypassAuth()) {
      return true;
    }

    return Object.hasOwnProperty.call(this, 'status') && this.status === 'Logged In';
  },
};

type LoginAuthAction = Action<typeof AuthTypes.LOGIN_COMPLETED> & { resp: Record<string, unknown> };
type AuthActions = LoginAuthAction | never;

export default (authState: AuthState = INITIAL_STATE, action: AuthActions): AuthState => {
  switch (action.type) {
    case AuthTypes.LOGIN_COMPLETED:
      return {
        ...authState, status: 'Logged In', ...action.resp,
      };
    default:
      return authState;
  }
};
