import { ToolboxClient } from 'app/proxyClients';

export interface ITool {
  name: string,
  endpoint: keyof ToolboxClient,
  header: string,
  placeholder: string,
  type: ToolTypes,
}

const enum ToolTypes {
  Base64 = 'Base64Generator',
  Hex = 'HexGenerator',
  NetId = 'NetIdGenerator',
  Guid = 'GuidGenerator',
  Sid = 'SidGenerator',
  DateTime = 'DateTimeGenerator',
}

const base64:ITool = {
  name: 'base64',
  endpoint: 'base64Converter',
  header: 'Base64 Converter',
  placeholder: 'e.g. aGVsbG93b3JsZA==',
  type: ToolTypes.Base64,
};

const hex:ITool = {
  name: 'hex',
  endpoint: 'hexConverter',
  header: 'Hex Converter',
  placeholder: 'e.g. 68656C6C6F776F726C64',
  type: ToolTypes.Hex,
};

const datetime:ITool = {
  name: 'dateTime',
  endpoint: 'dateTimeConverter',
  header: 'DateTime Converter',
  placeholder: 'e.g. 2021-02-25',
  type: ToolTypes.DateTime,
};

const netid:ITool = {
  name: 'netId',
  endpoint: 'netIdConverter',
  header: 'NetId Converter',
  placeholder: 'e.g. 10033FFF8006CDB3',
  type: ToolTypes.NetId,
};

const guid:ITool = {
  name: 'guid',
  endpoint: 'guidConverter',
  header: 'Guid Converter',
  placeholder: 'e.g. ab52bf06-9f8c-49f4-a0f5-f0a186b2e6b7',
  type: ToolTypes.Guid,
};

const sid:ITool = {
  name: 'sid',
  endpoint: 'sidConverter',
  header: 'Sid Converter',
  placeholder: 'e.g. S-1-10-1234-1234567890-1234567890',
  type: ToolTypes.Sid,
};

export const Tools = {
  Base64: base64,
  Hex: hex,
  Datetime: datetime,
  NetId: netid,
  Guid: guid,
  Sid: sid,
};
