/**
 * DSExplorer API
 * Read only APIs for interacting with the AAD Directory Store and helping discover data.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MicrosoftOnlineDSExplorerContractMetadataProperty } from '../models/MicrosoftOnlineDSExplorerContractMetadataProperty';
import { HttpFile } from '../http/http';

export class MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty {
    'displayName'?: string | null;
    'values'?: Array<string> | null;
    'name'?: MicrosoftOnlineDSExplorerContractMetadataProperty;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string",
            "format": ""
        },
        {
            "name": "values",
            "baseName": "values",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "MicrosoftOnlineDSExplorerContractMetadataProperty",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MicrosoftOnlineDSExplorerContractDSObjectMetadataProperty.attributeTypeMap;
    }

    public constructor() {
    }
}



