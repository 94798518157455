import { ColDef, ValueGetterParams } from 'ag-grid-community';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { TimeFormatType } from 'app/typings';
import { RowRecord } from './DSTableTypes';
import { FormatTime } from '../Utilities/TimeFormat';

export type Filter = {
  filterType: 'date' | 'number' | 'text',
  type: 'equals' |
  'notEqual' |
  'lessThan' |
  'lessThanOrEqual' |
  'greaterThan' |
  'greaterThanOrEqual' |
  'inRange' |
  'blank' |
  'notBlank' |
  'contains' |
  'notContains' |
  'startsWith' |
  'endsWith'
};

export type FilterModelText = Filter & {
  filter: string,
};

export type FilterModelNumeric = Filter & {
  filter: number,
  filterTo: number | undefined, // only on inRange
};

export type FilterModelDate = Filter & {
  dateFrom: string,
  dateTo: string,
};

export type FilterModelComplex = {
  operator: 'OR' | 'AND',
  filterType: string,
  condition1: FilterModelNumeric | FilterModelDate,
  condition2: FilterModelNumeric | FilterModelDate,
  condition3: FilterModelNumeric | FilterModelDate | undefined,
  condition4: FilterModelNumeric | FilterModelDate | undefined,
  condition5: FilterModelNumeric | FilterModelDate | undefined,
  condition6: FilterModelNumeric | FilterModelDate | undefined,
  condition7: FilterModelNumeric | FilterModelDate | undefined,
  condition8: FilterModelNumeric | FilterModelDate | undefined,
  condition9: FilterModelNumeric | FilterModelDate | undefined
};

const getColumnValue = (data: RowRecord, col: string, foundColumn: ColDef | undefined) => {
  if (foundColumn !== undefined && typeof foundColumn.valueGetter === 'function') {
    return foundColumn.valueGetter({
      colDef: foundColumn,
      data,
    } as ValueGetterParams);
  }

  const foundVal = _.get(data, col);
  if (Array.isArray(foundVal)) {
    return foundVal.join();
  }
  return foundVal as string;
};

const processNumericFilterModel = (data: RowRecord[], col: string, filter: FilterModelNumeric | undefined, colDef: ColDef[]) => {
  if (filter !== undefined) {
    const foundColumn = _.find(colDef, (o) => o.field === col);

    // eslint-disable-next-line default-case
    switch (filter.type) {
      case 'equals':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) === filter.filter);
      case 'notEqual':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) !== filter.filter);
      case 'lessThan':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) < filter.filter);
      case 'lessThanOrEqual':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) <= filter.filter);
      case 'greaterThan':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) > filter.filter);
      case 'greaterThanOrEqual':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) >= filter.filter);
      case 'inRange':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) >= filter.filter
          && getColumnValue(o, col, foundColumn) <= (filter.filterTo as number));
      case 'blank':
        return _.filter(data, (o) => {
          const value = getColumnValue(o, col, foundColumn);
          return value === undefined || value === null;
        });
      case 'notBlank':
        return _.filter(data, (o) => {
          const value = getColumnValue(o, col, foundColumn);
          return value !== undefined && value !== null;
        });
    }
  }

  return [];
};

const processDateFilterModel = (data: RowRecord[], col: string, filter: FilterModelDate | undefined, colDef: ColDef[], useUtc: boolean) => {
  if (filter !== undefined) {
    const foundColumn = _.find(colDef, (o) => o.field === col);
    let momentFilter = _.clone(filter);

    // Adjust dates provided to moments
    momentFilter = _.assign(momentFilter, {
      dateFrom: moment(filter.dateFrom).utc(useUtc),
      dateTo: moment(filter.dateTo).utc(useUtc),
    });

    // eslint-disable-next-line default-case
    switch (filter.type) {
      case 'equals':
        return _.filter(data, (o) => (momentFilter.dateFrom as unknown as Moment).isSame(getColumnValue(o, col, foundColumn), 'days'));
      case 'notEqual':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) !== momentFilter.dateFrom);
      case 'lessThan':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) < momentFilter.dateFrom);
      case 'lessThanOrEqual':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) <= momentFilter.dateFrom);
      case 'greaterThan':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) > momentFilter.dateFrom);
      case 'greaterThanOrEqual':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) >= momentFilter.dateFrom);
      case 'inRange':
        return _.filter(data, (o) => getColumnValue(o, col, foundColumn) >= momentFilter.dateFrom
          && getColumnValue(o, col, foundColumn) <= momentFilter.dateTo);
      case 'blank':
        return _.filter(data, (o) => {
          const value = getColumnValue(o, col, foundColumn);
          return value === '' || value === undefined || value === null;
        });
      case 'notBlank':
        return _.filter(data, (o) => {
          const value = getColumnValue(o, col, foundColumn);
          return value !== undefined && value !== null;
        });
    }
  }

  return [];
};

const processTextFilterModel = (data: RowRecord[],
  col: string,
  filter: FilterModelText | undefined,
  colDef: ColDef[],
  timeFormat: TimeFormatType,
  utc: boolean) => {
  if (filter !== undefined) {
    const foundColumn = _.find(colDef, (o) => o.field === col);
    const lowerCaseValue = filter.filter.toLowerCase();

    const validData: RowRecord[] = [...data].map((row) => {
      if (Array.isArray(row['Value(s)']) && typeof row['Value(s)'][0] === 'object') { // Time Formating
        // eslint-disable-next-line
        const timeObj: any = row['Value(s)'][0];
        return { ...row, 'Value(s)': [FormatTime(timeObj.props.time, timeFormat, utc)] };
      }
      return row;
    });

    // eslint-disable-next-line default-case
    switch (filter.type) {
      case 'contains':
        return _.filter(validData, (o) => {
          const value = getColumnValue(o, col, foundColumn).toLowerCase();
          return (value as string).indexOf(lowerCaseValue) > -1;
        });
      case 'notContains':
        return _.filter(validData, (o) => {
          const value = getColumnValue(o, col, foundColumn).toLowerCase();
          return (value as string).indexOf(lowerCaseValue) === -1;
        });
      case 'equals':
        return _.filter(validData, (o) => getColumnValue(o, col, foundColumn).toLowerCase() === lowerCaseValue);
      case 'notEqual':
        return _.filter(validData, (o) => getColumnValue(o, col, foundColumn).toLowerCase() !== lowerCaseValue);
      case 'startsWith':
        return _.filter(validData, (o) => _.startsWith(getColumnValue(o, col, foundColumn).toLowerCase(), lowerCaseValue));
      case 'endsWith':
        return _.filter(validData, (o) => _.endsWith(getColumnValue(o, col, foundColumn).toLowerCase(), lowerCaseValue));
      case 'blank':
        return _.filter(validData, (o) => {
          const value = getColumnValue(o, col, foundColumn);
          return value === undefined || value === null;
        });
      case 'notBlank':
        return _.filter(validData, (o) => {
          const value = getColumnValue(o, col, foundColumn);
          return value !== undefined && value !== null;
        });
    }
  }

  return [];
};

const processFilter = (data: RowRecord[], col: string, filter: Filter | undefined, colDef: ColDef[], useUtc: boolean, timeFormat: TimeFormatType) => {
  if (filter !== undefined) {
    // eslint-disable-next-line default-case
    switch (filter.filterType) {
      case 'text':
        return processTextFilterModel(data, col, filter as FilterModelText, colDef, timeFormat, useUtc);
      case 'number':
        return processNumericFilterModel(data, col, filter as FilterModelNumeric, colDef);
      case 'date':
        return processDateFilterModel(data, col, filter as FilterModelDate, colDef, useUtc);
    }
  }

  return [];
};

export const applyFilter = (
  data: RowRecord[],
  filterModel: Record<string, FilterModelNumeric | FilterModelDate | FilterModelComplex>,
  colDef: ColDef[],
  useUtc: boolean,
  timeFormat: TimeFormatType,
) => {
  let resultOfFilter = data;

  // eslint-disable-next-line consistent-return
  _.keysIn(filterModel).forEach((f) => {
    const filter = _.get(filterModel, f);

    if (!_.has(filter, 'condition1')) {
      resultOfFilter = processFilter(resultOfFilter, f, _.get(filterModel, f) as Filter, colDef, useUtc, timeFormat);
    } if ('operator' in filter && filter.operator === 'OR') {
      const resultOfFilter1 = processFilter(resultOfFilter, f, filter.condition1, colDef, useUtc, timeFormat);
      const resultOfFilter2 = processFilter(resultOfFilter, f, filter.condition2, colDef, useUtc, timeFormat);
      const resultOfFilter3 = processFilter(resultOfFilter, f, filter.condition3, colDef, useUtc, timeFormat);
      const resultOfFilter4 = processFilter(resultOfFilter, f, filter.condition4, colDef, useUtc, timeFormat);
      const resultOfFilter5 = processFilter(resultOfFilter, f, filter.condition5, colDef, useUtc, timeFormat);
      const resultOfFilter6 = processFilter(resultOfFilter, f, filter.condition6, colDef, useUtc, timeFormat);
      const resultOfFilter7 = processFilter(resultOfFilter, f, filter.condition7, colDef, useUtc, timeFormat);
      const resultOfFilter8 = processFilter(resultOfFilter, f, filter.condition8, colDef, useUtc, timeFormat);
      const resultOfFilter9 = processFilter(resultOfFilter, f, filter.condition9, colDef, useUtc, timeFormat);
      return _.uniq(_.union(resultOfFilter1,
        resultOfFilter2,
        resultOfFilter3,
        resultOfFilter4,
        resultOfFilter5,
        resultOfFilter6,
        resultOfFilter7,
        resultOfFilter8,
        resultOfFilter9));
    } if ('operator' in filter && filter.operator === 'AND') {
      if (filter.condition1) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition1, colDef, useUtc, timeFormat);
      }
      if (filter.condition2) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition2, colDef, useUtc, timeFormat);
      }
      if (filter.condition3) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition3, colDef, useUtc, timeFormat);
      }
      if (filter.condition4) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition4, colDef, useUtc, timeFormat);
      }
      if (filter.condition5) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition5, colDef, useUtc, timeFormat);
      }
      if (filter.condition6) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition6, colDef, useUtc, timeFormat);
      }
      if (filter.condition7) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition7, colDef, useUtc, timeFormat);
      }
      if (filter.condition8) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition8, colDef, useUtc, timeFormat);
      }
      if (filter.condition9) {
        resultOfFilter = processFilter(resultOfFilter, f, filter.condition9, colDef, useUtc, timeFormat);
      }
    }
  });

  return resultOfFilter;
};
