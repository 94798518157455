import type { ObjectTypeProps } from 'app/components/SidebarContent/CompanySidebar/CompanySidebar';
import React, { FunctionComponent, useState } from 'react';
import {
  Button, Input, Popup, Transition,
} from 'semantic-ui-react';

export type TrustedRealmSearchForm = Record<string, unknown> & {
  stsName: string,
};

const TrustedRealmSB: FunctionComponent<ObjectTypeProps<TrustedRealmSearchForm>> = ({
  disabled,
  searchEvent,
}: ObjectTypeProps<TrustedRealmSearchForm>) => {
  const [searchForm, setSearchForm] = useState<TrustedRealmSearchForm>({
    stsName: '',
  });

  return (
    <Transition transitionOnMount animation="scale" duration={400}>
      <div className="sub-side-bar">
        <label className="label">
          StsName
          <Popup
            flowing
            position="bottom left"
            content="Enter the StsName of the TrustedRealm you are looking for"
            trigger={(
              <Input
                disabled={disabled}
                value={searchForm.stsName}
                className="last-form-item"
                fluid
                placeholder="Enter the StsName of the TrustedRealm"
                onChange={(_e, { value }) => setSearchForm({ ...searchForm, stsName: value })}
              />
                    )}
          />
        </label>
        <div className="align-center">
          <Button
            compact
            disabled={disabled}
            className="sidebar-btn margin-right"
            onClick={() => setSearchForm({ stsName: '' })}
          >
            Reset
          </Button>
          <Button
            compact
            className="sidebar-btn"
            color="blue"
            disabled={searchForm.stsName === ''}
            onClick={() => searchEvent(searchForm)}
          >
            Search
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default TrustedRealmSB;
